import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '../../store';
import JourneyTemplates from './JourneyTemplates';

const JourneyTemplatesRoot = props => {
  const store = configureStore(props);

  return (
    <Provider store={store}>
      <JourneyTemplates />
    </Provider>
  );
};

export default JourneyTemplatesRoot;
