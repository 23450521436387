import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Icon } from '~ui';
import { FULL_DATE_FORMAT, TIME_FORMAT } from '~constants/datetime';
import { actionsLoadingSelector } from '~selectors';
import { bookAvailability } from '~actions/customerBooking';
import { sendInvoice } from '~actions/invoice';
import styles from './ConfirmRoute.module.scss';
import ICalendarLink from 'react-icalendar-link';
import axios from 'axios';
import { routes } from '~constants/routes';

const ConfirmRoute = ({ studio }) => {
  const bookingLogicCompleted = useSelector(
    state => state.customerBooking.bookingLogicCompleted
  );
  const availability = useSelector(
    state => state.customerBooking.selectedAvailability
  );
  const { full_address, location } = useSelector(
    state => state.customerBooking.bookingCalendar
  );
  const { id } = useSelector(state => state.customerBooking.clientInfo);
  const invoice = useSelector(state => state.customerBooking.invoice);
  const calendarEvent = useSelector(
    state => state.customerBooking.calendarEvent
  );
  const calendarEventError = useSelector(
    state => state.customerBooking.calendarEventError
  );

  const dispatch = useDispatch();
  const loading = useSelector(state =>
    actionsLoadingSelector(state, [
      'CUSTOMER_BOOKING_AVAILABILITY_BOOK_AVAILABILITY',
    ])
  );
  const cameFromConfirmRoute = window.sessionStorage.getItem(
    'cameFromConfirmRoute'
  );

  const sendInvoiceToClient = async () => {
    try {
      await dispatch(
        sendInvoice(invoice.id, {
          is_booking: true,
          studio_id: invoice.studio_id,
        })
      );
    } catch (error) {
      Rollbar.error('Unable to send booking invoice to client', error);
    }
  };

  useEffect(() => {
    if (id && !cameFromConfirmRoute) {
      window.sessionStorage.setItem('cameFromConfirmRoute', true);
      if (!bookingLogicCompleted) {
        dispatch(bookAvailability(availability, id));
      }
    }
  }, [id]);

  useEffect(() => {
    if (calendarEvent && invoice && invoice.id && !bookingLogicCompleted) {
      sendInvoiceToClient();
    }
  }, [calendarEvent]);

  const sendToGoogleLink = () => {
    window.open(createGoogleLink());
  };

  const event = {
    title: `Session with ${studio.name}`,
    startTime: availability.start_time,
    endTime: availability.end_time,
    location: location || '',
  };

  const createGoogleLink = () => {
    const title = event.title;
    const startTime = moment(event.startTime).format('YYYYMMDD[T]HHmmss');
    const endTime = moment(event.endTime).format('YYYYMMDD[T]HHmmss');
    const location = event.location;
    const googleLink = `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${startTime}/${endTime}&location=${location}`;
    return googleLink;
  };

  return (
    <div className={styles.ConfirmRoute}>
      {loading || (!calendarEvent && !calendarEventError) ? (
        <h1>Loading...</h1>
      ) : calendarEventError ? (
        <div className={styles['ConfirmRoute-Error']}>
          <div className={styles['ConfirmRoute-card']}>
            <h1>Oppps! We had trouble booking this time for you.</h1>
            <h1
              style={{ color: '#666666' }}
            >{`Please contact ${studio.name}.`}</h1>
          </div>
        </div>
      ) : (
        <>
          <h1>You're booked!</h1>
          <p>{`Thank you, ${studio.name} will be in touch soon.`}</p>
          <div className={styles['ConfirmRoute-card']}>
            <h2>
              <strong>{`Session with ${studio.name}`}</strong>
            </h2>
            {calendarEvent.all_day ? (
              <div>
                <Icon name="calendar" outlined />{' '}
                {`All Day ${moment(availability.end_time).format(
                  FULL_DATE_FORMAT
                )}`}
              </div>
            ) : (
              <div>
                <Icon name="calendar" outlined />{' '}
                {`${moment(availability.start_time).format(
                  TIME_FORMAT
                )} - ${moment(availability.end_time).format(
                  TIME_FORMAT
                )} ${moment(availability.end_time).format(FULL_DATE_FORMAT)}`}
              </div>
            )}

            {(full_address || location) && (
              <div>
                <Icon name="map-marker-alt" /> {full_address || location}
              </div>
            )}
          </div>
          <div className={styles['ConfirmRoute-buttonContainer']}>
            <p>Add to your calendar:</p>
            <div className={styles['ConfirmRoute-buttons']}>
              <div
                className={styles['ConfirmRoute-iCal']}
                onClick={sendToGoogleLink}
              >
                <i className="fab fa-google mr-8" />
                Google Calendar
              </div>
              <div className={styles['ConfirmRoute-iCal']}>
                <i className="far fa-calendar-plus mr-8" />
                <ICalendarLink event={event}>iCalendar</ICalendarLink>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default ConfirmRoute;
