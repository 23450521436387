import React from 'react';
import InvoicesHeader from './InvoicesHeader';
import InvoicesContent from './InvoicesContent';
import styles from './InvoicesRoot.module.scss';
import { Provider } from 'react-redux';
import { configureStore } from '../../store';
import { statusMetaMap } from './InvoicesSidebar/options';
import InvoicesSidebar from './InvoicesSidebar';

const InvoicesRoot = props => {
  const searchParams = new URLSearchParams(document.location.search);
  const statusParam = searchParams.get('status');

  const initialStoreMeta = statusMetaMap[statusParam] || statusMetaMap.open;

  const store = configureStore({
    invoice: {
      ...props,
      meta: {
        ...initialStoreMeta,
        total_records: 0,
        page_count: 0,
        due_date: { start: null, end: null },
      },
    },
    couponCodes: {},
  });

  return (
    <Provider store={store}>
      <div className={styles.InvoicesRoot}>
        <div className={styles['InvoicesRoot-flex']}>
          <div>
            <InvoicesSidebar />
          </div>
          <div className={styles['InvoicesRoot-flex-max-space']}>
            <InvoicesHeader />
            <InvoicesContent />
          </div>
        </div>
      </div>
    </Provider>
  );
};

export default InvoicesRoot;
