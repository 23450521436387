import EmailAttachments from './uploader/email_attachments';
import SquareCheckoutModal from './square/square_checkout_modal';
import EmailModal from './email_modal/email_modal';
import CalendarRoot from './Calendar/CalendarRoot';
import AvailabilityCalendarRoot from './AvailabilityCalendar/AvailabilityCalendarRoot';
import NewCalendarEventModal from './common/NewCalendarEventModal';
import NewInvoiceModal from './common/NewInvoiceModal';
import InvoiceRoot from './Invoice/InvoiceRoot';
import CustomerInvoice from './CustomerInvoice';
import CustomerInvoiceTemplate from './CustomerInvoiceTemplate';
import Spotlight from './Spotlight';
import ToDosRoot from './ToDos/ToDosRoot';
import InvoiceSetupRoot from './InvoiceSetup/InvoiceSetupRoot';
import SubscriptionRoot from './Subscription/SubscriptionRoot';
import JourneyTemplatesRoot from './JourneyTemplates/JourneyTemplatesRoot';
import JourneyTemplateRoot from './JourneyTemplates/JourneyTemplateRoot';
import OnePageSignupRoot from './OnePageSignup/OnePageSignupRoot';
import StudioProfile from './StudioProfile/StudioProfile';
import BookingSetup from './BookingSetup';
import ClientActivity from './ClientActivity/ClientActivity';
import ClientJourneys from './ClientJourneys';
import StudioInvoiceTemplates from './StudioInvoiceTemplates';
import StudioInvoiceTemplate from './StudioInvoiceTemplate';
import RailsComponents from './rails_components';
import EditAndSend from './common/EditAndSend';
import SelectAvailability from './BookingSetup/SelectAvailability';
import BookingRoot from './CustomerBooking/BookingRoot';
import BookingInvitationModal from './BookingInvitationModal';
import PendingSessions from './ClientProfile/SessionsHolder/PendingSessions';
import BookingSetupRoot from './BookingSetup/BookingSetupRoot';
import EmailTemplatesTable from './EmailTemplates/EmailTemplatesTable';
import EmailTemplate from './EmailTemplate';
import SignUpRoot from './SignUp/SignUpRoot';
import Dashboard from './Dashboard';
import SessionTypes from './SessionTypes';
import LeadsPageRoot from './LeadsPage/LeadsPageRoot';
import CustomerLeadsPageRoot from './CustomerLeadsPage/CustomerLeadsPageRoot';
import CSVExport from './Invoice/CSVExport';
import InvoicesRoot from './Invoices/InvoicesRoot';
import LocationsRoot from './Locations/LocationsRoot';
import SentLocation from './Locations/SentLocation/SentLocation';
import EmailMarketingRoot from './EmailMarketing/EmailMarketingRoot';
import CampaignTemplate from './EmailMarketing/CampaignTemplate';
import Unsubscribe from './Unsubscribe';
import BookingLandingPageRoot from './BookingLandingPage';
import EmailMarketingLandingPageRoot from './EmailMarketing/EmailMarketingLandingPage/EmailMarketingLandingPageRoot';
import ClientDetails from './ClientProfile/ClientDetails/ClientDetails';
import UpcomingSessions from './ClientProfile/SessionsHolder/UpcomingSessions/UpcomingSessions';
import PastSessions from './ClientProfile/SessionsHolder/PastSessions/PastSessions';
import SessionHolder from './ClientProfile/SessionsHolder/SessionHolder';
import ClientDetailsRoot from './ClientProfile/ClientDetails/ClientDetailsRoot';
import ClientSessionsRoot from './ClientProfile/ClientSessions/ClientSessionsRoot';
import ClientSessions from './ClientProfile/ClientSessions/ClientSessions/ClientSessions';
import ClientInvoicesRoot from './ClientProfile/ClientInvoices/ClientInvoicesRoot';
import ClientProfileRoot from './ClientProfile/ClientProfileRoot';
import ClientNavbar from './ClientProfile/ClientNavbar/ClientNavbar';
import ClientMobileNavbar from './ClientProfile/ClientMobileNavbar/ClientMobileNavbar';
import ClientBanner from './ClientProfile/ClientBanner/ClientBanner';
import StudioQuestionnaire from './StudioQuestionnaire';
import GalleryIntegrations from './GalleryIntegrations';
import DocumentsRoot from './Documents/DocumentsRoot';
import ApplicationNavbar from './ApplicationNavbar';
import CalendarConnect from './Calendar/CalendarConnect';
import ClientListRoot from './ClientList/ClientListRoot';
import SendLimitReached from './common/SendLimitReached';

export default {
  EmailAttachments,
  SquareCheckoutModal,
  EmailModal,
  CalendarRoot,
  AvailabilityCalendarRoot,
  NewCalendarEventModal,
  NewInvoiceModal,
  InvoiceRoot,
  CustomerInvoice,
  CustomerInvoiceTemplate,
  Spotlight,
  SubscriptionRoot,
  ToDosRoot,
  InvoiceSetupRoot,
  JourneyTemplatesRoot,
  JourneyTemplateRoot,
  SentLocation,
  OnePageSignupRoot,
  StudioProfile,
  ClientActivity,
  ClientJourneys,
  EditAndSend,
  StudioInvoiceTemplates,
  StudioInvoiceTemplate,
  BookingSetup,
  SelectAvailability,
  BookingRoot,
  BookingInvitationModal,
  PendingSessions,
  BookingSetupRoot,
  EmailTemplatesTable,
  EmailTemplate,
  SignUpRoot,
  Dashboard,
  SessionTypes,
  LeadsPageRoot,
  CustomerLeadsPageRoot,
  CSVExport,
  InvoicesRoot,
  LocationsRoot,
  EmailMarketingRoot,
  CampaignTemplate,
  Unsubscribe,
  BookingLandingPageRoot,
  EmailMarketingLandingPageRoot,
  ClientDetails,
  UpcomingSessions,
  PastSessions,
  SessionHolder,
  ClientDetailsRoot,
  ClientSessionsRoot,
  ClientSessions,
  ClientInvoicesRoot,
  ClientProfileRoot,
  ClientNavbar,
  ClientMobileNavbar,
  ClientBanner,
  StudioQuestionnaire,
  GalleryIntegrations,
  DocumentsRoot,
  ApplicationNavbar,
  CalendarConnect,
  ClientListRoot,
  SendLimitReached,
  ...RailsComponents,
};
