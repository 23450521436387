import { createActionTypes } from '~utils';

/*
  The actions defined in this file are grouped by backend models. Each action corresponds to the routes defined in the model's respective controller in the API. There may be actions defined for specific features instead of specific models (e.g. Calendar), which help manage feature-specific metadata.
*/

export const CALENDAR_EVENTS = createActionTypes('CALENDAR_EVENTS', {
  LIST: ['REQUEST', 'SUCCESS', 'FAILURE'],
  GET: ['REQUEST', 'SUCCESS', 'FAILURE'],
  CREATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  UPDATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  DELETE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  GET_SESSION_TYPE_COUNT: ['REQUEST', 'SUCCESS', 'FAILURE'],
});

export const CALENDAR = createActionTypes('CALENDAR', [
  'UPDATE_SELECTED_DAY',
  'UPDATE_INTERVAL',
  'UPDATE_SHOW_EDIT_MODAL',
  'UPDATE_SHOW_DELETE_MODAL',
  'UPDATE_ACTIVE_EVENT',
  'UPDATE_SUN',
  'UPDATE_DST',
]);

export const ERRORS = createActionTypes('ERRORS', ['CLEAR']);

export const INVOICES = createActionTypes('INVOICES', {
  GET: ['REQUEST', 'SUCCESS', 'FAILURE'],
  UPDATE: [
    'REQUEST',
    'SUCCESS',
    'FAILURE',
    'SHOW_RECORD_PAYMENT_MODAL',
    'SHOW_REFUND_MODAL',
    'SHOW_ADD_PAYMENT_SCHEDULE_MODAL',
    'HAS_QUICKBOOKS',
    'BOOKING_LOGIC_COMPLETED',
  ],
  DELETE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  SEND: ['REQUEST', 'SUCCESS', 'FAILURE'],
  SQUARE_REFUND: ['REQUEST', 'SUCCESS', 'FAILURE'],
  STRIPE_REFUND: ['REQUEST', 'SUCCESS', 'FAILURE'],
  MANUAL_REFUND: ['REQUEST', 'SUCCESS', 'FAILURE'],
});

export const INVOICES_LIST = createActionTypes('INVOICES_LIST', {
  GET: ['REQUEST', 'SUCCESS', 'FAILURE'],
  META: ['UPDATE'],
});

export const COUPON_CODE_LIST = createActionTypes('COUPON_CODE_LIST', {
  GET: ['REQUEST', 'SUCCESS', 'FAILURE'],
  META: ['UPDATE'],
});

export const COUPON_CODES = createActionTypes('COUPON_CODE', {
  CREATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  UPDATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  DELETE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  META: ['UPDATE'],
});

export const INVOICE_CONFIGS = createActionTypes('INVOICE_CONFIGS', {
  UPDATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
});

export const INVOICE_LINE_ITEMS = createActionTypes('INVOICE_LINE_ITEMS', {
  CREATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  UPDATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  DELETE: ['REQUEST', 'SUCCESS', 'FAILURE'],
});

export const INVOICE_PAYMENTS = createActionTypes('INVOICE_PAYMENTS', {
  CREATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  UPDATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  DELETE: ['REQUEST', 'SUCCESS', 'FAILURE'],
});

export const INVOICE_PRODUCTS = createActionTypes('INVOICE_PRODUCTS', {
  GET: ['REQUEST', 'SUCCESS', 'FAILURE'],
  CREATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  UPDATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  DELETE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  CHECK_LINE_ITEM_TEMPLATES: ['REQUEST', 'SUCCESS', 'FAILURE'],
});

export const PAYMENT_SCHEDULES = createActionTypes('PAYMENT_SCHEDULES', {
  UPDATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  RESTORE: ['REQUEST', 'SUCCESS', 'FAILURE'],
});

export const PAYMENT_SCHEDULE_INSTALLMENTS = createActionTypes(
  'PAYMENT_SCHEDULE_INSTALLMENTS',
  {
    CREATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
    UPDATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
    DELETE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  }
);

export const TODOS = createActionTypes('TODOS', {
  GET: ['REQUEST', 'SUCCESS', 'FAILURE'],
  CREATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  UPDATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  COMPLETE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  UNCOMPLETE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  DELETE: ['REQUEST', 'SUCCESS', 'FAILURE'],
});

export const CHARGES = createActionTypes('CHARGES', {
  GET: ['REQUEST', 'SUCCESS', 'FAILURE'],
});

export const JOURNEY_TEMPLATES = createActionTypes('JOURNEY_TEMPLATES', {
  INDEX: ['REQUEST', 'SUCCESS', 'FAILURE'],
  CREATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  DELETE: ['REQUEST', 'SUCCESS', 'FAILURE'],
});
export const JOURNEY_TEMPLATE = createActionTypes('JOURNEY_TEMPLATE', {
  GET: ['REQUEST', 'SUCCESS', 'FAILURE'],
  CREATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  UPDATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  COMPLETE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  UNCOMPLETE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  DELETE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  SET: ['RESOURCE_OPTIONS'],
  RESTORE: ['REQUEST', 'SUCCESS', 'FAILURE'],
});

export const REMINDER_TEMPLATE = createActionTypes('REMINDER_TEMPLATE', {
  CREATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  UPDATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
});

export const BOOKING_CALENDAR = createActionTypes('BOOKING_CALENDAR', [
  'UPDATE_SELECTED_DAY',
  'UPDATE_INTERVAL',
  'UPDATE_ACTIVE_SLOT',
  'UPDATE_SLOT_INTERVAL',
  'UPDATE_SHOW_ALL_CALENDARS',
]);

export const BOOKING_CALENDAR_REQUESTS = createActionTypes(
  'BOOKING_CALENDAR_REQUESTS',
  {
    PUBLISH: ['REQUEST', 'SUCCESS', 'FAILURE'],
  }
);

export const BOOKING_AVAILABILITY = createActionTypes('BOOKING_AVAILABILITY', {
  LIST: ['REQUEST', 'SUCCESS', 'FAILURE'],
  CREATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  UPDATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  DELETE: ['REQUEST', 'SUCCESS', 'FAILURE'],
});

export const CUSTOMER_BOOKING_AVAILABILITY = createActionTypes(
  'CUSTOMER_BOOKING_AVAILABILITY',
  {
    SELECT_AVAILABILITY: ['REQUEST', 'SUCCESS', 'FAILURE'],
    BOOK_AVAILABILITY: ['REQUEST', 'SUCCESS', 'FAILURE'],
    CHECK_CLIENT_PERMISSION: ['REQUEST', 'SUCCESS', 'FAILURE'],
  }
);

export const CUSTOMER_BOOKING_CLIENT = createActionTypes(
  'CUSTOMER_BOOKING_CLIENT',
  {
    CREATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  }
);

export const CUSTOMER_BOOKING_CONTRACT = createActionTypes(
  'CUSTOMER_BOOKING_CONTRACT',
  {
    GENERATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  }
);

export const CUSTOMER_BOOKING_INVOICE = createActionTypes(
  'CUSTOMER_BOOKING_INVOICE',
  {
    GENERATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  }
);

export const CUSTOMER_BOOKING_FOOTER = createActionTypes(
  'CUSTOMER_BOOKING_FOOTER',
  ['UPDATE_ON_CONTINUE', 'UPDATE_CURRENT_SELECTION', 'UPDATE_CONTINUE_DISABLED']
);

export const CUSTOMER_BOOKING = createActionTypes('CUSTOMER_BOOKING', [
  'RESET',
  'BOOKING_LOGIC_COMPLETED',
  'UPDATE_CALENDAR_EVENT',
]);

export const DOCUMENTS = createActionTypes('DOCUMENTS', {
  GET: ['REQUEST', 'SUCCESS', 'FAILURE'],
  META: ['UPDATE'],
});

export const CLIENT_DOCUMENTS = createActionTypes('CLIENT_DOCUMENTS', {
  GET: ['REQUEST', 'SUCCESS', 'FAILURE'],
  META: ['UPDATE'],
});

export const QUESTIONNAIRES = createActionTypes('QUESTIONNAIRES', {
  GET: ['REQUEST', 'SUCCESS', 'FAILURE'],
  META: ['UPDATE'],
});

export const CONTRACT_TEMPLATES = createActionTypes('CONTRACT_TEMPLATES', {
  GET: ['REQUEST', 'SUCCESS', 'FAILURE'],
  META: ['UPDATE'],
});

export const CLIENT_CONTRACTS = createActionTypes('CLIENT_CONTRACTS', {
  GET: ['REQUEST', 'SUCCESS', 'FAILURE'],
  META: ['UPDATE'],
});

export const CLIENT_LIST = createActionTypes('CLIENT_LIST', {
  GET: ['REQUEST', 'SUCCESS', 'FAILURE'],
  BULK_UPDATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  META: ['UPDATE'],
});

export const DELETE_CONFIRMATION = createActionTypes('DELETE_CONFIRMATION', {
  META: ['UPDATE'],
});

export const CLIENTS = createActionTypes('CLIENTS', {
  CREATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  UPDATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  DELETE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  META: ['UPDATE'],
});

export const GROUPS = createActionTypes('GROUPS', {
  BULK_UPDATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  GET: ['REQUEST', 'SUCCESS', 'FAILURE'],
  CREATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  UPDATE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  DELETE: ['REQUEST', 'SUCCESS', 'FAILURE'],
  META: ['UPDATE'],
});
