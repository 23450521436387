import React, { useState } from 'react';
import styles from './StudioSelections.module.scss';
import { ProgressBar, Button } from '~ui';
import ImageUploading from 'react-images-uploading';
import axios from 'axios';
import { routes } from '~constants/routes';
import ToggleContent from '~components/common/ToggleContent';
import SessionTypesModal from './SessionTypesModal';

const StudioSelections = ({ defaultSessionTypes, currentUser, setLoading }) => {
  const [activeStep, setActiveStep] = useState(1);
  const [logo, setLogo] = useState([]);
  const [sessionTypes, setSessionTypes] = useState(
    defaultSessionTypes.map((type, index) => ({
      ...type,
      id: index,
      default_session_type_id: type.id,
    }))
  );

  const [chosenSessionTypes, setChosenSessionTypes] = useState([]);

  const steps = [
    {
      label: '',
      onClick: () => {
        activeStep !== 3 && setActiveStep(1);
      },
      active: activeStep === 1,
    },
    {
      label: '',
      onClick: () => {
        activeStep !== 3 && setActiveStep(2);
      },
      active: activeStep === 2,
    },
    { label: '', onClick: null, active: activeStep === 3 },
  ];

  const handleGetStarted = async () => {
    setLoading(true);
    await axios.post(routes.SIGNUP.STUDIO_CREATE);
    setLoading(false);
    window.location.href = '/studio/profile';
  };

  const handleNewSessionType = async sessionTypeName => {
    setSessionTypes([
      ...sessionTypes,
      {
        id: sessionTypes.length + 1,
        default_session_type_id: null,
        name: sessionTypeName,
        color: '#3a87ad',
        image_name: null,
      },
    ]);
    handleSessionTypes({
      id: sessionTypes.length + 1,
      default_session_type_id: null,
      name: sessionTypeName,
      color: '#3a87ad',
      image_name: null,
    });
  };

  const handleSessionTypes = sessionType => {
    if (
      chosenSessionTypes.some(
        chosenSessionType =>
          sessionType.id === chosenSessionType.id ||
          sessionType.default_session_type_id ===
            chosenSessionType.default_session_type_id
      )
    ) {
      setChosenSessionTypes(
        chosenSessionTypes.filter(chosen => {
          if (chosen.id) {
            return chosen.id !== sessionType.id;
          } else {
            return (
              chosen.default_session_type_id !==
              sessionType.default_session_type_id
            );
          }
        })
      );
    } else {
      setChosenSessionTypes([...chosenSessionTypes, sessionType]);
    }
  };

  const Step1 = () => {
    const trackingPixelForShareASale = `
    <img id='_SHRSL_img_1' src='https://www.shareasale.com/sale.cfm?tracking=${currentUser.studio_id}&amount=0.00&merchantID=141946&transtype=lead' width='1' height='1'>
    <script src='https://www.dwin1.com/53201.js' type='text/javascript' defer='defer'></script>
  `;

    return (
      <>
        <div className={styles['StudioSelections-step1']}>
          <h2>Add your logo</h2>
          <p>
            Iris will add your logo across your entire account, keeping your
            brand consistent
          </p>
          <ImageUploading value={logo} onChange={setLogo} dataURLKey="data_url">
            {({ onImageUpload }) => (
              <div>
                <Button text="Upload Image" onClick={onImageUpload} />
                <div className={styles['StudioSelections-step1-filename']}>
                  {logo[0] ? logo[0].file.name : ''}
                </div>
              </div>
            )}
          </ImageUploading>
          {logo[0] && (
            <div className={styles['StudioSelections-step1-logo']}>
              <img src={logo[0].data_url} alt="your logo" />
            </div>
          )}
          <a onClick={() => setActiveStep(2)}>I'll do this later</a>
        </div>
        <div dangerouslySetInnerHTML={{ __html: trackingPixelForShareASale }} />
      </>
    );
  };

  const Step2 = () => {
    return (
      <div className={styles['StudioSelections-step2']}>
        <h2>What kind of photography do you offer?</h2>
        <p>
          Choose from the options below or add your own. We will add templates
          into your account based on your selections. Choose all that apply to
          your business.
        </p>
        <div className={styles['StudioSelections-step2-sessionTypes']}>
          {sessionTypes.map((sessionType, index) => (
            <Button
              className={
                chosenSessionTypes.some(
                  chosenSessionType => sessionType.id === chosenSessionType.id
                )
                  ? styles['StudioSelections-step2-sessionTypes-selected']
                  : ''
              }
              key={index}
              text={sessionType.name}
              onClick={() => handleSessionTypes(sessionType)}
            />
          ))}
          <ToggleContent
            toggle={handleShow => (
              <Button text={'+ Add my own'} onClick={handleShow} />
            )}
            content={handleHide => (
              <SessionTypesModal {...{ handleNewSessionType, handleHide }} />
            )}
          />
        </div>
      </div>
    );
  };

  const Step3 = () => {
    return (
      <div className={styles['StudioSelections-step3']}>
        <h2>You're ready to get started. </h2>
        <h2> Let's dive in!</h2>
        <p>
          We’ve added some templates in your account based on the type of
          photography you offer.{' '}
        </p>
        <p>
          You can use these emails, questionnaires and workflows as they are
          written, modify them, or create your own from scratch.{' '}
        </p>
        <Button text="Get Started" onClick={handleGetStarted} />
      </div>
    );
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 1:
        return <Step1 />;
      case 2:
        return <Step2 />;
      case 3:
        return <Step3 />;
      default:
        return <></>;
    }
  };

  const handleNext = async () => {
    switch (activeStep) {
      case 1:
        if (logo[0]) {
          setLoading(true);
          await axios.put(routes.SIGNUP.STUDIO_SETUP, {
            signup: {
              studio_attributes: {
                logo: logo[0].data_url,
                logo_file_name: logo[0].file.name,
              },
            },
          });
          setLoading(false);
        }
      case 2:
        setLoading(true);
        await axios.put(routes.SIGNUP.STUDIO_SETUP, {
          signup: {
            studio_attributes: {
              session_types_attributes: chosenSessionTypes.map(st => ({
                default_session_type_id: st.default_session_type_id,
                name: st.name,
                color: st.color || null,
                show_on_lead_form: true,
              })),
            },
          },
        });
        setLoading(false);
      default:
        break;
    }

    setActiveStep(activeStep + 1);
  };

  return (
    <div className={styles.StudioSelections}>
      <div className={styles['StudioSelections-header']}>
        <h1>Welcome, {currentUser.first_name}!</h1>
        <p>Let's set up the rest of your account.</p>
      </div>
      <div className={styles['StudioSelections-container']}>
        <div className={styles['StudioSelections-container-progress']}>
          <ProgressBar {...{ steps }} />
        </div>
        <div>{getStepContent()}</div>
        {activeStep !== 3 && (
          <div className={styles['StudioSelections-container-buttons']}>
            {activeStep !== 1 ? (
              <Button
                text="Back"
                onClick={() => setActiveStep(activeStep - 1)}
              />
            ) : (
              <div />
            )}
            <Button text="Next" onClick={handleNext} />
          </div>
        )}
      </div>
    </div>
  );
};

export default StudioSelections;
