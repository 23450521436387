import React, { useState, useEffect } from 'react';
import { TextInput, MultiSelectSearch, DocumentUploader } from '~ui';
import ImageUploading from 'react-images-uploading';
import classNames from 'classnames/bind';
import { routes } from '~constants/routes';
import axios from 'axios';
import { useDebounce, useIsFirstMount } from '~hooks';
import TinyMceInput from '~components/common/TinyMceInput';
import styles from './EmailTemplate.module.scss';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';

const EmailTemplate = props => {
  const {
    emailTemplate,
    initialAttachments,
    bookingUrls,
    locations,
    restrictBannerUsage,
  } = props;
  const [formData, setFormData] = useState(emailTemplate.attributes);
  const [attachmentsOptions, setAttachmentsOptions] = useState(
    initialAttachments
  );
  const [selectedAttachments, setSelectedAttachments] = useState([]);
  const isFirstMount = useIsFirstMount();

  useEffect(() => {
    setSelectedAttachments(
      formData.email_attachments.map(attachment => ({
        label: attachment.name,
        value: attachment.item_id,
        category: attachmentMapping[attachment.item_type].name,
        icon: attachmentMapping[attachment.item_type].icon,
      }))
    );
  }, [formData]);

  const attachmentMapping = {
    ContractTemplate: {
      name: 'Contract Templates',
      icon: 'signature',
    },
    InvoiceTemplate: {
      name: 'Invoice Templates',
      icon: 'file-invoice-dollar',
    },
    WebForm: {
      name: 'Questionnaires',
      icon: 'question',
    },
    Document: {
      name: 'Documents',
      icon: 'file',
    },
  };

  const getAttachmentType = attachmentCategory => {
    switch (attachmentCategory) {
      case 'Invoice Templates':
        return 'InvoiceTemplate';
      case 'Contract Templates':
        return 'ContractTemplate';
      case 'Questionnaires':
        return 'WebForm';
      case 'Documents':
        return 'Document';
      default:
        break;
    }
  };

  const cx = classNames.bind(styles);

  const debouncedName = useDebounce(formData.name, 1500);
  const debouncedSubject = useDebounce(formData.email_subject, 1500);
  const debouncedBody = useDebounce(formData.email_body, 1500);

  useEffect(() => {
    if (!isFirstMount) {
      const emailTemplateParams = {
        name: formData.name,
        email_subject: formData.email_subject,
      };
      updateTemplate(emailTemplateParams);
    }
  }, [debouncedName, debouncedSubject]);

  useEffect(() => {
    if (!isFirstMount && formData.email_body != '<p></p>') {
      const emailTemplateParams = {
        email_body:
          formData.email_body.length === 0 ? '<p></p>' : formData.email_body,
      };
      updateTemplate(emailTemplateParams);
    }
  }, [debouncedBody]);

  const updateTemplate = async params => {
    try {
      const res = await axios.put(routes.EMAIL_TEMPLATES.PUT(formData.id), {
        email_template: params,
      });
      setAttachmentsOptions(res.data.attachments_options);
      setFormData({ ...formData, ...res.data.data.attributes });
      toast.info('Saved!', { ...toastOptions, autoClose: 1000 });
    } catch (err) {
      toast.error(
        'Your email template did not save, please contact support',
        toastOptions
      );
      console.error(err);
    }
  };

  return (
    <div className={styles.EmailTemplate}>
      <a
        href="/studio/email_templates"
        className={`${
          formData.name &&
          formData.email_subject &&
          formData.name.length > 0 &&
          formData.email_subject.length > 0
            ? 'c-cb'
            : 'pe-n c-cb'
        }`}
      >
        <i className="fas fa-arrow-left" /> Back to all Email Templates
      </a>

      <div className={styles['EmailTemplate-nameSubject']}>
        <TextInput
          name="name"
          labelText="Name"
          initialValue={formData.name}
          onChangeCallback={value => setFormData({ ...formData, name: value })}
          required
        />

        <TextInput
          name="email_subject"
          labelText="Email Subject"
          initialValue={formData.email_subject || 'Add a subject'}
          onChangeCallback={value =>
            setFormData({ ...formData, email_subject: value })
          }
          required
        />
      </div>

      <TinyMceInput
        labelText="Body"
        value={formData.email_body}
        onChangeCallback={value =>
          setFormData({ ...formData, email_body: value })
        }
        locations={locations}
        bookingUrls={bookingUrls}
      />

      {!restrictBannerUsage ? (
        <div className={styles['EmailTemplate-columns']}>
          <div style={{ flex: 1 }}>
            <label>Layout</label>
            <div className={styles['EmailTemplate-layoutInput']}>
              <div className={styles['EmailTemplate-layoutContainer']}>
                <div
                  className={cx('EmailTemplate-layout', {
                    'EmailTemplate-layout--selected':
                      formData.layout === 'text_only',
                  })}
                  onClick={() => updateTemplate({ layout: 'text_only' })}
                >
                  <hr /> <hr /> <hr /> <hr />
                </div>
                <span>Text Only</span>
              </div>
              <div className={styles['EmailTemplate-layoutContainer']}>
                <div
                  className={cx('EmailTemplate-layout', {
                    'EmailTemplate-layout--selected':
                      formData.layout === 'banner_image',
                  })}
                  onClick={() => {
                    if (formData.banner) {
                      updateTemplate({ layout: 'banner_image' });
                    } else {
                      setFormData({ ...formData, layout: 'banner_image' });
                    }
                  }}
                >
                  <i className="fa fa-image" />
                  <hr /> <hr />
                </div>
                <span>Banner Image</span>
              </div>
            </div>
          </div>

          {formData.layout === 'banner_image' && (
            <div style={{ flex: 1 }}>
              <label>Banner</label>
              <ImageUploading
                value={formData.banner}
                onChange={banner => {
                  updateTemplate({
                    layout: formData.layout,
                    banner: banner[0].data_url,
                    banner_file_name: banner[0].file.name,
                  });
                }}
                dataURLKey="data_url"
              >
                {({ onImageUpload }) => (
                  <div className="upload__image-wrapper">
                    <div className="upload__title">
                      <button onClick={onImageUpload}>Upload</button>
                      <div className={styles['EmailTemplate-bannerName']}>
                        <p>{formData.banner_file_name}</p>
                        {!!formData.banner_file_name && (
                          <a
                            onClick={() => {
                              updateTemplate({
                                banner: null,
                                banner_file_name: null,
                              });
                            }}
                          >
                            <i className="fa fa-trash-alt" />
                          </a>
                        )}
                      </div>
                    </div>
                    {formData.banner && (
                      <img
                        src={formData.banner}
                        alt="Banner image"
                        width="auto"
                        height={180}
                      />
                    )}
                  </div>
                )}
              </ImageUploading>
            </div>
          )}
        </div>
      ) : null}

      <label>Attachments</label>
      <br />
      <span>Give your clients access to additional resources.</span>
      <h3>From Iris</h3>
      <MultiSelectSearch
        placeholder={'Start typing to search for an Iris attachment'}
        options={attachmentsOptions}
        initialValue={selectedAttachments}
        onChangeCallback={val => {
          updateTemplate({
            email_attachments_attributes: [
              {
                item_id: val.value,
                item_type: getAttachmentType(val.category),
              },
            ],
          });
        }}
        onDeleteCallback={val => {
          updateTemplate({
            email_attachments_attributes: [
              {
                id: formData.email_attachments.find(
                  att => att.item_id == val.value
                ).id,
                _destroy: true,
              },
            ],
          });
        }}
      />

      <h3>From your Computer</h3>
      <DocumentUploader
        readAs="readAsDataURL"
        onChange={fileData => {
          updateTemplate({
            from_computer_file: fileData.dataString,
            from_computer_file_name: fileData.fileName,
          });
        }}
        onError={error => {
          toast.error(error.message, toastOptions);
        }}
      />
    </div>
  );
};

export default EmailTemplate;
