import React, { useState } from 'react';
import { Modal, Dropdown, Button } from '~ui';
import styles from '../DocumentsContent/DocumentsContent.module.scss';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { routes } from '~constants/routes';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { getQuestionnaireResponses } from '~actions/questionnaires';

const SendQuestionnaireModal = ({
  handleHide,
  clientId,
  questionnairesOptions,
  clientName,
}) => {
  const [questionnaireId, setQuestionnaireId] = useState(null);
  const [sending, setSending] = useState(false);

  const dispatch = useDispatch();

  const handleSend = async () => {
    try {
      setSending(true);
      const res = await axios.put(
        routes.QUESTIONNAIRES.SEND_IT(questionnaireId),
        { client_id: clientId }
      );

      if (res.status === 204) {
        toast.info('Questionnaire Sent!', toastOptions);
      }

      dispatch(getQuestionnaireResponses(clientId));

      setSending(false);
      handleHide();
    } catch (error) {
      setSending(false);
      console.error(error);
      if (error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Error sending questionnaire');
      }
    }
  };

  return (
    <Modal
      title={`Send Questionnaire to ${clientName}`}
      handleHide={handleHide}
    >
      <div className={styles.DocumentModalInputContainer}>
        <Dropdown
          name="questionnaires"
          labelText="Questionnaires"
          onChangeCallback={setQuestionnaireId}
          options={questionnairesOptions}
        />
      </div>
      <div className={styles.DocumentModalButtonContainer}>
        <Button text="Cancel" onClick={handleHide} danger />
        <Button
          text="Send"
          onClick={handleSend}
          disabled={!questionnaireId || sending}
        />
      </div>
    </Modal>
  );
};

export default SendQuestionnaireModal;
