import React from 'react';
import styles from '../CalendarEvent/CalendarEvent.module.scss';
import placeholderStyles from './PlaceholderStyles.module.scss';

const PlaceholderEvent = () => {
  return (
    <div
      className={`${styles.CalendarEvent} ${placeholderStyles.Placeholder}`}
    />
  );
};

export default PlaceholderEvent;
