import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { uniqueId } from '~utils';
import { DEFAULT_EVENTS } from '~constants/calendar';
import styles from './TimeSlotGroup.module.scss';
import AllDayTimeSlot from '../AllDayTimeSlot';
import TimeSlot from '../TimeSlot';

const TimeSlotGroup = ({ day, events, availabilities }) => {
  const slotInterval =
    useSelector(state => state.bookingCalendar.slotInterval) || 60;

  const renderTimeSlots = () => {
    const numIntervals = 60 / +slotInterval - 1;

    return Object.keys(events)
      .filter(key => key !== 'all_day')
      .sort()
      .map(hour => {
        let dayClone = moment(day).clone();
        let key = uniqueId('timeSlot_');

        dayClone.set({ hour: hour, minute: '0' });

        return (
          <Fragment key={key}>
            <TimeSlot
              availabilities={availabilities[hour]}
              events={events[hour]}
              day={day}
              hour={hour}
              minute={0}
            />
            {[...Array(numIntervals)].map((i, index) => (
              <TimeSlot
                day={day}
                hour={hour}
                minute={(index + 1) * +slotInterval}
              />
            ))}
          </Fragment>
        );
      });
  };

  return (
    <div className={styles.TimeSlotGroup}>
      <AllDayTimeSlot
        availabilities={availabilities.all_day}
        events={events.all_day}
        day={day}
      />
      {renderTimeSlots()}
    </div>
  );
};

TimeSlotGroup.propTypes = {
  events: PropTypes.object,
};

TimeSlotGroup.defaultProps = {
  events: DEFAULT_EVENTS,
};

export default TimeSlotGroup;
