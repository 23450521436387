/**
 * Action Types Creator (courtesy of Waseem Dahman waseem@lessonly.com)
 *
 * @param {String} actionName - The overall action or category we want actions nested under. Should be all caps.
 * @param {Object} actions - The specific actions we want mapped under the action name. Should be all caps.
 * @return {Object} -  A set of actions indexed by object keys from the actions parameter. If the actions param is an array, then it will return a simple object with each value prefaced by the actionName.
 *
 * Example:
 *
 * const actions = {
 *   UPDATE: ['TAGS', 'SINGLE_TAG']
 * }
 *
 * const FILTER = createActionTypes('FILTER', actions)
 *
 * console.log(FILTER.UPDATE.SINGLE_TAG); // 'FILTER_UPDATE_SINGLE_TAG'
 *
 * console.log(FILTER);
 *
 * // FILTER = {
 * //   UPDATE: {
 * //     TAGS: 'FILTER_UPDATE_TAGS',
 * //     SINGLE_TAG: 'FILTER_UPDATE_SINGLE_TAG',
 * //   }
 * // }
 *
 */

export const createActionTypes = (actionName, actions) => {
  const spreadArray = (hash, key, origin) => {
    const spread = {};
    hash.forEach(each => {
      spread[each] = origin
        .concat(key, each)
        .filter(i => !!i)
        .join('_');
    });
    return spread;
  };

  if (Array.isArray(actions)) {
    const name = [actionName];
    return spreadArray(actions, null, name);
  }

  Object.keys(actions).forEach(key => {
    const name = [actionName];
    if (Array.isArray(actions[key])) {
      actions[key] = spreadArray(actions[key], key, name);
    } else {
      actions[key] = name.concat(key).join('_');
    }
  });
  return actions;
};
