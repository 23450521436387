import React from 'react';
import { Modal, Button } from '~ui';

const JourneyTemplatesDeleteModal = ({ handleHide, handleDelete, name }) => {
  return (
    <Modal title="Are you sure?" handleHide={handleHide}>
      <div className="mb-8">
        Would you like to delete the "{name}" Workflow?
      </div>
      <div className="d-fr">
        <Button className="mr-8" text="Cancel" onClick={handleHide} danger />
        <Button
          text="Delete"
          onClick={() => {
            handleDelete();
            handleHide();
          }}
        />
      </div>
    </Modal>
  );
};

export default JourneyTemplatesDeleteModal;
