import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment-timezone';
import { routes } from '~constants/routes';
import { Pill, Icon } from '~ui';
import { get } from '~utils';
import styles from './CalendarEventSummary.module.scss';
import {
  FULL_DATE_TIME_FORMAT,
  FULL_DATE_TIME_FORMAT_WITH_ZONE,
  FULL_DATE_FORMAT,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_WITH_ZONE,
} from '~constants/datetime';

const CalendarEventSummary = props => {
  const { event } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [eventData, setEventData] = useState({});
  const [clientData, setClientData] = useState({});
  const sentEmails = get(eventData, ['storyboard_items', 'sent_emails']);
  const invoices = get(eventData, ['storyboard_items', 'invoices']);
  const contracts = get(eventData, ['storyboard_items', 'contracts']);
  const webForms = get(eventData, ['storyboard_items', 'web_forms']);
  const timeZone = useSelector(state => state.calendar.meta.timeZone);

  const timezonesAreSame =
    moment().tz(moment.tz.guess()).format('z') ==
    moment().tz(timeZone).format('z');

  const dateTimeformatString = timezonesAreSame
    ? DATE_TIME_FORMAT
    : DATE_TIME_FORMAT_WITH_ZONE;

  const fullDateTimeFormatString = timezonesAreSame
    ? FULL_DATE_TIME_FORMAT
    : FULL_DATE_TIME_FORMAT_WITH_ZONE;

  useEffect(() => {
    let didCancel = false;

    async function loadData() {
      try {
        const res = await axios.get(routes.CALENDAR_EVENTS.SHOW(event.id), {
          params: { with_storyboard_items: true },
        });
        if (!didCancel) {
          setEventData(res.data.data.attributes);
          setClientData(get(res.data.included[0], ['attributes']));
          setIsLoading(false);
        }
      } catch (e) {
        toast.info(
          'There was a problem retrieving the event information. Contact support if this issue persists.'
        );
      }
    }

    loadData();

    return () => {
      didCancel = true;
    };
  }, []);

  const renderScheduledEmails = emails => {
    return (
      <div className={styles['CalendarEventSummary-section']}>
        <h3>
          <i className="fa fa-hourglass-half" />
          Scheduled Emails
        </h3>
        <ul>
          {emails.map(email => (
            <li key={`scheduledEmail_${email.scheduled_at}`}>
              <span>{email.name}</span>
              <span className={styles['CalendarEventSummary-itemSubtitle']}>
                {moment(email.scheduled_at).format(fullDateTimeFormatString)}
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderSentEmails = emails => {
    return (
      <div className={styles['CalendarEventSummary-section']}>
        <h3>
          <i className="fa fa-envelope" />
          Sent Emails
        </h3>
        <ul>
          {emails.map(email => (
            <li key={`sentEmail_${email.id}`}>
              <span>{email.email_subject}</span>
              <span className={styles['CalendarEventSummary-itemSubtitle']}>
                {moment(email.email_sent).format(fullDateTimeFormatString)}
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderInvoices = invoices => {
    return (
      <div className={styles['CalendarEventSummary-section']}>
        <h3>
          <i className="fa fa-dollar-sign" />
          Invoices
        </h3>
        <ul>
          {invoices.map(invoice => (
            <li key={`invoice_${invoice.id}`}>
              <a onClick={() => openInvoice(invoice.id)}>
                <span>{`Invoice ${invoice.invoice_num}`}</span>
              </a>
              {invoice.paid_in_full ? (
                <Pill text="Paid" color="green" small />
              ) : (
                <Pill text="Unpaid" color="red" small />
              )}
              <span className={styles['CalendarEventSummary-itemSubtitle']}>
                Due Date: {moment(invoice.due_date).format(FULL_DATE_FORMAT)}
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderContracts = contracts => {
    return (
      <div className={styles['CalendarEventSummary-section']}>
        <h3>
          <i className="fa fa-gavel" />
          Contracts
        </h3>
        <ul>
          {contracts.map(contract => (
            <li key={`contract_${contract.id}`}>
              <a onClick={() => openContract(contract.uuid)}>
                <span>{contract.name}</span>
              </a>
              {contract.agree_to_terms ? (
                <Pill text="Signed" color="green" small />
              ) : (
                <Pill text="Unsigned" color="red" small />
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderWebForms = webForms => {
    return (
      <div className={styles['CalendarEventSummary-section']}>
        <h3>
          <i className="fa fa-file-alt" />
          Questionnaires
        </h3>
        <ul>
          {webForms.map(webForm => (
            <li key={`webForm_${webForm.id}`}>
              <span>{webForm.name}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const openClientPage = clientId => {
    window.open(`/clients/${clientId}`, '_blank');
  };

  const openInvoice = invoiceId => {
    window.open(`/invoices/${invoiceId}`, '_blank');
  };

  const openContract = contractUuid => {
    window.open(`/customer/contracts/${contractUuid}.pdf`, '_blank');
  };

  const timeInterval = () => {
    const start = moment(eventData.start_time).tz(timeZone);
    const end = moment(eventData.end_time).tz(timeZone);
    const multiDay = end.diff(start, 'days') > 1;

    if (eventData.all_day && multiDay) {
      return `${start.format(DATE_FORMAT)} - ${end
        .subtract(1, 'day')
        .format(DATE_FORMAT)}`;
    }

    if (eventData.all_day) {
      return start.format(DATE_FORMAT);
    }

    return `${start.format(dateTimeformatString)} - ${end.format(
      dateTimeformatString
    )}`;
  };

  return (
    <div>
      {isLoading && <h1>Loading</h1>}
      {!isLoading && (
        <>
          <div className={styles['CalendarEventSummary-header']}>
            <h1>
              {eventData.status === 'needs_rescheduled' && (
                <Icon name="exclamation-triangle" />
              )}
              {eventData.title}
            </h1>
            <span>{timeInterval()}</span>
          </div>
          {(eventData.is_session ||
            eventData.marked_as_free ||
            eventData.all_day) && (
            <div className={styles['CalendarEventSummary-attributeSection']}>
              {eventData.status === 'needs_rescheduled' && (
                <Pill text="Needs Rescheduled" color="red" small />
              )}
              {eventData.is_session && <Pill text="Session" small />}
              {eventData.marked_as_free && <Pill text="Free" small />}
              {eventData.all_day && <Pill text="All Day" small />}
            </div>
          )}
          {clientData && (
            <span className={styles['CalendarEventSummary-section']}>
              <i className="fa fa-user" />{' '}
              <a onClick={() => openClientPage(clientData.id)}>
                {clientData.full_name}
              </a>
            </span>
          )}
          {eventData.pretty_full_address && (
            <span className={styles['CalendarEventSummary-section']}>
              <i className="fa fa-map-marker-alt" />{' '}
              {eventData.pretty_full_address}
            </span>
          )}
          {eventData.description && (
            <span className={styles['CalendarEventSummary-section']}>
              <i className="fa fa-quote-left" /> {eventData.description}
            </span>
          )}
          {event.status === 'active' && (
            <>
              {invoices &&
                invoices.length > 0 &&
                renderInvoices(eventData.storyboard_items.invoices)}
              {contracts &&
                contracts.length > 0 &&
                renderContracts(eventData.storyboard_items.contracts)}
              {webForms &&
                webForms.length > 0 &&
                renderWebForms(eventData.storyboard_items.web_forms)}
              {sentEmails &&
                sentEmails.length > 0 &&
                renderSentEmails(eventData.storyboard_items.sent_emails)}
              {eventData.scheduled_emails &&
                eventData.scheduled_emails.length > 0 &&
                renderScheduledEmails(eventData.scheduled_emails)}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CalendarEventSummary;
