export const metaMap = {
  allActive: {
    status: 'allActive',
    page: 1,
    titleDisplay: 'All Active',
    search_term: null,
    active: true,
    inactive: false,
    marketingContacts: false,
    groups: false,
    dateAddedSortDirection: 'desc',
    alphabeticalSort: null,
    companyAlpabeticalSort: null,
    activeSubOptions: true,
    inactiveSubOptions: false,
    marketingSubOptions: false,
    currentFilterIndex: 0,
    currentTitleIndex: 0,
    beforeSearchTitleDisplay: '',
    itemsPerPage: 10,
  },
  activeClients: {
    status: 'clients',
    page: 1,
    titleDisplay: 'Clients',
    search_term: null,
    active: true,
    inactive: false,
    marketingContacts: false,
    groups: false,
    dateAddedSortDirection: 'desc',
    alphabeticalSort: null,
    companyAlpabeticalSort: null,
    activeSubOptions: true,
    inactiveSubOptions: false,
    marketingSubOptions: false,
    currentFilterIndex: 1,
    currentTitleIndex: 0,
    beforeSearchTitleDisplay: '',
    itemsPerPage: 10,
  },
  activeLeads: {
    status: 'leads',
    page: 1,
    titleDisplay: 'Leads',
    search_term: null,
    active: true,
    inactive: false,
    marketingContacts: false,
    groups: false,
    dateAddedSortDirection: 'desc',
    alphabeticalSort: null,
    companyAlpabeticalSort: null,
    activeSubOptions: true,
    inactiveSubOptions: false,
    marketingSubOptions: false,
    currentFilterIndex: 2,
    currentTitleIndex: 0,
    beforeSearchTitleDisplay: '',
    itemsPerPage: 10,
  },
  activeRepeatClients: {
    status: 'repeatClients',
    page: 1,
    titleDisplay: 'Repeat Clients',
    search_term: null,
    active: true,
    inactive: false,
    marketingContacts: false,
    groups: false,
    dateAddedSortDirection: 'desc',
    alphabeticalSort: null,
    companyAlpabeticalSort: null,
    activeSubOptions: true,
    inactiveSubOptions: false,
    marketingSubOptions: false,
    currentFilterIndex: 3,
    currentTitleIndex: 0,
    beforeSearchTitleDisplay: '',
    itemsPerPage: 10,
  },
  allInactive: {
    status: 'allInactive',
    page: 1,
    titleDisplay: 'All Inactive',
    search_term: null,
    active: false,
    inactive: true,
    marketingContacts: false,
    groups: false,
    dateAddedSortDirection: 'desc',
    alphabeticalSort: null,
    companyAlpabeticalSort: null,
    activeSubOptions: false,
    inactiveSubOptions: true,
    marketingSubOptions: false,
    currentFilterIndex: 0,
    currentTitleIndex: 1,
    beforeSearchTitleDisplay: '',
    itemsPerPage: 10,
  },
  inactiveClients: {
    status: 'inactiveClients',
    page: 1,
    titleDisplay: 'Clients',
    search_term: null,
    active: false,
    inactive: true,
    marketingContacts: false,
    groups: false,
    dateAddedSortDirection: 'desc',
    alphabeticalSort: null,
    companyAlpabeticalSort: null,
    activeSubOptions: false,
    inactiveSubOptions: true,
    marketingSubOptions: false,
    currentFilterIndex: 1,
    currentTitleIndex: 1,
    beforeSearchTitleDisplay: '',
    itemsPerPage: 10,
  },
  inactiveLeads: {
    status: 'inactiveLeads',
    page: 1,
    titleDisplay: 'Leads',
    search_term: null,
    active: false,
    inactive: true,
    marketingContacts: false,
    groups: false,
    dateAddedSortDirection: 'desc',
    alphabeticalSort: null,
    companyAlpabeticalSort: null,
    activeSubOptions: false,
    inactiveSubOptions: true,
    marketingSubOptions: false,
    currentFilterIndex: 2,
    currentTitleIndex: 1,
    beforeSearchTitleDisplay: '',
    itemsPerPage: 10,
  },
  inactiveRepeatClients: {
    status: 'inactiveRepeatClients',
    page: 1,
    titleDisplay: 'Repeat Clients',
    search_term: null,
    active: false,
    inactive: true,
    marketingContacts: false,
    groups: false,
    dateAddedSortDirection: 'desc',
    alphabeticalSort: null,
    companyAlpabeticalSort: null,
    activeSubOptions: false,
    inactiveSubOptions: true,
    marketingSubOptions: false,
    currentFilterIndex: 3,
    currentTitleIndex: 1,
    beforeSearchTitleDisplay: '',
    itemsPerPage: 10,
  },
  marketingContacts: {
    status: 'marketingContacts',
    page: 1,
    titleDisplay: 'Marketing Contacts',
    search_term: null,
    active: false,
    inactive: false,
    marketingContacts: true,
    groups: false,
    dateAddedSortDirection: 'desc',
    alphabeticalSort: null,
    companyAlpabeticalSort: null,
    activeSubOptions: false,
    inactiveSubOptions: false,
    marketingSubOptions: true,
    currentFilterIndex: 0,
    currentTitleIndex: 2,
    beforeSearchTitleDisplay: '',
    itemsPerPage: 10,
  },
  inactiveMarketingContacts: {
    status: 'inactiveMarketingContacts',
    page: 1,
    titleDisplay: 'Marketing Contacts',
    search_term: null,
    active: false,
    inactive: false,
    marketingContacts: true,
    groups: false,
    dateAddedSortDirection: 'desc',
    alphabeticalSort: null,
    companyAlpabeticalSort: null,
    activeSubOptions: false,
    inactiveSubOptions: false,
    marketingSubOptions: true,
    currentFilterIndex: 1,
    currentTitleIndex: 2,
    beforeSearchTitleDisplay: '',
    itemsPerPage: 10,
  },
  groups: {
    status: 'groups',
    page: 1,
    titleDisplay: 'Groups',
    search_term: null,
    active: false,
    inactive: false,
    marketingContacts: false,
    groups: true,
    dateAddedSortDirection: null,
    alphabeticalSort: null,
    companyAlpabeticalSort: null,
    activeSubOptions: false,
    inactiveSubOptions: false,
    marketingSubOptions: false,
    currentFilterIndex: 0,
    currentTitleIndex: 3,
    beforeSearchTitleDisplay: '',
    itemsPerPage: 10,
  },
};

export const getOptions = (
  meta,
  dispatch,
  getAllClients,
  getGroups,
  updateClientListMeta
) => [
  {
    title: 'Active',
    active: meta.active,
    noChevron: true,
    onClickCallback: () => {
      dispatch(updateClientListMeta(metaMap['allActive']));
      dispatch(getAllClients());
    },
  },
  {
    title: 'Inactive',
    active: meta.inactive,
    noChevron: true,
    onClickCallback: () => {
      dispatch(updateClientListMeta(metaMap['allInactive']));
      dispatch(getAllClients());
    },
  },
  {
    title: 'Marketing Contacts',
    active: meta.marketingContacts,
    noChevron: true,
    onClickCallback: () => {
      dispatch(updateClientListMeta(metaMap['marketingContacts']));
      dispatch(getAllClients());
    },
  },
  {
    title: 'Groups',
    active: meta.groups,
    noChevron: true,
    onClickCallback: () => {
      dispatch(updateClientListMeta(metaMap['groups']));
      dispatch(getGroups());
    },
  },
];

export const getDropdownOptions = (
  meta,
  dispatch,
  getAllClients,
  getGroups,
  updateClientListMeta
) => [
  {
    label: 'Active',
    active: meta.active,
    noChevron: true,
    onClickCallback: () => {
      dispatch(updateClientListMeta(metaMap['allActive']));
      dispatch(getAllClients());
    },
  },
  {
    label: 'Inactive',
    active: meta.inactive,
    noChevron: true,
    onClickCallback: () => {
      dispatch(updateClientListMeta(metaMap['allInactive']));
      dispatch(getAllClients());
    },
  },
  {
    label: 'Marketing Contacts',
    active: meta.marketingContacts,
    noChevron: true,
    onClickCallback: () => {
      dispatch(updateClientListMeta(metaMap['marketingContacts']));
      dispatch(getAllClients());
    },
  },
  {
    label: 'Groups',
    active: meta.groups,
    noChevron: true,
    onClickCallback: () => {
      dispatch(updateClientListMeta(metaMap['groups']));
      dispatch(getGroups());
    },
  },
];

export const activeSubOptions = (
  dispatch,
  getAllClients,
  updateClientListMeta
) => [
  {
    label: 'All Active',
    onClickCallback: () => {
      dispatch(updateClientListMeta(metaMap['allActive']));
      dispatch(getAllClients());
    },
  },
  {
    label: 'Active Clients',
    onClickCallback: () => {
      dispatch(updateClientListMeta(metaMap['activeClients']));
      dispatch(getAllClients());
    },
  },
  {
    label: 'Active Leads',
    onClickCallback: () => {
      dispatch(updateClientListMeta(metaMap['activeLeads']));
      dispatch(getAllClients());
    },
  },
  {
    label: 'Active Repeat Clients',
    onClickCallback: () => {
      dispatch(updateClientListMeta(metaMap['activeRepeatClients']));
      dispatch(getAllClients());
    },
  },
];

export const inactiveSubOptions = (
  dispatch,
  getAllClients,
  updateClientListMeta
) => [
  {
    label: 'All Inactive',
    onClickCallback: () => {
      dispatch(updateClientListMeta(metaMap['allInactive']));
      dispatch(getAllClients());
    },
  },
  {
    label: 'Inactive Clients',
    onClickCallback: () => {
      dispatch(updateClientListMeta(metaMap['inactiveClients']));
      dispatch(getAllClients());
    },
  },
  {
    label: 'Inactive Leads',
    onClickCallback: () => {
      dispatch(updateClientListMeta(metaMap['inactiveLeads']));
      dispatch(getAllClients());
    },
  },
  {
    label: 'Inactive Repeat Clients',
    onClickCallback: () => {
      dispatch(updateClientListMeta(metaMap['inactiveRepeatClients']));
      dispatch(getAllClients());
    },
  },
];

export const marketingContactSubOptions = (
  dispatch,
  getAllClients,
  updateClientListMeta
) => [
  {
    label: 'Active Contacts',
    onClickCallback: () => {
      dispatch(updateClientListMeta(metaMap['marketingContacts']));
      dispatch(getAllClients());
    },
  },
  {
    label: 'Inactive Contacts',
    onClickCallback: () => {
      dispatch(updateClientListMeta(metaMap['inactiveMarketingContacts']));
      dispatch(getAllClients());
    },
  },
];
