import React from 'react';
import { Modal, Button } from '~ui';

const ConnectConfirmationModal = props => {
  const { handleHide, deauthorize } = props;

  return (
    <Modal title="Are you sure?" handleHide={() => handleHide(false)}>
      <div className="mb-8">Would you like to disconnect this calendar?</div>
      <br />
      <div className="d-fr">
        <Button
          className="mr-8"
          text="No"
          onClick={e => {
            e.stopPropagation();
            handleHide();
          }}
          danger
        />
        <Button
          text="Yes"
          onClick={e => {
            e.stopPropagation();
            deauthorize();
          }}
        />
      </div>
    </Modal>
  );
};

export default ConnectConfirmationModal;
