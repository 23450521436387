import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Checkbox,
  TextInput,
  DateTimePicker,
  Dropdown,
  TextArea,
  CurrencyInput,
  Icon,
  PercentageInput,
  ScheduleIntervalInputs,
} from '~ui';
import { toCurrencyAmount } from '~utils';
import { FULL_DATE_FORMAT } from '~constants/datetime';
import { absolutePath } from '~constants/routes';
import classNames from 'classnames/bind';
import styles from './Table.module.scss';

const TableCell = ({
  cell,
  children,
  isEditing,
  rowId,
  invoiceTemplateScheduleTrigger,
  stringId,
}) => {
  const {
    name,
    value,
    type,
    options,
    onChangeCallback,
    editable,
    interval,
    interval_amount,
    percent_amount,
    route,
    disabled,
  } = cell;
  const canEdit = editable && isEditing;
  const isRelativeDate = interval && interval_amount;
  const isPercentAmount = !!percent_amount;

  const ToggleButtons = ({ type, left, right }) => {
    const cx = classNames.bind(styles);
    const [selected, setSelected] = useState(left.selected ? 'left' : 'right');

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          className={styles['Table-toggleButtonContainer']}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            setSelected('left');
          }}
        >
          <button
            className={cx('Table-toggleButtonLeft', {
              'Table-toggleButtonLeft--selected': selected === 'left',
            })}
          >
            {left.icon ? (
              <Icon name={left.icon} small />
            ) : (
              <span>{left.text}</span>
            )}
          </button>
          <button
            className={cx('Table-toggleButtonRight', {
              'Table-toggleButtonRight--selected': selected === 'right',
            })}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              setSelected('right');
            }}
          >
            {right.icon ? (
              <Icon name={right.icon} small />
            ) : (
              <span>{right.text}</span>
            )}
          </button>
        </div>

        {type === 'due_date' && selected === 'right' && (
          <>
            <div>
              <TextInput
                initialValue={interval_amount}
                name="interval_amount"
                characterLimit={2}
              />
            </div>
            <div style={{ marginLeft: '4px' }}>
              <Dropdown
                initialValue={interval}
                name="interval"
                options={[
                  { value: '', label: 'Interval' },
                  { value: 'day', label: 'Day(s)' },
                  { value: 'week', label: 'Week(s)' },
                  { value: 'month', label: 'Month(s)' },
                  { value: 'year', label: 'Year(s)' },
                ]}
                className="mn-w-114"
              />
            </div>
          </>
        )}

        {type === 'due_date' && selected === 'left' && (
          <>
            <DateTimePicker
              initialDateTime={value}
              name={name}
              onChangeCallback={onChangeCallback}
              disableTime
            />
          </>
        )}

        {type === 'amount' && selected === 'left' && (
          <CurrencyInput
            disabled={disabled}
            hideIcon
            characterLimit={7}
            name="amount"
            initialValue={String(value)}
            max={10000}
          />
        )}

        {type === 'amount' && selected === 'right' && (
          <PercentageInput
            hideIcon
            characterLimit={7}
            name="percent_amount"
            initialValue={percent_amount}
          />
        )}
      </div>
    );
  };

  let selectedOption;
  if (options && options.length > 0) {
    options.forEach(option => {
      if (option.value === value) {
        selectedOption = option;
      }
    });

    if (!selectedOption) {
      selectedOption = options[0];
    }
  }

  const renderByDataType = () => {
    const dataType = typeof value;
    switch (dataType) {
      case 'boolean':
        if (type === 'checkbox') {
          return (
            <Checkbox
              checked={value}
              name={name}
              onChangeCallback={onChangeCallback}
            />
          );
        }
        return value ? 'Yes' : 'No';
      case 'function':
        return value();
      default:
        if (type === 'currency' || type === 'toggle_amount') {
          return toCurrencyAmount(value);
        }

        if (type === 'datetime' || type === 'toggle_datetime') {
          return moment(value).isValid()
            ? moment(value).format(FULL_DATE_FORMAT)
            : '—';
        }

        if (type === 'enum-link') {
          return selectedOption ? (
            <a
              className={styles['TableCell--textLink']}
              href={absolutePath(`${route}/${selectedOption.value}`)}
            >
              {selectedOption.label}
            </a>
          ) : (
            value
          );
        }

        return selectedOption ? selectedOption.label : value;
    }
  };

  const renderFieldByType = () => {
    switch (type) {
      case 'checkbox':
        return '';
      case 'boolean':
        return <Checkbox checked={value} name={name} />;
      case 'datetime':
        return (
          <DateTimePicker
            initialDateTime={value}
            name={name}
            onChangeCallback={onChangeCallback}
            disableTime
          />
        );
      case 'toggle_datetime':
        return (
          <ToggleButtons
            type="due_date"
            left={{
              icon: 'calendar-alt',
              selected: !isRelativeDate,
            }}
            right={{
              icon: 'stopwatch',
              selected: isRelativeDate,
            }}
          />
        );
      case 'toggle_amount':
        return (
          <ToggleButtons
            type="amount"
            left={{
              text: window.app.invoices.currency,
              selected: !isPercentAmount,
            }}
            right={{ text: '%', selected: isPercentAmount }}
          />
        );
      case 'enum':
      case 'enum-link':
        return (
          <Dropdown
            className="mx-w-175"
            initialValue={selectedOption ? String(selectedOption.value) : ''}
            name={name}
            options={options}
            onChangeCallback={onChangeCallback}
          />
        );
      case 'text':
        return <TextArea initialValue={value} name={name} />;
      case 'number':
        return (
          <TextInput
            initialValue={String(value)}
            name={name}
            characterLimit={4}
          />
        );
      case 'currency':
        return (
          <CurrencyInput
            disabled={disabled}
            name={name}
            initialValue={String(value)}
            max={50000}
            characterLimit={8}
            inline
          />
        );
      case 'interval':
        let [i_amount, unit] = value ? value.split(' ') : '1 test ';
        let inter = unit.replace('(s)', '');
        if (value == 'When invoice is received') {
          i_amount = 0;
          inter = 'day';
        }

        return (
          <div style={{ display: 'flex' }}>
            <div>
              <TextInput
                initialValue={Number(i_amount)}
                name="interval_amount"
                characterLimit={2}
              />
            </div>
            <div style={{ marginLeft: '4px' }}>
              <Dropdown
                initialValue={inter}
                name="interval"
                options={[
                  { value: '', label: 'Interval' },
                  { value: 'day', label: 'Day(s)' },
                  { value: 'week', label: 'Week(s)' },
                  { value: 'month', label: 'Month(s)' },
                  { value: 'year', label: 'Year(s)' },
                ]}
                className="mn-w-114"
              />
            </div>
          </div>
        );
      case 'schedule_interval':
        return (
          <ScheduleIntervalInputs
            scheduleTrigger={interval}
            scheduleOffset={interval_amount}
            rowId={rowId}
            invoiceTemplateScheduleTrigger={invoiceTemplateScheduleTrigger}
            stringId={stringId}
          />
        );
      default:
        return <TextInput initialValue={String(value)} name={name} small />;
    }
  };

  return (
    <td>
      <div className={styles['Table-dataContainer']}>
        {!canEdit && renderByDataType()}
        {canEdit && renderFieldByType()}
        {children}
      </div>
    </td>
  );
};

TableCell.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  cell: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.func,
    ]),
    type: PropTypes.string,
    editable: PropTypes.bool,
  }).isRequired,
  isEditing: PropTypes.bool,
};

export default TableCell;
