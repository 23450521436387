import React, { useEffect } from 'react';
import InvoicesSidebar from '../InvoicesSidebar';
import InvoicesTable from '../InvoicesTable';
import { useDispatch } from 'react-redux';
import { getInvoicesList } from '~actions/invoice';
import CouponCodeTable from '../CouponCodeTable';
import { useWindowSize } from '~hooks';
import styles from './InvoicesContent.module.scss';
import { useSelector } from 'react-redux';

const InvoicesContent = () => {
  const dispatch = useDispatch();
  const { codesExpanded } = useSelector(state => state.invoice.meta);

  const windowWidth = useWindowSize()[0];
  const isMobile = windowWidth < 680;

  useEffect(() => {
    dispatch(getInvoicesList());
  }, []);

  return (
    <div className={styles.InvoicesContent}>
      {!codesExpanded && <InvoicesTable isMobile={isMobile} />}
      {codesExpanded && <CouponCodeTable isMobile={isMobile} />}
    </div>
  );
};

export default InvoicesContent;
