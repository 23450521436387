import {
  INVOICES,
  INVOICE_LINE_ITEMS,
  INVOICE_PAYMENTS,
  PAYMENT_SCHEDULES,
  PAYMENT_SCHEDULE_INSTALLMENTS,
  INVOICES_LIST,
  COUPON_CODE_LIST,
} from '~constants/actionTypes';

export default function (state = {}, action) {
  let payments, installments, items;

  switch (action.type) {
    case INVOICES.GET.SUCCESS:
      items = [...action.payload.items];

      return {
        ...state,
        ...action.payload,
      };
    case INVOICES.UPDATE.SUCCESS:
      return { ...state, ...action.payload };
    case INVOICES.SEND.SUCCESS:
      return { ...state, ...action.payload };
    case INVOICE_LINE_ITEMS.CREATE.SUCCESS:
      items = [...state.items, action.payload];

      return { ...state, items };
    case INVOICE_LINE_ITEMS.UPDATE.SUCCESS:
      items = state.items.map(item => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          };
        }

        return item;
      });

      return { ...state, items };
    case INVOICE_LINE_ITEMS.DELETE.SUCCESS:
      items = state.items.filter(item => item.id !== action.payload.id);

      return { ...state, items };
    case INVOICE_PAYMENTS.CREATE.SUCCESS:
      payments = [...state.payments, action.payload];

      return { ...state, payments };
    case INVOICE_PAYMENTS.UPDATE.SUCCESS:
      payments = state.payments.map(payment => {
        if (payment.id === action.payload.id) {
          return {
            ...payment,
            ...action.payload,
          };
        }

        return payment;
      });

      return { ...state, payments };
    case INVOICE_PAYMENTS.DELETE.SUCCESS:
      payments = state.payments.filter(
        payment => payment.id !== action.payload.id
      );

      return { ...state, payments };
    case PAYMENT_SCHEDULES.UPDATE.SUCCESS:
      return { ...state, schedule: { ...state.schedule, ...action.payload } };
    case PAYMENT_SCHEDULES.RESTORE.SUCCESS:
      return { ...state, schedule: { ...state.schedule, ...action.payload } };
    case PAYMENT_SCHEDULE_INSTALLMENTS.CREATE.SUCCESS:
      installments = [...state.schedule.installments, action.payload];
      return { ...state, schedule: { ...state.schedule, installments } };
    case PAYMENT_SCHEDULE_INSTALLMENTS.UPDATE.SUCCESS:
      installments = state.schedule.installments.map(installment => {
        if (installment.id === action.payload.id) {
          return {
            ...installment,
            ...action.payload,
          };
        }

        return installment;
      });

      return { ...state, schedule: { ...state.schedule, installments } };
    case PAYMENT_SCHEDULE_INSTALLMENTS.DELETE.SUCCESS:
      installments = state.schedule.installments.filter(
        installment => installment.id !== action.payload.id
      );

      return { ...state, schedule: { ...state.schedule, installments } };
    case INVOICES.SQUARE_REFUND.SUCCESS:
      return { ...state, square_refunds: action.payload };
    case INVOICES.STRIPE_REFUND.SUCCESS:
      return { ...state, stripe_refunds: action.payload };
    case INVOICES.MANUAL_REFUND.SUCCESS:
      return { ...state, manual_refunds: action.payload };
    case INVOICES.UPDATE.SHOW_RECORD_PAYMENT_MODAL:
      return { ...state, showRecordPaymentModal: action.payload };
    case INVOICES.UPDATE.SHOW_REFUND_MODAL:
      return { ...state, showRefundModal: action.payload };
    case INVOICES.UPDATE.SHOW_ADD_PAYMENT_SCHEDULE_MODAL:
      return { ...state, showAddPaymentScheduleModal: action.payload };
    case INVOICES.UPDATE.HAS_QUICKBOOKS:
      return { ...state, hasQuickbooks: action.payload };
    case INVOICES_LIST.GET.REQUEST:
      return { ...state, meta: { ...state.meta, status: action.payload } };
    case INVOICES_LIST.GET.SUCCESS:
      return {
        ...state,
        invoicesList: action.payload.data,
        meta: { ...state.meta, ...action.payload.meta },
      };
    case INVOICES_LIST.META.UPDATE:
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.payload,
        },
      };
    case COUPON_CODE_LIST.GET.SUCCESS:
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.payload.meta,
        },
      };
    default:
      return state;
  }
}
