import React from 'react';
import styles from './Header.module.scss';
import { logoBase64 } from './logoBase64.js';

const Header = () => {
  const redirectToUrl = () => {
    window.location.href = 'https://iris-works.com';
  };

  return (
    <img
      src={logoBase64}
      className={styles.Header}
      onClick={redirectToUrl}
    ></img>
  );
};

export default Header;
