import React from 'react';
import styles from './ProgressBar.module.scss';
import ProgressBarStep from './ProgressBarStep';
import PropTypes from 'prop-types';
import { uniqueId } from '~utils';
import { useWindowSize } from '~hooks';
import { MOBILE_WIDTH } from '~constants/viewports';

const ProgressBar = ({ steps }) => {
  const activeStep = steps.find(step => step.active);
  const activeStepIndex = steps.findIndex(step => step.active);
  let windowWidth = useWindowSize()[0];
  return (
    <div className={styles.ProgressBar}>
      <div>
        {windowWidth <= MOBILE_WIDTH && (
          <div className={styles['ProgressBar-mobileStep']}>
            <hr />
            <ProgressBarStep
              active={true}
              complete={false}
              isLastStep={activeStepIndex === steps.length - 1}
              label={activeStep.label}
              stepsCount={steps.length}
            />
            {activeStepIndex === steps.length - 1 && <hr />}
          </div>
        )}
        {windowWidth > MOBILE_WIDTH &&
          steps.map((step, index) => (
            <ProgressBarStep
              active={step.active}
              complete={index <= activeStepIndex - 1}
              isLastStep={index === steps.length - 1}
              onClickCallback={step.onClick && step.onClick}
              label={step.label}
              stepsCount={steps.length}
              key={uniqueId(`progressBarStep-${index}`)}
            />
          ))}
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      active: PropTypes.bool,
    })
  ).isRequired,
};

export default ProgressBar;
