import React from 'react';
import { TextInput } from '~ui';
import styles from './FieldName.module.scss';

const FieldName = ({ onChangeCallback, initialValue, errors }) => {
  return (
    <TextInput
      className={styles.FieldNameStyles}
      name="fieldName"
      initialValue={initialValue || ''}
      onChangeCallback={onChangeCallback}
      placeholder="Enter Field Name"
      setWarningForEmpty={errors}
    />
  );
};

export default FieldName;
