import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import styles from './JourneyTemplates.module.scss';
import { SearchInput } from '~ui';
import { uniqueId } from '~utils';
import {
  deleteJourneyTemplate,
  getJourneyTemplates,
} from '~actions/journeyTemplates';
import { createReminderTemplate } from '~actions/journeyTemplate';
import { routes, absolutePath } from '~constants/routes';
import ToggleContent from '~components/common/ToggleContent';
import JourneyTemplatesDeleteModal from './JourneyTemplatesDeleteModal';
import JourneyTemplatesCreateModal from './JourneyTemplatesCreateModal';
import CannotDeleteJourneyTemplateModal from './CannotDeleteJourneyTemplateModal';

const JourneyTemplates = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const journeyTemplates = useSelector(
    state => state.journeyTemplates.templates
  );
  const incompleteJourneys = useSelector(
    state => state.journeyTemplates.incompleteJourneys
  );
  const asyncDelete = async id => {
    await dispatch(deleteJourneyTemplate(id));
    dispatch(getJourneyTemplates());
  };

  const handleDelete = id => {
    asyncDelete(id);
  };

  const currentStudioId = useSelector(state => state.journeyTemplates.studioId);

  const handleCreate = (name, steps_attributes = null) => {
    if (!name) {
      toast.info('Please provide a Workflow Name');
      return;
    } else if (
      journeyTemplates.filter(template => template.attributes.name === name)
        .length > 0
    ) {
      toast.info('Please use a unique Workflow Name');
      return;
    } else {
      const params = {
        journey_template: {
          name: name,
          studio_id: currentStudioId,
          steps_attributes: steps_attributes,
        },
      };
      axios.post(routes.JOURNEY_TEMPLATES.CREATE, params).then(res => {
        const journeyTemplateId = res.data.data.attributes.id;
        window.location.href = absolutePath(
          `/studio/workflows/${journeyTemplateId}`
        );
      });
    }
  };

  const getNewReminderTemplates = steps => {
    const reminderTemplatePromises = steps.map(async step => ({
      stepId: step.id,
      reminderTemplateId: await dispatch(
        createReminderTemplate(step.resource.description, currentStudioId)
      ),
    }));
    return Promise.all(reminderTemplatePromises);
  };

  const handleDuplicate = async workflow => {
    let isError = false;
    setIsLoading(true);
    const newReminderTemplates = await getNewReminderTemplates(
      workflow.attributes.steps.filter(
        step => step.resource_type == 'ReminderTemplate'
      )
    );
    const stepAttributes = workflow.attributes.steps.map(step => {
      try {
        return {
          name: step.name,
          journey_action_id: step.action.id,
          journey_trigger_id: step.trigger.id,
          resource_id:
            step.resource_type == 'ReminderTemplate'
              ? newReminderTemplates.find(
                  reminderTemplate => reminderTemplate.stepId == step.id
                ).reminderTemplateId
              : step.resource.id,
          resource_type: step.resource_type,
          time_offset: step.time_offset,
          order: step.order,
        };
      } catch (error) {
        isError = true;
        setIsLoading(false);
        toast.error(
          `Unable to duplicate workflow, '${step.name}' step contains an Email Template that has been deleted`,
          toastOptions
        );
      }
    });

    if (!isError) {
      handleCreate(`Copy of - ${workflow.attributes.name}`, stepAttributes);
    }
  };

  const [pageOffset, setPageOffset] = useState(0);

  const sortedJourneyTemplates = journeyTemplates.sort((a, b) =>
    a.attributes.name > b.attributes.name ? 1 : -1
  );

  const offsetJourneyTemplates = sortedJourneyTemplates.slice(
    pageOffset,
    pageOffset + 10
  );

  const navPageOffset = e => {
    const arrow = e.target.className;
    if (arrow.includes('right')) {
      if (pageOffset + 10 < journeyTemplates.length) {
        setPageOffset(pageOffset + 10);
      }
    } else {
      if (pageOffset - 10 >= 0) {
        setPageOffset(pageOffset - 10);
      }
    }
  };

  const filterJourneyTemplates = value => {
    dispatch(getJourneyTemplates({ search_term: value }));
  };

  const leftNav = journeyTemplates.length > 0 ? pageOffset + 1 : 0;

  const setRightNav = pageOffset => {
    let rightNav = 0;
    if (pageOffset + 10 < journeyTemplates.length) {
      rightNav = pageOffset + 10;
    } else {
      rightNav = journeyTemplates.length;
    }
    return rightNav;
  };

  return (
    <div className={styles.JourneyTemplates}>
      {isLoading && (
        <div className={styles['JourneyTemplates-loading']}>
          <div>Loading...</div>
        </div>
      )}
      <div className={styles['JourneyTemplates-headerBar']}>
        <div className={styles['JourneyTemplates-headerBar-header']}>
          My Workflows
        </div>
        <div className={styles['JourneyTemplates-headerBar-rightSide']}>
          <SearchInput
            name="searchWorkflows"
            className={styles['JourneyTemplates-headerBar-rightSide-searchBar']}
            placeholder="Search Workflows"
            initialValue=""
            onChangeCallback={filterJourneyTemplates}
          />
          <ToggleContent
            toggle={handleShow => (
              <button
                className={
                  styles[
                    'JourneyTemplates-headerBar-rightSide-createWorkflowButton'
                  ]
                }
                onClick={handleShow}
              >
                Create Workflow
              </button>
            )}
            content={handleHide => (
              <JourneyTemplatesCreateModal
                handleHide={handleHide}
                handleCreate={handleCreate}
              />
            )}
          />
        </div>
      </div>
      <a
        className={styles['JourneyTemplates-marketplaceLink']}
        href="https://iris-works.com/marketplace/"
        target="_blank"
      >
        Need a workflow? Click here to view curated workflows from industry
        leaders
      </a>
      <div className={styles['JourneyTemplates-table']}>
        <div className={styles['JourneyTemplates-table-title']}>Name</div>
        {offsetJourneyTemplates.map(workflow => {
          let activeJourney = false;
          let incompleteJourneysArray = [];

          if (incompleteJourneys.length > 0) {
            incompleteJourneys.map(incompleteJourney => {
              if (
                incompleteJourney.journey_template_id == workflow.attributes.id
              ) {
                activeJourney = true;
                incompleteJourneysArray.push({
                  title: incompleteJourney.calendar_event_title,
                });
              }
            });
          }

          return (
            <div
              className={styles['JourneyTemplates-table-entry']}
              key={uniqueId('workflow_')}
            >
              <a
                className={styles['JourneyTemplates-table-entry-name']}
                href={`/studio/workflows/${workflow.attributes.id}`}
              >
                {workflow.attributes.name}
              </a>
              <div className={styles['JourneyTemplates-table-entry-icons']}>
                <a onClick={() => handleDuplicate(workflow)}>Duplicate</a>
                <a
                  className="fas fa-pencil-alt c-mg"
                  href={`/studio/workflows/${workflow.attributes.id}`}
                />
                <ToggleContent
                  toggle={handleShow => (
                    <div className="fas fa-trash-alt" onClick={handleShow} />
                  )}
                  content={handleHide => (
                    <>
                      {!activeJourney && (
                        <JourneyTemplatesDeleteModal
                          handleHide={handleHide}
                          handleDelete={() =>
                            handleDelete(workflow.attributes.id)
                          }
                          name={workflow.attributes.name}
                        />
                      )}
                      {activeJourney && (
                        <CannotDeleteJourneyTemplateModal
                          handleHide={handleHide}
                          incompleteJourneysArray={incompleteJourneysArray}
                        />
                      )}
                    </>
                  )}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles['JourneyTemplates-workflowCount']}>
        <div>
          {leftNav} - {setRightNav(pageOffset)} of {journeyTemplates.length}
        </div>
        <div
          className="fas fa-chevron-circle-left c-mg"
          onClick={e => navPageOffset(e)}
        />
        <div
          className="fas fa-chevron-circle-right c-mg"
          onClick={e => navPageOffset(e)}
        />
      </div>
    </div>
  );
};

export default JourneyTemplates;
