import React, { useState } from 'react';
import { Modal, Button, TextInput } from '~ui';
import styles from './SessionTypesModal.module.scss';

const SessionTypesModal = ({ handleNewSessionType, handleHide }) => {
  const [sessionTypeName, setSessionTypeName] = useState('');

  return (
    <Modal title="Add your own session type">
      <div className={styles.SessionTypesModal}>
        <TextInput
          labelText="Session Type Name"
          name="sessionTypeName"
          onChangeCallback={setSessionTypeName}
        />
        <div>
          <Button
            text="+ Add"
            onClick={() => {
              handleNewSessionType(sessionTypeName);
              handleHide();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SessionTypesModal;
