import React from 'react';
import styles from './LogoLayout.module.scss';
import SessionTypeIcon from '../SessionTypeIcon';
import Form from '../Form';
import { Dropdown } from '~ui';

const LogoLayout = props => {
  const {
    displayLogo,
    isSubmitted,
    headline,
    displaySessionIcons,
    sessionTypes,
    dropdownOptions,
    chosenSessionTypeIds,
    setChosenSessionTypeIds,
    setChosenSessionTypeNames,
    handleSessionType,
    chosenSessionTypeNames,
    submitColor,
    sitekey,
    studioId,
    setIsSubmitted,
    logo,
    customFields,
    transparentBackground,
    showEmailMarketing,
    redirectURL,
  } = props;

  const handleSessionTypeChange = (val) => {
    const selectedValue = parseInt(val, 10);

    if (selectedValue === 0) {
      setChosenSessionTypeIds([]);
      setChosenSessionTypeNames([]);
    } else {
      setChosenSessionTypeIds([val]);
      setChosenSessionTypeNames([
        sessionTypes.find(st => val == st.id).name,
      ]);
    }
  }

  return (
    <div
      className={
        styles[transparentBackground ? 'Transparent' : 'NonTransparent']
      }
    >
      <div className={styles.LogoLayout}>
        {displayLogo && (
          <div className={styles['LogoLayout-logo']}>
            <img src={logo} alt="logo" />
          </div>
        )}
        {!isSubmitted ? (
          <>
            <div className={styles['LogoLayout-sessionTypes']}>
              <h1>{headline}</h1>
              {displaySessionIcons ? (
                <div className={styles['LogoLayout-sessionTypes-icons']}>
                  {sessionTypes.map((st, i) => (
                    <SessionTypeIcon
                      key={i}
                      {...{
                        chosenSessionTypeIds,
                        handleSessionType,
                        st,
                      }}
                    />
                  ))}
                </div>
              ) : (
                <div className={styles['LogoLayout-sessionTypes-dropdown']}>
                  <Dropdown
                    options={dropdownOptions}
                    onChangeCallback={handleSessionTypeChange}
                    name="sessionTypeDropdown"
                  />
                </div>
              )}
            </div>
            <Form
              {...{
                chosenSessionTypeIds,
                chosenSessionTypeNames,
                submitColor,
                sitekey,
                studioId,
                setIsSubmitted,
                customFields,
                showEmailMarketing,
                redirectURL,
              }}
            />
          </>
        ) : (
          <div className={styles['LogoLayout-submitted']}>
            <h1>Thank you!</h1>
            <p>I'll be in touch soon with next steps on your session!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default LogoLayout;
