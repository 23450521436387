import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from '~utils';
import TableCell from './TableCell';

const TempTableRow = props => {
  const {
    row,
    handleCreate,
    handleRemove,
    rowId,
    invoiceTemplateScheduleTrigger,
  } = props;
  const renderCells = () =>
    row.slice(0, -1).map(cell => {
      const cellId = uniqueId('cell_');

      return (
        <TableCell
          key={cellId}
          cell={cell}
          isEditing
          invoiceTemplateScheduleTrigger={invoiceTemplateScheduleTrigger}
          rowId={rowId}
        />
      );
    });

  const getParams = () => {
    const inputs = document.querySelectorAll('input,textarea,select');

    return Array.from(inputs).reduce((acc, input) => {
      if (input.type === 'checkbox') {
        acc[input.name] = input.checked;
      } else {
        acc[input.name] = input.value;
      }

      return acc;
    }, {});
  };

  const createAndRemove = () => {
    const params = getParams();
    handleCreate(params);
    handleRemove();
  };

  return (
    <tr id={rowId}>
      {renderCells()}
      <>
        <TableCell
          cell={row[row.length - 1]}
          isEditing
          invoiceTemplateScheduleTrigger={invoiceTemplateScheduleTrigger}
          rowId={rowId}
        >
          <div className="mn-w-94">
            <a onKeyPress={createAndRemove} onClick={createAndRemove}>
              <i className="fa fa-check" />
            </a>
            <a onKeyPress={() => handleRemove()} onClick={() => handleRemove()}>
              <i className="fa fa-times" />
            </a>
          </div>
        </TableCell>
      </>
    </tr>
  );
};

TempTableRow.propTypes = {
  row: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleCreate: PropTypes.func,
  handleRemove: PropTypes.func,
};

TempTableRow.defaultProps = {
  handleCreate: () => {},
  handleRemove: () => {},
};

export default TempTableRow;
