import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import {
  getMergedCalendarEventsByDate,
  selectedDaySelector,
  multiWeekEventsSelectorMonthView,
} from '~selectors';
import { get } from '~utils';
import {
  DATE_KEY_FORMAT,
  WEEKDAY_DATE_FORMAT,
  TIME_FORMAT,
} from '~constants/datetime';
import CalendarEvent from '../CalendarEvent';
import CronofyEvent from '../CronofyEvent';
import SUNRISE_ICON from '~images/sunrise.svg';
import SUNSET_ICON from '~images/sunset.svg';
import styles from './CalendarEventPane.module.scss';

const CalendarEventPane = () => {
  const selectedDay = useSelector(selectedDaySelector);
  const dateKey = moment(selectedDay).clone().format(DATE_KEY_FORMAT);
  let allEvents = useSelector(state =>
    getMergedCalendarEventsByDate(state, dateKey)
  );
  const { sunrise, sunset } = useSelector(state => state.calendar.sun);
  const multiWeekEvents = useSelector(multiWeekEventsSelectorMonthView);
  const events = [...allEvents, ...get(multiWeekEvents, [dateKey], [])];
  const sortedEvents = events.sort((eventA, eventB) => {
    let startA, startB;

    if (eventA.event_uid) {
      startA = eventA.start.time;
    } else {
      startA = eventA.start_time;
    }

    if (eventB.event_uid) {
      startB = eventB.start.time;
    } else {
      startB = eventB.start_time;
    }

    return Date.parse(startA) - Date.parse(startB);
  });

  let customStyles = { position: 'relative' };

  return (
    <div className={styles.CalendarEventPane}>
      <h2>{moment(selectedDay).format(WEEKDAY_DATE_FORMAT)}</h2>
      {sunrise && sunset && (
        <div className={styles['CalendarEventPane-sun']}>
          <div>
            <img src={SUNRISE_ICON} height="24" width="auto" />
            <p>{moment(sunrise).format(TIME_FORMAT)}</p>
          </div>
          <div>
            <img src={SUNSET_ICON} height="24" width="auto" />
            <p>{moment(sunset).format(TIME_FORMAT)}</p>
          </div>
        </div>
      )}
      {sortedEvents &&
        sortedEvents.map(event => {
          if (event.event_uid) {
            return (
              <CronofyEvent
                event={event}
                key={event.event_uid}
                customStyles={customStyles}
                tall
              />
            );
          }

          if (event.id) {
            return (
              <CalendarEvent
                event={event}
                key={event.id}
                customStyles={customStyles}
                tall
              />
            );
          }
          return;
        })}
      {!sortedEvents && <h3>You have no events on this day.</h3>}
    </div>
  );
};

export default CalendarEventPane;
