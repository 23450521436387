import React, { useState, useEffect } from 'react';
import { Modal, Button, TextInput, Dropdown } from '~ui';
import axios from 'axios';
import { routes } from '~constants/routes';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { emailValidation, phoneValidation } from '~constants/validation';

const ProfileEditModal = ({
  handleHide,
  studioId,
  spudUserId,
  countryOptions,
  updateSpudUserProps,
  updateStudioProps,
  studio,
  spudUser,
}) => {
  const [companyName, setCompanyName] = useState('');
  const [website, setWebsite] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [phone, setPhone] = useState('');
  const [stateOptions, setStateOptions] = useState([]);

  useEffect(() => {
    if (studio.country !== null) {
      handleCountryDropdownChange(studio.country);
    }
  }, []);

  const updateStudio = id => {
    const studioParams = {
      name: companyName,
      website: website,
      address: address,
      city: city,
      state: state,
      postal: zipCode,
      country: country,
      phone: phone
    };

    const filteredParams = Object.fromEntries(
      Object.entries(studioParams).filter(([_, value]) => value !== undefined)
    );

    if (Object.keys(filteredParams).length !== 0) {
      const params = { studio: filteredParams };

      axios.put(routes.STUDIO_PROFILE.UPDATE(id), params)
        .then(res => {
          updateStudioProps(res);
        })
        .catch(error => {
          console.log(error);
          const errorMessage = error.response?.data?.error || 'Error updating studio profile';
          toast.error(errorMessage, toastOptions);
        }
        );
    }
  };

  const updateSpudUser = id => {
    const spudUserParams = {
      first_name: firstName,
      last_name: lastName,
      email: email,
    };

    const filteredParams = Object.fromEntries(
      Object.entries(spudUserParams).filter(([_, value]) => value !== undefined)
    );

    if (Object.keys(filteredParams).length !== 0) {
      const params = { spud_user: filteredParams };

      axios.put(routes.SPUD_USER.UPDATE(id), params)
        .then(res => {
          updateSpudUserProps(res);
        })
        .catch(error => {
          console.log(error);
          const errorMessage = error.response?.data?.error || 'Error updating studio profile';
          toast.error(errorMessage, toastOptions);
        }
        );
    }
  };

  const updateAxios = (studioId, spudUserId) => {
    updateStudio(studioId);
    updateSpudUser(spudUserId);
  };

  const handleCountryDropdownChange = alpha2 => {
    if (alpha2 === 'Select a country') {
      setCountry(null);
      setStateOptions([{ label: 'Choose a State/Province', value: null }]);
      return;
    }

    setCountry(alpha2);

    axios
      .get(`/api/states/${alpha2}`)
      .then(res => {
        let options = res.data.map(state => ({
          label: state[1],
          value: state[0],
        }));
        options = [{ label: 'Choose a State/Province', value: null }].concat(
          options
        );
        setStateOptions(options);
      })
      .catch(error => {
        const errorMessage = `Error fetching states for ${alpha2}: ${error.response.data.error.messages}`;
        toast.error(errorMessage, toastOptions);
        Rollbar.error(`${errorMessage}: ${error}`);
      });
  };

  return (
    <Modal
      title="Edit Profile"
      handleHide={handleHide}
      studioId={studioId}
      spudUserId={spudUserId}
      countryOptions={countryOptions}
      studio={studio}
      spudUser={spudUser}
    >
      <div className="mb-8">Company Name</div>
      <TextInput
        name="companyProfileInput"
        onChangeCallback={setCompanyName}
        initialValue={studio.name || ''}
      />
      <div className="mb-8 mt-8">Website</div>
      <TextInput
        name="websiteInput"
        onChangeCallback={setWebsite}
        initialValue={studio.website || ''}
      />
      <div className="d-f">
        <div className="fg-1 mr-12">
          <div className="mb-8 mt-8">First Name</div>
          <TextInput
            name="firstNameInput"
            onChangeCallback={setFirstName}
            initialValue={spudUser.first_name || ''}
          />
        </div>
        <div className="fg-1">
          <div className="mb-8 mt-8">Last Name</div>
          <TextInput
            name="lastNameInput"
            onChangeCallback={setLastname}
            initialValue={spudUser.last_name || ''}
          />
        </div>
      </div>
      <div className="mb-8 mt-8">Email</div>
      <TextInput
        name="emailInput"
        onChangeCallback={setEmail}
        validation={emailValidation}
        initialValue={spudUser.email || ''}
      />
      <div className="mb-8 mt-8">Country</div>
      <Dropdown
        name="country"
        options={countryOptions}
        initialValue={studio.country || ''}
        onChangeCallback={handleCountryDropdownChange}
      />
      <div className="d-f">
        <div className="fg-1 mr-12">
          <div className="mb-8 mt-8">Address</div>
          <TextInput
            name="addressInput"
            onChangeCallback={setAddress}
            initialValue={studio.address || ''}
          />
        </div>
        <div className="fg-1">
          <div className="mb-8 mt-8">City</div>
          <TextInput
            name="cityInput"
            onChangeCallback={setCity}
            initialValue={studio.city || ''}
          />
        </div>
      </div>
      <div className="d-f">
        <div className="fg-1 mr-12">
          <div className="mb-8 mt-8">State</div>
          <Dropdown
            name="state_province"
            options={stateOptions}
            initialValue={studio.state || ''}
            onChangeCallback={setState}
            disabled={stateOptions.length === 0}
          />
        </div>
        <div className="fg-1">
          <div className="mb-8 mt-8">Zip Code</div>
          <TextInput
            name="zipCodeInput"
            onChangeCallback={setZipCode}
            initialValue={studio.postal || ''}
          />
        </div>
      </div>
      <div className="mb-8 mt-8">Phone Number</div>
      <TextInput
        name="phoneNumberInput"
        onChangeCallback={setPhone}
        initialValue={studio.phone || ''}
        validation={phoneValidation}
        type="tel"
      />
      <div className="d-fr mt-8">
        <Button className="mr-8" text="Cancel" onClick={handleHide} danger />
        <Button
          text="Update"
          onClick={() => {
            updateAxios(studioId, spudUserId);
            handleHide();
          }}
        />
      </div>
    </Modal>
  );
};

export default ProfileEditModal;
