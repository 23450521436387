import React from 'react';
import {
  IntroductionInput,
  DateInput,
  DropdownInput,
  AddressInput,
  PhoneNumberInput,
  EmailInput,
  TempInput,
  RadioButtonInput,
  MultipleCheckInput,
  CheckBoxInput,
  TextFieldInput,
  CommentFieldInput,
} from '../Inputs';

/*

The data object represented in each of these is the USER EDITABLE parts of the input, some will
have an options array, some will not. Some will have a client_mapping, some will not, etc.

Here are the possible fields that can be added to the data object: 

label
sort_order (no need to add this, this is handled by the handleSave method in /InputContainer)
input_type (I've already added these)
is_required
client_mapping
options

Reference app/controllers/api/questionnaires_controller.rb q_params method

*/

export const INPUTS = [
  {
    id: 'temp',
    title: '',
    InputComponent: TempInput,
    data: { input_type: 'temp' },
  },
  {
    id: 1,
    title: 'Introduction',
    InputComponent: IntroductionInput,
    data: {
      input_type: 'static_text',
      label: '',
    },
  },
  {
    id: 2,
    title: 'Text Field',
    InputComponent: TextFieldInput,
    data: {
      input_type: 'text',
      label: '',
      is_required: false,
    },
  },
  {
    id: 3,
    title: 'Comment Field',
    InputComponent: CommentFieldInput,
    data: {
      input_type: 'comment',
      is_required: false,
    },
  },
  {
    id: 4,
    title: 'Check Box',
    InputComponent: CheckBoxInput,
    data: {
      input_type: 'check',
      is_required: false,
      label: '',
    },
  },
  {
    id: 5,
    title: 'Multiple Check',
    InputComponent: MultipleCheckInput,
    data: {
      input_type: 'multi_check',
      is_required: false,
      label: '',
      options: [''],
    },
  },
  {
    id: 6,
    title: 'Radio Button',
    InputComponent: RadioButtonInput,
    data: {
      input_type: 'radio',
      is_required: false,
      label: '',
      options: [''],
    },
  },
  {
    id: 7,
    title: 'Dropdown List',
    InputComponent: DropdownInput,
    data: {
      input_type: 'select',
      label: '',
      options: [''],
    },
  },
  {
    id: 8,
    title: 'Address',
    InputComponent: AddressInput,
    data: {
      input_type: 'address',
      label: '',
      is_required: false,
    },
  },
  {
    id: 9,
    title: 'Phone Number',
    InputComponent: PhoneNumberInput,
    data: {
      input_type: 'phone',
      label: '',
      is_required: false,
    },
  },
  {
    id: 10,
    title: 'Email Address',
    InputComponent: EmailInput,
    data: {
      input_type: 'email',
      label: '',
      is_required: false,
    },
  },
  {
    id: 11,
    title: 'Date',
    InputComponent: DateInput,
    data: {
      input_type: 'date',
      label: '',
      is_required: false,
    },
  },
];
