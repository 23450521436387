import React, { useRef, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { uniqueId } from '~utils';
import { KeydownListener, Icon } from '~ui';
import { keyCodes } from '~constants/keyCodes';
import { getForm } from './forms';
import styles from './AutofillSearch.module.scss';

const AutofillSearch = props => {
  const {
    disabled,
    labelText,
    name,
    onChangeCallback,
    required,
    className,
    options,
    placeholder,
    withCreateForm,
    resource,
  } = props;
  const hasClassName = !!className;
  const cx = classNames.bind(styles);
  const classes = cx('AutofillSearch', {
    'AutofillSearch--disabled': disabled,
    [className]: hasClassName,
  });
  const [value, setValue] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState();
  const [isFocused, setIsFocused] = useState(false);
  const [indexOfFocusedOption, setIndexOfFocusedOption] = useState(null);
  const optionsRef = useRef(null);

  useLayoutEffect(() => {
    const optionToFocus = options[indexOfFocusedOption];
    if (optionToFocus && optionToFocus.ref && optionToFocus.ref.current) {
      optionToFocus.ref.current.focus();
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [indexOfFocusedOption, isFocused]);

  const handleClickOutside = e => {
    if (optionsRef.current && optionsRef.current.contains(e.target)) {
      return;
    }

    closeOptions();
  };

  const handleOnChange = e => {
    const { value } = e.target;
    setValue(value);
    setShowOptions(true);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(setTimeout(() => {}, 500));
  };

  const closeOptions = () => {
    setShowOptions(false);
    setIsFocused(true);
    setIndexOfFocusedOption(null);
  };

  const menuOptionKeyDownListener = (e, optionRef) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.keyCode === keyCodes.ENTER || e.keyCode === keyCodes.SPACE) {
      optionRef.current.click();
    }
  };

  const focusPreviousOption = () => {
    if (indexOfFocusedOption === options.length - 1) {
      setIndexOfFocusedOption(0);
    } else {
      setIndexOfFocusedOption(indexOfFocusedOption + 1);
    }
  };

  const focusNextOption = () => {
    if (indexOfFocusedOption === 0) {
      setIndexOfFocusedOption(options.length - 1);
    } else {
      setIndexOfFocusedOption(indexOfFocusedOption - 1);
    }
  };

  // Add scroll to options.
  const renderOptions = () => {
    const filteredOptions = options.filter(option => option.label.match(value));
    return (
      <ul ref={optionsRef} aria-labelledby="searchBar">
        {filteredOptions.length > 0 &&
          filteredOptions.map(({ label, value, ref }) => (
            <li
              key={uniqueId('AutofillSearch-option')}
              onClick={() => {
                onChangeCallback(value);
                setValue(label);
                closeOptions();
              }}
              onKeyDown={e => {
                menuOptionKeyDownListener(e, ref);
              }}
              ref={ref}
              tabIndex="-1"
            >
              {label}
            </li>
          ))}
        {filteredOptions.length === 0 && <li>No matching results.</li>}
        {withCreateForm && (
          <li
            onClick={() => {
              setShowCreateForm(true);
            }}
          >
            Create new <Icon name="plus" small />
          </li>
        )}
      </ul>
    );
  };

  return (
    <>
      {!showCreateForm && (
        <div className={classes}>
          {labelText && (
            <label htmlFor={name}>
              {labelText}
              {required && <span>*</span>}
            </label>
          )}
          <input
            id="searchBar"
            name={name}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            required={required}
            onChange={e => handleOnChange(e)}
            aria-haspopup="true"
            aria-expanded={showOptions}
            role="searchbox"
          />
          {showOptions && (
            <>
              {renderOptions()}
              <KeydownListener
                keyCode={keyCodes.ESCAPE}
                onKeyDown={closeOptions}
              />
              <KeydownListener
                keyCode={keyCodes.ARROW_DOWN}
                onKeyDown={focusPreviousOption}
              />
              <KeydownListener
                keyCode={keyCodes.ARROW_UP}
                onKeyDown={focusNextOption}
              />
            </>
          )}
        </div>
      )}
      {resource && showCreateForm && (
        <div className={styles.CreateForm}>
          {React.createElement(getForm(resource), {
            cancelCallback: () => setShowCreateForm(false),
          })}
        </div>
      )}
    </>
  );
};

AutofillSearch.propTypes = {
  disabled: PropTypes.bool,
  labelText: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChangeCallback: PropTypes.func,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  withCreateForm: PropTypes.bool,
  resource: PropTypes.string,
};

AutofillSearch.defaultProps = {
  disabled: false,
  labelText: null,
  onChangeCallback: () => {},
  required: false,
  placeholder: '',
  withCreateForm: false,
  resource: null,
};

export default AutofillSearch;
