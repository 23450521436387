import React from 'react';
import PT from 'prop-types';
import Dropzone from 'react-dropzone';
import Request from 'superagent';
import ItemsList from '../uploader/items_list';
import Pagination from '../uploader/pagination';

const MAX_UPLOAD_MB = 20;
const MAX_UPLOAD_BYTES = MAX_UPLOAD_MB * 1000000;
const DOCUMENT_TYPE = [
  //Microsoft
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',

  //Documents
  'application/pdf',
  'text/html',
  'text/plain',
  'text/rtf',
  'application/xml',

  //Archives
  'application/x-compressed',
  'application/x-tar',
  'application/x-gtar',
  'application/x-gzip',
  'application/zip',
  'application/gzip',
  'application/zip',

  //Images
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',

  //Adobe
  'image/vnd.adobe.photoshop',
  'application/postscript',
  'application/x-shockwave-flash',
];

class Uploader extends React.PureComponent {
  static propTypes = {
    addAttachment: PT.func.isRequired,
    reloadAttachments: PT.func.isRequired,
  };
  constructor() {
    super();

    this.state = {
      files: [],
      currentPage: 1,
      totalPages: 1,
      filesPerPage: 10,
      generic: 1,
    };
  }

  onDrop = files => {
    const validFiles = [];

    const previewFiles = files.map(file => {
      const tempFile = { file: { name: file.name }, id: '' };

      if (file.size <= MAX_UPLOAD_BYTES) {
        validFiles.push(file);
        return tempFile;
      }

      //If file size isn't valid
      tempFile.error = `must be less than ${MAX_UPLOAD_MB} MB`;
      return tempFile;
    });

    this.setState(prevState => ({
      files: previewFiles.concat(prevState.files),
      currentPage: 1,
    }));

    if (validFiles.length) {
      const req = Request.post('/api/documents/create_multiple');

      validFiles.forEach(file => {
        req.attach('documents[]', file);
      });
      req.end(() => {
        this.setState(prevState => {
          const updatedFiles = prevState.files.map(file => {
            if (previewFiles.indexOf(file) === -1) {
              return file;
            }
            return { ...file, created_at: new Date() };
          });
          return {
            files: updatedFiles,
          };
        });
        this.props.reloadAttachments();
      });
    }
  };

  render() {
    return (
      <section>
        <Dropzone onDrop={this.onDrop} accept={DOCUMENT_TYPE.join()}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="dropzone">
              <input {...getInputProps()} />
              <p>
                You can drag & drop some files here, or simply click this box to
                select files from your computer to upload.
              </p>
            </div>
          )}
        </Dropzone>
        <ItemsList
          addAttachment={this.props.addAttachment}
          items={this.state.files}
          invalidItems={this.state.invalidFiles}
        />
      </section>
    );
  }
}

export default Uploader;
