import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './SidebarMenu.module.scss';
import { Icon } from '~ui';

const MenuItem = ({ active, labelText, onClickCallback, icon, disabled }) => {
  const cx = classNames.bind(styles);
  const classes = cx('MenuItem', {
    'MenuItem--active': active,
    'MenuItem--disabled': disabled,
  });

  return (
    <li
      className={classes}
      role="none"
      onClick={() => {
        if (active || disabled) {
          return;
        }
        onClickCallback();
      }}
    >
      <a role="menuitem">
        <span>{labelText}</span>
        {icon && <Icon name={icon} />}
      </a>
    </li>
  );
};

MenuItem.propTypes = {
  active: PropTypes.bool,
  labelText: PropTypes.string.isRequired,
  onClickCallback: PropTypes.func.isRequired,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
};

MenuItem.defaultProps = {
  active: false,
  icon: undefined,
  disabled: false,
};

export default MenuItem;
