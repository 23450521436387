import React from 'react';
import InvoiceModal from '~components/Invoice/InvoiceModal';
import ToggleContent from '~components/common/ToggleContent';
import { Button } from '~ui';

const NewInvoiceModal = ({ invoiceProducts }) => {
  return (
    <ToggleContent
      toggle={handleShow => (
        <Button
          type="button"
          className="btn btn-primary"
          onClick={handleShow}
          text={'Invoice'}
          disabled={invoiceProducts.length < 1}
          whiteRectangle={true}
          icon="plus"
          style={{ height: '36px' }}
        />
      )}
      content={handleHide => <InvoiceModal handleHide={handleHide} />}
    />
  );
};

export default NewInvoiceModal;
