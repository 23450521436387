import React, { useState, Fragment } from 'react';
import Option from './Option';
import styles from './Sidebar.module.scss';
import { uniqueId } from '~utils';

const Sidebar = ({ options }) => {
  const getExpanded = option =>
    option.expanded || (option.subOptions && option.active);

  const Sidebar = styles.NewSidebar;

  return (
    <div className={Sidebar}>
      {options.map((option, index) => (
        <Fragment key={uniqueId(`option${index}_`)}>
          <Option
            title={option.title}
            onClickCallback={() => option.onClickCallback()}
            isActive={option.active}
            defaultExpanded={option.expanded}
            noChevron={option.noChevron}
          />
          {getExpanded(option) &&
            option.subOptions.map((subOption, index) => (
              <Option
                key={uniqueId(`subOption${index}_`)}
                title={subOption.title}
                onClickCallback={() => subOption.onClickCallback()}
                isActive={subOption.active}
                isSub
              />
            ))}
        </Fragment>
      ))}
    </div>
  );
};

export default Sidebar;
