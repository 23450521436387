import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../modal/modal';
import SquareCheckout from './square_checkout';

class SquareCheckoutModal extends React.PureComponent {
  static propTypes = {
    invoice_id: PropTypes.number,
    application_id: PropTypes.string,
    location_id: PropTypes.string,
  };

  render() {
    return (
      <Modal title={'Pay with Credit Card'} buttonLabel={'Pay with Square'}>
        {() => (
          <SquareCheckout
            invoiceId={this.props.invoice_id}
            applicationId={this.props.application_id}
            locationId={this.props.location_id}
          />
        )}
      </Modal>
    );
  }
}

export default SquareCheckoutModal;
