import React, { useState } from 'react';
import styles from './SessionTypesModal.module.scss';
import { Modal, TextInput, Dropdown, Button, Toggle } from '~ui';
import ImageUploading from 'react-images-uploading';
import axios from 'axios';
import { routes } from '~constants/routes';
import { ChromePicker } from 'react-color';
import { Icon } from '~ui';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';

const SessionTypesModal = props => {
  const {
    sessionType,
    isNew,
    handleHide,
    journeyTemplatesOptions,
    emailTemplatesOptions,
    getSessionTypes,
  } = props;

  const [iconFileName, setIconFileName] = useState(
    sessionType ? sessionType.icon_file_name : ''
  );
  const [icon, setIcon] = useState();
  const [showPicker, setShowPicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(
    sessionType || {
      id: null,
      name: '',
      default_journey_template: { id: 0 },
      color: '#3a87ad',
      lead_response_email_template: { id: 0 },
      show_on_lead_form: false,
    }
  );
  const MAX_UPLOAD_MB = 20;
  const MAX_UPLOAD_BYTES = MAX_UPLOAD_MB * 1000000;

  const newSessionType = async () => {
    try {
      setLoading(true);
      await axios.post(routes.SESSION_TYPES.CREATE, {
        session_type: {
          ...formData,
          lead_response_email_template_id:
            formData.lead_response_email_template.id,
          journey_template_id: formData.default_journey_template.id,
          icon: icon,
          icon_file_name: iconFileName,
        },
      });

      getSessionTypes();
      setLoading(false);
      handleHide();
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error("Error creating session type", toastOptions);
    }
  };

  const updateSessionType = async () => {
    try {
      setLoading(true);
      await axios.put(routes.SESSION_TYPES.UPDATE(formData.id), {
        session_type: {
          ...formData,
          lead_response_email_template_id:
            formData.lead_response_email_template &&
            formData.lead_response_email_template.id,
          journey_template_id:
            formData.default_journey_template &&
            formData.default_journey_template.id,
          icon: icon,
          icon_file_name: iconFileName,
        },
      });

      getSessionTypes();
      setLoading(false);
      handleHide();
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <Modal
      handleHide={handleHide}
      title={isNew ? 'New Session Type' : 'Edit Session Type'}
    >
      {loading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingText}>
            <p>Loading...</p>
            <Icon name="spinner" className="fa-pulse" large />
          </div>
        </div>
      )}
      <div className={styles.SessionTypesModal}>
        <TextInput
          labelText="Name"
          name="name"
          initialValue={formData.name}
          onChangeCallback={value => setFormData({ ...formData, name: value })}
        />

        <Dropdown
          labelText="Default Workflow"
          name="journeyTemplate"
          options={journeyTemplatesOptions}
          initialValue={
            formData.default_journey_template
              ? formData.default_journey_template.id
              : 0
          }
          onChangeCallback={val => {
            setFormData({
              ...formData,
              default_journey_template: { id: +val },
            });
          }}
        />
        <Dropdown
          labelText="Lead Response"
          name="leadResponse"
          options={emailTemplatesOptions}
          initialValue={
            formData.lead_response_email_template
              ? formData.lead_response_email_template.id
              : 0
          }
          onChangeCallback={val => {
            setFormData({
              ...formData,
              lead_response_email_template: { id: +val },
            });
          }}
        />

        <h3>Icon</h3>
        {formData.icon && (
          <img
            src={formData.icon}
            className={styles['SessionTypesModal-icon']}
            alt="icon"
          />
        )}
        {isNew && icon && (
          <img
            src={icon}
            className={styles['SessionTypesModal-icon']}
            alt="icon"
          />
        )}
        <ImageUploading
          value={'logo'}
          onChange={data => {
            if (data[0] && data[0].file.size > MAX_UPLOAD_BYTES) { 
              toast.error("File size must be less than 20 MB");
            } else {
              setIcon(data[0].data_url);
              setIconFileName(data[0].file.name);
            }
          }}
          dataURLKey="data_url"
        >
          {({ onImageUpload }) => (
            <div className={styles['SessionTypesModal-uploader']}>
              <Button onClick={onImageUpload} text="Choose File" white />
              <div className={styles['SessionTypesModal-uploader-filename']}>
                {iconFileName || 'No file uploaded'}
              </div>
            </div>
          )}
        </ImageUploading>
        <h3>Label Color</h3>
        <div className={styles['SessionTypesModal-label']}>
          <div
            onClick={() => setShowPicker(!showPicker)}
            className={styles['SessionTypesModal-label-color']}
            style={{ backgroundColor: formData.color }}
          />
          {showPicker && (
            <div>
              <ChromePicker
                disableAlpha
                color={formData.color}
                onChange={color => {
                  setFormData({ ...formData, color: color.hex });
                }}
              />
            </div>
          )}
        </div>
        <h3>Display on Website Lead Form</h3>
        <Toggle
          initialValue={formData.show_on_lead_form}
          onChangeCallback={val =>
            setFormData({ ...formData, show_on_lead_form: val })
          }
        />
      </div>
      <div className={styles['SessionTypesModal-buttons']}>
        <Button white text="Cancel" onClick={handleHide} />
        <Button
          disabled={!formData.name}
          text="Save"
          onClick={isNew ? newSessionType : updateSessionType}
        />
      </div>
    </Modal>
  );
};

export default SessionTypesModal;
