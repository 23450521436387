import React from 'react';
import { Table, Pill } from '~ui';

const InvoiceRefundsTable = ({ refunds }) => {
  const renderRefundStatus = status => {
    switch (status) {
      case 'PENDING':
        return <Pill text="Pending" className="va-t" color="gray" small />;
      case 'COMPLETED':
        return <Pill text="Completed" className="va-t" color="green" small />;
      case 'FAILED':
        return <Pill text="Failed" className="va-t" color="red" small />;
      case 'REJECTED':
        return <Pill text="Rejected" className="va-t" color="red" small />;
      default:
        return <></>;
    }
  };

  const rows = refunds.map(refund => {
    const { id, amount_in_cents, status, notes } = refund;

    const row = {
      id,
      editable: false,
      deletable: false,
      data: [
        {
          name: 'amount',
          value: `${window.app.invoices.currency}${amount_in_cents / 100}`,
          type: 'string',
          editable: false,
        },
        {
          name: 'notes',
          type: 'string',
          value: notes,
          editable: false,
        },
        {
          name: 'status',
          value: () => renderRefundStatus(status),
          type: 'function',
          editable: false,
        },
      ],
    };

    return row;
  });

  const refundHeaders = ['Amount', 'Notes', 'Status'];

  const schema = [
    { name: 'amount', value: '', type: 'string' },
    { name: 'notes', value: '', type: 'string' },
    { name: 'status', value: '', type: 'function' },
  ];

  return (
    <div>
      <Table
        headers={refundHeaders}
        rows={rows}
        title={'Refund History'}
        readOnly={true}
        schema={schema}
      />
    </div>
  );
};

export default InvoiceRefundsTable;
