import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, TableNew as Table } from '~ui';
import {
  createInvoicePayment,
  updateInvoicePayment,
  deleteInvoicePayment,
} from '~actions/invoicePayments';
import { getInvoice } from '~actions/invoice';
import {
  PAYMENT_PROCESSORS,
  PAYMENT_METHOD_OPTIONS,
  ALL_PAYMENT_METHOD_OPTIONS,
  PAYMENT_HEADERS,
} from '~constants/invoices';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { formatCurrencyInput } from '~utils';

const getErrors = parameters => {
  const errors = [];

  // convert amount string to a number, check if it's 0
  const amountAsNumber = Number.parseFloat(parameters.amount.replace(','));
  if (!amountAsNumber) {
    errors.push('Please enter an amount for the payment.');
  }

  if (!parameters.payment_date) {
    errors.push('Please enter a date for the payment.');
  }

  return errors;
};

const RecordPaymentModal = ({ payments, handleHide }) => {
  const dispatch = useDispatch();
  const invoiceId = useSelector(state => state.invoice.id);

  const asyncCreate = params => dispatch(createInvoicePayment(params));
  const asyncUpdate = (id, params) =>
    dispatch(updateInvoicePayment(id, params));
  const asyncDelete = (id, params) =>
    dispatch(deleteInvoicePayment(id, params));

  const createRowItem = (
    id = null,
    temp = false,
    amount,
    payment_date,
    payment_method,
    receipt_sent
  ) => {
    const isProcessorPayment = PAYMENT_PROCESSORS.includes(payment_method);

    return {
      id,
      temp,
      editable: true,
      deletable: true,
      data: {
        amount: {
          value: amount,
          type: 'currency',
          editable: true,
        },
        payment_date: {
          value: payment_date,
          type: 'datetime',
          editable: true,
        },
        payment_method: {
          value: payment_method,
          type: 'enum',
          options: isProcessorPayment
            ? ALL_PAYMENT_METHOD_OPTIONS
            : PAYMENT_METHOD_OPTIONS,
          editable: true,
        },
        receipt_sent: {
          value: receipt_sent,
          type: 'boolean',
          editable: true,
        },
      },
    };
  };

  const [rows, setRows] = useState([]);
  const schema = createRowItem(null, true, 0, new Date(), '', false);

  const handleCreate = params => {
    const { amount, payment_date } = params;

    const errors = getErrors({
      ...params,
      amount: amount.toString(),
    });

    if (errors.length > 0) {
      errors.forEach(error => {
        toast.error(error, toastOptions);
      });
      return false;
    }

    const wrappedParams = {
      invoice_payment: {
        ...params,
        amount: formatCurrencyInput(amount),
        invoice_id: invoiceId,
        payment_date: payment_date,
      },
    };

    asyncCreate(wrappedParams).then(() => {
      dispatch(getInvoice(invoiceId));
    });

    return true;
  };

  const handleUpdate = ({ id, body }) => {
    let sendReceipt = false;

    payments.forEach(payment => {
      if (
        payment.id === id &&
        payment.receipt_sent === false &&
        body.receipt_sent === true
      )
        sendReceipt = true;
    });

    const errors = getErrors({
      ...body,
      amount: body.amount.toString(),
    });

    if (errors.length > 0) {
      errors.forEach(error => {
        toast.error(error, toastOptions);
      });

      return false;
    }

    const { payment_date } = body;

    let formatted_payment_date = payment_date;

    if (payment_date['_d'])
      formatted_payment_date = payment_date['_d'].toISOString().split('T')[0];

    const wrappedParams = {
      invoice_payment: {
        ...body,
        amount: body.amount,
        receipt_sent: sendReceipt ? true : false,
        invoice_id: invoiceId,
        payment_date: formatted_payment_date,
      },
    };

    asyncUpdate(id, wrappedParams).then(() => {
      dispatch(getInvoice(invoiceId));
    });

    return true;
  };

  const handleDelete = ({ id }) => {
    const wrappedParams = {
      invoice_payment: {
        invoice_id: invoiceId,
      },
    };
    asyncDelete(id, wrappedParams).then(() => {
      dispatch(getInvoice(invoiceId));
    });
  };

  useEffect(() => {
    const newRows = payments.map(item =>
      createRowItem(
        item.id,
        false,
        item.amount,
        item.payment_date,
        item.payment_method,
        item.receipt_sent
      )
    );
    setRows([...newRows]);
  }, [payments]);

  const hooks = {
    create: handleCreate,
    update: handleUpdate,
    delete: handleDelete,
  };

  return (
    <Modal title="Record Payment" handleHide={handleHide}>
      <p>Click the Add button below to create a payment for this invoice.</p>
      <Table
        headers={PAYMENT_HEADERS}
        title="Payments"
        rows={rows}
        hooks={hooks}
        setRows={setRows}
        schema={schema}
      />
    </Modal>
  );
};

RecordPaymentModal.propTypes = {
  handleHide: PropTypes.func,
  payments: PropTypes.array,
};

export default RecordPaymentModal;
