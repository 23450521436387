import { absolutePath } from '~constants/routes';
import React from 'react';
import styles from './LandingPages.module.scss';

const MarketingNeedsAddress = () => {
  return (
    <div>
      <h1>
        In order to comply with CAN-SPAM requirements, you must input a physical
        address in order to utilize Email Marketing. Please click
        <a href={absolutePath('/studio/profile')}>
          <span className={styles['LandingStyle-span']}> here</span>
        </a>{' '}
        and add your address.
      </h1>
    </div>
  );
};

export default MarketingNeedsAddress;
