import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import SignatureCanvas from 'react-signature-canvas';
import { routes } from '~constants/routes';
import { Checkbox, TextInput } from '~ui';
import {
  generateContract,
  updateFooterOnContinue,
  updateFooterContinueDisabled,
  checkClientPermission,
} from '~actions/customerBooking';
import { actionsLoadingSelector, actionsErroredSelector } from '~selectors';
import styles from './ContractRoute.module.scss';
import withBackHandler from '../withBackHandler';
import { useWindowSize } from '~hooks';
import { useHistory } from 'react-router-dom';
import { CUSTOMER_BOOKING } from '~constants/actionTypes';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';

const ContractRoute = ({ uuid, BOOKING_PATHS }) => {
  const canvasRef = useRef();
  const secondaryCanvasRef = useRef();
  const dispatch = useDispatch();

  const { html, contract_id, requires_secondary_signature } = useSelector(
    state => state.customerBooking.contract
  );
  const { id: client_id } = useSelector(
    state => state.customerBooking.clientInfo
  );

  const loading = useSelector(state =>
    actionsLoadingSelector(state, ['CUSTOMER_BOOKING_CONTRACT_GENERATE'])
  );

  const errors = useSelector(state =>
    actionsErroredSelector(state, [
      'CUSTOMER_BOOKING_AVAILABILITY_CHECK_CLIENT_PERMISSION',
    ])
  );

  const token = useSelector(state => state.customerBooking.token);

  const [contractBody, setContractBody] = useState();
  const [contractId, setContractId] = useState();
  const [
    requiresSecondarySignature,
    setRequiresSecondarySignature,
  ] = useState();
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [secondaryAgreeToTerms, setSecondaryAgreeToTerms] = useState();
  const [signature, setSignature] = useState();
  const [typedName, setTypedName] = useState();
  const [secondaryTypedName, setSecondaryTypedName] = useState();
  const [secondarySignature, setSecondarySignature] = useState();

  const windowWidth = useWindowSize()[0];

  const history = useHistory();

  const cameFromInvoiceRoute = window.sessionStorage.getItem(
    'cameFromInvoiceRoute'
  );
  const cameFromContractRoute = window.sessionStorage.getItem(
    'cameFromInvoiceRoute'
  );
  // I know this may look weird, but don't get too bent about it.
  // All this does is resizes the canvas element with a screen
  // resize minus the 60 pixels of padding in the parent container.
  const canvasWidth =
    windowWidth <= 680 ? windowWidth - 60 : windowWidth / 2 - 60;

  const getErrorMessage = () =>
    Object.keys(errors).reduce((acc, key) => {
      acc += unescape(errors[key]);
      return acc;
    }, '');

  useEffect(() => {
    if (errors.CUSTOMER_BOOKING_AVAILABILITY_CHECK_CLIENT_PERMISSION) {
      Rollbar.error(
        `Contract Route Error ${
          errors.CUSTOMER_BOOKING_AVAILABILITY_CHECK_CLIENT_PERMISSION
        }, ${getErrorMessage()}`
      );
      toast.error(getErrorMessage(), toastOptions);
      dispatch({ type: CUSTOMER_BOOKING.RESET });
      history.push(BOOKING_PATHS.root(uuid));
    }
  }, [errors]);

  useEffect(() => {
    if (signature || secondarySignature) {
      setSignature(undefined);
      setSecondarySignature(undefined);
    }
  }, [canvasWidth]);

  useEffect(() => {
    if (cameFromContractRoute) {
      dispatch({ type: CUSTOMER_BOOKING.RESET });
      history.push(BOOKING_PATHS.root(uuid));
    }
    if (client_id && !cameFromContractRoute && !cameFromInvoiceRoute) {
      dispatch(checkClientPermission(client_id));
      dispatch(generateContract());
      window.sessionStorage.setItem('cameFromContractRoute', true);
    }
  }, [client_id]);

  useEffect(() => {
    setContractBody(html);
    setContractId(contract_id);
    setRequiresSecondarySignature(requires_secondary_signature);
  }, [html, contract_id]);

  const handleContinue = async nextStep => {
    await axios.put(
      routes.BOOKING_CONTRACT.SIGN(contractId),
      {
        signature: typedName,
        drawn_signature: signature,
        agree_to_terms: agreeToTerms,
        secondary_signature: secondaryTypedName,
        drawn_secondary_signature: secondarySignature,
        secondary_agree_to_terms: secondaryAgreeToTerms,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    nextStep();
  };

  const contDisabled = !signature || !agreeToTerms || !typedName;

  const contDisabledSecondary =
    !signature ||
    !secondarySignature ||
    !agreeToTerms ||
    !secondaryAgreeToTerms ||
    !typedName ||
    !secondaryTypedName;

  const continueDisabled = () => {
    if (requiresSecondarySignature) {
      return contDisabledSecondary;
    }
    return contDisabled;
  };

  useEffect(() => {
    dispatch(updateFooterOnContinue(handleContinue));
    dispatch(updateFooterContinueDisabled(continueDisabled()));
  }, [
    contractId,
    typedName,
    signature,
    agreeToTerms,
    secondaryAgreeToTerms,
    secondaryTypedName,
    secondarySignature,
  ]);

  return (
    <>
      <div className={styles.ContractRoute}>
        <p>
          Please read the text below and sign with your name to indicate that
          you agree.
        </p>
        {loading ? (
          <h1 className={styles['ContractRoute-contract']}>Loading...</h1>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: contractBody }} />
        )}

        <Checkbox
          name="agree_to_terms"
          checked={agreeToTerms}
          labelText="I agree"
          onChangeCallback={() => setAgreeToTerms(!agreeToTerms)}
        />
        <p>
          By checking this box, you indicate that you have read and agree to the
          terms above.
        </p>
        <TextInput
          name="typedName"
          labelText="Please type your name"
          onChangeCallback={setTypedName}
        />
        <SignatureCanvas
          canvasProps={{
            width: canvasWidth,
            height: 200,
            className: styles['ContractRoute-signature'],
          }}
          ref={canvasRef}
          onEnd={() => setSignature(canvasRef.current.toDataURL())}
        />
        <span
          onClick={() => {
            canvasRef.current.clear();
            setSignature(undefined);
          }}
        >
          Clear
        </span>
        {requiresSecondarySignature && (
          <>
            <Checkbox
              name="secondary_agree_to_terms"
              checked={secondaryAgreeToTerms}
              labelText="I agree"
              onChangeCallback={() =>
                setSecondaryAgreeToTerms(!secondaryAgreeToTerms)
              }
            />
            <p>
              By checking this box, you indicate that you have read and agree to
              the terms above.
            </p>
            <TextInput
              name="typedName"
              labelText="Please type your name"
              onChangeCallback={setSecondaryTypedName}
            />
            <SignatureCanvas
              canvasProps={{
                width: canvasWidth,
                height: 200,
                className: styles['ContractRoute-signature'],
              }}
              ref={secondaryCanvasRef}
              onEnd={() =>
                setSecondarySignature(secondaryCanvasRef.current.toDataURL())
              }
            />
            <span
              onClick={() => {
                secondaryCanvasRef.current.clear();
                setSecondarySignature(undefined);
              }}
            >
              Clear
            </span>
          </>
        )}
      </div>
    </>
  );
};

export default withBackHandler(ContractRoute);
