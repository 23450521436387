import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import moment from 'moment-timezone';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { updateSelectedDay, updateSun } from '~actions/calendar';
import {
  getMergedCalendarEventsByDate,
  multiWeekEventsSelectorMonthView,
} from '~selectors';
import { useWindowSize } from '~hooks';
import { get, uniqueId } from '~utils';
import { MOBILE_WIDTH } from '~constants/viewports';
import { DATE_KEY_FORMAT } from '~constants/datetime';
import styles from './CalendarCell.module.scss';
import CalendarEvent from '../CalendarEvent';
import CronofyEvent from '../CronofyEvent';
import PlaceholderEvent from '../PlaceholderEvent';
import SunCalc from 'suncalc';

const CalendarCell = props => {
  const { current, day, onDoubleClick, latitude, longitude } = props;
  const cx = classNames.bind(styles);
  let formattedDate = moment(day).format('D');
  const dispatch = useDispatch();
  let width = useWindowSize()[0];
  const classes = cx('CalendarCell', {
    'CalendarCell--current': current,
  });
  const dateKey = day.format(DATE_KEY_FORMAT);
  const multiWeekEvents = useSelector(multiWeekEventsSelectorMonthView);
  let allEvents = useSelector(state =>
    getMergedCalendarEventsByDate(state, day.format(dateKey))
  );
  const events = [...allEvents, ...get(multiWeekEvents, [dateKey], [])];
  const sortedEvents = events.sort((eventA, eventB) => {
    let startA, startB;

    if (eventA.all_day && eventA.event_uid) {
      startA = moment(day).format('YYYY-MM-DD HH:mm:SS');
    } else if (eventA.event_uid) {
      startA = eventA.start.time;
    } else {
      startA = eventA.start_time;
    }

    if (eventB.all_day && eventB.event_uid) {
      startB = moment(day).format('YYYY-MM-DD HH:mm:SS');
    } else if (eventB.event_uid) {
      startB = eventB.start.time;
    } else {
      startB = eventB.start_time;
    }

    return Date.parse(startA) - Date.parse(startB);
  });
  const firstEvents = sortedEvents.slice(0, 2);
  const hasMoreThanTwoEvents = sortedEvents.length > 2;

  const selectDay = async () => {
    await dispatch(updateSelectedDay(day.format(DATE_KEY_FORMAT)));

    const sunriseTime = SunCalc.getTimes(
      new Date(dateKey),
      latitude,
      longitude
    );

    const sunsetTime = SunCalc.getTimes(new Date(dateKey), latitude, longitude);

    if (latitude && longitude) {
      dispatch(
        updateSun({ sunrise: sunriseTime.sunrise, sunset: sunsetTime.sunset })
      );
    }
  };

  const renderEvent = event => {
    const start = event.event_uid
      ? moment(event.start.time)
      : moment(event.start_time);
    const end = event.event_uid
      ? moment(event.end.time)
      : moment(event.end_time);
    const startOfWeek = moment(day).startOf('week');
    const endOfWeek = moment(day).endOf('week');

    const getWidth = () => {
      let width = event.is_all_day_event ? end.diff(start, 'days') : 1;

      if (end.isAfter(endOfWeek)) {
        width = endOfWeek.diff(start, 'days') + 1;
      }

      if (start.isBefore(startOfWeek)) {
        width = end.diff(startOfWeek, 'days');
      }

      if (width > 7) {
        width = 7;
      }

      return `${width * 100}%`;
    };

    let customStyles = {
      position: 'relative',
      width: getWidth(),
    };

    if (event.event_uid) {
      const id = uniqueId('externalEvent_');

      return (
        <CronofyEvent event={event} key={id} customStyles={customStyles} />
      );
    }

    if (event.type === 'placeholder') {
      const id = uniqueId('placeholder_');
      return <PlaceholderEvent key={id} />;
    }

    return (
      <CalendarEvent
        event={event}
        key={uniqueId(`event${event.id}_`)}
        customStyles={customStyles}
      />
    );
  };

  return (
    <div
      className={classes}
      onClick={() => selectDay()}
      onDoubleClick={() => {
        onDoubleClick(day);
        window.app.showModal();
      }}
    >
      <div>{formattedDate}</div>
      {width < MOBILE_WIDTH && sortedEvents.length > 0 ? (
        <div className={styles['CalendarCell-eventMarker']} />
      ) : (
        <>
          {firstEvents.map(event => renderEvent(event))}
          {hasMoreThanTwoEvents && <a>{sortedEvents.length - 2} more</a>}
        </>
      )}
    </div>
  );
};

CalendarCell.propTypes = {
  current: PropTypes.bool,
  day: PropTypes.object.isRequired,
  onDoubleClick: PropTypes.func,
};

CalendarCell.defaultProps = {
  current: false,
  onDoubleClick: () => {},
};

export default CalendarCell;
