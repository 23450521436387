import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { toCurrencyAmount, toDollarAmount } from '~utils';
import { uniqueId } from '~utils/misc';
import {
  FULL_DATE_FORMAT,
  DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_WITH_ZONE,
} from '~constants/datetime';
import styles from './DataTable.module.scss';

const getDisplayValue = (originalValue, columnType) => {
  let displayValue;

  if (columnType === 'date') {
    displayValue = moment(originalValue).isValid()
      ? moment(originalValue).format(FULL_DATE_FORMAT)
      : '—';
  } else if (columnType === 'datetime') {
    if (moment(originalValue).isValid()) {
      if (
        moment().tz(moment.tz.guess()).format('z') ==
        moment().tz(app.studio.timezone).format('z')
      ) {
        displayValue = moment
          .utc(originalValue)
          .tz(app.studio.timezone)
          .format(DATE_TIME_FORMAT);
      } else {
        displayValue = moment
          .utc(originalValue)
          .tz(app.studio.timezone)
          .format(DATE_TIME_FORMAT_WITH_ZONE);
      }
    } else {
      displayValue = '-';
    }
  } else if (columnType === 'currency') {
    displayValue = toCurrencyAmount(originalValue);
  } else if (columnType === 'dollar') {
    displayValue = toDollarAmount(originalValue);
  } else if (columnType === 'function') {
    displayValue = originalValue();
  } else {
    displayValue = originalValue;
  }

  return displayValue;
};

const TableRow = ({ row, rowIndex, columnTypes, rowWidth }) => {
  return (
    <tr
      onClick={row.onClickCallback ? row.onClickCallback : null}
      className={row.onClickCallback ? styles['DataTable-hoverableRow'] : null}
    >
      {Object.keys(row).map((columnName, cellIndex) => {
        if (columnName != 'onClickCallback') {
          return (
            <td key={uniqueId(`cell_${rowIndex}_${cellIndex}_`)} style={{width: rowWidth}}>
              {getDisplayValue(row[columnName], columnTypes[cellIndex])}
            </td>
          );
        }
      })}
    </tr>
  );
};

TableRow.propTypes = {
  row: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
  columnTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TableRow;
