import React, { createRef, useLayoutEffect, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './Popover.module.scss';

const POPOVER_OFFSET = 24;

const Popover = props => {
  const {
    activatorBounds,
    children,
    controls,
    handleHide,
    animate,
    allowHandleClickOutside,
    popoverWidth,
  } = props;
  const cx = classNames.bind(styles);
  const classes = cx('Popover', {
    'Popover--animate': animate,
  });
  const popoverRef = createRef(null);

  const handleClickOutside = e => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(e.target) &&
      allowHandleClickOutside
    ) {
      handleHide();
      return;
    }
  };

  useLayoutEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    positionElement();

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useLayoutEffect(() => {
    positionElement();
  }, []);

  /*
    Position the popover relative to the activator's position and within the viewport.
  */
  const positionElement = () => {
    const popoverNode = popoverRef.current;
    const bounds = activatorBounds();
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;
    const heightOffset =
      viewportHeight -
      (popoverNode.clientHeight + bounds.top + bounds.height + POPOVER_OFFSET);
    const widthOffset =
      viewportWidth -
      (popoverNode.clientWidth + bounds.left + bounds.width + POPOVER_OFFSET);

    if (heightOffset < 0) {
      popoverNode.style.bottom = '0px';
      popoverNode.style.top = 'auto';
    } else {
      popoverNode.style.top = `${bounds.top + window.pageYOffset}px`;
      popoverNode.style.bottom = 'auto';
    }

    if (widthOffset < 0) {
      let right = viewportWidth - (bounds.left + POPOVER_OFFSET);
      popoverNode.style.right =
        right > 1500 ? `${right - 1000}px` : `${right}px`;
      popoverNode.style.left = 'auto';
    } else {
      let left = bounds.left + bounds.width + POPOVER_OFFSET;
      popoverNode.style.left = `${left}px`;
      popoverNode.style.right = 'auto';
    }

    popoverNode.style.display = 'block';
  };

  return ReactDOM.createPortal(
    <div className={classes} style={{ width: popoverWidth }} ref={popoverRef}>
      <div className={styles['Popover-controls']}>
        {controls &&
          controls.map((control, index) => (
            <Fragment key={`control_${index}`}>{control}</Fragment>
          ))}
        {/* We're using tabIndex for accessibility. */}
        <a onClick={handleHide} onKeyPress={handleHide}>
          <i tabIndex={0} className="fa fa-times" />
        </a>
      </div>
      <div className={styles['Popover-content']}>{children}</div>
    </div>,
    document.getElementById('popover-container')
  );
};

Popover.propTypes = {
  activatorBounds: PropTypes.func.isRequired,
  animate: PropTypes.bool,
  controls: PropTypes.array,
  handleHide: PropTypes.func.isRequired,
};

Popover.defaultProps = {
  animate: true,
  controls: null,
};

export default Popover;
