import React from 'react';
import EmailMarketingLandingPage from './EmailMarketingLandingPage';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const EmailMarketingLandingPageRoot = props => {
  const { sitekey, studio } = props;
  return (
    <GoogleReCaptchaProvider reCaptchaKey={sitekey}>
      <EmailMarketingLandingPage studio={studio} />
    </GoogleReCaptchaProvider>
  );
};

export default EmailMarketingLandingPageRoot;
