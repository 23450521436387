import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import styles from './BookingFooter.module.scss';
import { Button, Timer } from '~ui';
import { actionsLoadingSelector, actionsErroredSelector } from '~selectors';
import { CUSTOMER_BOOKING } from '~constants/actionTypes';
import { useHistory, useLocation } from 'react-router-dom';
import {
  DATE_TIME_FORMAT,
  TIME_FORMAT,
  DATE_FORMAT,
} from '~constants/datetime';
import { restoreAvailability, emailStudio } from '../bookingHelpers';
import moment from 'moment-timezone';
import { useCallback } from 'react';

const BookingFooter = props => {
  const { BOOKING_PATHS, uuid } = props;

  const { onContinue, currentSelection, continueDisabled } = useSelector(
    state => state.customerBooking.bookingFooterState
  );
  const dispatch = useDispatch();
  const loading = useSelector(state =>
    actionsLoadingSelector(state, [
      'CUSTOMER_BOOKING_AVAILABILITY_SELECT_AVAILABILITY',
    ])
  );
  const errors = useSelector(state =>
    actionsErroredSelector(state, [
      'CUSTOMER_BOOKING_AVAILABILITY_SELECT_AVAILABILITY',
    ])
  );
  const history = useHistory();
  const { pathname } = useLocation();
  const { showTimer } = useSelector(state => state.customerBooking);
  const selectedAvailability = useSelector(
    state => state.customerBooking.selectedAvailability
  );
  const client = useSelector(state => state.customerBooking.clientInfo);
  const invoice = useSelector(state => state.customerBooking.invoice);
  const contract = useSelector(state => state.customerBooking.contract);
  const token = useSelector(state => state.customerBooking.token);

  const zoneGuess = moment.tz(moment.tz.guess()).format('z');

  const zoneAbbr = zoneGuess[0] == '-' || zoneGuess[0] == '+' ? '' : zoneGuess;

  const onExpire = useCallback(async () => {
    await emailStudio(client, selectedAvailability, invoice, contract, uuid, "timer");
    await restoreAvailability(selectedAvailability, token);
    dispatch({ type: CUSTOMER_BOOKING.RESET });
    history.push(BOOKING_PATHS.root(uuid));
  }, [client, invoice, contract, token, selectedAvailability]);

  const getErrorMessage = () =>
    Object.keys(errors).reduce((acc, key) => {
      acc += unescape(errors[key]);
      return acc;
    }, '');

  useEffect(() => {
    if (loading !== false) {
      return;
    }

    if (errors.CUSTOMER_BOOKING_AVAILABILITY_SELECT_AVAILABILITY) {
      toast.error(getErrorMessage(), toastOptions);
    }

    if (!selectedAvailability.id) {
      history.push(BOOKING_PATHS.root(uuid));
    }
  }, [loading]);

  const nextStep = () => {
    const currentIndex = Object.values(BOOKING_PATHS).findIndex(
      value => value(uuid) === pathname
    );
    history.push(Object.entries(BOOKING_PATHS)[currentIndex + 1][1](uuid));
  };

  const handleContinue = async () => {
    if (onContinue) {
      await onContinue(nextStep);
    } else {
      nextStep();
    }
  };

  const showButton = () => {
    if (BOOKING_PATHS.invoice) {
      return pathname !== BOOKING_PATHS.invoice(uuid);
    }
    return true;
  };

  let startTimeSelection;
  let endTimeSelection;

  if (currentSelection) {
    startTimeSelection = moment(currentSelection.start_time).tz(
      moment.tz.guess()
    );
    endTimeSelection = moment(currentSelection.end_time).tz(moment.tz.guess());
  }
  if (history.location.pathname === BOOKING_PATHS.confirm(uuid)) {
    return null;
  } else {
    return (
      <div className={styles.BookingFooter}>
        {/* Here for styling purposes */}
        <div className={styles['BookingFooter-buttonContainer']} />
        <div className={styles['BookingFooter-timerContainer']}>
          {!showTimer && currentSelection && (
            <div className={styles['BookingFooter-timer']}>
              <strong>
                You have selected{' '}
                {currentSelection.all_day ? (
                  <span>
                    All Day{''}
                    {endTimeSelection
                      .clone()
                      .startOf('day')
                      .isBetween(startTimeSelection, endTimeSelection)
                      ? `, ${startTimeSelection.format(
                        DATE_FORMAT
                      )} - ${endTimeSelection.format(DATE_FORMAT)}`
                      : `, ${startTimeSelection.format(DATE_FORMAT)}`}
                  </span>
                ) : (
                  <>
                    {endTimeSelection.diff(startTimeSelection, 'hour') > 24 ||
                      endTimeSelection
                        .clone()
                        .startOf('day')
                        .isBetween(startTimeSelection, endTimeSelection) ? (
                      <span>{`${startTimeSelection.format(
                        DATE_TIME_FORMAT
                      )} - ${endTimeSelection.format(DATE_TIME_FORMAT)}`}</span>
                    ) : (
                      <span>
                        {`${startTimeSelection.format(
                          DATE_TIME_FORMAT
                        )} ${zoneAbbr} - ${endTimeSelection.format(
                          TIME_FORMAT
                        )} ${zoneAbbr}`}
                      </span>
                    )}
                  </>
                )}
              </strong>
            </div>
          )}
          {showTimer && (
            <div className={styles['BookingFooter-timer']}>
              <strong>
                Your reservation for{' '}
                {selectedAvailability.all_day ? (
                  <span>
                    All Day{''}
                    {moment(selectedAvailability.end_time)
                      .clone()
                      .startOf('day')
                      .isBetween(
                        moment(selectedAvailability.start_time),
                        moment(selectedAvailability.end_time)
                      )
                      ? `, ${moment(selectedAvailability.start_time).format(
                        DATE_FORMAT
                      )} - ${moment(selectedAvailability.end_time).format(
                        DATE_FORMAT
                      )}`
                      : `, ${moment(selectedAvailability.start_time).format(
                        DATE_FORMAT
                      )}`}
                  </span>
                ) : (
                  <>
                    {moment(selectedAvailability.end_time).diff(
                      moment(selectedAvailability.start_time),
                      'hour'
                    ) > 24 ||
                      moment(selectedAvailability.end_time)
                        .startOf('day')
                        .isBetween(
                          moment(selectedAvailability.start_time),
                          moment(selectedAvailability.end_time)
                        ) ? (
                      <span>
                        {`${moment(selectedAvailability.start_time).format(
                          DATE_TIME_FORMAT
                        )} ${zoneAbbr} - ${moment(
                          selectedAvailability.end_time
                        ).format(DATE_TIME_FORMAT)} ${zoneAbbr}`}
                      </span>
                    ) : (
                      <span>
                        {`${moment(selectedAvailability.start_time).format(
                          DATE_TIME_FORMAT
                        )} ${zoneAbbr} - ${moment(
                          selectedAvailability.end_time
                        ).format(TIME_FORMAT)} ${zoneAbbr}`}
                      </span>
                    )}
                  </>
                )}{' '}
                expires in:
              </strong>
              <Timer minutes={15} seconds={0} onExpireCallback={onExpire} />
            </div>
          )}
        </div>
        <div className={styles['BookingFooter-buttonContainer']}>
          {showButton() && (
            <Button
              text="Continue"
              disabled={continueDisabled}
              onClick={handleContinue}
            />
          )}
        </div>
      </div>
    );
  }
};

export default BookingFooter;
