import React, { useState } from 'react';
import classNames from 'classnames/bind';
import BANNER from '~images/banner.svg';
import { Button } from '~ui';
import NewSubscriptionModal from '../NewSubscriptionModal';
import styles from './SubscriptionCard.module.scss';
import SubscriptionToggle from '../SubscriptionToggle/index';

const SubscriptionCard = ({
  header,
  basic,
  premium,
  interval,
  plan,
  emailMarketing,
  onChangeCallback,
}) => {
  const cx = classNames.bind(styles);
  const classes = cx('SubscriptionCard', {
    'SubscriptionCard--basic': basic,
    'SubscriptionCard--premium': premium,
  });

  const price = interval == 'monthly' ? plan.amount : plan.amount / 12;

  const [dollarAmount, centAmount] = Number(price).toFixed(2).split('.');

  const [showModal, setShowModal] = useState(false);

  const buttonOnClick = () => {
    setShowModal(true);
  };

  return (
    <div className={classes} style={{ height: emailMarketing && '300px' }}>
      {premium && (
        <img
          src={BANNER}
          alt="Image"
          style={{ bottom: emailMarketing && '250px' }}
        />
      )}
      <h2>{header}</h2>
      <div className={styles['SubscriptionCard--content']}>
        <h3>
          ${dollarAmount}.<sup>{centAmount}</sup>
          <span>/mo</span>
        </h3>
        {interval == 'annually' && <p>when paid annually</p>}
      </div>
      {emailMarketing && (
        <div className={styles['SubscriptionCard--content2']}>
          <p>Add email marketing?</p>
          <SubscriptionToggle
            onChangeCallback={onChangeCallback}
            emailMarketing
          />
        </div>
      )}
      <Button text="Select" onClick={buttonOnClick} />
      {showModal && (
        <NewSubscriptionModal
          plan={plan}
          handleHide={() => {
            setShowModal(false);
          }}
        />
      )}
    </div>
  );
};

export default SubscriptionCard;
