import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { TextInput } from '~ui';
import styles from './CustomOrderElement.module.scss';

const ItemType = 'CUSTOM_ORDER_ELEMENT';

const CustomOrderElement = ({
  index,
  initialValue,
  moveElement,
  setHoveredIndex,
  isHovered,
}) => {
  const ref = React.useRef(null);

  const [, drop] = useDrop({
    accept: ItemType,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveElement(dragIndex, hoverIndex);
      item.index = hoverIndex;
      setHoveredIndex(hoverIndex);
    },
    leave() {
      setHoveredIndex(null);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index, type: ItemType },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      setHoveredIndex(null);
    },
  });

  drag(drop(ref));

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <div
        style={{
          marginRight: '8px',
          marginTop: '5px',
        }}
      >{`${index + 1}`}</div>
      <div
        className={styles.ButtonStyle}
        ref={ref}
        style={{
          cursor: 'grabbing',
        }}
      >
        <div
          style={{
            width: '360px',
            display: 'flex',
          }}
          className={isHovered ? styles.DragInput : ''}
        >
          <TextInput
            name="sessionType"
            initialValue={initialValue.name || ''}
            placeholder=""
            disabled
          />
          <i
            style={{
              marginLeft: '10px',
              marginTop: '9px',
              marginRight: '25px',
            }}
            className="fas fa-grip-vertical"
          />
        </div>
      </div>
    </div>
  );
};

export default CustomOrderElement;
