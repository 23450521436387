import React from 'react';
import BookingLandingPage from './BookingLandingPage/BookingLandingPage';

const BookingLandingPageRoot = props => {
  return (
    <div>
      <BookingLandingPage props={props} />
    </div>
  );
};

export default BookingLandingPageRoot;
