import React, { useState, useEffect } from 'react';
import { TableNew as Table, Icon, Button } from '~ui';
import styles from './CouponCodeTable.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { actionsLoadingSelector, actionsErroredSelector } from '~selectors';
import { getInvoicesList, updateInvoiceListMeta } from '~actions/invoice';
import moment from 'moment';
import { getCouponCodeList } from '~actions/couponCodes';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import CreateCouponModal from '../CouponCodeModals/CreateCouponModal';
import UpdateCouponModal from '../CouponCodeModals/UpdateCouponModal';
import DeleteCouponModal from '../CouponCodeModals/DeleteCouponModal';
import { dropdownOptions } from '../InvoicesTable/dropdownOptions';
import { absolutePath } from '~constants/routes';

const CouponCodeTable = ({ isMobile }) => {
  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [couponForUpdate, setCouponForUpdate] = useState({});
  const [currentFilterIndex, setCurrentFilterIndex] = useState(11);

  const options = dropdownOptions(
    dispatch,
    getInvoicesList,
    updateInvoiceListMeta,
    setCurrentFilterIndex,
    getCouponCodeList
  );

  const [idForDelete, setIdForDelete] = useState();

  const couponCodes = useSelector(state => state?.couponCodes.couponCodeList);

  const { titleDisplay, page, page_count, total_records } = useSelector(
    state => state.invoice.meta
  );
  const { studio_id, hasSquare, showCouponCodes } = useSelector(
    state => state.invoice
  );

  const [couponCodesFeature, setCouponCodesFeature] = useState(showCouponCodes);

  const isLoading = useSelector(state =>
    actionsLoadingSelector(state, ['COUPON_CODE_LIST_GET'])
  );

  const errors = useSelector(state =>
    actionsErroredSelector(state, ['COUPON_CODE_LIST_GET'])
  );

  useEffect(() => {
    if (isLoading !== false) {
      return;
    }

    if (errors.COUPON_CODE_LIST_GET) {
      toast.error(
        `Error grabbing coupons! Please reach out to support.`,
        toastOptions
      );
    }
  }, [isLoading, errors]);

  let ITEM_HEADERS = [
    'Discount Code',
    'Status',
    'Discount Amount',
    'Redemptions',
    'Expiration Date',
  ];

  let MOBILE_ITEM_HEADERS = ['Code', 'Status', 'Redemptions'];

  const createRowItem = props => {
    const {
      id,
      temp,
      code,
      ccStatus,
      discountAmount,
      redemptions,
      expirationDate,
      maxRedemptions,
    } = props;

    const moneyAmount = props.discountType == 'money_amount';
    const percentage = props.discountType == 'percentage';

    const rowItem = {
      id,
      editable: true,
      deletable: true,
      temp,
      onClickCallback: () => {
        setCouponForUpdate(props);
        setShowUpdateModal(true);
      },
      editButtonOverride: () => {
        setCouponForUpdate(props);
        setShowUpdateModal(true);
      },
      deleteButtonOverride: () => {
        setIdForDelete(id);
        setShowDeleteModal(true);
      },
      data: {
        code: {
          value: code,
          type: 'string',
          editable: false,
        },
        ccStatus: {
          value: ccStatus,
          type: 'string',
          editable: false,
        },
        discountAmount: {
          value: () => (
            <div>
              {moneyAmount && `$${discountAmount}`}
              {percentage && `${discountAmount}%`}
            </div>
          ),
          type: 'function',
          editable: false,
        },
        redemptions: {
          value: () => (
            <div>
              {maxRedemptions && `${redemptions}/${maxRedemptions}`}
              {!maxRedemptions && `${redemptions}`}
            </div>
          ),
          type: 'function',
          editable: false,
        },
        expirationDate: {
          value: expirationDate,
          type: 'string',
          editable: false,
        },
      },
    };

    return rowItem;
  };

  const createMobileRowItem = props => {
    const { id, temp, code, ccStatus, redemptions } = props;

    const rowItem = {
      id,
      editable: false,
      deletable: false,
      temp,
      readOnly: true,
      onClickCallback: () => {
        setCouponForUpdate(props);
        setShowUpdateModal(true);
      },
      data: {
        code: {
          value: code,
          type: 'string',
          editable: false,
        },
        ccStatus: {
          value: ccStatus,
          type: 'string',
          editable: false,
        },
        redemptions: {
          value: redemptions,
          type: 'enum',
          editable: false,
        },
      },
    };

    return rowItem;
  };

  useEffect(() => {
    if (couponCodes) {
      const newRows = couponCodes.map(cc => {
        if (isMobile) {
          return createMobileRowItem({
            id: +cc.id,
            temp: false,
            code: cc.code,
            ccStatus: checkCouponExpiration(cc.expiration_date),
            discountAmount: +cc.discount_amount,
            discountType: cc.discount_type,
            redemptions: +cc.redemptions_used,
            maxRedemptions: cc.redemptions,
            expirationDate: cc.expiration_date
              ? moment(cc.expiration_date).format('MM-DD-YYYY')
              : 'None',
            firstInstallment: cc.first_installment,
          });
        } else {
          return createRowItem({
            id: +cc.id,
            temp: false,
            code: cc.code,
            ccStatus: checkCouponExpiration(cc.expiration_date),
            discountAmount: +cc.discount_amount,
            discountType: cc.discount_type,
            redemptions: +cc.redemptions_used,
            maxRedemptions: cc.redemptions,
            expirationDate: cc.expiration_date
              ? moment(cc.expiration_date).format('MM-DD-YYYY')
              : 'None',
            firstInstallment: cc.first_installment,
          });
        }
      });

      setRows(newRows);
    }
  }, [couponCodes, isMobile]);

  const checkCouponExpiration = expirationDate => {
    if (moment(expirationDate).isBefore(moment())) {
      return 'Expired';
    }
    return 'Active';
  };

  if (hasSquare) {
    return (
      <>
        {isLoading && (
          <div className={styles.loadingOverlay}>
            <div className={styles.loadingText}>
              <p>Loading...</p>
              <Icon name="spinner" className="fa-pulse" large />
            </div>
          </div>
        )}
        <div className={styles.CouponCodeTable}>
          <Table
            title={titleDisplay}
            headers={isMobile ? MOBILE_ITEM_HEADERS : ITEM_HEADERS}
            dropdownButtonProps={
              isMobile
                ? {
                    options: options,
                    label: options[currentFilterIndex].label,
                  }
                : undefined
            }
            rows={rows}
            setRows={setRows}
            loading={isLoading}
            searchInputProps={{
              name: 'coupon-search',
              onChangeCallback: searchValue => {
                if (searchValue) {
                  dispatch(
                    updateInvoiceListMeta({
                      search_term: searchValue,
                      status: null,
                      is_quote: false,
                      titleDisplay: 'Search Results',
                      page: 1,
                    })
                  );
                } else {
                  dispatch(
                    updateInvoiceListMeta({
                      search_term: null,
                      status: 'couponCodes',
                      is_quote: false,
                      titleDisplay: 'Coupon Codes',
                      page: 1,
                    })
                  );
                }
                dispatch(getCouponCodeList());
              },
            }}
            page={page}
            pageCount={page_count}
            totalRecords={total_records}
            pageChangeCallback={newPageIndex => {
              dispatch(
                updateInvoiceListMeta({
                  page: newPageIndex,
                })
              );
              dispatch(getCouponCodeList());
            }}
            createButtonOverride={{
              text: 'Create Coupon',
              create: () => {
                setShowCreateModal(true);
              },
              whiteRectangle: true,
            }}
          />
        </div>
        {showCreateModal && (
          <CreateCouponModal
            handleHide={() => setShowCreateModal(false)}
            studio_id={studio_id}
            couponCodesFeature={couponCodesFeature}
            setCouponCodesFeature={setCouponCodesFeature}
          />
        )}
        {showUpdateModal && (
          <UpdateCouponModal
            handleHide={() => setShowUpdateModal(false)}
            studio_id={studio_id}
            couponForUpdate={couponForUpdate}
          />
        )}
        {showDeleteModal && (
          <DeleteCouponModal
            handleHide={() => setShowDeleteModal(false)}
            idForDelete={idForDelete}
          />
        )}
      </>
    );
  } else {
    return (
      <div className={styles.LandingStyle}>
        <div className={styles['LandingStyle-infoBox']}>
          <h1>
            <strong>Get Started with Iris Coupon Codes</strong>
          </h1>
          <ul>
            <li>
              Create coupon codes to promote your business to new customers and
              offer holiday sales
            </li>
            <li>
              Set expiration date and redemption limits on each code, providing
              you full control over your promotions
            </li>
            <li>
              Seamlessly integrate your coupon codes into your email marketing
              campaigns, helping to convert your leads into paying customers
            </li>
          </ul>
          <div className={styles['LandingStyle-infoBox-buttonDiv']}>
            <Button
              text="Enable Square"
              onClick={() => {
                window.location.href = absolutePath('/studio/invoice_setup');
              }}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default CouponCodeTable;
