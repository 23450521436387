import React from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from '~utils/misc';
import styles from './DataTable.module.scss';
import { Icon } from '~ui';

const ASCENDING = 'asc';
const DESCENDING = 'desc';

const TableHeader = ({
  headers,
  sortColumn,
  sortDirection,
  onSortCallback,
}) => (
  <thead>
    <tr>
      {headers.map((header, headerIndex) => {
        const sortable = header.sortable != false;

        const handleHeaderClick = () => {
          if (!sortable) {
            return;
          }

          if (header.name === sortColumn) {
            if (sortDirection === ASCENDING) {
              onSortCallback(sortColumn, DESCENDING);
            } else if (sortDirection === DESCENDING) {
              onSortCallback();
            }
          } else {
            onSortCallback(header.name, ASCENDING);
          }
        };

        const getIcon = () => {
          if (header.name !== sortColumn) {
            return 'arrows-alt-v';
          }

          if (sortDirection === ASCENDING) {
            return 'long-arrow-alt-up';
          }

          return 'long-arrow-alt-down';
        };

        return (
          <th
            key={uniqueId(`header_${headerIndex}_`)}
            onClick={handleHeaderClick}
            className={sortable ? styles.SortableHeader : undefined}
          >
            <div>
              {header.labelText}
              {sortable && <Icon className="ml-12" name={getIcon()} />}
            </div>
          </th>
        );
      })}
    </tr>
  </thead>
);

TableHeader.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      labelText: PropTypes.string,
      sortable: PropTypes.bool,
    })
  ).isRequired,
  sortColumn: PropTypes.string,
  sortDirection: PropTypes.string,
  onSortCallback: PropTypes.func,
};

TableHeader.defaultProps = {
  sortColumn: undefined,
  sortDirection: undefined,
  onSortCallback: () => {},
};

export default TableHeader;
