import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';

export const headerActionItems = (
  clientIdList,
  dispatch,
  getAllClients,
  bulkUpdate,
  setClientIdList,
  updateIdsForDelete,
  setChecked,
  setOpenActionConfirmationModal,
  setOpenBulkAddToGroup
) => [
  {
    text: 'Make Client',
    style: {},
    onClickCallback: async () => {
      setOpenActionConfirmationModal({
        text: 'Make Client',
        onClickCallback: async () => {
          const res = await dispatch(
            bulkUpdate({ updateAction: 'make_client', clientIds: clientIdList })
          );

          if (res.errors) {
            toast.error(
              `Error making client(s)! Please reach out to support.`,
              toastOptions
            );
          } else {
            toast.success(`Update successful!`, toastOptions);
            await dispatch(getAllClients());
            setClientIdList([]);
            setChecked(false);
          }
        },
      });
    },
  },
  {
    text: 'Make Lead',
    style: {},
    onClickCallback: async () => {
      setOpenActionConfirmationModal({
        text: 'Make Lead',
        onClickCallback: async () => {
          const res = await dispatch(
            bulkUpdate({ updateAction: 'make_lead', clientIds: clientIdList })
          );
          dispatch(getAllClients());
          setClientIdList([]);
          if (res.errors) {
            toast.error(
              `Error making lead(s)! Please reach out to support.`,
              toastOptions
            );
          } else {
            toast.success(`Update successful!`, toastOptions);
            await dispatch(getAllClients());
            setClientIdList([]);
            setChecked(false);
          }
        },
      });
    },
  },
  {
    text: 'Make Repeat',
    style: {},
    onClickCallback: async () => {
      setOpenActionConfirmationModal({
        text: 'Make Repeat',
        onClickCallback: async () => {
          const res = await dispatch(
            bulkUpdate({ updateAction: 'make_repeat', clientIds: clientIdList })
          );
          dispatch(getAllClients());
          setClientIdList([]);
          if (res.errors) {
            toast.error(
              `Error making repeat client(s)! Please reach out to support.`,
              toastOptions
            );
          } else {
            toast.success(`Update successful!`, toastOptions);
            await dispatch(getAllClients());
            setClientIdList([]);
            setChecked(false);
          }
        },
      });
    },
  },
  {
    text: 'Mark Active',
    style: {},
    onClickCallback: async () => {
      setOpenActionConfirmationModal({
        text: 'Make Active',
        onClickCallback: async () => {
          const res = await dispatch(
            bulkUpdate({ updateAction: 'mark_active', clientIds: clientIdList })
          );
          dispatch(getAllClients());
          setClientIdList([]);
          if (res.errors) {
            toast.error(
              `Error marking active! Please reach out to support.`,
              toastOptions
            );
          } else {
            toast.success(`Update successful!`, toastOptions);
            await dispatch(getAllClients());
            setClientIdList([]);
            setChecked(false);
          }
        },
      });
    },
  },
  {
    text: 'Mark Inactive',
    style: {},
    onClickCallback: async () => {
      setOpenActionConfirmationModal({
        text: 'Make Inactive',
        onClickCallback: async () => {
          const res = await dispatch(
            bulkUpdate({
              updateAction: 'mark_inactive',
              clientIds: clientIdList,
            })
          );
          dispatch(getAllClients());
          setClientIdList([]);
          if (res.errors) {
            toast.error(
              `Error marking inactive! Please reach out to support.`,
              toastOptions
            );
          } else {
            toast.success(`Update successful!`, toastOptions);
            await dispatch(getAllClients());
            setClientIdList([]);
            setChecked(false);
          }
        },
      });
    },
  },
  {
    text: 'Add to Group',
    style: {},
    onClickCallback: async () => {
      setOpenBulkAddToGroup(clientIdList);
    },
  },
  {
    text: 'Delete',
    style: { color: 'red' },
    onClickCallback: () => {
      dispatch(updateIdsForDelete(clientIdList));
    },
  },
];

export const rowActionItems = (
  clientId,
  dispatch,
  getAllClients,
  updateClient,
  updateIdsForDelete,
  setOpenAddToGroup,
  setOpenActionConfirmationModal
) => [
  {
    text: 'Make Client',
    style: {},
    onClickCallback: async () => {
      console.log('here');
      setOpenActionConfirmationModal({
        text: 'Make Client',
        rowAction: true,
        onClickCallback: async () => {
          const res = await dispatch(
            updateClient(clientId, {
              is_lead: false,
              is_repeat_client: false,
              is_marketing_contact: false,
            })
          );

          if (res.errors) {
            toast.error(
              `Error updating client! Please reach out to support.`,
              toastOptions
            );
          } else {
            toast.success(`Update successful!`, toastOptions);
          }
          await dispatch(getAllClients());
        },
      });
    },
  },
  {
    text: 'Make Lead',
    style: {},
    onClickCallback: async () => {
      setOpenActionConfirmationModal({
        text: 'Make Lead',
        rowAction: true,
        onClickCallback: async () => {
          const res = await dispatch(
            updateClient(clientId, {
              is_lead: true,
              is_repeat_client: false,
              is_marketing_contact: false,
            })
          );

          if (res.errors) {
            toast.error(
              `Error updating client! Please reach out to support.`,
              toastOptions
            );
          } else {
            toast.success(`Update successful!`, toastOptions);
          }
          await dispatch(getAllClients());
        },
      });
    },
  },
  {
    text: 'Make Repeat',
    style: {},
    onClickCallback: async () => {
      setOpenActionConfirmationModal({
        text: 'Make Repeat',
        rowAction: true,
        onClickCallback: async () => {
          const res = await dispatch(
            updateClient(clientId, {
              is_lead: false,
              is_repeat_client: true,
              is_marketing_contact: false,
            })
          );

          if (res.errors) {
            toast.error(
              `Error updating client! Please reach out to support.`,
              toastOptions
            );
          } else {
            toast.success(`Update successful!`, toastOptions);
          }
          await dispatch(getAllClients());
        },
      });
    },
  },
  {
    text: 'Mark Active',
    style: {},
    onClickCallback: async () => {
      setOpenActionConfirmationModal({
        text: 'Mark Active',
        rowAction: true,
        onClickCallback: async () => {
          const res = await dispatch(updateClient(clientId, { active: true }));

          if (res.errors) {
            toast.error(
              `Error updating client! Please reach out to support.`,
              toastOptions
            );
          } else {
            toast.success(`Update successful!`, toastOptions);
            await dispatch(getAllClients());
          }
        },
      });
    },
  },
  {
    text: 'Mark Inactive',
    style: {},
    onClickCallback: async () => {
      setOpenActionConfirmationModal({
        text: 'Mark Inactive',
        rowAction: true,
        onClickCallback: async () => {
          const res = await dispatch(updateClient(clientId, { active: false }));

          if (res.errors) {
            toast.error(
              `Error updating client! Please reach out to support.`,
              toastOptions
            );
          } else {
            toast.success(`Update successful!`, toastOptions);
            await dispatch(getAllClients());
          }
        },
      });
    },
  },
  {
    text: 'Add to Group',
    style: {},
    onClickCallback: async () => {
      setOpenAddToGroup(clientId);
    },
  },
  {
    text: 'Delete',
    style: { color: 'red' },
    onClickCallback: () => {
      dispatch(updateIdsForDelete([clientId]));
    },
  },
];
