import {
  useState,
  useLayoutEffect,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { setQueryStringValue, getQueryStringValue } from '~utils';

export function useWindowSize() {
  let [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export function useClickedOutside(ref, callback) {
  /**
   * Fire callback if user clicked outside element.
   */
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  }

  useEffect(() => {
    // Bind the event listener.
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up.
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

export function useQueryString(key, initialValue) {
  const [value, setValue] = useState(getQueryStringValue(key) || initialValue);
  const onSetValue = useCallback(
    newValue => {
      setValue(newValue);
      setQueryStringValue(key, newValue);
    },
    [key]
  );

  return [value, onSetValue];
}

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => setDebouncedValue(value), delay);

    return () => clearTimeout(timeout);
  }, [value, delay]);

  return debouncedValue;
}

export const useIsFirstMount = () => {
  const isFirstMountRef = useRef(true);
  useEffect(() => {
    isFirstMountRef.current = false;
  }, []);
  return isFirstMountRef.current;
};

export const useLocation = () => {
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();

  const success = position => {
    setLat(parseFloat(position.coords.latitude).toFixed(4));
    setLng(parseFloat(position.coords.longitude).toFixed(4));
  };

  const error = () => {
    console.error('Unable to get your location');
  };

  const getLocation = () => {
    if (!navigator.geolocation) {
      console.error('This browser does not support location');
    } else {
      navigator.geolocation.getCurrentPosition(success, error);
    }
  };

  useEffect(() => {
    getLocation(success, error);
  }, []);

  return [lat, lng];
};

export const usePrev = value => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

// Util for tracing prop changes
export const useTraceUpdate = props => {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', changedProps);
    }
    prev.current = props;
  });
};
