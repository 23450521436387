import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import AttachmentRow from './attachment_row';

import styles from './email_attachments.module.scss';

class Attachments extends React.PureComponent {
  static propTypes = {
    items: PropTypes.array.isRequired,
    deleteAttachment: PropTypes.func.isRequired,
    onlyShowType: PropTypes.string.isRequired,
  };

  render() {
    const { items, deleteAttachment, onlyShowType } = this.props;

    const filteredItems =
      ((onlyShowType || '').length > 0 &&
        items &&
        items.filter(i => i.item_type === onlyShowType)) ||
      items;

    const style = filteredItems.length
      ? { display: 'block' }
      : { display: 'none' };

    return (
      <div className="col-sm-10">
        <div
          className={classnames(
            'panel',
            'panel-attachments',
            styles.attachmentsList
          )}
          style={style}
        >
          <table id="email_attachments">
            <tbody>
              {filteredItems &&
                filteredItems.map((attachment, i) => (
                  <AttachmentRow
                    key={i}
                    attachment={attachment}
                    deleteAttachment={deleteAttachment}
                  />
                ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Attachments;
