import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { uniqueId } from '~utils';
import styles from './OnePageSignup.module.scss';
import SubscriptionToggle from '../Subscription/SubscriptionToggle';
import SubscriptionCard from '../Subscription/SubscriptionCard';

const OnePageSignup = () => {
  const {
    monthlyPlanBasic,
    monthlyPlanPremium,
    annualPlanBasic,
    annualPlanPremium,
    monthlyPlanBasicMarketing,
    monthlyPlanPremiumMarketing,
    annualPlanBasicMarketing,
    annualPlanPremiumMarketing,
  } = useSelector(state => state.subscription.availablePlans);

  const leftColumnDetails = [
    'Free Account Setup',
    'Workflows',
    'Contracts',
    'Invoices and Quotes',
    'Integrated Lead Page',
  ];

  const rightColumnDetails = [
    'All Basic Features',
    'Online Booking',
    'Intelligent Scheduling',
    'Custom Invitations',
  ];

  const emailMarketingDetails = [
    'Access to the Iris Email Marketing Template Library',
    'Send bulk marketing emails to clients and leads',
  ];

  const [interval, setInterval] = useState('annually');
  const [planBasicMarketing, setPlanBasicMarketing] = useState(true);
  const [planPremiumMarketing, setPlanPremiumMarketing] = useState(true);
  const [basicPlan, setBasicPlan] = useState(annualPlanBasicMarketing);
  const [premiumPlan, setPremiumPlan] = useState(annualPlanPremiumMarketing);

  const toggleOnChange = () => {
    setInterval(interval === 'monthly' ? 'annually' : 'monthly');
  };
  const toggleBasicChange = () => {
    setPlanBasicMarketing(planBasicMarketing === true ? false : true);
  };
  const togglePremiumChange = () => {
    setPlanPremiumMarketing(planPremiumMarketing === true ? false : true);
  };

  useEffect(() => {
    if (interval === 'monthly' && planBasicMarketing) {
      setBasicPlan(monthlyPlanBasicMarketing);
    }
    if (interval === 'monthly' && !planBasicMarketing) {
      setBasicPlan(monthlyPlanBasic);
    }
    if (interval !== 'monthly' && planBasicMarketing) {
      setBasicPlan(annualPlanBasicMarketing);
    }
    if (interval !== 'monthly' && !planBasicMarketing) {
      setBasicPlan(annualPlanBasic);
    }
    if (interval === 'monthly' && planPremiumMarketing) {
      setPremiumPlan(monthlyPlanPremiumMarketing);
    }
    if (interval === 'monthly' && !planPremiumMarketing) {
      setPremiumPlan(monthlyPlanPremium);
    }
    if (interval !== 'monthly' && planPremiumMarketing) {
      setPremiumPlan(annualPlanPremiumMarketing);
    }
    if (interval !== 'monthly' && !planPremiumMarketing) {
      setPremiumPlan(annualPlanPremium);
    }
  }, [planBasicMarketing, planPremiumMarketing, interval]);

  return (
    <div className={styles['OnePageSignup']}>
      <SubscriptionToggle onChangeCallback={toggleOnChange} />
      <div className={styles['OnePageSignup-cardContainer']}>
        <SubscriptionCard
          header={'Basic'}
          basic
          interval={interval}
          plan={basicPlan}
          emailMarketing
          onChangeCallback={toggleBasicChange}
        />
        <SubscriptionCard
          header="Booking"
          premium
          interval={interval}
          plan={premiumPlan}
          emailMarketing
          onChangeCallback={togglePremiumChange}
        />
      </div>
      <div className={styles['OnePageSignup-container']}>
        <div className={styles['OnePageSignup-column']}>
          {planBasicMarketing ? (
            emailMarketingDetails.map(detail => (
              <div key={uniqueId('Detail--')}>
                <i className="fa fa-check" style={{ color: 'lightgreen' }} />
                <p>{detail}</p>
              </div>
            ))
          ) : (
            <div></div>
          )}
          {leftColumnDetails.map(detail => (
            <div key={uniqueId('Detail--')}>
              <i className="fa fa-check" />
              <p>{detail}</p>
            </div>
          ))}
        </div>
        <div className={styles['OnePageSignup-column']}>
          {planPremiumMarketing ? (
            emailMarketingDetails.map(detail => (
              <div key={uniqueId('Detail--')}>
                <i className="fa fa-check" style={{ color: 'lightgreen' }} />
                <p>{detail}</p>
              </div>
            ))
          ) : (
            <div></div>
          )}
          {rightColumnDetails.map(detail => (
            <div key={uniqueId('Detail--')}>
              <i className="fa fa-check" />
              <p>{detail}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={styles['OnePageSignup--terms']}>
        <p>
          By signing up, I agree to Iris Works'{' '}
          <a
            target="_blank"
            href="https://iris-works.com/terms-and-conditions/"
          >
            Terms and Conditions
          </a>{' '}
          and{' '}
          <a target="_blank" href="https://iris-works.com/privacy-policy/">
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
};

export default OnePageSignup;
