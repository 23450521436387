import { combineReducers } from 'redux';
import calendar from './calendar';
import loading from './loading';
import errors from './errors';
import invoice from './invoice';
import invoiceConfig from './invoiceConfig';
import invoiceProducts from './invoiceProducts';
import todos from './todos';
import subscription from './subscription';
import journeyTemplates from './journeyTemplates';
import journeyTemplate from './journeyTemplate';
import bookingCalendar from './bookingCalendar';
import customerBooking from './customerBooking';
import documents from './documents';
import couponCodes from './couponCodes';
import clientList from './clientList';

export default combineReducers({
  calendar,
  loading,
  errors,
  invoice,
  invoiceConfig,
  invoiceProducts,
  todos,
  subscription,
  journeyTemplates,
  journeyTemplate,
  bookingCalendar,
  customerBooking,
  documents,
  couponCodes,
  clientList,
});
