import React, { useState } from 'react';
import styles from './GalleryIntegrations.module.scss';
import { Checkbox } from '~ui';
import axios from 'axios';
import { routes } from '~constants/routes';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';

const SHOOT_PROOF_USER = 'SHOOT_PROOF_USER';
const PIC_TIME_USER = 'PIC_TIME_USER';

const GalleryIntegrations = ({
  initialUseShootProof,
  initialUsePicTime,
  initialHasShootProof,
  initialHasPicTime,
  shootProofConnectUrl,
  picTimeConnectUrl,
}) => {
  const [useShootProof, setUseShootProof] = useState(initialUseShootProof);
  const [usePicTime, setUsePicTime] = useState(initialUsePicTime);
  const [hasShootProof, setHasShootProof] = useState(initialHasShootProof);
  const [hasPicTime, setHasPicTime] = useState(initialHasPicTime);

  const handleUseUpdate = async (integration, val) => {
    try {
      const params = {
        integration_config: {
          [integration]: val,
        },
      };

      await axios.put(routes.INTEGRATION_CONFIGS.UPDATE, params);
    } catch (error) {
      toast.error('Error updating your integration config', toastOptions);
    }
  };

  const deauthorize = async (type, params = null) => {
    try {
      const res = await axios.delete(routes[type].DELETE, { params });

      switch (type) {
        case SHOOT_PROOF_USER:
          setHasShootProof(false);
          setUseShootProof(false);
        case PIC_TIME_USER:
          setHasPicTime(false);
          setUsePicTime(false);
      }

      toast.info(res.data.message, toastOptions);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.GalleryIntegrations}>
      <h1>Gallery Integrations</h1>

      <div className={styles['GalleryIntegrations-integration']}>
        <h2>ShootProof</h2>
        <p>
          {hasShootProof ? (
            <a onClick={() => deauthorize(SHOOT_PROOF_USER)}>
              Disconnect ShootProof
            </a>
          ) : (
            <a href={shootProofConnectUrl}>Connect ShootProof</a>
          )}
        </p>
        <Checkbox
          labelText="Use ShootProof"
          checked={useShootProof}
          onChangeCallback={val => {
            handleUseUpdate('use_shoot_proof', val);
          }}
          name="useShootproof"
          disabled={!hasShootProof}
        />
      </div>
      <div className={styles['GalleryIntegrations-integration']}>
        <h2>PicTime</h2>
        <p>
          {hasPicTime ? (
            <a
              onClick={() =>
                deauthorize(PIC_TIME_USER, {
                  reason: 'Iris Works Disconnect',
                })
              }
            >
              Disconnect PicTime
            </a>
          ) : (
            <a href={picTimeConnectUrl}>Connect PicTime</a>
          )}
        </p>
        <Checkbox
          labelText="Use PicTime"
          checked={usePicTime}
          onChangeCallback={val => {
            handleUseUpdate('use_pic_time', val);
          }}
          name="usePicTime"
          disabled={!hasPicTime}
        />
      </div>
    </div>
  );
};

export default GalleryIntegrations;
