import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { Button, Dropdown, Modal, TextArea } from '~ui';
import styles from './CancelSubscriptionModal.module.scss';

const CancelSubscriptionModal = ({
  handleHide,
  currentSubscription,
  showPauseConfirmModal,
}) => {
  const [cancelSelection, setCancelSelection] = useState(null);
  const [comments, setComments] = useState('');
  const [disabled, setDisabled] = useState(false);
  const { cancelOptions } = useSelector(state => state.subscription);

  const isKeepMyData =
    currentSubscription.stripe_plan_id === 'iris-keep-my-data';

  const [page, setPage] = useState(isKeepMyData ? 2 : 1);

  const handleSubmit = () => {
    axios
      .post('/api/feedback_form_responses', {
        feedback_form_response: {
          feedback_form_option_id: Number(cancelSelection),
          details: comments || null,
          form_type: 'cancellation',
        },
      })
      .catch(err => {
        Rollbar.error(`Unable to POST feedback form data: ${err}`);
      });
    setDisabled(true);
    axios
      .delete('/api/subscriptions')
      .then(() => {
        window.location.reload();
      })
      .catch(err => {
        setDisabled(false);
        const errorMessage =
          'Unable to process cancellation, please contact support';
        toast.error(errorMessage, toastOptions);
        Rollbar.error(`${errorMessage}: ${err}`);
      });
  };

  return (
    <Modal title="Cancel Subscription" handleHide={handleHide}>
      <>
        {page == 1 && (
          <>
            <div>
              <p>We're sorry to see you wish to cancel your account!</p>
              <p>
                Instead of canceling your account, and losing your data, would
                you like to pause your account for $10/month?
              </p>
            </div>
            <div className={styles['CancelSubscriptionModal--footer']}>
              <Button
                danger
                text="Proceed to Cancel"
                onClick={() => setPage(2)}
              />
              <Button
                text="Pause my Account for $10/month"
                onClick={() => {
                  showPauseConfirmModal();
                  handleHide();
                }}
              />
            </div>
          </>
        )}
        {page == 2 && (
          <>
            <div>
              <p>
                We’re sorry to see you go! If you can share your reason for
                cancelling, it will help us create an even better experience for
                our users.
              </p>
              <p>
                Per our Terms & Conditions, you may cancel your Iris account at
                any time but will not be issued a refund.
              </p>
            </div>
            <div className={styles['CancelSubscriptionModal--input']}>
              <Dropdown
                labelText="Reason for Cancelling"
                name="cancelReason"
                required
                onChangeCallback={value => {
                  setCancelSelection(value);
                }}
                options={[
                  { label: 'Please select a reason', value: '' },
                  ...cancelOptions,
                ]}
              />
            </div>
            <div className={styles['CancelSubscriptionModal--input']}>
              <TextArea
                labelText="Additional Comments"
                name="additionalComments"
                onChangeCallback={val => {
                  setComments(val);
                }}
              />
            </div>
            <p>
              By canceling your subscription, you acknowledge that your data may
              be deleted.
            </p>
            <div className={styles['CancelSubscriptionModal--footer']}>
              {!isKeepMyData && (
                <Button white text="Back" onClick={() => setPage(1)} />
              )}
              <Button danger text="Exit" onClick={handleHide} />
              <Button
                disabled={!cancelSelection || disabled}
                text={disabled ? 'Canceling' : 'Cancel Subscription'}
                onClick={handleSubmit}
              />
            </div>
          </>
        )}
      </>
    </Modal>
  );
};

export default CancelSubscriptionModal;
