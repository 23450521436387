import {
  CLIENT_LIST,
  CLIENTS,
  GROUPS,
  DELETE_CONFIRMATION,
} from '~constants/actionTypes';

export default function (state = {}, action) {
  let clients;
  let groups;
  switch (action.type) {
    case CLIENT_LIST.GET.REQUEST:
      return { ...state, meta: { ...state.meta, status: action.payload } };
    case CLIENT_LIST.GET.SUCCESS:
      return {
        ...state,
        clients: [...action.payload.data],
        meta: { ...state.meta, ...action.payload.meta },
      };
    case CLIENT_LIST.META.UPDATE:
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.payload,
        },
      };
    case CLIENTS.CREATE.SUCCESS:
      const newClients = [action.payload, ...state.clients];

      if (newClients.length > 10) {
        newClients.pop();
      }
      return { ...state, clients: newClients };
    case CLIENTS.DELETE.SUCCESS:
      clients = state.clients.filter(client => client.id != action.payload.id);

      return { ...state, clients };
    case CLIENTS.UPDATE.SUCCESS:
      clients = state.clients.map(client => {
        if (client.id == action.payload.id) {
          return {
            ...client,
            attributes: { ...action.payload },
          };
        }
        return client;
      });
      return { ...state, clients: clients };
    case GROUPS.GET.SUCCESS:
      return {
        ...state,
        groups: [...action.payload.data],
        meta: { ...state.meta, ...action.payload.meta },
      };
    case GROUPS.CREATE.SUCCESS:
      const newGroups = [action.payload, ...state.groups];

      if (newGroups.length > 10) {
        newGroups.pop();
      }
      return { ...state, groups: newGroups };
    case GROUPS.DELETE.SUCCESS:
      groups = state.groups.filter(group => group.id != action.payload.id);

      return { ...state, groups };
    case GROUPS.UPDATE.SUCCESS:
      groups = state.groups.map(group => {
        if (group.id == action.payload.id) {
          return {
            ...group,
            attributes: { ...action.payload },
          };
        }
        return group;
      });
      return { ...state, groups: groups };
    case DELETE_CONFIRMATION.META.UPDATE:
      return {
        ...state,
        idsForDelete: [...action.payload],
      };
    default:
      return state;
  }
}
