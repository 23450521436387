import React from 'react';
import { Modal, Button } from '~ui';
import axios from 'axios';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';

const ConfirmRemove = props => {
  const {
    handleHide,
    setOpenModal,
    setSelectedGroup,
    selectedGroup,
    client_id,
    setLoading,
  } = props;

  const removeFromGroup = async () => {
    setLoading(true);

    try {
      await axios.patch(
        `/api/groups/${selectedGroup.value}/remove_group_client`,
        {
          id: selectedGroup.value,
          client_id: client_id,
        }
      );
      setOpenModal(false);
      setSelectedGroup(undefined);
      handleHide(false);
      setLoading(false);
      toast.success('Removed!', toastOptions);
    } catch (error) {
      setOpenModal(false);
      setSelectedGroup(undefined);
      handleHide(false);
      setLoading(false);
      toast.error('There was an error removing!', toastOptions);
    }
  };

  return (
    <Modal title="Are you sure?" handleHide={() => handleHide(false)}>
      <div className="mb-8">
        Would you like to remove this client from the selected group?
      </div>
      <br />
      <div className="d-fr">
        <Button
          className="mr-8"
          text="Cancel"
          onClick={e => {
            e.stopPropagation();
            handleHide();
          }}
          danger
        />
        <Button
          text="Remove"
          onClick={e => {
            e.stopPropagation();
            removeFromGroup();
            handleHide();
          }}
        />
      </div>
    </Modal>
  );
};

export default ConfirmRemove;
