import React, { useRef, useState } from 'react';
import axios from 'axios';
import styles from './StudioProfile.module.scss';
import { TextInput, Button } from '~ui';
import SignatureCanvas from 'react-signature-canvas';
import { useWindowSize } from '~hooks';
import { routes } from '~constants/routes';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';

const ContractSignature = props => {
  const {
    initialSignature,
    initialDrawnSignature,
    studioId,
    updateStudioProps,
  } = props;
  const canvasRef = useRef();
  const [typedSignature, setTypedSignature] = useState(initialSignature);
  const [drawnSignature, setDrawnSignature] = useState(initialDrawnSignature);

  const windowWidth = useWindowSize()[0];
  const canvasWidth =
    windowWidth <= 680 ? windowWidth - 60 : windowWidth / 2 - 10;
  const clearCanvas = () => {
    canvasRef.current.clear();
    setDrawnSignature(undefined);
  };

  const updateEmailSignatures = async () => {
    await axios
      .put(routes.STUDIOS.UPDATE(studioId), {
        signature: typedSignature,
        drawn_signature: drawnSignature,
      })
      .then(res => {
        toast.success('Contract signature successfully saved.', toastOptions);
        clearCanvas();
        updateStudioProps(res);
      });
  };

  return (
    <div className={styles['StudioProfile-signature']}>
      <h2>Contract Signature</h2>
      <p>
        These signatures are used in your contracts so that you don't have to
        re-sign for every client. Please fill out both fields.
      </p>
      <br />
      <TextInput
        name="typed_signature"
        className={styles['StudioProfile-signature-textInput']}
        labelText="Type Signature"
        placeholder="Please type your name"
        onChangeCallback={setTypedSignature}
        initialValue={typedSignature}
        required
      />
      <br />
      <div className={styles['StudioProfile-signatureHeader']}>
        <label>
          Draw Signature<span className="c-cr">*</span>
        </label>
        <span
          className={styles['StudioProfile-signature-clear']}
          onClick={clearCanvas}
        >
          Clear
        </span>
      </div>
      <SignatureCanvas
        canvasProps={{
          width: canvasWidth,
          height: 200,
          className: styles['StudioProfile-signature-canvas'],
        }}
        ref={canvasRef}
        onEnd={() => setDrawnSignature(canvasRef.current.toDataURL())}
      />

      {initialDrawnSignature && (
        <>
          <h3>Existing Signature</h3>
          <img src={initialDrawnSignature} alt="Current signature" />
        </>
      )}
      <div className={styles['StudioProfile-signature-buttonContainer']}>
        <Button
          className="mr-12"
          text="Save"
          onClick={() => updateEmailSignatures()}
        />
      </div>
    </div>
  );
};

export default ContractSignature;
