import React, { useState } from 'react';
import { CurrencyInput, PercentageInput, TextInput } from '~ui';
import styles from './AddPaymentScheduleTemplateModal.module.scss';
import classNames from 'classnames/bind';

const RetainerAndMultipleFields = ({
  retainerAmount,
  setRetainerAmount,
  numberOfPayments,
  setNumberOfPayments,
}) => {
  const [selected, setSelected] = useState('currency');

  const handleChange = val => {
    setRetainerAmount({ type: selected, value: +val });
  };

  const cx = classNames.bind(styles);
  return (
    <>
      <>
        <label>Retainer Amount</label>
        <div className={styles.Retainer}>
          <div className={styles['Retainer-toggles']}>
            <button
              onClick={() => setSelected('currency')}
              className={cx('Retainer-currency', {
                'Retainer-currency--selected': selected === 'currency',
              })}
            >
              {window.app.invoices.currency}
            </button>
            <button
              onClick={() => setSelected('percent')}
              className={cx('Retainer-percent', {
                'Retainer-percent--selected': selected === 'percent',
              })}
            >
              %
            </button>
          </div>

          {selected === 'currency' ? (
            <CurrencyInput
              name="retainer_amount"
              initialValue={retainerAmount}
              onChangeCallback={handleChange}
              max={50000}
              hideIcon
            />
          ) : null}

          {selected === 'percent' ? (
            <PercentageInput
              name="retainer_percent"
              onChangeCallback={handleChange}
              hideIcon
            />
          ) : null}
        </div>
      </>
      <TextInput
        name="number_of_additional_payments"
        labelText="Number of Additional Payments"
        initialValue={numberOfPayments}
        onChangeCallback={setNumberOfPayments}
      />
    </>
  );
};

export default RetainerAndMultipleFields;
