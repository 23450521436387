import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DataTable, Pill } from '~ui';

const InstallmentTemplatesTable = ({ installments }) => {
  let dateContext = moment();
  const INTERVAL_OPTIONS_TO_MOMENT_KEYS = {
    day: 'd',
    week: 'w',
    month: 'M',
    year: 'y',
  };

  const calculateDueDate = installment => {
    dateContext = dateContext
      .clone()
      .add(
        installment.interval_amount,
        INTERVAL_OPTIONS_TO_MOMENT_KEYS[installment.interval]
      );
    return dateContext;
  };

  const normalizedInstallments = installments.map(installment => ({
    due_date: calculateDueDate(installment),
    amount: installment.amount,
    status: () => (
      <Pill text={'Unpaid'} className="va-t" color="orange" small />
    ),
  }));
  const headers = [
    { name: 'due_date', labelText: 'Due Date', sortable: false },
    { name: 'amount', labelText: 'Amount', sortable: false },
    { name: 'status', labelText: 'Status', sortable: false },
  ];
  const columnTypes = ['date', 'currency', 'function'];

  return (
    <DataTable
      title="Payments"
      rows={normalizedInstallments}
      headers={headers}
      columnTypes={columnTypes}
    />
  );
};

InstallmentTemplatesTable.propTypes = {
  installments: PropTypes.array.isRequired,
};

export default InstallmentTemplatesTable;
