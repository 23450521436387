import React, { useState } from 'react';
import { Button, TextInput, Modal } from '~ui';
import styles from './InvoiceSetup.module.scss';

const PayPalModal = ({
  handleHide,
  handleUpdate,
  paypalAddress,
  hasPayPal,
}) => {
  const [address, setAddress] = useState(paypalAddress);

  return (
    <Modal title="PayPal Settings" handleHide={handleHide}>
      <TextInput
        name="paypal_address"
        labelText="Email Address"
        initialValue={address}
        onChangeCallback={setAddress}
      />
      {hasPayPal ? (
        <div className={styles.PayPalModalButtons}>
          <Button
            danger
            text="Disconnect"
            onClick={() => {
              handleUpdate({
                use_paypal: false,
                paypal_address: address,
              }).then(() => {
                handleHide(true);
              });
            }}
          />
          <Button
            disabled={address === paypalAddress || !address}
            text="Save"
            onClick={() => {
              handleUpdate({
                use_paypal: true,
                paypal_address: address,
              }).then(() => {
                handleHide(true);
              });
            }}
          />
        </div>
      ) : (
        <div className={styles.PayPalModalButtons}>
          <Button
            text="Connect"
            disabled={!address}
            onClick={() => {
              handleUpdate({
                use_paypal: true,
                paypal_address: address,
              }).then(() => {
                handleHide(true);
              });
            }}
          />
        </div>
      )}
    </Modal>
  );
};

export default PayPalModal;
