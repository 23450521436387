import React, { useState } from 'react';
import CustomOrderElement from './CustomOrderElement';
import styles from './LeadsPageSettings.module.scss';

const SessionList = ({ sessionTypes, setSessionTypes }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const moveElement = (fromIndex, toIndex) => {
    const newArray = [...sessionTypes];
    const [movedItem] = newArray.splice(fromIndex, 1);
    newArray.splice(toIndex, 0, movedItem);
    setSessionTypes(newArray);
  };

  return (
    <div className={styles.SessionListCard}>
      {sessionTypes.length > 0 ? (
        <>
          <p>Custom Session Order</p>
          {sessionTypes.map((type, index) => (
            <CustomOrderElement
              key={index}
              index={index}
              initialValue={type}
              moveElement={moveElement}
              setHoveredIndex={setHoveredIndex}
              isHovered={index === hoveredIndex}
            />
          ))}
        </>
      ) : (
        <p>
          Please create session types before trying to configure your own order.
        </p>
      )}
    </div>
  );
};

export default SessionList;
