import React from 'react';
import MappingDropdown from '../MappingDropdown';
import FieldName from '../FieldName';
import styles from './EmailInput.module.scss';
import DraggableInput from '../../draggable/DraggableInput';

const EmailInput = ({
  updateInputData,
  index,
  data,
  handleRemove,
  reOrderHandler,
  title,
  errors,
}) => {
  const handleDropdown = value => {
    if (value != data.client_mapping) {
      updateInputData(index, { client_mapping: value });
    }
  };

  const handleRadio = value => {
    if (value != data.is_required) {
      updateInputData(index, { is_required: value });
    }
  };

  return (
    <DraggableInput
      index={index}
      handleRemove={handleRemove}
      reOrderHandler={reOrderHandler}
      title={title}
    >
      <div>
        <FieldName
          name="phoneNumberInputLabel"
          initialValue={data.label}
          onChangeCallback={val => updateInputData(index, { label: val })}
          errors={errors}
        />
        <div className={styles.EmailInputStyles} disabled>
          <i className="far fa-envelope" disabled></i> username@email.com
        </div>
        <MappingDropdown
          radioCallback={handleRadio}
          dropdownCallback={handleDropdown}
          index={index}
          initialRadioValue={data.is_required || false}
          initialMappingValue={data.client_mapping}
          options={[
            { label: 'None', value: null },
            { label: 'Primary Email', value: 'email' },
            { label: 'Alternate Email', value: 'alternate_email' },
            { label: 'Client notes', value: 'notes' },
          ]}
        />
      </div>
    </DraggableInput>
  );
};

export default EmailInput;
