/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './Button.module.scss';

const Button = props => {
  const {
    className,
    danger,
    icon,
    loading,
    logo,
    onClick,
    submit,
    text,
    white,
    whiteDanger,
    small,
    disabled,
    category,
    ariaLabel,
    animate,
    style,
    purpleRectangle,
    whiteRectangle,
  } = props;
  const hasClassName = !!className;

  const cx = classNames.bind(styles);
  const classes = cx('Button', {
    'Button--danger': danger,
    'Button--icon': !!icon,
    'Button--loading': loading,
    'Button--logo': logo,
    'Button--white': white,
    'Button--whiteDanger': whiteDanger,
    'Button--small': small,
    'Button--disabled': disabled,
    'Button--animated': animate,
    'Button--purpleRectangle': purpleRectangle,
    'Button--whiteRectangle': whiteRectangle,
    OutlinedButton: category === 'outlined',
    IconButton: category === 'icon',
    [className]: hasClassName,
  });

  return (
    <button
      type={submit ? 'submit' : 'button'}
      onClick={onClick}
      className={classes}
      disabled={disabled || loading}
      aria-label={ariaLabel}
      style={style ? style : null}
    >
      {icon && (
        <>
          <i className={`fa fa-${icon}`} /> <span> </span>
        </>
      )}
      {category !== 'icon' && <span>{text}</span>}
      {logo && <img src={logo} height="18" width="auto" alt={String(logo)} />}
      {loading && <span className="Spinner" />}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  loading: PropTypes.bool,
  logo: PropTypes.string,
  onClick: PropTypes.func,
  danger: PropTypes.bool,
  submit: PropTypes.bool,
  text: PropTypes.string,
  white: PropTypes.bool,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  category: PropTypes.string,
  animate: PropTypes.bool,
};

Button.defaultProps = {
  className: null,
  icon: null,
  loading: false,
  onClick: () => {},
  danger: false,
  submit: false,
  text: null,
  white: false,
  whiteDanger: false,
  small: false,
  disabled: false,
  category: null,
  ariaLabel: undefined,
  animate: false,
};

export default Button;
