import React, { useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import { ButtonGroupInput, Icon, PercentageInput, CurrencyInput } from '~ui';
import { toCurrencyAmount, currencyIcon } from '~utils';

import styles from './InvoiceTotals.module.scss';

const Discount = props => {
  const {
    isEditing,
    setEditingDiscount,
    setDiscountEnabled,
    invoice,
    handleUpdateDiscount,
    handleDeleteDiscount,
  } = props;
  const { discount } = invoice.totals;
  const { percent_based_discount, discount_amount } = invoice;
  const [discountAmount, setDiscountAmount] = useState(discount_amount);
  const [discountIsPercent, setDiscountIsPercent] = useState(
    percent_based_discount
  );
  const PERCENT_BUTTON_INDEX = 1;

  const buttons = [
    {
      icon: currencyIcon(),
      ariaLabel: 'Use specific amount',
      input: () => (
        <CurrencyInput
          hideIcon
          name="discount_amount"
          max={10000}
          characterLimit={6}
          onChangeCallback={setDiscountAmount}
          initialValue={percent_based_discount ? 0 : discount_amount}
        />
      ),
    },
    {
      icon: 'percent',
      ariaLabel: 'Use percent amount',
      input: () => (
        <PercentageInput
          hideIcon
          name="discount_amount"
          characterLimit={6}
          onChangeCallback={setDiscountAmount}
          initialValue={percent_based_discount ? discount_amount : 0}
        />
      ),
    },
  ];

  const renderDiscountInput = () => (
    <>
      <span className={styles['InvoiceTotals-lineValue']}>
        <ButtonGroupInput
          buttons={buttons}
          initialValue={percent_based_discount ? 1 : 0}
          onButtonClickCallback={index => {
            if (index === PERCENT_BUTTON_INDEX) {
              return setDiscountIsPercent(true);
            }
            return setDiscountIsPercent(false);
          }}
        />
      </span>
      <span className={styles['InvoiceTotals-lineBuffer']}>
        <Icon
          name="check"
          ariaLabel="Save Discount"
          onClickCallback={() =>
            handleUpdateDiscount(discountAmount, discountIsPercent).then(() =>
              setEditingDiscount(false)
            )
          }
        />
        <Icon
          name="times"
          ariaLabel="Cancel Edit Discount"
          onClickCallback={() => setEditingDiscount(false)}
        />
      </span>
    </>
  );

  const renderDiscountLine = () => (
    <>
      <span
        className={styles['InvoiceTotals-lineValue']}
      >{`- ${toCurrencyAmount(discount)}`}</span>
      {!invoice.paid_in_full && (
        <span className={styles['InvoiceTotals-lineBuffer']}>
          <Icon
            name="pencil-alt"
            ariaLabel="Edit Discount"
            onClickCallback={() => setEditingDiscount(true)}
          />
          <Icon
            name="trash-alt"
            ariaLabel="Remove Discount"
            onClickCallback={() =>
              handleDeleteDiscount().then(() => {
                setDiscountEnabled(false);
                setEditingDiscount(false);
              })
            }
          />
        </span>
      )}
    </>
  );

  return (
    <div className={styles['InvoiceTotals-line']}>
      <span className={styles['InvoiceTotals-lineLabel']}>Discount</span>
      {isEditing ? renderDiscountInput() : renderDiscountLine()}
    </div>
  );
};

export default Discount;
