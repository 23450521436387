import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TableNew as Table } from '~ui';
import { API_DATE_FORMAT } from '~constants/datetime';
import {
  getTodos,
  createTodo,
  updateTodo,
  completeTodo,
  uncompleteTodo,
  deleteTodo,
} from '~actions/todos';
import { actionsLoadingSelector } from '~selectors';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { useWindowSize } from '~hooks';
import { MOBILE_WIDTH } from '~constants/viewports';
import { isMobile } from '~constants/userAgent';
import styles from './ToDos.module.scss';

const ToDos = () => {
  const dispatch = useDispatch();
  const { items, clients, client, meta } = useSelector(state => state?.todos);
  const windowWidth = useWindowSize()[0];
  const mobileScreen = windowWidth <= MOBILE_WIDTH || isMobile();

  const isLoading = useSelector(state =>
    actionsLoadingSelector(state, ['TODOS_GET'])
  );

  const [currentFilterIndex, setCurrentFilterIndex] = useState(
    client !== undefined ? 0 : 1
  );
  const [getParams, setGetParams] = useState();
  const [rows, setRows] = useState([]);

  const createEventTitle = event => {
    const title = event.title || '';

    if (event.status && event.status === 'needs_rescheduled') {
      const displayTitle =
        title.length > 15 ? `${title.substring(0, 15)}...` : title;
      return (
        <p>
          <strong>Needs Rescheduled: </strong> {displayTitle}
        </p>
      );
    } else {
      const displayTitle =
        title.length > 25 ? `${title.substring(0, 25)}...` : title;
      return <p>{displayTitle}</p>;
    }
  };

  const createMobileRowItem = (
    id = null,
    temp = false,
    is_completed,
    description,
    due_date,
    client_id
  ) => {
    const rowItem = {
      id,
      editable: true,
      deletable: true,
      temp,
      data: {
        checked: {
          value: is_completed,
          type: 'checkbox',
          editable: true,
        },
        description: {
          value: description,
          type: 'string',
          editable: true,
        },
        due_date: {
          value: due_date,
          type: 'datetime',
          editable: true,
        },
        ...(client === undefined && {
          client_id: {
            value: client_id,
            type: 'enum-link',
            editable: true,
            options: clients,
            route: '/clients',
          },
        }),
      },
    };

    return rowItem;
  };

  const createRowItem = (
    id = null,
    temp = false,
    is_completed,
    description,
    due_date,
    client_id,
    calendar_event = null
  ) => {
    const rowItem = {
      id,
      editable: true,
      deletable: true,
      temp,
      data: {
        checked: {
          value: is_completed,
          type: 'checkbox',
          editable: true,
        },
        description: {
          value: description,
          type: 'string',
          editable: true,
        },
        due_date: {
          value: due_date,
          type: 'datetime',
          editable: true,
        },
        ...(client === undefined && {
          client_id: {
            value: client_id,
            type: 'enum-link',
            editable: true,
            options: clients,
            route: '/clients',
          },
        }),
      },
    };

    rowItem.data['calendar_event'] = {
      value: createEventTitle(calendar_event),
      type: 'enum',
      editable: false,
    };

    return rowItem;
  };

  const schema = createRowItem(null, true, false, '', '', null, '');

  const dropdownOptions = [
    {
      label: 'All',
      onClickCallback: () => {
        const params = {};
        if (client !== undefined) {
          params.client_id = client.id;
          setGetParams(params);
        } else {
          setGetParams(undefined);
        }
        dispatch(getTodos(params));
        setCurrentFilterIndex(0);
      },
    },
    {
      label: 'Today',
      onClickCallback: () => {
        const today = moment().format(API_DATE_FORMAT);
        const params = {
          due_date: {
            end: today,
          },
        };
        if (client !== undefined) params.client_id = client.id;
        setGetParams(params);
        dispatch(getTodos(params));
        setCurrentFilterIndex(1);
      },
    },
    {
      label: 'Week',
      onClickCallback: () => {
        const today = moment();
        const sixDaysFromNow = today.add(6, 'd').format(API_DATE_FORMAT);
        const params = {
          due_date: {
            end: sixDaysFromNow,
          },
        };
        if (client !== undefined) params.client_id = client.id;
        setGetParams(params);
        dispatch(getTodos(params));
        setCurrentFilterIndex(2);
      },
    },
    {
      label: 'Completed',
      onClickCallback: () => {
        const params = {
          status: 'completed',
        };
        if (client !== undefined) params.client_id = client.id;
        setGetParams(params);
        dispatch(getTodos(params));
        setCurrentFilterIndex(3);
      },
    },
  ];

  const headers = () => {
    if (client === undefined) {
      if (mobileScreen) {
        return ['', 'Description', 'Due Date', 'Client'];
      }
      return ['', 'Description', 'Due Date', 'Client', 'Calendar Event'];
    } else {
      if (mobileScreen) {
        return ['', 'Description', 'Due Date'];
      }
      return ['', 'Description', 'Due Date', 'Calendar Event'];
    }
  };

  useEffect(() => {
    const newRows = items.map(item => {
      const calendarEvent = item.calendar_event
        ? item.calendar_event
        : { title: 'None' };

      if (mobileScreen) {
        return createMobileRowItem(
          item.id,
          false,
          item.is_completed,
          item.description,
          item.due_date,
          item.client_id
        );
      } else {
        return createRowItem(
          item.id,
          false,
          item.is_completed,
          item.description,
          item.due_date,
          item.client_id,
          calendarEvent
        );
      }
    });
    setRows([...newRows]);
  }, [items, mobileScreen]);

  useEffect(() => {
    dropdownOptions[currentFilterIndex].onClickCallback();
  }, []);

  const asyncCreate = async params => dispatch(createTodo(params));
  const asyncUpdate = async (id, params) => dispatch(updateTodo(id, params));
  const asyncDelete = async id => dispatch(deleteTodo(id));
  const asyncUncomplete = async id => dispatch(uncompleteTodo(id));
  const asyncComplete = async id => dispatch(completeTodo(id));

  const handleCreate = async todo => {
    const { client_id, description, due_date } = todo;

    if (description === '' || due_date === '' || !due_date || !description) {
      toast.error('Description and due date are required', toastOptions);

      return false;
    }

    const formatted_due_date = due_date['_d'].toISOString().split('T')[0];

    const wrappedCreateParams = {
      to_do: {
        client_id: client !== undefined ? client.id : client_id || 'None',
        description,
        due_date: formatted_due_date,
      },
    };

    wrappedCreateParams.to_do['todo-search'] = '';

    asyncCreate(wrappedCreateParams).then(() => {
      dispatch(getTodos(getParams));
    });

    return true;
  };

  const handleUpdate = async todo => {
    const { id, body } = todo;
    const { client_id, description, due_date } = body;

    if (description === '' || due_date === '') {
      toast.error(
        `The following fields are required: ${
          description === '' ? 'description' : ''
        } ${due_date === '' ? ', due_date' : ''}`,
        toastOptions
      );

      return false;
    }

    const wrappedParams = {
      to_do: {
        client_id,
        description,
        due_date,
      },
    };

    wrappedParams.to_do['todo-search'] = '';

    asyncUpdate(id, wrappedParams).then(() => {
      dispatch(getTodos(getParams));
    });

    return true;
  };

  const handleDelete = item => {
    const { id } = item;

    if (!id) return;

    asyncDelete(id).then(() => {
      dispatch(getTodos(getParams));
    });
  };

  const handleUncomplete = id => {
    asyncUncomplete(id).then(() => {
      const params = { ...getParams, page: meta.page };
      dispatch(getTodos(params));
    });
  };

  const handleComplete = id => {
    asyncComplete(id).then(() => {
      const params = { ...getParams, page: meta.page };
      dispatch(getTodos(params));
    });
  };

  const handleCheck = (rowIndex, value) => {
    const row = rows[rowIndex];
    const { id } = row;
    !!value ? handleComplete(id) : handleUncomplete(id);
  };

  const editFunctions = [
    {
      name: 'checked',
      fn: handleCheck,
    },
  ];

  const hooks = {
    delete: handleDelete,
    update: handleUpdate,
    create: handleCreate,
  };

  return (
    <div
      className={styles.ToDos}
      style={client !== undefined ? { marginTop: '50px' } : {}}
    >
      <Table
        editFunctions={editFunctions}
        headers={headers()}
        hooks={hooks}
        rows={rows}
        schema={schema}
        setRows={setRows}
        title={isLoading ? 'Loading Todos...' : `Todos (${meta.total_records})`}
        dropdownButtonProps={{
          options: dropdownOptions,
          label: dropdownOptions[currentFilterIndex].label,
        }}
        searchInputProps={{
          name: 'todo-search',
          onChangeCallback: searchValue => {
            const params = { ...getParams, search_term: searchValue };
            setGetParams(params);
            dispatch(getTodos(params));
          },
        }}
        page={meta.page}
        pageCount={meta.page_count}
        totalRecords={meta.total_records}
        pageChangeCallback={newPageIndex => {
          dispatch(getTodos({ ...getParams, page: newPageIndex }));
        }}
        type="static"
        loading={isLoading}
        floatNewRowToTop
      />
    </div>
  );
};

export default ToDos;
