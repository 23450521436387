import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '../../store';
import OnePageSignup from './OnePageSignup';
import IRIS_LOGO from '~images/logo.png';
import styles from './OnePageSignup.module.scss';

const OnePageSignupRoot = props => {
  const store = configureStore(props);

  return (
    <Provider store={store}>
      <div className={styles['OnePageSignup-logo']}>
        <img src={IRIS_LOGO}></img>
      </div>
      <OnePageSignup />
    </Provider>
  );
};

export default OnePageSignupRoot;
