import axios from 'axios';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';

export const headerActionItems = (
  groupIdList,
  dispatch,
  updateIdsForDelete
) => [
  {
    text: 'Delete',
    style: { color: 'red' },
    onClickCallback: () => {
      dispatch(updateIdsForDelete(groupIdList));
    },
  },
];

export const rowActionItems = (
  groupId,
  dispatch,
  updateIdsForDelete,
  exportName
) => [
  {
    text: 'Export to CSV',
    style: {},
    onClickCallback: async () => {
      const res = await axios.get(
        `/api/group_clients/grab_group_clients?group_id=${groupId}`
      );
      if (res.data.length > 0) {
        window.location.href = `/api/clients/export/${exportName}`;
      } else {
        toast.info(
          `This group doesn't have any clients to export.`,
          toastOptions
        );
      }
    },
  },
  {
    text: 'Delete',
    style: { color: 'red' },
    onClickCallback: () => {
      dispatch(updateIdsForDelete([groupId]));
    },
  },
];
