import React from 'react';
import LeadsPagePreview from './LeadsPagePreview';
import styles from './LeadsPageRoot.module.scss';
import LeadsPageSettings from './LeadsPageSettings';
import LeadsPageShare from './LeadsPageShare';

const LeadsPageRoot = props => {
  return (
    <div className={styles.LeadsPageRoot}>
      <div className={styles['LeadsPageRoot-header']}>
        <h1>Leads Page</h1>
        <h3>Capture Leads on Iris Works</h3>
        <LeadsPageSettings {...props} />
        <LeadsPagePreview {...props} />
        <LeadsPageShare {...props} />
      </div>
    </div>
  );
};

export default LeadsPageRoot;
