import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '../../store';
import InvoiceSetup from '~components/InvoiceSetup';

const InvoiceSetupRoot = props => {
  const store = configureStore(props);

  return (
    <Provider store={store}>
      <InvoiceSetup />
    </Provider>
  );
};

export default InvoiceSetupRoot;
