import React, { useState } from 'react';
import { TextInput, Button, Modal, Checkbox } from '~ui';
import styles from './LeadsPageSettings.module.scss';

const CustomFieldsModal = props => {
  const { setCustomFields, customFields, handleHide } = props;

  const customFieldsFilter = customFields.filter(f => !f.destroy);

  const [customFieldsModalData, setCustomFieldsModalData] = useState(
    customFieldsFilter.length > 0
      ? customFields
      : [
        ...customFields,
        {
          id: `newField${customFields.length + 1}`,
          fieldName: '',
          required: false,
        },
      ]
  );
  return (
    <Modal
      title="Add Custom Field(s)"
      handleHide={() => {
        handleHide();
      }}
    >
      <div className={styles['LeadsPageSettings-customFieldModal']}>
        <p>You can add up to 2 custom fields</p>
        {customFieldsModalData.map((fieldData, index) => (
          <React.Fragment key={index}>
            {!fieldData.destroy && (
              <div
                key={index}
                className={styles['LeadsPageSettings-customFieldModal-inputs']}
              >
                <TextInput
                  labelText="Field Name"
                  name="fieldName"
                  initialValue={fieldData.fieldName}
                  onChangeCallback={val => {
                    const copy = customFieldsModalData;
                    copy[index].fieldName = val;

                    setCustomFieldsModalData([...copy]);
                  }}
                />
                <Checkbox
                  labelText="Mark required"
                  name="required"
                  checked={fieldData.required}
                  onChangeCallback={val => {
                    const copy = customFieldsModalData;
                    copy[index].required = val;

                    setCustomFieldsModalData([...copy]);
                  }}
                />
              </div>
            )}
          </React.Fragment>
        ))}
        {customFieldsModalData.filter(f => !f.destroy).length === 1 && (
          <a
            onClick={() =>
              setCustomFieldsModalData([
                ...customFieldsModalData,
                { fieldName: '', required: false },
              ])
            }
          >
            +Add another field
          </a>
        )}
        <div className={styles['LeadsPageSettings-customFieldModal-buttons']}>
          <Button whiteDanger text="Cancel" onClick={handleHide} />
          <Button
            disabled={!customFieldsModalData.every(field => field.fieldName)}
            text="Add Field(s)"
            onClick={() => {
              setCustomFields([...customFieldsModalData]);
              handleHide();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CustomFieldsModal;
