import axios from 'axios';
import { routes } from '~constants/routes';
import { CONTRACT_TEMPLATES } from '~constants/actionTypes';

export const getContractTemplates = () => async (dispatch, getState) => {
  try {
    const { meta } = getState().documents.contracts;
    const { page, limit } = meta;
    dispatch({ type: CONTRACT_TEMPLATES.GET.REQUEST });

    const { data } = await axios.get(routes.CONTRACT_TEMPLATES.INDEX, {
      params: { page, limit, sort: { column: 'name', direction: 'asc' } },
    });

    dispatch({
      key: 'contracts',
      type: CONTRACT_TEMPLATES.GET.SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTRACT_TEMPLATES.GET.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};

export const updateContractTemplatesMeta = params => ({
  type: CONTRACT_TEMPLATES.META.UPDATE,
  payload: params,
});
