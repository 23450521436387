import React, { useState, useLayoutEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import moment from 'moment';

const Timer = memo(props => {
  const { minutes, seconds, onExpireCallback } = props;
  const initialTimeRemaining = minutes * 60 + seconds;
  const LOCAL_STORAGE_KEY = 'TIMER_SECONDS';
  const START_TIME_SS_KEY = 'START_TIME';
  const [timeRemaining, setTimeRemaining] = useState(
    window.sessionStorage.getItem(LOCAL_STORAGE_KEY) || initialTimeRemaining
  );

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      // This will calculate the amount of time that has elapsed, regardless if the
      // setTimeout is delayed from window out of focus
      const nowToTimeFromStorageDiff = moment().diff(
        moment(window.sessionStorage.getItem(START_TIME_SS_KEY)),
        'seconds'
      );

      if (
        timeRemaining < 1 ||
        nowToTimeFromStorageDiff > initialTimeRemaining
      ) {
        onExpireCallback && onExpireCallback();
        window.sessionStorage.removeItem(LOCAL_STORAGE_KEY);
        window.sessionStorage.removeItem(START_TIME_SS_KEY);
        return;
      }

      window.sessionStorage.setItem(
        LOCAL_STORAGE_KEY,
        String(timeRemaining - 1)
      );

      setTimeRemaining(timeRemaining - 1);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  });

  const setInitialSessionStorageTime = () => {
    window.sessionStorage.setItem(START_TIME_SS_KEY, String(moment()));
  };

  const calculateTimeFromSessionStorage = () => {
    const startTime = window.sessionStorage.getItem(START_TIME_SS_KEY);

    const timeElapsed = moment().diff(moment(startTime), 'seconds');

    setTimeRemaining(initialTimeRemaining - timeElapsed);
  };

  useEffect(() => {
    if (!window.sessionStorage.getItem(START_TIME_SS_KEY)) {
      setInitialSessionStorageTime();
    }
    window.addEventListener('focus', calculateTimeFromSessionStorage);

    return () => {
      window.removeEventListener('focus', calculateTimeFromSessionStorage);
    };
  }, []);

  const renderMinutes = () => Math.floor(timeRemaining / 60);

  const renderSeconds = () => {
    const secondsRemaining = timeRemaining % 60;

    if (secondsRemaining < 10) {
      return `0${secondsRemaining}`;
    }

    return secondsRemaining;
  };

  return (
    <h1>
      {renderMinutes()}:{renderSeconds()}
    </h1>
  );
});

Timer.propTypes = {
  minutes: PropTypes.number.isRequired,
  seconds: PropTypes.number.isRequired,
  onExpireCallback: PropTypes.func,
};

export default Timer;
