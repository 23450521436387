import React, { useState } from 'react';
import { Dropdown } from '~ui';
import axios from 'axios';
import styles from './CSVExport.module.scss';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';

const CSVExport = ({ years }) => {
  const [year, setYear] = useState(years[0]);

  const handleExport = async isYear => {
    let url = '/api/invoices/csv_summary';

    if (isYear) {
      url += `?year=${year}`;
    }

    try {
      const res = await axios.get(url);
      toast.success(res.data.data, toastOptions);
    } catch (error) {
      toast.error('There was an error sending your CSV Data', toastOptions);
    }
  };

  return (
    <div className={styles.CSVExport}>
      <Dropdown
        name="years"
        labelText="Choose a year"
        options={years.map(y => ({ label: y, value: y }))}
        onChangeCallback={val => setYear(val)}
      />
      <div className={styles['CSVExport-links']}>
        <a onClick={() => handleExport(true)}>Export Year to CSV</a>
        <a onClick={() => handleExport()}>Export Full Report to CSV</a>
      </div>
    </div>
  );
};

export default CSVExport;
