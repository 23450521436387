export const get = (object, path, defaultValue = null) =>
  path.reduce(
    (item, key) => (item && item[key] ? item[key] : defaultValue),
    object
  );

export const isEmpty = object => {
  for (var i in object) {
    return false;
  }
  return true;
};
