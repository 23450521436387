import React from 'react';
import classNames from 'classnames/bind';
import { useDrop } from 'react-dnd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import {
  listBookingAvailabilities,
  createBookingAvailability,
  updateBookingAvailability,
} from '~actions/bookingAvailabilities';
import { listCalendarEvents } from '~actions/calendarEvents';
import { updateActiveSlot } from '~actions/bookingCalendar';
import { uniqueId } from '~utils';
import { DATE_KEY_FORMAT } from '~constants/datetime';
import styles from './TimeSlot.module.scss';
import ItemTypes from '../ItemTypes';
import CalendarEvent from '../CalendarEvent';
import CronofyEvent from '../CronofyEvent';
import Availability from '../Availability';

const TimeSlot = props => {
  const { events, availabilities, className, day, hour, minute } = props;
  const dispatch = useDispatch();
  const calendarId = useSelector(state => state.bookingCalendar.id);
  const activeSlot = useSelector(state => state.bookingCalendar.activeSlot);
  const slotInterval = useSelector(state => state.bookingCalendar.slotInterval);
  const showAllCalendars = useSelector(
    state => state.bookingCalendar.showAllCalendars
  );
  const timeZone = useSelector(state => state.bookingCalendar.meta.timeZone);

  const getAvailabilitiesForInterval = date => {
    let start = moment(date)
      .tz(timeZone)
      .subtract(1, 'week')
      .format(DATE_KEY_FORMAT);
    let end = moment(date)
      .tz(timeZone)
      .add(1, 'week')
      .add(2, 'days')
      .format(DATE_KEY_FORMAT);

    dispatch(
      listBookingAvailabilities({
        booking_availability: {
          booking_calendar_id: calendarId,
          start,
          end,
          sort: 'hour',
          all: showAllCalendars,
        },
      })
    );

    dispatch(listCalendarEvents({ start, end }));
  };

  const moveEvent = async () => {
    const dayClone = moment(day).tz(timeZone);
    const date = dayClone.date();
    const startTime = moment(activeSlot.start_time);
    const endTime = moment(activeSlot.end_time);
    const differenceInMinutes = endTime.diff(startTime, 'minutes');
    const requestParams = {
      booking_availability: {
        booking_calendar_id: calendarId,
        start_time: startTime.set({
          hour,
          minute,
          date,
        }),
        end_time: endTime.set({
          date,
          hour,
          minute: minute + differenceInMinutes,
        }),
      },
    };
    const updateRes = await dispatch(
      updateBookingAvailability(activeSlot.id, requestParams)
    );

    if (updateRes.error) {
      toast.error(updateRes.error[0], toastOptions);
    } else {
      getAvailabilitiesForInterval(activeSlot.start_time);
    }
  };
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.AVAILABILITY,
    drop: () => moveEvent(),
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  });
  const hasClassName = !!className;
  const cx = classNames.bind(styles);
  const classes = cx('TimeSlot', {
    [className]: hasClassName,
  });

  const createNewSlot = async () => {
    const start = moment(day).tz(timeZone).set({
      hour,
      minute,
    });

    const getEndTime = () => {
      let endTime = moment(day)
        .tz(timeZone)
        .set({
          hour: Number(hour),
          minute: minute + +slotInterval,
        });

      if (endTime.hour() === 0 && endTime.minute() === 0) {
        endTime = endTime.subtract(1, 'minute');
      }

      return endTime;
    };

    const requestParams = {
      booking_availability: {
        booking_calendar_id: calendarId,
        start_time: start,
        end_time: getEndTime(),
      },
    };

    const createRes = await dispatch(createBookingAvailability(requestParams));

    if (createRes.errors) {
      toast.error(createRes.errors);
    } else {
      getAvailabilitiesForInterval(start);
      dispatch(updateActiveSlot(createRes));
    }
  };

  return (
    <div className={styles['TimeSlot-container']}>
      <div
        className={classes}
        onDoubleClick={createNewSlot}
        ref={drop}
        style={
          isOver
            ? {
                backgroundColor: '#d9fdf6',
              }
            : {}
        }
      >
        {events.map((event, index) => {
          let customStyles = {
            position: 'absolute',
          };

          // Leaving this in for future reference of formula used

          // const width = `${100 / events.length}%`;
          // const left = index === 0 ? '0%' : `${(index * 100) / events.length}%`;
          // customStyles.width = width;
          // customStyles.left = left;

          customStyles.width = '100%';

          if (event.event_uid) {
            const key = uniqueId(`event${event.event_uid}_`);

            return (
              <CronofyEvent
                event={event}
                key={key}
                customStyles={customStyles}
              />
            );
          }

          return (
            <CalendarEvent
              event={event}
              key={uniqueId(`event${event.id}_`)}
              customStyles={customStyles}
            />
          );
        })}
        {availabilities.map((availability, index) => {
          let customStyles = {
            position: 'absolute',
          };

          // Leaving this in for future reference of formula used

          // const width = `${100 / availabilities.length}%`;
          // const left =
          //   index == 0 ? '0%' : `${(index * 100) / availabilities.length}%`;
          // customStyles.width = width;
          // customStyles.left = left;

          customStyles.width = '100%';

          return (
            <Availability
              availability={availability}
              key={uniqueId(`av${availability.id}index${index}_`)}
              customStyles={customStyles}
            />
          );
        })}
      </div>
    </div>
  );
};

TimeSlot.defaultProps = {
  events: [],
  availabilities: [],
};

export default TimeSlot;
