import React from 'react';
import PropTypes from 'prop-types';
import SquarePaymentMethods from './SquarePaymentMethods';

const SquareButton = props => {
  const {
    settings,
    loadingCallback,
    className,
    tipAmount,
    isBooking,
    achEnabled,
    giftCardEnabled,
    afterpayEnabled,
    cashAppEnabled,
    applePayEnabled,
    googlePayEnabled,
    hasAfterpayAttributes,
    hasGoogleAndApplePayAttributes,
    studio,
    user,
    availabilityId,
    bookingCalendarId,
  } = props;

  const {
    currency,
    amountDue,
    client,
    squareApplicationId,
    squareRedirectUrl,
    squareLocationId,
    invoiceUuid,
  } = settings;

  const payments = Square.payments(squareApplicationId, squareLocationId);

  return (
    <div className={className}>
      <SquarePaymentMethods
        {...{
          squareApplicationId,
          squareRedirectUrl,
          squareLocationId,
          tipAmount,
          loadingCallback,
          isBooking,
          amountDue,
          invoiceUuid,
          client,
          currency,
          payments,
          achEnabled,
          giftCardEnabled,
          afterpayEnabled,
          cashAppEnabled,
          applePayEnabled,
          googlePayEnabled,
          hasAfterpayAttributes,
          hasGoogleAndApplePayAttributes,
          studio,
          user,
          availabilityId,
          bookingCalendarId,
        }}
      />
    </div>
  );
};

SquareButton.propTypes = {
  settings: PropTypes.object.isRequired,
  loadingCallback: PropTypes.func.isRequired,
  className: PropTypes.string,
  isBooking: PropTypes.bool,
};

SquareButton.defaultProps = {
  className: null,
  isBooking: false,
};

export default SquareButton;
