import React from 'react';
import PT from 'prop-types';
import axios from 'axios';
import { toast } from 'react-toastify';
import EmailModalContent from './email_modal_content';
import modes from '../email_modal_modes';

class EmailModalContentContainer extends React.PureComponent {
  static propTypes = {
    footerComponent: PT.element,
    client_id: PT.number,
    closeModal: PT.func,
    reloadPage: PT.bool,
    preSendHook: PT.func,
    generateParams: PT.object,
    showSessionChooser: PT.bool,
    bookingUrls: PT.array,
    locations: PT.array,
  };

  state = {
    email_templates: [],
    selectedTemplate: '',
    selectedSession: '',
    isFetchingTemplate: false,
    currentEmailTemplate: { email_attachments: [] },
    currentEmailSending: {},
    mode: modes.INIT,
  };

  componentDidMount() {
    this.fetchEmailTemplates();
  }

  componentDidUpdate() {
    const { mode } = this.state;
    if (Object.values(modes).indexOf(mode) === -1) {
      console.warn(
        `EmailModalContent.state.mode is set to an invalid value: ${mode}`
      );
    }
  }

  getEmptyTemplate = () => {
    return {
      email_attachments: [],
    };
  };

  fetchEmailTemplates = async () => {
    const url = `/api/legacy_email_templates`;
    try {
      const { data } = await axios.get(url);
      this.setState({ email_templates: data });
    } catch (e) {
      console.error(e);
    }
  };

  cloneSelected = () => {
    const templateId = this.state.selectedTemplate;
    if (!templateId || templateId === 'new') {
      this.setState({ currentEmailTemplate: this.getEmptyTemplate() });
      return;
    }
    const template =
      this.state.email_templates.find(t => t.id === parseInt(templateId, 10)) ||
      this.getEmptyTemplate();

    this.setState({ currentEmailTemplate: template });
  };

  handleTemplateChoice = e => {
    const { mode } = this.state;
    const choice = e.target.value;
    let nextMode;
    if (choice === '') {
      nextMode = modes.INIT; // selected none
    } else if (choice === 'new') {
      nextMode = modes.TEMPLATE; // selected new
    } else if (mode === modes.INIT) {
      nextMode = modes.SENDING; // selected one
    } else {
      nextMode = mode; // switched from one item to another
    }
    this.setState({ mode: nextMode, selectedTemplate: choice }, async () => {
      this.cloneSelected();
      if (!(choice === 'new' || choice === '')) {
        this.generateEmail();
      }
    });
  };

  handleSessionChoice = e => {
    const choice = e.target.value;
    this.setState({ selectedSession: choice }, () => {
      this.generateEmail();
    });
  };

  replaceEmailTemplate = template => {
    const { email_templates } = this.state;
    const existing = email_templates.find(
      t => t.id === parseInt(template.id, 10)
    );
    const index = email_templates.indexOf(existing);
    const newList = [...email_templates];
    const newTemplate = { ...template, _fetched: true };
    newList.splice(index, 1, newTemplate);
    this.setState({
      email_templates: newList,
      currentEmailTemplate: newTemplate,
      selectedTemplate: template.id,
    });
  };

  setIsFetchingTemplate = bool => {
    this.setState({ isFetchingTemplate: bool });
  };

  updateEmailTemplate = data => {
    this.setState({
      currentEmailTemplate: {
        ...this.state.currentEmailTemplate,
        ...data,
      },
    });
  };

  updateEmailSending = data => {
    this.setState({
      currentEmailSending: {
        ...this.state.currentEmailSending,
        ...data,
      },
    });
  };

  beginEdit = () => {
    this.setState({ mode: modes.TEMPLATE }, () => {
      this.cloneSelected();
    });
  };

  cancelEdit = () => {
    const { selectedTemplate } = this.state;
    if (!selectedTemplate || selectedTemplate === 'new') {
      this.setState({ mode: modes.INIT, selectedTemplate: '' });
    } else {
      this.setState({ mode: modes.SENDING });
    }
  };

  onTemplateSave = template => {
    const { email_templates } = this.state;

    let newList;
    const existing = email_templates.find(t => t.id === template.id);
    if (existing) {
      const index = email_templates.indexOf(existing);
      newList = [...email_templates];
      newList.splice(index, 1, template);
    } else {
      newList = [...email_templates, template];
    }

    this.setState(
      {
        selectedTemplate: template.id.toString(),
        email_templates: newList,
        mode: modes.SENDING,
        currentEmailSending: template,
      },
      () => {
        this.cloneSelected();
        this.generateEmail();
      }
    );
  };

  generateEmail = async () => {
    const { generateParams } = this.props;
    const { selectedTemplate, selectedSession } = this.state;

    const params = { ...generateParams };
    if (selectedSession) {
      params.calendar_event_id = selectedSession;
    }

    const url = `/api/legacy_email_templates/${selectedTemplate}/generate`;
    const body = {
      params,
    };

    try {
      const { data } = await axios.get(url, body);
      this.updateEmailSending({ email_body: data });
    } catch (e) {
      console.error(e);
    }
  };

  sendEmail = async () => {
    const {
      selectedTemplate,
      currentEmailSending,
      selectedSession,
    } = this.state;
    const {
      client_id,
      closeModal,
      reloadPage,
      preSendHook,
      checkSendLimit,
      activeTrial,
      trialAccountVerified,
    } = this.props;

    if (activeTrial && !trialAccountVerified) {
      const res = await checkSendLimit();
      if (res && closeModal) {
        closeModal();
        return;
      }
    }

    let email_body = currentEmailSending.email_body;

    if (preSendHook) {
      const result = preSendHook(currentEmailSending.email_body);
      if (!result) {
        return;
      }
      email_body = result;
    }

    const url = `/api/clients/send_email`;
    const body = {
      client: {
        client_id,
        email_body,
        email_template_id: selectedTemplate,
        calendar_event_id: selectedSession,
      },
    };

    try {
      await axios.post(url, body);
      toast.success('Email sent');
      if (reloadPage) {
        window.location.reload();
        return;
      }
      if (closeModal) {
        closeModal();
      }
    } catch (e) {
      if (e.response.data.error) {
        toast.error(e.response.data.error);
      } else {
        toast.error(`Error sending email`);
      }
    }
  };

  render() {
    const templateProps = {
      currentEmailTemplate: this.state.currentEmailTemplate,
      isFetchingTemplate: this.state.isFetchingTemplate,
      updateEmailTemplate: this.updateEmailTemplate,
      setIsFetchingTemplate: this.setIsFetchingTemplate,
      replaceEmailTemplate: this.replaceEmailTemplate,
      cancelEdit: this.cancelEdit,
      onTemplateSave: this.onTemplateSave,
    };

    const sendingProps = {
      updateEmailSending: this.updateEmailSending,
      currentEmailSending: this.state.currentEmailSending,
      beginEdit: this.beginEdit,
      sendEmail: this.sendEmail,
      showSessionChooser: this.props.showSessionChooser,
      handleSessionChoice: this.handleSessionChoice,
      selectedSession: this.state.selectedSession,
      generateParams: this.props.generateParams,
      client_id: this.props.client_id,
      generateEmail: this.generateEmail,
    };

    return (
      <EmailModalContent
        {...this.state}
        templateProps={templateProps}
        sendingProps={sendingProps}
        handleTemplateChoice={this.handleTemplateChoice}
        footerComponent={this.props.footerComponent}
        bookingUrls={this.props.bookingUrls}
        locations={this.props.locations}
      />
    );
  }
}

export default EmailModalContentContainer;
