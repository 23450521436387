import React, { useState } from 'react';
import {
  Modal,
  Button,
  TextInput,
  Dropdown,
  DateTimePicker,
  Checkbox,
} from '~ui';
import styles from './CreateCouponModal.module.scss';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { createCoupon, getCouponCodeList } from '~actions/couponCodes';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { routes } from '~constants/routes';

const CreateCouponModal = props => {
  const {
    handleHide,
    studio_id,
    couponCodesFeature,
    setCouponCodesFeature,
  } = props;

  const [createCouponParams, setCreateCouponParams] = useState({
    studio_id: studio_id,
    code: '',
    discount_type: '',
    discount_amount: '',
    first_installment: false,
    expiration_date: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const couponCodes = useSelector(state => state?.couponCodes.couponCodeList);

  let disabled =
    createCouponParams.code == '' ||
    createCouponParams.discount_type == '' ||
    createCouponParams.discount_amount == '';

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    setIsLoading(true);

    if (validateDate()) {
      toast.error(
        `Please ensure the expiration date is in the future.`,
        toastOptions
      );
      setIsLoading(false);
      return;
    }

    if (validateUniqueCode()) {
      toast.error(`Please ensure the code is unique!`, toastOptions);
      setIsLoading(false);
      return;
    }

    if (!couponCodesFeature) {
      const res = await couponCodesHandleChange();
      if (!res) {
        toast.error(`Oops! Something went wrong.`, toastOptions);
        setIsLoading(false);
        return;
      }
    }

    const createRes = await dispatch(
      createCoupon({
        coupon_code: createCouponParams,
      })
    );

    if (createRes.errors) {
      toast.error(
        `Error creating coupon. Please reach out to support.`,
        toastOptions
      );
      setIsLoading(false);
    } else {
      dispatch(getCouponCodeList());
      toast.success(`Coupon was created!`, toastOptions);
      handleHide();
    }
  };

  const validateDate = () => {
    if (createCouponParams.expiration_date != '') {
      const newDate = moment(createCouponParams.expiration_date);
      const isDateInThePast = newDate.isBefore(moment(), 'day');

      if (isDateInThePast) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const validateUniqueCode = () => {
    return couponCodes.some(
      coupon =>
        coupon.code.trim().toUpperCase() ===
        createCouponParams.code.trim().toUpperCase()
    );
  };

  const couponCodesHandleChange = async () => {
    const params = {
      feature_flag: {
        studio_id: studio_id,
        feature: 'show_coupon_codes',
      },
    };
    try {
      await axios.post(routes.FEATURE_FLAGS.POST, params);
      setCouponCodesFeature(true);
      return true;
    } catch (error) {
      return false;
    }
  };

  const timeZoneGuess = moment.tz.guess();

  return (
    <Modal title={`New Coupon Code`} handleHide={handleHide} maxWidth={500}>
      <div className={styles['CreateCouponModal-row']}>
        <div>
          <TextInput
            name="discountCode"
            labelText={'Discount Code'}
            onChangeCallback={e => {
              setCreateCouponParams({ ...createCouponParams, code: e });
            }}
            initialValue={''}
            required
          />
          <p className={styles['CreateCouponModal-note']}>
            Customers will enter this discount code at checkout.
          </p>
        </div>
      </div>
      <div className={styles['CreateCouponModal-row']}>
        <div>
          <Dropdown
            labelText={'Discount Type'}
            options={[
              { label: 'Choose discount type', value: '' },
              { label: 'Percentage', value: 'percentage' },
              { label: 'Dollar amount', value: 'money_amount' },
            ]}
            name="discountType"
            onChangeCallback={e => {
              setCreateCouponParams({
                ...createCouponParams,
                discount_type: e,
              });
            }}
            initialValue={''}
            required
          />
        </div>
        <div>
          <TextInput
            name="discountValue"
            labelText={'Discount Value'}
            onChangeCallback={e => {
              setCreateCouponParams({
                ...createCouponParams,
                discount_amount: e,
              });
            }}
            initialValue={''}
            required
          />
        </div>
      </div>

      <div className={styles['CreateCouponModal-row']}>
        <div>
          <DateTimePicker
            name="expirationDate"
            labelText="Expiration Date"
            disableTime={true}
            onChangeCallback={date => {
              const convertedDate = moment(date).tz(timeZoneGuess).format('YYYY-MM-DD');
              setCreateCouponParams({
                ...createCouponParams,
                expiration_date: convertedDate,
              });
            }}
            initialDateTime={null}
            onBlurInput={date => {
              const convertedDate = moment(date).tz(timeZoneGuess).format('YYYY-MM-DD');
              setCreateCouponParams({
                ...createCouponParams,
                expiration_date: convertedDate,
              });
            }}
            makeMaxWidth
            icon={'fa fa-calendar-alt'}
          />
        </div>
        <div>
          <TextInput
            name="maximumRedemptions"
            labelText={'Maximum Redemptions'}
            onChangeCallback={e => {
              setCreateCouponParams({
                ...createCouponParams,
                redemptions: e,
              });
            }}
            initialValue={''}
          />
        </div>
      </div>
      <div className={styles['CreateCouponModal-row']}>
        <div>
          <Checkbox
            name="preventOnFirstInstallment"
            labelText={'Prevent on first installment'}
            checked={createCouponParams.first_installment}
            onChangeCallback={e => {
              setCreateCouponParams({
                ...createCouponParams,
                first_installment: e,
              });
            }}
          />
          <p className={styles['CreateCouponModal-note']}>
            By checking this box, your coupon code will not be applied to the
            first invoice installment. However, it will be applied to all
            subsequent installments.
          </p>
        </div>
      </div>
      <hr></hr>

      <div className={styles['CreateCouponModal-buttons']}>
        <Button
          text="Cancel"
          onClick={() => {
            handleHide();
          }}
          danger
        />
        <Button
          onClick={handleSubmit}
          text="Save"
          disabled={disabled}
          loading={isLoading}
        />
      </div>
    </Modal>
  );
};

export default CreateCouponModal;
