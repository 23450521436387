import React, { useState, useEffect } from 'react';
import styles from './SessionTypes.module.scss';
import { Button, DataTable, DeleteConfirmation } from '~ui';
import { routes } from '~constants/routes';
import axios from 'axios';
import ToggleContent from '~components/common/ToggleContent';
import SessionTypesModal from './SessionTypesModal';

const SessionTypes = ({ journeyTemplatesOptions, emailTemplatesOptions }) => {
  const [normalizedSessionTypes, setNormalizedSessionTypes] = useState([]);

  const renderIcon = iconString => {
    return <img className={styles['SessionTypes-icon']} src={iconString} />;
  };

  const renderName = (name, color, sessionType) => {
    return (
      <div className={styles['SessionTypes-name']}>
        <div style={{ backgroundColor: color }}></div>
        <ToggleContent
          toggle={handleShow => (
            <div className={'SessionTypes-name-text'} onClick={handleShow}>
              {name}
            </div>
          )}
          content={handleHide => (
            <SessionTypesModal
              {...{
                handleHide,
                sessionType,
                journeyTemplatesOptions,
                emailTemplatesOptions,
                getSessionTypes,
              }}
            />
          )}
        />
      </div>
    );
  };

  const renderButtons = sessionType => {
    return (
      <div className={styles['SessionTypes-buttons']}>
        <ToggleContent
          toggle={handleShow => (
            <i className="fa fa-pencil-alt" onClick={handleShow} />
          )}
          content={handleHide => (
            <SessionTypesModal
              {...{
                handleHide,
                sessionType,
                journeyTemplatesOptions,
                emailTemplatesOptions,
                getSessionTypes,
              }}
            />
          )}
        />
        <ToggleContent
          toggle={handleShow => (
            <i className="fa fa-trash-alt" onClick={handleShow} />
          )}
          content={handleHide => (
            <DeleteConfirmation
              handleHide={handleHide}
              handleDelete={() => deleteSessionType(sessionType.id)}
              name={sessionType.name}
              resource="Session Type"
            />
          )}
        />
      </div>
    );
  };

  const getSessionTypes = async () => {
    try {
      const res = await axios.get(routes.SESSION_TYPES.INDEX);

      const alhpabetizeSessionTypes = res.data.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      const normalized = alhpabetizeSessionTypes.map(st => ({
        icon: () => renderIcon(st.icon),
        name: () => renderName(st.name, st.color, st),
        defaultWorkflow: st.default_journey_template
          ? st.default_journey_template.name
          : 'N/A',
        leadEmail: st.lead_response_email_template
          ? st.lead_response_email_template.name
          : 'N/A',
        buttons: () => renderButtons(st),
      }));

      setNormalizedSessionTypes(normalized);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteSessionType = async sessionTypeId => {
    try {
      await axios.delete(routes.SESSION_TYPES.DELETE(sessionTypeId));
      getSessionTypes();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSessionTypes();
  }, []);

  const headers = [
    { name: 'icon', labelText: 'Icon', sortable: false },
    { name: 'name', labelText: 'Name', sortable: false },
    { name: 'defaultWorkflow', labelText: 'Default Workflow', sortable: false },
    {
      name: 'leadEmail',
      labelText: 'Lead Auto-Response Email',
      sortable: false,
    },
    { name: 'buttons', labelText: '', sortable: false },
  ];

  const columnTypes = ['function', 'function', 'string', 'string', 'function'];
  return (
    <div className={styles.SessionTypes}>
      <div className={styles['SessionTypes-header']}>
        <h1>Session Types</h1>

        <ToggleContent
          toggle={handleShow => (
            <Button text="Create Session Type" onClick={handleShow} />
          )}
          content={handleHide => (
            <SessionTypesModal
              isNew
              {...{
                handleHide,
                journeyTemplatesOptions,
                emailTemplatesOptions,
                getSessionTypes,
              }}
            />
          )}
        />
      </div>
      <div className={styles['SessionTypes-table']}>
        <DataTable
          rows={normalizedSessionTypes}
          headers={headers}
          columnTypes={columnTypes}
        />
      </div>
    </div>
  );
};

export default SessionTypes;
