import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { TIME_SLOT_HEIGHT } from '~constants/calendar';
import styles from '../CalendarEvent/CalendarEvent.module.scss';

const CronofyEvent = props => {
  const { event, customStyles } = props;
  const { summary, start, end, all_day } = event;
  const formattedStartTime = moment(start.time).format('h:mm a');
  const slotInterval = useSelector(state => state.bookingCalendar.slotInterval);

  const timeSlotHeightMultiplier = 60 / +slotInterval;

  const getStyles = () => {
    let newStyles = { ...customStyles, opacity: 0.6, cursor: 'not-allowed' };
    newStyles.backgroundColor = event.color;
    newStyles.color = event.textColor;

    const timeDifference =
      moment(end.time).diff(moment(start.time), 'minutes') / 60;
    const relativeDistanceFromMidnight =
      (+slotInterval +
        moment(start.time).diff(moment(start.time).startOf('day'), 'minutes')) /
      60;

    const duration = all_day ? 0.5 : timeDifference;
    const offsetStart = all_day ? 0 : relativeDistanceFromMidnight;

    newStyles.height = `${
      TIME_SLOT_HEIGHT * duration * timeSlotHeightMultiplier
    }px`;

    if (!all_day) {
      newStyles.top = `${
        offsetStart * TIME_SLOT_HEIGHT * timeSlotHeightMultiplier
      }px`;
    }

    return newStyles;
  };

  return (
    <div className={styles.CalendarEvent} style={getStyles()}>
      {!all_day && formattedStartTime} {summary}
    </div>
  );
};

CronofyEvent.propTypes = {
  event: PropTypes.object.isRequired,
};

export default CronofyEvent;
