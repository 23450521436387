import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '../../store';
import ToDos from '../ToDos';

const ToDosRoot = props => {
  const store = configureStore(props);

  return (
    <Provider store={store}>
      <ToDos />
    </Provider>
  );
};

export default ToDosRoot;
