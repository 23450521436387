import React from 'react';
import PropTypes from 'prop-types';
import { DataTable, Pill } from '~ui';

const InstallmentsTable = ({ installments }) => {
  const renderInstallmentStatus = status => {
    switch (status) {
      case 'paid':
        return <Pill text="Paid" className="va-t" color="green" small />;
      case 'late':
        return <Pill text="Late" className="va-t" color="red" small />;
      case 'partially_paid':
        return (
          <Pill text="Partially Paid" className="va-t" color="orange" small />
        );
      case 'unpaid':
        return <Pill text="Unpaid" className="va-t" color="orange" small />;
      case 'ach_pending':
        return (
          <Pill text="ACH Pending" className="va-t" color="orange" small />
        );
      default:
        return <></>;
    }
  };
  const normalizedInstallments = installments.map(installment => ({
    due_date: installment.due_date,
    amount: installment.amount,
    status: () => renderInstallmentStatus(installment.status),
  }));
  const headers = [
    { name: 'due_date', labelText: 'Due Date', sortable: false },
    { name: 'amount', labelText: 'Amount', sortable: false },
    { name: 'status', labelText: 'Status', sortable: false },
  ];
  const columnTypes = ['date', 'currency', 'function'];

  return (
    <DataTable
      title="Payments"
      rows={normalizedInstallments}
      headers={headers}
      columnTypes={columnTypes}
      rowWidth="51%"
    />
  );
};

InstallmentsTable.propTypes = {
  installments: PropTypes.array.isRequired,
};

export default InstallmentsTable;
