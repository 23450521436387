import React, { useState } from 'react';
import styles from './StudioProfile.module.scss';
import { Checkbox, Button, Accordion } from '~ui';
import ToggleContent from '~components/common/ToggleContent';
import ProfileEditModal from './ProfileEditModal';
import SettingsEditModal from './SettingsEditModal';
import ContractSignature from './ContractSignature';
import axios from 'axios';
import { routes } from '~constants/routes';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import TinyMceInput from '~components/common/TinyMceInput';

const StudioProfile = props => {
  const { studioProfile } = props;
  const countryOptions = studioProfile.countryOptions;
  const timeZoneOptions = studioProfile.timeZoneOptions;

  const [studio, setStudio] = useState(studioProfile.studio);
  const [spudUser, setSpudUser] = useState(studioProfile.spudUser);
  const [emailSignature, setEmailSignature] = useState(
    studioProfile.emailSignature || ''
  );
  const { hasWeeklyDigestEnabled } = studioProfile.studio;
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);

  const companyProfileInfo = {
    companyName: studio.name,
    website: studio.website,
    primaryContact:
      spudUser.first_name !== null && spudUser.last_name !== null
        ? `${spudUser.first_name} ${spudUser.last_name}`
        : '',
    address1: studio.address,
    address2:
      (studio.city == (null || '') && studio.state == (null || '') && studio.postal == (null || ''))
        ? ''
        : `${studio.city}, ${studio.state} ${studio.postal}`,
    phoneNumber: studio.phone,
    email: spudUser.email,
  };

  const settingsInfo = {
    login: spudUser.login,
    password: '',
    timezone: spudUser.time_zone,
    preferences: studio.email_notifications,
    logo: studio.logo_content_type,
    logoUrl: studio.logoUrl,
    banner: studio.banner_content_type,
    bannerUrl: studio.bannerUrl,
  };

  const updateStudioProps = res => {
    setStudio(res.data);
  };

  const updateSpudUserProps = res => {
    setSpudUser(res.data);
  };

  const onPreferenceCheck = () => {
    const studio = {};
    if (settingsInfo.preferences === true) {
      studio.email_notifications = false;
    } else {
      studio.email_notifications = true;
    }
    const params = {
      studio: studio,
    };
    axios.put(routes.STUDIO_PROFILE.UPDATE(studioId), params).then(res => {
      updateStudioProps(res);
    });
  };

  const updateEmailSignature = () => {
    const params = {
      studio: {
        email_signature: emailSignature,
      },
    };
    axios.put(routes.STUDIO_PROFILE.UPDATE(studioId), params).then(res => {
      toast.success('Email Signature successfully saved.', toastOptions);
    });
  };

  const studioId = studio.id;
  const spudUserId = spudUser.id;

  const getColor = percentage => {
    if (percentage <= 50) {
      return 'green';
    } else if (percentage <= 75) {
      return 'blue';
    } else if (percentage < 100) {
      return 'orange';
    } else {
      return 'red';
    }
  };

  const percentageUsed = Math.floor((emailSignature.length / 750) * 100);
  const color = getColor(percentageUsed);

  return (
    <div className={styles.StudioProfile}>
      <div className={styles['StudioProfile-profileTitle']}>
        <div>
          <h1>Studio Profile</h1>
        </div>
      </div>
      <div className={styles['StudioProfile-headerBar']}>
        <img
          className={styles['StudioProfile-headerBar-banner']}
          src={settingsInfo.bannerUrl}
        />
        <img
          className={styles['StudioProfile-headerBar-profilePic']}
          src={settingsInfo.logoUrl}
        />
        <div className={styles['StudioProfile-headerBar-studioTitle']}>
          {companyProfileInfo.companyName}
        </div>
      </div>
      <div className={styles['StudioProfile-infoContainer']}>
        <div
          className={styles['StudioProfile-infoContainer-companyProfileBox']}
        >
          <div
            className={
              styles['StudioProfile-infoContainer-companyProfileBox-titleBar']
            }
          >
            <div
              className={
                styles[
                  'StudioProfile-infoContainer-companyProfileBox-titleBar-title'
                ]
              }
            >
              My Company Profile
            </div>
            <div
              className={
                styles[
                  'StudioProfile-infoContainer-companyProfileBox-titleBar-icon'
                ]
              }
            >
              <ToggleContent
                toggle={handleShow => (
                  <i className="fas fa-pencil-alt c-dg" onClick={handleShow} />
                )}
                content={handleHide => (
                  <ProfileEditModal
                    handleHide={handleHide}
                    studioId={studioId}
                    spudUserId={spudUserId}
                    countryOptions={countryOptions}
                    updateSpudUserProps={updateSpudUserProps}
                    updateStudioProps={updateStudioProps}
                    studio={studio}
                    spudUser={spudUser}
                  />
                )}
              />
            </div>
          </div>
          <div className={styles['StudioProfile-infoContainer-infoHeader']}>
            Company Name
          </div>
          <div className={styles['StudioProfile-infoContainer-info']}>
            {companyProfileInfo.companyName}
          </div>
          <div className={styles['StudioProfile-infoContainer-infoHeader']}>
            Website
          </div>
          <div className={styles['StudioProfile-infoContainer-info']}>
            {companyProfileInfo.website}
          </div>
          <div className={styles['StudioProfile-infoContainer-infoHeader']}>
            Primary Contact
          </div>
          <div className={styles['StudioProfile-infoContainer-info']}>
            {companyProfileInfo.primaryContact}
          </div>
          <div className={styles['StudioProfile-infoContainer-infoHeader']}>
            Email
          </div>
          <div className={styles['StudioProfile-infoContainer-info']}>
            {companyProfileInfo.email}
          </div>
          <div className={styles['StudioProfile-infoContainer-infoHeader']}>
            Address
          </div>
          <div className={styles['StudioProfile-infoContainer-info']}>
            {companyProfileInfo.address1}
          </div>
          <div className={styles['StudioProfile-infoContainer-info']}>
            {companyProfileInfo.address2}
          </div>
          <div className={styles['StudioProfile-infoContainer-infoHeader']}>
            Phone Number
          </div>
          <div className={styles['StudioProfile-infoContainer-info']}>
            {companyProfileInfo.phoneNumber}
          </div>
        </div>
        <div className={styles['StudioProfile-infoContainer-settingsBox']}>
          <div
            className={
              styles['StudioProfile-infoContainer-settingsBox-titleBar']
            }
          >
            <div
              className={
                styles['StudioProfile-infoContainer-settingsBox-titleBar-title']
              }
            >
              Settings
            </div>
            <div
              className={
                styles['StudioProfile-infoContainer-settingsBox-titleBar-icon']
              }
            >
              <ToggleContent
                toggle={handleShow => (
                  <i className="fas fa-pencil-alt c-dg" onClick={handleShow} />
                )}
                content={handleHide => (
                  <SettingsEditModal
                    handleHide={handleHide}
                    studioId={studioId}
                    spudUserId={spudUserId}
                    timeZoneOptions={timeZoneOptions}
                    updateSpudUserProps={updateSpudUserProps}
                    updateStudioProps={updateStudioProps}
                    studio={studio}
                    spudUser={spudUser}
                  />
                )}
              />
            </div>
          </div>
          <div className={styles['StudioProfile-infoContainer-infoHeader']}>
            Login
          </div>
          <div className={styles['StudioProfile-infoContainer-info']}>
            {settingsInfo.login}
          </div>
          <div
            type="password"
            className={styles['StudioProfile-infoContainer-infoHeader']}
          >
            Password
          </div>
          <div className={styles['StudioProfile-infoContainer-info']}>
            &#9679; &#9679; &#9679; &#9679; &#9679; &#9679; &#9679; &#9679;
            &#9679; &#9679;
          </div>
          <div className={styles['StudioProfile-infoContainer-infoHeader']}>
            Time Zone
          </div>
          <div className={styles['StudioProfile-infoContainer-info']}>
            {settingsInfo.timezone}
          </div>
          <div
            className={styles['StudioProfile-infoContainer-infoHeader']}
            name="preferencesCheck"
          >
            Preferences
          </div>
          <div
            className={styles['StudioProfile-infoContainer-info-preferences']}
          >
            <div className="mr-8">
              Send email notifications for client activity
            </div>
            <Checkbox
              name="Preferences Check"
              checked={settingsInfo.preferences}
              onChangeCallback={onPreferenceCheck}
            />
          </div>
          <div
            className={styles['StudioProfile-infoContainer-info-preferences']}
          >
            <div className="mr-8">Send me a weekly digest email</div>
            <Checkbox
              name="WeeklyDigest"
              checked={hasWeeklyDigestEnabled}
              onChangeCallback={enabled => {
                const params = {
                  feature_flag: {
                    studio_id: studioId,
                    feature: 'receives_weekly_digest',
                  },
                };
                if (enabled) {
                  axios.post(routes.FEATURE_FLAGS.POST, params);
                } else {
                  axios.delete(routes.FEATURE_FLAGS.DELETE, { data: params });
                }
              }}
            />
          </div>
          <div className={styles['StudioProfile-infoContainer-infoHeader']}>
            Logo
          </div>
          <div className={styles['StudioProfile-infoContainer-info']}>
            {settingsInfo.logo}
          </div>
          <div className={styles['StudioProfile-infoContainer-infoHeader']}>
            Banner
          </div>
          <div className={styles['StudioProfile-infoContainer-info']}>
            {settingsInfo.banner}
          </div>
          <div
            className={
              styles['StudioProfile-infoContainer-settingsBox-logoExample']
            }
          >
            <img
              className={
                styles[
                  'StudioProfile-infoContainer-settingsBox-logoExample-logo'
                ]
              }
              src={settingsInfo.logoUrl}
            />
          </div>
          <img
            className={
              styles['StudioProfile-infoContainer-settingsBox-bannerExample']
            }
            src={settingsInfo.bannerUrl}
          />
        </div>
      </div>
      <Accordion
        title="Email and Contract Signatures"
        expanded={isAccordionExpanded}
      >
        <div className={styles['StudioProfile-signature']}>
          <h2>
            Email Signature{' '}
            <p>
              {' '}
              Max space used:
              <span style={{ color: color }}>
                {emailSignature.length <= 750
                  ? ` (${percentageUsed}%)`
                  : ' Free up space'}
              </span>
            </p>
          </h2>
          <p>
            This signature is used at the bottom of all emails to your clients.
          </p>
          <div className={styles['StudioProfile-signature-tinymce']}>
            <TinyMceInput
              value={emailSignature}
              onChangeCallback={setEmailSignature}
              disableVariables
            />
          </div>
          <div className={styles['StudioProfile-signature-buttonContainer']}>
            <Button
              className="mr-12"
              text="Save"
              onClick={updateEmailSignature}
              disabled={emailSignature.length > 750 ? true : false}
            />
          </div>
        </div>
        <ContractSignature
          initialDrawnSignature={studio.drawn_signature}
          initialSignature={studio.signature}
          studioId={studio.id}
          updateStudioProps={updateStudioProps}
        />
      </Accordion>
    </div>
  );
};

export default StudioProfile;
