import React from 'react';
import styles from './SubscribedCard.module.scss';
import { Icon } from '~ui';

const SubscribedCard = props => {
  const { title, children, editOnClick } = props;

  return (
    <div className={styles['SubscribedCard']}>
      <div className={styles['SubscribedCard--header']}>
        <h3>{title}</h3>
        <Icon name="pencil-alt" onClickCallback={editOnClick} />
      </div>
      <div className={styles['SubscribedCard--content']}>{children}</div>
    </div>
  );
};

export default SubscribedCard;
