import { CHARGES } from '~constants/actionTypes';
import axios from 'axios';
import { routes } from '~constants/routes';

export const getCharges = (page = 1) => async (dispatch, getState) => {
  try {
    dispatch({ type: CHARGES.GET.REQUEST });
    const res = await axios.get(routes.CHARGES.GET, {
      params: {
        page: page,
      },
    });
    dispatch({
      type: CHARGES.GET.SUCCESS,
      payload: {
        charges: res.data.charges,
        page: page,
        pageCount: res.data.page_count,
        totalRecords: res.data.total_records,
      },
    });
  } catch (error) {
    dispatch({
      type: CHARGES.GET.FAILURE,
      payload: error,
      error: true,
    });
  }
};
