import {
  BOOKING_CALENDAR,
  BOOKING_CALENDAR_REQUESTS,
} from '~constants/actionTypes';
import { routes } from '~constants/routes';
import axios from 'axios';

export const updateSelectedDay = newDay => ({
  type: BOOKING_CALENDAR.UPDATE_SELECTED_DAY,
  payload: newDay,
});

export const updateInterval = newInterval => ({
  type: BOOKING_CALENDAR.UPDATE_INTERVAL,
  payload: newInterval,
});

export const updateActiveSlot = newSlot => ({
  type: BOOKING_CALENDAR.UPDATE_ACTIVE_SLOT,
  payload: newSlot,
});

export const updateSlotInterval = newInterval => ({
  type: BOOKING_CALENDAR.UPDATE_SLOT_INTERVAL,
  payload: newInterval,
});

export const updateShowAllCalendars = newValue => ({
  type: BOOKING_CALENDAR.UPDATE_SHOW_ALL_CALENDARS,
  payload: newValue,
});

export const handlePublish = cb => async (dispatch, getState) => {
  const { id } = getState().bookingCalendar;
  try {
    dispatch({ type: BOOKING_CALENDAR_REQUESTS.PUBLISH.REQUEST });

    const res = await axios.put(routes.BOOKING_CALENDARS.PUBLISH(id));

    dispatch({
      type: BOOKING_CALENDAR_REQUESTS.PUBLISH.SUCCESS,
      payload: res.data.last_published,
    });

    cb();
  } catch (error) {
    dispatch({
      type: BOOKING_CALENDAR_REQUESTS.PUBLISH.FAILURE,
      payload: error,
      error: true,
    });
  }
};
