import React, { useState } from 'react';
import axios from 'axios';
import { Modal, TextInput, Button, DeleteConfirmation } from '~ui';
import { toast } from 'react-toastify';

const FamilyMemberModal = props => {
  const {
    handleHide,
    partnerOrChild,
    title,
    kids,
    setPartner,
    setKids,
    partnerToUpdate,
    childToUpdate,
    setPartnerToUpdate,
    setChildToUpdate,
    clientId,
  } = props;

  const [date, setDate] = useState(
    partnerToUpdate
      ? partnerToUpdate.birthday
      : childToUpdate
      ? childToUpdate.birthday
      : ''
  );
  const [name, setName] = useState(
    partnerToUpdate
      ? partnerToUpdate.name
      : childToUpdate
      ? childToUpdate.name
      : ''
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  let newKidsArray = Array.from(kids);

  const params = {
    family_member: {
      name: name,
      birthday: date,
      relation: partnerOrChild,
    },
    client_id: clientId,
  };

  const closeModal = () => {
    setChildToUpdate(false);
    setPartnerToUpdate(false);
    handleHide(true);
  };

  const createFamilyMembers = async () => {
    if (!name) {
      toast.error('Please add a name!');
      return;
    }
    try {
      const res = await axios.post('/api/family_members', params);

      if (partnerOrChild == 'spouse') {
        setPartner(res.data);
      } else {
        newKidsArray.push(res.data);
        setKids(newKidsArray);
      }
      toast.success('Family member created!');
      closeModal();
    } catch (error) {
      toast.error('Error creating family member!');
      console.error(error);
    }
  };

  const updateFamilyMembers = async () => {
    if (!name) {
      toast.error('Please add a name!');
      return;
    }
    try {
      await axios.put(
        `/api/family_members/${
          partnerToUpdate ? partnerToUpdate.id : childToUpdate.id
        }`,
        params
      );

      if (partnerOrChild == 'spouse') {
        setPartner({
          ...partnerToUpdate,
          name: name,
          birthday: date,
        });
      } else {
        const indexOfObject = newKidsArray.findIndex(kid => {
          return kid.id === childToUpdate.id;
        });
        newKidsArray.splice(indexOfObject, 1, {
          ...childToUpdate,
          name: name,
          birthday: date,
        });
        setKids(newKidsArray);
      }
      toast.success('Family member updated!');
      closeModal();
    } catch (error) {
      toast.error('Error updating family member!');
      console.error(error);
    }
  };

  const deleteFamilyMembers = async () => {
    try {
      await axios.delete(
        `/api/family_members/${
          partnerToUpdate ? partnerToUpdate.id : childToUpdate.id
        }`,
        { data: params }
      );

      if (partnerToUpdate) {
        setPartner('');
      } else {
        const indexOfObject = newKidsArray.findIndex(kid => {
          return kid.id === childToUpdate.id;
        });
        newKidsArray.splice(indexOfObject, 1);
        setKids(newKidsArray);
      }
      toast.success('Family member deleted!');
      closeModal();
    } catch (error) {
      toast.error('Error deleting family member!');
      console.error(error);
    }
  };

  return (
    <>
      <Modal title={title} handleHide={closeModal}>
        <TextInput
          labelText="Name"
          name="name"
          initialValue={name}
          onChangeCallback={name => setName(name)}
          required
        />
        <br />
        <TextInput
          labelText="Birthday"
          name="Birthday"
          initialValue={date || ''}
          onChangeCallback={date => setDate(date)}
          type="date"
        />
        <br />
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button
            style={{ marginLeft: '10px' }}
            text={partnerToUpdate || childToUpdate ? 'Update' : '+ Add'}
            onClick={
              partnerToUpdate || childToUpdate
                ? () => updateFamilyMembers()
                : () => createFamilyMembers()
            }
          />
          <Button
            danger
            text={partnerToUpdate || childToUpdate ? 'Delete' : 'Cancel'}
            onClick={
              partnerToUpdate || childToUpdate
                ? () => setShowDeleteModal(true)
                : () => closeModal()
            }
          />
        </div>
        {showDeleteModal && (
          <DeleteConfirmation
            handleHide={() => setShowDeleteModal(false)}
            handleDelete={deleteFamilyMembers}
            resource="family member"
          />
        )}
      </Modal>
    </>
  );
};

export default FamilyMemberModal;
