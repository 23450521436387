import axios from 'axios';
import { routes } from '~constants/routes';
import { INVOICE_LINE_ITEMS } from '~constants/actionTypes';

export const createLineItem = params => async dispatch => {
  try {
    dispatch({ type: INVOICE_LINE_ITEMS.CREATE.REQUEST });

    const lineItem = await axios
      .post(routes.INVOICE_LINE_ITEMS.CREATE, params)
      .then(res => res.data);

    dispatch({
      type: INVOICE_LINE_ITEMS.CREATE.SUCCESS,
      payload: lineItem,
    });
  } catch (error) {
    dispatch({
      type: INVOICE_LINE_ITEMS.CREATE.FAILURE,
      payload: error,
      error: true,
    });
  }
};

export const updateLineItem = (lineItemId, params) => async dispatch => {
  try {
    dispatch({ type: INVOICE_LINE_ITEMS.UPDATE.REQUEST });

    const lineItem = await axios
      .put(routes.INVOICE_LINE_ITEMS.UPDATE(lineItemId), params)
      .then(res => res.data.line_item);

    dispatch({
      type: INVOICE_LINE_ITEMS.UPDATE.SUCCESS,
      payload: lineItem,
    });
  } catch (error) {
    dispatch({
      type: INVOICE_LINE_ITEMS.UPDATE.FAILURE,
      payload: error,
      error: true,
    });
  }
};

export const deleteLineItem = (lineItemId, params) => async dispatch => {
  try {
    dispatch({ type: INVOICE_LINE_ITEMS.DELETE.REQUEST });

    const res = await axios.delete(
      routes.INVOICE_LINE_ITEMS.DELETE(lineItemId),
      { data: params }
    );

    dispatch({
      type: INVOICE_LINE_ITEMS.DELETE.SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: INVOICE_LINE_ITEMS.DELETE.FAILURE,
      payload: error,
      error: true,
    });
  }
};
