import React, { useState } from 'react';
import { Modal, Button, Icon } from '~ui';
import { useSelector, useDispatch } from 'react-redux';
import { routes } from '~constants/routes';
import axios from 'axios';
import styles from '../DocumentsContent/DocumentsContent.module.scss';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { getContractTemplates } from '~actions/contractTemplates';
import SendContractModal from '../SendContractModal';
import SendLimitReached from '../../common/SendLimitReached';

const ContractsButtons = ({ contractTemplate }) => {
  const dispatch = useDispatch();

  const { activeTrial, studioId, trialVerified } = useSelector(
    state => state.documents
  );

  const [showSendModal, setShowSendModal] = useState(false);

  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showBeforeSend, setShowBeforeSend] = useState(false);
  const [miniLoading, setMiniLoading] = useState(false);

  const checkValidation = async () => {
    if (activeTrial && !trialVerified) {
      setMiniLoading(true);

      const res = await axios.get(
        routes.STUDIO_PROFILE.CHECK_SEND_LIMIT(studioId)
      );
      if (res.data.reached_send_limit) {
        setMiniLoading(false);
        setShowBeforeSend(true);
      } else {
        setMiniLoading(false);
        setShowSendModal(true);
      }
    } else {
      setShowSendModal(true);
    }
  };

  const handleDuplicate = async () => {
    try {
      await axios.put(routes.CONTRACT_TEMPLATES.DUPLICATE(contractTemplate.id));
      dispatch(getContractTemplates());
    } catch (error) {
      console.error(error);
      Rollbar.error('Error duplicating contract template');
      toast.error('Error duplicating contract template', toastOptions);
    }
    setShowDuplicateModal(false);
  };

  return (
    <>
      <a
        onClick={e => {
          e.stopPropagation();
          setShowDuplicateModal(true);
        }}
      >
        Duplicate
      </a>
      <a
        onClick={e => {
          e.stopPropagation();
          checkValidation();
        }}
      >
        Send
      </a>
      {showSendModal && (
        <SendContractModal
          handleHide={() => setShowSendModal(false)}
          initialContractTemplateId={contractTemplate.id}
        />
      )}
      {showDuplicateModal && (
        <Modal
          title={`Duplicate ${contractTemplate.attributes.name}`}
          handleHide={() => setShowDuplicateModal(false)}
        >
          <p>Are you sure you'd like to duplicate this Contract Template?</p>
          <div className={styles.DocumentModalButtonContainer}>
            <Button
              text="Cancel"
              danger
              onClick={() => setShowDuplicateModal(false)}
            />
            <Button text="Duplicate" onClick={handleDuplicate} />
          </div>
        </Modal>
      )}
      {miniLoading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingText}>
            <p>Loading...</p>
            <Icon name="spinner" className="fa-pulse" large />
          </div>
        </div>
      )}
      {showBeforeSend && (
        <SendLimitReached handleHide={() => setShowBeforeSend(false)} />
      )}
    </>
  );
};

export default ContractsButtons;
