import ReminderTemplateForm from './ReminderTemplateForm';

/**
 * Dynamically load the correct form based on the resource name.
 * @param {String} resource - The resource name.
 * @return {Object} - The form to load.
 */
export const getForm = resource => {
  switch (resource) {
    case 'reminder_template':
      return ReminderTemplateForm;
    default:
      return null;
  }
};
