import React, { useState } from 'react';
import { Button, TextInput, Modal, Checkbox } from '~ui';
import styles from './ContractTemplateModal.module.scss';
import TinyMceInput from '~components/common/TinyMceInput';
import axios from 'axios';
import { routes } from '~constants/routes';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { useSelector, useDispatch } from 'react-redux';
import { getContractTemplates } from '~actions/contractTemplates';

const ContractTemplateModal = ({ editing, handleHide, initialData }) => {
  const dispatch = useDispatch();

  const { bookingUrls, locations } = useSelector(state => state.documents);

  const [contractTemplateName, setContractTemplateName] = useState(
    editing ? initialData.name : ''
  );
  const [requiresTwoSignatures, setRequiresTwoSignatures] = useState(
    editing ? initialData.requiresTwoSignatures : false
  );
  const [contractBody, setContractBody] = useState(
    editing ? initialData.contractBody : ''
  );
  const [editId, setEditId] = useState(editing ? initialData.id : null);

  const handleSave = () => {
    const params = {
      contract_template: {
        name: contractTemplateName,
        contract_body: contractBody,
        requires_secondary_signature: requiresTwoSignatures,
      },
    };

    editing
      ? updateContractTemplate(params)
      : createNewContractTemplate(params);
  };

  const createNewContractTemplate = async params => {
    try {
      await axios.post(routes.CONTRACT_TEMPLATES.CREATE, params);

      handleHide();
      dispatch(getContractTemplates());
    } catch (error) {
      console.error(error);
      Rollbar.error('Error creating contract template');
      handleHide();
      toast.error('Error creating Contract Template', toastOptions);
    }
  };

  const updateContractTemplate = async params => {
    try {
      await axios.put(routes.CONTRACT_TEMPLATES.UPDATE(editId), params);

      handleHide();
      dispatch(getContractTemplates());
    } catch (error) {
      console.error(error);
      Rollbar.error('Error creating contract template');
      handleHide();
      toast.error('Error creating Contract Template', toastOptions);
    }
  };

  return (
    <Modal
      title={editing ? 'Edit Contract' : 'Create Contract'}
      handleHide={handleHide}
    >
      <div className={styles.ContractTemplateModal}>
        <div className={styles['ContractTemplateModal-input']}>
          <TextInput
            name="contract_template_name"
            initialValue={contractTemplateName}
            onChangeCallback={setContractTemplateName}
            labelText="Name"
          />
        </div>
        <div className={styles['ContractTemplateModal-input']}>
          <Checkbox
            labelText="Requires two signatures"
            name="requires_two_signatures"
            initialValue={requiresTwoSignatures}
            checked={requiresTwoSignatures}
            onChangeCallback={setRequiresTwoSignatures}
          />
        </div>
        <div className={styles['ContractTemplateModal-input']}>
          <TinyMceInput
            value={contractBody}
            onChangeCallback={setContractBody}
            locations={locations}
            bookingUrls={bookingUrls}
          />
        </div>

        <div className={styles['ContractTemplateModal-buttonGroup']}>
          <Button text="Cancel" danger onClick={handleHide} />
          <Button
            text="Save"
            onClick={handleSave}
            disabled={!contractTemplateName || !contractBody}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ContractTemplateModal;
