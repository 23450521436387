import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from '~utils';
import { Button, DropdownButton, SearchInput, TablePagination } from '~ui';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import TempTableRow from './TempTableRow';
import styles from './Table.module.scss';
import EmptyBody from './EmptyBody';

const PAGE_SIZE = 10;

const Table = ({
  oneAddMax,
  headers,
  rows,
  handleCreate,
  handleUpdate,
  handleDelete,
  schema,
  title,
  subtitle,
  readOnly,
  page,
  pageCount,
  totalRecords,
  pageChangeCallback,
  searchInputProps,
  dropdownButtonProps,
  floatNewRowToTop,
  invoiceTemplateScheduleTrigger,
}) => {
  const [tempRows, setTempRows] = useState([]);
  const [permRows, setPermRows] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [allRowsLength, setAllRowsLength] = useState(
    tempRows.length + permRows.length
  );

  const showPagination =
    page !== undefined &&
    pageCount !== undefined &&
    totalRecords !== undefined &&
    pageCount > 1;

  useEffect(() => {
    const rows = generateRows();
    setPermRows(rows);
  }, [rows]);

  useEffect(() => {
    setAllRowsLength(tempRows.length + permRows.length);
  }, [tempRows, permRows]);

  const generateTableRowId = index => {
    return `${title.toLowerCase().split(' ').join('-')}_row_${index}`;
  };

  const generateRows = () =>
    rows.map((row, index) => {
      return {
        index,
        rowId: generateTableRowId(index),
        key: uniqueId('row_'),
        row,
        handleUpdate,
        handleDelete,
        readOnly,
      };
    });

  const generateTempRowElements = updatedTempRows => {
    return updatedTempRows.map((_, index) => (
      <TempTableRow
        index={allRowsLength - 1}
        rowId={generateTableRowId(allRowsLength - 1)}
        key={index}
        row={schema}
        handleCreate={handleCreate}
        handleRemove={() => {
          const currTempRows = [...tempRows];
          currTempRows.splice(index, 1);
          setTempRows(currTempRows);
        }}
        invoiceTemplateScheduleTrigger={invoiceTemplateScheduleTrigger}
      />
    ));
  };

  const getPaginationLabel = () => {
    const startNumber = page * PAGE_SIZE - (PAGE_SIZE - 1);
    const endNumber = startNumber + allRowsLength - 1;
    return `${startNumber}-${endNumber} of ${totalRecords}`;
  };

  return (
    <>
      <div className={styles.TableHeader}>
        <div>
          {title && <h2>{title}</h2>}
          {subtitle && <p>{subtitle}</p>}
        </div>
        <div className={styles.TableHeaderButtons}>
          {searchInputProps && (
            <SearchInput
              name={searchInputProps.name}
              onChangeCallback={searchInputProps.onChangeCallback}
            />
          )}
          {dropdownButtonProps && (
            <DropdownButton
              options={dropdownButtonProps.options}
              label={dropdownButtonProps.label}
            />
          )}
          {!readOnly && handleCreate && (
            <div className={styles['Table-addButton']}>
              <Button
                disabled={oneAddMax && tempRows.length === 1}
                text="Add"
                icon="plus"
                onClick={() => {
                  if (oneAddMax && tempRows.length === 1) {
                    return;
                  }

                  setTempRows([...tempRows, {}]);
                }}
              />
            </div>
          )}
        </div>
      </div>
      <table className={styles.Table}>
        <TableHeader headers={headers} />
        {allRowsLength === 0 ? (
          <EmptyBody columnCount={headers.length} />
        ) : (
          <tbody>
            {floatNewRowToTop && generateTempRowElements(tempRows)}
            {permRows.map((permRow, index) => {
              const {
                rowId,
                key,
                row,
                handleUpdate,
                handleDelete,
                readOnly,
              } = permRow;
              return (
                <TableRow
                  rowId={rowId}
                  key={key}
                  row={row}
                  handleUpdate={handleUpdate}
                  handleDelete={handleDelete}
                  readOnly={readOnly}
                  invoiceTemplateScheduleTrigger={
                    invoiceTemplateScheduleTrigger
                  }
                  stringId={generateTableRowId(index)}
                />
              );
            })}
            {!floatNewRowToTop && generateTempRowElements(tempRows)}
          </tbody>
        )}
      </table>
      {showPagination && (
        <TablePagination
          label={getPaginationLabel()}
          hasPrevious={page > 1}
          onPreviousCallback={() => {
            const newPageIndex = page === undefined ? pageIndex - 1 : page - 1;
            setPageIndex(newPageIndex);
            pageChangeCallback(newPageIndex);
          }}
          hasNext={page < pageCount}
          onNextCallback={() => {
            const newPageIndex = page === undefined ? pageIndex + 1 : page + 1;
            setPageIndex(newPageIndex);
            pageChangeCallback(newPageIndex);
          }}
        />
      )}
    </>
  );
};

Table.propTypes = {
  oneAddMax: PropTypes.bool,
  handleCreate: PropTypes.func,
  handleUpdate: PropTypes.func,
  handleDelete: PropTypes.func,
  headers: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  schema: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      type: PropTypes.string,
      editable: PropTypes.bool,
      options: PropTypes.array,
    })
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      data: PropTypes.arrayOf(PropTypes.object),
      editable: PropTypes.bool,
      deletable: PropTypes.bool,
    })
  ).isRequired,
  searchInputProps: PropTypes.shape({
    name: PropTypes.string,
    onChangeCallback: PropTypes.func,
  }),
  dropdownButtonProps: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        onClickCallback: PropTypes.func,
      })
    ),
  }),
  page: PropTypes.number,
  pageCount: PropTypes.number,
  totalRecords: PropTypes.number,
  pageChangeCallback: PropTypes.func,
  floatNewRowToTop: PropTypes.bool,
};

Table.defaultProps = {
  oneAddMax: true,
  handleCreate: () => {},
  handleUpdate: () => {},
  handleDelete: () => {},
  title: null,
  subtitle: null,
  page: undefined,
  pageCount: undefined,
  totalRecords: undefined,
  pageChangeCallback: () => {},
  floatNewRowToTop: false,
};

export default Table;
