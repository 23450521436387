import React from 'react';
import styles from './SendModal.module.scss';
import { Button } from '~ui';
import { absolutePath } from '~constants/routes';

const CreateSessionType = ({ handleHide }) => {
  return (
    <div>
      <p>Please create a session type to use Email Marketing.</p>
      <div className={styles['SendModal-footerButtons']}>
        <Button text="Cancel" danger onClick={handleHide} />
        <Button
          text="Create"
          onClick={() => {
            window.location.href = absolutePath('/studio/session_types');
          }}
        />
      </div>
    </div>
  );
};

export default CreateSessionType;
