import { INVOICE_CONFIGS } from '~constants/actionTypes';

export default function(state = {}, action) {
  switch (action.type) {
    case INVOICE_CONFIGS.UPDATE.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
