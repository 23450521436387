import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { getCharges } from '~actions/charges';
import { actionsLoadingSelector, actionsErroredSelector } from '~selectors';
import styles from './Subscribed.module.scss';
import SubscribedCard from './SubscribedCard';
import STAR from '~images/purplestar.svg';
import { DataTable } from '~ui';
import EditPlanModal from '../EditPlanModal';
import EditBillingDetailsModal from '../EditBillingDetailsModal';
import PleaseResubscribeModal from '../PleaseResubscribeModal';
import NewSubscriptionModal from '../NewSubscriptionModal';

const headers = [
  { name: 'date', labelText: 'Date', sortable: false },
  { name: 'plan', labelText: 'Plan', sortable: false },
  { name: 'amount_charged', labelText: 'Amount Charged', sortable: false },
  { name: 'payment_method', labelText: 'Payment Method', sortable: false },
];

const columnTypes = ['date', 'string', 'dollar', 'string'];

const Subscribed = props => {
  const queryParams = new URLSearchParams(window.location.search);

  // If exists, else this is null
  const promoCode = queryParams.get('promo');

  const { planDisplayName, planInfo } = props;
  const dispatch = useDispatch();

  // If promoCode not null, we want to show the Edit Plan Modal
  const [showEditPlanModal, setShowEditPlanModal] = useState(!!promoCode);
  const [showBillingDetailsModal, setShowBillingDetailsModal] = useState(false);
  const [showResubscribe, setShowResubscribe] = useState(false);
  const [showNewSubModal, setShowNewSubModal] = useState(false);
  const loading = useSelector(state =>
    actionsLoadingSelector(state, ['CHARGES_GET'])
  );
  const { message: error } = useSelector(state =>
    actionsErroredSelector(state, ['CHARGES_GET'])
  );

  const currentSubscription = useSelector(
    state => state.subscription.currentSubscription
  );
  const charges = useSelector(state => state.subscription.charges.items);
  const currentPage = useSelector(state => state.subscription.charges.page);
  const pageCount = useSelector(state => state.subscription.charges.pageCount);
  const totalRecords = useSelector(
    state => state.subscription.charges.totalRecords
  );
  const annualPlanPremium = useSelector(
    state => state.subscription.availablePlans.annualPlanPremium
  );

  const pageChangeCallback = pageNumber => {
    dispatch(getCharges(pageNumber));
  };

  useEffect(() => {
    dispatch(getCharges());
  }, []);

  const price =
    planInfo.planInterval === 'month'
      ? planInfo.planPrice
      : planInfo.planPrice / 12;

  const [dollarAmount, centAmount] = Number(price).toFixed(2).split('.');

  const [
    intervalDisplay,
    planTypeDisplay,
    ...galleryInfo
  ] = planDisplayName.split(' ');

  const renderSubText = () => {
    if (currentSubscription.stripe_plan_id === 'iris-keep-my-data') {
      return (
        <div className={styles['Subscribed-pauseText']}>
          <p>
            Your account is currently paused. Don’t worry - all of your data and
            account details are still intact. Ready to reactivate your account?
            Click on the pencil icon below to get back in
          </p>
        </div>
      );
    }
  };

  return (
    <>
      <div className={styles['Subscribed']}>
        {renderSubText()}
        <div className={styles['Subscribed--topContainer']}>
          <SubscribedCard
            title="Current Plan*"
            editOnClick={() => {
              !!currentSubscription.canceled_at
                ? setShowResubscribe(true)
                : setShowEditPlanModal(true);
            }}
          >
            <div className={styles['Subscribed--currentPlanCard']}>
              <div>
                <h4>{intervalDisplay}</h4>
                <div>
                  <h5>
                    {planTypeDisplay}
                    {planTypeDisplay === 'Premium' && <img src={STAR} />}
                  </h5>
                  {galleryInfo.length > 0 && <p>{galleryInfo.join(' ')}</p>}
                </div>
              </div>
              <span>
                ${dollarAmount}.<sup>{centAmount}</sup>
                <span>/mo</span>
                {planInfo.planInterval == 'year' && <p>Paid Annually</p>}
              </span>
            </div>
          </SubscribedCard>
          <SubscribedCard
            title="Billing Details"
            editOnClick={() => {
              !!currentSubscription.canceled_at
                ? setShowResubscribe(true)
                : setShowBillingDetailsModal(true);
            }}
          >
            <div className={styles['Subscribed--billingDetailsCard']}>
              <p>Visa ending in {currentSubscription.card_last4}</p>
              <p>
                {currentSubscription.billing_first_name}{' '}
                {currentSubscription.billing_last_name}
              </p>
              <p>{currentSubscription.billing_address_1}</p>
              <p>
                {currentSubscription.billing_city},{' '}
                {currentSubscription.billing_state}{' '}
                {currentSubscription.billing_postal}
              </p>
            </div>
          </SubscribedCard>
        </div>
        <div className={styles['Subscribed--paymentTableContainer']}>
          <DataTable
            title={
              <>
                Billing History{' '}
                {error && (
                  <span className={styles['Subscribed--billingHistoryError']}>
                    currently unable to load billing history
                  </span>
                )}
              </>
            }
            headers={headers}
            columnTypes={columnTypes}
            rows={charges.map(charge => ({
              date: charge.date,
              plan: charge.plan,
              amount_charged: charge.amount_charged,
              payment_method: charge.payment_method,
            }))}
            loading={loading}
            page={currentPage}
            pageCount={pageCount}
            totalRecords={totalRecords}
            pageSize={5}
            pageChangeCallback={pageChangeCallback}
          />
        </div>
      </div>
      {showEditPlanModal && (
        <EditPlanModal
          handleHide={() => setShowEditPlanModal(false)}
          initialPromoCode={promoCode}
        />
      )}
      {showBillingDetailsModal && (
        <EditBillingDetailsModal
          handleHide={() => setShowBillingDetailsModal(false)}
        />
      )}
      {showResubscribe && (
        <PleaseResubscribeModal
          handleClick={() => {
            setShowResubscribe(false);
            setShowNewSubModal(true);
          }}
          handleHide={() => setShowResubscribe(false)}
        />
      )}
      {showNewSubModal && (
        <NewSubscriptionModal
          handleHide={() => setShowNewSubModal(false)}
          plan={annualPlanPremium}
        />
      )}
    </>
  );
};
Subscribed.propTypes = {
  planDisplayName: PropTypes.string.isRequired,
};

export default Subscribed;
