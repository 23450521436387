import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '../../store';
import Subscription from '../Subscription';

const SubscriptionRoot = props => {
  const store = configureStore(props);

  return (
    <Provider store={store}>
      <Subscription />
    </Provider>
  );
};

export default SubscriptionRoot;
