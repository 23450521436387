import React from 'react';
import Locations from './Locations';

const LocationsRoot = ({ locations }) => {
  const countryOptions = locations.countryOptions;
  const mapsApiKey = locations.googleMapsApiKey;
  const currentStudio = locations.currentStudio;
  const clients = locations.clients;
  const markerIcon = locations.mapMarker;
  const activeTrial = locations.activeTrial;
  const trialVerified = locations.trialVerified;

  return (
    <div>
      <Locations
        {...{
          countryOptions,
          mapsApiKey,
          currentStudio,
          clients,
          markerIcon,
          activeTrial,
          trialVerified,
        }}
      />
    </div>
  );
};
export default LocationsRoot;
