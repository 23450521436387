import React from 'react';
import MappingDropdown from '../MappingDropdown';
import { Dropdown, TextInput } from '~ui';
import FieldName from '../FieldName';
import styles from './AddressInput.module.scss';
import DraggableInput from '../../draggable/DraggableInput';

const AddressInput = ({
  updateInputData,
  index,
  data,
  handleRemove,
  reOrderHandler,
  title,
  errors,
}) => {
  const handleDropdown = value => {
    if (value != data.client_mapping) {
      updateInputData(index, { client_mapping: value });
    }
  };

  const handleRadio = value => {
    if (value != data.is_required) {
      updateInputData(index, { is_required: value });
    }
  };

  return (
    <DraggableInput
      index={index}
      handleRemove={handleRemove}
      reOrderHandler={reOrderHandler}
      title={title}
    >
      <div>
        <FieldName
          name="introduction"
          initialValue={data.label}
          onChangeCallback={val => updateInputData(index, { label: val })}
          errors={errors}
        />
        <div className={styles.AddressInputStyles}>
          <TextInput
            disabled
            name="address"
            initialValue={''}
            placeholder="Address"
          />
          <div className={styles['AddressInputStyles-flexDivs']}>
            <TextInput
              disabled
              name="city"
              initialValue={''}
              placeholder="City"
            />
            <Dropdown
              labelText=""
              name="country"
              initialValue={0}
              options={[{ label: 'Country', value: '' }]}
              disabled
            />
            <Dropdown
              labelText=""
              name="state"
              initialValue={0}
              options={[{ label: 'State', value: '' }]}
              disabled
            />
            <TextInput
              disabled
              name="zip"
              initialValue={''}
              placeholder="Zip"
            />
          </div>
        </div>
        <MappingDropdown
          radioCallback={handleRadio}
          dropdownCallback={handleDropdown}
          index={index}
          initialRadioValue={data.is_required || false}
          initialMappingValue={data.client_mapping}
          options={[
            { label: 'None', value: null },
            { label: 'Primary Address', value: 'address' },
            { label: 'Client notes', value: 'notes' },
          ]}
        />
      </div>
    </DraggableInput>
  );
};

export default AddressInput;
