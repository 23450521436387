import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { TextInput, Checkbox, Dropdown } from '~ui';
import { routes } from '~constants/routes';
import styles from './InfoRoute.module.scss';
import {
  addClient,
  updateFooterOnContinue,
  updateFooterContinueDisabled,
} from '~actions/customerBooking';
import withBackHandler from '../withBackHandler';
import { emailValidation, phoneValidation } from '~constants/validation';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { MOBILE_WIDTH } from '~constants/viewports';
import { useWindowSize } from '~hooks';
import { useHistory } from 'react-router-dom';
import { CUSTOMER_BOOKING } from '~constants/actionTypes';

const InfoRoute = ({ showEmailMarketing, addressRequired, contractRequired, uuid, BOOKING_PATHS, countryOptions }) => {
  const windowWidth = useWindowSize()[0];
  const isMobileWidth = windowWidth < MOBILE_WIDTH;
  const dispatch = useDispatch();
  const { id, first_name, last_name, phone, email, address, city, state, country, zip_code } = useSelector(
    state => state.customerBooking.clientInfo
  );
  const selectedAvailability = useSelector(
    state => state.customerBooking.selectedAvailability
  );
  const [firstName, setFirstName] = useState(first_name);
  const [lastName, setLastName] = useState(last_name);
  const [emailField, setEmailField] = useState(email);
  const [emailMarketing, setEmailMarketing] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(phone);
  const [addressField, setAddressField] = useState(address);
  const [cityField, setCityField] = useState(city);
  const [stateField, setStateField] = useState(state);
  const [countryField, setCountryField] = useState(country);
  const [zipCode, setZipCode] = useState(zip_code);
  const [emailValidity, setEmailValidity] = useState(true);
  const [phoneValidity, setPhoneValidity] = useState(true);
  const [stateOptions, setStateOptions] = useState([]);
  const history = useHistory();

  const updateAvailability = async id => {
    try {
      const res = await axios.put(
        routes.BOOKING_AVAILABILITIES.UPDATE(selectedAvailability.id),
        {
          booking_availability: {
            booking_calendar_id: selectedAvailability.booking_calendar_id,
            client_id: id,
          },
        }
      );
      return true;
    } catch (error) {
      toast.error(
        'Oops! The time slot you selected has been taken. Please select another time.',
        toastOptions
      );
      Rollbar.error(`Update Returned with Response Error ${error}`)
      return false;
    }
  };

  const handleCountryDropdownChange = alpha2 => {
    setCountryField(alpha2);
    axios
      .get(`/api/states/${alpha2}`)
      .then(res => {
        let options = res.data.map(state => ({
          label: state[1],
          value: state[0],
        }));
        options = [{ label: 'Choose a State/Province', value: null }].concat(
          options
        );
        setStateOptions(options);
      })
      .catch(error => {
        const errorMessage = `Error fetching states for ${alpha2}: ${error.response.data.error.messages}`;
        toast.error(errorMessage, toastOptions);
        Rollbar.error(`${errorMessage}: ${error}`);
      });
  };

  const onContinue = async nextStep => {
    const { clientId, isErrored, errorMessage } = await dispatch(
      addClient({
        first_name: firstName,
        last_name: lastName,
        email: emailField,
        phone: phoneNumber,
        email_marketing: emailMarketing,
        address: addressField,
        city: cityField,
        state: stateField,
        country: countryField,
        zip: zipCode,
      })
    );

    const res = await updateAvailability(clientId);

    if (!res) {
      dispatch({ type: CUSTOMER_BOOKING.RESET });
      history.push(BOOKING_PATHS.root(uuid));
      return;
    }

    if (isErrored) {
      toast.error("Error creating client. Please reach out to support.")
    } else {
      nextStep();
    }
  };

  const contDisabled =
    !firstName ||
    !lastName ||
    !emailField ||
    !phoneNumber ||
    !emailValidity ||
    !phoneValidity;

  const contDisabledSecondary =
    !firstName ||
    !lastName ||
    !emailField ||
    !phoneNumber ||
    !addressField ||
    !cityField ||
    !stateField ||
    !countryField ||
    !zipCode ||
    !emailValidity ||
    !phoneValidity;

  const continueDisabled = () => {
    if (addressRequired) {
      return contDisabledSecondary;
    }
    return contDisabled;
  };

  useEffect(() => {
    dispatch(updateFooterOnContinue(async nextStep => await onContinue(nextStep)));
    dispatch(updateFooterContinueDisabled(continueDisabled()));
  }, [contDisabled, contDisabledSecondary, firstName, lastName, emailField, phoneNumber, addressField, cityField, countryField, stateField, zipCode, emailMarketing]);

  return (
    <>
      <div className={styles.InfoRoute}>
        <p>Please fill out your information in the form below.</p>
        <br />
        <div className={styles['InfoRoute-formColumn']}>
          <div className={styles['InfoRoute-halfWidth']} style={isMobileWidth ? { marginRight: '0px' } : { marginRight: '5px' }}>
            <TextInput
              initialValue={firstName}
              name="first_name"
              labelText="First Name"
              onChangeCallback={setFirstName}
              required
            />
          </div>

          <div className={styles['InfoRoute-halfWidth']} style={isMobileWidth ? { marginLeft: '0px' } : { marginLeft: '5px' }}>
            <TextInput
              initialValue={lastName}
              name="last_name"
              labelText="Last Name"
              onChangeCallback={setLastName}
              required
            />
          </div>
        </div>
        <div className={styles['InfoRoute-formColumn']}>
          <div className={styles['InfoRoute-halfWidth']} style={isMobileWidth ? { marginRight: '0px' } : { marginRight: '5px' }}>
            <TextInput
              initialValue={emailField}
              name="email"
              labelText="Email"
              onChangeCallback={(value, valid) => {
                setEmailField(value);
                setEmailValidity(valid);
              }}
              validation={emailValidation}
              required
            />
          </div>
          <div className={styles['InfoRoute-halfWidth']} style={isMobileWidth ? { marginLeft: '0px' } : { marginLeft: '5px' }}>
            <TextInput
              initialValue={phoneNumber}
              name="phone"
              labelText="Phone Number"
              onChangeCallback={(value, valid) => {
                setPhoneNumber(value);
                setPhoneValidity(valid);
              }}
              validation={phoneValidation}
              type="tel"
              required
            />
          </div>
        </div>
        {addressRequired && <>
          <div className={styles['InfoRoute-formColumn']}>
            <div className={styles['InfoRoute-max']}>
              <TextInput
                initialValue={addressField}
                name="addressInput"
                labelText="Address"
                onChangeCallback={setAddressField}
                required
              />
            </div>
          </div>
          <div className={styles['InfoRoute-formColumn']}>
            <div className={styles['InfoRoute-halfWidth']} style={isMobileWidth ? { marginRight: '0px' } : { marginRight: '5px' }}>
              <TextInput
                initialValue={cityField}
                name="cityInput"
                labelText="City"
                onChangeCallback={setCityField}
                required
              />
            </div>
            <div className={styles['InfoRoute-halfWidth']} style={isMobileWidth ? { marginLeft: '0px' } : { marginLeft: '5px' }}>
              <Dropdown
                options={countryOptions}
                initialValue={countryField}
                name="countryInput"
                labelText="Country"
                onChangeCallback={handleCountryDropdownChange}
                required
              />
            </div>
          </div>
          <div className={styles['InfoRoute-formColumn']}>
            <div className={styles['InfoRoute-halfWidth']} style={isMobileWidth ? { marginRight: '0px' } : { marginRight: '5px' }}>
              <Dropdown
                options={stateOptions}
                initialValue={stateField}
                name="stateInput"
                labelText="State"
                onChangeCallback={setStateField}
                required
              />
            </div>
            <div className={styles['InfoRoute-halfWidth']} style={isMobileWidth ? { marginLeft: '0px' } : { marginLeft: '5px' }}>
              <TextInput
                initialValue={zipCode}
                name="zipCodeInput"
                labelText="Zip Code"
                onChangeCallback={setZipCode}
                required
              />
            </div>
          </div>
        </>}
        {showEmailMarketing && (
          <div className={styles['InfoRoute-formCenter']}>
            <Checkbox
              labelText="Sign me up to receive newsletters, updates and offers."
              onChangeCallback={() =>
                setEmailMarketing(emailMarketing ? false : true)
              }
              name="email"
            />
          </div>
        )}
        <br />
      </div >
    </>
  );
};

export default withBackHandler(InfoRoute);
