import React, { useState } from 'react';
import styles from './BookingSetup.module.scss';
import { TextInput, Toggle, Dropdown, TextArea, Button } from '~ui';
import { routes, absolutePath } from '~constants/routes';
import { useWindowSize } from '~hooks';
import { MOBILE_WIDTH } from '~constants/viewports';
import axios from 'axios';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import TogglePopover from '~components/common/TogglePopover';

const BookingSetupForm = props => {
  const {
    initialBookingCalendar,
    locations,
    sessionTypes,
    journeyTemplates,
    invoiceTemplates,
    contracts,
    shootProofBrands,
    bookingCalendar,
    contractDrawnSignature,
    contractTypedSignature,
  } = props;

  const [formData, setFormData] = useState({
    invoice_required: false,
    contract_required: false,
    address_required: false,
    ...initialBookingCalendar,
  });

  const getSchedule = leadDays => {
    if (leadDays === 0) return { interval: 1, number: 1 };

    if (leadDays % 30 === 0) {
      return { interval: 30, number: leadDays / 30 };
    } else if (leadDays % 7 === 0) {
      return { interval: 7, number: leadDays / 7 };
    } else {
      return { interval: 1, number: leadDays };
    }
  };

  const [leadInterval, setLeadInterval] = useState(
    getSchedule(initialBookingCalendar.lead_days).interval
  );
  const [leadDays, setLeadDays] = useState(
    getSchedule(initialBookingCalendar.lead_days).number
  );

  const [errors, setErrors] = useState([]);
  const [showLeadTime, setShowLeadTime] = useState(formData.lead_days > 0);
  const windowWidth = useWindowSize()[0];
  const {
    hasShootProofIntegration,
    hasPicTimeIntegration,
  } = bookingCalendar.meta.features;
  const handleSave = async () => {
    if (
      formData.contract_required &&
      !+formData.contract_template_id &&
      formData.invoice_required &&
      !+formData.invoice_template_id
    ) {
      toast.info('Please add an invoice!');
      toast.info('Please add a contract!');
    } else if (formData.contract_required && !+formData.contract_template_id) {
      toast.info('Please add a contract!');
    } else if (formData.invoice_required && !+formData.invoice_template_id) {
      toast.info('Please add an invoice!');
    } else if (formData.title.length > 255) {
      toast.error('Title cannot exceed 255 characters.', toastOptions);
    } else {
      const res = await axios.put(
        routes.BOOKING_CALENDARS.UPDATE(formData.id),
        {
          booking_calendar: {
            ...formData,
            lead_days: showLeadTime ? leadDays * leadInterval : 0,
          },
        }
      );

      if (res.status > 399) {
        setErrors(res.data.error.messages);
        return;
      }

      toast.info('Saved!');
      window.location.href = absolutePath(
        `/studio/booking_calendars/${initialBookingCalendar.id}/calendar`
      );
    }
  };

  return (
    <div className={styles.BookingSetup}>
      <div className={styles['BookingSetup-form']}>
        <div className={styles['BookingSetup-formColumns']}>
          <div className="w-50p">
            <TextInput
              labelText="Booking Calendar Title"
              name="title"
              className={styles['BookingSetup-title']}
              initialValue={formData.title === 'Default' ? '' : formData.title}
              onChangeCallback={title => setFormData({ ...formData, title })}
              required
            />
          </div>
          <div className={styles['BookingSetup-leadTime']}>
            <label>Require Lead Time to Book</label>
            <TogglePopover
              activator={<i className="fas fa-info-circle" />}
              popoverContent={
                <div style={{ height: '60px' }}>
                  Prevent last minute bookings by requiring sessions to be
                  completed at least X days in advance.
                </div>
              }
            />
            <div className={styles['BookingSetup-leadTime-content']}>
              <Toggle
                initialValue={formData.lead_days > 0}
                onChangeCallback={setShowLeadTime}
              />
              {showLeadTime && (
                <>
                  <TextInput
                    name="number"
                    type="number"
                    onFocusSelectText
                    characterLimit={1}
                    initialValue={leadDays}
                    onChangeCallback={setLeadDays}
                  />
                  <Dropdown
                    name="interval"
                    initialValue={leadInterval}
                    onChangeCallback={setLeadInterval}
                    options={[
                      { label: 'Day(s)', value: 1 },
                      { label: 'Week(s)', value: 7 },
                      { label: 'Month(s)', value: 30 },
                    ]}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="mt-18">
          <TextArea
            name="introduction"
            labelText="Introduction"
            placeholder="Include a custom welcome message, instructions or detailed information for your calendar."
            initialValue={formData.introduction || ''}
            onChangeCallback={introduction =>
              setFormData({ ...formData, introduction })
            }
          />
        </div>
        <div className="mt-18">
          <div className={styles['BookingSetup-formColumns']}>
            <Dropdown
              options={sessionTypes}
              name="session_type_id"
              labelText="Session Type"
              className={`mr-24 ${
                windowWidth <= MOBILE_WIDTH ? '' : 'mx-w-50p'
              }`}
              initialValue={formData.session_type_id || ''}
              onChangeCallback={session_type_id =>
                setFormData({ ...formData, session_type_id })
              }
            />
            <Dropdown
              options={journeyTemplates}
              name="journey_template_id"
              labelText="Workflow"
              initialValue={formData.journey_template_id || ''}
              onChangeCallback={journey_template_id =>
                setFormData({ ...formData, journey_template_id })
              }
            />
            <hr />
          </div>
        </div>
        {hasPicTimeIntegration && (
          <div className="mt-18">
            <div className={styles['BookingSetup-formColumns']}>
              <Toggle
                labelText="Create PicTime Project"
                initialValue={formData.create_pic_time_project}
                onChangeCallback={create_pic_time_project =>
                  setFormData({
                    ...formData,
                    create_pic_time_project,
                  })
                }
                className="mr-24 w-100p"
              />
            </div>
          </div>
        )}
        {hasShootProofIntegration && (
          <div className="mt-18">
            <div className={styles['BookingSetup-formColumns']}>
              <Toggle
                labelText="Create Shootproof Gallery"
                initialValue={formData.create_shoot_proof_gallery}
                onChangeCallback={create_shoot_proof_gallery =>
                  setFormData({ ...formData, create_shoot_proof_gallery })
                }
                className="mr-24 w-100p"
              />
              {formData.create_shoot_proof_gallery && (
                <Dropdown
                  name="shoot_proof_brand_id"
                  labelText="Shootproof Brand"
                  options={shootProofBrands}
                  onChangeCallback={brandId =>
                    setFormData({
                      ...formData,
                      shoot_proof_brand_id: brandId,
                    })
                  }
                  initialValue={String(formData.shoot_proof_brand_id)}
                />
              )}
            </div>
          </div>
        )}
        <div className="mt-18">
          <div className={styles['BookingSetup-formColumns']}>
            <Dropdown
              options={locations}
              labelText="Saved Locations"
              name="location_id"
              initialValue={formData.location_id || ''}
              className={`mr-24 ${
                windowWidth <= MOBILE_WIDTH ? '' : 'mx-w-50p'
              }`}
              onChangeCallback={location_id =>
                setFormData({ ...formData, location_id })
              }
            />
            <TextInput
              labelText="New Location"
              name="full_address"
              className="w-100p"
              initialValue={formData.full_address || ''}
              onChangeCallback={full_address =>
                setFormData({ ...formData, full_address })
              }
            />
          </div>
        </div>
        <div className="mt-18">
          <TextArea
            name="notes"
            labelText="Notes"
            placeholder="Optional private notes for your sessions (not visible to your clients)"
            initialValue={formData.notes || ''}
            onChangeCallback={notes => setFormData({ ...formData, notes })}
          />
        </div>
        <div className="mt-18">
          <Toggle
            labelText="Require Client Address"
            initialValue={formData.address_required || false}
            onChangeCallback={address_required =>
              setFormData({ ...formData, address_required })
            }
          />
        </div>
        <div className="mt-18">
          <Toggle
            labelText="Allow Coupon Codes"
            initialValue={formData.coupons_allowed || false}
            onChangeCallback={coupons_allowed =>
              setFormData({ ...formData, coupons_allowed })
            }
          />
        </div>
        <p className={styles['BookingSetup-warning']}>
          To require your client to pay an invoice or sign a contract for their
          session prior to completing their booking, make your selections here.
        </p>
        <div className={styles['BookingSetup-formToggles']}>
          <div className={styles['BookingSetup-formRows']}>
            <Toggle
              labelText="Require Contract"
              initialValue={formData.contract_required}
              disabledTooltipDescription="Please fill the Contract Signature fields in Studio Settings to add a Contract"
              disabled={!contractTypedSignature || !contractDrawnSignature}
              onChangeCallback={contract_required => {
                setFormData({
                  ...formData,
                  contract_required,
                  ...(contract_required === false && {
                    contract_template_id: null,
                  }),
                });
              }}
            />
            <Dropdown
              disabled={!formData.contract_required}
              options={contracts}
              initialValue={formData.contract_template_id || ''}
              name="contract_template_id"
              className="mt-18"
              onChangeCallback={contract_template_id => {
                setFormData({ ...formData, contract_template_id });
              }}
              required
            />
          </div>
          <div className={styles['BookingSetup-formRows']}>
            <div className={styles['BookingSetup-invoice']}>
              <label>Require Invoice</label>
              <TogglePopover
                activator={<i className="fas fa-info-circle" />}
                popoverContent={
                  <div style={{ height: '60px' }}>
                    Don’t see your invoice template? Make sure the ‘First
                    Payment Due’ on your invoice template is set to ‘When
                    Invoice is Issued’.
                  </div>
                }
              />
              <Toggle
                initialValue={formData.invoice_required}
                onChangeCallback={invoice_required => {
                  setFormData({
                    ...formData,
                    invoice_required,
                    ...(invoice_required === false && {
                      invoice_template_id: null,
                    }),
                  });
                }}
              />
              <Dropdown
                disabled={!formData.invoice_required}
                options={invoiceTemplates}
                initialValue={formData.invoice_template_id || ''}
                name="invoice_template_id"
                className="mt-18"
                onChangeCallback={invoice_template_id => {
                  setFormData({ ...formData, invoice_template_id });
                }}
                required
              />
            </div>
          </div>
        </div>
        <br />
        {errors && <span>{errors.join(', ')}</span>} <br />
        <div className={styles['BookingSetup-formButton']}>
          <Button text="Save and Continue" onClick={handleSave} />
        </div>
      </div>
    </div>
  );
};

export default BookingSetupForm;
