import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableNew as Table, Pill, DeleteConfirmation, Modal } from '~ui';
import moment from 'moment-timezone';
import {
  getClientContracts,
  updateClientContractsMeta,
} from '~actions/contracts';
import ClientContractsButtons from '../ButtonGroups/ClientContractsButtons';
import axios from 'axios';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { routes } from '~constants/routes';
import styles from './ClientContractsTable.module.scss';

const ClientContractsTable = ({ clientId }) => {
  const { data, meta } = useSelector(state => state.documents.contracts);
  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [idForDelete, setIdForDelete] = useState();
  const [showViewModal, setShowViewModal] = useState(false);
  const [viewModalContent, setViewModalContent] = useState();

  const ITEM_HEADERS = ['Name', 'Last Updated', 'Status'];

  const getStatusPill = status => {
    switch (status) {
      case 'unread':
        return (
          <Pill
            text={status.charAt(0).toUpperCase() + status.slice(1)}
            color="orange"
          />
        );
      case 'signed':
        return (
          <Pill
            text={status.charAt(0).toUpperCase() + status.slice(1)}
            color="green"
          />
        );
      case 'read':
        return (
          <Pill
            text={status.charAt(0).toUpperCase() + status.slice(1)}
            color="blue"
          />
        );
    }
  };

  const getUpdatedDate = (status, attributes) => {
    switch (status) {
      case 'unread':
        return attributes.delivered_at;
      case 'signed':
        return attributes.signed_at;
      case 'read':
        return attributes.read_at;
    }
  };

  const createRowItem = ({ id, name, lastUpdateDate, status, contract }) => {
    const formattedStatus =
      status == 'unread'
        ? 'Sent'
        : status.charAt(0).toUpperCase() + status.slice(1);

    return {
      id: +id,
      editable: false,
      deletable: true,
      temp: false,
      deleteButtonOverride: () => {
        setIdForDelete(id);
        setShowDeleteModal(true);
      },
      onClickCallback: () => {
        setViewModalContent(contract.contract_body);
        setShowViewModal(true);
      },
      buttonGroup: <ClientContractsButtons contract={contract} />,
      data: {
        name: {
          value: name,
          type: 'enum',
          editable: false,
        },
        lastUpdateDate: {
          value: `${formattedStatus} on: ${moment(lastUpdateDate).format(
            'MM/DD/YYYY'
          )}`,
          type: 'enum',
          editable: false,
        },
        status: {
          value: getStatusPill(status),
          type: 'enum',
          editable: false,
        },
      },
    };
  };

  useEffect(() => {
    const newRows = data.map(contract => {
      return createRowItem({
        id: contract.id,
        name: contract.attributes.contract_template_name,
        lastUpdateDate: getUpdatedDate(
          contract.attributes.status,
          contract.attributes
        ),
        status: contract.attributes.status,
        contract: contract.attributes,
      });
    });

    setRows(newRows);
  }, [data]);

  const handleContractDelete = async () => {
    try {
      await axios.delete(routes.CONTRACTS.DELETE(idForDelete));
      setShowDeleteModal(false);
      setIdForDelete(null);
      dispatch(getClientContracts(clientId));
    } catch (error) {
      console.error(error);
      toast.error('There was an error deleting your contract', toastOptions);
      setShowDeleteModal(false);
      setIdForDelete(null);
    }
  };

  return (
    <>
      <div>
        <Table
          stylesForButtonsAndIcons={styles.ContractsTable}
          readOnly
          headers={ITEM_HEADERS}
          rows={rows}
          setRows={setRows}
          page={meta.page}
          pageCount={meta.page_count}
          totalRecords={meta.total_records}
          pageChangeCallback={newPageIndex => {
            dispatch(
              updateClientContractsMeta({
                page: newPageIndex,
              })
            );
            dispatch(getClientContracts(clientId));
          }}
        />
      </div>
      {showDeleteModal && (
        <DeleteConfirmation
          handleHide={() => setShowDeleteModal(false)}
          handleDelete={() => handleContractDelete(idForDelete)}
          resource="Contract"
        />
      )}
      {showViewModal && (
        <Modal
          title="View Contract"
          handleHide={() => {
            setViewModalContent(null);
            setShowViewModal(false);
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: viewModalContent }} />
        </Modal>
      )}
    </>
  );
};

export default ClientContractsTable;
