import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { Button, TextArea, Icon } from '~ui';
import styles from './CustomerInvoice.module.scss';

const QuoteOptions = ({ invoice }) => {
  const [isModificationVisible, setIsModificationVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState('');

  const acceptQuote = () => {
    setIsLoading(true);
    axios
      .post(`/customer/invoices/${invoice.uuid}/accept_quote`)
      .then(res => window.location.reload())
      .catch(error => {
        setIsLoading(false);
        toast.error(
          'Something went wrong. Please contact support if this problem persists.',
          toastOptions
        );
      });
  };

  const declineQuote = params => {
    setIsLoading(true);
    axios
      .post(`/customer/invoices/${invoice.uuid}/decline_quote`, params)
      .then(res => window.location.reload())
      .catch(error => {
        setIsLoading(false);
        toast.error(
          'Something went wrong. Please contact support if this problem persists.',
          toastOptions
        );
      });
  };

  return (
    <>
      {isLoading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingText}>
            <p>Loading...</p>
            <Icon name="spinner" className="fa-pulse" large />
          </div>
        </div>
      )}
      <div className={styles.QuoteOptions}>
        <Button
          text="Accept"
          icon="check"
          onClick={() => acceptQuote()}
          category="outlined"
        />
        <Button
          text="Decline"
          icon="times"
          onClick={() => declineQuote()}
          category="outlined"
        />
        <Button
          text="Request Modification"
          icon="comment"
          onClick={() => setIsModificationVisible(true)}
          category="outlined"
        />
      </div>
      {isModificationVisible && (
        <div className={styles['QuoteOptions-request']}>
          Your feedback is appreciated.
          <TextArea
            placeholder="Please enter your comments here."
            onChangeCallback={setComments}
            name="comments"
          />
          <br />
          <Button
            text="Request Modification"
            onClick={() =>
              declineQuote({
                quote_comments: comments,
                modification_requested: true,
              })
            }
            className="mr-8"
          />
          <Button
            text="Cancel"
            onClick={() => setIsModificationVisible(false)}
            danger
          />
        </div>
      )}
    </>
  );
};

QuoteOptions.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default QuoteOptions;
