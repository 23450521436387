import React from 'react';
import styles from './ClientsHeader.module.scss';
import { useSelector } from 'react-redux';
import { Button } from '~ui';
import ToggleContent from '~components/common/ToggleContent';
import NewLeadModal from '../NewLeadModal';
import NewClientModal from '../NewClientModal';
import ImportClientsModal from '../ImportClientsModal';
import ManageGroupModal from '../ClientListModals/ManageGroupModal/index';

const ClientsHeader = ({ importsEnabled }) => {
  const titleDisplay = useSelector(state => state.clientList.meta.titleDisplay);
  const groups = useSelector(state => state.clientList.meta.groups);
  const questionnaireOptions = useSelector(
    state => state.clientList.questionnaires
  );
  const currentPlan = useSelector(state => state.clientList.current_plan);

  return (
    <div className={styles['ClientsHeader']}>
      <div className={styles['ClientsHeader-title']}>
        <h1>{titleDisplay}</h1>
        <div className={styles['ClientsHeader-title-buttons']}>
          <div className={styles['ClientsHeader-title-buttonGroup']}>
            {!groups && (
              <>
                <ToggleContent
                  toggle={handleShow => (
                    <Button
                      onClick={handleShow}
                      purpleRectangle={true}
                      text="New Lead"
                      icon="plus"
                    />
                  )}
                  content={handleHide => (
                    <NewLeadModal
                      handleHide={handleHide}
                      questionnaires={questionnaireOptions}
                      currentPlan={currentPlan}
                    />
                  )}
                />
                <ToggleContent
                  toggle={handleShow => (
                    <Button
                      onClick={handleShow}
                      purpleRectangle={true}
                      text="New Client"
                      icon="plus"
                    />
                  )}
                  content={handleHide => (
                    <NewClientModal
                      handleHide={handleHide}
                      questionnaires={questionnaireOptions}
                      currentPlan={currentPlan}
                    />
                  )}
                />
                {importsEnabled && (
                  <ToggleContent
                    toggle={handleShow => (
                      <Button
                        onClick={handleShow}
                        whiteRectangle={true}
                        text="Import Clients"
                        icon="upload"
                      />
                    )}
                    content={handleHide => (
                      <ImportClientsModal
                        handleHide={handleHide}
                        questionnaires={questionnaireOptions}
                        currentPlan={currentPlan}
                      />
                    )}
                  />
                )}
              </>
            )}
            {groups && (
              <>
                <ToggleContent
                  toggle={handleShow => (
                    <Button
                      onClick={handleShow}
                      purpleRectangle={true}
                      text="New Group"
                      icon="plus"
                    />
                  )}
                  content={handleHide => (
                    <ManageGroupModal handleHide={handleHide} />
                  )}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsHeader;
