import React from 'react';
import { TextArea } from '~ui';
import styles from './IntroductionInput.module.scss';
import DraggableInput from '../../draggable/DraggableInput';

const IntroductionInput = ({
  updateInputData,
  index,
  data,
  handleRemove,
  reOrderHandler,
  title,
  errors,
}) => {
  return (
    <DraggableInput
      index={index}
      handleRemove={handleRemove}
      reOrderHandler={reOrderHandler}
      title={title}
    >
      <div className={styles.IntroductionInput}>
        <TextArea
          name="introduction"
          initialValue={data.label}
          onChangeCallback={val => updateInputData(index, { label: val })}
          setWarningForEmpty={errors}
        />
      </div>
    </DraggableInput>
  );
};

export default IntroductionInput;
