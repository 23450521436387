import React from 'react';
import NewInvoiceModal from '../../../Invoices/NewInvoiceModal/index';
import { useSelector } from 'react-redux';
import styles from './ClientInvoicesHeader.module.scss';
const ClientInvoicesHeader = () => {
  const { invoiceProducts } = useSelector(state => state.invoice);

  return (
    <div className={styles.ClientInvoicesHeader}>
      <h1>Client Invoices</h1>
      <div>
        <NewInvoiceModal {...{ invoiceProducts }} />
      </div>
    </div>
  );
};

export default ClientInvoicesHeader;
