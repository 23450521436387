import React from 'react';
import styles from './InvoicesHeader.module.scss';
import { Button } from '~ui';
import NewInvoiceModal from '../NewInvoiceModal';
import FundyImportModal from '../FundyImportModal';
import { useSelector } from 'react-redux';

const InvoicesHeader = () => {
  const { revenue, useFundy, invoiceProducts } = useSelector(
    state => state.invoice
  );
  const { codesExpanded } = useSelector(state => state.invoice.meta);

  if (codesExpanded) {
    return <></>;
  }

  return (
    <div className={styles.InvoicesHeader}>
      <div className={styles['InvoicesHeader-title']}>
        <h1>Invoices</h1>
        <div className={styles['InvoicesHeader-title-buttons']}>
          <div className={styles['InvoicesHeader-title-buttonGroup']}>
            <NewInvoiceModal {...{ invoiceProducts }} />
            <Button
              text="Create Template"
              onClick={() => {
                window.location.href = '/studio/invoice_templates/new';
              }}
              whiteRectangle={true}
              icon="pencil-alt"
            />
            {useFundy && <FundyImportModal />}
          </div>
          {invoiceProducts.length < 1 && (
            <div className={styles['InvoicesHeader-title-productsLink']}>
              <a href="/studio/invoice_setup">
                Please add some invoice products before creating invoices
              </a>
            </div>
          )}
        </div>
      </div>
      <div className={styles['InvoicesHeader-new-revenue']}>
        <div>
          <h3>Invoiced This Month</h3>
          <p>{revenue.invoicedThisMonth}</p>
        </div>
        <div>
          <h3>Accounts Receivable</h3>
          <p>{revenue.accountsReceivable}</p>
        </div>
        <div>
          <h3>Received This Month</h3>
          <p>{revenue.amountReceivedForThisMonth}</p>
        </div>
        <div>
          <h3>Received This Year</h3>
          <p>{revenue.receivedThisYear}</p>
        </div>
        <div>
          <h3>Received Last Year</h3>
          <p>{revenue.receivedLastYear}</p>
        </div>
      </div>
    </div>
  );
};

export default InvoicesHeader;
