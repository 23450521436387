import React from 'react';
import { Modal, Button } from '~ui';
import styles from './ActionConfirmationModal.module.scss';
const ActionConfirmationModal = props => {
  const { option, handleHide } = props;

  return (
    <Modal
      title={option.rowAction ? 'Edit' : `Bulk Edit`}
      handleHide={handleHide}
      maxWidth={380}
    >
      <div className={styles.modalTitle}>
        Are you sure you want to make item(s){' '}
        {option.text.split(' ')[1].toLowerCase()}?
      </div>
      <div className={styles.buttons}>
        <Button text="Cancel" whiteRectangle={true} onClick={handleHide} />
        <Button
          text={option.text}
          purpleRectangle={true}
          onClick={() => {
            option.onClickCallback();
            handleHide();
          }}
        />
      </div>
    </Modal>
  );
};

export default ActionConfirmationModal;
