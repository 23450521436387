import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Button,
  Dropdown,
  Checkbox,
  Icon,
  Modal,
  DateTimePicker,
  TextInput,
} from '~ui';
import { getMergedAvailabilities } from '~selectors';
import {
  updateSlotInterval,
  updateShowAllCalendars,
  handlePublish,
} from '~actions/bookingCalendar';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '~constants/datetime';
import { routes, absolutePath } from '~constants/routes';
import styles from './CalendarHeader.module.scss';
import ToggleContent from '~components/common/ToggleContent';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';

const AvailabilityCalendarHeader = props => {
  const {
    dateContext,
    nextInterval,
    prevInterval,
    getAvailabilitiesForInterval,
    isClient,
  } = props;
  const dateHeader = `${moment(dateContext)
    .startOf('week')
    .format(DATE_FORMAT)} -
  ${moment(dateContext).endOf('week').format(DATE_FORMAT)}`;
  const [showOptions, setShowOptions] = useState(false);
  const bookingCalendarId = useSelector(state => state.bookingCalendar.id);
  const { lastPublished } = useSelector(state => state.bookingCalendar.meta);
  const slotInterval = useSelector(state => state.bookingCalendar.slotInterval);
  const availabilities = useSelector(getMergedAvailabilities);
  const showAllCalendars = useSelector(
    state => state.bookingCalendar.showAllCalendars
  );

  const [miniStartDate, setMiniStartDate] = useState(moment());
  const [numberOfSessions, setNumberOfSessions] = useState(1);
  const [sessionLength, setSessionLength] = useState(5);
  const [timeBetween, setTimeBetween] = useState(0);
  const [miniLoading, setMiniLoading] = useState(false);

  const createMiniSessionAvailabilities = () => {
    const availabilities = [];
    for (let i = 0; i < numberOfSessions; i++) {
      if (i === 0) {
        availabilities.push({
          start_time: moment(miniStartDate).set('second', 0),
          end_time: moment(miniStartDate)
            .add(sessionLength, 'minutes')
            .set('second', 0),
        });
      } else {
        availabilities.push({
          start_time: moment(availabilities[i - 1].end_time)
            .add(timeBetween, 'minutes')
            .set('second', 0),
          end_time: moment(availabilities[i - 1].end_time)
            .add(timeBetween, 'minutes')
            .add(sessionLength, 'minutes')
            .set('second', 0),
        });
      }
    }

    return availabilities;
  };

  const handleMiniSave = async handleHide => {
    try {
      const params = {
        booking_calendar: {
          availabilities_attributes: createMiniSessionAvailabilities(),
        },
      };

      setMiniLoading(true);
      await axios.put(
        routes.BOOKING_CALENDARS.UPDATE(bookingCalendarId),
        params
      );
      getAvailabilitiesForInterval(dateContext);
      setMiniLoading(false);
      handleHide();
    } catch (error) {
      setMiniLoading(false);
      toast.error(
        'This time has already been marked as available.',
        toastOptions
      );
    }
  };

  const dispatch = useDispatch();
  const menuButtonRef = useRef();
  const optionsRef = useRef();
  const handleClickOutside = e => {
    if (optionsRef.current && !optionsRef.current.contains(e.target)) {
      setShowOptions(false);
      return;
    }
  };

  useLayoutEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleContinue = async () => {
    // Passing this in as a callback to the action, for safari
    dispatch(
      handlePublish(() => {
        window.location.href = absolutePath(
          `/studio/booking_calendars/${bookingCalendarId}/summary`
        );
      })
    );
  };
  const intervalOptions = [
    { label: '1 hour', value: 60 },
    { label: '30 minutes', value: 30 },
    { label: '20 minutes', value: 20 },
    { label: '15 minutes', value: 15 },
    { label: '10 minutes', value: 10 },
    { label: '5 minutes', value: 5 },
  ];
  const copyAvailabilities = async () => {
    if (availabilities.length === 0) {
      return;
    }

    const params = {
      booking_calendar: {
        availabilities_attributes: availabilities
          .filter(availability => {
            const isCurrent = showAllCalendars ? availability.current : true;
            const afterIntervalStart = moment(availability.start_time)
              .clone()
              .add(1, 'week')
              .isAfter(moment(dateContext).add(1, 'week').startOf('week'));

            return isCurrent && afterIntervalStart;
          })
          .map(availability => {
            return {
              start_time: moment(availability.start_time)
                .clone()
                .add(1, 'week'),
              end_time: moment(availability.end_time).clone().add(1, 'week'),
              all_day: availability.all_day,
            };
          }),
      },
      copy_availabilities: true,
    };

    await axios.put(routes.BOOKING_CALENDARS.UPDATE(bookingCalendarId), params);
    nextInterval();
  };
  return (
    <>
      {miniLoading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingText}>
            <p>Loading...</p>
            <Icon name="spinner" className="fa-pulse" large />
          </div>
        </div>
      )}
      <div className={styles['CalendarHeader']}>
        <div className={styles['CalendarHeader-column']}>
          <div className={styles['CalendarHeader-date']}>
            <div className={styles['CalendarHeader-intervalArrow']}>
              <i className="fa fa-chevron-left" onClick={prevInterval} />
            </div>
            <div className={styles['CalendarHeader-dateHeader']}>
              <span>{dateHeader}</span>
            </div>
            <div className={styles['CalendarHeader-intervalArrow']}>
              <i className="fa fa-chevron-right" onClick={nextInterval} />
            </div>
          </div>
          <div className={styles['CalendarHeader-buttons']}>
            {!isClient && (
              <ToggleContent
                toggle={handleShow => (
                  <Button text="Mini Session Scheduler" onClick={handleShow} />
                )}
                content={handleHide => (
                  <Modal
                    title="Mini Session Scheduler"
                    handleHide={() => {
                      handleHide();
                    }}
                  >
                    <div className={styles['CalendarHeader-mini']}>
                      <div className={styles['CalendarHeader-mini-topRow']}>
                        <DateTimePicker
                          name="startTime"
                          labelText="Start time"
                          onChangeCallback={setMiniStartDate}
                          initialDateTime={miniStartDate}
                        />
                        <TextInput
                          name="numberSessions"
                          labelText="Number of sessions"
                          type="number"
                          initialValue={numberOfSessions}
                          onFocusSelectText
                          characterLimit={2}
                          className={styles['CalendarHeader-mini-topRow-input']}
                          onChangeCallback={setNumberOfSessions}
                        />
                      </div>
                      <div className={styles['CalendarHeader-mini-dropdowns']}>
                        <Dropdown
                          labelText="Session length"
                          name="sessionLength"
                          initialValue={sessionLength}
                          options={[
                            { label: '5 minutes', value: 5 },
                            { label: '10 minutes', value: 10 },
                            { label: '15 minutes', value: 15 },
                            { label: '20 minutes', value: 20 },
                            { label: '25 minutes', value: 25 },
                            { label: '30 minutes', value: 30 },
                          ]}
                          onChangeCallback={setSessionLength}
                        />
                        <Dropdown
                          labelText="Time between sessions"
                          name="timeBetween"
                          initialValue={timeBetween}
                          options={[
                            { label: 'None', value: 0 },
                            { label: '5 minutes', value: 5 },
                            { label: '10 minutes', value: 10 },
                            { label: '15 minutes', value: 15 },
                            { label: '20 minutes', value: 20 },
                            { label: '25 minutes', value: 25 },
                            { label: '30 minutes', value: 30 },
                          ]}
                          onChangeCallback={setTimeBetween}
                        />
                      </div>
                      <div className={styles['CalendarHeader-mini-saveCancel']}>
                        <Button text="Cancel" onClick={handleHide} />
                        <Button
                          text="Save"
                          onClick={() => handleMiniSave(handleHide)}
                        />
                      </div>
                    </div>
                  </Modal>
                )}
              />
            )}
            {!isClient && (
              <Button text="Publish & Continue" onClick={handleContinue} />
            )}
            <button
              className={styles['CalendarHeader-actionButton']}
              ref={menuButtonRef}
              aria-label="Calendar options"
              aria-haspopup="true"
              aria-controls="options"
              aria-expanded={showOptions}
              onClick={() => {
                setShowOptions(!showOptions);
              }}
            >
              <Icon name="ellipsis-v" large />
            </button>
            {showOptions && (
              <ul
                id="options"
                ref={optionsRef}
                className={styles['CalendarHeader-actionPanel']}
                role="menu"
                aria-labelledby="menuButton"
              >
                <li>
                  <p className="fs-12 c-dg">
                    Select an interval for your time slots. Default is 60
                    minutes.
                  </p>
                  <Dropdown
                    name="slot_interval"
                    options={intervalOptions}
                    initialValue={slotInterval}
                    onChangeCallback={interval =>
                      dispatch(updateSlotInterval(interval))
                    }
                  />
                </li>
                <li>
                  <Checkbox
                    labelText="Show other booking calendars"
                    checked={showAllCalendars}
                    name="showAllCalendars"
                    onChangeCallback={value => {
                      dispatch(updateShowAllCalendars(value));
                    }}
                  />
                </li>
                <li>
                  <Button
                    text="Apply to following week"
                    icon="clone"
                    onClick={copyAvailabilities}
                    white
                  />
                </li>
              </ul>
            )}
          </div>
        </div>
        {!isClient && (
          <div className={styles['CalendarHeader-lastPublished']}>
            {lastPublished
              ? `Last Published: ${moment(lastPublished).format(
                  DATE_TIME_FORMAT
                )}`
              : 'Your calendar has not yet been published'}
          </div>
        )}
      </div>
    </>
  );
};
AvailabilityCalendarHeader.propTypes = {
  dateContext: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  nextInterval: PropTypes.func.isRequired,
  prevInterval: PropTypes.func.isRequired,
};
export default AvailabilityCalendarHeader;
