import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { Modal, Button } from '~ui';
import { routes } from '~constants/routes';
import styles from './EditAndSend.module.scss';
import TinyMceInput from '~components/common/TinyMceInput';

const EmailModal = props => {
  const {
    client,
    calendarEvent,
    emailTemplate,
    journeyTemplate,
    toDo,
    initialEmailBody,
    handleHide,
    handleCancel,
    booking_urls,
    locations,
  } = props;

  const [emailBody, setEmailBody] = useState(initialEmailBody);

  const handleSend = async () => {
    const clientParams = {
      client: {
        client_id: client.id,
        email_template_id: emailTemplate.id,
        calendar_event_id: calendarEvent.id,
        email_body: emailBody,
        to_do_id: toDo.id,
      },
    };

    try {
      await axios.post(routes.CLIENTS.SEND_EMAIL, clientParams);
      handleHide();
      toast.success('Email sent successfully!', toastOptions);
    } catch (error) {
      toast.error(`Error sending email to client: ${error}`, toastOptions);
    }
  };

  return (
    <Modal
      title={`Edit Email For ${client.first_name} ${client.last_name}`}
      handleHide={handleHide}
    >
      <p>
        <strong>Session:</strong> {calendarEvent.title}
      </p>
      <p>
        <strong>Workflow:</strong> {journeyTemplate.name}
      </p>
      <br />
      <TinyMceInput
        value={emailBody}
        onChangeCallback={setEmailBody}
        bookingUrls={booking_urls}
        locations={locations}
      />
      <div className={styles['EditAndSendEmailModal-buttons']}>
        <Button
          text="Cancel"
          onClick={() => {
            handleHide();
            handleCancel();
          }}
          danger
        />
        <Button onClick={handleSend} text="Send Email" />
      </div>
    </Modal>
  );
};

export default EmailModal;
