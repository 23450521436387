import React, { useState, useContext, useEffect } from 'react';
import { TextArea, Button, Accordion, TextInput } from '~ui';
import { absolutePath } from '~constants/routes';
import styles from './MarketingLandingPageSettings.module.scss';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { useDebounce, useIsFirstMount } from '~hooks';
import EmailMarketingContext from '../../EmailMarketingContext';
import TogglePopover from '~components/common/TogglePopover';
import { ChromePicker } from 'react-color';
import axios from 'axios';
import { routes } from '~constants/routes';

const MarketingLandingPageSettings = () => {
  const { studioId, marketingMessage, marketingSubmitColor } = useContext(
    EmailMarketingContext
  );
  const [message, setMessage] = useState(marketingMessage);
  const [submitColor, setSubmitColor] = useState(
    marketingSubmitColor || '#111111'
  );
  const [captureCopyText, setCaptureCopyText] = useState('Copy');
  const [HTMLCopyText, setHTMLCopyText] = useState('Copy');
  const debouncedMessage = useDebounce(message, 1500);
  const debouncedSubmitColor = useDebounce(submitColor, 1500);
  const isFirstMount = useIsFirstMount();
  const captureURL = absolutePath(
    `/customer/marketing_contacts/new?ref=${studioId}`
  );
  const marketingHTML = `<iframe allowtransparency="true" src="${absolutePath(
    `/customer/marketing_contacts/new?ref=${studioId}`
  )}" width="100%" height="850px" frameBorder="0"></iframe>`;

  useEffect(() => {
    if (!isFirstMount) {
      handleStudioSave();
    }
  }, [debouncedMessage, debouncedSubmitColor]);

  const handleStudioSave = async () => {
    try {
      const res = await axios.put(routes.STUDIO_PROFILE.UPDATE(studioId), {
        studio: {
          marketing_message: message,
          marketing_submit_color: submitColor,
        },
      });
      if (res.status === 200) {
        toast.success('Settings Saved', toastOptions);
      }
    } catch (error) {
      toast.error('There was an error saving your settings', toastOptions);
      console.error(error);
    }
  };

  return (
    <>
      <div className={styles.ToggleContainer}>
        <p>Submit Button Color</p>
        <TogglePopover
          popoverWidth="267px"
          activator={
            <div
              className={styles['ToggleContainer-label-color']}
              style={{ backgroundColor: submitColor }}
            />
          }
          popoverContent={
            <ChromePicker
              disableAlpha
              color={submitColor}
              onChange={({ hex }) => {
                setSubmitColor(hex);
              }}
            />
          }
        />
      </div>
      <div className={styles.TextArea}>
        <TextArea
          labelText="Introduction/Welcome Message"
          name="marketingMessage"
          initialValue={message}
          onChangeCallback={setMessage}
        />
      </div>
      <div className={styles.MarketingPageShare}>
        <label>
          To automatically capture marketing contacts within Iris, add this URL
          to a page or a button on your website:
        </label>
        <div className={styles['MarketingPageShare-copyContainer2']}>
          <TextInput disabled initialValue={captureURL} name="URL" />
          <Button
            text={captureCopyText}
            onClick={() => {
              navigator.clipboard.writeText(captureURL);
              setCaptureCopyText('Copied!');
              setTimeout(() => setCaptureCopyText('Copy'), 1500);
            }}
          />
        </div>
        <hr />

        <label>
          Insert the following HTML to an existing web page to render your form:
        </label>
        <div className={styles['MarketingPageShare-copyContainer2']}>
          <TextArea disabled initialValue={marketingHTML} name="HTML" />
          <Button
            text={HTMLCopyText}
            onClick={() => {
              navigator.clipboard.writeText(marketingHTML);
              setHTMLCopyText('Copied!');
              setTimeout(() => setHTMLCopyText('Copy'), 1500);
            }}
          />
        </div>
      </div>
      <div className={styles.LeadsPagePreview}>
        <Accordion title="Opt-in Form Preview" expanded={false}>
          <iframe
            src={absolutePath(
              `/customer/marketing_contacts/new?ref=${studioId}`
            )}
          />
        </Accordion>
      </div>
    </>
  );
};

export default MarketingLandingPageSettings;
