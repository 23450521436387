import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import {
  calendarEventsSelector,
  multiWeekEventsSelectorWeekView,
  multiWeekAvailabilitiesSelector,
} from '~selectors';
import { DATE_KEY_FORMAT } from '~constants/datetime';
import { uniqueId, get } from '~utils';
import TimeSlotGroup from '../TimeSlotGroup';
import styles from './WeekView.module.scss';
import { HOURS, DEFAULT_EVENTS } from '~constants/calendar';

const WeekView = ({ dateContext }) => {
  const currentDate = moment();
  const timeZone = useSelector(state => state.bookingCalendar.meta.timeZone);
  const mutableDateContext = moment(dateContext);
  const allEvents = useSelector(calendarEventsSelector);
  const multiWeekEvents = useSelector(multiWeekEventsSelectorWeekView);
  const multiWeekAvailabilities = useSelector(multiWeekAvailabilitiesSelector);
  const slotInterval = useSelector(state => state.bookingCalendar.slotInterval);
  const allAvailabilities = useSelector(
    state => state.bookingCalendar.availabilities
  );
  const cx = classNames.bind(styles);
  const hourClasses = cx('WeekView-hour', {
    'WeekView-hour--oneHour': slotInterval == 60,
    'WeekView-hour--halfHour': slotInterval == 30,
    'WeekView-hour--thirdHour': slotInterval == 20,
    'WeekView-hour--quarterHour': slotInterval == 15,
    'WeekView-hour--sixthHour': slotInterval == 10,
    'WeekView-hour--fiveMinutes': slotInterval == 5,
  });

  useEffect(() => {
    document.getElementById('weekBody').scrollTop =
      40 * (60 / slotInterval) * 7;
  }, [slotInterval]);

  const renderWeekDays = () => {
    const startDate = moment(mutableDateContext).startOf('week');

    const days = [...Array(7).keys()].map(i => {
      let dayOfMonth = moment(startDate).format('D');
      let weekday = moment(startDate).format('dd');
      let current = startDate.isSame(currentDate, 'day');
      startDate.add(1, 'day');

      return (
        <div key={`weekday-${i}`} className={styles['WeekView-weekday']}>
          {weekday}
          <div className={current ? styles['WeekView-weekday--current'] : ''}>
            {dayOfMonth}
          </div>
        </div>
      );
    });

    return (
      <div className={styles['WeekView-weekdays']}>
        <div className={styles['WeekView-weekdayBuffer']} />
        {days}
      </div>
    );
  };

  const renderHours = () => (
    <div className={styles['WeekView-hours']}>
      {HOURS.map((hour, index) => (
        <div key={`${hour}-${index}`} className={hourClasses}>
          <span>{hour}</span>
          <div className={styles['WeekView-hourBuffer']} />
        </div>
      ))}
    </div>
  );

  const renderTimeSlotGroups = () => {
    let startDate = moment(mutableDateContext).startOf('week');
    let baseMoment = moment();
    let days = [];

    // We need to loop until the end of the week.
    for (let i = 0; i < 7; i++) {
      let current = currentDate.isSame(startDate, 'day');
      let dayClone = moment(startDate).clone();
      const dateKey = startDate.clone().format(DATE_KEY_FORMAT);
      const events = allEvents[dateKey] || DEFAULT_EVENTS;
      const eventsForDay = {
        ...events,
        all_day: [
          ...get(allEvents, [dateKey, 'all_day'], []),
          ...get(multiWeekEvents, [dateKey], []),
        ],
      };
      const availabilities = allAvailabilities[dateKey] || DEFAULT_EVENTS;

      const availabilitiesForDay = {
        ...availabilities,
        all_day: [
          ...get(allAvailabilities, [dateKey, 'all_day'], []),
          ...get(multiWeekAvailabilities, [dateKey], []),
        ],
      };

      // When we use startOf and endOf, the hour gets set to midnight
      // so we need to set the hour and minute again.
      dayClone.set({
        hour: baseMoment.get('hour'),
        minute: baseMoment.get('minute'),
      });

      days.push(
        <TimeSlotGroup
          day={dayClone}
          current={current}
          key={uniqueId('timeSlotGroup_')}
          events={eventsForDay}
          availabilities={availabilitiesForDay}
        />
      );
      startDate = moment(startDate).add(1, 'day');
    }

    return <div className={styles['WeekView-row']}>{days}</div>;
  };

  return (
    <div className={styles['WeekView']}>
      {renderWeekDays()}
      <div className={styles['WeekView-daysContainer']} id="weekBody">
        {renderHours()}
        {renderTimeSlotGroups()}
      </div>
    </div>
  );
};

export default WeekView;
