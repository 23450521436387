import React from 'react';
import axios from 'axios';
import { Modal, Button } from '~ui';
import { routes } from '~constants/routes';
import styles from './EditAndSend.module.scss';
import moment from 'moment';

const ConfirmationModal = props => {
  const { handleHide, toDo, journeyStep, calendarEvent } = props;

  const updateToDoAndClose = async () => {
    await axios.put(routes.TODOS.COMPLETE(toDo.id));
    handleHide();
  };

  const remindLater = async () => {
    const today = moment().startOf('day');
    const calStart = moment(calendarEvent.start_time).startOf('day');

    const diffDays = calStart.diff(today, 'days');

    let newOffset;
    let newTriggerId;

    // If session is today
    if (diffDays == 0) {
      // Update the journey step to after session
      newTriggerId = 4;
      // Update the offset to be tomorrow
      newOffset = 1;

      // If session is BEFORE today
    } else if (diffDays < 0) {
      // Update the journey step to after session
      newTriggerId = 4;
      //Update the offset to be the difference in days + 1 day (tomorrow)
      newOffset = Math.abs(diffDays) + 1;

      // If session is AFTER today
    } else if (diffDays > 0) {
      // Update the journey step to be before session
      newTriggerId = 3;
      // Update the offset to be difference - 1 day (tomorrow)
      newOffset = Math.abs(diffDays) - 1;
    }

    await axios.put(routes.JOURNEY_STEPS.UPDATE(journeyStep.id), {
      journey_step: {
        time_offset: newOffset,
        journey_trigger_id: newTriggerId,
        status: 'incomplete',
        completed_at: null,
      },
    });
    handleHide();
  };

  return (
    <Modal title="Remind You Later?" handleHide={handleHide}>
      <p>
        Iris can remind you to edit and send this email again the next time you
        login. Would you like Iris to remind you tomorrow?
      </p>
      <div className={styles['EditAndSendEmailModal-buttons']}>
        <Button text="No" white onClick={updateToDoAndClose} />
        <Button text="Yes" onClick={remindLater} />
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
