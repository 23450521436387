import React, { useState } from 'react';
import { TextArea, TextInput, Button, Checkbox } from '~ui';
import styles from './Form.module.scss';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { routes } from '~constants/routes';
import axios from 'axios';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { MOBILE_WIDTH } from '~constants/viewports';
import { useWindowSize } from '~hooks';

const Form = props => {
  const {
    sitekey,
    submitColor,
    chosenSessionTypeIds,
    chosenSessionTypeNames,
    studioId,
    setIsSubmitted,
    customFields,
    showEmailMarketing,
    redirectURL,
  } = props;
  const windowWidth = useWindowSize()[0];
  const isMobileWidth = windowWidth < MOBILE_WIDTH;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [emailMarketing, setEmailMarketing] = useState(false);
  const [referredBy, setReferredBy] = useState('');
  const [comments, setComments] = useState('');
  const [customFieldsData, setCustomFieldsData] = useState(
    customFields.map(field => ({ ...field, value: '' }))
  );
  const [disableButton, setDisableButton] = useState(false);
  const [botField, setBotField] = useState('');

  const { executeRecaptcha } = useGoogleReCaptcha();

  const buttonDisabled = () => {
    const customFieldsHasValue =
      customFieldsData.length > 0
        ? customFieldsData
            .filter(customField => customField.required)
            .every(f => !!f.value)
        : true;

    const originalFieldsHasValue = [firstName, lastName, email, phone].every(
      val => !!val
    );

    return disableButton || !customFieldsHasValue || !originalFieldsHasValue;
  };

  const handleSubmit = async () => {
    if (botField != '') {
      return;
    }

    if (!executeRecaptcha) {
      return;
    }
    const recaptchaHumanKey = await executeRecaptcha('submit');
    const customFieldsProperties = {};
    customFieldsData.forEach(f => {
      customFieldsProperties[f.field_name] = f.value;
    });

    try {
      await axios.post(routes.LEADS.CREATE, {
        isMobile: isMobileWidth,
        client: {
          first_name: firstName,
          last_name: lastName,
          referred_by: referredBy,
          email_marketing: emailMarketing,
          interested_session_type_ids: chosenSessionTypeIds.map(id =>
            id.toString()
          ),
          studio_id: studioId,
          recaptcha_human_key: recaptchaHumanKey,
          interested: chosenSessionTypeNames.join(' '),
          source: 'landing_page',
          custom_field_data: customFieldsProperties,
          email,
          phone,
          comments,
        },
      });

      if (redirectURL) {
        window.location.replace(redirectURL);
      } else {
        setIsSubmitted(true);
      }
    } catch (error) {
      const errors = error.response?.data?.errors?.base;

      if (errors && errors.includes('Failed to process recaptcha.')) {
        toast.error(
          'Error verifying Recaptcha. Please try again later.',
          toastOptions
        );
      } else if (errors) {
        errors.forEach(err => {
          toast.error(err, toastOptions);
        });
      } else {
        toast.error('Error creating lead', toastOptions);
      }
    }
  };

  const renderCustomFields = () => {
    if (customFieldsData.length === 1) {
      return (
        <div className={styles['Form-oneColumn']}>
          <TextInput
            required={customFieldsData[0].required}
            labelText={customFieldsData[0].field_name}
            onChangeCallback={val => {
              const customFieldsCopy = customFieldsData;

              customFieldsCopy[0].value = val;
              setCustomFieldsData([...customFieldsCopy]);
            }}
            name={customFieldsData[0].field_name}
          />
        </div>
      );
    } else if (customFieldsData.length === 2) {
      return (
        <div className={styles['Form-oneColumn']}>
          <TextInput
            required={customFieldsData[0].required}
            labelText={<div>{customFieldsData[0].field_name}</div>}
            onChangeCallback={val => {
              const customFieldsCopy = customFieldsData;

              customFieldsCopy[0].value = val;
              setCustomFieldsData([...customFieldsCopy]);
            }}
            name={customFieldsData[0].field_name}
          />
          <TextInput
            required={customFieldsData[1].required}
            labelText={customFieldsData[1].field_name}
            onChangeCallback={val => {
              const customFieldsCopy = customFieldsData;

              customFieldsCopy[1].value = val;
              setCustomFieldsData([...customFieldsCopy]);
            }}
            name={customFieldsData[1].field_name}
          />
        </div>
      );
    }
  };

  return (
    <div className={styles.Form}>
      {isMobileWidth && (
        <div className={styles['Form-name']}>
          <TextInput
            //Recaptcha does not work within instagram so I added this honeypot input as an alternative.
            labelText="Name"
            onChangeCallback={setBotField}
            name="Name"
            disableAutoComplete
          />
        </div>
      )}
      <div className={styles['Form-twoColumn']}>
        <TextInput
          labelText="First Name"
          required
          onChangeCallback={setFirstName}
          name="firstName"
        />
        <TextInput
          labelText="Last Name"
          required
          onChangeCallback={setLastName}
          name="lastName"
        />
      </div>
      <div className={styles['Form-twoColumn']}>
        <TextInput
          labelText="Email"
          required
          onChangeCallback={setEmail}
          name="email"
        />
        <TextInput
          labelText="Phone"
          required
          onChangeCallback={setPhone}
          name="phone"
        />
      </div>
      {renderCustomFields()}
      <div className={styles['Form-oneColumn']}>
        <TextArea
          labelText="Referred by"
          onChangeCallback={setReferredBy}
          name="referred"
        />
      </div>
      <div className={styles['Form-comments']}>
        <TextArea
          labelText="Comments"
          onChangeCallback={setComments}
          name="comments"
        />
      </div>
      {showEmailMarketing && (
        <div className={styles['Form-checkBox']}>
          <Checkbox
            labelText="Sign me up to receive newsletters, updates and offers."
            onChangeCallback={() =>
              setEmailMarketing(emailMarketing ? false : true)
            }
            name="email"
          />
        </div>
      )}
      <div className={styles['Form-buttons']}>
        <Button
          disabled={buttonDisabled()}
          text="Submit"
          onClick={() => {
            setDisableButton(true);
            handleSubmit();
          }}
          style={{
            backgroundColor: submitColor,
            border: `1px solid ${submitColor}`,
          }}
        />
      </div>
    </div>
  );
};

export default Form;
