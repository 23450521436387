import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import moment from 'moment';
import { uniqueId } from '~utils';
import { Button } from '~ui';
import SubscriptionToggle from './SubscriptionToggle';
import SubscriptionCard from './SubscriptionCard';
import Subscribed from './Subscribed';
import CancelSubscriptionModal from './CancelSubscriptionModal';
import NewSubscriptionModal from './NewSubscriptionModal';
import PauseConfirmModal from './PauseConfirmModal';
import styles from './Subscription.module.scss';
import axios from 'axios';
import { routes } from '~constants/routes';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';

const leftColumnDetails = [
  'Free Account Setup',
  'Workflows',
  'Contracts',
  'Invoices and Quotes',
  'Integrated Lead Page',
];

const rightColumnDetails = [
  'All Basic Features',
  'Online Booking',
  'Intelligent Scheduling',
  'Custom Invitations',
];

const emailMarketingDetails = [
  'Access to the Iris Email Marketing Template Library',
  'Send bulk marketing emails to clients and leads',
];
const Subscription = () => {
  const {
    currentSubscription,
    availablePlans,
    currentPlan,
    planDisplayName,
    endOfTrialNotSubscribed,
    studioId,
  } = useSelector(state => state.subscription);
  const showExtend = endOfTrialNotSubscribed;

  const getRemainingDays = () => {
    const current = moment().startOf('day');
    const end = moment(currentSubscription.current_period_end);
    return Math.round(moment.duration(end.diff(current)).asDays());
  };

  const {
    monthlyPlanBasic,
    monthlyPlanPremium,
    annualPlanBasic,
    annualPlanPremium,
    monthlyPlanBasicMarketing,
    monthlyPlanPremiumMarketing,
    annualPlanBasicMarketing,
    annualPlanPremiumMarketing,
  } = availablePlans;

  const [interval, setInterval] = useState('annually');
  const [planBasicMarketing, setPlanBasicMarketing] = useState(true);
  const [planPremiumMarketing, setPlanPremiumMarketing] = useState(true);
  const [basicPlan, setBasicPlan] = useState(annualPlanBasicMarketing);
  const [premiumPlan, setPremiumPlan] = useState(annualPlanPremiumMarketing);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showNewSubModal, setShowNewSubModal] = useState(false);
  const [showPauseConfirmModal, setShowPauseConfirmModal] = useState(false);

  const isSubscribed = !!currentSubscription;

  const cx = classNames.bind(styles);
  const classes = cx('Subscription', {
    'Subscription--notSubscribed': !isSubscribed,
    'Subscription--subscribed': isSubscribed,
  });

  const toggleOnChange = () => {
    setInterval(interval === 'monthly' ? 'annually' : 'monthly');
  };
  const toggleBasicChange = () => {
    setPlanBasicMarketing(planBasicMarketing === true ? false : true);
  };
  const togglePremiumChange = () => {
    setPlanPremiumMarketing(planPremiumMarketing === true ? false : true);
  };

  useEffect(() => {
    if (interval === 'monthly' && planBasicMarketing) {
      setBasicPlan(monthlyPlanBasicMarketing);
    }
    if (interval === 'monthly' && !planBasicMarketing) {
      setBasicPlan(monthlyPlanBasic);
    }
    if (interval !== 'monthly' && planBasicMarketing) {
      setBasicPlan(annualPlanBasicMarketing);
    }
    if (interval !== 'monthly' && !planBasicMarketing) {
      setBasicPlan(annualPlanBasic);
    }
    if (interval === 'monthly' && planPremiumMarketing) {
      setPremiumPlan(monthlyPlanPremiumMarketing);
    }
    if (interval === 'monthly' && !planPremiumMarketing) {
      setPremiumPlan(monthlyPlanPremium);
    }
    if (interval !== 'monthly' && planPremiumMarketing) {
      setPremiumPlan(annualPlanPremiumMarketing);
    }
    if (interval !== 'monthly' && !planPremiumMarketing) {
      setPremiumPlan(annualPlanPremium);
    }
  }, [planBasicMarketing, planPremiumMarketing, interval]);

  const handleExtendTrial = async () => {
    try {
      await axios.put(routes.STUDIO_PROFILE.EXTEND_TRIAL(studioId));
      toast.info('Trial Extended by 7 days', toastOptions);
      window.location.href = '/studio/subscription';
    } catch (error) {
      console.error(error);
      toast.error('Unable to extend your trial, please contact support');
    }
  };
  return (
    <>
      <div className={styles['Subscription--header']}>
        <div className={styles['Subscription--header--title']}>
          <h1>Subscription</h1>
          {showExtend && <a onClick={handleExtendTrial}>Extend my trial</a>}
        </div>
        {currentSubscription &&
          currentSubscription.canceled_at &&
          currentSubscription.status == 'active' && (
            <p>{getRemainingDays()} days remaining in current subscription</p>
          )}
        {isSubscribed && !currentSubscription.canceled_at && (
          <Button
            whiteDanger
            text="Cancel Subscription"
            onClick={() => setShowCancelModal(true)}
          />
        )}
        {currentSubscription && currentSubscription.canceled_at && (
          <div className={styles['Subscription--header--buttonGroup']}>
            <Button
              text="Resubscribe"
              onClick={() => setShowNewSubModal(true)}
            />
          </div>
        )}
      </div>
      {isSubscribed ? (
        <Subscribed
          planDisplayName={planDisplayName}
          planInfo={{
            planPrice: currentPlan.amount,
            planInterval: currentPlan.interval,
          }}
        />
      ) : (
        <div className={classes}>
          <SubscriptionToggle onChangeCallback={toggleOnChange} />
          <div className={styles['Subscription--container']}>
            <SubscriptionCard
              header={'Basic'}
              basic
              interval={interval}
              plan={basicPlan}
              emailMarketing
              onChangeCallback={toggleBasicChange}
            />
            <SubscriptionCard
              header="Booking"
              premium
              interval={interval}
              plan={premiumPlan}
              emailMarketing
              onChangeCallback={togglePremiumChange}
            />
          </div>
          <div className={styles['Subscription--container']}>
            <div className={styles['Subscription--column']}>
              {planBasicMarketing ? (
                emailMarketingDetails.map(detail => (
                  <div key={uniqueId('Detail--')}>
                    <i
                      className="fa fa-check"
                      style={{ color: 'lightgreen' }}
                    />
                    <p>{detail}</p>
                  </div>
                ))
              ) : (
                <div></div>
              )}
              {leftColumnDetails.map(detail => (
                <div key={uniqueId('Detail--')}>
                  <i className="fa fa-check" />
                  <p>{detail}</p>
                </div>
              ))}
            </div>
            <div className={styles['Subscription--column']}>
              {planPremiumMarketing ? (
                emailMarketingDetails.map(detail => (
                  <div key={uniqueId('Detail--')}>
                    <i
                      className="fa fa-check"
                      style={{ color: 'lightgreen' }}
                    />
                    <p>{detail}</p>
                  </div>
                ))
              ) : (
                <div></div>
              )}
              {rightColumnDetails.map(detail => (
                <div key={uniqueId('Detail--')}>
                  <i className="fa fa-check" />
                  <p>{detail}</p>
                </div>
              ))}
            </div>
          </div>
          <div className={styles['Subscription--terms']}>
            <p>
              By signing up, I agree to Iris Works'{' '}
              <a
                target="_blank"
                href="https://iris-works.com/terms-and-conditions/"
              >
                Terms and Conditions
              </a>{' '}
              and{' '}
              <a target="_blank" href="https://iris-works.com/privacy-policy/">
                Privacy Policy
              </a>
            </p>
          </div>
        </div>
      )}
      {showNewSubModal && (
        <NewSubscriptionModal
          plan={annualPlanPremium}
          handleHide={() => setShowNewSubModal(false)}
        />
      )}
      {showCancelModal && (
        <CancelSubscriptionModal
          handleHide={() => setShowCancelModal(false)}
          showPauseConfirmModal={() => setShowPauseConfirmModal(true)}
          currentSubscription={currentSubscription}
        />
      )}
      {showPauseConfirmModal && (
        <PauseConfirmModal
          handleHide={() => setShowPauseConfirmModal(false)}
          currentSubscription={currentSubscription}
        />
      )}
    </>
  );
};

export default Subscription;
