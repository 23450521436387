import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { TextInput, Button } from '~ui';
import { toastOptions } from '~constants/toasts';
import styles from './PaymentOptions.module.scss';
import { toCurrencyAmount } from '~utils';

const SquareACH = ({
  invoiceUUID,
  tipAmount,
  loadingCallback,
  isBooking,
  amountDue,
  payments,
  currency,
}) => {
  const [ach, setAch] = useState(null);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();

  useEffect(() => {
    initAch();
  }, []);

  const initAch = async () => {
    let achResponse = await payments.ach();

    achResponse.addEventListener('ontokenization', async event => {
      const { tokenResult, error } = event.detail;
      if (error) {
        console.error(error);
      } else if (tokenResult && tokenResult.status === 'OK') {
        await handleTokenizationSuccess(tokenResult.token);
      }
    });

    setAch(achResponse);
  };

  const handleTokenizationSuccess = async token => {
    loadingCallback({ loading: true });

    try {
      await axios.post(`/customer/invoices/${invoiceUUID}/charge_square`, {
        nonce: token,
        tip_amount: tipAmount,
        is_ach: true,
      });

      toast.success(
        'Successfully created your payment, please allow 3 to 5 days for processing!',
        toastOptions
      );

      setTimeout(() => {
        loadingCallback({
          loading: false,
          reload: !isBooking,
          success: true,
        });
      }, 3000);
    } catch (error) {
      loadingCallback({ loading: false, reload: false });
      error.response.data.errors.forEach(err => toast.error(err, toastOptions));
    }
  };

  const createAch = () => {
    if (ach) {
      ach
        .tokenize({
          accountHolderName: `${firstName} ${lastName}`,
          intent: 'CHARGE',
          total: {
            amount: Math.round(Number(amountDue) * 100),
            currencyCode: currency,
          },
        })
        .catch(error => {
          console.error('Tokenization error:', error);
        });
    }
  };

  return (
    <>
      <div className={styles['SquareACH-inputContainer']}>
        <TextInput
          labelText="Account First Name"
          name="firstName"
          onChangeCallback={setFirstName}
        />
        <TextInput
          labelText="Account Last Name"
          name="lastName"
          onChangeCallback={setLastName}
        />
      </div>
      <div className={styles['SquareACH-button']}>
        <Button
          text={`Pay ${toCurrencyAmount(amountDue)} by Bank (Square ACH)`}
          onClick={() => createAch()}
          disabled={!firstName || !lastName}
        />
      </div>
    </>
  );
};

export default SquareACH;
