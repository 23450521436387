import React, { useState } from 'react';
import { CurrencyInput, PercentageInput } from '~ui';
import styles from './AddPaymentScheduleModal.module.scss';
import classNames from 'classnames/bind';

const RetainerAndBalanceFields = ({ retainerAmount, setRetainerAmount }) => {
  const [selected, setSelected] = useState('currency');

  const handleChange = val => {
    setRetainerAmount({ type: selected, value: +val });
  };

  const cx = classNames.bind(styles);
  return (
    <>
      <label>Retainer Amount</label>
      <div className={styles.Retainer}>
        <div className={styles['Retainer-toggles']}>
          <button
            onClick={() => setSelected('currency')}
            className={cx('Retainer-currency', {
              'Retainer-currency--selected': selected === 'currency',
            })}
          >
            {window.app.invoices.currency}
          </button>
          <button
            onClick={() => setSelected('percent')}
            className={cx('Retainer-percent', {
              'Retainer-percent--selected': selected === 'percent',
            })}
          >
            %
          </button>
        </div>

        {selected === 'currency' ? (
          <CurrencyInput
            name="retainer_amount"
            initialValue={retainerAmount}
            onChangeCallback={handleChange}
            max={50000}
            hideIcon
          />
        ) : null}

        {selected === 'percent' ? (
          <PercentageInput
            name="retainer_percent"
            onChangeCallback={handleChange}
            hideIcon
          />
        ) : null}
      </div>
    </>
  );
};

export default RetainerAndBalanceFields;
