export const tinymceHeight = '300px';

export const vendorButtons = [
  'alignleft',
  'aligncenter',
  'alignright',
  'outdent',
  'indent',
  '|',
  'formatselect',
  'fontsizeselect',
  '|',
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'fontselect',
  '|',
  'forecolor backcolor',
  '|',
  'link',
  'unlink',
  'image imagetools',
  '|',
  'bullist',
  'numlist',
  '|',
  'undo',
  'redo',
  '|',
  'wordcount',
  '|',
  'print',
  'preview',
  'searchreplace',
  '|',
  'visualblocks',
  'visualchars',
  '|',
  'fullscreen',
  'pagebreak',
  'nonbreaking',
  'insertdatetime',
  '|',
  'help',
  '|',
];

export const customButtons = [
  'variablesReact',
  'bookingUrlsReact',
  'locationsReact',
];

export const vendorPlugins = [
  'print',
  'preview',
  'searchreplace',
  'visualblocks',
  'visualchars',
  'fullscreen',
  'image',
  'link',
  'help',
  'pagebreak',
  'nonbreaking',
  'insertdatetime',
  'image imagetools',
  'paste',
  'lists',
  'advlist',
  'wordcount',
];

export const variables = [
  ['{{studio_name}}', "Your photography studio's name"],
  ['{{event_invitation_url}}', 'Booking url for client'],
  ['{{client_name}}', "Client's full name"],
  ['{{client_first_name}}', "Client's first name"],
  ['{{client_last_name}}', "Client's last name"],
  ['{{client_address}}', "Client's street address"],
  ['{{client_city}}', "Client's city"],
  ['{{client_state}}', "Client's state"],
  ['{{client_zip_code}}', "Client's zip code"],
  ['{{client_email}}', "Client's email address"],
  ['{{client_phone}}', "Client's phone number"],
  ['{{client_company_name}}', "Client's company name"],
  ['{{spouse_name}}', "Client's spouse name"],
  ['{{child_name_1}}', "Client's first child's name"],
  ['{{child_name_2}}', "Client's second child's name"],
  ['{{child_name_3}}', "Client's third child's name"],
  ['{{child_name_4}}', "Client's fourth child's name"],
  ['{{child_name_5}}', "Client's fifth child's name"],
  ['{{child_name_6}}', "Client's sixth child's name"],
  ['{{current_date}}', "Today's date - MM DD, YY format"],
  ['{{current_date_intl}}', "Today's date - DD MM YY format"],
  ['{{event_name}}', 'Session event name'],
  ['{{pic_time_gallery_url}}', 'Pic-Time Gallery Link'],
  ['{{shoot_proof_gallery_url}}', 'ShootProof Gallery Link'],
  [
    '{{event_location}}',
    'Session location (e.g. 123 Sunshine Lane, Carmel, IN)',
  ],
  ['{{event_date}}', 'Session descriptive date (e.g. "Monday, June 19th")'],
  ['{{event_date_intl}}', 'Session descriptive date (e.g. "Monday, 19 June")'],
  [
    '{{event_date_full}}',
    'Session calendar date - MMDDYY format (e.g. "6/19/2019")',
  ],
  [
    '{{event_date_full_intl}}',
    'Session calendar date - DDMMYY format (e.g. "19/6/2019")',
  ],
  ['{{event_time}}', 'Session starting time (e.g. "3:55 PM" OR "All Day")'],
  ['{{event_end_time}}', 'Session ending time (e.g. "5:00 PM")'],
];

export const blockFormats = [
  'Paragraph=p',
  'Heading 1=h1',
  'Heading 2=h2',
  'Heading 3=h3',
  'Heading 4=h4',
];

export const validElements = [
  '@[style]',
  'h1[style]',
  'span[style]',
  'h2[style]',
  'h3[style]',
  'h4[style]',
  'p[style]',
  'strong[style]',
  'em[style]',
  'ul[style]',
  'ol[style]',
  'li[style]',
  'dl[style]',
  'dt[style]',
  'dd[style]',
  'br[style]',
  'a[href|title|alt|target|style]',
  'img[class|src|border=0|alt|title|hspace|vspace|width|height|align|name]',
];

export const contentStyle = `img {
  max-width: 100%;
  height: auto;
}`;

/*
We have to add an event listener to the document and then give the tinymce 
modal focus by stopping propagation outside of the modal. Read https://www.tiny.cloud/docs/integrations/bootstrap/
for more info
*/
$(document).on('focusin', function (e) {
  if (
    $(e.target).closest(
      '.tox-tinymce-aux, .moxman-window, .tam-assetmanager-root'
    ).length
  ) {
    e.stopImmediatePropagation();
  }
});
