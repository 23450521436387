import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { updateInvoice } from '~actions/invoice';
import { get } from 'utils';
import { DATE_KEY_FORMAT, FULL_DATE_TIME_FORMAT } from '~constants/datetime';
import {
  Modal,
  Button,
  Dropdown,
  Checkbox,
  DateTimePicker,
  TextArea,
  TextInput,
} from '~ui';
import { actionsLoadingSelector } from '~selectors';
import { absolutePath, routes } from '~constants/routes';
import styles from '../Invoice.module.scss';
import moment from 'moment';

const InvoiceModal = ({ handleHide }) => {
  const invoice = useSelector(state => state.invoice);
  const clientsOptions = useSelector(state => state.invoice.clients);
  const templateOptions = useSelector(state => state.invoice.templates);
  const [isQuote, setIsQuote] = useState(invoice.is_quote);
  const [templateId, setTemplateId] = useState(invoice.invoice_template_id);
  const [clientId, setClientId] = useState(invoice.client_id);
  const [issuedAt, setIssuedAt] = useState(moment(invoice.issued_at));
  const [expiresAt, setExpiresAt] = useState(moment(invoice.expires_at));
  const [createLoading, setCreateLoading] = useState(false);
  const [firstPaymentDue, setFirstPaymentDue] = useState('SelectDate');
  const [scheduleOffset, setScheduleOffset] = useState(1);
  const [scheduleInterval, setScheduleInterval] = useState(1);
  const [installmentsCount, setInstallmentsCount] = useState(1);

  const [dueDate, setDueDate] = useState(
    moment(invoice.due_date).startOf('day')
  );
  const [memo, setMemo] = useState(invoice.memo);
  const dispatch = useDispatch();
  const paid_in_full = get(invoice, ['paid_in_full'], false);
  const canEditInvoice = !paid_in_full;

  // Reset the state if the client ID changes
  useEffect(() => {
    if (clientId != invoice.client_id) {
      setTemplateId(null);
      setFirstPaymentDue('SelectDate');
      setSelectedCalendarEventId(null);
    }
  }, [clientId]);

  useEffect(() => {
    getInvoiceInstallments();
  }, []);

  const getInvoiceInstallments = async () => {
    try {
      if (!invoice.id) {
        setInstallmentsCount(0);
        return;
      }
      const res = await axios.get(routes.INVOICES.GET_INSTALLMENTS(invoice.id));
      const installments = res.data.data;
      setInstallmentsCount(installments.length);
    } catch (error) {
      console.error(error);
    }
  };

  const update = async params =>
    await dispatch(updateInvoice(invoice.id, params));
  const isLoading = useSelector(state =>
    actionsLoadingSelector(state, ['INVOICES_UPDATE'])
  );

  const calculateDueDate = () => {
    if (
      showPaymentDueContainer ||
      (installmentsCount == 1 && !(firstPaymentDue === 'SelectDate'))
    ) {
      if (firstPaymentDue === 'SelectDate') {
        return new Date(dueDate.format(DATE_KEY_FORMAT));
      } else if (firstPaymentDue === 'OnSessionDate') {
        const foundOption = calendarEventOptions.find(
          calendarEventOption =>
            calendarEventOption.value == selectedCalendarEventId
        );

        return new Date(moment(foundOption.startTime).format(DATE_KEY_FORMAT));
      } else if (firstPaymentDue === 'BeforeSession') {
        const foundOption = calendarEventOptions.find(
          calendarEventOption =>
            calendarEventOption.value == selectedCalendarEventId
        );

        const startDate = moment(foundOption.startTime);
        const due = startDate.subtract(
          scheduleInterval * scheduleOffset,
          'days'
        );
        return new Date(due.format(DATE_KEY_FORMAT));
      } else if (firstPaymentDue === 'AfterSession') {
        const foundOption = calendarEventOptions.find(
          calendarEventOption =>
            calendarEventOption.value == selectedCalendarEventId
        );

        const startDate = moment(foundOption.startTime);
        const due = startDate.add(scheduleInterval * scheduleOffset, 'days');
        return new Date(due.format(DATE_KEY_FORMAT));
      }
    }

    if (
      showDueDateContainer ||
      (templateId && invoice.id && installmentsCount == 1) ||
      invoice.id
    ) {
      return new Date(dueDate.format(DATE_KEY_FORMAT));
    }
    // IF invoice_template is selected, return null here.  The invoice template will be handling the due date on the backend
    return null;
  };

  const handleSubmit = e => {
    e.preventDefault();

    const sendAdditionalParams =
      !templateId && firstPaymentDue !== 'SelectDate';

    const params = {
      invoice: {
        is_quote: isQuote,
        invoice_template_id: templateId ? templateId : null,
        client_id: clientId,
        issued_at: issuedAt,
        expires_at: expiresAt,
        calendar_event_id: selectedCalendarEventId
          ? selectedCalendarEventId
          : null,
        due_date: calculateDueDate(),
        memo: memo,
        ...(!invoice.id && {
          interval: sendAdditionalParams ? 'day' : null,
          interval_amount: sendAdditionalParams
            ? scheduleInterval * scheduleOffset
            : null,
          schedule_trigger: sendAdditionalParams ? firstPaymentDue : null,
        }),
      },
    };

    if (invoice.id) {
      update(params).then(() => handleHide());
    } else {
      setCreateLoading(true);
      axios.post(routes.INVOICES.CREATE, params).then(res => {
        const invoiceId = res.data.data.attributes.id;
        window.location.href = absolutePath(`/invoices/${invoiceId}`);
      });
    }
  };

  const [calendarEventOptions, setCalendarEventOptions] = useState([]);
  const [
    invoiceTemplateRequiresSession,
    setInvoiceTemplateRequiresSession,
  ] = useState();
  const [selectedCalendarEventId, setSelectedCalendarEventId] = useState();

  useEffect(() => {
    // Fetch the template and the list of calendar_events in the future
    if (+templateId) {
      getInvoiceTemplate();
    } else {
      setInvoiceTemplateRequiresSession(false);
    }

    if (+clientId) {
      getCalendarEvents();
    } else {
      setCalendarEventOptions([]);
    }
  }, [clientId, templateId]);

  const getCalendarEvents = async () => {
    try {
      const res = await axios.get(routes.CALENDAR_EVENTS.INDEX, {
        params: {
          client_id: clientId,
          status: 'upcoming',
          sessions_only: true,
        },
      });

      const mappedCalendarEvents = res.data.data.map(calEvent => ({
        label: `${calEvent.attributes.title} - ${moment(
          calEvent.attributes.start_time
        ).format(FULL_DATE_TIME_FORMAT)}`,
        value: calEvent.attributes.id,
        startTime: calEvent.attributes.start_time,
      }));

      if (mappedCalendarEvents.length > 0) {
        mappedCalendarEvents.unshift({
          label: 'Select a Session',
          value: null,
          startTime: null,
        });
        setCalendarEventOptions(mappedCalendarEvents);

        if (invoice && invoice.calendar_event_id) {
          setSelectedCalendarEventId(invoice.calendar_event_id);
        }
      } else if (invoice.id && invoice.calendar_event_id) {
        setCalendarEventOptions([]);
        setSelectedCalendarEventId(invoice.calendar_event_id);
      } else {
        setCalendarEventOptions([]);
        setSelectedCalendarEventId(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getInvoiceTemplate = async () => {
    try {
      const res = await axios.get(routes.INVOICE_TEMPLATES.SHOW(templateId));

      setInvoiceTemplateRequiresSession(
        res.data.schedule_trigger === 'BeforeSession' ||
          res.data.schedule_trigger === 'AfterSession' ||
          res.data.schedule_trigger === 'OnSessionDate' ||
          res.data.base_payments_on === 'based_on_session'
      );
    } catch (error) {
      console.error(error);
    }
  };

  const showDueDateContainer =
    !templateId &&
    !!clientId &&
    !invoiceTemplateRequiresSession &&
    firstPaymentDue === 'SelectDate';

  const showPaymentDueContainer =
    !templateId &&
    !!clientId &&
    !invoiceTemplateRequiresSession &&
    !!selectedCalendarEventId;

  const getDisabled = () => {
    if (!clientId) {
      return true;
    }

    if (invoiceTemplateRequiresSession && !selectedCalendarEventId) {
      return true;
    }
  };

  return (
    <Modal
      title={invoice.id ? 'Update Invoice' : 'New Invoice'}
      handleHide={handleHide}
    >
      <form className={styles.InvoiceModalForm} onSubmit={handleSubmit}>
        <div className={styles.InvoiceFormTopRow}>
          {isQuote ? (
            <DateTimePicker
              name="expires_at"
              initialDateTime={expiresAt}
              onChangeCallback={setExpiresAt}
              labelText="Expiration Date"
              disableTime
              required
            />
          ) : (
            <DateTimePicker
              name="issued_at"
              initialDateTime={issuedAt}
              onChangeCallback={setIssuedAt}
              labelText="Issue Date"
              disableTime
              required
            />
          )}
          <Checkbox
            name="is_quote"
            checked={isQuote}
            onChangeCallback={isQuote => {
              setExpiresAt(isQuote ? moment() : null);
              setIsQuote(isQuote);
            }}
            labelText="Quote?"
            disabled={!canEditInvoice}
          />
        </div>
        <div className={styles.InvoiceFormColumns}>
          <Dropdown
            name="client_id"
            initialValue={String(clientId)}
            options={clientsOptions}
            onChangeCallback={val => setClientId(+val)}
            disabled={!canEditInvoice}
            labelText="Client"
            required
          />

          {!!clientId && !invoice.id ? (
            <Dropdown
              name="invoice_template_id"
              value={templateId}
              onChangeCallback={val => {
                if (val == 0) {
                  setTemplateId(null);
                } else {
                  setTemplateId(+val);
                }
              }}
              disabled={!canEditInvoice}
              options={templateOptions}
              labelText="Invoice Template"
            />
          ) : null}
        </div>

        <div>
          {!!clientId && (
            <>
              {calendarEventOptions.length > 0 && (
                <Dropdown
                  labelText={
                    <div className={styles.InvoiceModalSession}>
                      <label>Session</label>
                      {invoiceTemplateRequiresSession ? (
                        <p>The selected invoice template requires a session</p>
                      ) : null}
                    </div>
                  }
                  name="calendar_event"
                  initialValue={selectedCalendarEventId}
                  options={calendarEventOptions}
                  onChangeCallback={val => {
                    if (val !== 'Select a Session') {
                      setSelectedCalendarEventId(+val);
                    } else {
                      setSelectedCalendarEventId(null);
                    }
                  }}
                />
              )}
            </>
          )}
        </div>

        {calendarEventOptions.length == 0 &&
          invoiceTemplateRequiresSession &&
          clientId && (
            <div>
              <h2>
                This client has no upcoming sessions, please choose a different
                client or a different invoice template.
              </h2>
            </div>
          )}

        <>
          <div className={styles.InvoiceFormColumns}>
            <>
              {(invoice.id
                ? installmentsCount == 1 && !!selectedCalendarEventId
                : showPaymentDueContainer) && (
                <Dropdown
                  labelText="Initial Payment Due"
                  name="first_payment_due"
                  initialValue={firstPaymentDue}
                  options={[
                    {
                      label: 'Select Calendar Date',
                      value: 'SelectDate',
                    },

                    { label: 'On Session Date', value: 'OnSessionDate' },
                    { label: 'Before Session', value: 'BeforeSession' },
                    { label: 'After Session', value: 'AfterSession' },
                  ]}
                  onChangeCallback={setFirstPaymentDue}
                />
              )}
              {(
                invoice.id
                  ? installmentsCount == 1 && firstPaymentDue === 'SelectDate'
                  : showDueDateContainer
              ) ? (
                <DateTimePicker
                  name="due_date"
                  initialDateTime={dueDate}
                  onChangeCallback={setDueDate}
                  labelText="Due Dates"
                  disabled={!canEditInvoice}
                  disableTime
                  required
                />
              ) : null}

              {(invoice.id
                ? installmentsCount == 1
                : showPaymentDueContainer) && (
                <>
                  {firstPaymentDue === 'SelectDate' ? null : (
                    <>
                      {firstPaymentDue === 'BeforeSession' ||
                      firstPaymentDue === 'AfterSession' ? (
                        <div className={styles.InvoiceModalSchedule}>
                          <div className={styles['InvoiceModalSchedule-by']}>
                            by
                          </div>
                          <TextInput
                            name="schedule_offset"
                            type="number"
                            onFocusSelectText
                            characterLimit={2}
                            initialValue={1}
                            onChangeCallback={value => {
                              setScheduleOffset(+value);
                            }}
                          />
                          <Dropdown
                            name="schedule_interval"
                            initialValue={1}
                            onChangeCallback={value => {
                              setScheduleInterval(+value);
                            }}
                            options={[
                              { label: 'Day(s)', value: 1 },
                              { label: 'Week(s)', value: 7 },
                              { label: 'Month(s)', value: 30 },
                              { label: 'Year(s)', value: 365 },
                            ]}
                          />
                        </div>
                      ) : null}
                    </>
                  )}
                </>
              )}
            </>
          </div>
        </>

        <TextArea
          name="memo"
          initialValue={memo}
          onChangeCallback={setMemo}
          placeholder="This memo is visible to your clients."
          labelText="Memo"
        />
        <div className={styles.InvoiceFormButtons}>
          <Button text="Cancel" onClick={handleHide} danger />
          <Button
            text={invoice.id ? 'Update Invoice' : 'Create Invoice'}
            disabled={getDisabled()}
            loading={isLoading || createLoading}
            submit
          />
        </div>
      </form>
    </Modal>
  );
};

InvoiceModal.propTypes = {
  handleHide: PropTypes.func.isRequired,
};

export default InvoiceModal;
