import React from 'react';
import moment from 'moment';
import { FULL_DATE_FORMAT } from '~constants/datetime';
import { toCurrencyAmount } from '~utils';
import LineItemsTable from '../CustomerInvoice/LineItemsTable';
import InvoiceTotals from '../CustomerInvoice/InvoiceTotals';
import InstallmentTemplatesTable from './InstallmentTemplatesTable';
import styles from '../CustomerInvoice/CustomerInvoice.module.scss';

const CustomerInvoiceTemplate = props => {
  const { invoiceTemplate, studio, user, imageUrl, meta } = props;
  const { due_date, memo, totals, items } = invoiceTemplate;
  const { payment_schedule_template } = invoiceTemplate;

  return (
    <div className={styles.CustomerInvoice}>
      <div className={styles['CustomerInvoice-header']}>
        <img src={imageUrl} alt={`${studio.name}'s Logo`} />
        <p>
          {studio.name} • {user.phone} • {user.email}
        </p>
        <br />
        <h1>Sample Invoice</h1>
        <div className={styles['CustomerInvoice-meta']}>
          <div className={styles['CustomerInvoice-metaLine']}>
            <span>Client Name</span>
            Sample Client
          </div>
          <div className={styles['CustomerInvoice-metaLine']}>
            <span>Due Date</span>
            {moment(due_date).format(FULL_DATE_FORMAT)}
          </div>
        </div>
        <br />
      </div>
      <LineItemsTable lineItems={items} />
      <InvoiceTotals totals={totals} invoice={invoiceTemplate} meta={meta} />
      <br />
      <br />
      {Number(totals.due) > 0 && (
        <p className={styles['CustomerInvoice-nextInstallment']}>
          Amount Due on {moment(due_date).format(FULL_DATE_FORMAT)}
          <span>{toCurrencyAmount(totals.due)}</span>
        </p>
      )}
      {payment_schedule_template && (
        <InstallmentTemplatesTable
          installments={payment_schedule_template.installments}
        />
      )}
      <br />
      {memo && (
        <>
          <h1>Memo</h1>
          <p>{memo}</p>
        </>
      )}
    </div>
  );
};

export default CustomerInvoiceTemplate;
