import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { intervalSelector } from '~selectors';
import styles from './Calendar.module.scss';
import CalendarContent from './CalendarContent';
import CalendarEventPane from './CalendarEventPane';

const Calendar = () => {
  const interval = useSelector(intervalSelector);
  const timeZone = useSelector(state => state.calendar.meta.timeZone);

  useEffect(() => {
    moment.tz.setDefault(timeZone);
  }, []);

  return (
    <div className={styles.Calendar}>
      <CalendarContent />
      {interval === 'month' && <CalendarEventPane />}
    </div>
  );
};

export default Calendar;
