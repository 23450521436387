import React, { useState } from 'react';
import { Modal, Button, TextInput } from '~ui';

const JourneyTemplatesCreateModal = ({ handleHide, handleCreate }) => {
  const [name, setName] = useState('');

  return (
    <Modal title="Create a Workflow" handleHide={handleHide}>
      <div className="mb-8">What will your new Workflow be called?</div>
      <TextInput
        name="workflowTextInput"
        placeholder="Workflow Name"
        onChangeCallback={setName}
        autoFocus
      />
      <br />
      <div className="d-fr">
        <Button className="mr-8" text="Cancel" onClick={handleHide} danger />
        <Button text="Save" onClick={() => handleCreate(name)} />
      </div>
    </Modal>
  );
};

export default JourneyTemplatesCreateModal;
