import React, { useState, useEffect } from 'react';
import styles from './EmailMarketingRoot.module.scss';
import EmailMarketingHeader from './EmailMarketingHeader';
import EmailMarketingTable from './EmailMarketingTable';
import MarketingNeedsAddress from './LandingPages/MarketingNeedsAddress';
import axios from 'axios';
import { routes } from '~constants/routes';
import EmailMarketingContext from './EmailMarketingContext';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';

const EmailMarketingRoot = ({
  studioId,
  marketingMessage,
  marketingSubmitColor,
  sessionTypesOptions,
  address,
  current_plan,
  studioGroups,
  marketingValidated,
  timeZone,
}) => {
  const marketing = current_plan.includes('mktg');

  const [activeTabKey, setActiveTabKey] = useState('campaigns');
  const [templates, setTemplates] = useState([]);
  const [templatesMeta, setTemplatesMeta] = useState({});
  const [campaigns, setCampaigns] = useState([]);
  const [campaignsMeta, setCampaignsMeta] = useState({});
  const [campaignCommunications, setCampaignCommunications] = useState([]);
  const [statistics, setStatistics] = useState({
    totalSends: 0,
    totalOpenRate: 0,
    totalClickRate: 0,
  });
  const [statisticsFetched, setStatisticsFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getTemplates = async (page = 1) => {
    try {
      const res = await axios.get(routes.EMAIL_CAMPAIGN_TEMPLATES.INDEX, {
        params: {
          page,
        },
      });
      setTemplates(res.data.data);
      setTemplatesMeta(res.data.meta);
    } catch (error) {
      console.error(error);
    }
  };

  const getCampaigns = async (page = 1) => {
    setIsLoading(true);
    try {
      const res = await axios.get(routes.EMAIL_CAMPAIGNS.INDEX, {
        params: {
          page,
          limit: 5,
        },
      });
      setCampaigns(res.data.data);
      setCampaignsMeta(res.data.meta);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(
        'Error fetching campaigns. Please contact support.',
        toastOptions
      );
      console.error(error);
      Rollbar.error('Error fetching email marketing campaigns.', error);
    }
  };

  const getCampaignDetails = async id => {
    try {
      const res = await axios.get(routes.EMAIL_CAMPAIGNS.SHOW(id));

      setCampaignCommunications(res.data.data);
    } catch (error) {
      toast.error('Error fetching campaign details.', toastOptions);
      console.error(error);
      Rollbar.error('Error fetching email marketing campaign details.', error);
    }
  };

  const getHeaderStatistics = async () => {
    try {
      const res = await axios.get(routes.EMAIL_CAMPAIGNS.STATISTICS);
      setStatistics({
        ...statistics,
        totalSends: res.data.total_sends,
        totalOpenRate: res.data.total_open_rate,
        totalClickRate: res.data.total_click_rate,
      });
      setStatisticsFetched(true);
    } catch (error) {
      if (!(error.response.status === 403)) {
        toast.error(
          'Error fetching campaign statistics. Please contact support.',
          toastOptions
        );
      }
      console.error(error);
      Rollbar.error('Error fetching email marketing statistics.', error);
    }
  };

  const cancelCampaign = async id => {
    try {
      await axios.post(routes.EMAIL_CAMPAIGNS.CANCEL(id), {
        email_campaign: { id },
      });
      getCampaigns();
      toast.success('Campaign canceled!', toastOptions);
    } catch (error) {
      toast.success('Error canceling campaign.', toastOptions);
      console.error(error);
      Rollbar.error('Error canceling email marketing campaign.', error);
    }
  };

  useEffect(() => {
    getHeaderStatistics();
  }, []);

  return (
    <div className={styles.EmailMarketingRoot}>
      {address && (
        <EmailMarketingContext.Provider
          value={{
            studioId,
            templates,
            getTemplates,
            campaigns,
            campaignCommunications,
            getCampaigns,
            getCampaignDetails,
            cancelCampaign,
            templatesMeta,
            campaignsMeta,
            getHeaderStatistics,
            statistics,
            sessionTypesOptions,
            studioGroups,
            marketingMessage,
            marketingSubmitColor,
            timeZone,
            marketing,
            marketingValidated,
            statisticsFetched,
            isLoading,
          }}
        >
          <EmailMarketingHeader {...{ activeTabKey, setActiveTabKey }} />
          <EmailMarketingTable {...{ activeTabKey, setActiveTabKey }} />
        </EmailMarketingContext.Provider>
      )}
      {!address && <MarketingNeedsAddress />}
    </div>
  );
};

export default EmailMarketingRoot;
