import React, { useEffect, useState } from 'react';
import DocumentsTable from '../../../Documents/DocumentsTable';
import QuestionnairesResponsesTable from '../QuestionnaireResponsesTable';
import ClientContractsTable from '../ClientContractsTable';
import styles from './ClientDocumentsContent.module.scss';
import { absolutePath, routes } from '~constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import {
  getClientDocuments,
  updateClientDocumentsMeta,
} from '~actions/documents';
import { getClientContracts } from '~actions/contracts';
import { getQuestionnaireResponses } from '~actions/questionnaires';
import axios from 'axios';

const menuFilters = ['questionnaires', 'contracts', 'documents'];

const ClientDocumentsContent = () => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);
  const [filterType, setFilterType] = useState('questionnaires');

  const clientId = useSelector(state => state.documents.clientId);

  useEffect(() => {
    dispatch(getClientDocuments(clientId));
    dispatch(getClientContracts(clientId));
    dispatch(getQuestionnaireResponses(clientId));
  }, []);

  const handleMenuFilter = (type, index) => {
    setActiveTab(index);
    setFilterType(type);
  };

  const handleDocumentDelete = async idForDelete => {
    try {
      await axios.delete(
        absolutePath(`/${routes.DOCUMENTS.DELETE(idForDelete)}`)
      );

      dispatch(getClientDocuments(clientId));
    } catch (error) {
      console.log(error);
    }
  };

  const renderTableFromFilter = () => {
    switch (filterType) {
      case 'documents':
        return (
          <DocumentsTable
            handleDocumentDelete={handleDocumentDelete}
            selectorKey="clientDocuments"
            updateMetaData={updateClientDocumentsMeta}
            getData={getClientDocuments}
          />
        );
      case 'questionnaires':
        return <QuestionnairesResponsesTable clientId={clientId} />;
      case 'contracts':
        return <ClientContractsTable clientId={clientId} />;
      default:
        break;
    }
  };

  return (
    <div className={styles.ClientDocumentsContent}>
      <div className={styles['ClientDocumentsContent-headerButtons']}>
        <ul>
          {menuFilters.map((menuItem, index) => (
            <li key={menuItem}>
              <button
                onClick={() => handleMenuFilter(menuItem, index)}
                className={
                  index === activeTab ? styles['activeButton'] : 'inactive'
                }
              >
                {menuItem}
              </button>
            </li>
          ))}
        </ul>
      </div>
      {renderTableFromFilter()}
    </div>
  );
};

export default ClientDocumentsContent;
