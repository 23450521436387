import React, { useState, useEffect } from 'react';
import styles from './DocumentPreviewModal.module.scss';
import { Modal } from '~ui';
import axios from 'axios';
import { routes } from '~constants/routes';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import FileViewer from 'react-file-viewer';

const DocumentPreviewModal = ({ handleHide, previewId }) => {
  const [document, setDocument] = useState();

  useEffect(() => {
    getDocument();
  }, []);

  const getDocument = async () => {
    try {
      const res = await axios.get(routes.DOCUMENTS.GET(previewId));
      setDocument(res.data.data.attributes);
    } catch (error) {
      console.error(error);
      toast.error('Unable to load document', toastOptions);
    }
  };

  return (
    <Modal title="Preview" handleHide={handleHide}>
      <div className={styles.DocumentPreviewModal}>
        {document && (
          <FileViewer
            fileType={document.file_content_type.split('/')[1]}
            filePath={document.download_url}
            onError={e => {
              console.log(e);
            }}
          />
        )}
      </div>
    </Modal>
  );
};

export default DocumentPreviewModal;
