import React from 'react';
import PropTypes from 'prop-types';
import styles from './BookingCalendarHeader.module.scss';
import { MONTH_YEAR_FORMAT } from '~constants/datetime';

const BookingCalendarHeader = props => {
  const { dateContext, nextInterval, prevInterval } = props;
  return (
    <>
      <div className={styles['BookingCalendarHeader']}>
        <div className={styles['BookingCalendarHeader-intervalArrow']}>
          <i className="fa fa-chevron-left" onClick={prevInterval} />
        </div>
        <div className={styles['BookingCalendarHeader-dateHeader']}>
          <span>{dateContext.format(MONTH_YEAR_FORMAT)}</span>
        </div>
        <div className={styles['BookingCalendarHeader-intervalArrow']}>
          <i className="fa fa-chevron-right" onClick={nextInterval} />
        </div>
      </div>
    </>
  );
};

BookingCalendarHeader.propTypes = {
  dateContext: PropTypes.object.isRequired,
  nextInterval: PropTypes.func.isRequired,
  prevInterval: PropTypes.func.isRequired,
};

export default BookingCalendarHeader;
