import axios from 'axios';
import moment from 'moment';
import { routes } from '~constants/routes';
import {
  CUSTOMER_BOOKING_AVAILABILITY,
  CUSTOMER_BOOKING_CLIENT,
  CUSTOMER_BOOKING_CONTRACT,
  CUSTOMER_BOOKING_INVOICE,
  CUSTOMER_BOOKING_FOOTER,
  CUSTOMER_BOOKING,
} from '~constants/actionTypes';

export const selectAvailability = availability => async (
  dispatch,
  getState
) => {
  try {
    const { token } = getState().customerBooking;

    dispatch({
      type: CUSTOMER_BOOKING_AVAILABILITY.SELECT_AVAILABILITY.REQUEST,
    });

    const params = {
      booking_availability: {
        booking_calendar_id: availability.booking_calendar_id,
        status: 'pending',
        status_updated_at: moment(),
      },
    };

    const res = await axios.put(
      routes.BOOKING_AVAILABILITIES.SELECT_AVAILABILITY(availability.id),
      params,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({
      type: CUSTOMER_BOOKING_AVAILABILITY.SELECT_AVAILABILITY.SUCCESS,
      payload: res.data.data.attributes,
    });
  } catch (error) {
    dispatch({
      type: CUSTOMER_BOOKING_AVAILABILITY.SELECT_AVAILABILITY.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });

    Rollbar.error(
      `Select Availability Frontend Error with error messages ${error.response.data.error.messages} and availability ${availability}`
    );
  }
};

export const addClient = params => async (dispatch, getState) => {
  try {
    const { token } = getState().customerBooking;

    dispatch({ type: CUSTOMER_BOOKING_CLIENT.CREATE.REQUEST });
    const { data } = await axios.post(
      routes.CLIENTS.CREATE,
      {
        client: params,
        from_booking: true,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({ type: CUSTOMER_BOOKING_CLIENT.CREATE.SUCCESS, payload: data });
    return { clientId: data.id, isErrored: false, errorMessage: null };
  } catch (error) {
    let errorMessage = 'An unknown error occurred';

    if (error.response && error.response.data) {
      if (typeof error.response.data.message === 'string') {
        errorMessage = error.response.data.message;
      } else if (
        Array.isArray(error.response.data) &&
        error.response.data.length > 0
      ) {
        errorMessage = error.response.data[0][0];
      }
    }

    dispatch({
      type: CUSTOMER_BOOKING_CLIENT.CREATE.FAILURE,
      payload: error.response.data,
      error: true,
    });

    Rollbar.error(
      `Create Client Frontend Error with error response ${errorMessage}`
    );

    return {
      clientId: null,
      isErrored: true,
      errorMessage: errorMessage,
    };
  }
};

export const generateContract = () => async (dispatch, getState) => {
  try {
    const {
      contract_template_id,
      studio_id,
    } = getState().customerBooking.bookingCalendar;

    const booking_availability_id = getState().customerBooking
      .selectedAvailability.id;

    const { id: client_id } = getState().customerBooking.clientInfo;

    dispatch({ type: CUSTOMER_BOOKING_CONTRACT.GENERATE.REQUEST });
    const res = await axios.get(
      routes.BOOKING_CONTRACT.GENERATE(contract_template_id),
      {
        params: { client_id, studio_id, booking_availability_id },
      }
    );

    dispatch({
      type: CUSTOMER_BOOKING_CONTRACT.GENERATE.SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: CUSTOMER_BOOKING_CONTRACT.GENERATE.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });

    Rollbar.error(
      `Create Contract Frontend Error with error response ${error.response.data.error.messages}`
    );
  }
};

export const generateInvoice = token => async (dispatch, getState) => {
  try {
    const {
      invoice_template_id,
      studio_id,
    } = getState().customerBooking.bookingCalendar;

    const booking_availability_id = getState().customerBooking
      .selectedAvailability.id;

    const { id: client_id } = getState().customerBooking.clientInfo;

    dispatch({ type: CUSTOMER_BOOKING_INVOICE.GENERATE.REQUEST });
    const res = await axios.get(
      routes.BOOKING_INVOICE.GENERATE(invoice_template_id),
      {
        params: { client_id, studio_id, booking_availability_id },
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    dispatch({
      type: CUSTOMER_BOOKING_INVOICE.GENERATE.SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: CUSTOMER_BOOKING_INVOICE.GENERATE.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });

    Rollbar.error(
      `Create Invoice Frontend Error with error response ${error.response.data.error.messages}`
    );
  }
};

export const bookAvailability = (availability, clientId) => async (
  dispatch,
  getState
) => {
  try {
    const { token } = getState().customerBooking;

    dispatch({
      type: CUSTOMER_BOOKING_AVAILABILITY.BOOK_AVAILABILITY.REQUEST,
    });

    const params = {
      booking_availability: {
        booking_calendar_id: availability.booking_calendar_id,
        status: 'booked',
        client_id: clientId,
      },
    };

    const res = await axios.put(
      routes.BOOKING_AVAILABILITIES.BOOK(availability.id),
      params,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    dispatch({
      type: CUSTOMER_BOOKING_AVAILABILITY.BOOK_AVAILABILITY.SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: CUSTOMER_BOOKING_AVAILABILITY.BOOK_AVAILABILITY.FAILURE,
      payload: error.response.data.error,
      error: true,
    });

    Rollbar.error(
      `Create Calendar Event Error ${error.response.data.error}, availability ${availability} and client id ${client_id}`
    );
  }
};

export const checkClientPermission = clientId => async (dispatch, getState) => {
  try {
    const { token, selectedAvailability } = getState().customerBooking;
    const booking_availability_id = getState().customerBooking
      .selectedAvailability.id;

    dispatch({
      type: CUSTOMER_BOOKING_AVAILABILITY.CHECK_CLIENT_PERMISSION.REQUEST,
    });

    const res = await axios.put(
      routes.BOOKING_AVAILABILITIES.CHECK_CLIENT_PERMISSION(
        booking_availability_id
      ),
      {
        booking_availability: {
          client_id: clientId,
          booking_calendar_id: selectedAvailability.booking_calendar_id,
        },
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    dispatch({
      type: CUSTOMER_BOOKING_AVAILABILITY.CHECK_CLIENT_PERMISSION.SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: CUSTOMER_BOOKING_AVAILABILITY.CHECK_CLIENT_PERMISSION.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });

    Rollbar.error(
      `Check Client Permission Frontend Error with error response ${error.response.data.error.messages} and client id ${client_id}`
    );
  }
};

export const updateFooterOnContinue = newFuncRef => ({
  type: CUSTOMER_BOOKING_FOOTER.UPDATE_ON_CONTINUE,
  payload: newFuncRef,
});

export const updateFooterCurrentSelection = newSelection => ({
  type: CUSTOMER_BOOKING_FOOTER.UPDATE_CURRENT_SELECTION,
  payload: newSelection,
});

export const updateFooterContinueDisabled = isDisabled => ({
  type: CUSTOMER_BOOKING_FOOTER.UPDATE_CONTINUE_DISABLED,
  payload: isDisabled,
});

export const updateBookingLogicCompleted = newValue => ({
  type: CUSTOMER_BOOKING.BOOKING_LOGIC_COMPLETED,
  payload: newValue,
});

export const updateCalendarEvent = newValue => ({
  type: CUSTOMER_BOOKING.UPDATE_CALENDAR_EVENT,
  payload: newValue,
});
