import axios from 'axios';
import { routes, absolutePath } from '~constants/routes';
import { BOOKING_AVAILABILITY } from '~constants/actionTypes';

export const listBookingAvailabilities = params => async dispatch => {
  try {
    dispatch({ type: BOOKING_AVAILABILITY.LIST.REQUEST });
    const res = await axios.get(routes.BOOKING_AVAILABILITIES.INDEX, {
      params,
    });

    dispatch({ type: BOOKING_AVAILABILITY.LIST.SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: BOOKING_AVAILABILITY.LIST.FAILURE,
      payload: error,
      error: true,
    });
  }
};

export const createBookingAvailability = params => async dispatch => {
  try {
    dispatch({ type: BOOKING_AVAILABILITY.CREATE.REQUEST });

    const event = await axios
      .post(absolutePath(routes.BOOKING_AVAILABILITIES.CREATE), params)
      .then(res => res.data.data.attributes);

    dispatch({ type: BOOKING_AVAILABILITY.CREATE.SUCCESS, payload: event });
    return { res: 'success', errors: null };
  } catch (error) {
    dispatch({
      type: BOOKING_AVAILABILITY.CREATE.FAILURE,
      payload: error,
      error: true,
    });
    return {
      res: 'error',
      errors: 'This time has already been marked as available.',
    };
  }
};

export const updateBookingAvailability = (
  availabilityId,
  params
) => async dispatch => {
  try {
    dispatch({ type: BOOKING_AVAILABILITY.UPDATE.REQUEST });

    const event = await axios
      .put(routes.BOOKING_AVAILABILITIES.UPDATE(availabilityId), params)
      .then(res => res.data.data.attributes);

    dispatch({ type: BOOKING_AVAILABILITY.UPDATE.SUCCESS, payload: event });
    return { res: 'success', errors: null };
  } catch (error) {
    dispatch({
      type: BOOKING_AVAILABILITY.UPDATE.FAILURE,
      payload: error,
      error: true,
    });
    return { res: 'error', errors: error };
  }
};

export const deleteBookingAvailability = (
  availabilityId,
  params
) => async dispatch => {
  try {
    dispatch({ type: BOOKING_AVAILABILITY.DELETE.REQUEST });

    const res = await axios.delete(
      routes.BOOKING_AVAILABILITIES.DELETE(availabilityId),
      { data: params }
    );

    dispatch({ type: BOOKING_AVAILABILITY.DELETE.SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: BOOKING_AVAILABILITY.DELETE.FAILURE,
      payload: error,
      error: true,
    });
  }
};
