import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Request from 'superagent';
import { Button } from 'react-bootstrap';
import { withContext } from 'with-context';

import Uploader from './uploader';
import Attachments from '../uploader/attachments';
import BackendSelect from '../common/backend_select/backend_select';
import ApiCacheContext from '../../contexts/api_cache_context';

import styles from './email_attachments.module.scss';

@withContext(ApiCacheContext, 'apiCacheContext')
class EmailAttachments extends React.PureComponent {
  static propTypes = {
    email_template_id: PropTypes.number.isRequired,
    apiCacheContext: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      attachments: [],
      email_template_id: props.email_template_id,
      selectedDocument: '',
      selectedContract: '',
      selectedQuestionnaire: '',
      selectedInvoice: '',
    };
  }

  componentDidMount() {
    if (this.state.email_template_id) {
      this.loadData();
    }
  }

  addAttachment = attachmentItem => {
    const newAttachment = {
      id: '',
      item_id: attachmentItem.id,
      item_name: attachmentItem.file.name,
      item_type: 'Document',
    };

    this.setState({
      attachments: this.state.attachments.concat(newAttachment),
    });
  };

  addDocument = () => {
    const { selectedDocument } = this.state;
    if (!selectedDocument) {
      return;
    }
    this.addAttachment(selectedDocument);
  };

  reloadAttachments = () => {
    this.props.apiCacheContext.reloadIndex('documents');
  };

  deleteAttachment = attachmentItemId => {
    const { attachments } = this.state;
    const index = attachments.findIndex(
      e => e.item_id === attachmentItemId && !e._destroy
    );
    const updatedAttachment = {
      ...attachments[index],
      _destroy: true,
    };

    const newList = [...attachments];
    newList.splice(index, 1, updatedAttachment);
    this.setState({ attachments: newList });
  };

  loadData = () => {
    Request.get(
      `/api/legacy_email_templates/${this.state.email_template_id}/email_attachments`
    ).end((err, res) => {
      this.setState({
        attachments: res.body || [],
      });
    });
  };

  addQuestionnaire = () => {
    const { selectedQuestionnaire } = this.state;
    if (!selectedQuestionnaire) {
      return;
    }

    const newAttachment = {
      id: '',
      item_id: selectedQuestionnaire.id,
      item_name: selectedQuestionnaire.name,
      item_type: 'WebForm',
    };
    this.setState({
      attachments: this.state.attachments.concat(newAttachment),
    });
  };

  addContract = () => {
    const { selectedContract } = this.state;
    if (!selectedContract) {
      return;
    }

    const newAttachment = {
      id: '',
      item_id: selectedContract.id,
      item_name: selectedContract.name,
      item_type: 'ContractTemplate',
    };
    this.setState({
      attachments: this.state.attachments.concat(newAttachment),
    });
  };

  addInvoice = () => {
    const { selectedInvoice } = this.state;
    if (!selectedInvoice) {
      return;
    }

    const newAttachment = {
      id: '',
      item_id: selectedInvoice.id,
      item_name: selectedInvoice.name,
      item_type: 'InvoiceTemplate',
    };
    this.setState({
      attachments: this.state.attachments.concat(newAttachment),
    });
  };

  render() {
    const {
      attachments,
      selectedContract,
      selectedQuestionnaire,
      selectedInvoice,
      selectedDocument,
    } = this.state;

    const hasContractSignature = window.app.studio.hasContractSignature;

    return (
      <div className={styles.attachmentsFormContainer}>
        <div className={classnames('form-group', styles.attachmentCategory)}>
          <h1 className={styles.attachmentCategoryTitle}>Attachments</h1>
          <div>
            <Uploader
              addAttachment={this.addAttachment}
              reloadAttachments={this.reloadAttachments}
            />
          </div>
          <div>
            <BackendSelect
              apiName="documents"
              name="document_id"
              returnObject
              onChange={item => this.setState({ selectedDocument: item })}
              getItemName={item => item.file.name}
              value={selectedDocument && selectedDocument.id}
              placeholder="Select Attachment"
              className="form-control"
            />
            <Button
              className={classnames('btn', styles.addDocumentButton)}
              bsSize="small"
              onClick={this.addDocument}
            >
              Add
            </Button>
          </div>
          <Attachments
            deleteAttachment={this.deleteAttachment}
            items={attachments}
            onlyShowType={'Document'}
          />
        </div>

        <div className={classnames('form-group', styles.attachmentCategory)}>
          <h1 className={styles.attachmentCategoryTitle}>Questionnaires</h1>
          <div>
            <BackendSelect
              apiName="web_forms"
              name="web_form_id"
              returnObject
              onChange={item => this.setState({ selectedQuestionnaire: item })}
              value={selectedQuestionnaire && selectedQuestionnaire.id}
              placeholder="Select Questionnaire"
              className="form-control"
            />
            <Button
              className={classnames('btn', styles.addDocumentButton)}
              bsSize="small"
              onClick={this.addQuestionnaire}
            >
              Add
            </Button>
          </div>
          <Attachments
            deleteAttachment={this.deleteAttachment}
            items={attachments}
            onlyShowType={'WebForm'}
          />
        </div>

        <div className={classnames('form-group', styles.attachmentCategory)}>
          <h1 className={styles.attachmentCategoryTitle}>Contracts</h1>
          {hasContractSignature ? (
            <div>
              <BackendSelect
                apiName="contract_templates"
                name="contract_template_id"
                returnObject
                onChange={item => this.setState({ selectedContract: item })}
                value={selectedContract && selectedContract.id}
                placeholder="Select Contract Template"
                className="form-control"
              />
              <Button
                className="btn btn-default btn-small pull-left"
                bsSize="small"
                onClick={this.addContract}
              >
                Add
              </Button>
              <Attachments
                deleteAttachment={this.deleteAttachment}
                items={attachments}
                onlyShowType={'ContractTemplate'}
              />
            </div>
          ) : (
            <a
              href={`/studio/signatures`}
              rel="noopener noreferrer"
              target="_blank"
            >
              Input your signature to attach a contract
            </a>
          )}
        </div>

        <div className={classnames('form-group', styles.attachmentCategory)}>
          <h1 className={styles.attachmentCategoryTitle}>Invoices</h1>
          <div>
            <BackendSelect
              apiName="invoice_templates"
              name="invoice_template_id"
              returnObject
              onChange={item => this.setState({ selectedInvoice: item })}
              value={selectedInvoice && selectedInvoice.id}
              placeholder="Select Invoice Template"
              className="form-control"
            />
            <Button
              className={classnames('btn', styles.addDocumentButton)}
              bsSize="small"
              onClick={this.addInvoice}
            >
              Add
            </Button>
          </div>
          <Attachments
            deleteAttachment={this.deleteAttachment}
            items={attachments}
            onlyShowType={'InvoiceTemplate'}
          />
        </div>
      </div>
    );
  }
}

export default EmailAttachments;
