import React, { useState } from 'react';
import styles from './ClientMobileNavbar.module.scss';

const ClientMobileNavbar = props => {
  const { clientId } = props;

  const [currentUrlPath, setCurrentUrlPath] = useState(
    window.location.pathname
  );
  const [openDropdown, setOpenDropdown] = useState(false);

  const CLIENT_PATHS = {
    details: `/clients/${clientId}`,
    activity: `/clients/${clientId}/activities`,
    workflows: `/clients/${clientId}/journeys`,
    toDos: `/clients/${clientId}/to-dos`,
    sessions: `/clients/${clientId}/sessions`,
    invoices: `/clients/${clientId}/invoices`,
    documents: `/clients/${clientId}/documents`,
    galleries: `/clients/${clientId}/galleries`,
  };

  return (
    <div className={styles.ClientMobileNavbar}>
      <div onClick={() => setOpenDropdown(!openDropdown)}>
        <div>Client</div>
        <span className="fa fa-caret-down"></span>
      </div>
      <ul className={openDropdown ? styles['ClientMobileNavbar-open'] : ''}>
        <li
          className={
            currentUrlPath === CLIENT_PATHS.details
              ? styles['ClientMobileNavbar-active']
              : ''
          }
        >
          <a
            href={CLIENT_PATHS.details}
            onClick={() => setCurrentUrlPath(CLIENT_PATHS.details)}
          >
            Details
          </a>
        </li>
        <li
          className={
            currentUrlPath === CLIENT_PATHS.activity
              ? styles['ClientMobileNavbar-active']
              : ''
          }
        >
          <a
            href={CLIENT_PATHS.activity}
            onClick={() => setCurrentUrlPath(CLIENT_PATHS.activity)}
          >
            Activity
          </a>
        </li>
        <li
          className={
            currentUrlPath === CLIENT_PATHS.workflows
              ? styles['ClientMobileNavbar-active']
              : ''
          }
        >
          <a
            href={CLIENT_PATHS.workflows}
            onClick={() => setCurrentUrlPath(CLIENT_PATHS.workflows)}
          >
            Workflows
          </a>
        </li>
        <li
          className={
            currentUrlPath === CLIENT_PATHS.toDos
              ? styles['ClientMobileNavbar-active']
              : ''
          }
        >
          <a
            href={CLIENT_PATHS.toDos}
            onClick={() => setCurrentUrlPath(CLIENT_PATHS.toDos)}
          >
            To-Dos
          </a>
        </li>
        <li
          className={
            currentUrlPath === CLIENT_PATHS.sessions
              ? styles['ClientMobileNavbar-active']
              : ''
          }
        >
          <a
            href={CLIENT_PATHS.sessions}
            onClick={() => setCurrentUrlPath(CLIENT_PATHS.sessions)}
          >
            Sessions
          </a>
        </li>
        <li
          className={
            currentUrlPath === CLIENT_PATHS.invoices
              ? styles['ClientMobileNavbar-active']
              : ''
          }
        >
          <a
            href={CLIENT_PATHS.invoices}
            onClick={() => setCurrentUrlPath(CLIENT_PATHS.invoices)}
          >
            Invoices
          </a>
        </li>
        <li
          className={
            currentUrlPath === CLIENT_PATHS.documents
              ? styles['ClientMobileNavbar-active']
              : ''
          }
        >
          <a
            href={CLIENT_PATHS.documents}
            onClick={() => setCurrentUrlPath(CLIENT_PATHS.documents)}
          >
            Documents
          </a>
        </li>
        <li
          className={
            currentUrlPath === CLIENT_PATHS.galleries
              ? styles['ClientMobileNavbar-active']
              : ''
          }
        >
          <a
            href={CLIENT_PATHS.galleries}
            onClick={() => setCurrentUrlPath(CLIENT_PATHS.galleries)}
          >
            Galleries
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ClientMobileNavbar;
