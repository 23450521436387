import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './TimePicker.module.scss';

const TimePicker = props => {
  const {
    initialDateTime,
    name,
    onChangeCallback,
    className,
    required,
    onBlurInput,
  } = props;

  const [dateTime, setDateTime] = useState(
    initialDateTime ? moment(initialDateTime) : moment()
  );
  const [timeHourValue, setTimeHourValue] = useState('');
  const [timeMinValue, setTimeMinValue] = useState('');
  const [meridiem, setMeridiem] = useState();
  const [timeValid, setTimeValid] = useState(true);
  const cx = classNames.bind(styles);
  const timeClasses = cx('TimePicker', {
    'TimePicker--invalid': !timeValid,
    [className]: !!className,
  });

  useEffect(() => {
    const updatedDateTime = moment(initialDateTime).clone();
    if (updatedDateTime.isValid()) {
      if (updatedDateTime.hour() == 12) {
        setTimeHourValue(updatedDateTime.hour());
        setMeridiem('PM');
      } else if (updatedDateTime.hour() == 0) {
        setTimeHourValue(12);
        setMeridiem('AM');
      } else if (updatedDateTime.hour() > 12) {
        setTimeHourValue(updatedDateTime.hour() - 12);
        setMeridiem('PM');
      } else {
        setTimeHourValue(updatedDateTime.hour());
        setMeridiem('AM');
      }
      setTimeMinValue(
        updatedDateTime.minute() < 10
          ? `0${updatedDateTime.minute()}`
          : updatedDateTime.minute()
      );

      setTimeValid(true);
      setDateTime(updatedDateTime);
    }
  }, [initialDateTime]);

  const handleTimeUpdate = (hourValue, minuteValue, meridiem) => {
    let hour = +hourValue;
    if (meridiem === 'PM' && hour !== 12) {
      hour = hour + 12;
    } else if (meridiem === 'AM' && hour === 12) {
      hour = 0;
    }

    const dateTimeClone = dateTime.clone();
    dateTimeClone.set({ hour, minute: minuteValue });
    onChangeCallback(dateTimeClone);
    onBlurInput(dateTimeClone);
    setDateTime(dateTimeClone);
  };

  const checkTimeValid = (hour, minute) => {
    if (hour > 12 || minute > 59) {
      setTimeValid(false);
      return false;
    } else {
      setTimeValid(true);
      return true;
    }
  };

  return (
    <div className={styles['TimePicker-container']}>
      <div className={timeClasses}>
        <input
          className="hr"
          type="text"
          name={name}
          value={timeHourValue}
          required={required}
          autoComplete="off"
          maxLength="2"
          onFocus={e => {
            e.stopPropagation();
            e.target.select();
          }}
          onBlur={e => {
            if (checkTimeValid(e.target.value, timeMinValue)) {
              handleTimeUpdate(e.target.value, timeMinValue, meridiem);
            }
          }}
          onChange={e => {
            if (!isNaN(e.target.value)) {
              setTimeHourValue(e.target.value);
            }
          }}
        />
        <span>:</span>
        <input
          className="min"
          type="text"
          name={name}
          value={timeMinValue}
          required={required}
          autoComplete="off"
          maxLength="2"
          onFocus={e => e.target.select()}
          onBlur={e => {
            if (checkTimeValid(timeHourValue, e.target.value)) {
              handleTimeUpdate(timeHourValue, e.target.value, meridiem);
            }
          }}
          onChange={e => {
            if (!isNaN(e.target.value)) {
              setTimeMinValue(e.target.value);
            }
          }}
        />
        <select
          name="meridiem"
          value={meridiem}
          onBlur={() => onBlurInput(dateTime)}
          onChange={e => {
            setMeridiem(e.target.value);
            handleTimeUpdate(timeHourValue, timeMinValue, e.target.value);
          }}
        >
          <option value="AM">AM</option>
          <option value="PM">PM</option>
        </select>
      </div>
      {!timeValid && <span>Invalid Time</span>}
    </div>
  );
};

TimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  onChangeCallback: PropTypes.func,
  onBlurInput: PropTypes.func,
  required: PropTypes.bool,
  initialDateTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

TimePicker.defaultProps = {
  onChangeCallback: () => {},
  required: false,
  onBlurInput: () => {},
};

export default TimePicker;
