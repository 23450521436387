import axios from 'axios';
import { routes } from '~constants/routes';
import { CLIENT_CONTRACTS } from '~constants/actionTypes';

export const getClientContracts = clientId => async (dispatch, getState) => {
  try {
    const { meta } = getState().documents.contracts;
    const { page, limit } = meta;
    dispatch({ type: CLIENT_CONTRACTS.GET.REQUEST });

    const { data } = await axios.get(routes.CONTRACTS.INDEX, {
      params: {
        page,
        limit,
        client_id: clientId,
        sort: { column: 'delivered_at', direction: 'desc' },
      },
    });

    dispatch({
      key: 'contracts',
      type: CLIENT_CONTRACTS.GET.SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLIENT_CONTRACTS.GET.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};

export const updateClientContractsMeta = params => ({
  type: CLIENT_CONTRACTS.META.UPDATE,
  payload: params,
});
