import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateInvoiceConfig } from '~actions/invoiceConfig';
import { Accordion } from '~ui';
import { useSelector } from 'react-redux';
import PaymentProcessorsSection from './PaymentProcessorsSection';
import PaymentSettingsSection from './PaymentSettingsSection';
import IntegrationsSection from './IntegrationsSection';
import InvoiceProductsSection from './InvoiceProductsSection';
import styles from './InvoiceSetup.module.scss';

const InvoiceSetup = () => {
  const invoiceConfig = useSelector(state => state.invoiceConfig);
  const dispatch = useDispatch();
  const asyncUpdateInvoiceConfig = params =>
    dispatch(updateInvoiceConfig(params));

  const [currency, setCurrency] = useState(invoiceConfig.currency);
  const [taxEnabled, setTaxEnabled] = useState(
    invoiceConfig.tax_enabled_default
  );
  const [taxAmount, setTaxAmount] = useState(invoiceConfig.tax_amount_default);
  const [sendPaymentReminders, setSendPaymentReminders] = useState(
    invoiceConfig.send_payment_reminders
  );
  const [invoiceEmailMessage, setInvoiceEmailMessage] = useState();
  const [tipsEnabled, setTipsEnabled] = useState(invoiceConfig.tips_enabled);
  const [cashAppEnabled, setCashAppEnabled] = useState(
    invoiceConfig.cashAppEnabled
  );
  const [showCouponCodes, setShowCouponCodes] = useState(
    invoiceConfig.showCouponCodes
  );

  return (
    <div className={styles.InvoiceSetup}>
      <h1>Invoice Setup</h1>
      <Accordion
        title={`Payment Processor${
          invoiceConfig.showOtherProcessorsOption ? 's' : ''
        }`}
        expanded
      >
        <PaymentProcessorsSection
          asyncUpdateInvoiceConfig={asyncUpdateInvoiceConfig}
        />
      </Accordion>
      <Accordion title="Settings" preventClose={true}>
        <PaymentSettingsSection
          asyncUpdateInvoiceConfig={asyncUpdateInvoiceConfig}
          setCurrency={setCurrency}
          setTaxEnabled={setTaxEnabled}
          setTaxAmount={setTaxAmount}
          setSendPaymentReminders={setSendPaymentReminders}
          setInvoiceEmailMessage={setInvoiceEmailMessage}
          setTipsEnabled={setTipsEnabled}
          setCashAppEnabled={setCashAppEnabled}
          setShowCouponCodes={setShowCouponCodes}
          currency={currency}
          taxEnabled={taxEnabled}
          taxAmount={taxAmount}
          sendPaymentReminders={sendPaymentReminders}
          invoiceEmailMessage={invoiceEmailMessage}
          tipsEnabled={tipsEnabled}
          cashAppEnabled={cashAppEnabled}
          showCouponCodes={showCouponCodes}
        />
      </Accordion>
      <Accordion title="Integrations">
        <IntegrationsSection
          asyncUpdateInvoiceConfig={asyncUpdateInvoiceConfig}
        />
      </Accordion>
      <Accordion title="Invoice Products">
        <InvoiceProductsSection />
      </Accordion>
    </div>
  );
};

export default InvoiceSetup;
