import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, CurrencyInput, Button, Dropdown, TextArea } from '~ui';
import {
  refundSquareInvoice,
  refundStripeInvoice,
  refundManualInvoice,
} from '~actions/invoice';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import styles from './RefundModal.module.scss';

const RefundModal = ({ invoice, clientPayments, handleHide, payments }) => {
  const dispatch = useDispatch();

  const [refundAmount, setRefundAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [refundSelection, setRefundSelection] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [notes, setNotes] = useState('');
  const [payment, setPayment] = useState(false);

  const sendRefund = async () => {
    setDisabled(true);
    setLoading(true);
    let res;

    if (payment.square_payment_id) {
      res = await dispatch(
        refundSquareInvoice(invoice.uuid, {
          amount: refundAmount,
          square_payment_id: refundSelection,
          invoice_payment_id: payment.id,
          notes: notes,
        })
      );
    } else if (payment.manual_refund) {
      res = await dispatch(
        refundManualInvoice(invoice.uuid, {
          amount: refundAmount,
          invoice_payment_id: payment.id,
          notes: notes,
        })
      );
    } else {
      res = await dispatch(
        refundStripeInvoice(invoice.uuid, {
          amount: refundAmount,
          stripe_payment_intent_id: refundSelection,
          invoice_payment_id: payment.id,
          notes: notes,
        })
      );
    }

    if (res.success) {
      toast.success('Success!');
      handleHide();
    } else {
      const errorMessage =
        res.errors || 'There was an error issuing the refund.';
      toast.error(errorMessage, toastOptions);
    }

    setLoading(false);
    setDisabled(false);
  };

  useEffect(() => {
    if (refundSelection) {
      let payment = payments.find(
        payment =>
          payment.square_payment_id === refundSelection ||
          payment.stripe_payment_id === refundSelection ||
          payment.id == removeManualPrefix(refundSelection)
      );
      setPayment(payment);
    }
    if (!refundSelection || refundAmount == 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [refundAmount, refundSelection]);

  const removeManualPrefix = input => {
    return input.replace(/^manual-/, '');
  };

  return (
    <Modal title="Process Refund" handleHide={handleHide}>
      <Dropdown
        labelText="Refundable Client Payments"
        name="refundSelection"
        options={clientPayments}
        onChangeCallback={setRefundSelection}
      />
      {refundSelection && payment.available_refund_amount < payment.amount && (
        <div
          style={{
            fontSize: '12px',
            marginLeft: '10px',
          }}
        >
          Available refundable amount: {window.app.invoices.currency}
          {payment.available_refund_amount}
        </div>
      )}
      {refundSelection && payment.manual_refund && (
        <div
          style={{
            fontSize: '12px',
            marginLeft: '10px',
          }}
        >
          This payment was manually recorded. Ensure funds are returned to the
          client after recording the refund.
        </div>
      )}
      <div
        style={{
          width: '200px',
          marginTop: '20px',
        }}
      >
        <CurrencyInput
          labelText="Refund Amount"
          max={5000}
          name="tip_amount"
          currencyType={invoice.meta.currency}
          initialValue={refundAmount}
          onChangeCallback={val => {
            setRefundAmount(Number(val));
          }}
        />
      </div>
      <br />
      <TextArea
        name="notes"
        labelText="Notes"
        initialValue={''}
        onChangeCallback={setNotes}
      />
      <div className={styles['RefundModal-buttons']}>
        <Button white text="Cancel" onClick={handleHide} />
        <Button
          disabled={disabled}
          text="Refund"
          loading={loading}
          onClick={() => sendRefund()}
        />
      </div>
    </Modal>
  );
};

export default RefundModal;
