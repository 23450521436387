import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import classNames from 'classnames/bind';
import {
  listCalendarEvents,
  getSessionTypeCount,
} from '~actions/calendarEvents';
import { updateShowEditModal, updateDSTDates } from '~actions/calendar';
import { intervalSelector } from '~selectors';
import { DATE_KEY_FORMAT } from '~constants/datetime';
import styles from './CalendarContent.module.scss';
import CalendarHeader from '../CalendarHeader';
import CalendarEventForm from '../CalendarEventForm';
import MonthView from '../MonthView';
import WeekView from '../WeekView';
import DeleteEventModal from '../DeleteEventModal';

/*
  The calendar makes use of the Calendar Events API and the Cronofy Calendar API
  to populate events. It is composed of several nested components with the structure:

  <CalendarContent>
    <CalendarHeader/>
    <MonthView /> || <WeekView />
  </CalendarContent>

  A description of each child component lives in their respective component files.
*/

const CalendarContent = () => {
  const debounceTimer = useRef(null);

  const [dateContext, setDateContext] = useState(moment());
  const interval = useSelector(intervalSelector);
  const activeEvent = useSelector(state => state.calendar.activeEvent);
  const showEditModal = useSelector(state => state.calendar.showEditModal);
  const showDeleteModal = useSelector(state => state.calendar.showDeleteModal);
  const timeZone = useSelector(state => state.calendar.meta.timeZone);
  const dispatch = useDispatch();
  const cx = classNames.bind(styles);
  const classes = cx('CalendarContent', {
    'CalendarContent--fullWidth': interval === 'week',
  });
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  const getLocation = async () =>
    await app.util.getMyLocation(position => {
      if (position) {
        setLatitude(position.latitude);
        setLongitude(position.longitude);
      }
    });

  const getDSTDatesForCurrentAndFutureYears = () => {
    const dstDates = [];
    const currentYear = moment().year();

    for (let year = currentYear; year <= currentYear + 4; year++) {
      const startOfYear = moment.tz(`${year}-01-01T00:00:00`, timeZone);
      const endOfYear = moment.tz(`${year}-12-31T23:59:59`, timeZone);
      let current = startOfYear.clone();

      while (current.isBefore(endOfYear)) {
        const next = current.clone().add(1, 'hour');

        if (current.isDST() !== next.isDST()) {
          const transitionType = next.isDST() ? 'spring forward' : 'fall back';
          dstDates.push({
            date: next.format('YYYY-MM-DD'),
            type: transitionType,
            year: year,
          });

          current = next.clone().add(1, 'day').startOf('day');
        } else {
          current = next;
        }
      }
    }

    dispatch(updateDSTDates(dstDates));
  };

  useEffect(() => {
    getLocation();
    getDSTDatesForCurrentAndFutureYears();
  }, []);

  const getCalendarEventsForInterval = date => {
    let start = moment(date)
      .tz(timeZone)
      .subtract(1, 'month')
      .format(DATE_KEY_FORMAT);
    let end = moment(date).tz(timeZone).add(1, 'month').format(DATE_KEY_FORMAT);
    let day = moment(date).clone().format(DATE_KEY_FORMAT);

    dispatch(listCalendarEvents({ start, end }));
    dispatch(getSessionTypeCount({ start: day }));
  };

  const debounceApiCall = newDateContext => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    debounceTimer.current = setTimeout(() => {
      getCalendarEventsForInterval(newDateContext);
    }, 1000);
  };

  const nextInterval = () => {
    let newDateContext = moment(dateContext).clone().add(1, interval);

    setDateContext(newDateContext);
    debounceApiCall(newDateContext);
  };

  const prevInterval = () => {
    let newDateContext = moment(dateContext).clone().subtract(1, interval);

    setDateContext(newDateContext);
    debounceApiCall(newDateContext);
  };

  return (
    <div className={classes}>
      <CalendarHeader
        dateContext={dateContext}
        prevInterval={prevInterval}
        nextInterval={nextInterval}
      />
      {interval === 'month' ? (
        <MonthView
          dateContext={dateContext}
          latitude={latitude}
          longitude={longitude}
        />
      ) : (
        <WeekView dateContext={dateContext} />
      )}
      {showEditModal && (
        <CalendarEventForm
          event={activeEvent}
          handleHide={() => dispatch(updateShowEditModal(false))}
          dateContext={dateContext}
        />
      )}
      {showDeleteModal && (
        <DeleteEventModal
          getCalendarEventsForInterval={getCalendarEventsForInterval}
          dateContext={dateContext}
        />
      )}
    </div>
  );
};

export default CalendarContent;
