import React from 'react';
import PropTypes from 'prop-types';
import styles from './Table.module.scss';
import { CustomDropdown, Button } from '~ui';

const TableActionBar = ({ options, actionBarCount, downloadOnClick }) => (
  <div className={styles.TableActionBar}>
    {actionBarCount > 0 && (
      <>
        <div>
          <CustomDropdown options={options} label={'Actions'} />
        </div>
        <div>
        <p style={{ margin: '10px', color: '#7EC0E5' }}>
            {actionBarCount} items selected
          </p>
        </div>
      </>
    )}

    {downloadOnClick && (
      <div
        className={styles['TableActionBar-download']}
        onClick={downloadOnClick}
      >
        <i className="fa fa-download" aria-hidden="true"></i>{' '}
      </div>
    )}
  </div>
);

TableActionBar.propTypes = {
  options: PropTypes.array.isRequired,
  actionBarCount: PropTypes.number.isRequired,
};

export default TableActionBar;
