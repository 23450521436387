import React, { useEffect, useState } from 'react';
import styles from './DashboardHeader.module.scss';
import { useLocation } from '~hooks';
import axios from 'axios';
import { routes } from '~constants/routes';
import Skycons from 'react-skycons';
import SUNRISE_ICON from '~images/sunrise.svg';
import SUNSET_ICON from '~images/sunset.svg';
import moment from 'moment-timezone';
import { getDaysBetweenDates } from '~utils';
import { DATE_KEY_FORMAT } from '~constants/datetime';
import { useWindowSize } from '~hooks';
import { MOBILE_WIDTH } from '~constants/viewports';
import SunCalc from 'suncalc';

const DashboardHeader = ({ spudUser }) => {
  const [lat, lng] = useLocation();
  const [forecastData, setForecastData] = useState();
  const [sunData, setSunData] = useState();
  const [weatherData, setWeatherData] = useState();
  const [forecastLoading, setForecastLoading] = useState(true);
  const [sunLoading, setSunLoading] = useState(true);
  const [error, setError] = useState();

  const windowWidth = useWindowSize()[0];
  const isMobile = windowWidth < MOBILE_WIDTH;

  const getForecast = async () => {
    try {
      const res = await axios.get(routes.FORECAST.INDEX, {
        params: {
          lat,
          lng,
        },
      });

      setForecastData(res.data.data.attributes);
      setForecastLoading(false);
    } catch (error) {
      setForecastLoading(false);
      setError(error);
      console.error(error);
    }
  };

  const days = getDaysBetweenDates(
    moment(),
    moment().add(4, 'days'),
    DATE_KEY_FORMAT
  );

  const getSun = async () => {
    try {
      const sunriseAndSunset = days.map(day => {
        const sunriseTime = SunCalc.getTimes(new Date(day), lat, lng);

        const sunsetTime = SunCalc.getTimes(new Date(day), lat, lng);

        return {
          sunrise: moment(sunriseTime.sunrise).format('h:mm'),
          sunset: moment(sunsetTime.sunset).format('h:mm'),
        };
      });

      setSunData(sunriseAndSunset);
      setSunLoading(false);
    } catch (error) {
      setSunLoading(false);
      setError(error);
      console.error(error);
    }
  };

  useEffect(() => {
    if (lat && lng) {
      getForecast();
      getSun();
    }
  }, [lat, lng]);

  useEffect(() => {
    if (sunData && forecastData) {
      const days = getDaysBetweenDates(
        moment(),
        moment().add(4, 'days'),
        'ddd'
      );

      const mergedData = days.map((day, index) => ({
        day: index === 0 ? 'Today' : day,
        sunrise: sunData[index].sunrise,
        sunset: sunData[index].sunset,
        tempMax: forecastData.daily[index].temperatureMax.toFixed(),
        icon: mapIconToSkycons(forecastData.daily[index].icon),
      }));
      if (isMobile) {
        setWeatherData([mergedData[0]]);
      } else {
        setWeatherData(mergedData);
      }
    }
  }, [sunData, forecastData, isMobile]);

  const mapIconToSkycons = icon => {
    const iconMap = {
      '01d': 'CLEAR_DAY',
      '02d': 'PARTLY_CLOUDY_DAY',
      '03d': 'CLOUDY',
      '04d': 'CLOUDY',
      '09d': 'RAIN',
      '10d': 'RAIN',
      '11d': 'RAIN',
      '13d': 'SNOW',
      '50d': 'FOG',
      '01n': 'CLEAR_NIGHT',
      '02n': 'PARTLY_CLOUDY_NIGHT',
      '03n': 'CLOUDY',
      '04n': 'CLOUDY',
      '09n': 'RAIN',
      '10n': 'RAIN',
      '11n': 'RAIN',
      '13n': 'SNOW',
      '50n': 'FOG',
    };

    return iconMap[icon] || '';
  };

  return (
    <div className={styles.DashboardHeader}>
      <div className={styles['DashboardHeader-userInfo']}>
        <h1>Hi, {spudUser.firstName}!</h1>
        <h3>{moment().format('dddd, MMMM D, YYYY')}</h3>
      </div>
      <div className={styles['DashboardHeader-weatherInfo']}>
        {error && (
          <div>
            Unable to load weather, please ensure location services are enabled
            to view weather.
          </div>
        )}
        {forecastLoading || sunLoading ? (
          <div>Loading...</div>
        ) : (
          weatherData &&
          weatherData.map((wd, index) => (
            <React.Fragment key={index}>
              <div
                className={styles['DashboardHeader-forecast']}
                style={index === 0 ? { color: 'black' } : {}}
              >
                <p>{wd.day}</p>
                <Skycons
                  type={wd.icon}
                  color={index === 0 ? 'black' : '#949596'}
                  animate
                  size={35}
                  resizeClear
                />
                <p>
                  {wd.tempMax}
                  <sup>&deg;</sup>
                </p>
              </div>
              <div
                className={styles['DashboardHeader-sun']}
                style={index === 0 ? { color: 'black' } : {}}
              >
                <div>
                  <img src={SUNRISE_ICON} height="17" width="auto" />
                  <p>{wd.sunrise}am</p>
                </div>
                <div>
                  <img src={SUNSET_ICON} height="17" width="auto" />
                  <p>{wd.sunset}pm</p>
                </div>
              </div>
              {index !== weatherData.length - 1 && (
                <div className={styles['DashboardHeader-weatherInfo-vl']}></div>
              )}
            </React.Fragment>
          ))
        )}
      </div>
    </div>
  );
};

export default DashboardHeader;
