import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames/bind';
import { Icon } from '~ui';
import { MONTH_YEAR_FORMAT } from '~constants/datetime';
import { uniqueId } from '~utils';
import styles from './DatePicker.module.scss';

const DatePicker = ({ initialDate, nextMonth, onChangeCallback }) => {
  const currentDate = nextMonth ? moment(new Date()).add(1, 'months') : moment()
  
  const [dateContext, setDateContext] = useState(
    initialDate ? moment(initialDate) : currentDate
  );
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const dateContextClone = { ...dateContext };
  const startDate = moment(dateContextClone).startOf('week');
  const days = [...Array(7).keys()].map(i => {
    let weekday = moment(startDate).format('dd');
    startDate.add(1, 'day');
    return (
      <div
        key={uniqueId('DatePicker-weekday_')}
        className={styles['DatePicker-weekday']}
      >
        {weekday}
      </div>
    );
  });

  useEffect(() => {
    setSelectedDate(initialDate);
  }, [initialDate]);

  const prevInterval = () => {
    let newDateContext = moment(dateContext)
      .clone()
      .subtract(1, 'month');

    setDateContext(newDateContext);
  };

  const nextInterval = () => {
    let newDateContext = moment(dateContext)
      .clone()
      .add(1, 'month');

    setDateContext(newDateContext);
  };

  const renderCells = () => {
    const mutableDateContext = { ...dateContext };
    const monthStart = moment(mutableDateContext).startOf('month');
    const monthEnd = moment(mutableDateContext).endOf('month');
    let startDate = moment(monthStart).startOf('week');
    let endDate = moment(monthEnd).endOf('week');
    let days = [];
    let rows = [];

    // We need to loop until the end of the month.
    while (startDate <= endDate) {
      // Each week is 7 days, so we go up to 7 in this loop to construct a row.
      for (let i = 0; i < 7; i++) {
        const cx = classNames.bind(styles);
        const classes = cx('DatePicker-cell', {
          'DatePicker-cell--current': currentDate.isSame(startDate, 'day'),
          'DatePicker-cell--selected':
            selectedDate && selectedDate.isSame(startDate, 'day'),
          'DatePicker-cell--doubleDigit': startDate.date() > 9,
        });
        let dayClone = moment(startDate).clone();

        if (selectedDate) {
          // When we use startOf and endOf, the hour gets set to midnight
          // so we need to set the hour and minute again.
          dayClone.set({
            hour: selectedDate.get('hour'),
            minute: selectedDate.get('minute'),
          });
        }

        days.push(
          <div
            key={uniqueId('DatePicker-cell_')}
            className={classes}
            onClick={e => {
              e.stopPropagation();
              setSelectedDate(dayClone);
              onChangeCallback(dayClone);
            }}
          >
            <span>{dayClone.date()}</span>
          </div>
        );
        startDate = moment(startDate).add(1, 'day');
      }

      // Push at least 4 rows into the calendar. This will be at least 4 rows worth of days
      // for the month.
      rows.push(
        <div
          className={styles['DatePicker-row']}
          key={uniqueId('DatePicker-row_')}
        >
          {days}
        </div>
      );

      days = []; // Clear out the days array so we can start the next row.
    }

    return <>{rows}</>;
  };

  return (
    <div className={styles['DatePicker']}>
      <div className={styles['DatePicker-header']}>
        <Icon name="chevron-left" onClickCallback={prevInterval} />
        <h3>{dateContext.format(MONTH_YEAR_FORMAT)}</h3>
        <Icon name="chevron-right" onClickCallback={nextInterval} />
      </div>
      <div className={styles['DatePicker-weekdays']}>{days}</div>
      {renderCells()}
    </div>
  );
};

DatePicker.propTypes = {
  initialDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChangeCallback: PropTypes.func,
};

DatePicker.defaultProps = {
  initialDate: undefined,
  onChangeCallback: () => {},
};

export default DatePicker;
