import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableNew as Table, DeleteConfirmation } from '~ui';
import { routes } from '~constants/routes';
import axios from 'axios';
import ContractTemplateModal from '~components/ContractTemplate/ContractTemplateModal';
import {
  getContractTemplates,
  updateContractTemplatesMeta,
} from '~actions/contractTemplates';
import ContractsButtons from '../ButtonGroups/ContractsButtons';

const ContractsTable = () => {
  const { data, meta } = useSelector(state => state.documents.contracts);

  const [rows, setRows] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [idForDelete, setIdForDelete] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [contractTemplateForEdit, setContractTemplateForEdit] = useState({});

  const dispatch = useDispatch();

  const ITEM_HEADERS = ['Name', 'Requires Two Signatures'];

  const createRowItem = ({
    id,
    name,
    requiresTwoSig,
    contractBody,
    contractTemplate,
  }) => {
    const rowItem = {
      id,
      editable: true,
      deletable: true,
      temp: false,
      onClickCallback: () => {
        setContractTemplateForEdit({
          requiresTwoSignatures: requiresTwoSig,
          contractBody,
          name,
          id,
        });
        setShowEditModal(true);
      },
      editButtonOverride: () => {
        setContractTemplateForEdit({
          requiresTwoSignatures: requiresTwoSig,
          contractBody,
          name,
          id,
        });
        setShowEditModal(true);
      },
      deleteButtonOverride: () => {
        setIdForDelete(id);
        setShowDeleteModal(true);
      },
      buttonGroup: <ContractsButtons {...{ contractTemplate }} />,
      data: {
        name: {
          value: name,
          type: 'enum',
          editable: false,
        },
        requiresTwoSig: {
          value: requiresTwoSig ? 'Yes' : 'No',
          type: 'enum',
          editable: false,
        },
      },
    };

    return rowItem;
  };

  useEffect(() => {
    if (data) {
      const newRows = data.map(contractTemplate => {
        return createRowItem({
          id: +contractTemplate.id,
          name: contractTemplate.attributes.name,
          contractBody: contractTemplate.attributes.contract_body,
          contractTemplate: contractTemplate,
          requiresTwoSig:
            contractTemplate.attributes.requires_secondary_signature,
        });
      });

      setRows(newRows);
    }
  }, [data]);

  const handleContractDelete = async () => {
    try {
      await axios.delete(routes.CONTRACT_TEMPLATES.DELETE(idForDelete));
      setShowDeleteModal(false);
      setIdForDelete(null);
      dispatch(getContractTemplates());
    } catch (error) {
      console.error(error);
      setIdForDelete(null);
    }
  };

  return (
    <>
      <div>
        <Table
          readOnly
          headers={ITEM_HEADERS}
          rows={rows}
          setRows={setRows}
          page={meta.page}
          pageCount={meta.page_count}
          totalRecords={meta.total_records}
          pageChangeCallback={newPageIndex => {
            dispatch(
              updateContractTemplatesMeta({
                page: newPageIndex,
              })
            );
            dispatch(getContractTemplates());
          }}
        />
      </div>
      {showDeleteModal && (
        <DeleteConfirmation
          handleHide={() => setShowDeleteModal(false)}
          handleDelete={() => handleContractDelete(idForDelete)}
          resource="Contract"
        />
      )}
      {showEditModal && (
        <ContractTemplateModal
          editing
          handleHide={() => setShowEditModal(false)}
          initialData={contractTemplateForEdit}
        />
      )}
    </>
  );
};

export default ContractsTable;
