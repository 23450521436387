import { COUPON_CODE_LIST, COUPON_CODES } from '~constants/actionTypes';

export default function (state = {}, action) {
  let items;

  switch (action.type) {
    case COUPON_CODE_LIST.GET.SUCCESS:
      return {
        ...state,
        couponCodeList: action.payload.data,
      };
    case COUPON_CODES.CREATE.SUCCESS:
      items = [...state.couponCodeList, action.payload];

      return { ...state, couponCodeList: items };
    case COUPON_CODES.UPDATE.SUCCESS:
      items = state.couponCodeList.map(coupon => {
        if (coupon.id === action.payload.id) {
          return {
            ...coupon,
            ...action.payload,
          };
        }

        return coupon;
      });

      return { ...state, couponCodeList: items };
    case COUPON_CODES.DELETE.SUCCESS:
      items = state.couponCodeList.filter(
        coupon => coupon.id !== action.payload.couponId
      );

      return { ...state, couponCodeList: items };
    default:
      return state;
  }
}
