import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Col, Row, Button } from 'react-bootstrap';

class SquareCheckout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    //Place square payment form function in componentDidMount
    //Because it has to be called after the DOM renders the divs
    this.paymentForm = new window.SqPaymentForm({
      applicationId: this.props.applicationId,
      locationId: this.props.locationId,
      inputClass: 'sq-input',
      inputStyles: [
        {
          fontSize: '14px',
          padding: '7px 12px',
          backgroundColor: 'transparent',
        },
      ],
      cardNumber: {
        elementId: 'sq-card-number',
        placeholder: '0000 0000 0000 0000',
      },
      cvv: {
        elementId: 'sq-cvv',
        placeholder: 'CVV',
      },
      expirationDate: {
        elementId: 'sq-expiration-date',
        placeholder: 'MM/YY',
      },
      postalCode: {
        elementId: 'sq-postal-code',
        placeholder: '94110',
      },
      callbacks: {
        cardNonceResponseReceived: function(errors, nonce, cardData) {
          if (errors) {
            this.setState({ is_processing: false, card_errors: errors });
          } else {
            this.setState({ card_errors: [] });
            this.chargeCardWithNonce(nonce);
          }
        }.bind(this), //needed to bring this into context
        unsupportedBrowserDetected: function() {
          // Alert the buyer
          alert('Browser not supported. Please use a modern browser.');
        }.bind(this),
      },
    });
    this.paymentForm.build();
  }

  chargeCardWithNonce(nonce) {
    const invoiceId = this.props.invoiceId;
    const url = `/customer/invoices/${invoiceId}/charge_square`;
    const data = { nonce: nonce };

    // used to be on success
    // - makes sense if we don't want to refresh
    // this.setState({ is_payment_success: true })
    const success = (data => location.reload()).bind(this);
    const onComplete = (data => this.setState({ is_processing: false })).bind(
      this
    );
    const onError = (data => {
      data = data.responseJSON;
      let errors = [];

      for (let i = 0; i < data.errors.length; i++) {
        errors.push({ message: data.errors[i].detail });
      }

      this.setState({ card_errors: errors });
      this.setState({ is_processing: false });
    }).bind(this);

    $.post(url, data, success)
      .fail(onError)
      .done(onComplete);
  }

  handleSubmit() {
    this.setState({ is_processing: true });
    this.paymentForm.requestCardNonce();
  }

  render() {
    if (this.state.is_payment_success == true) {
      return <div id="successNotification">Card Charged Succesfully!!</div>;
    } else {
      var cardErrors = this.state.card_errors;
      var cardErrorNodes = [];
      for (var key in cardErrors) {
        cardErrorNodes.push(<li key={key}>{cardErrors[key].message}</li>);
      }

      return (
        <div>
          <ul id="card-errors">{cardErrorNodes}</ul>

          <Row className="show-grid">
            <Col xs={12} md={6}>
              <label>Card Number</label>
              <div id="sq-card-number" />
            </Col>
            <Col xs={12} md={6}>
              <label>CVV</label>
              <div id="sq-cvv" />
            </Col>
          </Row>

          <Row className="show-grid">
            <Col xs={6}>
              <label>Expiration Date</label>
              <div id="sq-expiration-date" />
            </Col>
            <Col xs={6}>
              <label>Postal Code</label>
              <div id="sq-postal-code" />
            </Col>
          </Row>

          <hr />

          <div>
            <Button
              type="submit"
              id="submit"
              onClick={this.handleSubmit.bind(this)}
              disabled={this.state.is_processing}
              style={{ margin: '0 auto', display: 'block' }}
            >
              Pay
            </Button>
          </div>
        </div>
      );
    }
  }
}

SquareCheckout.propTypes = {
  invoiceId: PropTypes.number,
  applicationId: PropTypes.string,
  locationId: PropTypes.string,
};

export default SquareCheckout;
