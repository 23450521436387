import React from 'react';
import { Button, Modal } from '~ui';
import styles from './PleaseResubscribeModal.module.scss';

const PleaseResubscribeModal = ({ handleHide, handleClick }) => {
  return (
    <Modal title="Please Resubscribe" handleHide={handleHide}>
      <div className={styles['PleaseResubscribe--content']}>
        <p>
          Our records note that you have recently canceled your subscription.
        </p>
        <p>We would love to have you back!</p>
        <p>Please resubscribe to access this feature.</p>
      </div>
      <div className={styles['PleaseResubscribe--footer']}>
        <Button danger text="Cancel" onClick={handleHide} />
        <Button text="Resubscribe" onClick={handleClick} />
      </div>
    </Modal>
  );
};

export default PleaseResubscribeModal;
