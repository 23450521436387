import React, { useState } from 'react';
import { useEffect } from 'react';
import { Button, Modal, Dropdown } from '~ui';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import axios from 'axios';
import styles from './AddToGroupModal.module.scss';

const AddToGroupModal = ({ handleHide, clientId }) => {
  const [groupList, setGroupList] = useState([]);
  const [clientGroups, setClientGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(undefined);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getModalData = async () => {
    setIsLoading(true);
    const res = await axios.get('/api/groups', {
      params: { grab_all_groups: true },
    });
    const rawGroupList = res.data.data.map(group => {
      return { label: group.attributes.name, value: group.attributes.id };
    });
    rawGroupList.unshift({ label: 'Select group', value: '' });

    const clientsGroups = await axios.get(
      `/api/group_clients/grab_client_groups?client_id=${clientId}`
    );

    const clientGroupList = clientsGroups.data.map(group => {
      return { name: group.name, id: group.group_id };
    });

    if (clientGroupList.length > 0) setClientGroups(clientGroupList);
    if (clientGroupList.length === 0) setClientGroups([]);
    setGroupList(rawGroupList);
    setIsLoading(false);
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);

      let params = {
        group: {
          id: selectedGroup,
          group_clients_attributes: [{ client_id: Number(clientId) }],
        },
      };

      await axios.put(`/api/groups/${selectedGroup}`, params);
      setLoading(false);
      toast.success(`Added to group!`, toastOptions);
    } catch (error) {
      toast.error(`Error adding to group!`, toastOptions);
    }
    handleHide();
  };

  const checkDisabled = groupID => {
    const disable = clientGroups.some(group => group.id == groupID);
    setDisabled(disable);
  };

  useEffect(() => {
    getModalData();
  }, []);

  useEffect(() => {
    if (selectedGroup) {
      checkDisabled(selectedGroup);
    }
  }, [selectedGroup]);

  return (
    <Modal title="Add to Group" handleHide={handleHide} maxWidth={500}>
      {isLoading ? (
        <h1>Loading...</h1>
      ) : (
        <div>
          <div className={styles.AddToGroupModal}>
            <Dropdown
              name="Group Name"
              initialValue={''}
              onChangeCallback={e => {
                setSelectedGroup(Number(e));
              }}
              options={groupList}
            />
          </div>

          {clientGroups.length > 0 && (
            <div>
              <br />
              This client belongs to the following group(s):
              <br />
              <ul>
                {clientGroups.map((group, index) => (
                  <li key={index}>{group.name}</li>
                ))}
              </ul>
            </div>
          )}
          <br />
          <div className="d-fr">
            <Button
              className="mr-8"
              text="Cancel"
              onClick={e => {
                e.stopPropagation();
                handleHide();
              }}
              whiteRectangle={true}
            />
            <Button
              text="Add to Group"
              onClick={e => {
                e.stopPropagation();
                handleUpdate();
              }}
              loading={loading}
              purpleRectangle={true}
              disabled={disabled}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AddToGroupModal;
