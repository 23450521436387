import { createSelector } from 'reselect';
import moment from 'moment';

export const calendarEventsSelector = state => state.calendar.events;
export const getMergedCalendarEventsByDate = createSelector(
  calendarEventsSelector,
  (_, date) => date,
  (calendarEvents, date) => {
    const events = calendarEvents[date];
    if (events) {
      return Object.keys(events).reduce((acc, key) => {
        acc.push(...events[key]);

        return acc;
      }, []);
    }
    return [];
  }
);

export const multiWeekEventsSelectorMonthView = createSelector(
  calendarEventsSelector,
  calendarEvents => {
    // Combine every all day event.
    let reducedEvents = Object.keys(calendarEvents).reduce((acc, key) => {
      // Filter out external events and placeholders.
      const validEvents = calendarEvents[key]['all_day'].filter(
        event => !event.type && !event.event_uid
      );
      acc.push(...validEvents);

      return acc;
    }, []);
    let events = {};

    // Use combined list to identify which events occur across weeks.
    reducedEvents.map(event => {
      const start = moment(event.start_time);
      const end = moment(event.end_time);
      let day = start.clone().add(1, 'day');

      if (end.diff(start, 'days') > 1) {
        while (!day.isSame(end, 'day')) {
          const formattedDate = day.clone().format('YYYY-MM-DD');

          if (!events[formattedDate]) {
            events[formattedDate] = [];
          }

          // If the day is a Sunday, then we've started another week.
          if (day.day() === 0) {
            events[formattedDate].push(event);
          } else {
            events[formattedDate].push({
              type: 'placeholder',
              ...event,
            });
          }

          day.add(1, 'day');
        }
      }
    });

    return events;
  }
);

export const multiWeekEventsSelectorWeekView = createSelector(
  calendarEventsSelector,
  calendarEvents => {
    // Combine every all day event.
    let reducedEvents = Object.keys(calendarEvents).reduce((acc, key) => {
      // Filter out external events and placeholders.
      const validEvents = calendarEvents[key]['all_day'].filter(
        event => !event.type && !event.event_uid
      );
      acc.push(...validEvents);

      return acc;
    }, []);
    let events = {};

    // Use combined list to identify which events occur across weeks.
    reducedEvents.map(event => {
      const start = moment(event.start_time);
      const end = moment(event.end_time);
      const isOvernight = end.clone().startOf('day').isBetween(start, end);
      let day = start.clone().add(1, 'hour');

      if (end.diff(start, 'hours') > 24 || isOvernight) {
        while (!day.isSame(end, 'hour')) {
          const formattedDate = day.clone().format('YYYY-MM-DD');

          if (!events[formattedDate]) {
            events[formattedDate] = [];
          }

          // If the day is a Sunday, then we've started another week.
          if (
            day.day() === 0 &&
            !events[formattedDate].find(ev => ev.id == event.id)
          ) {
            events[formattedDate].push({ ...event, isOvernight });
          }

          day.add(1, 'hour');
        }
      }
    });

    return events;
  }
);

const availabilitiesSelector = state => state.bookingCalendar.availabilities;

export const getMergedAvailabilities = createSelector(
  availabilitiesSelector,
  availabilities => {
    if (Array.isArray(availabilities)) {
      return availabilities;
    }

    return Object.keys(availabilities).reduce((acc, key) => {
      Object.keys(availabilities[key]).forEach(hour => {
        acc.push(...availabilities[key][hour]);
        return;
      });

      return acc;
    }, []);
  }
);

export const multiWeekAvailabilitiesSelector = createSelector(
  availabilitiesSelector,
  availabilities => {
    // Combine every all day event.
    let reducedAvailabilities = Object.keys(availabilities).reduce(
      (acc, key) => {
        acc.push(...availabilities[key]['all_day']);
        return acc;
      },
      []
    );
    let combinedAvailabilities = {};

    // Use combined list to identify which combinedAvailabilities occur across weeks.
    reducedAvailabilities.map(availability => {
      const start = moment(availability.start_time);
      const end = moment(availability.end_time);
      const isOvernight =
        end.clone().startOf('day').isBetween(start, end) ||
        end.clone().startOf('day').isSame(end);
      let day = start.clone().add(1, 'hour');

      if (end.diff(start, 'hours') > 24 || isOvernight) {
        while (!day.isSame(end, 'hour')) {
          const formattedDate = day.clone().format('YYYY-MM-DD');

          if (!combinedAvailabilities[formattedDate]) {
            combinedAvailabilities[formattedDate] = [];
          }

          // If the day is a Sunday, then we've started another week.
          if (
            day.day() === 0 &&
            !combinedAvailabilities[formattedDate].find(
              ev => ev.id == availability.id
            )
          ) {
            combinedAvailabilities[formattedDate].push({
              ...availability,
              isOvernight,
            });
          }

          day.add(1, 'hour');
        }
      }
    });

    return combinedAvailabilities;
  }
);

/*
  Form building selectors. Pulls relevant studio from state.
*/
export const clientsSelector = state => state.calendar.clients;
export const locationsSelector = state => state.calendar.locations;
export const sessionTypesSelector = state => state.calendar.sessionTypes;
export const shootProofBrandsSelector = state =>
  state.calendar.shootProofBrands;
export const journeyTemplatesSelector = state =>
  state.calendar.journeyTemplates;
export const selectedDaySelector = state => state.calendar.selectedDay;
export const intervalSelector = state => state.calendar.interval;

/*
Request helper selectors. Determines whether Redux has dispatched a loading or 
error action. 
*/
export const loadingSelector = state => state.loading;
export const actionsLoadingSelector = createSelector(
  loadingSelector,
  (_, actions) => actions,
  (loading, actions) => actions.some(action => loading[action])
);

export const errorsSelector = state => state.errors;
export const actionsErroredSelector = createSelector(
  errorsSelector,
  (_, actions) => actions,
  (errors, actions) =>
    actions.reduce((acc, action) => {
      if (errors[action]) {
        acc[action] = errors[action];
      }

      return acc;
    }, {})
);
