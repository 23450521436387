import React from 'react';
import moment from 'moment-timezone';
import ToggleContent from '~components/common/ToggleContent';
import CalendarCell from '../CalendarCell';
import CalendarEventForm from '../CalendarEventForm';
import styles from '../CalendarContent/CalendarContent.module.scss';

const MonthView = ({ dateContext, latitude, longitude }) => {
  const currentDate = moment();
  const dateContextClone = { ...dateContext };
  const startDate = moment(dateContextClone).startOf('week');
  const days = [...Array(7).keys()].map(i => {
    let weekday = moment(startDate).format('dd');
    startDate.add(1, 'day');
    return (
      <div key={`weekday-${i}`} className={styles['CalendarContent-weekday']}>
        {weekday}
      </div>
    );
  });

  const renderCells = () => {
    const mutableDateContext = { ...dateContext };
    const monthStart = moment(mutableDateContext).startOf('month');
    const monthEnd = moment(mutableDateContext).endOf('month');
    let startDate = moment(monthStart).startOf('week');
    let endDate = moment(monthEnd).endOf('week');
    let baseMoment = moment();
    let days = [];
    let rows = [];

    // We need to loop until the end of the month.
    while (startDate <= endDate) {
      // Each week is 7 days, so we go up to 7 in this loop to construct a row.
      for (let i = 0; i < 7; i++) {
        let current = currentDate.isSame(startDate, 'day');
        let dayClone = moment(startDate).clone();

        // When we use startOf and endOf, the hour gets set to midnight
        // so we need to set the hour and minute again.
        dayClone.set({
          hour: baseMoment.get('hour'),
          minute: baseMoment.get('minute'),
        });

        days.push(
          <ToggleContent
            key={dayClone}
            toggle={handleShow => (
              <CalendarCell
                day={dayClone}
                current={current}
                onDoubleClick={handleShow}
                latitude={latitude}
                longitude={longitude}
              />
            )}
            content={handleHide => (
              <CalendarEventForm
                day={Number(dayClone.format('x'))}
                handleHide={handleHide}
                dateContext={dateContext}
              />
            )}
          />
        );
        startDate = moment(startDate).add(1, 'day');
      }

      // Push at least 4 rows into the calendar. This will be at least 4 rows worth of days
      // for the month.
      rows.push(
        <div className={styles['CalendarContent-row']} key={`row-${startDate}`}>
          {days}
        </div>
      );

      days = []; // Clear out the days array so we can start the next row.
    }

    return <>{rows}</>;
  };

  return (
    <>
      <div className={styles['CalendarContent-weekdays']}>{days}</div>
      {renderCells()}
    </>
  );
};

export default MonthView;
