import React, { useEffect, useState } from 'react';
import { Modal, Button, TextInput, Dropdown, SearchInput } from '~ui';
import styles from './ManageGroupModal.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { routes } from '~constants/routes';
import { getGroups } from '~actions/clientList';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';

const ManageGroupModal = props => {
  const dispatch = useDispatch();

  const { handleHide, groupData = {}, updateModal = false } = props;

  const { default_group_types, sessionTypes, newClose } = useSelector(
    state => state.clientList
  );
  const [sessionTypeMap, setSessionTypeMap] = useState({});

  useEffect(() => {
    const map = sessionTypes.reduce((acc, sessionType) => {
      acc[sessionType.value] = sessionType.label;
      return acc;
    }, {});

    setSessionTypeMap(map);
  }, [sessionTypes]);

  const [firstDivSelected, setFirstDivSelected] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValues, setInputValues] = useState({
    name: groupData.groupName || '',
    category: groupData.category || 'Boudoir',
    currentClientList: [],
    displayedCurrentClients: [],
    removedClientList: [],
    sessionTypes: groupData.sessionTypes || [],
    removedSessionTypes: [],
  });

  const [newSessionTypes, setNewSessionTypes] = useState([]);
  const [selectedSessionTypeID, setSelectedSessionTypeID] = useState();
  const [selectedClient, setSelectedClient] = useState(null);
  const [searchResultsList, setSearchResultsList] = useState([]);
  const [newClients, setNewClients] = useState([]);
  const [clientMap, setClientMap] = useState({});

  const defaultGroupCategories = default_group_types.map(group => {
    return { label: group, value: group };
  });

  const handleUpdate = async () => {
    try {
      setIsLoading(true);
      const newClientsWithFormat = newClients.map(client => {
        return { client_id: Number(client.id) };
      });

      const removedClientsWithFormat = inputValues.removedClientList.map(
        client => {
          return {
            id: client.group_client_id,
            client_id: client.id,
            _destroy: true,
          };
        }
      );

      const newSessionTypesWithFormat = newSessionTypes.map(sessionTypeID => {
        return {
          session_type_id: Number(sessionTypeID),
        };
      });

      const removedSessionTypesWithFormat = inputValues.removedSessionTypes.map(
        sessionType => {
          return {
            id: sessionType.session_types_joining_group_id,
            session_type_id: sessionType.id,
            _destroy: true,
          };
        }
      );

      const combinedClientArray = [
        ...newClientsWithFormat,
        ...removedClientsWithFormat,
      ];

      const combinedSessionTypeArray = [
        ...newSessionTypesWithFormat,
        ...removedSessionTypesWithFormat,
      ];

      let params = {
        group: {
          id: groupData.id,
          name: inputValues.name,
          group_type: inputValues.category,
          group_clients_attributes: combinedClientArray,
          session_types_joining_groups_attributes: combinedSessionTypeArray,
        },
      };

      await axios.put(`/api/groups/${groupData.id}`, params);
      setIsLoading(false);
      await dispatch(getGroups());
      toast.success(`Update success!`, toastOptions);
    } catch (error) {
      toast.error(
        `Error updating group! Please ensure the name is unique.`,
        toastOptions
      );
    }
    handleHide();
  };

  const handleCreate = async () => {
    try {
      setIsLoading(true);
      const newClientsWithFormat = newClients.map(client => {
        return { client_id: Number(client.id) };
      });

      const newSessionTypesWithFormat = newSessionTypes.map(sessionTypeID => {
        return {
          session_type_id: Number(sessionTypeID),
        };
      });

      let params = {
        group: {
          name: inputValues.name,
          group_type: inputValues.category,
          group_clients_attributes: newClientsWithFormat,
          session_types_joining_groups_attributes: newSessionTypesWithFormat,
        },
      };

      await axios.post(`/api/groups`, params);
      setIsLoading(false);
      await dispatch(getGroups());
      toast.success(`Create success!`, toastOptions);
    } catch (error) {
      toast.error(
        `Error creating group! Please ensure the name is unique.`,
        toastOptions
      );
    }
    handleHide();
  };

  const grabCurrentClients = async () => {
    const res = await axios.get(
      `/api/group_clients/grab_group_clients?group_id=${groupData.id}`
    );
    setInputValues({
      ...inputValues,
      currentClientList: res.data,
      displayedCurrentClients: res.data,
    });
    const clientMap = res.data.reduce((map, client) => {
      map[client.id] = client;
      return map;
    }, {});
    setClientMap(clientMap);
  };

  const grabClients = async searchTerm => {
    if (searchTerm == '') {
      setSearchResultsList([]);
      return;
    }

    let params = {
      search_term: searchTerm,
    };

    const { data } = await axios.get(routes.CLIENTS.GET, { params });

    const formattedResults = data.data
      .filter(client => !clientMap[client.id])
      .map(client => {
        return { id: client.id, name: client.attributes.full_name };
      });
    setSearchResultsList(formattedResults);
  };

  const searchCurrentClients = searchTerm => {
    if (searchTerm === '') {
      const clientsToDisplay = inputValues.currentClientList.filter(client => {
        return !inputValues.removedClientList.some(
          removedClient => removedClient.id === client.id
        );
      });

      setInputValues({
        ...inputValues,
        displayedCurrentClients: clientsToDisplay,
      });

      return;
    }

    const filteredCurrentClients = inputValues.displayedCurrentClients.filter(
      client => {
        const fullName = client.full_name.toLowerCase();
        const searchTermLowerCase = searchTerm.toLowerCase();

        return (
          fullName.includes(searchTermLowerCase) &&
          !inputValues.removedClientList.some(
            removedClient => removedClient.id === client.id
          )
        );
      }
    );

    setInputValues({
      ...inputValues,
      displayedCurrentClients: filteredCurrentClients,
    });
  };

  const availableSessionTypes = () => {
    const groupSessionTypeIds = new Set(
      inputValues.sessionTypes.map(st => st.id)
    );

    const filteredSessionTypes = sessionTypes.filter(
      sessionType => !groupSessionTypeIds.has(sessionType.value)
    );

    return filteredSessionTypes;
  };

  const currentSessionTypes = () => {
    if (inputValues.sessionTypes.length >= 1) {
      return (
        <div className={styles.ItemList}>
          {inputValues.sessionTypes.map(sessionType => {
            return (
              <div key={sessionType.id} className={styles.Item}>
                <p>{sessionType.name}</p>
                <img
                  onClick={() => {
                    const updatedSessionTypes = inputValues.sessionTypes.filter(
                      st => st.id !== sessionType.id
                    );

                    setInputValues({
                      ...inputValues,
                      sessionTypes: updatedSessionTypes,
                      removedSessionTypes: [
                        ...inputValues.removedSessionTypes,
                        sessionType,
                      ],
                    });
                  }}
                  src={newClose}
                  height="16"
                  width="auto"
                />
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className={styles.ItemList}>
          <div className={styles.Item}>
            <p>None</p>
          </div>
        </div>
      );
    }
  };

  const currentClients = () => {
    if (inputValues.displayedCurrentClients.length >= 1) {
      return (
        <div className={styles.ItemList} style={{ marginTop: '10px' }}>
          {inputValues.displayedCurrentClients.map(client => {
            return (
              <div key={client.id} className={styles.NewItem}>
                <p>{client.full_name}</p>
                <img
                  onClick={() => {
                    const filteredOldClients = inputValues.displayedCurrentClients.filter(
                      clientItem => clientItem.id !== client.id
                    );
                    setInputValues({
                      ...inputValues,
                      displayedCurrentClients: filteredOldClients,
                      removedClientList: [
                        ...inputValues.removedClientList,
                        client,
                      ],
                    });
                  }}
                  src={newClose}
                  height="16"
                  width="auto"
                />
              </div>
            );
          })}
        </div>
      );
    } else {
      return renderNoContent();
    }
  };

  const searchResults = () => {
    if (searchResultsList.length >= 1) {
      return (
        <div className={styles.ItemList} style={{ marginTop: '10px' }}>
          {searchResultsList.map(client => {
            return (
              <div
                key={client.id}
                className={styles.SearchItem}
                tabIndex="1"
                onClick={() => setSelectedClient(client)}
              >
                <p>{client.name}</p>
              </div>
            );
          })}
        </div>
      );
    } else {
      return renderNoContent();
    }
  };

  const addedSessionTypes = () => {
    return (
      <div>
        {newSessionTypes.map(sessionTypeID => {
          return (
            <div key={sessionTypeID} className={styles.NewItem}>
              <p> {sessionTypeMap[sessionTypeID]}</p>
              <img
                onClick={() => {
                  const updatedSessionTypes = newSessionTypes.filter(
                    id => id !== sessionTypeID
                  );
                  setNewSessionTypes(updatedSessionTypes);
                }}
                src={newClose}
                height="16"
                width="auto"
              />
            </div>
          );
        })}
      </div>
    );
  };

  const newlyAddedClients = () => {
    if (newClients.length >= 1) {
      return (
        <div className={styles.ItemList}>
          {newClients.map(client => {
            return (
              <div key={client.id} className={styles.NewItem}>
                <p>{client.name}</p>
                <img
                  onClick={() => {
                    const filteredNewClients = newClients.filter(
                      clientItem => clientItem.id !== client.id
                    );
                    setNewClients(filteredNewClients);
                  }}
                  src={newClose}
                  height="16"
                  width="auto"
                />
              </div>
            );
          })}
        </div>
      );
    } else {
      return renderNoContent();
    }
  };

  const renderNoContent = () => {
    return (
      <div className={styles.ItemList} style={{ marginTop: '10px' }}>
        <div className={styles.Item}>
          <p>None</p>
        </div>
      </div>
    );
  };

  const renderToggleButton = (isSelected, text, onClick) => {
    return (
      <div
        onClick={onClick}
        style={isSelected ? { backgroundColor: '#7EC0E5' } : null}
      >
        <p style={isSelected ? { color: 'white' } : null}>{text}</p>
      </div>
    );
  };

  const renderModalBody = () => {
    if (firstDivSelected) {
      return (
        <div>
          <TextInput
            name="group name"
            labelText="Name"
            initialValue={inputValues.name || ''}
            onChangeCallback={name => setInputValues({ ...inputValues, name })}
            required
          />
          <br />
          <Dropdown
            labelText="Category"
            name="Category"
            initialValue={inputValues.category || ''}
            options={defaultGroupCategories}
            onChangeCallback={category =>
              setInputValues({ ...inputValues, category })
            }
          />
          <br />
          <div>
            <p>
              <strong>Current Session Types</strong>
            </p>
            <div>{currentSessionTypes()}</div>
          </div>
          <br />
          <div>
            <p>
              <strong>Auto Add Sessions</strong>
            </p>
            <div className={styles.NewAdditionBox}>
              <div>{addedSessionTypes()}</div>
              <Dropdown
                labelText=""
                name="current session types"
                initialValue={0}
                options={availableSessionTypes()}
                onChangeCallback={sessionType => {
                  setSelectedSessionTypeID(sessionType);
                }}
              />
            </div>
            <p
              style={{ color: '#7EC0E5', cursor: 'pointer' }}
              onClick={() => {
                if (
                  !newSessionTypes.includes(selectedSessionTypeID) &&
                  selectedSessionTypeID != 0 &&
                  selectedSessionTypeID != undefined
                ) {
                  setNewSessionTypes([
                    ...newSessionTypes,
                    selectedSessionTypeID,
                  ]);
                }
              }}
            >
              + Add Session
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div>
            <p>
              <strong>Current Client List</strong>
            </p>
            <div className={styles.NewAdditionBox}>
              <SearchInput
                name={'search current client list'}
                onChangeCallback={e => searchCurrentClients(e)}
                placeholder={'Search current client list'}
              />
              <div>{currentClients()}</div>
            </div>
          </div>
          <br />
          <div>
            <p>
              <strong>Newly Added Clients</strong>
            </p>
            <div>{newlyAddedClients()}</div>
          </div>
          <br />
          <div>
            <p>
              <strong>Add Clients</strong>
            </p>
            <div className={styles.NewAdditionBox}>
              <SearchInput
                name={'search for new client'}
                onChangeCallback={e => grabClients(e)}
                placeholder={'Search for new clients'}
              />
              {searchResults()}
            </div>
            <p
              style={{ color: '#7EC0E5', cursor: 'pointer' }}
              onClick={() => {
                if (
                  selectedClient != null &&
                  !newClients.some(client => client.id === selectedClient.id)
                ) {
                  setNewClients([...newClients, selectedClient]);
                }
              }}
            >
              + Add Client
            </p>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    renderModalBody();
  }, [firstDivSelected]);

  useEffect(() => {
    if (updateModal) {
      grabCurrentClients();
    }
  }, []);

  return (
    <Modal title={`Manage Group`} handleHide={handleHide} maxWidth={500}>
      <div className={styles.ManageGroupModal}>
        <div className={styles.Toggle}>
          {renderToggleButton(firstDivSelected, 'Manage Details', () =>
            setFirstDivSelected(true)
          )}
          {renderToggleButton(!firstDivSelected, 'Manage List', () =>
            setFirstDivSelected(false)
          )}
        </div>
        <br />
        {renderModalBody()}
        <div className="d-fr">
          <Button
            className="mr-8"
            text="Cancel"
            onClick={e => {
              e.stopPropagation();
              handleHide();
            }}
            whiteRectangle={true}
          />
          <Button
            text="Save"
            onClick={e => {
              e.stopPropagation();
              if (updateModal) {
                handleUpdate();
              } else {
                handleCreate();
              }
            }}
            loading={isLoading}
            purpleRectangle={true}
            disabled={inputValues.name === ''}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ManageGroupModal;
