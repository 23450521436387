import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TableNew as Table,
  DeleteConfirmation,
  Pill,
  Modal,
  Button,
  Icon,
} from '~ui';
import { routes, absolutePath } from '~constants/routes';
import styles from '../../../Documents/DocumentsContent/DocumentsContent.module.scss';
import axios from 'axios';
import {
  getQuestionnaireResponses,
  updateQuestionnairesMeta,
} from '~actions/questionnaires';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { MOBILE_WIDTH } from '~constants/viewports';
import { isMobile } from '~constants/userAgent';
import { useWindowSize } from '~hooks';
import SendLimitReached from '../../../common/SendLimitReached';

const QuestionnairesResponsesTable = ({ clientId }) => {
  const { data, meta } = useSelector(state => state.documents.questionnaires);

  const [rows, setRows] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [idForDelete, setIdForDelete] = useState(null);

  const dispatch = useDispatch();

  const ITEM_HEADERS = ['Name', 'Last Updated', 'Status'];

  const getStatusPill = submittedAt => {
    return submittedAt
      ? {
          pill: <Pill text="Completed" color="green" />,
          status: 'submitted',
        }
      : {
          pill: <Pill text="Sent" color="orange" />,
          status: 'sent',
        };
  };

  const createRowItem = ({ id, name, sent, submittedAt, uuid, clientName }) => {
    return {
      id,
      editable: getStatusPill(submittedAt).status == 'sent',
      deletable: true,
      temp: false,
      deleteButtonOverride: () => {
        setIdForDelete(id);
        setShowDeleteModal(true);
      },
      editButtonOverride: () => {
        window.open(absolutePath(`/customer/form/${uuid}`), '_blank');
      },
      onClickCallback: () => {
        window.open(absolutePath(`/customer/form/${uuid}`), '_blank');
      },
      buttonGroup: (
        <QuestionnaireButtons
          status={getStatusPill(submittedAt).status}
          uuid={uuid}
          id={id}
          clientName={clientName}
          clientId={clientId}
        />
      ),
      data: {
        name: {
          value: name,
          type: 'enum',
          editable: false,
        },
        sent: {
          value:
            getStatusPill(submittedAt).status == 'sent'
              ? `Sent on: ${moment(sent).format('MM/DD/YYYY')}`
              : `Completed on: ${moment(submittedAt).format('MM/DD/YYYY')}`,
          type: 'enum',
          editable: false,
        },
        status: {
          value: getStatusPill(submittedAt).pill,
          type: 'enum',
          editable: false,
        },
      },
    };
  };

  useEffect(() => {
    const newRows = data.map(qResponse => {
      return createRowItem({
        id: qResponse.attributes.id,
        name: qResponse.attributes.name,
        sent: qResponse.attributes.created_at,
        submittedAt: qResponse.attributes.submitted_at,
        uuid: qResponse.attributes.uuid,
        clientName: qResponse.attributes.client_name,
      });
    });

    setRows(newRows);
  }, [data]);

  const handleQuestionnaireDelete = async id => {
    try {
      await axios.delete(routes.QUESTIONNAIRE_RESPONSES.DELETE(id));
      dispatch(getQuestionnaireResponses(clientId));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div>
        <Table
          stylesForButtonsAndIcons={styles.QuestionnairesResponsesTable}
          readOnly
          headers={ITEM_HEADERS}
          rows={rows}
          setRows={setRows}
          page={meta.page}
          pageCount={meta.page_count}
          totalRecords={meta.total_records}
          pageChangeCallback={newPageIndex => {
            dispatch(
              updateQuestionnairesMeta({
                page: newPageIndex,
              })
            );
            dispatch(getQuestionnaireResponses(clientId));
          }}
        />
      </div>
      {showDeleteModal && (
        <DeleteConfirmation
          handleHide={() => setShowDeleteModal(false)}
          handleDelete={() => handleQuestionnaireDelete(idForDelete)}
          resource="Questionnaire"
        />
      )}
    </>
  );
};

const QuestionnaireButtons = ({ status, uuid, id, clientName, clientId }) => {
  const { activeTrial, studioId, trialVerified } = useSelector(
    state => state.documents
  );
  const [showBeforeSend, setShowBeforeSend] = useState(false);
  const [miniLoading, setMiniLoading] = useState(false);
  const [showResendModal, setShowResendModal] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const windowWidth = useWindowSize()[0];
  const mobileScreen = windowWidth <= MOBILE_WIDTH || isMobile();
  const dispatch = useDispatch();

  const checkValidation = async () => {
    if (activeTrial && !trialVerified) {
      setMiniLoading(true);

      const res = await axios.get(
        routes.STUDIO_PROFILE.CHECK_SEND_LIMIT(studioId)
      );
      if (res.data.reached_send_limit) {
        setMiniLoading(false);

        setShowBeforeSend(true);
      } else {
        setMiniLoading(false);

        setShowResendModal(true);
      }
    } else {
      setShowResendModal(true);
    }
  };

  const handleResend = async () => {
    try {
      setResendLoading(true);
      await axios.put(routes.QUESTIONNAIRE_RESPONSES.RESEND(id));
      setShowResendModal(false);
      dispatch(getQuestionnaireResponses(clientId));
      toast.success('Questionnaire Sent!', toastOptions);
    } catch (error) {
      setResendLoading(false);
      console.error(error);
      if (error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Error Sending the Questionnaire', toastOptions);
      }
    }
  };
  return (
    <>
      {status == 'submitted' ? (
        <a
          href={absolutePath(`/clients/${clientId}/form/${id}.pdf`)}
          target="_blank"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {mobileScreen ? 'Print' : 'Print PDF'}
        </a>
      ) : null}
      {status == 'sent' && (
        <a
          onClick={e => {
            e.stopPropagation();
            checkValidation();
          }}
        >
          Resend
        </a>
      )}
      {showResendModal && (
        <Modal
          title="Resend Questionnaire"
          handleHide={() => setShowResendModal(false)}
        >
          <div>
            <div>
              Are you sure you'd like to resend this questionnaire to{' '}
              {clientName}?
            </div>
            <div className={styles.DocumentModalButtonContainer}>
              <Button
                text="Cancel"
                danger
                onClick={() => setShowResendModal(false)}
              />
              <Button
                text="Send"
                onClick={handleResend}
                disabled={resendLoading}
              />
            </div>
          </div>
        </Modal>
      )}
      {miniLoading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingText}>
            <p>Loading...</p>
            <Icon name="spinner" className="fa-pulse" large />
          </div>
        </div>
      )}
      {showBeforeSend && (
        <SendLimitReached handleHide={() => setShowBeforeSend(false)} />
      )}
    </>
  );
};

export default QuestionnairesResponsesTable;
