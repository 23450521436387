import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { restoreAvailability, emailStudio } from './bookingHelpers';
import { CUSTOMER_BOOKING } from '~constants/actionTypes';
import { useBeforeunload } from 'react-beforeunload';

const withBackHandler = WrappedComponent => props => {
  const { BOOKING_PATHS, uuid } = useSelector(state => state.customerBooking);
  const history = useHistory();
  const dispatch = useDispatch();

  const client = useSelector(state => state.customerBooking.clientInfo);
  const invoice = useSelector(state => state.customerBooking.invoice);
  const contract = useSelector(state => state.customerBooking.contract);
  const selectedAvailability = useSelector(
    state => state.customerBooking.selectedAvailability
  );
  const token = useSelector(state => state.customerBooking.token);

  useBeforeunload(e => {
    e.preventDefault();
    emailStudio(client, selectedAvailability, invoice, contract, uuid, "refresh");
    restoreAvailability(selectedAvailability, token);
  });

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        const cameFromConfirmRoute = window.sessionStorage.getItem(
          'cameFromConfirmRoute'
        );
        !cameFromConfirmRoute &&
          emailStudio(client, selectedAvailability, invoice, contract, uuid, "back");
        !cameFromConfirmRoute &&
          restoreAvailability(selectedAvailability, token);
        cameFromConfirmRoute &&
          window.sessionStorage.removeItem('cameFromConfirmRoute');
        dispatch({ type: CUSTOMER_BOOKING.RESET });
        history.push(BOOKING_PATHS.root(uuid));
      }
    };
  }, [history, selectedAvailability, client, contract, invoice]);

  return <WrappedComponent {...props} />;
};

export default withBackHandler;
