import axios from 'axios';
import { routes } from '~constants/routes';
import { INVOICE_PRODUCTS } from '~constants/actionTypes';

export const getProducts = () => async dispatch => {
  try {
    dispatch({ type: INVOICE_PRODUCTS.GET.REQUEST });

    const products = await axios
      .get(routes.INVOICE_PRODUCTS.GET)
      .then(res => res.data);

    dispatch({
      type: INVOICE_PRODUCTS.GET.SUCCESS,
      payload: products,
    });
  } catch (error) {
    dispatch({
      type: INVOICE_PRODUCTS.GET.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};

export const createProduct = (params, setProduct) => async dispatch => {
  try {
    dispatch({ type: INVOICE_PRODUCTS.CREATE.REQUEST });

    const product = await axios
      .post(routes.INVOICE_PRODUCTS.CREATE, params)
      .then(res => res.data.data.attributes);

    dispatch({
      type: INVOICE_PRODUCTS.CREATE.SUCCESS,
      payload: product,
    });

    setProduct(product);
  } catch (error) {
    dispatch({
      type: INVOICE_PRODUCTS.CREATE.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};

export const updateProduct = (productId, params) => async dispatch => {
  try {
    dispatch({ type: INVOICE_PRODUCTS.UPDATE.REQUEST });

    const product = await axios
      .put(routes.INVOICE_PRODUCTS.UPDATE(productId), params)
      .then(res => res.data.data.attributes);

    dispatch({
      type: INVOICE_PRODUCTS.UPDATE.SUCCESS,
      payload: product,
    });
  } catch (error) {
    dispatch({
      type: INVOICE_PRODUCTS.UPDATE.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};

export const deleteProduct = (productId, params) => async dispatch => {
  try {
    dispatch({ type: INVOICE_PRODUCTS.DELETE.REQUEST });

    const res = await axios.delete(routes.INVOICE_PRODUCTS.DELETE(productId), {
      data: params,
    });

    dispatch({
      type: INVOICE_PRODUCTS.DELETE.SUCCESS,
      payload: res.data.data.attributes,
    });
  } catch (error) {
    dispatch({
      type: INVOICE_PRODUCTS.DELETE.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};

export const checkLineItemTemplates = (
  productId = null,
  reset = false
) => async dispatch => {
  try {
    dispatch({ type: INVOICE_PRODUCTS.CHECK_LINE_ITEM_TEMPLATES.REQUEST });

    if (reset) {
      dispatch({
        type: INVOICE_PRODUCTS.CHECK_LINE_ITEM_TEMPLATES.SUCCESS,
        payload: false,
      });
    } else {
      const res = await axios.get(
        routes.INVOICE_PRODUCTS.CHECK_LINE_ITEM_TEMPLATES(productId)
      );

      dispatch({
        type: INVOICE_PRODUCTS.CHECK_LINE_ITEM_TEMPLATES.SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: INVOICE_PRODUCTS.CHECK_LINE_ITEM_TEMPLATES.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};
