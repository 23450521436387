import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from '~ui';
import { isMobile } from '~constants/userAgent';
import { useWindowSize } from '~hooks';
import { MOBILE_WIDTH } from '~constants/viewports';
import MobileLineItems from './MobileLineItems';

const LineItemsTable = ({ lineItems }) => {
  const windowWidth = useWindowSize()[0];
  const mobileScreen = isMobile() || windowWidth <= MOBILE_WIDTH;
  const normalizedLineItems = lineItems.map(item => ({
    invoice_type: item.invoice_type,
    description: item.description,
    price: item.price,
    quantity: item.quantity,
    total: item.quantity * item.price,
  }));

  const headers = [
    { name: 'invoice_type', labelText: 'Type', sortable: false },
    { name: 'description', labelText: 'Description', sortable: false },
    { name: 'price', labelText: 'Price', sortable: false },
    { name: 'quantity', labelText: 'Quantity', sortable: false },
    { name: 'total', labelText: 'Total', sortable: false },
  ];
  const columnTypes = ['string', 'string', 'currency', 'string', 'currency'];
  const title = 'Line Items';
  const subtitle = "Products and Services you're billing for";

  return (
    <>
      {mobileScreen ? (
        <MobileLineItems items={lineItems} title={title} subtitle={subtitle} />
      ) : (
        <DataTable
          title="Line Items"
          rows={normalizedLineItems}
          headers={headers}
          columnTypes={columnTypes}
          rowWidth="24%"
        />
      )}
    </>
  );
};

LineItemsTable.propTypes = {
  lineItems: PropTypes.array.isRequired,
};

export default LineItemsTable;
