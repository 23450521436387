import React from 'react';
import EmailSender from './email_sender';

class EmailSenderContainer extends React.PureComponent {
  render() {
    return <EmailSender {...this.props} />;
  }
}

export default EmailSenderContainer;
