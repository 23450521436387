import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

class ItemsList extends React.PureComponent {
  static propTypes = {
    addAttachment: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
  };

  prettyDate = object => {
    if (object.created_at) {
      return <Moment format="MMM D YYYY" date={object.created_at} />;
    }

    return this.prettyStatus(object);
  };

  prettyStatus = object => {
    if (object.error) {
      return <span className="label label-danger">{object.error}</span>;
    }

    return 'waiting...';
  };

  RenderItemListRow = (object, i) => {
    return (
      <tr
        className="add-attachment-document"
        key={i}
        onClick={() => this.props.addAttachment(object)}
      >
        <td>
          <span className="document-file-name" title={object.name}>
            {object.file.name}
          </span>
        </td>
        <td>{this.prettyDate(object)}</td>
      </tr>
    );
  };

  render() {
    const listItems = this.props.items.map(this.RenderItemListRow);

    return (
      <section>
        <h2>Files Added:</h2>
        <div id="add_attachment_documents">
          <table>
            <tbody>{listItems}</tbody>
          </table>
        </div>
      </section>
    );
  }
}

export default ItemsList;
