import React, { useState, useContext, useEffect } from 'react';
import { TableNew as Table } from '~ui';
import EmailMarketingContext from '../../EmailMarketingContext';
import moment from 'moment-timezone';
import { FULL_DATE_FORMAT } from '~constants/datetime';
import { CampaignInfoModal } from '../../Modals';
import styles from '../EmailMarketingTable.module.scss';

const CampaignsTable = () => {
  const {
    campaigns,
    getCampaigns,
    getCampaignDetails,
    campaignCommunications,
    campaignsMeta,
    cancelCampaign,
    timeZone,
    statisticsFetched,
    isLoading,
  } = useContext(EmailMarketingContext);
  const [rows, setRows] = useState([]);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [shownCampaign, setShownCampaign] = useState({});
  const [isFutureCampaign, setIsFutureCampaign] = useState(false);
  const [currentCampaignId, setCurrentCampaignId] = useState();

  const timezonesAreSame =
    moment().tz(moment.tz.guess()).format('z') ==
    moment().tz(timeZone).format('z');
  const formatString = timezonesAreSame ? 'hh:mm a' : 'hh:mm a (z)';

  const HEADERS = [
    'Campaign Name',
    'Description',
    'Send Date',
    'Open Rate',
    'Click Rate',
  ];

  useEffect(() => {
    if (statisticsFetched) {
      getCampaigns();
    }
  }, [statisticsFetched]);

  useEffect(() => {
    if (campaigns.length > 0) {
      const newRows = campaigns.map(campaign => createRowItem({
        id: campaign.attributes.id,
        emailCampaignName: campaign.attributes.name || campaign.attributes.email_campaign_template.data.attributes.name,
        emailCampaignDescription: campaign.attributes.description || campaign.attributes.email_campaign_template.data.attributes.description,
        emailCampaignSubject: campaign.attributes.subject || campaign.attributes.email_campaign_template.data.attributes.steps.data[0].attributes.email_template.email_subject,
        sentAt: moment(campaign.attributes.started_at).isValid()
          ? moment(campaign.attributes.started_at).format(FULL_DATE_FORMAT)
          : 'Not yet available',
        openRate: campaign.attributes.open_rate !== null ? `${campaign.attributes.open_rate}%` : 'Not yet available',
        clickRate: campaign.attributes.click_rate !== null ? `${campaign.attributes.click_rate}%` : 'Not yet available',
        campaign: campaign.attributes,
        scheduled_start_time: campaign.attributes.scheduled_start_time,
        completed_at: campaign.attributes.completed_at,
      }));
      setRows(newRows);
    }
  }, [campaigns]);

  const getSentColumnValue = (sentAt, completedAt, scheduledStartTime) => {
    if (scheduledStartTime && !completedAt) {
      return (
        <div>
          <i className="fas fa-clock"></i>{' '}
          {moment(scheduledStartTime).tz(timeZone).format(FULL_DATE_FORMAT)} @{' '}
          {moment(scheduledStartTime).tz(timeZone).format(formatString)}
        </div>
      );
    } else {
      return `Sent on: ${sentAt}`;
    }
  };

  const createRowItem = ({
    id,
    emailCampaignName,
    emailCampaignDescription,
    emailCampaignSubject,
    sentAt,
    openRate,
    clickRate,
    campaign,
    scheduled_start_time,
    completed_at,
  }) => ({
    id,
    editable: false,
    deletable: false,
    onClickCallback: () => {
      const futureCampaign = completed_at === null;
      setIsFutureCampaign(futureCampaign);
      if (futureCampaign) {
        setCurrentCampaignId(id);
      }
      setShownCampaign(campaign);
      setShowInfoModal(true);
    },
    data: {
      name: {
        value: () => (
          <div>
            <h2>{emailCampaignName}</h2>
            <h4>Subject: {emailCampaignSubject}</h4>
          </div>
        ),
        type: 'function',
        editable: false,
      },
      description: {
        value: emailCampaignDescription,
        type: 'enum',
        editable: false,
      },
      sentAt: {
        value: getSentColumnValue(sentAt, completed_at, scheduled_start_time),
        type: 'enum',
        editable: false,
      },
      openRate: {
        value: openRate,
        type: 'enum',
        editable: false,
      },
      clickRate: {
        value: clickRate,
        type: 'enum',
        editable: false,
      },
    },
  });

  return (
    <>
      {isLoading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingText}>
            <p>Loading...</p>
            <i className="fas fa-spinner fa-pulse" />
          </div>
        </div>
      )}
      {!isLoading && (
        <Table
          readOnly
          title="My Campaigns"
          headers={HEADERS}
          rows={rows}
          setRows={setRows}
          loading={false}
          page={campaignsMeta.page || 1}
          pageCount={campaignsMeta.page_count || 1}
          totalRecords={campaignsMeta.total_records || 0}
          pageSize={5}
          pageChangeCallback={getCampaigns}
        />
      )}
      {showInfoModal && (
        <CampaignInfoModal
          handleHide={() => setShowInfoModal(false)}
          campaign={shownCampaign}
          campaignCommunications={campaignCommunications}
          getCampaignDetails={getCampaignDetails}
          handleCancelCampaign={() => cancelCampaign(currentCampaignId)}
          isFutureCampaign={isFutureCampaign}
        />
      )}
    </>
  );
};

export default CampaignsTable;
