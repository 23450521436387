import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styles from './DraggableInput.module.scss';
import ItemTypes from '../../constants/ItemTypes';

const DraggableInput = ({
  children,
  index,
  handleRemove,
  reOrderHandler,
  title,
}) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.INPUT, id: index },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const ref = useRef();

  const [, drop] = useDrop({
    accept: ItemTypes.INPUT,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      reOrderHandler(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  const classes = isDragging ? 'grabbing' : 'grabbable';

  return (
    <div className={styles.DraggableInput} ref={ref}>
      <div className={styles['DraggableInput-top']}>
        <div className={styles['DraggableInput-top-title']}>{title}</div>
        <div>
          <i
            className={`fas fa-grip-horizontal ${
              styles[`DraggableInput-top-${classes}`]
            }`}
          />
        </div>
        <div>
          <i
            className="fa fa-times"
            style={{ cursor: 'pointer' }}
            onClick={handleRemove}
          />
        </div>
      </div>
      <div className={styles['DraggableInput-children']}>{children}</div>
    </div>
  );
};

export default DraggableInput;
