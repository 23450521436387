import React, { useState, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { keyCodes } from '~constants/keyCodes';
import { uniqueId } from '~utils';
import { Icon, KeydownListener } from '~ui';
import styles from './MenuButton.module.scss';

const MenuButton = ({ name, options, icon, logo, className }) => {
  const menuButtonRef = useRef();
  const optionsRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [indexOfFocusedOption, setIndexOfFocusedOption] = useState(null);

  const cx = classNames.bind(styles);
  const hasClassName = !!className;
  const classes = cx('MenuButton', {
    [className]: hasClassName,
  });

  const handleClickOutside = e => {
    if (
      (menuButtonRef.current && menuButtonRef.current.contains(e.target)) ||
      (optionsRef.current && optionsRef.current.contains(e.target))
    ) {
      return;
    }

    setIsOpen(false);
  };

  useLayoutEffect(() => {
    const menuOptionToFocus = options[indexOfFocusedOption];
    if (
      menuOptionToFocus &&
      menuOptionToFocus.ref &&
      menuOptionToFocus.ref.current
    ) {
      menuOptionToFocus.ref.current.focus();
    } else if (isFocused && menuButtonRef && menuButtonRef.current) {
      menuButtonRef.current.focus();
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [isOpen, indexOfFocusedOption, isFocused]);

  const menuOptionKeyDownListener = (e, menuOptionRef) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.keyCode === keyCodes.ENTER || e.keyCode === keyCodes.SPACE) {
      menuOptionRef.current.click();
    }
  };

  const focusPreviousOption = () => {
    if (indexOfFocusedOption === options.length - 1) {
      setIndexOfFocusedOption(0);
    } else {
      setIndexOfFocusedOption(indexOfFocusedOption + 1);
    }
  };

  const focusNextOption = () => {
    if (indexOfFocusedOption === 0) {
      setIndexOfFocusedOption(options.length - 1);
    } else {
      setIndexOfFocusedOption(indexOfFocusedOption - 1);
    }
  };

  const closeOptions = () => {
    setIsOpen(false);
    setIsFocused(true);
    setIndexOfFocusedOption(null);
  };

  const openOptions = optionToFocus => {
    setIsOpen(true);
    setIsFocused(false);
    setIndexOfFocusedOption(optionToFocus);
  };

  return (
    <>
      {isOpen && (
        <>
          <KeydownListener keyCode={keyCodes.ESCAPE} onKeyDown={closeOptions} />
          <KeydownListener
            keyCode={keyCodes.ARROW_DOWN}
            onKeyDown={focusPreviousOption}
          />
          <KeydownListener
            keyCode={keyCodes.ARROW_UP}
            onKeyDown={focusNextOption}
          />
        </>
      )}
      {!isOpen && isFocused && (
        <>
          <KeydownListener
            keyCode={keyCodes.ARROW_DOWN}
            onKeyDown={() => {
              openOptions(0);
            }}
          />
          <KeydownListener
            keyCode={keyCodes.ARROW_UP}
            onKeyDown={() => {
              openOptions(options.length - 1);
            }}
          />
        </>
      )}
      <div className={styles['MenuButtonContainer']}>
        <button
          id="menuButton"
          className={classes}
          ref={menuButtonRef}
          aria-label={name}
          aria-haspopup="true"
          aria-controls="options"
          aria-expanded={isOpen}
          onClick={() => {
            if (isOpen) {
              closeOptions();
            } else {
              openOptions(0);
            }
          }}
          onFocus={() => {
            setIsFocused(true);
          }}
        >
          {logo ? logo : <Icon name={icon || 'ellipsis-v'} large />}
        </button>
        {isOpen && (
          <ul
            id="options"
            ref={optionsRef}
            className={styles['MenuOptions']}
            role="menu"
            aria-labelledby="menuButton"
          >
            {options.map(({ label, onClickCallback, ref }) => (
              <li
                role="menuitem"
                key={uniqueId(`menuOption_`)}
                onClick={() => {
                  closeOptions();
                  onClickCallback();
                }}
                onKeyDown={e => {
                  menuOptionKeyDownListener(e, ref);
                }}
                ref={ref}
                tabIndex="-1"
              >
                {label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

MenuButton.propTypes = {
  icon: PropTypes.string,
  logo: PropTypes.object,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClickCallback: PropTypes.func.isRequired,
      ref: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        .isRequired,
    })
  ).isRequired,
};

MenuButton.defaultProps = {
  icon: undefined,
  logo: undefined,
};

export default MenuButton;
