import React from 'react';
import { Provider } from 'react-redux';
import AvailabilityCalendar from './index';
import { configureStore } from '../../store';

const AvailabilityCalendarRoot = React.memo(
  props => {
    const { isClient, ...restOfProps } = props;
    const store = configureStore(restOfProps);

    return (
      <Provider store={store}>
        <AvailabilityCalendar isClient={isClient} />
      </Provider>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.bookingCalendar.id === nextProps.bookingCalendar.id &&
      prevProps.calendar === nextProps.calendar &&
      prevProps.isClient === nextProps.isClient
    );
  }
);

export default AvailabilityCalendarRoot;
