import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { routes, absolutePath } from '~constants/routes';
import styles from './Chart.module.scss';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from 'recharts';
import { Icon } from '~ui';

const Chart = ({ currency }) => {
  const [charts, setCharts] = useState([]);
  const [chartData, setChartData] = useState();
  const [chartIndex, setChartIndex] = useState(0);
  const [chartsLoading, setChartsLoading] = useState();
  const [chartLoading, setChartLoading] = useState();

  const getCurrencySymbol = () => {
    switch (currency) {
      case 'GBP':
        return '£';
      case 'EUR':
        return '€';
      case 'NZD':
        return '$';
      default:
        return '$';
    }
  };

  const getCharts = async () => {
    try {
      setChartsLoading(true);
      const res = await axios.get(routes.GOALS_METRICS.INDEX);
      setCharts(res.data);
      setChartsLoading(false);
    } catch (error) {
      setChartsLoading(false);
      console.error(error);
    }
  };

  const getChartData = async chartId => {
    try {
      setChartLoading(true);
      const res = await axios.get(routes.GOALS_METRICS.GET_DATA(chartId));

      const data = res.data.metrics.data.map((d, index) => {
        return {
          name: res.data.metrics.labels[index][0],
          Actual: d,
          Goal: res.data.goal_metric.value,
        };
      });

      setChartData(data);
      setChartLoading(false);
    } catch (error) {
      setChartLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getCharts();
  }, []);

  useEffect(() => {
    if (charts.length > 0) {
      getChartData(charts[chartIndex].id);
    }
  }, [charts, chartIndex]);

  const handleNextChart = () => {
    if (chartIndex !== charts.length - 1) setChartIndex(chartIndex + 1);
  };

  const handlePrevChart = () => {
    if (chartIndex > 0) setChartIndex(chartIndex - 1);
  };

  const getChartTitle = () => {
    switch (charts[chartIndex].goal_type) {
      case 'Clients':
        return `New Clients Per ${getPeriodForGoalMetric(
          charts[chartIndex].goal_period
        )}`;
      case 'Leads':
        return `New Leads Per ${getPeriodForGoalMetric(
          charts[chartIndex].goal_period
        )}`;
      case 'Billing':
        return `Billable Dollars Per ${getPeriodForGoalMetric(
          charts[chartIndex].goal_period
        )}`;
      case 'Sessions':
        return `Completed Sessions Per ${getPeriodForGoalMetric(
          charts[chartIndex].goal_period
        )}`;
    }
  };

  const getPeriodForGoalMetric = goalPeriod => {
    switch (goalPeriod) {
      case 'Quarterly':
        return 'Quarter';
      case 'Monthly':
        return 'Month';
      case 'Daily':
        return 'Day';
      case 'Week':
        return 'Week';
      case 'Yearly':
        return 'Year';
      default:
        return '';
    }
  };

  const CustomTick = props => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          fontSize="10px"
          textAnchor="end"
          fill="#949695"
          transform="rotate(-60)"
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <div className={styles.Chart}>
      <h1>Just the Numbers</h1>

      {chartsLoading && (
        <div className={styles['Chart-loading']}>
          <Icon name="spinner" className="fa-pulse" large />
        </div>
      )}

      {chartData && (
        <>
          <div className={styles['Chart-arrows']}>
            <i
              onClick={handlePrevChart}
              className="fas fa-angle-left fa-lg"
            ></i>
            <h2>{getChartTitle()}</h2>
            <i
              onClick={handleNextChart}
              className="fas fa-angle-right fa-lg"
            ></i>
          </div>
          <div className={styles['Chart-lineChart']}>
            <LineChart
              width={425}
              height={350}
              data={chartData}
              margin={{
                top: 5,
                right: 50,
                left: 0,
                bottom: 5,
              }}
            >
              <CartesianGrid stroke="#ccc" />
              <XAxis
                interval={0}
                dataKey="name"
                height={85}
                tick={<CustomTick />}
              />
              <YAxis
                scale="linear"
                type="number"
                tickFormatter={arg => {
                  if (getChartTitle().split(' ').includes('Billable')) {
                    return `${getCurrencySymbol()}${arg}`;
                  } else {
                    return arg;
                  }
                }}
                domain={[
                  0,
                  Math.max.apply(
                    Math,
                    chartData.map(o => o.Goal)
                  ),
                ]}
                interval={0}
                tick={{ fontSize: 12, color: '#949695' }}
              />
              <Tooltip
                formatter={arg => {
                  if (getChartTitle().split(' ').includes('Billable')) {
                    return `${getCurrencySymbol()}${arg}`;
                  } else {
                    return arg;
                  }
                }}
              />
              <Legend />
              <Line
                type="monotone"
                dataKey="Actual"
                stroke="#81f9e2"
                activeDot={{ r: 8 }}
                strokeWidth={2}
              />
              <Line
                type="monotone"
                dataKey="Goal"
                stroke="#a78ce1"
                strokeWidth={2}
              />
            </LineChart>
          </div>
        </>
      )}
      {!chartsLoading && !chartLoading && !chartData && (
        <div className={styles['Chart-noChart']}>
          You have not created any metrics.
          <a href={absolutePath('/studio/goal/new')}>
            &nbsp;Click here to create one.
          </a>
        </div>
      )}
      <div className={styles['Chart-link']}>
        <a href={absolutePath('/studio/goal')}>
          View All <i className={'fas fa-arrow-right'}></i>
        </a>
      </div>
    </div>
  );
};

export default Chart;
