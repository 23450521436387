import React, { useEffect, useState } from 'react';
import { routes } from '~constants/routes';
import axios from 'axios';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { ChromePicker } from 'react-color';
import { Accordion, Toggle, TextArea, Button, Icon } from '~ui';
import { useDebounce, useIsFirstMount } from '~hooks';
import Table from '~ui/TableNew/Table';
import ImageUploading from 'react-images-uploading';
import TogglePopover from '~components/common/TogglePopover';
import styles from './BookingPageSettings.module.scss';
const BookingPageSettings = ({ bookingData, studio_id }) => {
  const {
    booking_message,
    booking_transparent_background,
    booking_display_logo,
    booking_submit_color,
    booking_photo_file_name,
  } = bookingData;

  const [showCurrentPhotoName, setShowCurrentPhotoName] = useState(
    booking_photo_file_name ? booking_photo_file_name : ''
  );
  const [showStudioLogo, setShowStudioLogo] = useState(booking_display_logo);
  const [settingsLoading, setSettingsLoading] = useState(false);
  const [transparentBackground, setTransparentBackground] = useState(
    booking_transparent_background
  );
  const [submitColor, setSubmitColor] = useState(
    booking_submit_color || '#111111'
  );
  const [bookingMessage, setBookingMessage] = useState(booking_message);
  const [headers, setHeaders] = useState(['']);
  const [currentPage, setCurrentPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [pageCount, setPageCount] = useState();
  const isFirstMount = useIsFirstMount();
  const [totalRecords, setTotalRecords] = useState(0);

  const debouncedColor = useDebounce(submitColor, 1500);
  const debouncedMessage = useDebounce(bookingMessage, 1500);

  useEffect(() => {
    if (!isFirstMount) {
      handleStudioSave({
        studio: {
          booking_submit_color: submitColor,
          booking_message: bookingMessage,
        },
      });
    }
  }, [debouncedColor, debouncedMessage]);

  useEffect(() => {
    if (rows.length === 0 && currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [rows.length]);

  useEffect(() => {
    getCalendars();
  }, [currentPage]);

  const getCalendars = async () => {
    try {
      const res = await axios.get(routes.BOOKING_CALENDARS.INDEX, {
        params: {
          page: currentPage,
          global: true,
        },
      });
      setTotalRecords(res.data.meta.total_records);
      setPageCount(res.data.meta.page_count);
      setCurrentPage(res.data.meta.page);

      const newRowArray = res.data.data.map(row => {
        return row.attributes;
      });
      setRows(createRowItems(newRowArray));
    } catch (error) {
      toast.error(
        'There was an error loading your booking calendars!',
        toastOptions
      );
      console.error(error);
    }
  };

  const handleStudioSave = async param => {
    try {
      const res = await axios.put(
        routes.STUDIO_PROFILE.UPDATE(studio_id),
        param
      );
      if (res.status === 200) {
        toast.success('Settings Saved', toastOptions);
      }
    } catch (error) {
      toast.error('There was an error saving your settings', toastOptions);
      console.error(error);
    }
  };

  const handleBookingCalendarSave = async param => {
    try {
      const res = await axios.put(
        routes.BOOKING_CALENDARS.UPDATE(param.id),
        param.booking_calendar
      );
      if (res.status === 202) {
        toast.success('Settings Saved', toastOptions);
      }
    } catch (error) {
      toast.error('There was an error saving your settings', toastOptions);
      console.error(error);
    }
  };

  const handleImageDelete = async () => {
    setShowCurrentPhotoName('');
    await handleStudioSave({
      studio: {
        booking_photo: null,
        booking_photo_file_name: null,
      },
    });
  };

  const handleImageUpload = async val => {
    const MAX_UPLOAD_MB = 20;
    const MAX_UPLOAD_BYTES = MAX_UPLOAD_MB * 1000000;

    if (val[0] && val[0].file.size <= MAX_UPLOAD_BYTES) {
      await handleStudioSave({
        studio: {
          booking_photo: val[0].data_url,
          booking_photo_file_name: val[0].file.name,
        },
      });
      setShowCurrentPhotoName(val[0].file.name);
    } else {
      toast.error('Photo file size must be less than 20 MB');
    }
  };

  const showDelete = () => {
    if (showCurrentPhotoName) {
      return !!showCurrentPhotoName;
    } else {
      return !!showCurrentPhotoName;
    }
  };

  const createRowItems = calendars => {
    const rowItems = calendars.map(calendar => {
      const { id, title, show_on_landing } = calendar;
      const rowItem = {
        id,
        editable: false,
        deletable: false,
        buttonGroup: (
          <>
            <Toggle
              initialValue={show_on_landing}
              onChangeCallback={val =>
                handleBookingCalendarSave({
                  booking_calendar: {
                    show_on_landing: val,
                  },
                  id,
                })
              }
            />
          </>
        ),
        data: {
          title: {
            value: () => <div>{title}</div>,
            type: 'function',
            editable: false,
            deletable: false,
          },
        },
      };
      return rowItem;
    });
    return rowItems;
  };
  return (
    <>
      {settingsLoading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingText}>
            <p>Loading...</p>
            <Icon name="spinner" className="fa-pulse" large />
          </div>
        </div>
      )}
      <div>
        <div>
          <br />
          <h1 className={styles['BookingPageSettings-header']}>
            Booking Landing Page
          </h1>
          <p>
            Create one landing page/URL for all of your booking calendars.
            Optional items include using a URL or embedding the page on an
            existing site, using a photo layout, and choosing which calendars to
            show to your clients.
          </p>
          <br />
        </div>
        <Accordion title="Page Form Settings" expanded>
          <div className={styles['BookingPageSettings-toggleContainer']}>
            <p>Show my Studio Logo</p>
            <div>{showStudioLogo}</div>
            <Toggle
              initialValue={showStudioLogo}
              onChangeCallback={val =>
                handleStudioSave({
                  studio: {
                    booking_display_logo: val,
                  },
                })
              }
            />
          </div>
          <div className={styles['BookingPageSettings-toggleContainer']}>
            <p>Transparent Background</p>
            <Toggle
              initialValue={transparentBackground}
              onChangeCallback={val =>
                handleStudioSave({
                  studio: {
                    booking_transparent_background: val,
                  },
                })
              }
            />
          </div>
          <div className={styles['BookingPageSettings-toggleContainer']}>
            <p>Submit Button Color</p>
            <div>
              <TogglePopover
                popoverWidth="267px"
                activator={
                  <div
                    className={styles['BookingPageSettings-label-color']}
                    style={{ backgroundColor: submitColor }}
                  />
                }
                popoverContent={
                  <ChromePicker
                    disableAlpha
                    color={submitColor}
                    onChangeComplete={({ hex }) => {
                      setSubmitColor(hex);
                    }}
                  />
                }
              />
            </div>
          </div>
          <div>
            <div className={styles['BookingPageSettings-uploadLabel']}>
              <label>Upload a photo for your layout</label>
              <p>Maximum file size: 20MB</p>
            </div>
            <ImageUploading
              value={[]}
              onChange={handleImageUpload}
              dataURLKey="data_url"
            >
              {({ onImageUpload }) => (
                <div className={styles['BookingPageSettings-uploadContainer']}>
                  <Button text="Upload" onClick={onImageUpload} />
                  <div className={styles['BookingPageSettings-filename']}>
                    <p>{showCurrentPhotoName}</p>
                    {showDelete() && (
                      <a
                        className="fas fa-trash-alt"
                        onClick={handleImageDelete}
                      />
                    )}
                  </div>
                </div>
              )}
            </ImageUploading>
          </div>
          <hr />
          <div className={styles['BookingPageSettings-inputs']}>
            <TextArea
              labelText="Introduction/Welcome Message"
              name="bookingMessage"
              initialValue={bookingMessage}
              onChangeCallback={setBookingMessage}
            />
          </div>
          <br />
          <hr />
          <div style={{ width: '400px' }}>
            <Table
              readOnly
              title="Included on Landing Page"
              headers={headers}
              rows={rows}
              setRows={setRows}
              page={currentPage}
              pageCount={pageCount}
              totalRecords={totalRecords}
              pageChangeCallback={setCurrentPage}
            />
          </div>
        </Accordion>
      </div>
    </>
  );
};

export default BookingPageSettings;
