import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from '~utils/misc';
import styles from './DataTable.module.scss';
import { TablePagination } from '~ui';
import EmptyBody from './EmptyBody';
import LoadingBody from './LoadingBody';
import TableHeader from './TableHeader';
import TableRow from './TableRow';

const DESCENDING = 'desc';

const getSortedRows = (rows, columnName, direction, columnType) => {
  return [...rows].sort((a, b) => {
    let valueA;
    let valueB;
    if (columnType === 'date' || columnType === 'datetime') {
      valueA = Date.parse(a[columnName]);
      valueB = Date.parse(b[columnName]);
    } else if (columnType === 'currency') {
      valueA = Number(a[columnName]);
      valueB = Number(b[columnName]);
    } else {
      valueA = a[columnName].toUpperCase();
      valueB = b[columnName].toUpperCase();
    }

    if (direction === DESCENDING) {
      if (valueA < valueB) {
        return 1;
      } else if (valueA > valueB) {
        return -1;
      }
      return 0;
    } else {
      if (valueA < valueB) {
        return -1;
      } else if (valueA > valueB) {
        return 1;
      }
      return 0;
    }
  });
};

const DataTable = ({
  headers,
  rows,
  title,
  columnTypes,
  loading,
  page,
  pageCount,
  totalRecords,
  pageChangeCallback,
  onSortCallback,
  sorting,
  pageSize,
  rowWidth,
}) => {
  const [sortColumn, setSortColumn] = useState();
  const [sortDirection, setSortDirection] = useState();
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    if (sorting === false) {
      setSortColumn(null);
      setSortDirection(null);
    }
  }, [sorting]);

  const showPagination = rows.length > pageSize || pageCount >= 2;

  const getRows = page => {
    if (
      page !== undefined &&
      pageCount !== undefined &&
      totalRecords != undefined
    ) {
      return rows;
    }

    let rowsToSlice;
    if (sortColumn) {
      rowsToSlice = getSortedRows(rows, sortColumn, sortDirection);
    } else {
      rowsToSlice = rows;
    }
    return rowsToSlice.slice(page * pageSize, page * pageSize + pageSize);
  };

  const previousPage = getRows(pageIndex - 1);
  const currentPage = getRows(pageIndex);
  const nextPage = getRows(pageIndex + 1);

  const handlePageClick = page => {
    setPageIndex(page);
    pageChangeCallback(page);
  };

  const getPaginationLabel = () => {
    if (
      page !== undefined &&
      pageCount !== undefined &&
      totalRecords != undefined
    ) {
      return `${(page - 1) * pageSize + 1}-${
        (page - 1) * pageSize + rows.length
      } of ${totalRecords}`;
    }

    return `${pageIndex * pageSize + 1}-${
      pageIndex * pageSize + currentPage.length
    } of ${rows.length}`;
  };

  const getTableBody = () => {
    if (loading) {
      return <LoadingBody columnCount={headers.length} />;
    } else if (currentPage.length > 0) {
      return (
        <tbody>
          {currentPage.map((row, rowIndex) => (
            <TableRow
              key={uniqueId(`row_${rowIndex}`)}
              row={row}
              rowIndex={rowIndex}
              columnTypes={columnTypes}
              rowWidth={rowWidth}
            />
          ))}
        </tbody>
      );
    } else {
      return <EmptyBody columnCount={headers.length} />;
    }
  };

  const hasPrevious = () => {
    if (page === undefined) {
      return previousPage.length !== 0;
    }

    return page > 1;
  };

  const hasNext = () => {
    if (page === undefined) {
      return nextPage.length !== 0;
    }

    return page !== pageCount;
  };

  return (
    <>
      {title && <h2 className={styles.DataTableTitle}>{title}</h2>}
      <table className={styles.DataTable}>
        <TableHeader
          headers={headers}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSortCallback={(column, direction) => {
            setSortColumn(column);
            setSortDirection(direction);
            onSortCallback(column, direction);
          }}
        />
        {getTableBody()}
      </table>
      {showPagination && (
        <TablePagination
          label={getPaginationLabel()}
          hasPrevious={hasPrevious()}
          hasNext={hasNext()}
          currentPage={page === undefined ? pageIndex : page}
          totalPages={pageCount}
          onPreviousCallback={() => {
            const newPageIndex = page === undefined ? pageIndex - 1 : page - 1;
            setPageIndex(newPageIndex);
            pageChangeCallback(newPageIndex);
          }}
          onNextCallback={() => {
            const newPageIndex = page === undefined ? pageIndex + 1 : page + 1;
            setPageIndex(newPageIndex);
            pageChangeCallback(newPageIndex);
          }}
          handlePageNumberClick={handlePageClick}
        />
      )}
    </>
  );
};

DataTable.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      labelText: PropTypes.string,
    })
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.node,
  columnTypes: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  page: PropTypes.number,
  pageCount: PropTypes.number,
  totalRecords: PropTypes.number,
  pageSize: PropTypes.number,
  pageChangeCallback: PropTypes.func,
  onSortCallback: PropTypes.func,
  sorting: PropTypes.bool,
};

DataTable.defaultProps = {
  title: undefined,
  columnTypes: [],
  loading: false,
  page: undefined,
  pageCount: undefined,
  totalRecords: undefined,
  pageSize: 10,
  pageChangeCallback: () => {},
  onSortCallback: () => {},
  sorting: undefined,
};

export default DataTable;
