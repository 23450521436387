import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './TextArea.module.scss';

const TextArea = props => {
  const {
    disabled,
    initialValue,
    labelText,
    name,
    onChangeCallback,
    placeholder,
    required,
    setWarningForEmpty = false,
  } = props;
  const cx = classNames.bind(styles);
  const [value, setValue] = useState(initialValue);
  const classes = cx('TextArea', {
    'TextArea--disabled': disabled,
    'TextArea--blueInvalid': setWarningForEmpty && value.length === 0,
  });
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div className={classes}>
      {labelText && (
        <label htmlFor={name}>
          {labelText}
          {required && '*'}
        </label>
      )}
      <textarea
        rows={2}
        name={name}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        value={value}
        onBlur={() => onChangeCallback(value)}
        onChange={e => {
          const { value } = e.target;
          setValue(value);
        }}
      />
    </div>
  );
};

TextArea.propTypes = {
  disabled: PropTypes.bool,
  initialValue: PropTypes.string,
  labelText: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChangeCallback: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

TextArea.defaultProps = {
  disabled: false,
  initialValue: '',
  labelText: null,
  onChangeCallback: () => {},
  placeholder: '',
  required: false,
};

export default TextArea;
