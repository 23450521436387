import axios from 'axios';
import { routes } from '~constants/routes';
import { DOCUMENTS, CLIENT_DOCUMENTS } from '~constants/actionTypes';

export const getDocuments = () => async (dispatch, getState) => {
  try {
    const { meta } = getState().documents.documents;
    const { page, limit } = meta;
    dispatch({ type: DOCUMENTS.GET.REQUEST });

    const { data } = await axios.get(routes.DOCUMENTS.INDEX, {
      params: {
        page,
        limit,
        generic: true,
        sort: { column: 'file_file_name', direction: 'asc' },
      },
    });

    dispatch({
      key: 'documents',
      type: DOCUMENTS.GET.SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DOCUMENTS.GET.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};

export const getClientDocuments = (clientId = null) => async (
  dispatch,
  getState
) => {
  try {
    const { meta } = getState().documents.clientDocuments;
    const { page, limit } = meta;
    dispatch({ type: CLIENT_DOCUMENTS.GET.REQUEST });

    const { data } = await axios.get(routes.DOCUMENTS.CLIENT_DOCUMENTS, {
      params: {
        page,
        limit,
        ...(clientId && { client_id: clientId }),
        with_client: true,
        sort: { column: 'file_file_name', direction: 'asc' },
      },
    });

    dispatch({
      key: 'clientDocuments',
      type: CLIENT_DOCUMENTS.GET.SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLIENT_DOCUMENTS.GET.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};

export const updateClientDocumentsMeta = params => ({
  type: CLIENT_DOCUMENTS.META.UPDATE,
  payload: params,
});

export const updateDocumentsMeta = params => ({
  type: DOCUMENTS.META.UPDATE,
  payload: params,
});
