import React, { useState, useEffect, useContext } from 'react';
import { TableNew as Table, DeleteConfirmation, Icon } from '~ui';
import axios from 'axios';
import { absolutePath, routes } from '~constants/routes';
import { SendModal } from '../../Modals';
import EmailMarketingContext from '../../EmailMarketingContext';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import CampaignTemplatesDupModal from './CampaignTemplatesDupModal';
import styles from './CampaignTemplatesTable.module.scss';

const CampaignTemplatesTable = ({ setActiveTabKey }) => {
  const {
    templates,
    getTemplates,
    templatesMeta,
    sessionTypesOptions,
    studioGroups,
    timeZone,
    marketing,
    marketingValidated,
  } = useContext(EmailMarketingContext);
  const [rows, setRows] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [idForDelete, setIdForDelete] = useState(null);
  const [nameForDelete, setNameForDelete] = useState('');
  const [showSendModal, setShowSendModal] = useState(false);
  const [idForSend, setIdForSend] = useState(null);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [idForDuplicate, setIdForDuplicate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getTemplates();
  }, []);

  useEffect(() => {
    if (templates.length > 0) {
      const newRows = templates.map(template => {
        return createRowItem({
          id: template.attributes.id,
          name: template.attributes.name,
          description: template.attributes.description,
          temp: false,
          template: template,
        });
      });
      setRows(newRows);
    }
  }, [templates]);

  const duplicateTemplate = async () => {
    setShowDuplicateModal(false);
    setIsLoading(true);
    try {
      await axios.post(
        `/api/email_campaign_templates/${idForDuplicate}/duplicate`
      );
      getTemplates();
      toast.success('Copy created!', toastOptions);
      setIsLoading(false);
    } catch (error) {
      toast.error('Error creating copy', toastOptions);
      setIsLoading(false);
    }
  };

  const headers = ['Name', 'Description', 'Email Subject'];

  const buildEditData = template => ({
    campaignTemplateId: template.attributes.id,
    stepId: template.attributes.steps.data[0].attributes.id,
    emailTemplateId:
      template.attributes.steps.data[0].attributes.email_template.id,
    form: {
      name: template.attributes.name,
      description: template.attributes.description,
      emailSubject:
        template.attributes.steps.data[0].attributes.email_template
          .email_subject,
      emailTemplateContent:
        template.attributes.steps.data[0].attributes.email_template.email_body,
    },
  });

  const createRowItem = ({ id, name, description, temp, template }) => {
    const rowItem = {
      id,
      editable: true,
      deletable: true,
      temp,
      onClickCallback: () => {
        window.location.href = absolutePath(`/email_marketing/${id}`);
      },
      editButtonOverride: () => {
        window.location.href = absolutePath(`/email_marketing/${id}`);
      },
      deleteButtonOverride: () => {
        setIdForDelete(id);
        setNameForDelete(name);
        setShowDeleteModal(true);
      },
      buttonGroup: (
        <>
          <a>
            <i
              onClick={e => {
                e.stopPropagation();
                setIdForSend(id);
                setShowSendModal(true);
              }}
              className="fa fa-paper-plane"
            />
          </a>
          <a>
            <i
              onClick={e => {
                e.stopPropagation();
                setIdForDuplicate(id);
                setShowDuplicateModal(true);
              }}
              className="fa fa-plus"
            />
          </a>
        </>
      ),
      data: {
        name: {
          value: name,
          type: 'enum',
          editable: false,
        },
        description: {
          value: description,
          type: 'enum',
          editable: false,
        },
        emailSubject: {
          value:
            template.attributes.steps.data[0].attributes.email_template
              .email_subject,
          type: 'enum',
          editable: false,
        },
      },
    };
    return rowItem;
  };

  const deleteCampaignTemplate = async () => {
    try {
      await axios.delete(routes.EMAIL_CAMPAIGN_TEMPLATES.DELETE(idForDelete));
      setIdForDelete(null);
      setNameForDelete('');
      getTemplates();
      setShowDeleteModal(false);
    } catch (error) {
      console.error(error);
      setShowDeleteModal(false);
    }
  };

  return (
    <>
      <Table
        readOnly
        title="My Campaign Templates"
        headers={headers}
        rows={rows}
        setRows={setRows}
        loading={false}
        page={templatesMeta.page || 1}
        pageCount={templatesMeta.page_count || 1}
        totalRecords={templatesMeta.total_records || 0}
        pageChangeCallback={getTemplates}
      />
      {showDeleteModal && (
        <DeleteConfirmation
          handleHide={() => setShowDeleteModal(false)}
          handleDelete={deleteCampaignTemplate}
          name={nameForDelete}
          resource="Email Campaign Template"
        />
      )}
      {showSendModal && (
        <SendModal
          handleHide={() => setShowSendModal(false)}
          emailCampaignTemplateId={idForSend}
          setActiveTabKey={setActiveTabKey}
          sessionTypesOptions={sessionTypesOptions}
          studioGroups={studioGroups}
          timeZone={timeZone}
          marketing={marketing}
          marketingValidated={marketingValidated}
        />
      )}
      {showDuplicateModal && (
        <CampaignTemplatesDupModal
          handleHide={setShowDuplicateModal}
          handleCreate={duplicateTemplate}
        />
      )}
      {isLoading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingText}>
            <p>Loading...</p>
            <Icon name="spinner" className="fa-pulse" large />
          </div>
        </div>
      )}
    </>
  );
};

export default CampaignTemplatesTable;
