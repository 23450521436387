import React, { useState } from 'react';
import styles from './StudioInvoiceTemplates.module.scss';
import { Button, DataTable, DeleteConfirmation } from '~ui';
import ToggleContent from '~components/common/ToggleContent';
import { absolutePath, routes } from '~constants/routes';
import axios from 'axios';

const StudioInvoiceTemplates = props => {
  const { initialInvoiceTemplates, invoiceProducts } = props;
  const [invoiceTemplates, setInvoiceTemplates] = useState(
    initialInvoiceTemplates
  );

  const headers = [
    { name: 'name', labelText: 'Name', type: 'string' },
    { name: 'buttonGroup', labelText: '', type: 'function', sortable: false },
  ];

  const handleDeleteInvoiceTemplate = templateId => {
    axios.delete(routes.INVOICE_TEMPLATES.DELETE(templateId)).then(res => {
      setInvoiceTemplates(
        invoiceTemplates.filter(template => template.id != res.data.data.id)
      );
    });
  };

  const renderButtons = template => {
    return (
      <div className={styles['StudioInvoiceTemplates-buttons']}>
        <a
          onClick={e => {
            e.stopPropagation();
            window.location.href = absolutePath(
              `/studio/invoice_templates/${template.id}/edit`
            );
          }}
          className="fas fa-pencil-alt"
        />
        <a
          onClick={e => {
            e.stopPropagation();
            window.location.href = absolutePath(
              `/customer/invoice_templates/${template.uuid}`
            );
          }}
          className="fas fa-eye "
          target="_blank"
        />
        <ToggleContent
          toggle={handleShow => (
            <a
              className="fas fa-trash-alt"
              onClick={e => {
                e.stopPropagation();
                handleShow();
              }}
            />
          )}
          content={handleHide => (
            <DeleteConfirmation
              handleHide={handleHide}
              handleDelete={() => handleDeleteInvoiceTemplate(template.id)}
              name={template.name}
              resource="Invoice Template"
            />
          )}
        />
      </div>
    );
  };

  return (
    <div className={styles['StudioInvoiceTemplates']}>
      <div className={styles['StudioInvoiceTemplates-header']}>
        <h1>Invoice Templates</h1>
        <div>
          {invoiceProducts.length === 0 && (
            <a href={absolutePath('/studio/invoice_setup')}>
              Please add Invoice Products before continuing!
            </a>
          )}
          <Button
            text="New Template"
            disabled={invoiceProducts.length === 0}
            onClick={() => {
              window.location.href = absolutePath(
                '/studio/invoice_templates/new'
              );
            }}
          />
        </div>
      </div>
      <div className={styles['StudioInvoiceTemplates-table']}>
        <DataTable
          columnTypes={['string']}
          headers={headers}
          rows={invoiceTemplates.map(template => ({
            name: template.name,
            buttonGroup: renderButtons(template),
            onClickCallback: () => {
              window.location.href = absolutePath(
                `/studio/invoice_templates/${template.id}/edit`
              );
            },
          }))}
        />
      </div>
    </div>
  );
};

export default StudioInvoiceTemplates;
