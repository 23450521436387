import React from 'react';
import { Provider } from 'react-redux';
import Calendar from '../Calendar';
import { configureStore } from '../../store';

const CalendarRoot = props => {
  const store = configureStore(props);

  return (
    <Provider store={store}>
      <Calendar />
    </Provider>
  );
};

export default CalendarRoot;
