import React from 'react';
import styles from './Sidebar.module.scss';
import classNames from 'classnames/bind';

const Option = props => {
  const {
    title,
    onClickCallback,
    isActive,
    isSub,
    defaultExpanded,
    noChevron,
  } = props;

  const cx = classNames.bind(styles);

  const classes = cx('NewSidebar-option', {
    'NewSidebar-option-active': isSub && isActive,
    'NewSidebar-option-sub': isSub,
    'NewSidebar-option-expanded': defaultExpanded,
    'NewSidebar-option-parent': !isSub && !isActive,
    'NewSidebar-option-parent-active': !isSub && isActive,
  });

  const renderIcon = () => {
    if (!isSub && !noChevron) {
      return (
        <i
          className={`fa fa-chevron-${isActive ? 'down' : 'right'}`}
          style={{
            color: !isSub && isActive ? '#FFFFFF' : '#7EC0E5',
          }}
        />
      );
    }
  };

  return (
    <div
      className={classes}
      onClick={onClickCallback}
      style={{ paddingTop: !isSub && isActive ? '6px' : '' }}
    >
      <div style={{ display: 'flex', position: 'relative' }}>
        {isSub && (
          <div
            style={{
              width: '1px',
              height: title == 'All' ? '30px' : '47px',
              backgroundColor: 'gray',
              position: 'absolute',
              left: '-8px',
              top: '8px',
            }}
          ></div>
        )}
        <span />
        <h2>{title}</h2>
      </div>
      {renderIcon()}
    </div>
  );
};

export default Option;
