import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Icon } from '~ui';
import { uniqueId } from '~utils';
import styles from './ButtonGroupInput.module.scss';

const ButtonGroupInput = ({ initialValue, onButtonClickCallback, buttons }) => {
  const [selected, setSelected] = useState(initialValue);
  const cx = classNames.bind(styles);

  const renderButtons = () =>
    buttons.map((item, index) => {
      const { icon, text, ariaLabel } = item;
      const key = uniqueId('buttonInput_');
      const classes = cx('ButtonGroupInput-button', {
        'ButtonGroupInput-button--selected': selected === index,
      });

      return (
        <button
          key={key}
          aria-label={ariaLabel}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            setSelected(index);
            onButtonClickCallback(index);
          }}
          className={classes}
        >
          {icon ? <Icon name={icon} small /> : <span>{text}</span>}
        </button>
      );
    });

  const renderInput = () => {
    return buttons[selected].input();
  };

  return (
    <div className={styles['ButtonGroupInput']}>
      <div className={styles['ButtonGroupInput-buttonContainer']}>
        {renderButtons()}
      </div>
      {renderInput()}
    </div>
  );
};

ButtonGroupInput.propTypes = {
  initialValue: PropTypes.number,
  onButtonClickCallback: PropTypes.func,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string,
      input: PropTypes.func.isRequired,
      ariaLabel: PropTypes.string,
    })
  ).isRequired,
};

ButtonGroupInput.defaultProps = {
  initialValue: 0,
  onButtonClickCallback: () => {},
};

export default ButtonGroupInput;
