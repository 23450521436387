import React from 'react';
import styles from '../CalendarEvent/CalendarEvent.module.scss';

const PlaceholderEvent = () => {
  return (
    <div
      className={styles.CalendarEvent}
      style={{
        zIndex: 0,
        position: 'relative',
      }}
    />
  );
};

export default PlaceholderEvent;
