import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getQuestionnaires,
  updateQuestionnairesMeta,
} from '~actions/questionnaires';
import { TableNew as Table, DeleteConfirmation } from '~ui';
import QuestionnairesButtons from '../ButtonGroups/QuestionnairesButtons';
import axios from 'axios';
import { routes } from '~constants/routes';
import StudioQuestionnaire from '../../StudioQuestionnaire';

const QuestionnairesTable = () => {
  const { data, meta } = useSelector(state => state.documents.questionnaires);

  const [rows, setRows] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [idForEdit, setIdForEdit] = useState();
  const [idForDelete, setIdForDelete] = useState(null);

  const dispatch = useDispatch();

  const ITEM_HEADERS = ['Name'];

  const createRowItem = ({ id, name }) => {
    const rowItem = {
      id,
      editable: true,
      deletable: true,
      temp: false,
      onClickCallback: () => {
        setShowEditModal(true);
        setIdForEdit(+id);
      },
      editButtonOverride: () => {
        setShowEditModal(true);
        setIdForEdit(+id);
      },
      deleteButtonOverride: () => {
        setIdForDelete(id);
        setShowDeleteModal(true);
      },
      buttonGroup: (
        <QuestionnairesButtons questionnaireId={id} questionnaireName={name} />
      ),
      data: {
        name: {
          value: name,
          type: 'enum',
          editable: false,
        },
      },
    };

    return rowItem;
  };

  useEffect(() => {
    if (data) {
      const newRows = data.map(questionnaire => {
        return createRowItem({
          id: +questionnaire.id,
          name: questionnaire.attributes.name,
        });
      });

      setRows(newRows);
    }
  }, [data]);

  const handleQuestionnaireDelete = async () => {
    try {
      await axios.delete(routes.QUESTIONNAIRES.DELETE(idForDelete));

      dispatch(getQuestionnaires());
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        <Table
          readOnly
          headers={ITEM_HEADERS}
          rows={rows}
          setRows={setRows}
          page={meta.page}
          pageCount={meta.page_count}
          totalRecords={meta.total_records}
          pageChangeCallback={newPageIndex => {
            dispatch(
              updateQuestionnairesMeta({
                page: newPageIndex,
              })
            );
            dispatch(getQuestionnaires());
          }}
        />
      </div>
      {showDeleteModal && (
        <DeleteConfirmation
          handleHide={() => setShowDeleteModal(false)}
          handleDelete={handleQuestionnaireDelete}
          resource="Questionnaire"
        />
      )}
      {showEditModal && (
        <>
          <StudioQuestionnaire
            handleHide={() => {
              dispatch(getQuestionnaires());
              setShowEditModal(false);
            }}
            editQuestionnaireId={idForEdit}
          />
        </>
      )}
    </>
  );
};

export default QuestionnairesTable;
