import {
  DOCUMENTS,
  CLIENT_DOCUMENTS,
  CONTRACT_TEMPLATES,
  QUESTIONNAIRES,
  CLIENT_CONTRACTS,
} from '~constants/actionTypes';

export default function (state = {}, action) {
  switch (action.type) {
    case CLIENT_DOCUMENTS.META.UPDATE:
      return {
        ...state,
        clientDocuments: {
          meta: {
            ...state.clientDocuments.meta,
            ...action.payload,
          },
        },
      };
    case DOCUMENTS.META.UPDATE:
      return {
        ...state,
        documents: {
          meta: {
            ...state.documents.meta,
            ...action.payload,
          },
        },
      };
    case QUESTIONNAIRES.META.UPDATE:
      return {
        ...state,
        questionnaires: {
          data: [...state.questionnaires.data],
          meta: {
            ...state.questionnaires.meta,
            ...action.payload,
          },
        },
      };
    case CONTRACT_TEMPLATES.META.UPDATE:
    case CLIENT_CONTRACTS.META.UPDATE:
      return {
        ...state,
        contracts: {
          data: [...state.contracts.data],
          meta: {
            ...state.contracts.meta,
            ...action.payload,
          },
        },
      };
    case DOCUMENTS.GET.SUCCESS:
    case CLIENT_DOCUMENTS.GET.SUCCESS:
    case QUESTIONNAIRES.GET.SUCCESS:
    case CONTRACT_TEMPLATES.GET.SUCCESS:
    case CLIENT_CONTRACTS.GET.SUCCESS:
      return {
        ...state,
        [action.key]: {
          data: action.payload.data,
          meta: { ...state[action.key].meta, ...action.payload.meta },
        },
      };
    default:
      return state;
  }
}
