import React from 'react';
import styles from './Playground.module.scss';

const PLAYGROUND_PATH =
  'app/assets/javascripts/components/UIPlayground/index.jsx';

const Playground = ({ children }) => {
  return (
    <div className={styles.Playground}>
      <h1 className={styles['Playground-header']}>UI Playground</h1>
      <p className={styles['Playground-description']}>
        Preview of your component:
      </p>
      <div className={styles['Playground-canvas']}>{children}</div>
      <div>
        Edit the Playground by editing <pre>{PLAYGROUND_PATH}</pre>
      </div>
    </div>
  );
};

export default Playground;
