import React from 'react';
import { Modal, Button } from '~ui';

const CampaignTemplatesDupModal = ({ handleHide, handleCreate }) => {
  return (
    <Modal title="Copy Campaign Template" handleHide={() => handleHide(false)}>
      <div className="mb-8">
        Are you sure you want to create a copy of this template?
      </div>
      <br />
      <div className="d-fr">
        <Button
          className="mr-8"
          text="Cancel"
          onClick={() => handleHide(false)}
          danger
        />
        <Button text="Create copy" onClick={() => handleCreate(true)} />
      </div>
    </Modal>
  );
};

export default CampaignTemplatesDupModal;
