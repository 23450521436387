import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { toCurrencyAmount, uniqueId } from '~utils';
import {
  Button,
  MenuButton,
  Dropdown,
  TextArea,
  TextInput,
  CurrencyInput,
  Checkbox,
} from '~ui';

import styles from '../Invoice.module.scss';

const MobileInvoiceItems = ({
  title,
  subtitle,
  readOnly,
  handleCreate,
  handleUpdate,
  handleDelete,
  setOpenProductModal,
  invoiceProductsOptions,
  setRowId,
}) => {
  const invoice = useSelector(state => state.invoice);
  const invoiceProducts = useSelector(state => state.invoiceProducts.products);
  const { items } = invoice;

  const [lineItems, setLineItems] = useState([]);
  const [currentAction, setCurrentAction] = useState();
  const [isSelecting, setIsSelecting] = useState(false);
  const [indexOfSelectedLineItem, setIndexOfSelectedLineItem] = useState();
  const [isScrolling, setIsScrolling] = useState(false);

  const lineItemOptions = [
    {
      label: 'Add',
      onClickCallback: () => {
        const newIndex = lineItems.length;
        setCurrentAction('add');
        setLineItems(lineItems.concat([{}]));
        setIndexOfSelectedLineItem(newIndex);
        setIsScrolling(true);
      },
      ref: React.createRef(),
    },
  ];
  if (lineItems.length > 0) {
    lineItemOptions.push({
      label: 'Edit',
      onClickCallback: () => {
        setCurrentAction('edit');
        setIsSelecting(true);
      },
      ref: React.createRef(),
    });
    lineItemOptions.push({
      label: 'Delete',
      onClickCallback: () => {
        setCurrentAction('delete');
        setIsSelecting(true);
      },
      ref: React.createRef(),
    });
  }

  const getLineItemCardId = indexOfLineItem => {
    return `LineItemCard-${indexOfLineItem}`;
  };

  useEffect(() => {
    setLineItems(items);
  }, [items]);

  useLayoutEffect(() => {
    if (isScrolling) {
      const lastCard = document.getElementById(
        getLineItemCardId(lineItems.length - 1)
      );
      window.scrollTo(0, lastCard.offsetTop - 20);
      setIsScrolling(false);
    }
  }, [isScrolling]);

  const getInvoiceProductFromId = (id, item = null) => {
    if (id) {
      return invoiceProducts.find(product => product.id === id);
    } else {
      return { name: item.invoice_type };
    }
  };

  const getAriaLabel = item => {
    if (isSelecting) {
      const invoiceProduct = getInvoiceProductFromId(
        item.invoice_product_id,
        item
      );
      return `${currentAction} ${invoiceProduct.name}`;
    }

    return undefined;
  };

  const cancelAction = lineItem => {
    if (lineItem.id === undefined) {
      setLineItems(lineItems.slice(0, lineItems.length - 1));
    }
    setCurrentAction();
    setIndexOfSelectedLineItem();
    setIsSelecting(false);
  };

  const getMenuButton = () => {
    if (currentAction) {
      return (
        <Button
          className="bgc-m bc-m c-w"
          name={`Cancel ${currentAction} Action`}
          category="icon"
          icon="times"
          onClick={() => {
            cancelAction(lineItems[lineItems.length - 1]);
          }}
        />
      );
    }

    return (
      <MenuButton
        className="bgc-m bc-m c-w"
        name="Line Item Actions"
        options={lineItemOptions}
      />
    );
  };

  const handleSubmit = e => {
    e.preventDefault();
    const fieldElements = e.target.querySelectorAll('input,select,textarea');

    const params = Array.from(fieldElements).reduce((acc, input) => {
      if (input.type === 'checkbox') {
        acc[input.name] = { value: input.checked };
      } else {
        acc[input.name] = { value: input.value };
      }

      return acc;
    }, {});

    const currentItem = lineItems[indexOfSelectedLineItem];
    if (currentItem.id === undefined) {
      handleCreate(params);
      setLineItems(items);
    } else {
      handleUpdate({ id: currentItem.id, body: params });
    }

    setCurrentAction();
    setIndexOfSelectedLineItem();
  };

  const getDeleteModal = item => (
    <div className={styles.DeleteContainer}>
      <div className={styles.DeleteModal}>
        <p>Delete this item?</p>
        <div>
          <Button
            text="No"
            danger
            onClick={() => {
              setIsSelecting(true);
              setIndexOfSelectedLineItem();
            }}
          />
          <Button
            text="Yes"
            onClick={() => {
              setIndexOfSelectedLineItem();
              setCurrentAction();
              handleDelete({ id: item.id });
            }}
          />
        </div>
      </div>
    </div>
  );

  const isAdding = currentAction === 'add';
  const isEditing = currentAction === 'edit';
  const isDeleting = currentAction === 'delete';
  const getItemCard = (item, indexOfLineItem) => {
    const isSelected = indexOfLineItem === indexOfSelectedLineItem;

    let className = styles['MobileCard'];
    if (isSelecting || isSelected) {
      className = `${className} ${styles['MobileCard--outlined']}`;
    }

    let cardBody;
    if ((isEditing || isAdding) && isSelected) {
      cardBody = (
        <form className={styles.EditContainer} onSubmit={handleSubmit}>
          {isEditing && !item.invoice_product_id ? (
            <div>
              <label>Item</label>
              <p>{item.invoice_type}</p>
            </div>
          ) : (
            <Dropdown
              labelText="Item"
              name="invoice_product_id"
              options={invoiceProductsOptions}
              initialValue={item.invoice_product_id}
              onChangeCallback={value => {
                if (value == 'new product') {
                  if (setRowId) {
                    setRowId(lineItems[indexOfSelectedLineItem].id);
                  }
                  setOpenProductModal(true);
                } else {
                  const invoiceProduct = getInvoiceProductFromId(+value);
                  lineItems[indexOfSelectedLineItem] = {
                    ...lineItems[indexOfSelectedLineItem],
                    invoice_product_id: value,
                    description: invoiceProduct.description,
                    quantity: 1,
                    price: invoiceProduct.price,
                    taxable: invoiceProduct.taxable,
                  };
                  setLineItems([...lineItems]);
                }
              }}
            />
          )}
          <TextArea
            labelText="Description"
            name="description"
            initialValue={item.description}
          />
          <div className={styles.Line}>
            <TextInput
              labelText="Quantity"
              name="quantity"
              initialValue={item.quantity}
            />
            <CurrencyInput
              labelText="Price"
              name="price"
              initialValue={item.price || '0.00'}
              max={10000}
            />
          </div>
          <Checkbox
            labelText="Taxable?"
            name="taxable"
            checked={item.taxable}
          />
          <div className={styles.Line}>
            <Button
              text="Cancel"
              onClick={() => {
                cancelAction(item);
              }}
              danger
            />
            <Button text="Save" submit />
          </div>
        </form>
      );
    } else {
      cardBody = (
        <div className={styles.CardBody}>
          <p>{item.description}</p>
          <p>{`${item.quantity} x ${toCurrencyAmount(item.price)}`}</p>
          <p>{item.taxable ? 'Taxable' : 'Not taxable'}</p>
        </div>
      );
    }

    return (
      <div
        key={uniqueId('lineItem_')}
        id={getLineItemCardId(indexOfLineItem)}
        className={className}
        tabIndex={isSelecting ? '0' : '-1'}
        role={isSelecting ? 'button' : undefined}
        aria-label={getAriaLabel(item)}
        onClick={
          isSelecting
            ? () => {
                setIsSelecting(false);
                setIndexOfSelectedLineItem(indexOfLineItem);
              }
            : undefined
        }
      >
        <div className={styles['MobileCard-header']}>
          <h3>
            {isAdding && isSelected
              ? 'New Line Item'
              : getInvoiceProductFromId(+item.invoice_product_id, item).name}
          </h3>
          <p>
            {currentAction != 'add' &&
              toCurrencyAmount(+item.price * +item.quantity)}
          </p>
        </div>
        {cardBody}
        {isDeleting && isSelected && getDeleteModal(item)}
      </div>
    );
  };
  return (
    <div>
      <div className={styles['MobileSectionHeader']}>
        <div>
          <h2>{title}</h2>
          <p>{subtitle}</p>
        </div>
        {!readOnly && getMenuButton()}
      </div>
      {lineItems.length === 0 ? (
        <p>No line items yet.</p>
      ) : (
        lineItems.map((item, index) => getItemCard(item, index))
      )}
    </div>
  );
};

MobileInvoiceItems.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  handleCreate: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

MobileInvoiceItems.defaultProps = {
  readOnly: false,
};

export default MobileInvoiceItems;
