import React, { useState } from 'react';
import Playground from './Playground';
import { Button, TextInput } from '~ui';
import StudioQuestionnaire from '../StudioQuestionnaire';

const UIPlayground = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [questionnaireId, setQuestionnaireId] = useState(null);
  return (
    <Playground>
      {/* Import your react component and render it here. */}
      {/* This is only for testing purposes */}
      <TextInput
        labelText="Enter an old questionnaire id (web form) here to test edit functionality, leave empty to test new questionnaire"
        onChangeCallback={setQuestionnaireId}
      />
      <Button
        text="Open the Modal"
        onClick={() => {
          setModalOpen(true);
        }}
      />

      {/* For testing without an existing questionnaire */}
      {modalOpen && (
        <StudioQuestionnaire
          setModalOpen={setModalOpen}
          editQuestionnaireId={questionnaireId}
        />
      )}

      {/* For testing WITH an existing questionnaire (will have to change the ID) */}
      {/* {modalOpen && (
        <StudioQuestionnaire
          setModalOpen={setModalOpen}
          editQuestionnaireId={175}
        />
      )} */}
    </Playground>
  );
};

export default UIPlayground;
