import React, { useState, useEffect } from 'react';
import styles from './ClientActivity.module.scss';
import { routes, absolutePath } from '~constants/routes';
import axios from 'axios';
import { Icon } from '~ui';
import moment from 'moment-timezone';

const ClientActivity = () => {
  const [activities, setActivities] = useState([]);
  const [clientLoading, setClientLoading] = useState();

  const getClientActivities = async () => {
    const currentDate = moment().format('YYYY-MM-DD');
    const twoWeeksAgo = moment().subtract(2, 'weeks').format('YYYY-MM-DD');

    try {
      setClientLoading(true);
      const res = await axios.get(routes.CLIENT_ACTIVITIES.INDEX, {
        params: {
          created_at: { start: twoWeeksAgo, end: currentDate },
          sort: {
            column: 'created_at',
            direction: 'desc',
          },
        },
      });

      setActivities(res.data.data);
      setClientLoading(false);
    } catch (error) {
      setClientLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getClientActivities();
  }, []);

  const renderIcon = attributes => {
    return (
      <div
        style={{
          backgroundColor:
            attributes.awesome_icon === 'fa-times' ? '#949596' : '#DFFBFF',
        }}
        className={styles['ClientActivity-activity-icon']}
      >
        <i className={`fas ${attributes.awesome_icon}`}></i>
      </div>
    );
  };

  return (
    <div className={styles.ClientActivity}>
      <h1>Recent Client Activity</h1>
      <>
        {clientLoading && (
          <div className={styles['ClientActivity-loading']}>
            <Icon name="spinner" className="fa-pulse" large />
          </div>
        )}
      </>
      {activities &&
        activities.map(({ attributes }, index) => (
          <div
            onClick={() => {
              if (attributes.reference_type === 'WebFormResponse') {
                window.location.href = absolutePath(
                  `/clients/${attributes.client_id}/documents`
                );
              } else if (attributes.reference_type === 'Invoice') {
                let path = absolutePath(attributes.url);
                if (attributes.message == 'invoice_full_payment') {
                  path += '?status=paid';
                } else if (attributes.message == 'invoice_partial_paid') {
                  path += '?status=partially_paid';
                } else if (attributes.message == 'quote_sent') {
                  path += '?status=quotes';
                } else if (attributes.message == 'quote_accepted') {
                  path += '?status=all';
                } else if (attributes.message == 'quote_declined') {
                  path += '?status=declined_quote';
                } else if (attributes.message == 'quote_modification') {
                  path += '?status=mod_requested_quote';
                }
                window.location.href = path;
              } else {
                window.location.href = absolutePath(attributes.url);
              }
            }}
            key={index}
            className={styles['ClientActivity-activity']}
          >
            {renderIcon(attributes)}
            <div className={styles['ClientActivity-activity-info']}>
              <h3>{attributes.status_message}</h3>
              <p>
                {moment(attributes.created_at).format(
                  'MMMM D, YYYY [at] h:mma'
                )}
              </p>
            </div>
          </div>
        ))}
      {!clientLoading && !activities.length > 0 && (
        <div className={styles['ClientActivity-noActivity']}>
          No recent activity was found.
        </div>
      )}
    </div>
  );
};

export default ClientActivity;
