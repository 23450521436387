import axios from 'axios';
import { routes } from '~constants/routes';
import { COUPON_CODE_LIST, COUPON_CODES } from '~constants/actionTypes';

export const getCouponCodeList = () => async (dispatch, getState) => {
  try {
    const { meta } = getState().invoice;

    const { page, search_term } = meta;

    dispatch({ type: COUPON_CODE_LIST.GET.REQUEST });

    let params = {
      page,
      search_term,
    };

    const res = await axios.get(routes.COUPON_CODES.GET, { params });

    dispatch({
      type: COUPON_CODE_LIST.GET.SUCCESS,
      payload: {
        data: res.data.coupon_codes,
        meta: res.data.meta,
      },
    });
  } catch (error) {
    dispatch({
      type: COUPON_CODE_LIST.GET.FAILURE,
      payload: error,
      error: true,
    });
  }
};

export const createCoupon = params => async dispatch => {
  try {
    dispatch({ type: COUPON_CODES.CREATE.REQUEST });

    const coupon = await axios.post(routes.COUPON_CODES.CREATE, params);

    dispatch({ type: COUPON_CODES.CREATE.SUCCESS, payload: coupon.data });
    return { res: 'success', errors: null };
  } catch (error) {
    dispatch({
      type: COUPON_CODES.CREATE.FAILURE,
      payload: error,
      error: true,
    });
    return { res: 'error', errors: error };
  }
};

export const updateCoupon = (couponId, params) => async dispatch => {
  try {
    dispatch({ type: COUPON_CODES.UPDATE.REQUEST });

    const coupon = await axios.put(
      routes.COUPON_CODES.UPDATE(couponId),
      params
    );

    dispatch({ type: COUPON_CODES.UPDATE.SUCCESS, payload: coupon.data });
    return { res: 'success', errors: null };
  } catch (error) {
    dispatch({
      type: COUPON_CODES.UPDATE.FAILURE,
      payload: error,
      error: true,
    });
    return { res: 'error', errors: error };
  }
};

export const deleteCoupon = couponId => async dispatch => {
  try {
    dispatch({ type: COUPON_CODES.DELETE.REQUEST });

    await axios.delete(routes.COUPON_CODES.DELETE(couponId));

    dispatch({
      type: COUPON_CODES.DELETE.SUCCESS,
      payload: { couponId: couponId },
    });
    return { res: 'success', errors: null };
  } catch (error) {
    dispatch({
      type: COUPON_CODES.DELETE.FAILURE,
      payload: error,
      error: true,
    });
    return { res: 'error', errors: error };
  }
};
