import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Dropdown } from '~ui';
import { updateInterval } from '~actions/calendar';
import { intervalSelector } from '~selectors';
import ToggleContent from '~components/common/ToggleContent';
import { getNearestHalfHourDateTime, uniqueId } from '~utils';
import styles from './CalendarHeader.module.scss';
import CalendarEventForm from '../CalendarEventForm';
import { DATE_FORMAT, MONTH_YEAR_FORMAT } from '~constants/datetime';

/*
  The calendar header is composed of the date display (either the month or week interval), arrows to move to the next or previous interval, an interval selector, and a button to create a new event.

  The month display makes use of moment to add and subtract the appropriate interval based on what interval is selected ("month" or "week"). The "New Event" button opens a modal with the <CalendarEventForm /> so a user can create new events as necessary.
*/

const CalendarHeader = props => {
  const { dateContext, nextInterval, prevInterval } = props;
  const dispatch = useDispatch();
  const interval = useSelector(intervalSelector);
  const week = () =>
    `${dateContext.startOf('week').format(DATE_FORMAT)} - 
      ${dateContext.endOf('week').format(DATE_FORMAT)}`;
  const month = () => dateContext.format(MONTH_YEAR_FORMAT);
  const dateHeader = interval === 'month' ? month() : week();
  const options = [
    { value: 'month', label: 'Month' },
    { value: 'week', label: 'Week' },
  ];
  const hasCronofyCalendar = useSelector(
    state => state.calendar.meta.features.hasCronofyCalendar
  );
  const sessionCounts = useSelector(state => state.calendar.sessionTypeCount);
  const sessionCountSort = sessionCounts
    .sort((a, b) => (a.session_count < b.session_count ? 1 : -1))
    .slice(0, 5);

  const openConnectCalendarsPage = () => {
    window.open('/calendar/connect');
  };

  return (
    <>
      <div className={styles['CalendarHeader']}>
        <div className={styles['CalendarHeader-column']}>
          <div className={styles['CalendarHeader-intervalArrow']}>
            <i className="fa fa-chevron-left" onClick={prevInterval} />
          </div>
          <div className={styles['CalendarHeader-dateHeader']}>
            <span>{dateHeader}</span>
          </div>
          <div className={styles['CalendarHeader-intervalArrow']}>
            <i className="fa fa-chevron-right" onClick={nextInterval} />
          </div>
        </div>
        <div className={styles['CalendarHeader-column']}>
          <div className={styles['CalendarHeader-actions']}>
            <div className={styles['CalendarHeader-interval']}>
              <Dropdown
                name="calendarView"
                options={options}
                onChangeCallback={e => dispatch(updateInterval(e))}
              />
            </div>
            <div style={{ marginRight: '16px' }}>
              <Button
                text={
                  hasCronofyCalendar ? 'View Calendars' : 'Connect Calendars'
                }
                onClick={openConnectCalendarsPage}
                icon="calendar-alt"
              />
            </div>
            <div>
              <ToggleContent
                toggle={handleShow => (
                  <Button text="New Event" onClick={handleShow} icon="plus" />
                )}
                content={handleHide => (
                  <CalendarEventForm
                    day={Date.parse(getNearestHalfHourDateTime())}
                    handleHide={handleHide}
                    dateContext={dateContext}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles['CalendarHeader-sessionCount']}>
        {sessionCountSort.map(session => {
          return (
            <div
              style={{ backgroundColor: session.color }}
              key={uniqueId('session_')}
            >
              {session.session_count} - {session.name}
            </div>
          );
        })}
      </div>
    </>
  );
};

CalendarHeader.propTypes = {
  dateContext: PropTypes.object.isRequired,
  nextInterval: PropTypes.func.isRequired,
  prevInterval: PropTypes.func.isRequired,
};

export default CalendarHeader;
