import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Dropdown, Button } from '~ui';
import { routes, absolutePath } from '~constants/routes';
import styles from './BookingInvitationModal.module.scss';
import TinyMceInput from '~components/common/TinyMceInput';
import { toast } from 'react-toastify';

const SendEmailPage = ({
  emailTemplates,
  locations,
  bookingCalendar,
  handleHide,
}) => {
  const [emailBody, setEmailBody] = useState(
    'Select from the options above, then click "Generate" to get started.'
  );
  const [emailTemplateId, setEmailTemplateId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [bookingUrls, setBookingUrls] = useState([]);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const fetchBookingUrls = async () => {
      const response = await axios.get(routes.BOOKING_CALENDARS.INDEX);
      
      const urls = response.data.data.map(calendar => [
        calendar.attributes.short_url,
        calendar.attributes.title,
      ]);
      setBookingUrls(urls);
    };

    fetchBookingUrls();
  }, []);

  const fetchEmailBody = async templateId => {
    if (templateId === 'Select a template') {
      setEmailBody(initialValue);
      setEmailTemplateId('');
      setDisabled(true);
      return;
    } else {
      setDisabled(false);
    }

    setEmailTemplateId(templateId);

    const response = await axios.get(
      routes.LEGACY_EMAIL_TEMPLATES.SHOW(templateId)
    );
    setEmailBody(response.data.email_body);
  };

  const generateBody = async () => {
    if (!emailTemplateId) return;

    try {
      const response = await axios.get(
        `${routes.LEGACY_EMAIL_TEMPLATES.BOOKING_GENERATE(
          emailTemplateId
        )}?client_id=${bookingCalendar.client.id}&booking_calendar_id=${
          bookingCalendar.id
        }`
      );

      setEmailBody(response.data.html);
    } catch (error) {
      console.error(error);
    }
  };

  const sendEmail = async () => {
    try {
      setIsLoading(true);
      await axios.post(routes.CLIENTS.SEND_EMAIL, {
        client: {
          client_id: bookingCalendar.client.id,
          email_template_id: emailTemplateId,
          email_body: emailBody,
          booking_invitation_id: bookingCalendar.id,
        },
      });
      setIsLoading(false);
      toast.success('Success!');
      handleHide();
    } catch (error) {
      setIsLoading(false);
      if (error.response.data.error) {
        toast.error(error.response.data.error);
      }
      console.error(error);
    }
  };

  return (
    <div className={styles['BookingInvitationModal-email']}>
      <Dropdown
        name="email_template_id"
        options={emailTemplates}
        labelText="Email Template"
        onChangeCallback={fetchEmailBody}
      />
      <br />
      <TinyMceInput
        value={emailBody}
        onChangeCallback={setEmailBody}
        locations={locations}
        bookingUrls={bookingUrls}
      />
      <div className={styles['BookingInvitationModal-emailButtons']}>
        <Button onClick={() => generateBody()} text="Generate" white />
        <Button
          onClick={() => sendEmail()}
          text="Send"
          loading={isLoading}
          disabled={disabled}
        />
      </div>
      <br />
      <p>
        <strong>Or share this unique link:</strong>
      </p>
      <pre>{absolutePath(`/customer/booking/${bookingCalendar.uuid}`)}</pre>
      <div className={styles['BookingInvitationModal-emailButtons']}>
        <Button text="Finish" onClick={handleHide} white />
      </div>
    </div>
  );
};

export default SendEmailPage;
