import React, { useEffect, useState } from 'react';
import { Modal, TableNew as Table, Pill, Button } from '~ui';

const CampaignInfoModal = ({
  handleHide,
  campaignCommunications,
  getCampaignDetails,
  campaign,
  handleCancelCampaign,
  isFutureCampaign,
}) => {
  const [rows, setRows] = useState([]);
  const HEADERS = ['To', 'Status'];

  useEffect(() => {
    getCampaignDetails(campaign.id);
  }, []);

  useEffect(() => {
    if (campaignCommunications.length > 0) {
      const newRows = campaignCommunications.map(comm => {
        return createRowItem({
          id: comm.attributes.id,
          to: comm.attributes.to[0],
          status: comm.attributes.status.toUpperCase(),
        });
      });
      setRows(newRows);
    }
  }, [campaignCommunications]);

  const closeAndCancel = () => {
    handleCancelCampaign();
    handleHide();
  };

  const getStatusPill = status => {
    switch (status) {
      case 'SENT':
        return <Pill text={status} color="blue" />;
      case 'OPENED':
        return <Pill text={status} color="orange" />;
      case 'CLICKED':
        return <Pill text={status} color="green" />;
      case 'REJECTED':
        return <Pill text={status} color="red" />;
      default:
        break;
    }
  };

  const createRowItem = ({ id, to, status }) => ({
    id,
    editable: false,
    deletable: false,
    data: {
      to: {
        value: to,
        type: 'enum',
        editable: false,
      },
      status: {
        value: getStatusPill(status),
        type: 'enum',
        editable: false,
      },
    },
  });

  return (
    <Modal
      title={campaign.email_campaign_template.data.attributes.name}
      handleHide={handleHide}
    >
      {isFutureCampaign && (
        <div>
          <p>Would you like to cancel this campaign?</p>
          <div
            className="d-fr"
            style={{ display: 'flex', justifyContent: 'end' }}
          >
            <Button className="mr-8" danger text="Close" onClick={handleHide} />
            <Button text="Cancel Campaign" onClick={closeAndCancel} />
          </div>
        </div>
      )}
      {!isFutureCampaign && (
        <Table
          readOnly
          title={`This Campaign was sent to ${campaignCommunications.length} clients!`}
          headers={HEADERS}
          rows={rows}
          loading={false}
        />
      )}
    </Modal>
  );
};

export default CampaignInfoModal;
