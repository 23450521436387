import React from 'react';
import { useEffect } from 'react';
import CalendarEventForm from '~components/Calendar/CalendarEventForm';
import ToggleContent from '~components/common/ToggleContent';
import { getNearestHalfHourDateTime } from '~utils';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { DeleteConfirmation } from '~ui';
import styles from './UpcomingSessions.module.scss';
import moment from 'moment-timezone';
import { deleteCalendarEvent } from '~actions/calendarEvents';
import { absolutePath } from '~constants/routes';

const UpcomingSessions = ({ client_id, limitToThree = false }) => {
  const [upcomingSessions, setUpcomingSessions] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const timeZone = useSelector(state => state.calendar.meta.timeZone);

  const timezonesAreSame =
    moment().tz(moment.tz.guess()).format('z') ==
    moment().tz(timeZone).format('z');

  const formatString = timezonesAreSame ? 'h:mm a' : 'h:mm a (z)';

  const dispatch = useDispatch();

  const calendarEvents = useSelector(state => state.calendar.clientEvents.data);

  const grabEvents = async () => {
    let upcomingEvents = [];
    const sortedEvents = calendarEvents.sort((a, b) =>
      a.attributes.start_time > b.attributes.start_time ? 1 : -1
    );
    sortedEvents.forEach(event => {
      const eventData = event.attributes;
      if (new Date(eventData.end_time).getTime() > new Date().getTime()) {
        upcomingEvents.push(eventData);
      }
    });
    setUpcomingSessions(upcomingEvents);
  };

  useEffect(() => {
    grabEvents();
  }, [calendarEvents]);

  const setEventForDelete = id => {
    setDeleteId(id);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    try {
      dispatch(deleteCalendarEvent(deleteId));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    createUpcomingSessions();
  }, [upcomingSessions]);

  const createUpcomingSessions = () => {
    let newSessions = [];

    if (upcomingSessions.length !== 0) {
      const itemsToMap = limitToThree
        ? upcomingSessions.slice(0, 3)
        : upcomingSessions;

      newSessions = itemsToMap.map(session => {
        const month = moment.utc(session.start_time).tz(timeZone).format('MMM');
        const day = moment.utc(session.start_time).tz(timeZone).format('DD');
        const needsRescheduled = session.status === 'needs_rescheduled';
        const formattedStartTime = session.all_day
          ? 'all-day'
          : moment.utc(session.start_time).tz(timeZone).format(formatString);
        return (
          <ToggleContent
            key={session.id}
            toggle={handleShow => (
              <div onClick={handleShow} className={styles['eventBox']}>
                <div className={styles['firstChild']}>
                  <div>
                    <strong>{month}</strong>
                  </div>
                  <div>
                    <strong>{day}</strong>
                  </div>
                </div>
                <div className={styles['secondChild']}>
                  {needsRescheduled && (
                    <strong>
                      <i className="c-bl fa fa-exclamation-triangle"></i> Needs
                      Rescheduled:{' '}
                    </strong>
                  )}
                  {session.title} @ {formattedStartTime}
                </div>
              </div>
            )}
            content={handleHide => (
              <CalendarEventForm
                event={session}
                day={Date.parse(getNearestHalfHourDateTime())}
                handleHide={handleHide}
                clientOverride={client_id}
                deleteCallback={setEventForDelete}
              />
            )}
          />
        );
      });

      if (limitToThree && upcomingSessions.length > 3) {
        newSessions.push(
          <a
            key="see-more"
            href={absolutePath(`/clients/${client_id}/sessions`)}
          >
            See more...
          </a>
        );
      }
    } else {
      newSessions.push(
        <div key="no-content" className={styles['noContentBox']}>
          No upcoming sessions found for this client.
        </div>
      );
    }

    return newSessions;
  };

  return (
    <>
      {createUpcomingSessions()}
      {showDeleteModal && (
        <DeleteConfirmation
          handleHide={() => setShowDeleteModal(false)}
          handleDelete={handleDelete}
          resource="event"
        />
      )}
    </>
  );
};

export default UpcomingSessions;
