import React, { useState } from 'react';
import { Modal, Button, Dropdown, Icon } from '~ui';
import { useSelector } from 'react-redux';
import { routes } from '~constants/routes';
import axios from 'axios';
import styles from '../DocumentsContent/DocumentsContent.module.scss';
import DocumentUploadModal from '../DocumentUploadModal';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import SendLimitReached from '../../common/SendLimitReached';

const DocumentsButtons = ({ documentId, documentName, documentUrl }) => {
  const { clientsOptions, activeTrial, studioId, trialVerified } = useSelector(
    state => state.documents
  );
  const { clientId: initialClientId } = useSelector(state => state.documents);

  const [showSendModal, setShowSendModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [clientId, setClientId] = useState(initialClientId);
  const [miniLoading, setMiniLoading] = useState(false);

  const clientFullName = initialClientId
    ? clientsOptions.find(opt => opt.value == clientId).label
    : '';

  const [showBeforeSend, setShowBeforeSend] = useState(false);

  const checkValidation = async () => {
    if (activeTrial && !trialVerified) {
      setMiniLoading(true);

      const res = await axios.get(
        routes.STUDIO_PROFILE.CHECK_SEND_LIMIT(studioId)
      );
      if (res.data.reached_send_limit) {
        setMiniLoading(false);

        setShowBeforeSend(true);
      } else {
        setMiniLoading(false);

        setShowSendModal(true);
      }
    } else {
      setShowSendModal(true);
    }
  };

  const handleSend = async () => {
    try {
      const res = await axios.put(routes.DOCUMENTS.SEND_IT(documentId), {
        client_id: clientId,
      });

      if (res.status === 204) {
        toast.info('Document Sent!', toastOptions);
      }

      setShowSendModal(false);
    } catch (error) {
      Rollbar.error('Error sending document');
      if (error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Error sending document');
        setShowSendModal(false);
      }
    }
  };

  const handleDownload = () => {
    window.open(documentUrl, '_blank');
    setShowDownloadModal(false);
  };

  return (
    <>
      <a
        onClick={e => {
          e.stopPropagation();
          checkValidation();
        }}
      >
        Send
      </a>
      <a
        onClick={e => {
          e.stopPropagation();
          setShowUpdateModal(true);
        }}
      >
        Update
      </a>
      <a
        onClick={e => {
          e.stopPropagation();
          setShowDownloadModal(true);
        }}
      >
        Download
      </a>
      {showSendModal && (
        <Modal
          title={`Send ${documentName}`}
          handleHide={() => setShowSendModal(false)}
        >
          {initialClientId ? (
            <div>
              Are you sure you'd like to send this document to {clientFullName}?
            </div>
          ) : (
            <Dropdown
              name="client"
              labelText="Client"
              onChangeCallback={setClientId}
              options={clientsOptions}
            />
          )}
          <div className={styles.DocumentModalButtonContainer}>
            <Button
              text="Cancel"
              onClick={() => setShowSendModal(false)}
              danger
            />
            <Button text="Send" onClick={handleSend} disabled={!clientId} />
          </div>
        </Modal>
      )}
      {showDownloadModal && (
        <Modal title="Download" handleHide={() => setShowDownloadModal(false)}>
          Are you sure you want to download {documentName}?
          <div className={styles.DocumentModalButtonContainer}>
            <Button
              text="Cancel"
              onClick={() => setShowDownloadModal(false)}
              danger
            />
            <Button text="Confirm" onClick={handleDownload} />
          </div>
        </Modal>
      )}
      {showUpdateModal && (
        <DocumentUploadModal
          handleHide={() => setShowUpdateModal(false)}
          documentId={documentId}
        />
      )}
      {miniLoading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingText}>
            <p>Loading...</p>
            <Icon name="spinner" className="fa-pulse" large />
          </div>
        </div>
      )}
      {showBeforeSend && (
        <SendLimitReached handleHide={() => setShowBeforeSend(false)} />
      )}
    </>
  );
};

export default DocumentsButtons;
