import React from 'react';
import ClientDetails from './ClientDetails';
import SessionHolder from '../SessionsHolder/SessionHolder';
import styles from './ClientDetailsRoot.module.scss';
const ClientDetailsRoot = props => {
  const {
    client_id,
    client,
    sessionTypes,
    journeyTemplates,
    invoiceTemplates,
    contracts,
    emailTemplates,
    locationVariables,
    email,
    companyName,
    emailValidated,
    alternateEmail,
    phoneNumber,
    secondaryPhone,
    fullAddress,
    address,
    city,
    state,
    zip,
    country,
    countryOptions,
    weddingDate,
    referredBy,
    instagramHandle,
    facebookProfile,
    emailMarketingEnabled,
    emailMarketingUnsubscribeDate,
    subscribedToEmailMarketing,
    notes,
    dateAdded,
    comments,
    children,
    spouse,
    id,
    firstName,
    lastName,
    timeZone,
    bookingEnabled,
    calendar,
    birthday,
  } = props;

  return (
    <>
      <h1>Client Details</h1>
      <div className={styles.FlexContainer}>
        <ClientDetails
          birthday={birthday}
          dateAdded={dateAdded}
          emailMarketingEnabled={emailMarketingEnabled}
          emailMarketingUnsubscribeDate={emailMarketingUnsubscribeDate}
          emailValidated={emailValidated}
          referredBy={referredBy}
          subscribedToEmailMarketing={subscribedToEmailMarketing}
          children={children}
          spouse={spouse}
          id={id}
          comments={comments}
          email={email}
          alternateEmail={alternateEmail}
          phoneNumber={phoneNumber}
          secondaryPhone={secondaryPhone}
          companyName={companyName}
          facebookProfile={facebookProfile}
          instagramHandle={instagramHandle}
          notes={notes}
          city={city}
          state={state}
          zip={zip}
          country={country}
          address={address}
          firstName={firstName}
          lastName={lastName}
          fullAddress={fullAddress}
          weddingDate={weddingDate}
          countryOptions={countryOptions}
          bookingEnabled={bookingEnabled}
        />
        <SessionHolder
          bookingEnabled={bookingEnabled}
          calendar={calendar}
          client={client}
          locations={calendar.locations}
          sessionTypes={sessionTypes}
          journeyTemplates={journeyTemplates}
          invoiceTemplates={invoiceTemplates}
          contracts={contracts}
          emailTemplates={emailTemplates}
          locationVariables={locationVariables}
          timeZone={timeZone}
          client_id={client_id}
        />
      </div>
    </>
  );
};

export default ClientDetailsRoot;
