import { useEffect } from 'react';
import PropTypes from 'prop-types';

const KeydownListener = ({ keyCode, onKeyDown }) => {
  const handleKeyDown = e => {
    if (e.keyCode === keyCode) {
      onKeyDown(e);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  return null;
};

KeydownListener.propTypes = {
  keyCode: PropTypes.number.isRequired,
  onKeyDown: PropTypes.func.isRequired,
};

export default KeydownListener;
