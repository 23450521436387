import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './Checkbox.module.scss';

const Checkbox = props => {
  const {
    disabled,
    checked,
    className,
    inline,
    labelText,
    name,
    onChangeCallback,
    required,
    lightGrey,
    purpleBorder,
    whiteBackground,
  } = props;

  const cx = classNames.bind(styles);
  const classes = cx('Checkbox', {
    'Checkbox--disabled': disabled,
    'Checkbox--inline': inline,
    'Checkbox--lightGrey': lightGrey,
    'Checkbox--purpleBorder': purpleBorder,
    'Checkbox--whiteBackground': whiteBackground,
    [className]: !!className,
  });

  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <div className={classes}>
      {labelText && (
        <label htmlFor={name}>
          {labelText}
          {required && '*'}
        </label>
      )}
      <input
        name={name}
        type="checkbox"
        required={required}
        disabled={disabled}
        checked={isChecked}
        onChange={e => {
          const { checked } = e.currentTarget;
          setIsChecked(checked);
          onChangeCallback(checked);
        }}
      />
    </div>
  );
};

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  className: PropTypes.string,
  inline: PropTypes.bool,
  labelText: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChangeCallback: PropTypes.func,
  required: PropTypes.bool,
};

Checkbox.defaultProps = {
  checked: false,
  className: undefined,
  disabled: false,
  inline: false,
  onChangeCallback: () => {},
  required: false,
};

export default Checkbox;
