import React from 'react';
import styles from './Dashboard.module.scss';
import DashboardHeader from './DashboardHeader';
import UpcomingEvents from './UpcomingEvents';
import Todos from './Todos';
import ClientActivity from './ClientActivity';
import Chart from './Chart';
import WhatsNew from './WhatsNew';

const Dashboard = props => {
  const { spudUser, currency, studioTimezone } = props;
  return (
    <div className={styles.Dashboard}>
      <DashboardHeader {...{ spudUser }} />
      <div className={styles['Dashboard-tilesContainer']}>
        <div className={styles['Dashboard-tilesContainer-tile']}>
          <UpcomingEvents studioTimezone={studioTimezone} />
          <ClientActivity />
        </div>
        <div className={styles['Dashboard-tilesContainer-tile']}>
          <Todos />
          <Chart {...{ currency }} />
          <WhatsNew />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
