import React, { useState, useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import LogoLayout from './LogoLayout';
import PhotoLayout from './PhotoLayout';

const CustomerLeadsPageRoot = props => {
  const {
    displayLogo,
    displaySessionIcons,
    headline,
    submitColor,
    logo,
    sessionTypes,
    sitekey,
    studioId,
    layout,
    customFields,
    leadsPhoto,
    transparentBackground,
    showEmailMarketing,
    redirectURL,
    customSessionOrder,
  } = props;
  const [chosenSessionTypeIds, setChosenSessionTypeIds] = useState([]);
  const [chosenSessionTypeNames, setChosenSessionTypeNames] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [sessionTypeList, setSessionTypeList] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (!customSessionOrder) {
      const dropdownOptionsMap = sessionTypes.map(st => ({
        label: st.name,
        value: st.id,
      }));

      const sessionTypeIconsMap = sessionTypes.map(st => ({
        name: st.name,
        id: st.id,
        icon: st.icon,
      }));

      dropdownOptionsMap.sort((a, b) => a.label.localeCompare(b.label));
      sessionTypeIconsMap.sort((a, b) => a.name.localeCompare(b.name));

      dropdownOptionsMap.unshift({ label: 'Select a service', value: 0 });

      setSessionTypeList(sessionTypeIconsMap);
      setDropdownOptions(dropdownOptionsMap);
    } else {
      const withOrder = [];
      const withoutOrder = [];

      sessionTypes.forEach(item => {
        if (item.order !== null && item.order !== undefined) {
          withOrder.push(item);
        } else {
          withoutOrder.push(item);
        }
      });

      withOrder.sort((a, b) => a.order - b.order);

      withoutOrder.sort((a, b) => a.name.localeCompare(b.name));

      setSessionTypeList(withOrder.concat(withoutOrder));

      const dropdownOptionsMap = withOrder.concat(withoutOrder).map(st => ({
        label: st.name,
        value: st.id,
      }));

      dropdownOptionsMap.unshift({ label: 'Select a service', value: 0 });

      setDropdownOptions(dropdownOptionsMap);
    }
  }, []);

  const handleSessionType = val => {
    if (chosenSessionTypeIds.includes(val.id)) {
      setChosenSessionTypeIds(
        chosenSessionTypeIds.filter(prevChosenId => prevChosenId !== val.id)
      );
      setChosenSessionTypeNames(
        chosenSessionTypeNames.filter(prevName => prevName !== val.name)
      );
    } else {
      setChosenSessionTypeIds([val.id]);
      setChosenSessionTypeNames([val.name]);
    }
  };

  if (layout === 'logo') {
    return (
      <GoogleReCaptchaProvider reCaptchaKey={sitekey}>
        <LogoLayout
          {...{
            displayLogo,
            isSubmitted,
            headline,
            displaySessionIcons,
            sessionTypes: sessionTypeList,
            dropdownOptions,
            chosenSessionTypeIds,
            setChosenSessionTypeIds,
            setChosenSessionTypeNames,
            handleSessionType,
            chosenSessionTypeNames,
            submitColor,
            sitekey,
            studioId,
            setIsSubmitted,
            logo,
            customFields,
            transparentBackground,
            showEmailMarketing,
            redirectURL,
          }}
        />
      </GoogleReCaptchaProvider>
    );
  } else if (layout === 'photo') {
    return (
      <GoogleReCaptchaProvider reCaptchaKey={sitekey}>
        <PhotoLayout
          {...{
            isSubmitted,
            headline,
            displaySessionIcons,
            sessionTypes: sessionTypeList,
            dropdownOptions,
            chosenSessionTypeIds,
            setChosenSessionTypeIds,
            setChosenSessionTypeNames,
            handleSessionType,
            chosenSessionTypeNames,
            submitColor,
            sitekey,
            studioId,
            setIsSubmitted,
            customFields,
            leadsPhoto,
            logo,
            displayLogo,
            transparentBackground,
            showEmailMarketing,
            redirectURL,
          }}
        />
      </GoogleReCaptchaProvider>
    );
  }
};

export default CustomerLeadsPageRoot;
