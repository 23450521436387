import React, { useState } from 'react';
import { Modal, Button, TextInput } from '~ui';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';

const BookingCalendarCreateModal = ({ handleHide, handleCreate }) => {
  const [title, setTitle] = useState('');

  const checkForLength = () => {
    if (title.length > 255) {
      toast.error('Please use less than 255 characters', toastOptions);
    } else {
      handleCreate(title);
    }
  };

  return (
    <Modal title="Create a Booking Calendar" handleHide={handleHide}>
      <div className="mb-8">What will your new Booking Calendar be called?</div>
      <TextInput
        name="titleTextInput"
        placeholder="Booking Calendar Title"
        onChangeCallback={setTitle}
        autoFocus
      />
      <br />
      <div className="d-fr">
        <Button className="mr-8" text="Cancel" onClick={handleHide} danger />
        <Button text="Save" onClick={() => checkForLength()} />
      </div>
    </Modal>
  );
};

export default BookingCalendarCreateModal;
