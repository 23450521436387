import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { Modal, Button } from '~ui';
import { deleteClient, bulkUpdate, getAllClients } from '~actions/clientList';
import { useDispatch } from 'react-redux';

const DeleteClientModal = props => {
  const { handleHide, idsForDelete, setClientIdList, setChecked } = props;

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const deleteClients = async () => {
    setIsLoading(true);
    const bulkDelete = idsForDelete.length > 1;

    if (bulkDelete) {
      const res = await dispatch(
        bulkUpdate({ updateAction: 'destroy', clientIds: idsForDelete })
      );
      setIsLoading(false);
      dispatch(getAllClients());
      if (res.errors) {
        toast.error(
          `Error deleting! Please reach out to support.`,
          toastOptions
        );
      } else {
        toast.success(`Delete successful!`, toastOptions);
        await dispatch(getAllClients());
      }
    } else {
      const res = await dispatch(deleteClient(idsForDelete[0]));
      setIsLoading(false);

      if (res.errors) {
        toast.error(
          `Error deleting client! Please reach out to support.`,
          toastOptions
        );
      } else {
        toast.success(`Delete successful!`, toastOptions);
      }
    }
    setChecked(false);
    setClientIdList([]);
    handleHide();
  };

  return (
    <Modal title={`Delete Client(s)`} handleHide={handleHide}>
      <div className="mb-8">
        Are you sure you want to permanently delete? This action is irreversible
        and you'll lose access to all invoices, events, to-dos, contracts, and
        other associated data.
      </div>
      <br />
      <div className="d-fr">
        <Button
          className="mr-8"
          text="Cancel"
          onClick={e => {
            e.stopPropagation();
            handleHide();
          }}
          danger
        />
        <Button
          text="Delete"
          onClick={e => {
            e.stopPropagation();
            deleteClients();
          }}
          loading={isLoading}
        />
      </div>
    </Modal>
  );
};

export default DeleteClientModal;
