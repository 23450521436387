import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { Modal, Button } from '~ui';
import { deleteCoupon, getCouponCodeList } from '~actions/couponCodes';
import { useDispatch } from 'react-redux';

const DeleteCouponModal = props => {
  const { handleHide, idForDelete } = props;

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const deleteCouponCode = async () => {
    setIsLoading(true);
    const deleteRes = await dispatch(deleteCoupon(idForDelete));

    if (deleteRes.errors) {
      toast.error(
        `Error deleting coupon. Please reach out to support.`,
        toastOptions
      );
      setIsLoading(false);
    } else {
      await dispatch(getCouponCodeList());
      toast.success(`Coupon deleted!`, toastOptions);
      handleHide();
    }
  };

  return (
    <Modal title={`Delete Coupon Code`} handleHide={handleHide}>
      <div className="mb-8">
        Are you sure you want to delete this coupon code?
      </div>
      <br />
      <div className="d-fr">
        <Button
          className="mr-8"
          text="Cancel"
          onClick={e => {
            e.stopPropagation();
            handleHide();
          }}
          danger
        />
        <Button
          text="Delete"
          onClick={e => {
            e.stopPropagation();
            deleteCouponCode();
          }}
          loading={isLoading}
        />
      </div>
    </Modal>
  );
};

export default DeleteCouponModal;
