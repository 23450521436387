import React from 'react';
import { Modal } from '~ui';
import styles from './DetailsModal.module.scss';

const DetailsModal = props => {
  const { emailDetails, emailAttachments, setOpenModal } = props;

  const handleHide = () => {
    setOpenModal(false);
  };

  return (
    <Modal title={emailDetails.email_subject} handleHide={handleHide}>
      <div
        className={styles.Details}
        dangerouslySetInnerHTML={{ __html: emailDetails.email_body }}
      />
      <div style={{ display: 'grid' }}>
        {emailAttachments.length > 0 && <div>Attachments:</div>}
        {emailAttachments.length > 0 &&
          emailAttachments.map((attachment, index) => {
            return (
              <a
                key={index}
                href={attachment.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {attachment.filename}
              </a>
            );
          })}
      </div>
    </Modal>
  );
};

export default DetailsModal;
