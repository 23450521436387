import React, { useState } from 'react';
import axios from 'axios';
import { Modal } from '~ui';
import ToggleContent from '~components/common/ToggleContent';
import { routes } from '~constants/routes';
import SelectAvailabilityPage from './SelectAvailabilityPage';
import SendEmailPage from './SendEmailPage';
import SendLimitReached from '../common/SendLimitReached';

const BookingInvitationModal = props => {
  const {
    client,
    bookingCalendar,
    calendar,
    sessionTypes,
    journeyTemplates,
    contracts,
    locations,
    invoiceTemplates,
    emailTemplates,
    locationVariables,
    bookingUrls,
    activeTrial,
    studioId,
    trialVerified,
  } = props;

  const {
    hasShootProofIntegration,
    hasPicTimeIntegration,
    shootProofBrands,
  } = bookingCalendar.meta.features;

  const [formData, setFormData] = useState(bookingCalendar);
  const [pageIndex, setPageIndex] = useState(0);
  const [showBeforeSend, setShowBeforeSend] = useState(false);
  const createDefaultCalendar = async () => {
    const response = await axios.post(routes.BOOKING_CALENDARS.CREATE, {
      booking_calendar: {
        title: `Session for ${client.first_name}`,
        client_id: client.id,
      },
    });

    setFormData(response.data.data.attributes);
  };

  const deleteDefaultCalendar = async () => {
    if (formData.id) {
      await axios.delete(routes.BOOKING_CALENDARS.DELETE(formData.id));
    }
  };

  const handleSave = async (
    bookingCalendar,
    leadDays,
    leadInterval,
    showLeadTime
  ) => {
    const res = await axios.put(
      routes.BOOKING_CALENDARS.UPDATE(bookingCalendar.id),
      {
        booking_calendar: {
          ...bookingCalendar,
          lead_days: showLeadTime ? leadDays * leadInterval : 0,
        },
      }
    );

    const publishRes = await axios.put(
      routes.BOOKING_CALENDARS.PUBLISH(bookingCalendar.id)
    );

    if (res.status > 399 || publishRes.status > 399) {
      setErrors(res.data.error.messages);
      return;
    }

    setPageIndex(1);
  };

  const checkSendLimit = async handleShow => {
    if (activeTrial && !trialVerified) {
      const res = await axios.get(
        routes.STUDIO_PROFILE.CHECK_SEND_LIMIT(studioId)
      );
      if (res.data.reached_send_limit) {
        setShowBeforeSend(true);
      } else {
        await createDefaultCalendar();
        handleShow();
      }
    } else {
      await createDefaultCalendar();
      handleShow();
    }
  };

  return (
    <>
      <ToggleContent
        toggle={handleShow => (
          <button
            type="button"
            className="btn btn-primary btn-wide"
            onClick={() => {
              checkSendLimit(handleShow);
            }}
            style={{
              marginBottom: '30px',
              marginLeft: '0px',
            }}
          >
            Booking
          </button>
        )}
        content={handleHide => (
          <Modal
            title={`Book ${client.first_name}`}
            handleHide={() => {
              handleHide();
              if (pageIndex === 0) {
                deleteDefaultCalendar();
              }
              setPageIndex(0);
            }}
          >
            {pageIndex === 0 ? (
              <SelectAvailabilityPage
                initialBookingCalendar={{
                  ...formData,
                  slotInterval: '60',
                  meta: {
                    lastPublished: null,
                    timeZone: bookingCalendar.meta.timeZone,
                  },
                }}
                calendar={calendar}
                sessionTypes={sessionTypes}
                journeyTemplates={journeyTemplates}
                contracts={contracts}
                locations={locations}
                invoiceTemplates={invoiceTemplates}
                handleSave={handleSave}
                hasShootProofIntegration={hasShootProofIntegration}
                hasPicTimeIntegration={hasPicTimeIntegration}
                shootProofBrands={shootProofBrands}
                isClient
              />
            ) : (
              <SendEmailPage
                emailTemplates={emailTemplates}
                locations={locationVariables}
                bookingUrls={bookingUrls}
                bookingCalendar={formData}
                handleHide={() => {
                  handleHide();
                  setPageIndex(0);
                }}
              />
            )}
          </Modal>
        )}
      />
      {showBeforeSend && (
        <SendLimitReached handleHide={() => setShowBeforeSend(false)} />
      )}
    </>
  );
};

export default BookingInvitationModal;
