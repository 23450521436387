import React, { useState } from 'react';
import PropTypes from 'prop-types';

/*
  This is a helper component solely responsible for toggling
  the visibility of content via two functions, `handleHide`
  and `handleShow`, that set the value of `isShown`. 
  
  The two props, `toggle` and `content`, are render props that
  are meant to return JSX to render. For `toggle`, the JSX 
  can be a button or an anchor tag that binds `handleShow` to an
  `onClick` prop. Similarly, `content` can return a button or anchor
  that has `handleHide` bound to an `onClick` prop.
*/
const ToggleContent = ({ toggle, content }) => {
  const [isShown, setIsShown] = useState(false);
  const handleHide = () => setIsShown(false);
  const handleShow = () => setIsShown(true);

  return (
    <>
      {toggle(handleShow)}
      {isShown && content(handleHide)}
    </>
  );
};

ToggleContent.propTypes = {
  toggle: PropTypes.func.isRequired,
  content: PropTypes.func.isRequired,
};

export default ToggleContent;
