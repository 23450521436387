import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './CustomDropdown.module.scss';

const CustomDropdown = ({ options, label, rowItem }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <div
      className={styles.dropdown}
      ref={dropdownRef}
      tabIndex={0}
      onBlur={() => setIsOpen(false)}
    >
      {rowItem ? (
        <div
          onClick={e => {
            e.stopPropagation();
            toggleDropdown();
          }}
        >
          <i
            style={{ paddingLeft: '20px', paddingRight: '20px' }}
            className="fas fa-ellipsis-v"
          ></i>{' '}
        </div>
      ) : (
        <div className={styles.dropdownSelected} onClick={toggleDropdown}>
          <p>
            <strong>{label}</strong>
          </p>{' '}
          <span className="fa fa-caret-down"></span>
        </div>
      )}

      {isOpen && (
        <div
          className={styles.dropdownList}
          style={rowItem && { right: '10px' }}
        >
          {options.map((option, index) => (
            <div
              key={index}
              className={styles.dropdownItem}
              onClick={e => {
                e.stopPropagation();
                option.onClickCallback();
                setIsOpen(false);
              }}
            >
              <p style={option.style}>{option.text}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

CustomDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string,
};

CustomDropdown.defaultProps = {
  label: 'Actions',
};

export default CustomDropdown;
