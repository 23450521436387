import axios from 'axios';
import { routes } from '~constants/routes';
import { QUESTIONNAIRES } from '~constants/actionTypes';

export const getQuestionnaires = () => async (dispatch, getState) => {
  try {
    const { meta } = getState().documents.questionnaires;
    const { page, limit } = meta;

    dispatch({ type: QUESTIONNAIRES.GET.REQUEST });

    const { data } = await axios.get(routes.QUESTIONNAIRES.INDEX, {
      params: { page, limit },
    });

    dispatch({
      key: 'questionnaires',
      type: QUESTIONNAIRES.GET.SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: QUESTIONNAIRES.GET.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};

export const getQuestionnaireResponses = (clientId = null) => async (
  dispatch,
  getState
) => {
  try {
    const { meta } = getState().documents.questionnaires;
    const { page, limit } = meta;

    dispatch({ type: QUESTIONNAIRES.GET.REQUEST });

    const { data } = await axios.get(routes.QUESTIONNAIRE_RESPONSES.INDEX, {
      params: {
        page,
        limit,
        ...(clientId ? { client_id: clientId } : null),
      },
    });

    dispatch({
      key: 'questionnaires',
      type: QUESTIONNAIRES.GET.SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: QUESTIONNAIRES.GET.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};

export const updateQuestionnairesMeta = params => ({
  type: QUESTIONNAIRES.META.UPDATE,
  payload: params,
});
