import React from 'react';
import { configureStore } from '../../store';
import { Provider } from 'react-redux';
import { metaMap } from './metaMap';
import ClientListSidebar from './ClientListSidebar';
import ClientsHeader from './ClientsHeader';
import styles from './ClientListRoot.module.scss';
import ClientListContent from './ClientListContent';

const ClientListRoot = props => {
  const searchParams = new URLSearchParams(document.location.search);
  const statusParam = searchParams.get('status');
  const initialStoreMeta = metaMap[statusParam] || metaMap.allActive;

  const store = configureStore({
    clientList: {
      clients: [],
      groups: [],
      idsForDelete: [],
      ...props,
      meta: {
        ...initialStoreMeta,
        total_records: 0,
        page_count: 0,
      },
    },
    couponCodes: {},
  });

  return (
    <Provider store={store}>
      <div className={styles['ClientListRoot']}>
        <div className={styles['ClientListRoot-flex']}>
          <div>
            <ClientListSidebar fromClients />
          </div>
          <div
            className={styles['ClientListRoot-flex-max-space']}
            style={{ marginBottom: '200px' }}
          >
            <ClientsHeader importsEnabled={props.importsEnabled} />
            <ClientListContent />
          </div>
        </div>
      </div>
    </Provider>
  );
};

export default ClientListRoot;
