import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  createLineItem,
  updateLineItem,
  deleteLineItem,
} from '~actions/invoiceLineItems';
import { getProducts } from '~actions/invoiceProducts';
import { getInvoice } from '~actions/invoice';
import { TableNew as Table } from '~ui';
import { ITEM_HEADERS } from '~constants/invoices';
import MobileInvoiceItems from '../MobileInvoiceItems';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import NewProductModal from '../NewProductModal';

const title = 'Line Items';
const subtitle = "Products and Services you're billing for";

const InvoiceItemsTable = ({ readOnly, isMobile }) => {
  const invoiceId = useSelector(state => state.invoice.id);
  const items = useSelector(state => state.invoice.items);
  const invoiceProducts = useSelector(state => state.invoiceProducts.products);
  const firstProduct = useSelector(state => state.invoice.invoiceProducts[0]);
  const [invoiceProductsOptions, setInvoiceProductOptions] = useState(
    useSelector(state => state.invoice.invoiceProductsOptions)
  );
  const [openProductModal, setOpenProductModal] = useState(false);
  const [rowId, setRowId] = useState(false);
  const [closeEditingExternally, setCloseEditingExternally] = useState(false);

  useEffect(() => {
    setRows(itemsToRows());
  }, [items]);

  useEffect(() => {
    let newArr = invoiceProductsOptions;
    newArr.unshift({
      label: '+ Add New',
      value: 'new product',
    });
    setInvoiceProductOptions(newArr);
  }, []);

  useEffect(() => {
    let options = invoiceProducts.map(product => {
      const name = product.name;
      const value = product.id;
      return { label: name, value: value };
    });
    options.sort(function (a, b) {
      var textA = a.label.toUpperCase();
      var textB = b.label.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    options.unshift({ label: '+ Add New', value: 'new product' });
    setInvoiceProductOptions(options);
  }, [invoiceProducts]);

  const dispatch = useDispatch();

  const asyncGetProducts = async () => dispatch(getProducts());

  const asyncCreate = async params => dispatch(createLineItem(params));

  const asyncUpdate = async (id, params) =>
    dispatch(updateLineItem(id, params));

  const asyncDelete = async (id, params) =>
    dispatch(deleteLineItem(id, params));

  const createRowItem = (
    id = null,
    productId,
    productDescription,
    productQuantity,
    productPrice,
    productTaxable,
    temp = false,
    options = invoiceProductsOptions
  ) => {
    return {
      id,
      editable: productId === -1 ? false : true,
      deletable: true,
      temp,
      data: {
        invoice_product_id: {
          value: +productId,
          type: 'enum',
          options: options,
          editable: true,
        },
        description: {
          value: productDescription,
          type: 'text',
          editable: true,
        },
        quantity: {
          value: productQuantity,
          type: 'number',
          editable: true,
          min: 1,
        },
        price: {
          value: +productPrice,
          type: 'currency',
          editable: true,
        },
        taxable: {
          value: productTaxable,
          type: 'boolean',
          editable: false,
        },
        total: {
          value: +productPrice * +productQuantity,
          type: 'currency',
          editable: false,
          disabled: true,
        },
      },
    };
  };

  const itemsToRows = () => {
    return items.map(item => {
      const {
        id,
        invoice_product_id,
        description,
        quantity,
        price,
        taxable,
        invoice_type,
      } = item;

      let options = invoiceProductsOptions;

      if (!invoice_product_id) {
        options.push({
          label: invoice_type,
          value: -1,
        });
      }

      return createRowItem(
        id,
        invoice_product_id || -1,
        description,
        quantity,
        price,
        taxable,
        false,
        options
      );
    });
  };

  const [rows, setRows] = useState([]);
  const [schema, setSchema] = useState(
    createRowItem(
      firstProduct.id,
      firstProduct.invoice_product_id,
      firstProduct.description,
      firstProduct.quantity,
      firstProduct.price,
      firstProduct.taxable
    )
  );

  const getInvoiceType = invoiceProductId => {
    let invoiceType;
    invoiceProductsOptions.forEach(invoiceProduct => {
      if (String(invoiceProduct.value) === String(invoiceProductId)) {
        invoiceType = invoiceProduct.label;
      }
    });

    return invoiceType;
  };

  const handleCreate = params => {
    const {
      description: { value: description },
      invoice_product_id: { value: invoice_product_id },
      price: { value: price },
      quantity: { value: quantity },
      taxable: { value: taxable },
    } = params;

    const wrappedParams = {
      description,
      invoice_product_id,
      quantity,
      taxable,
      price,
      invoice_id: invoiceId,
      invoice_type: params.name
        ? params.name
        : getInvoiceType(invoice_product_id),
    };

    asyncCreate(wrappedParams).then(() => {
      dispatch(getInvoice(invoiceId));
    });
    setCloseEditingExternally(true);
    return true;
  };

  const handleUpdate = async ({ id, body }) => {
    const {
      invoice_product_id: { value: invoice_product_id },
      description: { value: description },
      price: { value: price },
      quantity: { value: quantity },
      taxable: { value: taxable },
    } = body;

    const wrappedParams = {
      invoice_line_item: {
        description,
        invoice_id: invoiceId,
        price,
        quantity,
        taxable,
        invoice_type: body.name
          ? body.name
          : getInvoiceType(invoice_product_id),
        invoice_product_id,
      },
    };

    asyncUpdate(id, wrappedParams).then(() => {
      dispatch(getInvoice(invoiceId));
    });
    setCloseEditingExternally(true);
    return true;
  };

  const handleDelete = ({ id }) => {
    const wrappedParams = { invoice_id: invoiceId };
    asyncDelete(id, wrappedParams).then(() => {
      dispatch(getInvoice(invoiceId));
    });
  };

  const handlePriceChange = (rowIndex, value) => {
    const tempRows = rows;

    if (tempRows.length > 0) {
      const rowItem = tempRows[rowIndex];
      const { data } = rowItem;

      const quantity = +data.quantity.value;

      const price = value;
      const total = price * quantity;

      data.total.value = total;
      data.price.value = price;

      tempRows[rowIndex] = rowItem;

      setRows([...tempRows]);
    }
  };

  const handleQuantityChange = (rowIndex, value) => {
    const tempRows = rows;

    if (value < 1) {
      toast.error('Please enter a number greater than 0 ', toastOptions);
      return;
    }

    if (tempRows.length > 0) {
      const rowItem = tempRows[rowIndex];
      const { data } = rowItem;

      const price = +data.price.value;
      const total = price * value;

      data.total.value = total;
      data.quantity.value = value;

      tempRows[rowIndex] = rowItem;

      setRows([...tempRows]);
    }
  };

  const getProductList = async params => {
    if (params == undefined) {
      setOpenProductModal(false);
      return;
    } else if (rowId == 'create') {
      handleCreate({
        description: { value: params.description },
        invoice_product_id: { value: params.id },
        price: { value: params.price },
        quantity: { value: 1 },
        taxable: { value: params.taxable },
        name: params.name,
      });
    } else if (params) {
      handleUpdate({
        id: rowId,
        body: {
          description: { value: params.description },
          invoice_product_id: { value: params.id },
          price: { value: params.price },
          quantity: { value: 1 },
          taxable: { value: params.taxable },
          name: params.name,
        },
      });
    }

    try {
      await asyncGetProducts().then(() => {
        dispatch(getInvoice(invoiceId));
      });
      toast.success('Updated!');
      setOpenProductModal(false);
    } catch {
      toast.error('Error');
      setOpenProductModal(false);
    }
  };

  const handleProductSelectChange = (rowIndex, value) => {
    if (value === 'new product') {
      setOpenProductModal(true);
      return;
    }
    const selectedInvoiceProduct = invoiceProducts.find(invoiceProduct => {
      return +invoiceProduct.id === +value;
    });

    if (!selectedInvoiceProduct) {
      return;
    }

    const tempRows = rows;
    const rowItem = rows[rowIndex];

    rowItem.data.invoice_product_id.value = +selectedInvoiceProduct.id;
    rowItem.data.description.value = selectedInvoiceProduct.description;
    rowItem.data.price.value = +selectedInvoiceProduct.price;
    rowItem.data.total.value =
      +rowItem.data.quantity.value * selectedInvoiceProduct.price;
    rowItem.data.taxable.value = !!selectedInvoiceProduct.taxable;

    tempRows[rowIndex] = rowItem;

    setRows([...tempRows]);
  };

  const handleDescChange = (rowIndex, value) => {
    const tempRows = rows;

    if (value.trim() === '') {
      toast.error('A description is required', toastOptions);
      return;
    }

    if (value.length > 255) {
      toast.error(
        `Can't save. Description is too long. ${value.length}/255 characters.`,
        toastOptions
      );
      return;
    }

    if (tempRows.length > 0) {
      const rowItem = tempRows[rowIndex];
      const { data } = rowItem;

      data.description.value = value;

      tempRows[rowIndex] = rowItem;

      setRows([...tempRows]);
    }
  };

  const editFunctions = [
    {
      name: 'invoice_product_id',
      fn: handleProductSelectChange,
    },
    {
      name: 'quantity',
      fn: handleQuantityChange,
    },
    {
      name: 'price',
      fn: handlePriceChange,
    },
    {
      name: 'description',
      fn: handleDescChange,
    },
  ];

  const hooks = {
    create: handleCreate,
    update: handleUpdate,
    delete: handleDelete,
  };

  useEffect(() => {
    setRows(itemsToRows());
  }, [items]);

  useEffect(() => {
    const newSchema = createRowItem(
      null,
      firstProduct.id,
      firstProduct.description,
      1,
      firstProduct.price,
      firstProduct.taxable
    );
    setSchema(newSchema);
  }, [rows]);

  if (isMobile) {
    return (
      <>
        {openProductModal && (
          <NewProductModal
            getProductList={getProductList}
            itemList={items}
            invoiceId={invoiceId}
          />
        )}
        <MobileInvoiceItems
          readOnly={readOnly}
          title={title}
          subtitle={subtitle}
          handleCreate={handleCreate}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
          setOpenProductModal={setOpenProductModal}
          invoiceProductsOptions={invoiceProductsOptions}
          setRowId={setRowId}
        />
      </>
    );
  } else {
    return (
      <>
        {openProductModal && (
          <NewProductModal
            getProductList={getProductList}
            itemList={items}
            invoiceId={invoiceId}
          />
        )}
        <Table
          editFunctions={editFunctions}
          headers={ITEM_HEADERS}
          rows={rows}
          schema={schema}
          setRows={setRows}
          title={title}
          subtitle={subtitle}
          hooks={hooks}
          setRowId={setRowId}
          closeEditingExternally={closeEditingExternally}
          setCloseEditingExternally={setCloseEditingExternally}
          floatNewRowToTop
          type="dynamic"
        />
      </>
    );
  }
};

InvoiceItemsTable.propTypes = {
  readOnly: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
};

InvoiceItemsTable.defaultProps = {
  readOnly: false,
};

export default InvoiceItemsTable;
