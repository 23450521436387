import React from 'react';
import PropTypes from 'prop-types';

import { Editor } from '@tinymce/tinymce-react';

import {
  tinymceHeight,
  vendorButtons,
  customButtons,
  vendorPlugins,
  blockFormats,
  validElements,
  contentStyle,
  variables,
} from './editor_config';

class TinymceEditor extends React.PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    initProps: PropTypes.object,
    uploadImage: PropTypes.func.isRequired,
    fetchImageList: PropTypes.func.isRequired,
    bookingUrls: PropTypes.array,
    locations: PropTypes.array,
  };

  state = {
    error: false,
  };

  fileInputRef = React.createRef();
  tinyMceFilePickerCallback = null;

  componentDidCatch(e) {
    console.error('tinymce', e);
    this.setState({ error: true });
  }

  openFilePicker = callback => {
    this.tinyMceFilePickerCallback = callback;
    this.fileInputRef.current.click();
  };

  filePicked = clickEvent => {
    if (!this.tinyMceFilePickerCallback) {
      return;
    }

    const file = clickEvent.target.files[0];
    const callback = this.tinyMceFilePickerCallback;
    this.props.uploadImage(file, callback);
    this.tinyMceFilePickerCallback = null;
  };

  render() {
    const {
      value,
      onChange,
      initProps,
      fetchImageList,
      bookingUrls,
      locations,
    } = this.props;
    if (this.state.error) {
      return <p>Error with email editor</p>;
    }

    const setup = editor => {
      editor.ui.registry.addMenuButton('variablesReact', {
        text: 'Variables',
        fetch: function (callback) {
          var items = variables.map(function (item) {
            return {
              type: 'menuitem',
              text: item[1],
              onAction: function (_) {
                editor.insertContent(item[0]);
              },
            };
          });
          callback(items);
        },
      });

      if (bookingUrls.length) {
        editor.ui.registry.addMenuButton('bookingUrlsReact', {
          text: 'Booking URLs',
          fetch: function (callback) {
            var items = bookingUrls.map(function (item) {
              return {
                type: 'menuitem',
                text: item[1],
                onAction: function (_) {
                  editor.insertContent(
                    '<a href="' + item[0] + '">' + item[1] + '</a>'
                  );
                },
              };
            });
            callback(items);
          },
        });
      }

      if (locations.length) {
        editor.ui.registry.addMenuButton('locationsReact', {
          text: 'Locations',
          fetch: function (callback) {
            var items = locations.map(function (location) {
              return {
                type: 'menuitem',
                text: location[1],
                onAction: function (_) {
                  editor.insertContent(
                    '<a href="' + location[0] + '">' + location[1] + '</a>'
                  );
                },
              };
            });
            callback(items);
          },
        });
      }
    };
    const plugins = vendorPlugins;
    const toolbar = vendorButtons.concat(customButtons).join(' ');

    return (
      <div>
        <Editor
          value={value || ''}
          init={{
            setup,
            plugins,
            toolbar,
            menubar: false,
            image_list: fetchImageList,
            content_style: contentStyle,
            paste_data_images: false,
            image_advtab: true,
            height: tinymceHeight,
            branding: false,
            convert_urls: false,
            theme: 'silver',
            language: 'en',
            imagetools_cors_hosts: ['*.iris-works.com'],
            browser_spellcheck: true,
            block_formats: blockFormats.join(';'),
            valid_elements: validElements.join(','),
            file_picker_callback: (callback, _, meta) => {
              if (meta.filetype === 'image') {
                this.openFilePicker(callback);
              }
            },
            ...(initProps || {}),
          }}
          onEditorChange={onChange}
        />
        <input
          type="file"
          ref={this.fileInputRef}
          hidden
          style={{ display: 'none' }}
          onChange={this.filePicked}
        />
      </div>
    );
  }
}

export default TinymceEditor;
