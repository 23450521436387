import React, { useState } from 'react';
import { useEffect } from 'react';
import { Button, Modal, Dropdown } from '~ui';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import axios from 'axios';
import styles from './BulkAddToGroupModal.module.scss';

const BulkAddToGroupModal = ({
  handleHide,
  clientIdList,
  setClientIdList,
  setChecked,
}) => {
  const [groupList, setGroupList] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clientMap, setClientMap] = useState({});

  const getGroupList = async () => {
    setIsLoading(true);
    const res = await axios.get('/api/groups', {
      params: { grab_all_groups: true },
    });
    const rawGroupList = res.data.data.map(group => {
      return { label: group.attributes.name, value: group.attributes.id };
    });
    rawGroupList.unshift({ label: 'Select group', value: '' });

    setGroupList(rawGroupList);
    setIsLoading(false);
  };

  const grabCurrentClients = async () => {
    setLoading(true);
    const res = await axios.get(
      `/api/group_clients/grab_group_clients?group_id=${selectedGroup}`
    );
    const clientMap = res.data.reduce((map, client) => {
      map[client.id] = client;
      return map;
    }, {});
    setClientMap(clientMap);
    setLoading(false);
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);
      const filteredAndTransformedClients = clientIdList.reduce(
        (acc, clientId) => {
          if (!clientMap[clientId.toString()]) {
            acc.push({ client_id: Number(clientId) });
          }
          return acc;
        },
        []
      );

      let params = {
        group: {
          id: selectedGroup,
          group_clients_attributes: filteredAndTransformedClients,
        },
      };
      await axios.put(`/api/groups/${selectedGroup}`, params);
      setLoading(false);
      toast.success(`Added to group!`, toastOptions);
    } catch (error) {
      toast.error(`Error adding to group!`, toastOptions);
    }
    setChecked(false);
    setClientIdList([]);
    handleHide();
  };

  useEffect(() => {
    getGroupList();
  }, []);

  useEffect(() => {
    if (selectedGroup) {
      grabCurrentClients();
    }
  }, [selectedGroup]);

  return (
    <Modal title="Add to Group" handleHide={handleHide} maxWidth={500}>
      {isLoading ? (
        <h1>Loading...</h1>
      ) : (
        <div>
          <div className={styles.AddToGroupModal}>
            <Dropdown
              name="Group Name"
              initialValue={''}
              onChangeCallback={e => {
                setSelectedGroup(Number(e));
              }}
              options={groupList}
            />
          </div>
          <br />
          <div className="d-fr">
            <Button
              className="mr-8"
              text="Cancel"
              onClick={e => {
                e.stopPropagation();
                handleHide();
              }}
              whiteRectangle={true}
            />
            <Button
              text="Add to Group"
              onClick={e => {
                e.stopPropagation();
                handleUpdate();
              }}
              loading={loading}
              purpleRectangle={true}
              disabled={loading}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default BulkAddToGroupModal;
