import React from 'react';
import { Modal, Button } from '~ui';

const NotesAndCommentsModal = props => {
  const { notes, comments, notesOrComments, setNotesOrComments } = props;

  const closeModal = () => {
    setNotesOrComments(false);
  };

  return (
    <>
      <Modal
        title={notesOrComments == 'comments' ? 'Lead Comments' : 'Notes'}
        handleHide={closeModal}
      >
        {notesOrComments == 'comments' && (
          <div
            dangerouslySetInnerHTML={{ __html: comments }}
            style={{ whiteSpace: 'pre-line' }}
          />
        )}
        {notesOrComments == 'notes' && (
          <div style={{ whiteSpace: 'pre-line' }}>{notes}</div>
        )}
        <br />
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button danger text={'Close'} onClick={closeModal} />
        </div>
      </Modal>
    </>
  );
};

export default NotesAndCommentsModal;
