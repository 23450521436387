export const statusMetaMap = {
  open: {
    status: 'open',
    is_quote: false,
    page: 1,
    titleDisplay: 'Open Invoices',
    search_term: null,
    sortColumn: 'invoice_num',
    sortDirection: 'desc',
    invoicesExpanded: true,
    codesExpanded: false,
  },
  late: {
    status: 'late',
    is_quote: false,
    page: 1,
    titleDisplay: 'Late Invoices',
    search_term: null,
    sortColumn: 'due_date',
    sortDirection: 'asc',
    invoicesExpanded: true,
    codesExpanded: false,
  },
  paid: {
    status: 'paid',
    is_quote: false,
    page: 1,
    titleDisplay: 'Paid Invoices',
    search_term: null,
    sortColumn: 'due_date',
    sortDirection: 'desc',
    invoicesExpanded: true,
    codesExpanded: false,
  },
  partially_paid: {
    status: 'partially_paid',
    is_quote: false,
    page: 1,
    titleDisplay: 'Partially Paid Invoices',
    search_term: null,
    sortColumn: 'due_date',
    sortDirection: 'desc',
    invoicesExpanded: true,
    codesExpanded: false,
  },
  unpaid: {
    status: 'unpaid',
    is_quote: false,
    page: 1,
    titleDisplay: 'Unpaid Invoices',
    search_term: null,
    sortColumn: 'due_date',
    sortDirection: 'desc',
    invoicesExpanded: true,
    codesExpanded: false,
  },
  refunded: {
    status: 'refunded',
    is_quote: false,
    page: 1,
    titleDisplay: 'Refunded Invoices',
    search_term: null,
    sortColumn: 'due_date',
    sortDirection: 'desc',
    invoicesExpanded: true,
    codesExpanded: false,
  },
  partially_refunded: {
    status: 'partially_refunded',
    is_quote: false,
    page: 1,
    titleDisplay: 'Partially Refunded Invoices',
    search_term: null,
    sortColumn: 'due_date',
    sortDirection: 'desc',
    invoicesExpanded: true,
    codesExpanded: false,
  },
  draft: {
    status: 'draft',
    is_quote: false,
    page: 1,
    titleDisplay: 'Draft Invoices',
    search_term: null,
    sortColumn: 'due_date',
    sortDirection: 'asc',
    invoicesExpanded: true,
    codesExpanded: false,
  },
  all: {
    status: 'all',
    is_quote: false,
    page: 1,
    titleDisplay: 'All Invoices',
    search_term: null,
    sortColumn: 'due_date',
    sortDirection: 'asc',
    invoicesExpanded: true,
    codesExpanded: false,
  },
  pending_quote: {
    status: 'pending_quote',
    is_quote: true,
    page: 1,
    titleDisplay: 'Pending Quotes',
    search_term: null,
    sortColumn: 'due_date',
    sortDirection: 'asc',
    quotesExpanded: true,
    codesExpanded: false,
  },
  mod_requested_quote: {
    status: 'mod_requested_quote',
    is_quote: true,
    titleDisplay: 'Needs Review Quotes',
    search_term: null,
    sortColumn: 'due_date',
    sortDirection: 'asc',
    quotesExpanded: true,
    codesExpanded: false,
  },
  declined_quote: {
    status: 'declined_quote',
    is_quote: true,
    page: 1,
    titleDisplay: 'Declined Quotes',
    search_term: null,
    sortColumn: 'due_date',
    sortDirection: 'asc',
    quotesExpanded: true,
    codesExpanded: false,
  },
  expired_quote: {
    status: 'expired_quote',
    is_quote: true,
    page: 1,
    titleDisplay: 'Expired Quotes',
    search_term: null,
    sortColumn: 'due_date',
    sortDirection: 'asc',
    quotesExpanded: true,
    codesExpanded: false,
  },
  draft_quote: {
    status: 'draft_quote',
    is_quote: true,
    page: 1,
    titleDisplay: 'Draft Quotes',
    search_term: null,
    sortColumn: 'due_date',
    sortDirection: 'asc',
    quotesExpanded: true,
    codesExpanded: false,
  },
  quotes: {
    status: 'quotes',
    is_quote: true,
    page: 1,
    titleDisplay: 'All Quotes',
    search_term: null,
    sortColumn: 'due_date',
    sortDirection: 'asc',
    quotesExpanded: true,
    codesExpanded: false,
  },
  couponCodes: {
    status: 'codes',
    is_quote: false,
    page: 1,
    titleDisplay: 'Coupon Codes',
    search_term: null,
    sortColumn: 'due_date',
    sortDirection: 'asc',
    codesExpanded: true,
  },
};

export const getOptions = (
  meta,
  dispatch,
  getInvoicesList,
  updateInvoiceListMeta,
  getCouponCodeList
) => [
  {
    title: 'Invoices',
    active: meta.invoicesExpanded,
    onClickCallback: () => {
      dispatch(
        updateInvoiceListMeta({
          invoicesExpanded: !meta.invoicesExpanded,
        })
      );
    },
    subOptions: [
      {
        title: 'Open',
        onClickCallback: () => {
          dispatch(updateInvoiceListMeta(statusMetaMap['open']));
          dispatch(getInvoicesList());
        },
        active: meta.status === 'open',
      },
      {
        title: 'Late',
        onClickCallback: () => {
          dispatch(updateInvoiceListMeta(statusMetaMap['late']));
          dispatch(getInvoicesList());
        },
        active: meta.status === 'late',
      },
      {
        title: 'Paid',
        onClickCallback: () => {
          dispatch(updateInvoiceListMeta(statusMetaMap['paid']));
          dispatch(getInvoicesList());
        },
        active: meta.status === 'paid',
      },
      {
        title: 'Partially Paid',
        onClickCallback: () => {
          dispatch(updateInvoiceListMeta(statusMetaMap['partially_paid']));
          dispatch(getInvoicesList());
        },
        active: meta.status === 'partially_paid',
      },
      {
        title: 'Unpaid',
        onClickCallback: () => {
          dispatch(updateInvoiceListMeta(statusMetaMap['unpaid']));
          dispatch(getInvoicesList());
        },
        active: meta.status === 'unpaid',
      },
      {
        title: 'Refunded',
        onClickCallback: () => {
          dispatch(updateInvoiceListMeta(statusMetaMap['refunded']));
          dispatch(getInvoicesList());
        },
        active: meta.status === 'refunded',
      },
      {
        title: 'Partially Refunded',
        onClickCallback: () => {
          dispatch(updateInvoiceListMeta(statusMetaMap['partially_refunded']));
          dispatch(getInvoicesList());
        },
        active: meta.status === 'partially_refunded',
      },
      {
        title: 'Drafts',
        onClickCallback: () => {
          dispatch(updateInvoiceListMeta(statusMetaMap['draft']));
          dispatch(getInvoicesList());
        },
        active: meta.status === 'draft',
      },
      {
        title: 'All',
        onClickCallback: () => {
          dispatch(updateInvoiceListMeta(statusMetaMap['all']));
          dispatch(getInvoicesList());
        },
        active: meta.status === 'all',
      },
    ],
  },
  {
    title: 'Quotes',
    active: meta.quotesExpanded,
    onClickCallback: () => {
      dispatch(
        updateInvoiceListMeta({
          quotesExpanded: !meta.quotesExpanded,
        })
      );
    },
    subOptions: [
      {
        title: 'Pending',
        onClickCallback: () => {
          dispatch(updateInvoiceListMeta(statusMetaMap['pending_quote']));
          dispatch(getInvoicesList());
        },
        active: meta.status === 'pending_quote',
      },
      {
        title: 'Needs Review',
        onClickCallback: () => {
          dispatch(updateInvoiceListMeta(statusMetaMap['mod_requested_quote']));
          dispatch(getInvoicesList());
        },
        active: meta.status === 'mod_requested_quote',
      },
      {
        title: 'Declined',
        onClickCallback: () => {
          dispatch(updateInvoiceListMeta(statusMetaMap['declined_quote']));
          dispatch(getInvoicesList());
        },
        active: meta.status === 'declined_quote',
      },
      {
        title: 'Expired',
        onClickCallback: () => {
          dispatch(updateInvoiceListMeta(statusMetaMap['expired_quote']));
          dispatch(getInvoicesList());
        },
        active: meta.status === 'expired_quote',
      },
      {
        title: 'Draft',
        onClickCallback: () => {
          dispatch(updateInvoiceListMeta(statusMetaMap['draft_quote']));
          dispatch(getInvoicesList());
        },
        active: meta.status === 'draft_quote',
      },
      {
        title: 'All',
        onClickCallback: () => {
          dispatch(updateInvoiceListMeta(statusMetaMap['quotes']));
          dispatch(getInvoicesList());
        },
        active: meta.status === 'quotes',
      },
    ],
  },
  {
    title: 'Coupon Codes',
    active: meta.codesExpanded,
    noChevron: true,
    onClickCallback: () => {
      dispatch(
        updateInvoiceListMeta({
          codesExpanded: !meta.codesExpanded,
        })
      );
      dispatch(updateInvoiceListMeta(statusMetaMap['couponCodes']));
      dispatch(getCouponCodeList());
    },
  },
];
