import axios from 'axios';
import { routes } from '~constants/routes';
import { INVOICES, INVOICES_LIST } from '~constants/actionTypes';

export const getInvoice = invoiceId => async dispatch => {
  try {
    dispatch({ type: INVOICES.GET.REQUEST });

    const invoice = await axios
      .get(routes.INVOICES.SHOW(invoiceId))
      .then(res => res.data.data.attributes);

    dispatch({ type: INVOICES.GET.SUCCESS, payload: invoice });
  } catch (error) {
    dispatch({
      type: INVOICES.GET.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};

export const getInvoicesList = () => async (dispatch, getState) => {
  try {
    const { meta } = getState().invoice;
    const {
      status,
      is_quote,
      page,
      search_term,
      sortColumn,
      sortDirection,
    } = meta;

    dispatch({ type: INVOICES_LIST.GET.REQUEST, payload: status });

    let params = {
      status,
      page,
      is_quote,
      search_term,
      sort: { column: sortColumn, direction: sortDirection },
    };

    if (!(meta.due_date.start == null && meta.due_date.end == null)) {
      params = {
        ...params,
        due_date: { start: meta.due_date.start, end: meta.due_date.end },
      };
    }

    if (meta.client_id) {
      params = {
        ...params,
        client_id: meta.client_id,
      };
    }

    const { data } = await axios.get(routes.INVOICES.GET, { params });
    dispatch({
      type: INVOICES_LIST.GET.SUCCESS,
      payload: { data: data.data, meta: { ...data.meta, is_quote } },
    });
  } catch (error) {
    dispatch({
      type: INVOICES_LIST.GET.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};

export const updateInvoiceListMeta = params => ({
  type: INVOICES_LIST.META.UPDATE,
  payload: params,
});

export const updateInvoice = (invoiceId, params) => async dispatch => {
  try {
    dispatch({ type: INVOICES.UPDATE.REQUEST });

    const invoice = await axios
      .put(routes.INVOICES.UPDATE(invoiceId), params)
      .then(res => res.data.data.attributes);

    dispatch({ type: INVOICES.UPDATE.SUCCESS, payload: invoice });
  } catch (error) {
    dispatch({
      type: INVOICES.UPDATE.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};

export const deleteInvoice = invoiceId => async dispatch => {
  try {
    dispatch({ type: INVOICES.DELETE.REQUEST });

    const res = await axios.delete(routes.INVOICES.DELETE(invoiceId));

    dispatch({ type: INVOICES.DELETE.SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: INVOICES.DELETE.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};

export const sendInvoice = (invoiceId, params) => async dispatch => {
  try {
    dispatch({ type: INVOICES.SEND.REQUEST });

    const invoice = await axios
      .put(routes.INVOICES.SEND(invoiceId), params)
      .then(res => res.data.data.attributes);

    dispatch({ type: INVOICES.SEND.SUCCESS, payload: invoice });
  } catch (error) {
    dispatch({
      type: INVOICES.SEND.REQUEST,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};

export const refundSquareInvoice = (invoiceId, params) => async dispatch => {
  try {
    dispatch({ type: INVOICES.SQUARE_REFUND.REQUEST });

    const res = await axios
      .post(`/customer/invoices/${invoiceId}/refund_square_payment`, params)
      .then(res => res.data.data);
    dispatch({ type: INVOICES.SQUARE_REFUND.SUCCESS, payload: res });
    return { success: true };
  } catch (error) {
    dispatch({
      type: INVOICES.SQUARE_REFUND.FAILURE,
      payload: error,
      error: true,
    });
    return { errors: error.response.data.message };
  }
};

export const refundStripeInvoice = (invoiceId, params) => async dispatch => {
  try {
    dispatch({ type: INVOICES.STRIPE_REFUND.REQUEST });

    const res = await axios
      .post(`/customer/invoices/${invoiceId}/refund_stripe_payment`, params)
      .then(res => res.data.data);

    dispatch({ type: INVOICES.STRIPE_REFUND.SUCCESS, payload: res });
    return { success: true };
  } catch (error) {
    dispatch({
      type: INVOICES.STRIPE_REFUND.FAILURE,
      payload: error,
      error: true,
    });
    return { errors: error.response.data.message };
  }
};

export const refundManualInvoice = (invoiceId, params) => async dispatch => {
  try {
    dispatch({ type: INVOICES.MANUAL_REFUND.REQUEST });

    const res = await axios
      .post(`/customer/invoices/${invoiceId}/refund_manual_payment`, params)
      .then(res => res.data.data);

    dispatch({ type: INVOICES.MANUAL_REFUND.SUCCESS, payload: res });
    return { success: true };
  } catch (error) {
    dispatch({
      type: INVOICES.MANUAL_REFUND.FAILURE,
      payload: error,
      error: true,
    });
    return { errors: error.response.data.message };
  }
};

export const updateShowRecordPaymentModal = newValue => ({
  type: INVOICES.UPDATE.SHOW_RECORD_PAYMENT_MODAL,
  payload: newValue,
});

export const showRefundModal = newValue => ({
  type: INVOICES.UPDATE.SHOW_REFUND_MODAL,
  payload: newValue,
});

export const updateShowAddPaymentScheduleModal = newValue => ({
  type: INVOICES.UPDATE.SHOW_ADD_PAYMENT_SCHEDULE_MODAL,
  payload: newValue,
});

export const updateHasQuickbooks = newValue => ({
  type: INVOICES.UPDATE.HAS_QUICKBOOKS,
  payload: newValue,
});
