import React from 'react';
import { useEffect } from 'react';
import CalendarEventForm from '~components/Calendar/CalendarEventForm';
import ToggleContent from '~components/common/ToggleContent';
import { getNearestHalfHourDateTime } from '~utils';
import { useState } from 'react';
import { DeleteConfirmation } from '~ui';
import styles from './PastSessions.module.scss';
import moment from 'moment-timezone';
import { deleteCalendarEvent } from '~actions/calendarEvents';
import { useSelector, useDispatch } from 'react-redux';

const PastSessions = props => {
  const { client_id } = props;
  const [pastSessions, setPastSessions] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [pageCount, setPageCount] = useState();
  const [page, setPage] = useState(1);
  const [paginationButtons, setPaginationButtons] = useState(false);
  const timeZone = useSelector(state => state.calendar.meta.timeZone);

  const timezonesAreSame =
    moment().tz(moment.tz.guess()).format('z') ==
    moment().tz(timeZone).format('z');

  const formatString = timezonesAreSame
    ? 'hh:mma MMM DD, YYYY'
    : 'hh:mma MMM DD, YYYY (z)';
  const dispatch = useDispatch();

  const calendarEvents = useSelector(state => state.calendar.clientEvents.data);

  const grabEvents = async () => {
    let pastSessions = [];

    const sortedEvents = calendarEvents.sort((a, b) =>
      a.attributes.start_time > b.attributes.start_time ? 1 : -1
    );
    sortedEvents.forEach(event => {
      const eventData = event.attributes;
      if (new Date(eventData.end_time).getTime() < new Date().getTime()) {
        pastSessions.push(eventData);
      }
    });

    pastSessions = pastSessions.reverse();

    const start = (page - 1) * 10;
    let end = start + 10;
    if (end > pastSessions.length) {
      end = pastSessions.length;
    }

    if (pastSessions.length >= 10) {
      setPageCount(Math.ceil(pastSessions.length / 10));
    } else {
      setPageCount(1);
    }

    setPastSessions(pastSessions.slice(start, end));
  };

  const generatePaginationNumbers = () => {
    let paginationArr = [];

    for (let i = 1; i < pageCount; i++) {
      paginationArr.push(
        <a
          className={page === i ? styles.Disable : ''}
          key={i}
          onClick={() => setPage(i)}
        >
          {i}
        </a>
      );
    }
    if (paginationArr.length === 1) {
      paginationArr = [];
    }
    return paginationArr;
  };

  const incrementOrDecrement = plusOrMinus => {
    let currentPage = page;
    if (plusOrMinus === '+') {
      currentPage += 1;
      setPage(currentPage);
    } else {
      currentPage -= 1;
      setPage(currentPage);
    }
  };

  useEffect(() => {
    grabEvents();
  }, [calendarEvents, page]);

  useEffect(() => {
    if (pageCount >= 2) {
      setPaginationButtons(true);
    } else {
      setPaginationButtons(false);
    }
  }, [pageCount]);

  const setEventForDelete = id => {
    setDeleteId(id);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    try {
      dispatch(deleteCalendarEvent(deleteId));
    } catch (error) {
      console.error(error);
    }
  };

  const createPastSessions = () => {
    let newSessions = (
      <div className={styles['noContentBox']}>
        No past sessions found for this client.
      </div>
    );
    if (pastSessions.length !== 0) {
      newSessions = pastSessions.map(session => {
        const year = moment.utc(session.start_time).tz(timeZone).format('YYYY');
        const month = moment.utc(session.start_time).tz(timeZone).format('MMM');
        const day = moment.utc(session.start_time).tz(timeZone).format('DD');
        const needsRescheduled = session.status === 'needs_rescheduled';
        const formattedStartTime = session.all_day
          ? 'all-day'
          : moment.utc(session.start_time).tz(timeZone).format(formatString);
        return (
          <ToggleContent
            key={session.id}
            toggle={handleShow => (
              <div onClick={handleShow} className={styles['eventBox']}>
                <div className={styles['firstChild']}>
                  <div>
                    <strong>{month}</strong>
                  </div>
                  <div>
                    <strong>{day}</strong>
                  </div>
                  <div>
                    <strong>{year}</strong>
                  </div>
                </div>
                <div className={styles['secondChild']}>
                  {needsRescheduled && (
                    <strong>
                      <i className="c-bl fa fa-exclamation-triangle"></i> Needs
                      Rescheduled:{' '}
                    </strong>
                  )}
                  {session.title} @ {formattedStartTime}
                </div>
              </div>
            )}
            content={handleHide => (
              <CalendarEventForm
                event={session}
                day={Date.parse(getNearestHalfHourDateTime())}
                handleHide={handleHide}
                clientOverride={client_id}
                deleteCallback={setEventForDelete}
              />
            )}
          />
        );
      });
    }
    return newSessions;
  };

  return (
    <>
      {createPastSessions()}
      {paginationButtons && (
        <div className={styles.Pagination}>
          <a
            className={page === 1 ? styles.Disable : ''}
            onClick={() => incrementOrDecrement('-')}
          >
            ← Previous
          </a>
          {generatePaginationNumbers()}
          <a
            className={page === pageCount ? styles.Disable : ''}
            onClick={() => incrementOrDecrement('+')}
          >
            Next →
          </a>
        </div>
      )}
      {showDeleteModal && (
        <DeleteConfirmation
          handleHide={() => setShowDeleteModal(false)}
          handleDelete={handleDelete}
          resource="event"
        />
      )}
    </>
  );
};

export default PastSessions;
