import { JOURNEY_TEMPLATE } from '~constants/actionTypes';

export default function (state = {}, action) {
  switch (action.type) {
    case JOURNEY_TEMPLATE.GET.SUCCESS:
      return {
        ...state,
        currentJourneyTemplate: action.payload,
      };
    case JOURNEY_TEMPLATE.UPDATE.SUCCESS:
      return {
        ...state,
        currentJourneyTemplate: action.payload,
      };
    case JOURNEY_TEMPLATE.RESTORE.SUCCESS:
      return {
        ...state,
        currentJourneyTemplate: action.payload,
      }
    case JOURNEY_TEMPLATE.SET.RESOURCE_OPTIONS:
      switch (action.payload) {
        case 1:
        case 5:
          return {
            ...state,
            resourceData: {
              resourceType: { name: 'Email Templates', value: 'EmailTemplate' },
              resourceOptions: state.dropdownOptions.emailTemplateOptions,
            },
          };
        case 2:
          return {
            ...state,
            resourceData: {
              resourceType: {
                name: 'Reminder Template',
                value: 'ReminderTemplate',
              },
              resourceOptions: [],
            },
          };
        default:
          return {
            ...state,
            resourceData: { resourceType: '', resourceOptions: [] },
          };
      }
    default:
      return state;
  }
}
