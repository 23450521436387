import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import {
  Button,
  Checkbox,
  DateTimePicker,
  Dropdown,
  Modal,
  TextArea,
  TextInput,
} from '~ui';
import {
  clientsSelector,
  actionsLoadingSelector,
  locationsSelector,
  sessionTypesSelector,
  shootProofBrandsSelector,
  journeyTemplatesSelector,
  selectedDaySelector,
  intervalSelector,
} from '~selectors';
import {
  listCalendarEvents,
  getCalendarEvents,
  createCalendarEvent,
  updateCalendarEvent,
} from '~actions/calendarEvents';
import { get, getNearestHalfHourDateTime } from '~utils';
import { absolutePath } from '~constants/routes';
import { DATE_KEY_FORMAT } from '~constants/datetime';
import styles from '../Calendar.module.scss';
import axios from 'axios';
import { routes } from '~constants/routes';

const CalendarEventForm = props => {
  const deleteCallback = props.deleteCallback;
  const dateContext = props.dateContext;
  const timeZone = useSelector(state => state.calendar.meta.timeZone);
  const { handleHide, event, day, allDayOverride, clientOverride } = props;
  const ONE_HOUR = 60000 * 60;

  const getDateTime = datetime => {
    const interval = useSelector(intervalSelector);

    if (interval === 'month') {
      return moment(getNearestHalfHourDateTime(new Date(datetime))).tz(
        timeZone
      );
    }

    return datetime;
  };

  // Internal state to populate params for the API request.
  const [eventTitle, setEventTitle] = useState(get(event, ['title'], ''));
  const [client, setClient] = useState(
    get(event, ['client_id']) || clientOverride
  );
  const [isAllDay, setIsAllDay] = useState(
    get(event, ['all_day']) || allDayOverride || false
  );
  const [markedAsFree, setMarkedAsFree] = useState(
    get(event, ['marked_as_free'], false)
  );
  const [isSession, setIsSession] = useState(event ? event.is_session : true);
  const [sessionType, setSessionType] = useState(
    get(event, ['session_type_id'])
  );
  const [notes, setNotes] = useState(get(event, ['description'], ''));
  const [startDateTime, setStartDateTime] = useState(
    get(event, ['start_time'], new Date(getDateTime(day)))
  );
  const [endDateTime, setEndDateTime] = useState(
    get(event, ['end_time'], new Date(getDateTime(day + ONE_HOUR)))
  );
  const [makeShootProofGallery, setMakeShootProofGallery] = useState(
    get(event, ['shoot_proof_gallery_opt_in'], false)
  );
  const [makePicTimeProject, setMakePicTimeProject] = useState(
    get(event, ['pic_time_project_id'], false)
  );
  const [fullAddress, setFullAddress] = useState(
    get(event, ['full_address'], '')
  );
  const [location, setLocation] = useState(get(event, ['location_id']));
  const [journeyTemplate, setJourneyTemplate] = useState(
    get(event, ['journey_template_id'])
  );
  const [shootProofBrand, setShootProofBrand] = useState(
    get(event, ['shoot_proof_brand_id'])
  );
  const [status, setStatus] = useState(get(event, ['status'], 'active'));
  const [validDatesSelected, setValidDatesSelected] = useState(
    moment(startDateTime).isBefore(moment(endDateTime))
  );
  const [sendLimitReached, setSendLimitReached] = useState(false);
  const activeTrial = useSelector(state => state.calendar.activeTrial);
  const trialVerified = useSelector(state => state.calendar.trialVerified);
  const studioId = useSelector(state => state.calendar.studioId);

  const [loading, setLoading] = useState(
    activeTrial && !trialVerified ? true : false
  );

  // Pulling from Redux state to populate the form dropdowns.
  const clients = useSelector(clientsSelector);
  const locations = useSelector(locationsSelector);
  const sessionTypes = useSelector(sessionTypesSelector);
  const shootProofBrands = useSelector(shootProofBrandsSelector);
  const journeyTemplates = useSelector(journeyTemplatesSelector);
  const statuses = useSelector(state => state.calendar.statuses);
  const hasShootProofIntegration = useSelector(
    state => state.calendar.meta.features.hasShootProofIntegration
  );

  const hasPicTimeIntegration = useSelector(
    state => state.calendar.meta.features.hasPicTimeIntegration
  );
  const isLoading = useSelector(state =>
    actionsLoadingSelector(state, [
      'CALENDAR_EVENTS_CREATE',
      'CALENDAR_EVENTS_UPDATE',
    ])
  );
  const defaultJourneyTemplates = useSelector(
    state => state.calendar.defaultJourneyTemplates
  );

  useEffect(() => {
    if (isAllDay) {
      setEndDateTime(moment(endDateTime).subtract(1, 'day'));
    }

    if (activeTrial && !trialVerified) {
      setLoading(true);
      checkSendLimit();
    }
  }, []);

  const dispatch = useDispatch();
  const openClientPage = () => {
    window.open(absolutePath(`/clients/${client}`), '_blank');
  };
  const getCalendarEventsForInterval = date => {
    let start = moment(date)
      .tz(timeZone)
      .subtract(1, 'month')
      .format(DATE_KEY_FORMAT);
    let end = moment(date).tz(timeZone).add(1, 'month').format(DATE_KEY_FORMAT);

    if (clientOverride) {
      getCalendarEventsForClient();
    } else {
      dispatch(
        listCalendarEvents({
          start,
          end,
        })
      );
    }
  };

  const getCalendarEventsForClient = () => {
    dispatch(
      getCalendarEvents({
        client_id: clientOverride ? clientOverride : null,
      })
    );
  };

  const checkSendLimit = async () => {
    const res = await axios.get(
      routes.STUDIO_PROFILE.CHECK_SEND_LIMIT(studioId)
    );
    setSendLimitReached(res.data.reached_send_limit);
    setLoading(false);
  };

  const handleSubmit = async e => {
    let endTime = endDateTime;
    e.preventDefault();
    e.stopPropagation();

    let journeyTemplateId = journeyTemplate;
    if (journeyTemplateId === 'None' || journeyTemplateId === '0') {
      journeyTemplateId = null;
    }

    let endMoment = moment(endDateTime);

    if (!isAllDay && endMoment.hour() === 0 && endMoment.minute() === 0) {
      endTime = endMoment.subtract(1, 'minute');
    }

    const requestParams = {
      event: {
        title: eventTitle,
        client_id: client,
        all_day: isAllDay,
        is_session: isSession,
        session_type_id: sessionType,
        description: notes,
        start_time: moment(startDateTime).utc(),
        end_time: moment(endTime).tz(timeZone),
        journey_template_id: journeyTemplateId,
        full_address: fullAddress,
        location_id: location,
        marked_as_free: markedAsFree,
        shoot_proof_brand_id: shootProofBrand,
        shoot_proof_gallery_opt_in: makeShootProofGallery,
        pic_time_project_opt_in: makePicTimeProject,
        status: status,
      },
    };

    if (event) {
      const updateRes = await dispatch(
        updateCalendarEvent(event.id, requestParams)
      );
      if (updateRes.errors) {
        updateRes.errors.forEach(err => {
          toast.error(err, toastOptions);
        });
      } else {
        getCalendarEventsForInterval(dateContext);
        toast.success('Success!');
        handleHide();
      }
      if (event.invoice_id) {
        toast.info(
          "This session has an attached invoice. Please check the associated invoice's due date for correctness.",
          toastOptions
        );
      }
    } else {
      const createRes = await dispatch(createCalendarEvent(requestParams));
      if (createRes.errors) {
        createRes.errors.forEach(err => {
          toast.error(err, toastOptions);
        });
      } else {
        getCalendarEventsForInterval(dateContext);
        toast.success('Success!');
        handleHide();
      }
    }
  };

  const handleDateTimeChange = (startDateTime, endDateTime) => {
    if (isAllDay) {
      const valid = moment(endDateTime).isSameOrAfter(moment(startDateTime));
      setValidDatesSelected(valid);
    } else {
      const valid = moment(startDateTime).isBefore(moment(endDateTime));
      setValidDatesSelected(valid);
    }
  };

  const checkForDeleteCallback = () => {
    if (deleteCallback) {
      deleteCallback(event.id);
      handleHide();
    } else {
      handleHide();
    }
  };

  return (
    <Modal handleHide={handleHide} title={event ? 'Edit Event' : 'New Event'}>
      <>
        {loading ? (
          <h1>Loading...</h1>
        ) : (
          <form className={styles.EventModalForm} onSubmit={handleSubmit}>
            <div>
              <TextInput
                name="title"
                labelText="Event Title"
                placeholder="Untitled Event"
                onChangeCallback={setEventTitle}
                required
                initialValue={eventTitle}
              />
            </div>
            <div>
              <Checkbox
                name="is_session"
                labelText="Photo Session"
                onChangeCallback={setIsSession}
                checked={isSession}
              />
            </div>
            <div className={styles.EventFormColumns}>
              <div>
                <Dropdown
                  name="client_id"
                  labelText="Client"
                  options={clients}
                  onChangeCallback={setClient}
                  initialValue={String(client)}
                  required={isSession}
                />
              </div>
              <div className={styles.ClientProfileButtonContainer}>
                {client && (
                  <Button
                    text="View Client Profile"
                    icon="external-link-alt"
                    onClick={openClientPage}
                  />
                )}
              </div>
            </div>
            <div className={styles.EventFormColumns}>
              <div>
                <Dropdown
                  name="status"
                  labelText="Status"
                  options={statuses}
                  onChangeCallback={setStatus}
                  initialValue={status}
                  required
                />
              </div>
              {/* This is here purely for styling purposes. */}
              <div></div>
            </div>

            {!sendLimitReached && <hr />}

            {isSession && (
              <>
                {!sendLimitReached && (
                  <div className={styles.EventFormColumns}>
                    <div>
                      <Dropdown
                        name="session_type_id"
                        labelText="Session Type"
                        options={sessionTypes}
                        onChangeCallback={type => {
                          setSessionType(type);
                          if (defaultJourneyTemplates[type]) {
                            setJourneyTemplate(
                              String(defaultJourneyTemplates[type])
                            );
                          }
                        }}
                        initialValue={String(sessionType)}
                      />
                    </div>
                    <div>
                      <Dropdown
                        name="journey_template_id"
                        labelText="Workflow"
                        options={journeyTemplates}
                        onChangeCallback={setJourneyTemplate}
                        initialValue={String(journeyTemplate)}
                        disabled={status === 'needs_rescheduled' && event}
                      />
                    </div>
                  </div>
                )}
                {hasPicTimeIntegration && (
                  <div>
                    <Checkbox
                      name="pic_time_gallery_opt_in"
                      labelText="Create PicTime Project"
                      onChangeCallback={setMakePicTimeProject}
                      checked={!!makePicTimeProject}
                    />
                  </div>
                )}
                {hasShootProofIntegration && (
                  <div>
                    <Checkbox
                      name="shoot_proof_gallery_opt_in"
                      labelText="Create Shootproof Gallery"
                      onChangeCallback={setMakeShootProofGallery}
                      checked={makeShootProofGallery}
                    />
                  </div>
                )}
                {makeShootProofGallery && (
                  <>
                    <div>
                      <Dropdown
                        name="shoot_proof_brand_id"
                        labelText="Shootproof Brand"
                        options={shootProofBrands}
                        onChangeCallback={setShootProofBrand}
                        initialValue={String(shootProofBrand)}
                      />
                    </div>
                    {/* This is here purely for styling purposes. */}
                    <div />
                  </>
                )}
                <hr />
              </>
            )}

            <div className={styles.EventFormColumns}>
              <div>
                <DateTimePicker
                  name="start_time"
                  labelText="Start"
                  disableTime={isAllDay}
                  onChangeCallback={setStartDateTime}
                  initialDateTime={moment.utc(startDateTime).tz(timeZone)}
                  valid={validDatesSelected}
                  onBlurInput={dateTime => {
                    if (!event) {
                      setEndDateTime(moment(dateTime).add(1, 'hour'));
                      handleDateTimeChange(
                        dateTime,
                        moment(dateTime).add(1, 'hour')
                      );
                    } else {
                      const endDateTimeClone = moment(endDateTime)
                        .tz(timeZone)
                        .set({
                          month: dateTime.month(),
                          date: dateTime.date(),
                          year: dateTime.year(),
                        });
                      setEndDateTime(endDateTimeClone);
                      handleDateTimeChange(dateTime, endDateTimeClone);
                    }
                  }}
                  required
                />
              </div>
              <div>
                <DateTimePicker
                  name="end_time"
                  labelText="End"
                  disableTime={isAllDay}
                  onChangeCallback={setEndDateTime}
                  onBlurInput={dateTime =>
                    handleDateTimeChange(startDateTime, dateTime)
                  }
                  initialDateTime={moment.utc(endDateTime).tz(timeZone)}
                  valid={validDatesSelected}
                  errorMessage="End Date must be after Start Date"
                  required
                />
              </div>
            </div>

            <div className={styles.EventFormColumns}>
              <div>
                <Checkbox
                  name="marked_as_free"
                  labelText="Mark as free?"
                  onChangeCallback={setMarkedAsFree}
                  checked={markedAsFree}
                />
              </div>
              <div>
                <Checkbox
                  name="all_day"
                  labelText="All day?"
                  onChangeCallback={setIsAllDay}
                  checked={isAllDay}
                />
              </div>
            </div>

            <hr />

            <div>
              <TextInput
                name="full_address"
                labelText="Location"
                placeholder="Add an optional location to this event."
                onChangeCallback={setFullAddress}
                initialValue={fullAddress}
              />
            </div>
            <div>
              <Dropdown
                name="location_id"
                labelText="Saved Locations"
                options={locations}
                onChangeCallback={setLocation}
                initialValue={String(location)}
              />
            </div>
            <div>
              <TextArea
                name="description"
                labelText="Notes"
                placeholder="Only you will be able to see these notes."
                onChangeCallback={setNotes}
                initialValue={notes}
              />
            </div>

            <div className={styles.EventFormButtons}>
              <Button
                text={deleteCallback ? 'Delete' : 'Cancel'}
                onClick={() => checkForDeleteCallback()}
                danger
              />
              <Button
                text={event ? 'Update Event' : 'Create Event'}
                loading={isLoading}
                submit
              />
            </div>
          </form>
        )}
      </>
    </Modal>
  );
};

CalendarEventForm.propTypes = {
  day: PropTypes.number,
  event: PropTypes.object,
  handleHide: PropTypes.func.isRequired,
};

CalendarEventForm.defaultProps = {
  day: null,
  event: null,
};

export default CalendarEventForm;
