import React from 'react';
import PropTypes from 'prop-types';

const TableHeader = ({ headers }) => (
  <thead>
    <tr>
      {headers.map((header, index) => (
        <th key={`TableHeader-${index}`}>{header}</th>
      ))}
    </tr>
  </thead>
);

TableHeader.propTypes = {
  headers: PropTypes.array.isRequired,
};

export default TableHeader;
