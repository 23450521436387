import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';
import PaypalButton from './PaypalButton';
import SquareButton from './SquareButton';
import StripeButton from './StripeButton';
import styles from './PaymentOptions.module.scss';

const PaymentOptions = props => {
  const {
    loadingCallback,
    processorsMetadata,
    amountDue,
    invoice,
    client,
    studio,
    loading,
    tipAmount,
    isBooking,
    user,
    availabilityId,
    bookingCalendarId,
  } = props;

  const {
    paypalAddress,
    squareApplicationId,
    squareRedirectUrl,
    squareLocationId,
    stripePublicKey,
    currency,
  } = processorsMetadata;
  const {
    hasPaypal,
    hasSquare,
    hasStripe,
    tipsEnabled,
    achEnabled,
    giftCardEnabled,
    afterpayEnabled,
    cashAppEnabled,
    applePayEnabled,
    googlePayEnabled,
    hasAfterpayAttributes,
    hasGoogleAndApplePayAttributes,
    showCouponCodes,
  } = processorsMetadata.features;

  const paypalSettings = {
    name: `${studio.name}: ${invoice.invoice_num}`,
    invoiceUuid: invoice.uuid,
    currency,
    amountDue,
    paypalAddress,
  };

  const squareSettings = {
    currency,
    amountDue,
    client,
    squareApplicationId,
    squareRedirectUrl,
    squareLocationId,
    invoiceUuid: invoice.uuid,
  };

  const stripeSettings = {
    amountDue,
    client,
    invoice,
  };

  const [stripePromise, _] = useState(
    () => hasStripe && loadStripe(stripePublicKey)
  );

  return (
    <div className={styles.PaymentOptions}>
      {hasPaypal && !(hasSquare && (tipsEnabled || showCouponCodes)) && (
        <PaypalButton
          settings={paypalSettings}
          loadingCallback={loadingCallback}
          className={styles['PaymentOptions-processor']}
          isBooking={isBooking}
        />
      )}
      {hasSquare && !loading && (
        <SquareButton
          settings={squareSettings}
          loadingCallback={loadingCallback}
          className={styles['PaymentOptions-processor']}
          tipAmount={tipAmount}
          isBooking={isBooking}
          achEnabled={achEnabled}
          giftCardEnabled={giftCardEnabled}
          afterpayEnabled={afterpayEnabled}
          cashAppEnabled={cashAppEnabled}
          applePayEnabled={applePayEnabled}
          googlePayEnabled={googlePayEnabled}
          hasAfterpayAttributes={hasAfterpayAttributes}
          hasGoogleAndApplePayAttributes={hasGoogleAndApplePayAttributes}
          studio={studio}
          user={user}
          availabilityId={availabilityId}
          bookingCalendarId={bookingCalendarId}
        />
      )}
      {hasStripe && !(hasSquare && (tipsEnabled || showCouponCodes)) && (
        <Elements stripe={stripePromise}>
          <StripeButton
            settings={stripeSettings}
            loadingCallback={loadingCallback}
            className={styles['PaymentOptions-processor']}
            isBooking={isBooking}
            availabilityId={availabilityId}
            bookingCalendarId={bookingCalendarId}
          />
        </Elements>
      )}
    </div>
  );
};

PaymentOptions.propTypes = {
  loadingCallback: PropTypes.func.isRequired,
  processorsMetadata: PropTypes.object.isRequired,
  amountDue: PropTypes.string.isRequired,
  invoice: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  studio: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

export default PaymentOptions;
