import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { keyCodes } from '~constants/keyCodes';
import styles from './Toggle.module.scss';

const Toggle = ({
  labelText,
  initialValue,
  onChangeCallback,
  disabled,
  staticColor,
  className,
  disabledTooltipDescription,
}) => {
  const [isOn, setIsOn] = useState(initialValue);
  const hasClassName = !!className;
  const cx = classNames.bind(styles);
  const classes = cx('Toggle', {
    'Toggle--disabled': disabled,
    'Toggle--staticColor': staticColor,
    [className]: hasClassName,
  });

  const toggleValue = () => {
    const newValue = !isOn;
    setIsOn(newValue);
    onChangeCallback(newValue);
  };

  useEffect(() => {
    setIsOn(initialValue);
  }, [initialValue]);

  return (
    <div className={classes}>
      {labelText && <label>{labelText}</label>}
      <div
        data-tip={disabledTooltipDescription}
        role="checkbox"
        aria-checked={`${isOn}`}
        tabIndex="0"
        onClick={() => {
          if (disabled) {
            return;
          }

          toggleValue();
        }}
        onKeyDown={({ keyCode }) => {
          if (keyCode === keyCodes.SPACE) {
            toggleValue();
          }
        }}
      >
        <div />
      </div>
      {disabled && disabledTooltipDescription && <ReactTooltip />}
    </div>
  );
};

Toggle.propTypes = {
  labelText: PropTypes.string,
  initialValue: PropTypes.bool,
  onChangeCallback: PropTypes.func,
  disabled: PropTypes.bool,
};

Toggle.defaultProps = {
  labelText: undefined,
  initialValue: false,
  onChangeCallback: () => {},
  disabled: false,
};

export default Toggle;
