import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { FULL_DATE_FORMAT } from '~constants/datetime';
import { toCurrencyAmount, uniqueId } from '~utils';
import {
  MenuButton,
  Button,
  ButtonGroupInput,
  DateTimePicker,
  TextInput,
  Dropdown,
  CurrencyInput,
  PercentageInput,
} from '~ui';
import styles from '../Invoice.module.scss';

const MobileInvoiceInstallments = ({
  title,
  subtitle,
  readOnly,
  handleCreate,
  handleUpdate,
  handleDelete,
  renderInstallmentStatus,
}) => {
  const invoice = useSelector(state => state.invoice);
  const { schedule } = invoice;

  const [installments, setInstallments] = useState([]);
  const [currentAction, setCurrentAction] = useState();
  const [isSelecting, setIsSelecting] = useState(false);
  const [
    indexOfSelectedInstallment,
    setIndexOfSelectedInstallment,
  ] = useState();
  const [isScrolling, setIsScrolling] = useState(false);

  const installmentOptions = [
    {
      label: 'Add',
      onClickCallback: () => {
        const newIndex = installments.length;
        setCurrentAction('add');
        setInstallments(installments.concat([{}]));
        setIndexOfSelectedInstallment(newIndex);
        setIsScrolling(true);
      },
      ref: React.createRef(),
    },
    {
      label: 'Edit',
      onClickCallback: () => {
        setCurrentAction('edit');
        setIsSelecting(true);
      },
      ref: React.createRef(),
    },
  ];
  if (installments.length > 1) {
    installmentOptions.push({
      label: 'Delete',
      onClickCallback: () => {
        setCurrentAction('delete');
        setIsSelecting(true);
      },
      ref: React.createRef(),
    });
  }

  const getInstallmentCardId = indexOfInstallment => {
    return `InstallmentCard-${indexOfInstallment}`;
  };

  useEffect(() => {
    setInstallments(schedule.installments);
  }, [schedule]);

  useLayoutEffect(() => {
    if (isScrolling) {
      const lastCard = document.getElementById(
        getInstallmentCardId(installments.length - 1)
      );
      window.scrollTo(0, lastCard.offsetTop - 20);
      setIsScrolling(false);
    }
  }, [isScrolling]);

  const getAriaLabel = installment => {
    if (isSelecting) {
      return `${currentAction} Installment ${installment.order}`;
    }

    return undefined;
  };

  const cancelAction = currentInstallment => {
    if (currentInstallment.id === undefined) {
      setInstallments(installments.slice(0, installments.length - 1));
    }
    setCurrentAction();
    setIndexOfSelectedInstallment();
    setIsSelecting(false);
  };

  const getMenuButton = () => {
    if (currentAction) {
      return (
        <Button
          className="bgc-m bc-m c-w"
          name={`Cancel ${currentAction} Action`}
          category="icon"
          icon="times"
          onClick={() => {
            cancelAction(installments[installments.length - 1]);
          }}
        />
      );
    }

    return (
      <MenuButton
        className="bgc-m bc-m c-w"
        name="Installment Actions"
        options={installmentOptions}
      />
    );
  };

  const handleSubmit = e => {
    e.preventDefault();
    const fieldElements = e.target.querySelectorAll('input,select,textarea');

    const params = Array.from(fieldElements).reduce((acc, input) => {
      if (input.type === 'checkbox') {
        acc[input.name] = input.checked;
      } else {
        acc[input.name] = input.value;
      }

      return acc;
    }, {});

    const currentInstallment = installments[indexOfSelectedInstallment];
    if (currentInstallment.id === undefined) {
      handleCreate(params);
    } else {
      handleUpdate(currentInstallment.id, params);
    }

    setCurrentAction();
    setIndexOfSelectedInstallment();
  };

  const getDueDateInput = (installment, indexOfInstallment) => {
    if (indexOfInstallment === 0) {
      return (
        <DateTimePicker
          initialDateTime={installment.due_date}
          name="due_date"
          disableTime
        />
      );
    } else {
      const buttons = [
        {
          icon: 'calendar-alt',
          ariaLabel: 'Use calendar date',
          input: () => (
            <DateTimePicker
              initialDateTime={installment.due_date}
              name="due_date"
              disableTime
            />
          ),
        },
        {
          icon: 'stopwatch',
          ariaLabel: 'Use interval date',
          input: () => (
            <>
              <div>
                <TextInput
                  initialValue={installment.interval_amount}
                  name="interval_amount"
                  characterLimit={2}
                />
              </div>
              <div style={{ marginLeft: '4px' }}>
                <Dropdown
                  initialValue={installment.interval}
                  name="interval"
                  options={[
                    { value: '', label: 'Interval' },
                    { value: 'day', label: 'Day(s)' },
                    { value: 'week', label: 'Week(s)' },
                    { value: 'month', label: 'Month(s)' },
                    { value: 'year', label: 'Year(s)' },
                  ]}
                  className="mn-w-114"
                />
              </div>
            </>
          ),
        },
      ];
      return (
        <ButtonGroupInput
          buttons={buttons}
          initialValue={installment.interval ? 1 : 0}
        />
      );
    }
  };

  const getAmountInput = (installment, indexOfInstallment) => {
    const buttons = [
      {
        text: window.app.invoices.currency,
        ariaLabel: 'Use specific amount',
        input: () => (
          <CurrencyInput
            hideIcon
            name="amount"
            initialValue={installment.amount}
            max={10000}
          />
        ),
      },
      {
        icon: 'percent',
        ariaLabel: 'Use percent amount',
        input: () => (
          <PercentageInput
            hideIcon
            characterLimit={8}
            name="percent_amount"
            initialValue={installment.percent_amount}
          />
        ),
      },
    ];
    return (
      <ButtonGroupInput
        buttons={buttons}
        initialValue={installment.percent_amount ? 1 : 0}
      />
    );
  };

  const getDeleteModal = installment => (
    <div className={styles.DeleteContainer}>
      <div className={styles.DeleteModal}>
        <p>Delete this installment?</p>
        <div>
          <Button
            text="No"
            danger
            onClick={() => {
              setIsSelecting(true);
              setIndexOfSelectedInstallment();
            }}
          />
          <Button
            text="Yes"
            onClick={() => {
              setIndexOfSelectedInstallment();
              setCurrentAction();
              handleDelete(installment.id);
            }}
          />
        </div>
      </div>
    </div>
  );

  const isAdding = currentAction === 'add';
  const isEditing = currentAction === 'edit';
  const isDeleting = currentAction === 'delete';
  const getInstallmentCard = (installment, indexOfInstallment) => {
    const isSelected = indexOfInstallment === indexOfSelectedInstallment;

    let className = styles['MobileCard'];
    if (isSelecting || isSelected) {
      className = `${className} ${styles['MobileCard--outlined']}`;
    }

    let cardBody;
    if ((isEditing || isAdding) && isSelected) {
      cardBody = (
        <form className={styles.EditContainer} onSubmit={handleSubmit}>
          <label>Due date</label>
          <div className={styles.Line}>
            {getDueDateInput(installment, indexOfInstallment)}
          </div>
          <label>Amount</label>
          <div className={styles.Line}>
            {getAmountInput(installment, indexOfInstallment)}
          </div>
          <div className={styles.Line}>
            <Button
              text="Cancel"
              onClick={() => {
                cancelAction(installment);
              }}
              danger
            />
            <Button text="Save" submit />
          </div>
        </form>
      );
    } else {
      cardBody = (
        <div>
          <p>
            Due Date: {moment(installment.due_date).format(FULL_DATE_FORMAT)}
          </p>
          <p>Amount: {toCurrencyAmount(installment.amount)}</p>
        </div>
      );
    }

    return (
      <div
        key={uniqueId('installment_')}
        id={getInstallmentCardId(indexOfInstallment)}
        className={className}
        tabIndex={isSelecting ? '0' : '-1'}
        role={isSelecting ? 'button' : undefined}
        aria-label={getAriaLabel(installment)}
        onClick={
          isSelecting
            ? () => {
                setIsSelecting(false);
                setIndexOfSelectedInstallment(indexOfInstallment);
              }
            : undefined
        }
      >
        <div className={styles['MobileCard-header']}>
          <h3>
            {isAdding && isSelected
              ? 'New Installment'
              : `Installment #${installment.order}`}
          </h3>
          {renderInstallmentStatus(installment.status)}
        </div>
        {cardBody}
        {isDeleting && isSelected && getDeleteModal(installment)}
      </div>
    );
  };

  return (
    <div>
      <div className={styles['MobileSectionHeader']}>
        <div>
          <h2>{title}</h2>
          <p>{subtitle}</p>
        </div>
        {!readOnly && getMenuButton()}
      </div>
      {installments.map((installment, index) =>
        getInstallmentCard(installment, index)
      )}
    </div>
  );
};

MobileInvoiceInstallments.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  handleCreate: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  renderInstallmentStatus: PropTypes.func.isRequired,
};

MobileInvoiceInstallments.defaultProps = {
  readOnly: false,
};

export default MobileInvoiceInstallments;
