import React, { useState } from 'react';
import { Modal, Button, DateTimePicker } from '~ui';
import moment from 'moment';
import styles from './ClientActivity.module.scss';
import axios from 'axios';
import { routes } from '~constants/routes';

const DateModal = ({
  email,
  handleHide,
  getScheduledEmails,
  journeyTriggers,
}) => {
  const [chosenDate, setChosenDate] = useState(moment(email.scheduled_at));

  const handleDateSave = async () => {
    const calendarEventStart = moment(email.calendar_event_start).startOf(
      'day'
    );
    const isBeforeSession = chosenDate.isBefore(calendarEventStart);
    const isAfterSession = chosenDate.isSameOrAfter(calendarEventStart);
    const triggerName = isBeforeSession
      ? 'BeforeSession'
      : isAfterSession
      ? 'AfterSession'
      : null;

    const trigger = journeyTriggers.find(
      trigger => trigger.trigger_type === triggerName
    );

    const newOffset = calendarEventStart.diff(chosenDate, 'days');

    const requestParams = {
      journey_step: {
        time_offset: Math.abs(newOffset),
        journey_trigger_id: trigger.id,
      },
    };

    try {
      await axios.put(routes.JOURNEY_STEPS.UPDATE(email.id), requestParams);
      getScheduledEmails();
    } catch (error) {
      console.error(error);
    }

    handleHide();
  };

  return (
    <Modal title="Edit Send Date" handleHide={handleHide}>
      <div>Edit the send date of this scheduled email</div>
      <div className={styles['ClientActivity-editDate']}>
        <DateTimePicker
          name="editSendDate"
          initialDateTime={chosenDate}
          onChangeCallback={date => setChosenDate(date.startOf('day'))}
          disableTime
        />
      </div>
      <div className={styles['ClientActivity-modalButtons']}>
        <Button text="Cancel" onClick={handleHide} white />
        <Button text="Save" onClick={handleDateSave} />
      </div>
    </Modal>
  );
};

export default DateModal;
