import React, { useState } from 'react';
import { PercentageInput, Icon } from '~ui';
import { toCurrencyAmount } from '~utils';
import styles from './InvoiceTotals.module.scss';

const Tax = props => {
  const {
    isEditing,
    setEditingTax,
    setTaxEnabled,
    invoice,
    handleUpdateTax,
    handleDeleteTax,
    studioTax,
  } = props;
  const { tax } = invoice.totals;
  const { tax_amount } = invoice;
  const [taxAmount, setTaxAmount] = useState(
    studioTax ? studioTax : tax_amount
  );

  const renderTaxInput = () => (
    <>
      <span className={styles['InvoiceTotals-lineValue']}>
        <PercentageInput
          characterLimit={8}
          name="tax_amount"
          initialValue={taxAmount}
          onChangeCallback={setTaxAmount}
          maximumFractionDigits={3}
          minimumFractionDigits={3}
        />
      </span>
      <span className={styles['InvoiceTotals-lineBuffer']}>
        <Icon
          name="check"
          ariaLabel="Save Tax"
          onClickCallback={() =>
            handleUpdateTax(taxAmount).then(() => setEditingTax(false))
          }
        />
        <Icon
          name="times"
          ariaLabel="Cancel Edit Tax"
          onClickCallback={() => {
            if (taxAmount === 0.0) {
              setTaxEnabled(false);
            }
            setEditingTax(false);
          }}
        />
      </span>
    </>
  );

  const renderTaxLine = () => (
    <>
      <span className={styles['InvoiceTotals-lineValue']}>
        {toCurrencyAmount(tax)}
      </span>
      {!invoice.paid_in_full && (
        <span className={styles['InvoiceTotals-lineBuffer']}>
          <Icon
            name="pencil-alt"
            ariaLabel="Edit Tax"
            onClickCallback={() => setEditingTax(true)}
          />
          <Icon
            name="trash-alt"
            ariaLabel="Remove Tax"
            onClickCallback={() =>
              handleDeleteTax().then(() => {
                setTaxEnabled(false);
                setEditingTax(false);
              })
            }
          />
        </span>
      )}
    </>
  );

  return (
    <div className={styles['InvoiceTotals-line']}>
      <span className={styles['InvoiceTotals-lineLabel']}>Tax</span>
      {isEditing ? renderTaxInput() : renderTaxLine()}
    </div>
  );
};

export default Tax;
