import React from 'react';

export const FIELDS = [
  {
    id: 1,
    icon: <i className="fas fa-quote-right"></i>,
    title: 'Introduction',
  },
  {
    id: 2,
    icon: <i className="far fa-keyboard"></i>,
    title: 'Text Field',
  },
  {
    id: 3,
    icon: <i className="fas fa-comment"></i>,
    title: 'Comment Field',
  },
  {
    id: 4,
    icon: <i className="far fa-check-square"></i>,
    title: 'Check Box',
  },
  {
    id: 5,
    icon: <i className="fas fa-tasks"></i>,
    title: 'Multiple Check',
  },
  {
    id: 6,
    icon: <i className="fas fa-dot-circle"></i>,
    title: 'Radio Button',
  },
  {
    id: 7,
    icon: <i className="fas fa-chevron-down"></i>,
    title: 'Dropdown List',
  },
  {
    id: 8,
    icon: <i className="fas fa-map-marker-alt"></i>,
    title: 'Address',
  },
  {
    id: 9,
    icon: <i className="fas fa-phone-alt"></i>,
    title: 'Phone Number',
  },
  {
    id: 10,
    icon: <i className="far fa-envelope"></i>,
    title: 'Email Address',
  },
  {
    id: 11,
    icon: <i className="far fa-calendar-alt"></i>,
    title: 'Date',
  },
];
