import React from 'react';
import moment from 'moment-timezone';
import { Pill } from '~ui';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '~constants/datetime';
import styles from '../CalendarEventSummary/CalendarEventSummary.module.scss';

const CronofyEventSummary = ({ event }) => {
  const renderAttendees = attendees => {
    return (
      <div className={styles['CalendarEventSummary-section']}>
        <h3>
          <i className="fa fa-users" />
          Attendees
        </h3>
        <ul>
          {attendees.map(attendee => {
            const { email, display_name, status } = attendee;
            let statusPill = null;

            if (status === 'accepted') {
              statusPill = <Pill text="Accepted" color="green" small />;
            } else if (status === 'declined') {
              statusPill = <Pill text="Declined" color="red" small />;
            } else {
              statusPill = <Pill text="Awaiting" color="orange" small />;
            }

            return (
              <li key={`attendee_${email}`}>
                <span>{display_name ? display_name : email}</span>
                {statusPill}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const timeInterval = () => {
    const start = moment(event.start.time);
    const end = moment(event.end.time);

    if (event.all_day) {
      return start.format(DATE_FORMAT);
    }

    return `${start.format(DATE_TIME_FORMAT)} - ${end.format(
      DATE_TIME_FORMAT
    )}`;
  };

  return (
    <>
      <div className={styles['CalendarEventSummary-header']}>
        <h1>{event.summary}</h1>
        <span>{timeInterval()}</span>
      </div>
      <div className={styles['CalendarEventSummary-attributeSection']}>
        <Pill text="External Event" small />
        {event.all_day && <Pill text="All Day" small />}
      </div>
      {event.description && (
        <span className={styles['CalendarEventSummary-section']}>
          <i className="fa fa-quote-left" />
          {event.description.split('\n').map((snippet, i) => (
            <p key={Math.random()}>{snippet}</p>
          ))}
        </span>
      )}
      {event.attendees.length > 0 && renderAttendees(event.attendees)}
    </>
  );
};

export default CronofyEventSummary;
