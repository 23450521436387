import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './TextInput.module.scss';

const TableTextInput = props => {
  const {
    className,
    disabled,
    initialValue,
    inline,
    labelText,
    name,
    onChangeCallback,
    placeholder,
    required,
    characterLimit,
    type,
    onFocusSelectText,
    validation,
    min,
  } = props;
  const hasCharacterLimit = !!characterLimit;
  const hasType = !!type;
  const cx = classNames.bind(styles);
  const [inputValue, setInputValue] = useState(initialValue);
  const [isValid, setIsValid] = useState(true);
  let isFireFox = 'InstallTrigger' in window;

  const classes = cx('TextInput', {
    'TextInput--disabled': disabled,
    'TextInput--inline': inline,
    'TextInput--invalid': !isValid,
    [`TextInput--limit-${characterLimit}`]: hasCharacterLimit,
    [className]: !!className,
  });

  const handleKeyDown = e => {
    if (e.key === 'Enter') onChangeCallback(inputValue);
  };

  return (
    <div className={classes}>
      {labelText && (
        <label htmlFor={name}>
          {labelText}
          {required && <span>*</span>}
        </label>
      )}
      <input
        name={name}
        type={hasType ? type : undefined}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        value={inputValue}
        maxLength={characterLimit}
        onFocus={e => {
          if (onFocusSelectText) {
            e.target.select();
          }
        }}
        onKeyDown={handleKeyDown}
        onBlur={() => onChangeCallback(inputValue)}
        onMouseUp={() => {
          if (isFireFox) {
            onChangeCallback(inputValue);
          }
        }}
        onChange={e => {
          const { value } = e.target;

          if (validation && inputValue !== '') {
            setIsValid(validation.test(value));
          }

          setInputValue(value);
        }}
        autoComplete="off"
        min={min}
      />
    </div>
  );
};

TableTextInput.propTypes = {
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelText: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChangeCallback: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  characterLimit: PropTypes.number,
  type: PropTypes.string,
  validation: PropTypes.instanceOf(RegExp),
  min: PropTypes.number,
};

TableTextInput.defaultProps = {
  disabled: false,
  inline: false,
  initialValue: '',
  labelText: undefined,
  placeholder: '',
  onChangeCallback: () => {},
  required: false,
  characterLimit: undefined,
  validation: undefined,
  min: null,
};

export default TableTextInput;
