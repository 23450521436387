import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import {
  createPaymentScheduleInstallment,
  updatePaymentScheduleInstallment,
  deletePaymentScheduleInstallment,
} from '~actions/paymentScheduleInstallments';
import { getInvoice } from '~actions/invoice';
import { clearErrors } from '~actions/errors';
import { actionsErroredSelector } from '~selectors';
import { SCHEDULE_HEADERS } from '~constants/invoices';
import { toastOptions } from '~constants/toasts';
import { toCurrencyAmount, formatCurrencyInput } from '~utils';
import { Table, Pill } from '~ui';
import MobileInvoiceInstallments from '../MobileInvoiceInstallments';
import styles from './InvoiceInstallmentsTable.module.scss';

const title = 'Payment Schedule';
const subtitle = 'When you will be receiving your payments for this invoice';

const InvoiceInstallmentsTable = ({
  schedule,
  readOnly,
  installmentsSum,
  isMobile,
}) => {
  const dispatch = useDispatch();
  const invoice = useSelector(state => state.invoice);
  const errors = useSelector(state =>
    actionsErroredSelector(state, [
      'PAYMENT_SCHEDULE_INSTALLMENTS_CREATE',
      'PAYMENT_SCHEDULE_INSTALLMENTS_UPDATE',
    ])
  );

  const asyncCreate = async params =>
    dispatch(createPaymentScheduleInstallment(params));

  const asyncUpdate = async (id, params) =>
    dispatch(updatePaymentScheduleInstallment(id, params));

  const asyncDelete = async (id, params) =>
    dispatch(deletePaymentScheduleInstallment(id, params));

  const handleCreate = params => {
    if (params.percent_amount) {
      priceAdjuster(id, params);
    }
    const wrappedCreateParams = {
      payment_schedule_installment: {
        ...params,
        ...(!!params.amount && {
          amount: formatCurrencyInput(params.amount),
        }),
        ...(!!params.percent_amount && {
          percent_amount: formatCurrencyInput(params.percent_amount),
        }),
        payment_schedule_id: schedule.id,
        order: schedule.installments.length + 1,
      },
    };
    asyncCreate(wrappedCreateParams).then(() => {
      dispatch(getInvoice(invoice.id));
    });
  };

  const handleUpdate = (id, params) => {
    if (params.percent_amount) {
      priceAdjuster(id, params);
    }

    const wrappedParams = {
      payment_schedule_installment: {
        ...params,
        ...(!!params.amount && {
          amount: formatCurrencyInput(params.amount),
        }),
        ...(!!params.percent_amount && {
          percent_amount: formatCurrencyInput(params.percent_amount),
        }),
        payment_schedule_id: schedule.id,
      },
    };

    asyncUpdate(id, wrappedParams).then(() => {
      dispatch(getInvoice(invoice.id));
    });
  };

  const handleDelete = id => {
    const params = {
      payment_schedule_installment: {
        payment_schedule_id: schedule.id,
      },
    };
    asyncDelete(id, params).then(() => {
      dispatch(getInvoice(invoice.id));
    });
  };

  const renderInstallmentStatus = status => {
    switch (status) {
      case 'paid':
        return <Pill text="Paid" className="va-t" color="green" small />;
      case 'late':
        return <Pill text="Late" className="va-t" color="red" small />;
      case 'partially_paid':
        return (
          <Pill text="Partially Paid" className="va-t" color="orange" small />
        );
      case 'unpaid':
        return <Pill text="Unpaid" className="va-t" color="orange" small />;
      case 'ach_pending':
        return (
          <Pill text="ACH Pending" className="va-t" color="orange" small />
        );
      default:
        return <></>;
    }
  };

  const showErrorToasts = () => {
    let needToClearErrors = false;
    const position = { position: 'bottom-center' };

    if (errors.PAYMENT_SCHEDULE_INSTALLMENTS_CREATE) {
      errors.PAYMENT_SCHEDULE_INSTALLMENTS_CREATE.forEach(err => {
        toast.error(err, { ...toastOptions, ...position });
      });
      needToClearErrors = true;
    }

    if (errors.PAYMENT_SCHEDULE_INSTALLMENTS_UPDATE) {
      errors.PAYMENT_SCHEDULE_INSTALLMENTS_UPDATE.forEach(err => {
        toast.error(err, { ...toastOptions, ...position });
      });
      needToClearErrors = true;
    }

    if (needToClearErrors) {
      dispatch(clearErrors());
    }
  };

  const onlyOneInstallment = schedule.installments.length === 1;
  const rows = schedule.installments.map(installment => {
    const {
      id,
      order,
      due_date,
      amount,
      interval,
      interval_amount,
      percent_amount,
      status,
    } = installment;

    const row = {
      id,
      editable: true,
      deletable: !onlyOneInstallment,
      data: [
        { name: 'order', value: order, type: 'number' },
        {
          name: 'due_date',
          type: order === 1 ? 'datetime' : 'toggle_datetime',
          value: due_date,
          editable: true,
        },
        {
          name: 'amount',
          value: amount,
          type: 'toggle_amount',
          editable: true,
        },
        {
          name: 'status',
          value: () => renderInstallmentStatus(status),
          type: 'function',
          editable: false,
        },
      ],
    };

    if (interval && interval_amount) {
      row.data[1].interval = interval;
      row.data[1].interval_amount = interval_amount;
    }

    if (percent_amount) {
      row.data[2].percent_amount = percent_amount;
    }

    return row;
  });

  const priceAdjuster = (id, params) => {
    let totalPercentage = 0;
    let totalInstallmentAmount = 0;
    let oneCentTooMany = false;
    let oneCentTooLittle = false;
    const paramAmount = Number(params.percent_amount / 100) * invoice.total;

    rows.map(row => {
      if (row.id !== id) {
        if (!row.data[2].percent_amount) {
          totalPercentage += Number(
            (row.data[2].value / invoice.total).toFixed(2)
          );
          totalInstallmentAmount += Number(row.data[2].value);
        } else {
          totalPercentage += Number(row.data[2].percent_amount);
          totalInstallmentAmount += Number(row.data[2].value);
        }
      }
    });

    totalPercentage += Number(params.percent_amount) / 100;
    totalInstallmentAmount += Number(paramAmount.toFixed(2));

    if (totalPercentage == 1) {
      if ((totalInstallmentAmount - invoice.total).toFixed(2) == 0.01) {
        oneCentTooMany = true;
      } else if ((invoice.total - totalInstallmentAmount).toFixed(2) == 0.01) {
        oneCentTooLittle = true;
      }
    }

    if (oneCentTooMany) {
      params.amount = Number(paramAmount.toFixed(2) - 0.01).toFixed(2);
      return { ...params };
    } else if (oneCentTooLittle) {
      params.amount = Number(paramAmount.toFixed(2) + 0.01).toFixed(2);
      return { ...params };
    } else {
      return params;
    }
  };

  const schema = [
    { name: 'order', value: '', type: 'number' },
    { name: 'due_date', value: '', type: 'toggle_datetime', editable: true },
    { name: 'amount', value: '', type: 'toggle_amount', editable: true },
    { name: 'status', value: '', type: 'function' },
  ];

  const calculationError =
    !invoice.paid_in_full &&
    Number.parseFloat(installmentsSum) !== Number.parseFloat(invoice.total);

  showErrorToasts();

  return (
    <div className={styles.InvoiceInstallmentsTable}>
      {isMobile ? (
        <MobileInvoiceInstallments
          title={title}
          subtitle={subtitle}
          readOnly={readOnly}
          handleCreate={handleCreate}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
          renderInstallmentStatus={renderInstallmentStatus}
        />
      ) : (
        <Table
          headers={SCHEDULE_HEADERS}
          rows={rows}
          schema={schema}
          title={title}
          subtitle={subtitle}
          readOnly={readOnly}
          handleCreate={handleCreate}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
        />
      )}
      {calculationError && (
        <p className={styles['InvoiceInstallmentsTable-error']}>
          * The sum of these installments ({toCurrencyAmount(installmentsSum)})
          does not add up to the total amount due for this invoice.
        </p>
      )}
    </div>
  );
};

InvoiceInstallmentsTable.propTypes = {
  schedule: PropTypes.shape({
    name: PropTypes.string,
    installments: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  readOnly: PropTypes.bool,
  installmentsSum: PropTypes.number,
  isMobile: PropTypes.bool.isRequired,
};

InvoiceInstallmentsTable.defaultProps = {
  readOnly: false,
  installmentsSum: 0,
};

export default InvoiceInstallmentsTable;
