import React from 'react';
import { Accordion } from '~ui';
import { routes, absolutePath } from '~constants/routes';
import styles from './LeadsPagePreview.module.scss';

const LeadsPagePreview = props => {
  const { meta } = props;
  return (
    <div className={styles.LeadsPagePreview}>
      <Accordion title="Leads Page Form Preview" expanded={false}>
        <iframe
          src={absolutePath(`/customer/leads/new?ref=${meta.studio_id}`)}
        />
      </Accordion>
    </div>
  );
};

export default LeadsPagePreview;
