import React, { useState } from 'react';
import { routes } from '~constants/routes';
import { getQuestionnaires } from '~actions/questionnaires';
import axios from 'axios';
import { Modal, Button, Dropdown, Icon } from '~ui';
import { useSelector, useDispatch } from 'react-redux';
import styles from '../DocumentsContent/DocumentsContent.module.scss';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import SendLimitReached from '../../common/SendLimitReached';

const QuestionnairesButtons = ({ questionnaireId, questionnaireName }) => {
  const dispatch = useDispatch();

  const { clientsOptions, activeTrial, studioId, trialVerified } = useSelector(
    state => state.documents
  );
  const [showSendModal, setShowSendModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [disableSend, setDisableSend] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [showBeforeSend, setShowBeforeSend] = useState(false);
  const [miniLoading, setMiniLoading] = useState(false);

  const checkValidation = async () => {
    if (activeTrial && !trialVerified) {
      setMiniLoading(true);

      const res = await axios.get(
        routes.STUDIO_PROFILE.CHECK_SEND_LIMIT(studioId)
      );
      if (res.data.reached_send_limit) {
        setMiniLoading(false);

        setShowBeforeSend(true);
      } else {
        setMiniLoading(false);

        setShowSendModal(true);
      }
    } else {
      setShowSendModal(true);
    }
  };

  const handleDuplicate = async () => {
    try {
      await axios.put(routes.QUESTIONNAIRES.DUPLICATE(questionnaireId));
      dispatch(getQuestionnaires());
    } catch (error) {
      console.error(error);
      Rollbar.error('Error duplicating questionnaire');
      toast.error('Error duplicating questionnaire');
    }
    setShowDuplicateModal(false);
  };

  const handleSend = async () => {
    try {
      setDisableSend(true);
      const res = await axios.put(
        routes.QUESTIONNAIRES.SEND_IT(questionnaireId),
        {
          client_id: clientId,
        }
      );

      if (res.status === 204) {
        toast.info('Questionnaire Sent!', toastOptions);
      }

      setShowSendModal(false);
      setDisableSend(false);
    } catch (error) {
      if (error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Error sending questionnaire', toastOptions);
      }
      console.error(error);
      Rollbar.error('Error sending questionnaire');
      setShowSendModal(false);
      setDisableSend(false);
    }
  };

  return (
    <>
      <a
        onClick={e => {
          e.stopPropagation();
          setShowDuplicateModal(true);
        }}
      >
        Duplicate
      </a>
      <a
        onClick={e => {
          e.stopPropagation();
          checkValidation();
        }}
      >
        Send
      </a>
      {showSendModal && (
        <Modal
          title={`Send ${questionnaireName}`}
          handleHide={() => setShowSendModal(false)}
        >
          <Dropdown
            name="client"
            labelText="Client"
            onChangeCallback={setClientId}
            options={clientsOptions}
          />
          <div className={styles.DocumentModalButtonContainer}>
            <Button
              text="Cancel"
              onClick={() => setShowSendModal(false)}
              danger
            />
            <Button
              text="Send"
              onClick={handleSend}
              disabled={!clientId || disableSend}
            />
          </div>
        </Modal>
      )}
      {showDuplicateModal && (
        <Modal
          title={`Duplicate ${questionnaireName}`}
          handleHide={() => setShowDuplicateModal(false)}
        >
          <p>Are you sure you'd like to duplicate this Questionnaire?</p>
          <div className={styles.DocumentModalButtonContainer}>
            <Button
              text="Cancel"
              danger
              onClick={() => setShowDuplicateModal(false)}
            />
            <Button text="Duplicate" onClick={handleDuplicate} />
          </div>
        </Modal>
      )}
      {miniLoading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingText}>
            <p>Loading...</p>
            <Icon name="spinner" className="fa-pulse" large />
          </div>
        </div>
      )}
      {showBeforeSend && (
        <SendLimitReached handleHide={() => setShowBeforeSend(false)} />
      )}
    </>
  );
};

export default QuestionnairesButtons;
