import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { uniqueId } from '~utils';
import { Checkbox, Icon, KeydownListener } from '~ui';
import { keyCodes } from '~constants/keyCodes';
import styles from './MultiSelectSearch.module.scss';

const MultiSelectSearch = props => {
  const {
    disabled,
    name,
    onChangeCallback,
    onDeleteCallback,
    options,
    placeholder,
    initialValue,
  } = props;
  const [value, setValue] = useState('');
  const [selectedOptions, setSelectedOptions] = useState(initialValue);
  const [showOptions, setShowOptions] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState();
  const cx = classNames.bind(styles);
  const optionClasses = cx('MultiSelectSearch-optionsPane', {
    'MultiSelectSearch-optionsPane--focused': showOptions,
  });
  const inputClasses = cx('MultiSelectSearch-searchBar', {
    'MultiSelectSearch-searchBar--disabled': disabled,
  });
  const optionsRef = useRef(null);
  const selectedOptionsRef = useRef(null);

  // Updates the selected options shown if the parent updates the options selected from an outside source
  useEffect(() => {
    setSelectedOptions(initialValue);
  }, [initialValue]);

  const handleClickOutside = e => {
    if (showOptions === false) {
      return;
    }

    if (
      optionsRef.current &&
      !optionsRef.current.contains(e.target) &&
      selectedOptionsRef.current &&
      !selectedOptionsRef.current.contains(e.target)
    ) {
      setShowOptions(false);
      return;
    }
  };

  useLayoutEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showOptions]);

  const handleOnChange = e => {
    const { value } = e.target;
    setValue(value);
    setShowOptions(true);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(setTimeout(() => {}, 500));
  };

  const updateSelectedOptions = option => {
    const values = selectedOptions.map(opt => opt.value);

    if (values.includes(option.value)) {
      const newOptions = selectedOptions.filter(
        selected => selected.value !== option.value
      );
      setSelectedOptions(newOptions);
      onDeleteCallback(option);
    } else {
      setSelectedOptions([...selectedOptions, option]);
      onChangeCallback(option);
    }
  };

  const renderOptions = () => {
    const filteredOptions = options.filter(option =>
      option.label.toLowerCase().match(value.toLowerCase())
    );
    const categories = options.map(option => option.category);
    const sortedOptions = categories.reduce((acc, curr) => {
      acc[curr] = filteredOptions.filter(option => option.category === curr);
      return acc;
    }, {});

    return (
      <div className={optionClasses} ref={optionsRef}>
        {Object.keys(sortedOptions).map(category =>
          renderList(category, sortedOptions[category])
        )}
        <KeydownListener
          keyCode={keyCodes.ESCAPE}
          onKeyDown={() => setShowOptions(false)}
        />
      </div>
    );
  };

  const renderList = (category, categoryOptions) => {
    return (
      <ul
        key={uniqueId('MultiSelectSearch-category')}
        aria-labelledby="searchBar"
      >
        <li>
          <strong>{category}</strong>
        </li>
        {categoryOptions.length > 0 &&
          categoryOptions.map(({ label, value }, index) => (
            <li key={uniqueId('MultiSelectSearch-option')}>
              <Checkbox
                name={label}
                checked={selectedOptions.map(opt => opt.value).includes(value)}
                onChangeCallback={() =>
                  updateSelectedOptions(categoryOptions[index])
                }
              />{' '}
              <span>{label}</span>
            </li>
          ))}
        {categoryOptions.length === 0 && (
          <li key={uniqueId('MultiSelectSearch-option')}>
            <span>No matches.</span>
          </li>
        )}
      </ul>
    );
  };

  return (
    <div className={styles.MultiSelectSearch}>
      {selectedOptions.length > 0 && (
        <div
          className={styles['MultiSelectSearch-selected']}
          ref={selectedOptionsRef}
        >
          {selectedOptions.map(option => (
            <div
              key={uniqueId('selected_')}
              className={styles['MultiSelectSearch-selectedOption']}
            >
              <Icon name={option.icon} className="mr-8" small />
              <span className="mr-24">{option.label}</span>
              <a
                className="fa fa-times"
                onClick={() => {
                  const index = selectedOptions
                    .map(opt => opt.label)
                    .indexOf(option.label);
                  const optionsCopy = [...selectedOptions];
                  optionsCopy.splice(index, 1);
                  setSelectedOptions(optionsCopy);
                  onDeleteCallback(selectedOptions[index]);
                }}
              />
            </div>
          ))}
        </div>
      )}
      <input
        id="multiSearch"
        name={name}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onFocus={() => setShowOptions(true)}
        onChange={e => handleOnChange(e)}
        aria-haspopup="true"
        aria-expanded={showOptions}
        role="searchbox"
        className={inputClasses}
        autoComplete={'off'}
      />
      {showOptions && !disabled && <>{renderOptions()}</>}
    </div>
  );
};

MultiSelectSearch.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChangeCallback: PropTypes.func,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

MultiSelectSearch.defaultProps = {
  disabled: false,
  onChangeCallback: () => {},
  placeholder: '',
};

export default MultiSelectSearch;
