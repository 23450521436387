import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import TogglePopover from '~components/common/TogglePopover';
import { TIME_SLOT_HEIGHT } from '~constants/calendar';
import CronofyEventSummary from '../CronofyEventSummary';
import styles from '../CalendarEvent/CalendarEvent.module.scss';

const CronofyEvent = props => {
  const { event, tall, setHeight, customStyles, studioTimezone } = props;
  const { summary, start, end, all_day } = event;
  const timeZone = useSelector(state => state.calendar.meta.timeZone);

  const timezonesAreSame =
    moment().tz(moment.tz.guess()).format('z') ==
    moment().tz(timeZone).format('z');

  const formatString = timezonesAreSame ? 'h:mm a' : 'h:mm a (z)';

  const formattedStartTime = moment(start.time).format(formatString);
  const cx = classNames.bind(styles);
  const classes = cx('CalendarEvent', {
    'CalendarEvent--tall': tall,
  });

  const getStyles = () => {
    let newStyles = { ...customStyles };
    newStyles.backgroundColor = event.color;
    newStyles.color = event.textColor;

    if (setHeight) {
      const timeDifference =
        moment(end.time).diff(moment(start.time), 'minutes') / 60;
      const relativeDistanceFromMidnight =
        (60 +
          moment(start.time).diff(
            moment(start.time).startOf('day'),
            'minutes'
          )) /
        60;
      const duration = all_day ? 0.5 : timeDifference;
      const offsetStart = all_day ? 0 : relativeDistanceFromMidnight;

      newStyles.height = `${TIME_SLOT_HEIGHT * duration}px`;

      if (!all_day) {
        newStyles.top = `${offsetStart * TIME_SLOT_HEIGHT}px`;
      }
    }

    return newStyles;
  };

  return (
    <TogglePopover
      activator={
        <div className={classes} style={getStyles()}>
          {!all_day && formattedStartTime} {summary}
        </div>
      }
      popoverContent={<CronofyEventSummary event={event} />}
    />
  );
};

CronofyEvent.propTypes = {
  event: PropTypes.object.isRequired,
  tall: PropTypes.bool,
  setHeight: PropTypes.bool,
};

CronofyEvent.defaultProps = {
  tall: false,
  setHeight: false,
};

export default CronofyEvent;
