import React, { useEffect, useState } from 'react';
import { Modal, Button, Dropdown, DateTimePicker, Toggle } from '~ui';
import styles from './SendModal.module.scss';
import { absolutePath, routes } from '~constants/routes';
import axios from 'axios';
import moment from 'moment';
import { API_DATE_FORMAT } from '~constants/datetime';
import { usePrev } from '~hooks';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import PurchaseMarketing from './PurchaseMarketing';
import CreateSessionType from './CreateSessionType';
const SendModal = ({
  handleHide,
  emailCampaignTemplateId,
  setActiveTabKey,
  sessionTypesOptions,
  studioGroups,
  timeZone,
  marketing,
  marketingValidated,
}) => {
  const [sendLater, setSendLater] = useState(false);
  const [displayPurchaseMarketing, setDisplayPurchaseMarketing] = useState(
    false
  );
  const timezonesAreSame =
    moment().tz(moment.tz.guess()).format('z') ==
    moment().tz(timeZone).format('z');

  const [futureSendTime, setFutureSendTime] = useState(moment().tz(timeZone));

  const formatString = timezonesAreSame
    ? 'hh:mma MMMM DD, YYYY'
    : 'hh:mma MMMM DD, YYYY (z)';

  const formattedStartTime = moment(futureSendTime)
    .tz(timeZone)
    .format(formatString);

  const [audienceChoice, setAudienceChoice] = useState(1);

  const [showClientFilterChoice, setShowClientFilterChoice] = useState(false);
  const [clientFilterChoice, setClientFilterChoice] = useState(1);

  const [isIsNot, setIsIsNot] = useState('is');
  const [isIsNot2, setIsIsNot2] = useState('is');

  const [showClientFilterChoice2, setShowClientFilterChoice2] = useState(false);
  const [clientFilterChoice2, setClientFilterChoice2] = useState(1);

  const [datePreposition, setDatePreposition] = useState(1);
  const [date, setDate] = useState(moment());
  const [date2, setDate2] = useState(moment());

  const [chosenSessionType, setChosenSessionType] = useState(
    sessionTypesOptions.length > 0 ? sessionTypesOptions[0].value : null
  );

  const [chosenGroup, setChosenGroup] = useState(
    studioGroups.length > 0 ? studioGroups[0].value : null
  );

  const [clientIdsArray, setClientIdsArray] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const choiceValue = clientFilterOptions.find(
      opt => opt.value !== clientFilterChoice
    ).value;
    setClientFilterChoice2(choiceValue);
  }, [clientFilterChoice]);

  const prevAudienceChoice = usePrev(audienceChoice);

  useEffect(() => {
    if (prevAudienceChoice !== audienceChoice && showClientFilterChoice) {
      setShowClientFilterChoice(false);
      setShowClientFilterChoice2(false);
      setClientFilterChoice(1);
      setDatePreposition(1);
      setChosenSessionType(
        sessionTypesOptions.length > 0 ? sessionTypesOptions[0].value : null
      );
      setChosenGroup(studioGroups.length > 0 ? studioGroups[0].value : null);
    } else {
      getClients();
    }
  }, [
    audienceChoice,
    clientFilterChoice,
    clientFilterChoice2,
    datePreposition,
    chosenSessionType,
    chosenGroup,
    date,
    date2,
    showClientFilterChoice,
    showClientFilterChoice2,
    isIsNot,
  ]);

  const getClients = async () => {
    const getAudience = () => {
      switch (audienceChoice) {
        case 1:
          return 'all'; // Active and Inactive
        case 2:
          return 'active'; // Only Active
        case 3:
          return 'repeat'; // Only Repeat
        case 4:
          return 'leads'; // Only leads
        case 5:
          return 'marketing_contacts'; // Only marketingContacts
        default:
          return 'all';
      }
    };

    const getDatePreposition = prep => {
      switch (prep) {
        case 1:
          return 'before';
        case 2:
          return 'after';
        case 3:
          return 'between';
        default:
          return 'before';
      }
    };

    const getFilter = choice => {
      switch (choice) {
        case 1:
          if (isClients) {
            return {
              name: 'sessionType',
              value: chosenSessionType,
            };
          } else {
            return {
              name: 'dateAdded',
              preposition: getDatePreposition(datePreposition),
              value: [
                moment(date).format(API_DATE_FORMAT),
                moment(date2).format(API_DATE_FORMAT),
              ],
            };
          }
        case 2:
          return {
            name: 'dateOfMostRecentSession',
            value: [
              moment(date).format(API_DATE_FORMAT),
              moment(date2).format(API_DATE_FORMAT),
            ],
            preposition: getDatePreposition(datePreposition),
          };
        case 3:
          return {
            name: 'group',
            value: chosenGroup,
          };
        default:
          return {
            name: 'sessionType',
            value: chosenSessionType,
          };
      }
    };

    const filterData = {
      audience: getAudience(),
      filters: [
        ...(showClientFilterChoice
          ? [
              {
                is: isIsNot,
                name: getFilter(clientFilterChoice).name,
                preposition: getFilter(clientFilterChoice).preposition
                  ? getFilter(clientFilterChoice).preposition
                  : null,
                value: getFilter(clientFilterChoice).value,
              },
            ]
          : []),
        ...(showClientFilterChoice && showClientFilterChoice2
          ? [
              {
                is: isIsNot2,
                name: getFilter(clientFilterChoice2).name,
                preposition: getFilter(clientFilterChoice2).preposition
                  ? getFilter(clientFilterChoice2).preposition
                  : null,
                value: getFilter(clientFilterChoice2).value,
              },
            ]
          : []),
      ],
    };

    try {
      const res = await axios.post(
        routes.EMAIL_CAMPAIGN_TEMPLATES.GET_CLIENTS,
        {
          client_filters: filterData,
        }
      );

      setClientIdsArray(res.data.client_ids);
    } catch (error) {
      console.error(error);
    }
  };

  const audienceOptions = [
    { label: 'All Clients (Active & Inactive)', value: 1 },
    { label: 'Active Clients', value: 2 },
    { label: 'Repeat Clients', value: 3 },
    { label: 'Leads', value: 4 },
    { label: 'Marketing Contacts', value: 5 },
  ];

  const clientFilterOptions = [
    { label: 'Session Type', value: 1 },
    { label: 'Date of Most Recent Session', value: 2 },
    ...(studioGroups.length > 0 ? [{ label: 'Group', value: 3 }] : []),
  ];

  const leadsFilterOptions = [
    {
      label: 'Date Added is',
      value: 1,
    },
  ];

  const marketingContactsFilterOptions = [
    {
      label: 'Date Added is',
      value: 1,
    },
  ];

  const getAdditionalFiltersOptions = () => {
    switch (audienceChoice) {
      case 1:
      case 2:
      case 3:
        return clientFilterOptions;
      case 4:
        return leadsFilterOptions;
      case 5:
        return marketingContactsFilterOptions;
      default:
        break;
    }
  };

  const clearFilters = () => {
    setAudienceChoice(1);
    setShowClientFilterChoice(false);
    setShowClientFilterChoice2(false);
    setClientFilterChoice(1);
    setDatePreposition(1);
  };

  const handleTestSend = async () => {
    try {
      const res = await axios.put(
        routes.EMAIL_CAMPAIGN_TEMPLATES.TEST_SEND(emailCampaignTemplateId)
      );
      toast.success(
        'Test email(s) sent to your email address, please allow a few minutes to complete the send!',
        toastOptions
      );
    } catch (error) {
      toast.error('Unable to send test email(s)');
    }
  };

  const clientFilterOptionsForThird = clientFilterOptions.filter(
    option => +option.value !== clientFilterChoice
  );

  function getDaysBetweenDates(dateStr1, dateStr2) {
    const date1 = moment(dateStr1, 'MMMM DD, YYYY');
    const date2 = moment(dateStr2, 'hh:mma MMMM DD, YYYY');

    const diffInDays = date2.diff(date1, 'days');

    return diffInDays;
  }

  const handleSend = async () => {
    try {
      setLoading(true);
      const res = await axios.put(
        routes.EMAIL_CAMPAIGN_TEMPLATES.SEND_TO_CLIENTS(
          emailCampaignTemplateId
        ),
        {
          email_campaign_template: {
            client_ids: clientIdsArray,
            ...(sendLater
              ? {
                  future_send_time: moment(futureSendTime).utc(),
                }
              : {}),
          },
        }
      );

      if (res.status === 202) {
        if (sendLater) {
          toast.success('Email Campaign Started!', toastOptions);
        } else {
          toast.success('Email Campaign Sent!', toastOptions);
        }
        handleHide();
        if (setActiveTabKey) {
          setActiveTabKey('campaigns');
        } else {
          window.location.href = absolutePath('/email_marketing');
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        'There was an error while sending your email campaign, please contact support',
        toastOptions
      );
      console.error(error);
    }
  };

  const isClients =
    audienceChoice == 1 || audienceChoice == 2 || audienceChoice == 3;

  const title =
    sessionTypesOptions.length == 0
      ? 'Create Session Type'
      : !marketing && !marketingValidated && displayPurchaseMarketing
      ? 'Upgrade Now'
      : marketing && !marketingValidated && displayPurchaseMarketing
      ? 'Please Wait!'
      : 'Send to Clients';

  return (
    <Modal title={title} handleHide={handleHide}>
      {sessionTypesOptions.length == 0 ? (
        <CreateSessionType handleHide={handleHide} />
      ) : displayPurchaseMarketing ? (
        <PurchaseMarketing handleHide={handleHide} marketing={marketing} />
      ) : (
        <>
          <div className={styles.SendModal}>
            <div className={styles['SendModal-sendingCount']}>
              <p>
                {' '}
                Currently sending to {clientIdsArray.length} clients
                {sendLater ? ` @ ${formattedStartTime}` : ''}
              </p>
            </div>
            <div className={styles['SendModal-header']}>
              <h3>Choose your Audience:</h3>
              <Button white text="Clear Filters" onClick={clearFilters} />
            </div>
            <div className={styles['SendModal-input']}>
              <Dropdown
                name="audience"
                initialValue={String(audienceChoice)}
                onChangeCallback={val => {
                  setAudienceChoice(+val);
                }}
                options={audienceOptions}
              />
            </div>
            <div className={styles['SendModal-additionalButton']}>
              {showClientFilterChoice ? (
                'Where'
              ) : (
                <Button
                  text="Add Additional Filter"
                  icon="plus"
                  disabled={!audienceChoice}
                  onClick={() => setShowClientFilterChoice(true)}
                />
              )}
            </div>
            {showClientFilterChoice && (
              <div className={styles['SendModal-additionalFiltersContainer']}>
                <div
                  className={
                    styles['SendModal-additionalFiltersContainer-line']
                  }
                >
                  <div
                    className={
                      styles['SendModal-additionalFiltersContainer-dropdown']
                    }
                  >
                    <Dropdown
                      name="second"
                      options={getAdditionalFiltersOptions()}
                      onChangeCallback={val => setClientFilterChoice(+val)}
                    />
                  </div>
                  {clientFilterChoice == 1 || clientFilterChoice == 3 ? (
                    <div
                      className={
                        styles[
                          'SendModal-additionalFiltersContainer-isDropdown'
                        ]
                      }
                    >
                      <Dropdown
                        name="isnot"
                        options={[
                          { label: 'is', value: 'is' },
                          { label: 'is not', value: 'is_not' },
                        ]}
                        onChangeCallback={val => setIsIsNot(val)}
                      />
                    </div>
                  ) : (
                    <p>is</p>
                  )}
                  {isClients && clientFilterChoice == 1 && (
                    <div
                      className={
                        styles['SendModal-additionalFiltersContainer-dropdown']
                      }
                    >
                      <Dropdown
                        name="sessionTypes"
                        options={sessionTypesOptions}
                        onChangeCallback={setChosenSessionType}
                      />
                    </div>
                  )}
                  {studioGroups.length > 0 &&
                    isClients &&
                    clientFilterChoice == 3 && (
                      <div
                        className={
                          styles[
                            'SendModal-additionalFiltersContainer-dropdown'
                          ]
                        }
                      >
                        <Dropdown
                          name="groups"
                          options={studioGroups}
                          onChangeCallback={setChosenGroup}
                        />
                      </div>
                    )}
                  {((isClients && clientFilterChoice == 2) || !isClients) && (
                    <>
                      <div
                        className={
                          styles[
                            'SendModal-additionalFiltersContainer-datePreposition'
                          ]
                        }
                      >
                        <Dropdown
                          name="preposition"
                          onChangeCallback={val => setDatePreposition(+val)}
                          options={[
                            { label: 'Before', value: 1 },
                            { label: 'After', value: 2 },
                            { label: 'Between', value: 3 },
                          ]}
                        />
                      </div>
                      {datePreposition < 3 && (
                        <div
                          className={
                            styles[
                              'SendModal-additionalFiltersContainer-datePicker'
                            ]
                          }
                        >
                          <DateTimePicker
                            name="date1"
                            disableTime
                            initialValue={date}
                            onChangeCallback={setDate}
                          />
                        </div>
                      )}
                      {datePreposition == 3 && (
                        <>
                          <div
                            className={
                              styles[
                                'SendModal-additionalFiltersContainer-datePicker'
                              ]
                            }
                          >
                            <DateTimePicker
                              name="date1"
                              disableTime
                              initialValue={date}
                              onChangeCallback={setDate}
                            />
                          </div>
                          &amp;
                          <div
                            className={
                              styles[
                                'SendModal-additionalFiltersContainer-datePicker'
                              ]
                            }
                          >
                            <DateTimePicker
                              name="date2"
                              disableTime
                              initialValue={date2}
                              onChangeCallback={setDate2}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
            {isClients && showClientFilterChoice && (
              <div className={styles['SendModal-additionalButton']}>
                {!showClientFilterChoice2 ? (
                  <Button
                    text="And"
                    icon="plus"
                    onClick={() => setShowClientFilterChoice2(true)}
                  />
                ) : (
                  'And'
                )}
              </div>
            )}

            {showClientFilterChoice && showClientFilterChoice2 && (
              <div className={styles['SendModal-additionalFiltersContainer']}>
                <div
                  className={
                    styles['SendModal-additionalFiltersContainer-line']
                  }
                >
                  <div
                    className={
                      styles['SendModal-additionalFiltersContainer-dropdown']
                    }
                  >
                    <Dropdown
                      name="thirdoptions"
                      options={clientFilterOptionsForThird}
                      onChangeCallback={val => setClientFilterChoice2(+val)}
                    />
                  </div>
                  {clientFilterChoice2 == 1 || clientFilterChoice2 == 3 ? (
                    <div
                      className={
                        styles[
                          'SendModal-additionalFiltersContainer-isDropdown'
                        ]
                      }
                    >
                      <Dropdown
                        name="isnot"
                        options={[
                          { label: 'is', value: 'is' },
                          { label: 'is not', value: 'is_not' },
                        ]}
                        onChangeCallback={val => setIsIsNot2(val)}
                      />
                    </div>
                  ) : (
                    <p>is</p>
                  )}
                  {isClients && clientFilterChoice2 == 1 && (
                    <div
                      className={
                        styles['SendModal-additionalFiltersContainer-dropdown']
                      }
                    >
                      <Dropdown
                        name="sessiontypes"
                        options={sessionTypesOptions}
                        onChangeCallback={setChosenSessionType}
                      />
                    </div>
                  )}
                  {studioGroups.length > 0 &&
                    isClients &&
                    clientFilterChoice2 == 3 && (
                      <div
                        className={
                          styles[
                            'SendModal-additionalFiltersContainer-dropdown'
                          ]
                        }
                      >
                        <Dropdown
                          name="groups"
                          options={studioGroups}
                          onChangeCallback={setChosenGroup}
                        />
                      </div>
                    )}
                  {((isClients && clientFilterChoice2 == 2) || !isClients) && (
                    <>
                      <div
                        className={
                          styles[
                            'SendModal-additionalFiltersContainer-datePreposition'
                          ]
                        }
                      >
                        <Dropdown
                          name="preposition"
                          onChangeCallback={val => setDatePreposition(+val)}
                          options={[
                            { label: 'Before', value: 1 },
                            { label: 'After', value: 2 },
                            { label: 'Between', value: 3 },
                          ]}
                        />
                      </div>
                      {datePreposition < 3 && (
                        <div
                          className={
                            styles[
                              'SendModal-additionalFiltersContainer-datePicker'
                            ]
                          }
                        >
                          <DateTimePicker
                            name="date1"
                            disableTime
                            initialValue={date}
                            onChangeCallback={setDate}
                          />
                        </div>
                      )}
                      {datePreposition == 3 && (
                        <>
                          <div
                            className={
                              styles[
                                'SendModal-additionalFiltersContainer-datePicker'
                              ]
                            }
                          >
                            <DateTimePicker
                              name="date1"
                              disableTime
                              initialValue={date}
                              onChangeCallback={setDate}
                            />
                          </div>
                          &amp;
                          <div
                            className={
                              styles[
                                'SendModal-additionalFiltersContainer-datePicker'
                              ]
                            }
                          >
                            <DateTimePicker
                              name="date2"
                              disableTime
                              initialValue={date2}
                              onChangeCallback={setDate2}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          <div style={{ marginTop: '30px' }}>
            <div>Schedule for later?</div>
            <div className={styles['SendModal-sendLater']}>
              <p>No</p>
              <Toggle
                initialValue={false}
                onChangeCallback={val => setSendLater(val)}
              />
              <p>Yes</p>
            </div>
            {sendLater && (
              <div>
                <p>Pick a date and time this should be sent out:</p>{' '}
                <DateTimePicker
                  name="futureSendTime"
                  onChangeCallback={setFutureSendTime}
                  initialDateTime={futureSendTime}
                />
              </div>
            )}
          </div>
          <div className={styles['SendModal-footerButtons']}>
            <Button text="Cancel" danger onClick={handleHide} />
            <Button text="Send Test to Myself" white onClick={handleTestSend} />
            <Button
              text={
                sendLater
                  ? 'Schedule send'
                  : `Send to ${clientIdsArray.length} clients`
              }
              disabled={clientIdsArray.length < 1 || loading}
              onClick={() => {
                if (!marketing || !marketingValidated) {
                  setDisplayPurchaseMarketing(true);
                } else {
                  handleSend();
                }
              }}
            />
          </div>
        </>
      )}
    </Modal>
  );
};

export default SendModal;
