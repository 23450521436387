import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { routes } from '~constants/routes';
import { DATE_KEY_FORMAT } from '~constants/datetime';
import BookingCalendarHeader from './BookingCalendarHeader';
import MonthView from './MonthView';
import styles from './BookingCalendar.module.scss';

const BookingCalendar = props => {
  const {
    calendarId,
    onClickCallback,
    timeZone,
    firstAvailabilityDate,
  } = props;
  const [dateContext, setDateContext] = useState(
    firstAvailabilityDate ? moment(firstAvailabilityDate) : moment()
  );
  const [availabilityGroup, setAvailabilityGroup] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getAvailabilitiesForInterval = async (start, end) => {
    const params = {
      booking_availability: {
        booking_calendar_id: calendarId,
        start: start.format(DATE_KEY_FORMAT),
        end: end.format(DATE_KEY_FORMAT),
      },
    };
    const response = await axios.get(routes.BOOKING_AVAILABILITIES.INDEX, {
      params,
    });

    const adjustedDateKeyAvailabilities = response.data.map(availability => ({
      ...availability,
      dateKey: moment(availability.start_time).format('Y-MM-DD'),
    }));

    setAvailabilityGroup(adjustedDateKeyAvailabilities);
  };

  useEffect(() => {
    const loadCalendar = async () => {
      await getAvailabilitiesForInterval(
        dateContext.clone().subtract(7, 'day'),
        dateContext.clone().endOf('month').add(7, 'day')
      );
      setIsLoading(false);
    };

    loadCalendar();
  }, []);

  const nextInterval = () => {
    const newDateContext = moment(dateContext).clone().add(1, 'month');

    const start = newDateContext.clone().startOf('month').subtract(7, 'day');
    const end = moment(dateContext)
      .clone()
      .add(1, 'month')
      .endOf('month')
      .add(7, 'day');

    setDateContext(newDateContext);
    getAvailabilitiesForInterval(start, end);
  };

  const prevInterval = () => {
    let newDateContext = moment(dateContext).clone().subtract(1, 'month');
    const start = moment(dateContext)
      .clone()
      .subtract(1, 'month')
      .startOf('month')
      .subtract(7, 'day');
    let actualStart;

    if (start.isBefore(moment())) {
      actualStart = moment();
    } else {
      actualStart = start;
    }

    setDateContext(newDateContext);
    getAvailabilitiesForInterval(
      actualStart,
      newDateContext.clone().endOf('month').add(7, 'day')
    );
  };

  return (
    <div className={styles.BookingCalendar}>
      <BookingCalendarHeader
        dateContext={dateContext}
        prevInterval={prevInterval}
        nextInterval={nextInterval}
      />
      {isLoading ? (
        <h1>Loading...</h1>
      ) : (
        <MonthView
          timeZone={timeZone}
          dateContext={dateContext}
          availabilities={availabilityGroup}
          onClickCallback={onClickCallback}
        />
      )}
    </div>
  );
};

export default BookingCalendar;
