import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './Dropdown.module.scss';

const Dropdown = props => {
  const {
    disabled,
    initialValue,
    inline,
    labelText,
    name,
    onChangeCallback,
    options,
    required,
    className,
    lightGrey,
  } = props;
  const hasClassName = !!className;
  const cx = classNames.bind(styles);
  const classes = cx('Dropdown', {
    'Dropdown--disabled': disabled,
    'Dropdown--inline': inline,
    'Dropdown--lightGrey': lightGrey,
    [className]: hasClassName,
  });
  const [value, setValue] = useState(
    initialValue ? initialValue.toString() : ''
  );

  useEffect(() => {
    setValue(initialValue ? initialValue.toString() : '');
  }, [initialValue]);

  return (
    <div className={classes}>
      {labelText && (
        <label htmlFor={name}>
          {labelText}
          {required && <span>*</span>}
        </label>
      )}
      <select
        name={name}
        value={value}
        onChange={e => {
          const { value } = e.target;
          setValue(value);
          onChangeCallback(value);
        }}
        required={required}
        disabled={disabled}
      >
        {options.map((option, index) => (
          <option
            key={`${name}_${option.value}-${index}`}
            value={option.value}
            children={option.label}
          />
        ))}
      </select>
    </div>
  );
};

Dropdown.propTypes = {
  disabled: PropTypes.bool,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inline: PropTypes.bool,
  labelText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string.isRequired,
  onChangeCallback: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  required: PropTypes.bool,
};

Dropdown.defaultProps = {
  disabled: false,
  initialValue: '',
  inline: false,
  labelText: null,
  onChangeCallback: () => {},
  required: false,
};

export default Dropdown;
