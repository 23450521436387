import React, { useState } from 'react';
import { Modal, Button, TextInput, Dropdown } from '~ui';
import axios from 'axios';
import { routes } from '~constants/routes';
import ImageUploading from 'react-images-uploading';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import styles from './StudioProfile.module.scss';

const SettingsEditModal = ({
  handleHide,
  studioId,
  spudUserId,
  timeZoneOptions,
  updateSpudUserProps,
  updateStudioProps,
  studio,
  spudUser,
}) => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [timeZone, setTimeZone] = useState('');
  const [logo, setLogo] = useState([]);
  const [banner, setBanner] = useState([]);
  const [showCurrentLogo, setShowCurrentLogo] = useState(
    studio.logo_file_name ? studio.logo_file_name  : ''
  );
  const [showCurrentBanner, setShowCurrentBanner] = useState(
    studio.banner_file_name ? studio.banner_file_name  : ''
  );
  const showLogoDelete = () => !!showCurrentLogo;
  const showBannerDelete = () => !!showCurrentBanner;
  const MAX_UPLOAD_MB = 20;
  const MAX_UPLOAD_BYTES = MAX_UPLOAD_MB * 1000000;

  const handleMediaChange = (mediaType) => (newMedia) => {
    const updatedMedia = newMedia.length > 0 ? newMedia[0].file.name : '';
    if (mediaType === 'logo') {
      setLogo(newMedia);
      setShowCurrentLogo(updatedMedia);
    } else if (mediaType === 'banner') {
      setBanner(newMedia);
      setShowCurrentBanner(updatedMedia);
    }
  };

  const handleBannerDelete = async () => {
    setShowCurrentBanner('');

    await handleStudioSave({
      studio: {
        banner: null,
        banner_file_name: null,
      },
    });
    window.location.reload();
  };

  const handleLogoDelete = async () => {
    setShowCurrentLogo('');

    await handleStudioSave({
      studio: {
        logo: null,
        logo_file_name: null,
      },
    });
    window.location.reload();
  };

  const handleStudioSave = async param => {
    try {
      const res = await axios.put(
        routes.STUDIO_PROFILE.UPDATE(studioId),
        param
      );

      if (res.status === 200) {
        toast.success('Settings Saved', toastOptions);
      }

      updateStudioProps(res);
    } catch (error) {
      toast.error('There was an error saving your settings', toastOptions);
      console.error(error);
    }
  };

  const updateStudio = id => {
    const studio = {};
    if (logo[0] && logo[0].file.size <= MAX_UPLOAD_BYTES) {
      studio.logo = logo[0].data_url;
      studio.logo_file_name = logo[0].file.name;
    } else if (logo[0]) {
      toast.error("Logo file size must be less than 20 MB");
      return;
    }

    if (banner[0] && banner[0].file.size <= MAX_UPLOAD_BYTES) {
      studio.banner = banner[0].data_url;
      studio.banner_file_name = banner[0].file.name;
    } else if (banner[0]) {
      toast.error("Banner file size must be less than 20 MB");
      return;
    }

    const params = {
      studio: studio,
    };

    if (Object.keys(studio).length !== 0) {
      axios.put(routes.STUDIO_PROFILE.UPDATE(id), params)
      .then(res => {
        updateStudioProps(res);
        window.location.reload();
      })
      .catch(error => {
        Rollbar.error("Error updating studio settings", error);
        const errorMessage = error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : `Unexpected error occurred: ${error.message}`;
        
        toast.error(`Error: ${errorMessage}`);
      });
    }
  };

  const updateSpudUser = id => {
    const spudUser = {};
    if (login !== '') {
      spudUser.login = login;
    }
    if (timeZone !== '') {
      spudUser.time_zone = timeZone;
    }
    if (password !== '' && password === confirmPassword) {
      spudUser.password = password;
      spudUser.password_confirmation = confirmPassword;
    } else if (password !== '' && password !== confirmPassword) {
      toast.error('Password and Confirm Password should match');
    }
    const params = {
      spud_user: spudUser,
    };
    if (Object.keys(spudUser).length !== 0) {
      axios.put(routes.SPUD_USER.UPDATE(id), params)
        .then(res => {
          updateSpudUserProps(res);
        })
        .catch(error => {
          toast.error("Error updating settings. Please reach out to support.")
        }
      );
    }
  };

  const updateAxios = (studioId, spudUserId) => {
    updateSpudUser(spudUserId);
    updateStudio(studioId);
  };

  return (
    <Modal
      title="Edit Settings"
      handleHide={handleHide}
      studioId={studioId}
      spudUserId={spudUserId}
      timeZoneOptions={timeZoneOptions}
      studio={studio}
      spudUser={spudUser}
    >
      <div className="mb-8">Login</div>
      <TextInput
        name="loginInput"
        onChangeCallback={setLogin}
        initialValue={spudUser.login}
      />
      <div className="mb-8 mt-8">Password</div>
      <TextInput
        name="passwordInput"
        type="password"
        initialValue=""
        onChangeCallback={setPassword}
      />
      <div className="mb-8 mt-8">Confirm Password</div>
      <TextInput
        name="confirmPasswordInput"
        type="password"
        initialValue=""
        onChangeCallback={setConfirmPassword}
      />
      <div className="mb-8 mt-8">Time Zone</div>
      <Dropdown
        name="time_zone"
        options={timeZoneOptions}
        initialValue={spudUser.time_zone}
        onChangeCallback={setTimeZone}
      />
      <div className={styles.StudioProfile}>
        <div className="mb-8 mt-8">Logo</div>
        <ImageUploading 
          value={logo}
          onChange={handleMediaChange('logo')} 
          dataURLKey="data_url"
        >
          {({ onImageUpload }) => (
            <div className={styles['StudioProfile-uploadContainer']}>
                <Button text="Upload" onClick={onImageUpload} />
                <div className={styles['StudioProfile-filename']}>
                  <p>{showCurrentLogo}</p>
                  {showLogoDelete() && (
                    <a
                      className="fas fa-trash-alt"
                      onClick={handleLogoDelete}
                    />
                  )}
              </div>
            </div>
          )}
        </ImageUploading>
        <div className="mb-8 mt-8">Banner</div>
        <ImageUploading 
          value={banner} 
          onChange={handleMediaChange('banner')} 
          dataURLKey="data_url"
        >
          {({ onImageUpload }) => (
            <div className={styles['StudioProfile-uploadContainer']}>
              <Button text="Upload" onClick={onImageUpload} />
              <div className={styles['StudioProfile-filename']}>
                <p>{showCurrentBanner}</p>
                {showBannerDelete() && (
                  <a
                    className="fas fa-trash-alt"
                    onClick={handleBannerDelete}
                  />
                )}
              </div>
            </div>
          )}
        </ImageUploading>
        <div className="d-fr mt-8">
          <Button className="mr-8" text="Cancel" onClick={handleHide} danger />
          <Button
            text="Update"
            onClick={() => {
              updateAxios(studioId, spudUserId);
              handleHide();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SettingsEditModal;
