import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';
import { toast } from 'react-toastify';
import styles from './PaymentOptions.module.scss';
import { toCurrencyAmount } from '~utils';
import { toastOptions } from '~constants/toasts';

const StripeButton = props => {
  const {
    settings,
    loadingCallback,
    className,
    isBooking,
    availabilityId,
    bookingCalendarId,
  } = props;
  const { amountDue, invoice, client } = settings;
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [ref, setRef] = useState(null);
  let error = false;
  const elementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  const handleSubmit = async event => {
    event.preventDefault();
    loadingCallback({ loading: true, reload: !isBooking });
    setIsProcessing(true);

    if (!stripe || !elements) {
      loadingCallback({ loading: false, reload: !isBooking });
      return;
    }

    const { secret } = await axios
      .get(`/customer/invoices/${invoice.uuid}/initialize_stripe_payment`)
      .then(res => res.data)
      .catch(error => {
        toast.error("We've encountered an error with your payment.", {
          ...toastOptions,
          onClose: () => {
            ref.clear();
            loadingCallback({ loading: false, reload: !isBooking });
            setIsProcessing(false);
          },
        });
        Rollbar.error(`Error initializing Stripe payment: ${error}`);
      });

    const result = await stripe.confirmCardPayment(secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: `${client.full_name}`,
        },
      },
    });

    if (result.error) {
      toast.error(result.error.message, {
        ...toastOptions,
        onClose: async () => {
          ref.clear();
          loadingCallback({ loading: false, reload: !isBooking });
          setIsProcessing(false);
        },
      });
      Rollbar.error(
        `Error confirming card payment with Stripe: ${result.error}`
      );
    } else {
      const res = await axios
        .post(`/customer/invoices/${invoice.uuid}/record_stripe_payment`, {
          isBooking: isBooking,
          availability_id: availabilityId,
          booking_calendar_id: bookingCalendarId,
          client_id: client.id,
          payment_intent_id: result.paymentIntent.id,
        })
        .catch(error => {
          toast.error("We've encountered an error with your payment.", {
            ...toastOptions,
            onClose: () => {
              ref.clear();
              loadingCallback({ loading: false, reload: !isBooking });
              setIsProcessing(false);
            },
          });
          Rollbar.error(
            `Error recording stripe payment Stripe payment: ${error}`
          );
        });

      toast.success('Successfully created your payment!', {
        ...toastOptions,
        onClose: () => {
          ref.clear();
          loadingCallback({
            loading: false,
            reload: !isBooking,
            success: true,
            bookingLogicCompleted: isBooking,
            calendarEvent: isBooking ? res.data : false,
          });
          setIsProcessing(false);
        },
      });
    }
  };

  return (
    <div className={className}>
      <form onSubmit={handleSubmit}>
        <label>Please enter your credit card details below.</label>
        <CardElement
          onReady={e => setRef(e)}
          onChange={e => setIsEmpty(e.empty)}
          options={elementOptions}
        />
        <button
          type="submit"
          disabled={!stripe || isProcessing || isEmpty}
          className={styles.StripeButton}
        >
          {`Pay ${toCurrencyAmount(amountDue)}`}
        </button>
      </form>
    </div>
  );
};

StripeButton.propTypes = {
  settings: PropTypes.object.isRequired,
  loadingCallback: PropTypes.func.isRequired,
  className: PropTypes.string,
  isBooking: PropTypes.bool,
};

StripeButton.defaultProps = {
  className: null,
  isBooking: false,
};

export default StripeButton;
