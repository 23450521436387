import React, { useState } from 'react';
import { useEffect } from 'react';
import { Button, Modal, Dropdown } from '~ui';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import ConfirmRemove from '../ConfirmRemove/ConfirmRemove';
import styles from './ClientGroupModal.module.scss';
import { Icon } from '~ui';
import axios from 'axios';

const ClientGroupModal = props => {
  const [openModal, setOpenModal] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [clientGroups, setClientGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(undefined);
  const [disabled, setDisabled] = useState(true);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getGroups = async () => {
    setIsLoading(true);
    const res = await axios.get('/api/groups', {
      params: { grab_all_groups: true },
    });
    const rawGroupList = res.data.data.map(group => {
      return { label: group.attributes.name, value: group.attributes.id };
    });
    rawGroupList.unshift({ label: 'Select group', value: '' });

    const clientsGroups = await axios.get(
      `/api/group_clients/grab_client_groups?client_id=${props.client_id}`
    );

    const clientGroupList = clientsGroups.data.map(group => {
      return group.name;
    });

    if (clientGroupList.length > 0) setClientGroups(clientGroupList);
    if (clientGroupList.length === 0) setClientGroups([]);
    setSelectedGroup(undefined);
    setGroupList(rawGroupList);
    setIsLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      await axios.put(`/api/groups/${selectedGroup.value}`, {
        id: selectedGroup.value,
        group: {
          group_clients_attributes: [{ client_id: props.client_id }],
        },
      });
      setOpenModal(false);
      setSelectedGroup(undefined);
      setLoading(false);
      toast.success('Saved!', toastOptions);
    } catch (error) {
      setOpenModal(false);
      setSelectedGroup(undefined);
      setLoading(false);
      toast.error('There was an error saving!', toastOptions);
    }
  };

  const checkClientGroups = () => {
    if (selectedGroup === undefined) {
      setDisabled(true);
      return;
    }

    let disabled = true;
    clientGroups.forEach(group => {
      if (group === selectedGroup.label) {
        disabled = false;
        return;
      }
    });
    setDisabled(disabled);
  };

  const grabSelectedGroup = groupId => {
    groupList.map(group => {
      if (group.value == groupId) {
        setSelectedGroup(group);
      }
    });
  };

  useEffect(() => {
    checkClientGroups();
  }, [selectedGroup]);

  return (
    <>
      <div className={styles.BannerButton}>
        <Button
          text="Client Groups"
          onClick={() => {
            setOpenModal(true);
            getGroups();
          }}
        />
      </div>
      {openModal && (
        <Modal
          title="Edit Client's Groups"
          handleHide={() => {
            setOpenModal(false);
            setSelectedGroup(undefined);
          }}
        >
          {isLoading ? (
            <h1>Loading...</h1>
          ) : (
            <div className={styles.ClientGroupModal}>
              {clientGroups.length > 0 && (
                <div>
                  This client belongs to the following group(s):
                  <br />
                  <ul>
                    {clientGroups.map((group, index) => (
                      <li key={index}>{group}</li>
                    ))}
                  </ul>
                  <br />
                  <br />
                  Select another option and hit save to add the client to a new
                  group.
                </div>
              )}
              {clientGroups.length === 0 && (
                <div>
                  This client is not associated with any groups. Select an
                  option and hit save to add the client to a group.
                </div>
              )}
              <div className={styles['ClientGroupModal--dropdown']}>
                <Dropdown
                  name="Groups"
                  initialValue={''}
                  onChangeCallback={e => {
                    grabSelectedGroup(e);
                  }}
                  options={groupList}
                />
              </div>
              <div className={styles['ClientGroupModal--buttonGroup']}>
                <Button
                  danger
                  disabled={disabled}
                  onClick={() => {
                    setOpenConfirmationModal(true);
                  }}
                  text="Remove"
                />
                <Button
                  disabled={
                    !disabled === false && selectedGroup !== undefined
                      ? false
                      : true
                  }
                  onClick={() => {
                    handleSubmit();
                  }}
                  text="Save"
                />
              </div>
              {openConfirmationModal && (
                <ConfirmRemove
                  handleHide={setOpenConfirmationModal}
                  setOpenModal={setOpenModal}
                  setSelectedGroup={setSelectedGroup}
                  selectedGroup={selectedGroup}
                  client_id={props.client_id}
                  setLoading={setLoading}
                />
              )}
            </div>
          )}
          {loading && (
            <div className={styles.loadingOverlay}>
              <div className={styles.loadingText}>
                <p>Loading...</p>
                <Icon name="spinner" className="fa-pulse" large />
              </div>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default ClientGroupModal;
