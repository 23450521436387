import React from 'react';
import { Modal, Button } from '~ui';
import StepForm from './StepForm';

const AddStepModal = ({
  handleHide,
  allBeforeSessionSteps,
  afterSessionSteps,
  initialNewItemFormState,
}) => {
  return (
    <Modal
      title="Add a Step"
      handleHide={handleHide}
      allBeforeSessionSteps={allBeforeSessionSteps}
      afterSessionSteps={afterSessionSteps}
      initialNewItemFormState={initialNewItemFormState}
    >
      <StepForm
        beforeSessionSteps={allBeforeSessionSteps}
        afterSessionSteps={afterSessionSteps}
        initialFormState={initialNewItemFormState}
        handleClose={handleHide}
      />
    </Modal>
  );
};

export default AddStepModal;
