/**
 * This format is used in the Grouped Calendar Events API to index each day's
 * events in the interval.
 *
 * e.g. { "2019-12-12": { "00": [CalendarEvent, CalendarEvent, ..., CalendarEvent], etc. } }
 */
export const DATE_KEY_FORMAT = 'YYYY-MM-DD';

/**
 * This is one of the formats for non-all-day events. It does not
 * include the year.
 *
 * e.g. Oct 31 3:30pm
 */
export const DATE_TIME_FORMAT = 'MMM D h:mma';

export const DATE_TIME_FORMAT_WITH_ZONE = 'MMM D h:mma (z)';

/**
 * This is the full date time format with the year. It is our default format
 * for displaying non-all-day events.
 *
 * e.g. Oct 31, 2019 3:30pm
 */
export const FULL_DATE_TIME_FORMAT = 'MMM D, YYYY h:mma';

export const FULL_DATE_TIME_FORMAT_WITH_ZONE = 'MMM D, YYYY h:mma (z)';

/**
 * This is one of the formats for all-day events. It does not include the year.
 *
 * e.g. Oct 31
 */
export const DATE_FORMAT = 'MMM D';

/**
 * This is the format used for timelines. We want the
 * two digits for all the days of the month so the boxes
 * can be completely aligned.
 *
 * e.g. Oct 01
 */
export const DATE_FORMAT_FULL_DAY = 'MMM DD';

/**
 * This is the format to use when you're sending dates in query strings to our API.
 *
 * e.g. 2019-10-31
 */
export const API_DATE_FORMAT = 'YYYY-MM-DD';

/**
 * This is the default format for all-day events.
 *
 * e.g. Oct 31, 2019
 */
export const FULL_DATE_FORMAT = 'MMM D, YYYY';

/**
 * This is used to render the day of the week, month, and day.
 *
 * e.g. Thursday Oct 31
 */
export const WEEKDAY_DATE_FORMAT = 'dddd MMM D';

/**
 * This format is used to render the full month name and full year.
 *
 * e.g. October 2019
 */
export const MONTH_YEAR_FORMAT = 'MMMM YYYY';

/**
 * This format is used to render the time with meridiem.
 *
 * e.g. 10:00am
 */

export const TIME_FORMAT = 'h:mma';

/**
 * Generates all the times between midnight and midnight where `step` is the interval
 * between each time.
 * @param {Number} step - The interval in minutes between elements in the series.
 * @return {Array<String>} Array of datetimes in the HH:MM A format
 */
export const generateSeries = step => {
  let datetime = new Date(1970, 0, 1, 0, 0, 0, 0);
  let result = [];

  while (datetime.getDate() == 1) {
    datetime.setMinutes(datetime.getMinutes() + step);
    let hour = datetime.getHours();
    const meridiem = hour >= 12 ? 'PM' : 'AM';
    let minutes = datetime.getMinutes();
    hour = hour % 12 ? hour % 12 : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    result.push(`${hour}:${minutes} ${meridiem}`);
  }

  return result;
};
