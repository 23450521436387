export const ITEM_HEADERS = [
  'Item',
  'Description',
  'Quantity',
  'Price',
  'Taxable?',
  'Total',
];

export const PRODUCT_HEADERS = hasQuickbooks => {
  if (hasQuickbooks) {
    return ['Name', 'Description', 'Price', 'Taxable?', 'QuickBooks'];
  }

  return ['Name', 'Description', 'Price', 'Taxable?'];
};

export const SCHEDULE_HEADERS = ['Installment', 'Due Date', 'Amount', 'Status'];
export const PAYMENT_HEADERS = [
  'Amount',
  'Payment Date',
  'Payment Method',
  'Receipt?',
];
export const SCHEDULE_TEMPLATE_HEADERS = ['Installment', 'Due Date', 'Amount'];

export const PAYMENT_PROCESSORS = ['square', 'paypal', 'stripe'];

export const PAYMENT_METHOD_OPTIONS = [
  {
    label: 'Unspecified',
    value: null,
  },
  {
    label: 'Credit Card',
    value: 'credit_card',
  },
  {
    label: 'Cash',
    value: 'cash',
  },
  {
    label: 'Check',
    value: 'check',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const ALL_PAYMENT_METHOD_OPTIONS = [
  {
    label: 'Unspecified',
    value: null,
  },
  {
    label: 'Credit Card',
    value: 'credit_card',
  },
  {
    label: 'Cash',
    value: 'cash',
  },
  {
    label: 'Square',
    value: 'square',
  },
  {
    label: 'Paypal',
    value: 'paypal',
  },
  {
    label: 'Stripe',
    value: 'stripe',
  },
  {
    label: 'Check',
    value: 'check',
  },
  {
    label: 'Other',
    value: 'other',
  },
];
