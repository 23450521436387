import axios from 'axios';
import { routes } from '~constants/routes';
import { PAYMENT_SCHEDULE_INSTALLMENTS } from '~constants/actionTypes';

export const createPaymentScheduleInstallment = params => async dispatch => {
  try {
    dispatch({ type: PAYMENT_SCHEDULE_INSTALLMENTS.CREATE.REQUEST });

    const installment = await axios
      .post(routes.PAYMENT_SCHEDULE_INSTALLMENTS.CREATE, params)
      .then(res => res.data.data.attributes);

    dispatch({
      type: PAYMENT_SCHEDULE_INSTALLMENTS.CREATE.SUCCESS,
      payload: installment,
    });
  } catch (error) {
    dispatch({
      type: PAYMENT_SCHEDULE_INSTALLMENTS.CREATE.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};

export const updatePaymentScheduleInstallment = (
  installmentId,
  params
) => async dispatch => {
  try {
    dispatch({ type: PAYMENT_SCHEDULE_INSTALLMENTS.UPDATE.REQUEST });

    const installment = await axios
      .put(routes.PAYMENT_SCHEDULE_INSTALLMENTS.UPDATE(installmentId), params)
      .then(res => res.data.data.attributes);

    dispatch({
      type: PAYMENT_SCHEDULE_INSTALLMENTS.UPDATE.SUCCESS,
      payload: installment,
    });
  } catch (error) {
    dispatch({
      type: PAYMENT_SCHEDULE_INSTALLMENTS.UPDATE.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};

export const deletePaymentScheduleInstallment = (
  installmentId,
  params
) => async dispatch => {
  try {
    dispatch({ type: PAYMENT_SCHEDULE_INSTALLMENTS.DELETE.REQUEST });

    const res = await axios.delete(
      routes.PAYMENT_SCHEDULE_INSTALLMENTS.DELETE(installmentId),
      { data: params }
    );

    dispatch({
      type: PAYMENT_SCHEDULE_INSTALLMENTS.DELETE.SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: PAYMENT_SCHEDULE_INSTALLMENTS.DELETE.FAILURE,
      payload: error,
      error: true,
    });
  }
};
