import React from 'react'
import {combineContexts} from 'react-combine-contexts'

import ApiCacheContext from './api_cache_context'

const GlobalContext = combineContexts({
  apiCacheContext: ApiCacheContext,
})

const withProvider = Provider => WrappedComponent => props => (
  <Provider>
    <WrappedComponent {...props} />
  </Provider>
)

export const withGlobalContext = withProvider(GlobalContext.Provider)
export default GlobalContext
