import axios from 'axios';
import { routes } from '~constants/routes';
import { JOURNEY_TEMPLATES } from '~constants/actionTypes';

export const getJourneyTemplates = (params = {}) => async (dispatch, getState) => {
  try {
    dispatch({ type: JOURNEY_TEMPLATES.INDEX.REQUEST})

    const res = await axios
      .get(routes.JOURNEY_TEMPLATES.INDEX, { params })
      .then(res => res)
    dispatch({
      type: JOURNEY_TEMPLATES.INDEX.SUCCESS,
      payload: res.data.data,
    })
  } catch (error) {
    dispatch({
      type: JOURNEY_TEMPLATES.INDEX.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    })
  }
}

export const deleteJourneyTemplate = id => async dispatch => {
  try {
    dispatch({ type: JOURNEY_TEMPLATES.DELETE.REQUEST });
    const deletedJourneyTemplateId = await axios
      .delete(routes.JOURNEY_TEMPLATES.DELETE(id))
      .then(res => {
        return res.data.id
      });

    dispatch({ type: JOURNEY_TEMPLATES.DELETE.SUCCESS, payload: deletedJourneyTemplateId });
  } catch (error) {
    dispatch({
      type: JOURNEY_TEMPLATES.DELETE.FAILURE,
      payload: error,
      error: true,
    });
  }
};

export const createJourneyTemplate = name  => async(dispatch, getState) => {
  const currentStudioId = getState().journeyTemplates.studioId
  try {
    dispatch({ type: JOURNEY_TEMPLATES.CREATE.REQUEST });
    const res =
      await axios.post(routes.JOURNEY_TEMPLATES.CREATE, {
        journey_template: {
          name: name,
          studio_id: currentStudioId
        }
      });

    dispatch({ type: JOURNEY_TEMPLATES.CREATE.SUCCESS, payload: res.data.data.attributes })
  } catch (error) {
    dispatch({
      type: JOURNEY_TEMPLATES.CREATE.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    })
  }
}
