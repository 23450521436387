import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Col, Row, Button } from 'react-bootstrap';
import classNames from 'classnames';
import TinymceEditor from '../tinymce_editor/tinymce_editor_container';
import Uploader from '../../uploader/uploader';
import BackendSelect from '../../common/backend_select/backend_select';

import styles from './email_template_creator.module.scss';

class EmailTemplateCreator extends React.PureComponent {
  static propTypes = {
    currentEmailTemplate: PropTypes.object.isRequired,
    updateEmailTemplate: PropTypes.func.isRequired,
    cancelEdit: PropTypes.func.isRequired,
    addAttachment: PropTypes.func.isRequired,
    addDocument: PropTypes.func.isRequired,
    addContract: PropTypes.func.isRequired,
    addQuestionnaire: PropTypes.func.isRequired,
    addInvoice: PropTypes.func.isRequired,
    saveEmail: PropTypes.func.isRequired,
    setSelectedContract: PropTypes.func.isRequired,
    setSelectedQuestionnaire: PropTypes.func.isRequired,
    setSelectedInvoice: PropTypes.func.isRequired,
    selectedInvoice: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
      .isRequired,
    selectedContract: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
      .isRequired,
    selectedQuestionnaire: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]).isRequired,
    removeAttachment: PropTypes.func.isRequired,
    reloadAttachments: PropTypes.func.isRequired,
    selectedDocument: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
      .isRequired,
    setSelectedDocument: PropTypes.func.isRequired,
    bookingUrls: PropTypes.array,
    locations: PropTypes.array,
  };

  renderFields = () => {
    const { currentEmailTemplate, updateEmailTemplate } = this.props;
    const fields = [
      { name: 'Email Template Name', field: 'name' },
      { name: 'Email Subject', field: 'email_subject' },
    ];

    return fields.map(f => (
      <div key={f.field} className="form-group">
        <Row>
          <Col xs={4}>
            {' '}
            <label className={'control-label'} htmlFor={f.field}>
              {f.name}
            </label>{' '}
          </Col>
          <Col xs={8}>
            <input
              className={classNames('form-control', styles.emailHeaderInputBox)}
              name={f.field}
              value={currentEmailTemplate[f.field] || ''}
              onChange={e => updateEmailTemplate({ [f.field]: e.target.value })}
            />
          </Col>
        </Row>
      </div>
    ));
  };

  attachmentConfigs = {
    contracts: {
      apiName: 'contract_templates',
      id: 'contract_template_id',
      item_type: 'ContractTemplate',
      title: 'Contracts',
      placeholder: 'Select Contract',
      getItemName: item => item.attributes.name,
      selected: () => this.props.selectedContract,
      setSelected: this.props.setSelectedContract,
      add: this.props.addContract,
    },
    documents: {
      apiName: 'documents',
      id: 'document_id',
      item_type: 'Document',
      title: 'Attachments',
      placeholder: 'Select Attachment',
      getItemName: item => item.attributes.file_file_name,
      selected: () => this.props.selectedDocument,
      setSelected: this.props.setSelectedDocument,
      add: this.props.addDocument,
    },
    invoices: {
      apiName: 'invoice_templates',
      id: 'invoice_template_id',
      item_type: 'InvoiceTemplate',
      title: 'Invoices',
      placeholder: 'Select Invoice',
      selected: () => this.props.selectedInvoice,
      setSelected: this.props.setSelectedInvoice,
      add: this.props.addInvoice,
    },
    questionnaires: {
      apiName: 'web_forms',
      id: 'web_form_id',
      item_type: 'WebForm',
      title: 'Questionnaires',
      placeholder: 'Select Questionnaire',
      selected: () => this.props.selectedQuestionnaire,
      setSelected: this.props.setSelectedQuestionnaire,
      add: this.props.addQuestionnaire,
    },
  };

  renderAttachmentSection = name => {
    const { removeAttachment } = this.props;
    const config = this.attachmentConfigs[name];
    const { email_attachments } = this.props.currentEmailTemplate;

    const filtered = (email_attachments || []).filter(
      att => att.item_type === config.item_type && !att._destroy
    );
    const selectedItem = config.selected();
    return (
      <div>
        <h1>{config.title}</h1>
        <div>
          <BackendSelect
            apiName={config.apiName}
            name={config.id}
            className={styles.attachmentSelect}
            returnObject
            getItemName={config.getItemName}
            onChange={config.setSelected}
            value={selectedItem && selectedItem.id}
            placeholder={config.placeholder}
          />
        </div>
        <div>
          <Button
            bsClass={classNames(
              'btn btn-default btn-sm',
              styles.addAttachmentButton
            )}
            onClick={config.add}
          >
            Add
          </Button>
        </div>
        <ul className={styles.attachmentList}>
          {filtered.map((att, i) => (
            <li key={i}>
              <span>{(att.name || att.item_name || '').substring(0, 20)}</span>
              <i
                className={`styles.removeAttachmentButton fa fa-times`}
                onClick={() => removeAttachment(att)}
              />
            </li>
          ))}
        </ul>
      </div>
    );
  };

  render() {
    const {
      currentEmailTemplate,
      updateEmailTemplate,
      addAttachment,
      cancelEdit,
      reloadAttachments,
      bookingUrls,
      locations,
    } = this.props;

    const hasContractSignature = window.app.studio.hasContractSignature;

    return (
      <Grid fluid>
        <Row>
          <Col xs={10}>{this.renderFields()}</Col>
          <Col xs={2}>
            <Button
              className={styles.emailCancelEdit}
              onClick={cancelEdit}
              bsSize="small"
              bsStyle="danger"
            >
              Cancel
              <i className="fa fa-times" style={{ marginLeft: '4px' }} />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <form className={styles.emailTinyMCE}>
              <TinymceEditor
                value={currentEmailTemplate.email_body || ''}
                onChange={value => updateEmailTemplate({ email_body: value })}
                bookingUrls={bookingUrls}
                locations={locations}
              />
            </form>
          </Col>
        </Row>
        <div className={styles.attachmentsFromIris}>
          <Row className={styles.attachmentsGridRow}>
            <Col xs={6} className={styles.attachmentsFromIrisBlocks}>
              {' '}
              {this.renderAttachmentSection('documents')}{' '}
            </Col>
            <Col xs={6} className={styles.attachmentsFromIrisBlocks}>
              {hasContractSignature ? (
                this.renderAttachmentSection('contracts')
              ) : (
                <div>
                  <h1>Contracts</h1>
                  <a
                    href={`/studio/signatures`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Input your signature to attach a contract
                  </a>
                </div>
              )}
            </Col>
          </Row>
          <Row className={styles.attachmentsGridRow}>
            <Col xs={6} className={styles.attachmentsFromIrisBlocks}>
              {' '}
              {this.renderAttachmentSection('questionnaires')}{' '}
            </Col>
            <Col xs={6} className={styles.attachmentsFromIrisBlocks}>
              {' '}
              {this.renderAttachmentSection('invoices')}{' '}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Uploader
                addAttachment={addAttachment}
                reloadAttachments={reloadAttachments}
              />
            </Col>
          </Row>
        </div>
        <Button onClick={this.props.saveEmail}>Save</Button>
      </Grid>
    );
  }
}

export default EmailTemplateCreator;
