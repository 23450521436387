import { CALENDAR_EVENTS, CALENDAR } from '~constants/actionTypes';

export default function (state = {}, action) {
  switch (action.type) {
    case CALENDAR_EVENTS.LIST.SUCCESS:
      return { ...state, events: action.payload };
    case CALENDAR_EVENTS.GET.SUCCESS:
      return { ...state, clientEvents: action.payload };
    case CALENDAR_EVENTS.DELETE.SUCCESS:
      let clientEvents = state.clientEvents.data.filter(clientEvent => {
        return parseInt(clientEvent.id) !== action.payload.id;
      });
      return { ...state, clientEvents: { data: clientEvents } };
    case CALENDAR.UPDATE_SELECTED_DAY:
      return { ...state, selectedDay: action.payload };
    case CALENDAR.UPDATE_INTERVAL:
      return { ...state, interval: action.payload };
    case CALENDAR.UPDATE_SHOW_EDIT_MODAL:
      return { ...state, showEditModal: action.payload };
    case CALENDAR.UPDATE_SHOW_DELETE_MODAL:
      return { ...state, showDeleteModal: action.payload };
    case CALENDAR.UPDATE_ACTIVE_EVENT:
      return { ...state, activeEvent: action.payload };
    case CALENDAR.UPDATE_SUN:
      return { ...state, sun: action.payload };
    case CALENDAR.UPDATE_DST:
      return { ...state, dstDates: action.payload };
    case CALENDAR_EVENTS.GET_SESSION_TYPE_COUNT.SUCCESS:
      return { ...state, sessionTypeCount: action.payload };
    default:
      return state;
  }
}
