import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './Icon.module.scss';

const Icon = ({
  name,
  className,
  onClickCallback,
  small,
  large,
  ariaLabel,
  outlined,
}) => {
  const hasClassName = !!className;
  const cx = classNames.bind(styles);
  const classes = cx('Icon', {
    'Icon--small': small,
    'Icon--large': large,
    [className]: hasClassName,
  });
  const getIconClass = () => (outlined ? `far fa-${name}` : `fa fa-${name}`);

  return onClickCallback ? (
    <button
      type="button"
      className={`${styles.IconButton} ${classes}`}
      onClick={onClickCallback}
      aria-label={ariaLabel}
    >
      <i className={getIconClass()} />
    </button>
  ) : (
    <i className={`${getIconClass()} ${classes}`} />
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClickCallback: PropTypes.func,
  small: PropTypes.bool,
  large: PropTypes.bool,
  ariaLabel: PropTypes.string,
  outlined: PropTypes.bool,
};

Icon.defaultProps = {
  className: null,
  onClickCallback: undefined,
  small: false,
  large: false,
  ariaLabel: undefined,
  outlined: false,
};

export default Icon;
