import axios from 'axios';
import { routes } from '~constants/routes';
import { TODOS } from '~constants/actionTypes';

export const getTodos = params => async dispatch => {
  try {
    dispatch({ type: TODOS.GET.REQUEST });

    const { items, meta } = await axios
      .get(routes.TODOS.GET, {
        params,
      })
      .then(res => ({
        items: res.data.data.map(todo => todo.attributes),
        meta: res.data.meta,
      }));

    dispatch({
      type: TODOS.GET.SUCCESS,
      payload: { items, meta },
    });
  } catch (error) {
    dispatch({
      type: TODOS.GET.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};

export const createTodo = params => async dispatch => {
  try {
    dispatch({ type: TODOS.CREATE.REQUEST });

    const newTodo = await axios
      .post(routes.TODOS.CREATE, params)
      .then(res => res.data.data.attributes);

    dispatch({ type: TODOS.CREATE.SUCCESS, payload: newTodo });
  } catch (error) {
    dispatch({
      type: TODOS.CREATE.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};

export const updateTodo = (todoId, params) => async dispatch => {
  try {
    dispatch({ type: TODOS.UPDATE.REQUEST });

    const updatedTodo = await axios
      .put(routes.TODOS.UPDATE(todoId), params)
      .then(res => res.data.data.attributes);

    dispatch({ type: TODOS.UPDATE.SUCCESS, payload: updatedTodo });
  } catch (error) {
    dispatch({
      type: TODOS.UPDATE.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};

export const completeTodo = todoId => async (dispatch, getState) => {
  try {
    dispatch({ type: TODOS.COMPLETE.REQUEST });

    const completedTodo = await axios
      .put(routes.TODOS.COMPLETE(todoId))
      .then(res => res.data.data.attributes);

    dispatch({ type: TODOS.COMPLETE.SUCCESS, payload: completedTodo });
  } catch (error) {
    dispatch({
      type: TODOS.COMPLETE.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};

export const uncompleteTodo = todoId => async (dispatch, getState) => {
  try {
    dispatch({ type: TODOS.UNCOMPLETE.REQUEST });

    const uncompletedTodo = await axios
      .put(routes.TODOS.UNCOMPLETE(todoId))
      .then(res => res.data.data.attributes);

    dispatch({ type: TODOS.UNCOMPLETE.SUCCESS, payload: uncompletedTodo });
  } catch (error) {
    dispatch({
      type: TODOS.UNCOMPLETE.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};

export const deleteTodo = todoId => async dispatch => {
  try {
    dispatch({ type: TODOS.DELETE.REQUEST });
    const deletedTodo = await axios
      .delete(routes.TODOS.DELETE(todoId))
      .then(res => res.data.data.attributes);

    dispatch({ type: TODOS.DELETE.SUCCESS, payload: deletedTodo });
  } catch (error) {
    dispatch({
      type: TODOS.DELETE.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};
