import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { DataTable, DeleteConfirmation, Button, Icon, Toggle } from '~ui';
import ToggleContent from '~components/common/ToggleContent';
import TogglePopover from '~components/common/TogglePopover';
import { absolutePath, routes } from '~constants/routes';
import styles from './BookingSetup.module.scss';
import BookingCalendarCreateModal from './BookingCalendarCreateModal';
import BookingPageSettings from './BookingPageSettings/BookingPageSettings';
import BookingPageShare from './BookingPageShare/BookingPageShare';

const BookingSetup = ({
  initialBookingCalendars,
  hasCronofyCalendar,
  addExternalEvents,
  reinstateAvailabilities,
  studio_id,
  bookingData,
}) => {
  const [bookingCalendars, setBookingCalendars] = useState(
    initialBookingCalendars
  );
  const [isCopiedId, setIsCopiedId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const headers = [
    { name: 'title', labelText: 'Title', type: 'function', sortable: false },
    { name: 'notes', labelText: 'Notes', type: 'string', sortable: false },
    { name: 'buttonGroup', labelText: '', type: 'function', sortable: false },
  ];

  const renderTitle = calendar => (
    <a
      className={styles['BookingSetup-calendarTitle']}
      href={`/studio/booking_calendars/${calendar.id}`}
    >
      {calendar.title}
    </a>
  );

  const handleCreate = title => {
    if (!title) {
      toast.info('Please provide a Calendar Name');
      return;
    } else {
      const params = {
        booking_calendar: {
          title: title,
          studio_id: studio_id,
        },
      };
      axios.post(routes.BOOKING_CALENDARS.CREATE, params).then(res => {
        const bookingCalendarId = res.data.data.attributes.id;
        window.location.href = absolutePath(
          `/studio/booking_calendars/${bookingCalendarId}`
        );
      });
    }
  };

  const handleDuplicate = async bookingCalendar => {
    setIsLoading(true);
    axios
      .post(routes.BOOKING_CALENDARS.DUPLICATE(bookingCalendar.id))
      .then(res => {
        const bookingCalendarId = res.data.data.attributes.id;
        setIsLoading(false);
        window.location.href = absolutePath(
          `/studio/booking_calendars/${bookingCalendarId}`
        );
      });
  };

  const renderButtons = calendar => {
    return (
      <div className={styles['BookingSetup-buttons']}>
        <a onClick={() => handleDuplicate(calendar)}>Duplicate</a>
        <a
          className="far fa-calendar-plus"
          title="Add Availability"
          href={absolutePath(
            `/studio/booking_calendars/${calendar.id}/calendar`
          )}
        />
        <a
          className="fas fa-eye "
          title="Preview"
          target="_blank"
          href={absolutePath(`/customer/booking/${calendar.uuid}`)}
        />
        <a
          href="#"
          className={`fa ${
            isCopiedId == calendar.uuid ? 'fa-check' : 'fa-copy'
          }`}
          title="Copy URL"
          onClick={e => {
            e.preventDefault();
            navigator.clipboard.writeText(
              absolutePath(`/customer/booking/${calendar.uuid}`)
            );
            setIsCopiedId(calendar.uuid);
            toast.success('Booking link copied to clipboard!', toastOptions);
          }}
          onMouseOut={() => setIsCopiedId(undefined)}
        />
        <ToggleContent
          toggle={handleShow => (
            <a
              className="fas fa-trash-alt"
              title="Delete"
              onClick={handleShow}
            />
          )}
          content={handleHide => (
            <DeleteConfirmation
              handleHide={handleHide}
              handleDelete={() => handleDeleteBookingCalendar(calendar.id)}
              name={calendar.title}
              resource="Booking Calendar"
            />
          )}
        />
      </div>
    );
  };

  const handleDeleteBookingCalendar = calendarId => {
    axios.delete(routes.BOOKING_CALENDARS.DELETE(calendarId)).then(res => {
      setBookingCalendars(
        bookingCalendars.filter(calendar => calendar.id !== res.data.id)
      );
    });
    setIsLoading(true);
    window.location.reload();
  };

  return (
    <div className={styles.BookingSetup}>
      {isLoading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingText}>
            <p>Loading...</p>
            <Icon name="spinner" className="fa-pulse" large />
          </div>
        </div>
      )}
      <h1 className={styles['BookingSetup-header']}>Booking Setup</h1>
      <p>
        Create a publishable calendar of available session times to share with
        your clients. Create various calendars with your availability to share
        with your clients and leads, each with its own unique URL. Optional
        items include requiring an invoice and/or contract, initiating a
        workflow upon booking, and a required lead time in order to book.
      </p>

      <div className={styles['BookingSetup-actions']}>
        <ToggleContent
          toggle={handleShow => (
            <Button text="Create Booking Calendar" onClick={handleShow} />
          )}
          content={handleHide => (
            <BookingCalendarCreateModal
              handleHide={handleHide}
              handleCreate={handleCreate}
            />
          )}
        />
        <a
          className={styles['BookingSetup-linkExternal']}
          href={absolutePath('/calendar/connect')}
          target="_blank"
        >
          <Icon name="calendar" />{' '}
          {hasCronofyCalendar
            ? 'Edit calendar integration'
            : 'Link external calendar'}
        </a>
        <TogglePopover
          popoverWidth={'645px'}
          activator={
            <button className={styles['BookingSetup-actionButton']}>
              <Icon name="ellipsis-v" large />
            </button>
          }
          popoverContent={
            <div className={styles['BookingSetup-settings']}>
              {hasCronofyCalendar && (
                <div
                  className={styles['BookingSetup-settings-toggleContainer']}
                >
                  <label>
                    Consider external events when viewing your availability?
                  </label>
                  <Toggle
                    initialValue={addExternalEvents}
                    onChangeCallback={val => {
                      axios.put(routes.STUDIOS.UPDATE(studio_id), {
                        studio: {
                          opt_in_for_cronofy_availability: val,
                        },
                      });
                    }}
                  />
                </div>
              )}
              <div className={styles['BookingSetup-settings-toggleContainer']}>
                <label>
                  Automatically reinstate availabilities if the originally
                  booked session is deleted?
                </label>
                <Toggle
                  initialValue={reinstateAvailabilities}
                  onChangeCallback={enabled => {
                    const params = {
                      feature_flag: {
                        studio_id: studio_id,
                        feature: 'reinstate_booking_availability',
                      },
                    };
                    if (enabled) {
                      axios.post(routes.FEATURE_FLAGS.POST, params);
                    } else {
                      axios.delete(routes.FEATURE_FLAGS.DELETE, {
                        data: params,
                      });
                    }
                  }}
                />
              </div>
            </div>
          }
        />
      </div>

      <DataTable
        columnTypes={['string']}
        headers={headers}
        rows={bookingCalendars.map(calendar => ({
          title: renderTitle(calendar),
          notes: calendar.notes,
          buttonGroup: renderButtons(calendar),
        }))}
      />
      <BookingPageSettings
        bookingData={bookingData}
        studio_id={studio_id}
        initialBookingCalendars={initialBookingCalendars}
      />
      <BookingPageShare studio_id={studio_id} />
    </div>
  );
};

export default BookingSetup;
