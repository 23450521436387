import React, { useState, useEffect } from 'react';
import styles from './ClientActivity.module.scss';
import Item from './Item/Item';
import { routes } from '~constants/routes';
import axios from 'axios';
import { uniqueId } from '~utils';
import { Modal, Button } from '~ui';
import ToggleContent from '~components/common/ToggleContent';
import DateModal from './DateModal';
import RecentActivity from './RecentActivity/RecentActivity';

const ClientActivity = ({
  journeyTriggers,
  pageCount,
  activeTrial,
  studioId,
  trialVerified,
}) => {
  const [scheduledEmails, setScheduledEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const pathArray = window.location.href.split('/');
  const clientId = pathArray[pathArray.length - 2];

  useEffect(() => {
    getScheduledEmails();
  }, []);

  const getScheduledEmails = async () => {
    setIsLoading(true);
    await axios.get(routes.SCHEDULED_EMAILS.GET(clientId)).then(res => {
      setScheduledEmails(res.data);
      setIsLoading(false);
    });
  };

  const deleteScheduledEmail = async id => {
    await axios.delete(routes.SCHEDULED_EMAILS.DELETE(id)).then(res => {
      getScheduledEmails();
    });
  };

  return (
    <div className={styles['ClientActivity']}>
      <h1>Scheduled Emails</h1>
      {isLoading ? (
        <h2>Loading...</h2>
      ) : (
        <div className={styles['ClientActivity-container']}>
          {scheduledEmails.length === 0 ? (
            <div>This Client has no scheduled emails.</div>
          ) : (
            scheduledEmails.map((email, index) => (
              <Item
                key={uniqueId(`email-${email.id}_`)}
                index={index}
                icon="fa-envelope"
                description={email.email_template.name}
                datetime={email.scheduled_at}
              >
                <ToggleContent
                  toggle={handleShow => (
                    <i className="fa fa-pencil-alt" onClick={handleShow} />
                  )}
                  content={handleHide => (
                    <DateModal
                      {...{
                        email,
                        handleHide,
                        getScheduledEmails,
                        journeyTriggers,
                      }}
                    />
                  )}
                />
                <ToggleContent
                  toggle={handleShow => (
                    <i className="fa fa-trash-alt" onClick={handleShow} />
                  )}
                  content={handleHide => (
                    <Modal title="Are you sure?" handleHide={handleHide}>
                      <div>
                        This email will not be sent and will be permanently
                        deleted.
                      </div>
                      <div className={styles['ClientActivity-modalButtons']}>
                        <Button text="Cancel" onClick={handleHide} />
                        <Button
                          text="Delete"
                          danger
                          onClick={() => deleteScheduledEmail(email.id)}
                        />
                      </div>
                    </Modal>
                  )}
                />
              </Item>
            ))
          )}
        </div>
      )}
      <RecentActivity
        pageCount={pageCount}
        clientId={clientId}
        activeTrial={activeTrial}
        studioId={studioId}
        trialVerified={trialVerified}
      />
    </div>
  );
};

export default ClientActivity;
