import React, { useState, useEffect } from 'react';
import styles from './Todos.module.scss';
import { routes, absolutePath } from '~constants/routes';
import axios from 'axios';
import moment from 'moment-timezone';
import { API_DATE_FORMAT } from '~constants/datetime';
import { Checkbox, Pill, Icon } from '~ui';

const Todos = () => {
  const [todos, setTodos] = useState([]);
  const [todosLoading, setTodosLoading] = useState();

  const getTodos = async () => {
    try {
      setTodosLoading(true);
      const res = await axios.get(routes.TODOS.GET, {
        params: {
          limit: 6,
          due_date: {
            end: moment().add(6, 'days').format(API_DATE_FORMAT),
          },
        },
      });

      setTodos(res.data.data);
      setTodosLoading(false);
    } catch (error) {
      setTodosLoading(false);
      console.error(error);
    }
  };

  const changeTodoCompletion = async todoAttributes => {
    try {
      if (todoAttributes.is_completed) {
        await axios.put(routes.TODOS.UNCOMPLETE(todoAttributes.id));
      } else if (!todoAttributes.is_completed) {
        await axios.put(routes.TODOS.COMPLETE(todoAttributes.id));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTodos();
  }, []);

  const renderPill = attributes => {
    if (moment(attributes.due_date).isBefore(moment().startOf('day'))) {
      return <Pill text="Overdue" color="orange" small />;
    } else {
      return (
        <Pill
          text={moment(attributes.due_date).format('[Due] MMM Do')}
          color="green"
          small
        />
      );
    }
  };

  return (
    <div className={styles.Todos}>
      <h1>To-Do's</h1>
      <>
        {todosLoading && (
          <div className={styles['Todos-loading']}>
            <Icon name="spinner" className="fa-pulse" large />
          </div>
        )}
      </>
      {todos &&
        todos.map(({ attributes }, index) => (
          <div key={index} className={styles['Todos-todo']}>
            <Checkbox
              name={`todo-${index}`}
              onChangeCallback={() => changeTodoCompletion(attributes)}
            />
            <div className={styles['Todos-todo-info']}>
              <h3>{attributes.description}</h3>
              {attributes.client_name && (
                <p
                  onClick={() => {
                    window.location.href = absolutePath(
                      `/clients/${attributes.client_id}/to-dos`
                    );
                  }}
                >
                  {attributes.client_name}
                </p>
              )}
            </div>
            <div className={styles['Todos-todo-pill']}>
              {renderPill(attributes)}
            </div>
          </div>
        ))}
      {!todosLoading && !todos.length > 0 && (
        <div className={styles['Todos-noTodos']}>
          There are no To Dos in this list.
        </div>
      )}
      <div className={styles['Todos-link']}>
        <a href={absolutePath('/todos')}>
          View All <i className={'fas fa-arrow-right'}></i>
        </a>
      </div>
    </div>
  );
};

export default Todos;
