import React from 'react';
import PropTypes from 'prop-types';
import { withContext } from 'with-context';

import ApiCacheContext from '../../../contexts/api_cache_context';

@withContext(ApiCacheContext, 'apiCacheContext')
class BackendSelect extends React.PureComponent {
  static propTypes = {
    apiCacheContext: PropTypes.shape({
      fetchItems: PropTypes.func,
      fetchItem: PropTypes.func,
      apiCache: PropTypes.object,
    }),
    apiName: PropTypes.string.isRequired,
    className: PropTypes.string,
    returnObject: PropTypes.bool,
    onChange: PropTypes.func,
    onRetrieve: PropTypes.func,
    getItemName: PropTypes.func,
    placeholder: PropTypes.string,
    optionsProps: PropTypes.object,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectProps: PropTypes.object,
    params: PropTypes.object,
  };

  componentDidMount() {
    const { apiName, params } = this.props;
    const { fetchItems } = this.props.apiCacheContext;
    fetchItems(apiName, params);
  }

  getItems = () => {
    const { apiName, apiCacheContext } = this.props;
    return apiCacheContext.getItems(apiName);
  };

  onChange = e => {
    const { returnObject, onChange, onRetrieve } = this.props;
    const items = this.getItems();
    const id = parseInt(e.target.value, 10);

    if (returnObject) {
      const item = items.find(elem => elem.id == id);
      onChange(item);
    } else {
      onChange(e);
    }

    if (onRetrieve) {
      this.retrieveObject(id);
    }
  };

  retrieveObject = async id => {
    const { apiName, onRetrieve } = this.props;
    const { fetchItem } = this.props.apiCacheContext;
    if (!id) {
      onRetrieve(null);
      return;
    }
    const item = await fetchItem(apiName, id);
    onRetrieve(item);
  };

  renderOptions = () => {
    const { getItemName, placeholder, optionsProps = {} } = this.props;
    const items = this.getItems();

    const options = items.map((item, i) => {
      return (
        <option key={item.id || i} value={item.id} {...optionsProps}>
          {(getItemName && getItemName(item)) || item.name}
        </option>
      );
    });
    if (placeholder) {
      const option = (
        <option value="" key={'placeholder'}>
          {placeholder}
        </option>
      );
      options.splice(0, 0, option);
    }
    return options;
  };

  render() {
    const { name, value, selectProps = {}, className } = this.props;

    return (
      <select
        name={name}
        value={value || ''}
        onChange={this.onChange}
        className={className}
        {...selectProps}
      >
        {this.renderOptions()}
      </select>
    );
  }
}

export default BackendSelect;
