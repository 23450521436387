import React, { useState } from 'react';
import { Button, Icon } from '~ui';
import styles from './DocumentsHeader.module.scss';
import ContractTemplateModal from '~components/ContractTemplate/ContractTemplateModal';
import DocumentUploadModal from '../DocumentUploadModal';
import SendContractModal from '../SendContractModal';
import SendQuestionnaireModal from '../SendQuestionnaireModal';
import StudioQuestionnaire from '~components/StudioQuestionnaire';
import SendLimitReached from '../../common/SendLimitReached';
import axios from 'axios';
import { routes } from '~constants/routes';

const DocumentsHeader = ({
  initialClientId = null,
  clientName,
  questionnairesOptions,
  activeTrial,
  studioId,
  trialVerified,
}) => {
  const [showNewContractModal, setShowNewContractModal] = useState(false);
  const [showNewDocumentModal, setShowNewDocumentModal] = useState(false);
  const [showSendContractModal, setShowSendContractModal] = useState(false);
  const [showSendQuestionnaireModal, setShowSendQuestionnaireModal] = useState(
    false
  );
  const [showNewQuestionnaireModal, setShowNewQuestionnaireModal] = useState(
    false
  );
  const [miniLoading, setMiniLoading] = useState(false);

  const [showBeforeSend, setShowBeforeSend] = useState(false);

  const checkValidation = async type => {
    if (activeTrial && !trialVerified) {
      setMiniLoading(true);

      const res = await axios.get(
        routes.STUDIO_PROFILE.CHECK_SEND_LIMIT(studioId)
      );
      setMiniLoading(false);

      if (res.data.reached_send_limit) {
        setShowBeforeSend(true);
      } else {
        switch (type) {
          case 'Contract':
            setShowSendContractModal(true);
            break;
          case 'Questionnaire':
            setShowSendQuestionnaireModal(true);
            break;
          default:
            break;
        }
      }
    } else {
      switch (type) {
        case 'Contract':
          setShowSendContractModal(true);
          break;
        case 'Questionnaire':
          setShowSendQuestionnaireModal(true);
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      <div className={styles.DocumentsHeader}>
        <h1>{initialClientId ? 'Client Files' : 'Documents'}</h1>
        <div>
          <div>
            <Button
              text="New Document"
              onClick={() => setShowNewDocumentModal(true)}
            />
          </div>

          {initialClientId ? (
            <>
              <div>
                <Button
                  text="Send Contract"
                  onClick={() => checkValidation('Contract')}
                />
              </div>
              <div>
                <Button
                  text="Send Questionnaire"
                  onClick={() => checkValidation('Questionnaire')}
                />
              </div>
            </>
          ) : (
            <>
              <div>
                <Button
                  text="New Contract"
                  onClick={() => setShowNewContractModal(true)}
                />
              </div>
              <div>
                <Button
                  text="New Questionnaire"
                  onClick={() => setShowNewQuestionnaireModal(true)}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {showNewContractModal && (
        <ContractTemplateModal
          handleHide={() => setShowNewContractModal(false)}
        />
      )}
      {showNewDocumentModal && (
        <DocumentUploadModal
          handleHide={() => setShowNewDocumentModal(false)}
        />
      )}
      {showSendContractModal && (
        <SendContractModal handleHide={() => setShowSendContractModal(false)} />
      )}
      {showSendQuestionnaireModal && (
        <SendQuestionnaireModal
          clientId={initialClientId}
          questionnairesOptions={questionnairesOptions}
          clientName={clientName}
          handleHide={() => setShowSendQuestionnaireModal(false)}
        />
      )}
      {showNewQuestionnaireModal && (
        <StudioQuestionnaire
          handleHide={() => setShowNewQuestionnaireModal(false)}
        />
      )}
      {showBeforeSend && (
        <SendLimitReached handleHide={() => setShowBeforeSend(false)} />
      )}
      {miniLoading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingText}>
            <p>Loading...</p>
            <Icon name="spinner" className="fa-pulse" large />
          </div>
        </div>
      )}
    </>
  );
};

export default DocumentsHeader;
