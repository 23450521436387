import React from 'react';
import PT from 'prop-types';

import TinymceEditorContainer from '../../email_modal/tinymce_editor/tinymce_editor_container';

class RailsEmailTemplatePageTinymce extends React.PureComponent {
  static propTypes = {
    email_body: PT.string.isRequired,
    bookingUrls: PT.array,
    locations: PT.array,
  };

  state = {
    value: this.props.email_body || '',
  };

  onChange = value => {
    this.setState({ value });
  };

  render() {
    const { value } = this.state;

    return (
      <div>
        <TinymceEditorContainer
          value={value || ''}
          onChange={this.onChange}
          bookingUrls={this.props.bookingUrls}
          locations={this.props.locations}
        />
        <textarea
          name="email_template[email_body]"
          value={value || ''}
          style={{ display: 'none' }}
        />
      </div>
    );
  }
}

export default RailsEmailTemplatePageTinymce;
