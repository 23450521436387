import React, { useState, useEffect } from 'react';
import { Modal, Dropdown, Button, Checkbox } from '~ui';
import styles from '../DocumentsContent/DocumentsContent.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import TinyMceInput from '~components/common/TinyMceInput';
import axios from 'axios';
import { routes, absolutePath } from '~constants/routes';
import { getClientContracts } from '~actions/contracts';
import moment from 'moment';
const SendContractModal = ({
  handleHide,
  initialContractTemplateId = null,
}) => {
  const {
    clientId: initialClientId,
    contractsOptions,
    locations,
    bookingUrls,
    hasStudioSignature,
    hasDrawnSignature,
    clientsOptions,
  } = useSelector(state => state.documents);

  const dispatch = useDispatch();

  const [contractTemplateId, setContractTemplateId] = useState(
    initialContractTemplateId
  );
  const [calendarEventOptions, setCalendarEventOptions] = useState([]);
  const [calendarEventId, setCalendarEventId] = useState(null);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [clientId, setClientId] = useState(initialClientId);

  const [contractBody, setContractBody] = useState(
    '<p>Select from the options above, then click "Generate" to get started.</p>'
  );
  const [sending, setSending] = useState(false);

  const handleSend = async () => {
    try {
      const params = {
        contract: {
          contract_template_id: contractTemplateId,
          client_id: clientId,
          calendar_event_id: calendarEventId,
          contract_body: contractBody,
          studio_agree_to_terms: agreeToTerms,
        },
      };

      setSending(true);

      await axios.post(routes.CONTRACTS.CREATE, params);
      toast.success('Contract sent!', toastOptions);
      if (initialClientId) {
        dispatch(getClientContracts(clientId));
      }
      handleHide();
    } catch (error) {
      console.error(error);
      if (error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Error Sending the Contract', toastOptions);
      }
    }
  };

  const handleGenerate = async () => {
    try {
      const res = await axios.get(
        routes.CONTRACT_TEMPLATES.GENERATE(contractTemplateId),
        {
          params: {
            client_id: clientId,
            calendar_event_id: calendarEventId,
          },
        }
      );
      setContractBody(res.data);
    } catch (error) {
      toast.error('Error generating contract body', toastOptions);
      Rollbar.error('Error generating contract body', error);
    }
  };

  const getSessions = async () => {
    try {
      const res = await axios.get(routes.CALENDAR_EVENTS.INDEX, {
        params: {
          client_id: clientId,
        },
      });
      const mappedOptions = res.data.data.map(calEvent => {
        const start_time = moment(calEvent.attributes.start_time);
        const formatted_date = start_time.format('M.DD.YY');
        return {
          label: `${calEvent.attributes.title} - ${formatted_date}`,
          value: calEvent.attributes.id,
        };
      });
      mappedOptions.unshift({ label: 'Select a Session', value: null });
      setCalendarEventOptions(mappedOptions);
    } catch (error) {
      console.error('Error getting sessions: ', error);
    }
  };

  useEffect(() => {
    if (clientId) {
      getSessions();
    }
  }, [clientId]);

  return (
    <Modal
      title={
        hasStudioSignature && hasDrawnSignature
          ? 'Send Contract'
          : 'Add Signature!'
      }
      handleHide={handleHide}
    >
      {hasStudioSignature && hasDrawnSignature ? (
        <>
          {!initialClientId ? (
            <div className={styles.DocumentModalInputContainer}>
              <Dropdown
                name="client"
                labelText="Client"
                onChangeCallback={setClientId}
                options={clientsOptions}
              />
            </div>
          ) : null}
          {!initialContractTemplateId ? (
            <div className={styles.DocumentModalInputContainer}>
              <Dropdown
                name="contracts"
                labelText="Contract Templates"
                initialValue={contractTemplateId}
                onChangeCallback={setContractTemplateId}
                options={contractsOptions}
              />
            </div>
          ) : null}
          {calendarEventOptions.length > 0 && (
            <div className={styles.DocumentModalInputContainer}>
              <Dropdown
                name="session"
                labelText="Session"
                onChangeCallback={setCalendarEventId}
                options={calendarEventOptions}
                disabled={calendarEventOptions.length < 1}
              />
            </div>
          )}
          <div className={styles.DocumentModalInputContainer}>
            <Button
              text="Generate"
              onClick={handleGenerate}
              disabled={!clientId || !contractTemplateId}
            />
          </div>
          <div className={styles.DocumentModalInputContainer}>
            <TinyMceInput
              value={contractBody}
              onChangeCallback={setContractBody}
              locations={locations}
              bookingUrls={bookingUrls}
            />
          </div>
          <div className={styles.DocumentModalInputContainer}>
            <Checkbox
              labelText="Agree to Terms"
              checked={agreeToTerms}
              onChangeCallback={setAgreeToTerms}
              name="agrees"
            />
          </div>
          <p>
            By checking this box, you indicate that you have read and agree to
            the terms.
          </p>
          <div className={styles.DocumentModalButtonContainer}>
            <Button text="Cancel" onClick={handleHide} danger />
            <Button
              text="Send"
              onClick={handleSend}
              disabled={!(clientId && agreeToTerms) || sending}
            />
          </div>
        </>
      ) : (
        <>
          {' '}
          <div>You need a studio signature to send contracts.</div>
          <br />
          <div>
            Click{' '}
            <a
              href={absolutePath('/studio/profile')}
              style={{ color: '#8dd6ff' }}
            >
              HERE
            </a>{' '}
            to add a signature to your studio.
          </div>
        </>
      )}
    </Modal>
  );
};

export default SendContractModal;
