import React, { useState } from 'react';
import styles from './EmailMarketingLandingPageRoot.module.scss';
import { TextInput, Button, Checkbox } from '~ui';
import { routes } from '~constants/routes';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import axios from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const EmailMarketingLandingPage = props => {
  const { studio } = props;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [emailMarketing, setEmailMarketing] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const buttonDisabled = () => {
    const originalFieldsHasValue = [
      firstName,
      lastName,
      email,
      emailMarketing,
    ].every(val => !!val);

    return !originalFieldsHasValue;
  };

  const handleSubmit = async () => {
    if (!executeRecaptcha) {
      return;
    }
    const recaptchaHumanKey = await executeRecaptcha('submit');

    try {
      await axios.post(routes.LEADS.CREATE, {
        client: {
          first_name: firstName,
          last_name: lastName,
          email_marketing: emailMarketing,
          studio_id: studio.id,
          recaptcha_human_key: recaptchaHumanKey,
          source: 'marketing_contact',
          email,
        },
      });
      setIsSubmitted(true);
    } catch (error) {
      toast.error('Error creating Marketing Contact.', toastOptions);
    }
  };
  return (
    <div className={styles.Form}>
      {!isSubmitted ? (
        <>
          <div>
            <h1>{studio.marketing_message}</h1>
          </div>
          <div className={styles['Form-twoColumn']}>
            <TextInput
              labelText="First Name"
              required
              onChangeCallback={setFirstName}
              name="firstName"
            />
            <TextInput
              labelText="Last Name"
              required
              onChangeCallback={setLastName}
              name="lastName"
            />
          </div>
          <div className={styles['Form-oneColumn']}>
            <TextInput
              labelText="Email"
              required
              onChangeCallback={setEmail}
              name="email"
            />
          </div>
          <div className={styles['Form-checkBox']}>
            <Checkbox
              labelText="Sign me up to receive newsletters, updates and offers."
              onChangeCallback={setEmailMarketing}
              name="email"
            />
          </div>
          <div className={styles['Form-buttons']}>
            <Button
              disabled={buttonDisabled()}
              style={{
                background: studio.marketing_submit_color,
                borderColor: studio.marketing_submit_color,
              }}
              text={'Submit'}
              onClick={handleSubmit}
            />
          </div>
        </>
      ) : (
        <div className={styles.Success}>
          <h1>Thank you!</h1>
        </div>
      )}
    </div>
  );
};

export default EmailMarketingLandingPage;
