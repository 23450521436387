import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styles from './Modal.module.scss';

const Modal = props => {
  const { handleHide, title, children, maxWidth, backgroundColor } = props;

  return ReactDOM.createPortal(
    <div
      className={styles.Modal}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <div
        className={styles['Modal-main']}
        style={{
          maxWidth: maxWidth ? `${maxWidth}px` : null,
          backgroundColor: backgroundColor ? backgroundColor : null,
        }}
      >
        <div className={styles['Modal-header']}>
          <div className={styles['Modal-title']}>{title}</div>
          <div className={styles['Modal-closeIcon']}>
            <a
              onClick={e => {
                e.stopPropagation();
                handleHide();
              }}
            >
              <i className="fa fa-times" />
            </a>
          </div>
        </div>
        <div className={styles['Modal-body']}>{children}</div>
      </div>
    </div>,
    document.getElementById('modal-holder')
  );
};

Modal.propTypes = {
  handleHide: PropTypes.func,
  title: PropTypes.string.isRequired,
};

Modal.defaultProps = {
  handleHide: () => {},
};

export default Modal;
