import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actionsErroredSelector } from '~selectors';
import { clearErrors } from '~actions/errors';
import { toastOptions } from '~constants/toasts';
import { toast } from 'react-toastify';
import CONNECT_TO_QB_DEFAULT from '~images/integrations/C2QB_default.png';
import CONNECT_TO_QB_HOVER from '~images/integrations/C2QB_hover.png';
import { Button, Toggle } from '~ui';
import styles from './InvoiceSetup.module.scss';

const ConnectButton = () => {
  const [loadingImg, setLoadingImg] = useState(true);
  const [loading, setLoading] = useState(false);

  return (
    <div className={styles.QuickBooksConnectButtonContainer}>
      <button
        onClick={() => {
          setLoading(true);
          window.location.href = '/api/quickbooks/connection/new';
        }}
        disabled={loading}
      >
        <img
          src={CONNECT_TO_QB_DEFAULT}
          onLoad={() => {
            setLoadingImg(false);
          }}
          onMouseOver={e => (e.currentTarget.src = CONNECT_TO_QB_HOVER)}
          onMouseOut={e => (e.currentTarget.src = CONNECT_TO_QB_DEFAULT)}
        />
        {loadingImg && <span className="Spinner" />}
      </button>
      {loading && <span className="Spinner" />}
    </div>
  );
};

const DisconnectButton = () => {
  const [loading, setLoading] = useState(false);

  return (
    <Button
      danger
      text={loading ? 'Disconnecting' : 'Disconnect'}
      loading={loading}
      disabled={loading}
      onClick={() => {
        setLoading(true);
        window.location.href = '/api/quickbooks/connection/disconnect';
      }}
    />
  );
};

const IntegrationsSection = ({ asyncUpdateInvoiceConfig }) => {
  const dispatch = useDispatch();
  const invoiceConfig = useSelector(state => state.invoiceConfig);
  const errors = useSelector(state =>
    actionsErroredSelector(state, ['INVOICE_CONFIGS_UPDATE'])
  );
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (loading !== false) {
      return;
    }

    if (errors.INVOICE_CONFIGS_UPDATE) {
      toast.error('Error saving Payment Settings.', toastOptions);
      dispatch(clearErrors());
    } else {
      toast.success('Payment Settings successfully saved.', toastOptions);
    }
  }, [loading]);

  return (
    <div className={styles.IntegrationsContainer}>
      <Toggle
        labelText="Fundy"
        initialValue={invoiceConfig.use_fundy}
        disabled={loading}
        onChangeCallback={newValue => {
          setLoading(true);
          asyncUpdateInvoiceConfig({
            use_fundy: newValue,
          }).then(() => {
            setLoading(false);
          });
        }}
      />
      <label>QuickBooks Online</label>
      {invoiceConfig.meta.features.hasQuickBooks ? (
        <DisconnectButton />
      ) : (
        <ConnectButton />
      )}
    </div>
  );
};

export default IntegrationsSection;
