import React from 'react';
import { Toggle } from '~components/ui/Toggle';
import styles from './SubscriptionToggle.module.scss';

const SubscriptionToggle = ({ onChangeCallback, emailMarketing }) => {
  return (
    <div
      className={
        !emailMarketing
          ? styles.SubscriptionToggle
          : styles.MarketingSubscriptionToggle
      }
    >
      {!emailMarketing ? (
        <>
          <div>Annually</div>
          <Toggle staticColor onChangeCallback={onChangeCallback} />
          <div>Monthly</div>
        </>
      ) : (
        <>
          <div>No</div>
          <Toggle initialValue onChangeCallback={onChangeCallback} />
          <div>Yes</div>
        </>
      )}
    </div>
  );
};

export default SubscriptionToggle;
