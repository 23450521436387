import React from 'react';
import PropTypes from 'prop-types';

const EmptyBody = ({ columnCount }) => (
  <tbody>
    <tr>
      <td colSpan={columnCount} style={{ textAlign: 'center' }}>
        Nothing here yet.
      </td>
    </tr>
  </tbody>
);

EmptyBody.propTypes = {
  columnCount: PropTypes.number.isRequired,
};

export default EmptyBody;
