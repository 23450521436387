import React from 'react';
import styles from './EmailMarketingTable.module.scss';
import CampaignTemplatesTable from './CampaignTemplatesTable';
import CampaignsTable from './CampaignsTable';
import MarketingLandingPageSettings from './MarketingLandingPageSettings';

const EmailMarketingTable = ({ activeTabKey, setActiveTabKey }) => {
  const tabs = [
    {
      text: 'My Campaigns',
      key: 'campaigns',
      active: activeTabKey === 'campaigns',
    },
    {
      text: 'My Campaign Templates',
      key: 'campaignTemplates',
      active: activeTabKey === 'campaignTemplates',
    },
    {
      text: 'Opt-in Form',
      key: 'landingPage',
      active: activeTabKey === 'landingPage',
    },
  ];

  return (
    <div className={styles.EmailMarketingTable}>
      <div className={styles['EmailMarketingTable-tabs']}>
        {tabs.map((tab, i) => (
          <a
            className={
              tab.active ? styles['EmailMarketingTable-tabs-active'] : ''
            }
            onClick={() => setActiveTabKey(tab.key)}
            key={i}
          >
            {tab.text}
          </a>
        ))}
      </div>
      <hr />
      <div className={styles['EmailMarketingTable-tableContainer']}>
        {activeTabKey === 'campaigns' && <CampaignsTable />}
        {activeTabKey === 'campaignTemplates' && (
          <CampaignTemplatesTable setActiveTabKey={setActiveTabKey} />
        )}
        {activeTabKey == 'landingPage' && <MarketingLandingPageSettings />}
      </div>
    </div>
  );
};

export default EmailMarketingTable;
