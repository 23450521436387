import React, { useState, useEffect } from 'react';
import styles from './StepForm.module.scss';
import { Dropdown, TextInput, Button } from '~ui';
import {
  setResourceOptions,
  createReminderTemplate,
  updateJourneyTemplate,
  updateReminderTemplate,
} from '~actions/journeyTemplate';
import { useSelector, useDispatch } from 'react-redux';

const StepForm = props => {
  const { handleClose, isEditing, initialFormState } = props;

  const [item, setItem] = useState(initialFormState.item);
  const [description, setDescription] = useState(initialFormState.description);
  const [schedule, setSchedule] = useState(initialFormState.schedule);
  const [number, setNumber] = useState(initialFormState.number);
  const [scheduleInterval, setScheduleInterval] = useState(
    initialFormState.scheduleInterval
  );
  const [resourceId, setResourceId] = useState(initialFormState.resourceId);

  const [buttonText, setButtonText] = useState(isEditing ? 'Save' : 'Add Item');

  const { dropdownOptions, resourceData, studioId } = useSelector(
    state => state.journeyTemplate
  );

  useEffect(() => {
    if (
      resourceData.resourceOptions.length &&
      initialFormState.resourceType !== resourceData.resourceType.value
    ) {
      setResourceId(resourceData.resourceOptions[0].value);
    }
  }, [resourceData.resourceType.value]);

  useEffect(() => {
    dispatch(setResourceOptions(item));
  }, []);

  const dispatch = useDispatch();

  const showInterval = !(schedule === 0 || schedule === 1);
  const showDescription = item === 2;
  const showResourceDropdown = item === 1 || item === 5;

  const isDisabled = () => {
    if (showDescription && showInterval) {
      return description === '' || scheduleInterval === 0;
    } else if (!showDescription && showInterval) {
      return scheduleInterval === 0 || item === 0;
    } else if (showDescription && !showInterval) {
      return description === '';
    } else {
      return item === 0;
    }
  };

  const getStepName = () => {
    return showDescription
      ? description
      : `Send "${
          resourceData.resourceOptions.find(
            resource => resourceId === resource.value
          ).label
        }" email`;
  };

  const handleAddItem = async () => {
    const reminderTemplateId = showDescription
      ? await dispatch(createReminderTemplate(description, studioId))
      : null;

    dispatch(
      updateJourneyTemplate({
        type: 'ADD_STEP',
        step: {
          name: getStepName(),
          journey_trigger_id: schedule,
          journey_action_id: item,
          time_offset: showInterval ? number * scheduleInterval : null,
          resource_id: showResourceDropdown ? resourceId : reminderTemplateId,
          resource_type: resourceData.resourceType.value,
        },
      })
    );

    setButtonText('Added!');
    setTimeout(() => setButtonText('Add Item'), 1000);
  };

  const handleEditItem = async () => {
    let reminderTemplateId;
    if (initialFormState.item == 2 && showDescription) {
      reminderTemplateId = await dispatch(
        updateReminderTemplate(description, resourceId)
      );
    } else if (showDescription) {
      reminderTemplateId = await dispatch(
        createReminderTemplate(description, studioId)
      );
    } else {
      reminderTemplateId = null;
    }

    dispatch(
      updateJourneyTemplate({
        type: 'UPDATE_STEP',
        step: {
          id: initialFormState.stepId,
          name: getStepName(),
          journey_trigger_id: schedule,
          journey_action_id: item,
          time_offset: showInterval ? number * scheduleInterval : null,
          resource_id: showResourceDropdown ? resourceId : reminderTemplateId,
          resource_type: resourceData.resourceType.value,
        },
      })
    );
  };

  const handleSave = () => {
    isEditing ? handleEditItem() : handleAddItem();
  };

  return (
    <div className={styles['StepForm']}>
      <div className={styles['StepForm-topRow']}>
        <div className={styles['StepForm-item']}>
          <Dropdown
            name="itemOptions"
            labelText="Item"
            initialValue={String(item)}
            onChangeCallback={value => {
              setItem(+value);
              dispatch(setResourceOptions(+value));
            }}
            options={dropdownOptions.actionOptions}
          />
        </div>
        {showDescription && (
          <div className={styles['StepForm-description']}>
            <TextInput
              name="description"
              labelText="Description"
              initialValue={description}
              onChangeCallback={value => {
                setDescription(value);
              }}
            />
          </div>
        )}
        {showResourceDropdown && (
          <Dropdown
            className={styles['StepForm-resource']}
            name="resource"
            labelText={resourceData.resourceType.name}
            initialValue={String(resourceId)}
            options={resourceData.resourceOptions}
            onChangeCallback={value => {
              setResourceId(+value);
            }}
          />
        )}
      </div>
      <div className={styles['StepForm-scheduleContainer']}>
        <Dropdown
          name="schedule"
          initialValue={String(schedule)}
          labelText="Schedule"
          options={dropdownOptions.triggerOptions}
          onChangeCallback={value => {
            setSchedule(+value);
          }}
        />
        {showInterval && (
          <>
            <div className={styles['StepForm-scheduleContainer-by']}>by</div>
            <TextInput
              name="number"
              type="number"
              onFocusSelectText
              characterLimit={2}
              initialValue={number}
              onChangeCallback={value => {
                setNumber(+value);
              }}
            />
            <Dropdown
              name="interval"
              initialValue={String(scheduleInterval)}
              onChangeCallback={value => {
                setScheduleInterval(+value);
              }}
              options={[
                { label: 'Day(s)', value: 1 },
                { label: 'Week(s)', value: 7 },
                { label: 'Month(s)', value: 30 },
                { label: 'Year(s)', value: 365 },
              ]}
            />
          </>
        )}
        <div className={styles['StepForm-scheduleContainer-buttonGroup']}>
          <Button text="Cancel" danger onClick={handleClose} />
          <Button
            text={buttonText}
            disabled={isDisabled()}
            onClick={() => {
              handleSave();
              handleClose();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default StepForm;
