import React from 'react';
import DocumentsHeader from '../../../Documents/DocumentsHeader';
import { Provider } from 'react-redux';
import ClientDocumentsContent from '../ClientDocumentsContent';
import { configureStore } from '../../../../store';

const ClientDocumentsRoot = ({
  clients,
  locations,
  bookingUrls,
  clientId,
  contractsOptions,
  clientName,
  questionnairesOptions,
  hasStudioSignature,
  hasDrawnSignature,
  activeTrial,
  studioId,
  trialVerified,
}) => {
  const store = configureStore({
    documents: {
      clientDocuments: { data: [], meta: { page: 1, limit: 10 } },
      documents: { data: [], meta: { page: 1, limit: 10 } },
      questionnaires: { data: [], meta: { page: 1, limit: 10 } },
      contracts: { data: [], meta: { page: 1, limit: 10 } },
      clientsOptions: clients,
      locations: locations,
      bookingUrls: bookingUrls,
      clientId: clientId,
      contractsOptions: contractsOptions,
      hasStudioSignature: hasStudioSignature,
      hasDrawnSignature: hasDrawnSignature,
      activeTrial,
      studioId,
      trialVerified,
    },
  });

  return (
    <Provider store={store}>
      <DocumentsHeader
        initialClientId={clientId}
        clientName={clientName}
        questionnairesOptions={questionnairesOptions}
        activeTrial={activeTrial}
        studioId={studioId}
        trialVerified={trialVerified}
      />
      <ClientDocumentsContent />
    </Provider>
  );
};

export default ClientDocumentsRoot;
