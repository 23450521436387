import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import WebpackerReact from 'webpacker-react';
import { withRegisteredContexts } from '../portal_provider_injector/portal_provider_injector';

import components from '../components';
import GlobalContext from '../contexts/global_context';

const allComponents = components;

allComponents.UIPlayground = require('../components/UIPlayground').default;

const WebpackerReactPortalProvider = withRegisteredContexts({ GlobalContext });
WebpackerReact.setup({ ...allComponents, WebpackerReactPortalProvider });

window.addEventListener('load', () => {
  const element = document.createElement('div');
  document.body.appendChild(element);
  ReactDOM.render(<ToastContainer hideProgressBar />, element);
});
