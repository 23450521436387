import React, { useState } from 'react';
import styles from './ClientBanner.module.scss';
import BookingInvitationModal from '../../BookingInvitationModal/index';
import NewCalendarEventModal from '../../common/NewCalendarEventModal/index';
import RailsEmailModal from '../../rails_components/email_modal/rails_email_modal';
import ClientGroupModal from '../ClientNavbar/ClientGroupModal/ClientGroupModal';
import ClientAvatarModal from './ClientAvatarModal/ClientAvatarModal';
import SendLimitReached from '../../common/SendLimitReached';
import axios from 'axios';
import { routes } from '~constants/routes';

const ClientBanner = props => {
  const {
    firstName,
    lastName,
    calendar,
    client_id,
    client,
    company,
    avatar,
    bookingEnabled,
    activeTrial,
    studioId,
  } = props;

  const fullName = `${firstName} ${lastName}`;
  const [openUploader, setOpenUploader] = useState(false);
  const [clientAvatar, setClientAvatar] = useState(avatar);
  const [showBeforeSend, setShowBeforeSend] = useState(false);

  const checkSendLimit = async () => {
    try {
      const res = await axios.get(
        routes.STUDIO_PROFILE.CHECK_SEND_LIMIT(studioId)
      );

      if (res.data.reached_send_limit) {
        setShowBeforeSend(true);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error checking send limit:', error);
    }
  };

  return (
    <>
      <div className={styles.ClientBanner}>
        <div className={styles['ClientBanner--nameGroup']}>
          <img
            className={styles.ImageStyle}
            src={clientAvatar}
            onClick={() => {
              setOpenUploader(true);
            }}
          />
          <div style={!company ? { marginTop: '8px' } : {}}>
            <h1>{fullName}</h1>
            {company && <h2>{company}</h2>}
          </div>
        </div>
        <div style={{ display: 'inline-flex' }}>
          <div
            className={styles['ClientBanner--buttonGroup']}
            style={{ marginRight: '0px' }}
          >
            <NewCalendarEventModal calendar={calendar} client_id={client_id} />
            <div>
              {bookingEnabled && (
                <BookingInvitationModal
                  client={client}
                  locations={calendar.locations}
                  sessionTypes={props.sessionTypes}
                  journeyTemplates={props.journeyTemplates}
                  invoiceTemplates={props.invoiceTemplates}
                  contracts={props.contracts}
                  emailTemplates={props.emailTemplates}
                  bookingUrls={props.bookingUrls}
                  locationVariables={props.locationVariables}
                  bookingCalendar={props.bookingCalendar}
                  calendar={calendar}
                  activeTrial={activeTrial}
                  studioId={studioId}
                  trialVerified={props.trialAccountVerified}
                />
              )}
            </div>
          </div>
          <div className={styles['ClientBanner--buttonGroup']}>
            <div>
              <RailsEmailModal
                button_label={props.button_label}
                button_class_name={props.button_class_name}
                client_id={client_id}
                client_full_name={props.client_full_name}
                bookingUrls={props.bookingUrls}
                locations={props.locations}
                trialAccountVerified={props.trialAccountVerified}
                hasActiveSub={props.hasActiveSub}
                activeTrial={props.activeTrial}
                checkSendLimit={checkSendLimit}
              />
            </div>
            <div>
              <ClientGroupModal client_id={client_id} />
            </div>
          </div>
        </div>
      </div>
      {openUploader && (
        <ClientAvatarModal
          handleHide={setOpenUploader}
          client_id={client_id}
          setClientAvatar={setClientAvatar}
        />
      )}
      {showBeforeSend && (
        <SendLimitReached handleHide={() => setShowBeforeSend(false)} />
      )}
    </>
  );
};

export default ClientBanner;
