import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FULL_DATE_FORMAT } from '~constants/datetime';
import { toCurrencyAmount } from '~utils';
import { Icon, Button } from '~ui';
import LineItemsTable from './LineItemsTable';
import InstallmentsTable from './InstallmentsTable';
import InvoiceTotals from './InvoiceTotals';
import QuoteOptions from './QuoteOptions';
import PaymentOptions from '../PaymentOptions';
import styles from './CustomerInvoice.module.scss';
import { toastOptions } from '~constants/toasts';
import { toast } from 'react-toastify';
import axios from 'axios';
import { routes } from '~constants/routes';

const CustomerInvoice = props => {
  const {
    invoice,
    meta,
    studio,
    imageUrl,
    user,
    handlePayment,
    bookingNextStepUrl,
    couponAllowed,
    availabilityId,
    bookingCalendarId,
  } = props;
  const {
    items,
    totals,
    schedule,
    client,
    client_name,
    next_installment_amount_due,
    next_installment_date_due,
    total,
  } = invoice;

  const { isBooking } = meta;
  const [isLoading, setIsLoading] = useState(false);
  const [updatedInvoice, setUpdatedInvoice] = useState(invoice);
  const allowCoupons = couponAllowed !== undefined ? couponAllowed : true;
  const [keyValue, setKeyValue] = useState(0);

  const paidTip =
    updatedInvoice.tip_date !== null && updatedInvoice.tip_amount > 0
      ? updatedInvoice.tip_amount
      : 0;
  const currentTip = paidTip > 0 ? 0 : updatedInvoice.tip_amount;

  let isMountedRef = useRef(false);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const loadingCallback = ({
    loading,
    reload = true,
    success = false,
    bookingLogicCompleted = false,
    calendarEvent = false,
  }) => {
    if (success && handlePayment) {
      handlePayment(bookingLogicCompleted, calendarEvent);
    }

    if (success && bookingNextStepUrl) {
      window.location.href = bookingNextStepUrl;
    }

    if (!loading && reload) {
      window.location.reload();
    }

    if (isMountedRef.current) {
      setIsLoading(loading);
    }
  };
  const type = invoice.is_quote ? 'quote' : 'invoice';
  const labels = {
    quote: {
      title: `Quote ${invoice.invoice_num} (${invoice.quote_status})`,
      dateLabel: 'Expiration Date',
      dateValue: moment(invoice.expires_at).format(FULL_DATE_FORMAT),
    },
    invoice: {
      title: `Invoice ${invoice.invoice_num}`,
      dateLabel: 'Issue Date',
      dateValue: moment(invoice.issued_at).format(FULL_DATE_FORMAT),
    },
  };

  let showInstallmentsTable = true;
  if (
    schedule &&
    schedule.installments.length === 1 &&
    Number(schedule.installments[0].amount) === Number(total)
  ) {
    showInstallmentsTable = false;
  }

  // This does some checks to make sure we don't have random floating dot separators
  // if some details are not present. Instead of using a bunch of `if` statements
  // to check if we should insert the dot separators, an array of variables is filtered
  // to get the pieces that are not null or undefined, and then all of these defined
  // variables are joined with the dot separator.
  const renderStudioDetails = () => {
    const { name, phone, address, city, state, postal } = studio;
    const { email } = user;

    let cityAndState;
    if (city && state) {
      cityAndState = `${city}, ${state}`;
    } else if (city) {
      cityAndState = city;
    } else if (state) {
      cityAndState = state;
    }

    return (
      <>
        <p>{[name, phone, email].filter(item => !!item).join(' • ')}</p>
        {address && (
          <p>
            {[address, cityAndState, postal].filter(item => !!item).join(' • ')}
          </p>
        )}
      </>
    );
  };

  const handleCouponCode = async (code, studio) => {
    try {
      const invoiceId = invoice.id;
      const params = { invoice: { coupon_code: code, studio_id: studio.id } };
      const response = await axios.put(
        routes.INVOICES.UPDATE(invoiceId),
        params
      );

      setUpdatedInvoice({
        ...updatedInvoice,
        ...response.data.data.attributes,
      });

      setKeyValue(prevValue => prevValue + 1);
      toast.success(`Coupon Code applied!`, toastOptions);
    } catch (error) {
      toast.error(`Coupon Code is invalid. Try again.`, toastOptions);
    }
  };

  const handleTip = async (tip, studio) => {
    try {
      const invoiceId = invoice.id;
      const params = { invoice: { tip_amount: tip, studio_id: studio.id } };
      const response = await axios.put(
        routes.INVOICES.UPDATE(invoiceId),
        params
      );

      setUpdatedInvoice({
        ...updatedInvoice,
        ...response.data.data.attributes,
      });

      setKeyValue(prevValue => prevValue + 1);
    } catch (error) {
      toast.error(`Could not apply tip. Try again.`, toastOptions);
    }
  };
  return (
    <>
      {isLoading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingText}>
            <p>Payment Processing</p>
            <Icon name="spinner" className="fa-pulse" large />
          </div>
        </div>
      )}
      <div className={styles.CustomerInvoice}>
        <div className={styles['CustomerInvoice-header']}>
          {imageUrl && !isBooking && (
            <>
              <img src={imageUrl} alt={`${studio.name}'s Logo`} />
              {renderStudioDetails()}
            </>
          )}
          <br />
          <h1>{labels[type].title}</h1>
          <div className={styles['CustomerInvoice-meta']}>
            <div className={styles['CustomerInvoice-metaLine']}>
              <span>Client</span>
              <div className={styles['CustomerInvoice-metaLine-nameAddress']}>
                <div>{client_name}</div>
                <div>{client.attributes.address}</div>
                <div>{client.attributes.city_state_zip}</div>
              </div>
            </div>
            <div className={styles['CustomerInvoice-metaLine']}>
              <span>{labels[type].dateLabel}</span>
              {labels[type].dateValue}
            </div>
          </div>
          <br />
        </div>
        <LineItemsTable lineItems={items} />
        {schedule && showInstallmentsTable && (
          <InstallmentsTable
            installments={updatedInvoice.schedule.installments}
          />
        )}
        <br />
        <InvoiceTotals
          totals={updatedInvoice.totals}
          invoice={updatedInvoice}
          meta={meta}
          handleTip={handleTip}
          paidTip={paidTip}
          currentTip={currentTip}
          studio={studio}
          handleCouponCode={handleCouponCode}
          hasActiveCode={props.hasActiveCode}
          allowCoupons={allowCoupons}
        />
        <br />
        {invoice.memo && (
          <>
            <h1>Memo</h1>
            <p>{invoice.memo}</p>
          </>
        )}
        <br />
        {invoice.is_quote && <QuoteOptions invoice={invoice} />}
        {invoice.quote_comments && invoice.is_quote && (
          <>
            <h1>Comments</h1>
            <p>{invoice.quote_comments}</p>
          </>
        )}
        {Number(updatedInvoice.totals.due) > 0 &&
          !updatedInvoice.is_quote &&
          meta.isActive &&
          updatedInvoice.status !== 'ach_pending' &&
          !updatedInvoice.paid_in_full && (
            <div key={keyValue}>
              <PaymentOptions
                loadingCallback={loadingCallback}
                processorsMetadata={meta}
                amountDue={`${
                  Number(updatedInvoice.next_installment_amount_due) +
                  Number(currentTip)
                }`}
                tipAmount={currentTip}
                invoice={updatedInvoice}
                client={client}
                studio={studio}
                loading={isLoading}
                isBooking={isBooking}
                user={user}
                availabilityId={availabilityId}
                bookingCalendarId={bookingCalendarId}
              />
            </div>
          )}
        {isBooking &&
          Number(updatedInvoice.totals.due) <= 0 &&
          !updatedInvoice.is_quote &&
          meta.isActive &&
          updatedInvoice.status !== 'ach_pending' && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row-reverse',
              }}
            >
              <Button
                text={`Complete`}
                onClick={() =>
                  loadingCallback({
                    loading: false,
                    reload: false,
                    success: true,
                  })
                }
              />
            </div>
          )}
      </div>
    </>
  );
};

CustomerInvoice.propTypes = {
  invoice: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  studio: PropTypes.object.isRequired,
  imageUrl: PropTypes.string,
  user: PropTypes.object.isRequired,
  handlePayment: PropTypes.func,
};

CustomerInvoice.defaultProps = {
  handlePayment: undefined,
};

export default CustomerInvoice;
