import React, { useEffect, useState, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './TextInput.module.scss';

const TextInput = forwardRef((props, outerRef) => {
  const {
    className,
    disabled,
    initialValue,
    inline,
    labelText,
    name,
    onChangeCallback,
    placeholder,
    required,
    characterLimit,
    type,
    onFocusSelectText,
    validation,
    setWarningForEmpty = false,
    lightGrey,
    disableAutoComplete,
  } = props;
  const hasCharacterLimit = !!characterLimit;
  const hasType = !!type;
  const cx = classNames.bind(styles);
  const [inputValue, setInputValue] = useState(initialValue);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  // Various browser autofill workaround
  const inputRef = outerRef || useRef(null);
  useEffect(() => {
    let interval = setInterval(() => {
      if (inputRef.current) {
        if (validation && inputValue !== '') {
          setIsValid(validation.test(inputRef.current.value));
        }
        setInputValue(inputRef.current.value);
        clearInterval(interval);
      }
    }, 100);
  });

  useEffect(() => {
    if (validation) {
      onChangeCallback(inputValue, isValid);
    } else {
      onChangeCallback(inputValue);
    }
  }, [inputValue]);

  const classes = cx('TextInput', {
    'TextInput--disabled': disabled,
    'TextInput--inline': inline,
    'TextInput--invalid': !isValid,
    'TextInput--blueInvalid': setWarningForEmpty && inputValue.length === 0,
    [`TextInput--limit-${characterLimit}`]: hasCharacterLimit,
    'TextInput--lightGrey': lightGrey,
    [className]: !!className,
    'TextInput--lightGrey': lightGrey,
  });

  return (
    <div className={classes}>
      {labelText && (
        <label htmlFor={name}>
          {labelText}
          {required && <span>*</span>}
        </label>
      )}
      <input
        ref={inputRef}
        name={name}
        type={hasType && type !== 'number' ? type : undefined}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        value={inputValue}
        maxLength={characterLimit}
        onFocus={e => {
          if (onFocusSelectText) {
            e.target.select();
          }
        }}
        onChange={e => {
          const { value } = e.target;
          if (hasType && type === 'number') {
            if (!isNaN(value)) {
              setInputValue(value);
            }
          } else {
            if (validation && inputValue !== '') {
              setIsValid(validation.test(value));
            }
            setInputValue(value);
          }
        }}
        autoComplete={disableAutoComplete ? 'new-password' : ''}
      />
    </div>
  );
});

TextInput.propTypes = {
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelText: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChangeCallback: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  characterLimit: PropTypes.number,
  type: PropTypes.string,
  validation: PropTypes.instanceOf(RegExp),
};

TextInput.defaultProps = {
  disabled: false,
  inline: false,
  initialValue: '',
  labelText: undefined,
  placeholder: '',
  onChangeCallback: () => {},
  required: false,
  characterLimit: undefined,
  validation: undefined,
};

export default TextInput;
