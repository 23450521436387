import {
  CUSTOMER_BOOKING_AVAILABILITY,
  CUSTOMER_BOOKING_CLIENT,
  CUSTOMER_BOOKING_CONTRACT,
  CUSTOMER_BOOKING_INVOICE,
  CUSTOMER_BOOKING_FOOTER,
  CUSTOMER_BOOKING,
} from '~constants/actionTypes';

export default function (state = {}, action) {
  switch (action.type) {
    case CUSTOMER_BOOKING_AVAILABILITY.SELECT_AVAILABILITY.SUCCESS:
      return {
        ...state,
        selectedAvailability: action.payload,
        showTimer: true,
      };
    case CUSTOMER_BOOKING_AVAILABILITY.CHECK_CLIENT_PERMISSION.SUCCESS:
      return {
        ...state,
      };
    case CUSTOMER_BOOKING_CLIENT.CREATE.SUCCESS:
      return {
        ...state,
        clientInfo: action.payload,
      };
    case CUSTOMER_BOOKING_CONTRACT.GENERATE.SUCCESS:
      return {
        ...state,
        contract: action.payload,
      };
    case CUSTOMER_BOOKING_INVOICE.GENERATE.SUCCESS:
      return {
        ...state,
        invoice: action.payload,
      };
    case CUSTOMER_BOOKING_AVAILABILITY.BOOK_AVAILABILITY.SUCCESS:
      return {
        ...state,
        calendarEvent: action.payload,
        showTimer: false,
      };
    case CUSTOMER_BOOKING_AVAILABILITY.BOOK_AVAILABILITY.FAILURE:
      return {
        ...state,
        calendarEventError: action.payload,
        showTimer: false,
      };
    case CUSTOMER_BOOKING.RESET:
      return {
        ...state,
        selectedAvailability: {},
        clientInfo: {
          first_name: '',
          last_name: '',
          phone: '',
          email: '',
          id: null,
        },
        sessionLocation: '',
        invoice: null,
        contract: {},
        showTimer: false,
      };
    case CUSTOMER_BOOKING.BOOKING_LOGIC_COMPLETED:
      return { ...state, bookingLogicCompleted: action.payload };
    case CUSTOMER_BOOKING.UPDATE_CALENDAR_EVENT:
      return { ...state, calendarEvent: action.payload, showTimer: false };
    case CUSTOMER_BOOKING_FOOTER.UPDATE_ON_CONTINUE:
      return {
        ...state,
        bookingFooterState: {
          ...state.bookingFooterState,
          onContinue: action.payload,
        },
      };
    case CUSTOMER_BOOKING_FOOTER.UPDATE_CURRENT_SELECTION:
      return {
        ...state,
        bookingFooterState: {
          ...state.bookingFooterState,
          currentSelection: action.payload,
        },
      };
    case CUSTOMER_BOOKING_FOOTER.UPDATE_CONTINUE_DISABLED:
      return {
        ...state,
        bookingFooterState: {
          ...state.bookingFooterState,
          continueDisabled: action.payload,
        },
      };
    default:
      return state;
  }
}
