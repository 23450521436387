import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toCurrencyAmount } from '~utils';
import { Button, Dropdown, Modal, Table } from '~ui';
import { routes } from '~constants/routes';
import RetainerAndBalanceFields from './RetainerAndBalanceFields';
import RetainerAndMultipleFields from './RetainerAndMultipleFields';
import EqualPaymentsFields from './EqualPaymentsFields';
import styles from './AddPaymentScheduleTemplateModal.module.scss';

const planOptions = [
  { label: 'Choose a plan', value: '' },
  { label: 'Retainer + Balance', value: 'retainer_balance' },
  {
    label: 'Retainer + Multiple Payments',
    value: 'retainer_multiple',
  },
  { label: 'Multiple Equal Payments', value: 'equal' },
  { label: 'Custom', value: 'custom' },
];

const frequencyOptions = [
  { label: 'Choose a frequency', value: '' },
  { label: 'Daily', value: 'day', display: 'daily' },
  { label: 'Weekly', value: 'week', display: 'weekly' },
  { label: 'Bi-Weekly', value: 'week2', display: 'bi_weekly' },
  { label: 'Monthly', value: 'month', display: 'monthly' },
  { label: 'Bi-Monthly', value: 'month2', display: 'bi_monthly' },
];

const frequencyLabels = {
  daily: '1 day(s) later',
  weekly: '1 week(s) later',
  bi_weekly: '2 week(s) later',
  monthly: '1 month(s) later',
  bi_monthly: '2 month(s) later',
};

const getPlanFields = options => {
  if (options.currentPlan === 'retainer_balance') {
    return (
      <RetainerAndBalanceFields
        retainerAmount={options.retainerAmount.value}
        setRetainerAmount={options.setRetainerAmount}
      />
    );
  } else if (options.currentPlan === 'retainer_multiple') {
    return (
      <RetainerAndMultipleFields
        retainerAmount={options.retainerAmount.value}
        setRetainerAmount={options.setRetainerAmount}
        numberOfPayments={options.numberOfPayments}
        setNumberOfPayments={options.setNumberOfPayments}
      />
    );
  } else if (options.currentPlan === 'equal') {
    return (
      <EqualPaymentsFields
        numberOfPayments={options.numberOfPayments}
        setNumberOfPayments={options.setNumberOfPayments}
      />
    );
  }
};

const title = 'Payment Schedule';
const subtitle = 'When you will be receiving your payments for this invoice';
const headers = ['Installment', 'Due Date', 'Amount'];

const scheduleTriggerMap = {
  WhenReceived: 'When invoice is received',
  BeforeSession: 'Before the session',
  AfterSession: 'After the session',
  OnSessionDate: 'On the session date',
};

const AddPaymentScheduleTemplateModal = ({ invoice, handleHide }) => {
  const [currentPlan, setCurrentPlan] = useState(planOptions[0].value);
  const [retainerAmount, setRetainerAmount] = useState({
    type: 'currency',
    value: 0,
  });
  const [numberOfPayments, setNumberOfPayments] = useState(0);
  const [currentFrequency, setCurrentFrequency] = useState(
    invoice.schedule_trigger !== 'WhenReceived'
      ? {
          value: invoice.schedule_trigger,
        }
      : frequencyOptions[0]
  );
  const [rows, setRows] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [basedOn, setBasedOn] = useState(
    invoice.schedule_trigger !== 'WhenReceived' ? 'based_on_session' : null
  );

  const schemaInputContainer =
    basedOn == 'based_on_session'
      ? {
          name: 'schedule',
          value: 'OnSessionDate',
          type: 'schedule_interval',
          editable: true,
        }
      : {
          name: 'due_date',
          value: '',
          type: 'interval',
          editable: true,
        };

  const schema = [
    { name: 'order', value: '', type: 'number' },
    schemaInputContainer,
    { name: 'amount', value: '', type: 'toggle_amount', editable: true },
  ];

  const generateTableRows = () => {
    if (currentPlan === 'custom') {
      return [{}];
    }

    let hasRetainer;
    let numberOfRows;
    if (currentPlan === 'retainer_balance') {
      hasRetainer = true;
      numberOfRows = 2;
    } else if (currentPlan === 'retainer_multiple') {
      hasRetainer = true;
      numberOfRows = numberOfPayments + 1;
    } else {
      hasRetainer = false;
      numberOfRows = numberOfPayments;
    }

    let totalToApply = Number.parseFloat(invoice.total).toFixed(2);
    const generatedRows = [...Array(numberOfRows)].map((_, i) => {
      const order = i + 1;
      let amount;
      let due_date;

      if (hasRetainer && i === 0) {
        if (retainerAmount.type === 'currency') {
          amount = retainerAmount.value;
        } else if (retainerAmount.type === 'percent') {
          amount = (invoice.total * retainerAmount.value).toFixed(2);
        }

        due_date = scheduleTriggerMap[invoice.schedule_trigger];
      } else if (i === 0) {
        amount = Number.parseFloat(totalToApply / (numberOfRows - i)).toFixed(
          2
        );
        due_date = scheduleTriggerMap[invoice.schedule_trigger];
      } else {
        amount = Number.parseFloat(totalToApply / (numberOfRows - i)).toFixed(
          2
        );
        due_date = frequencyLabels[currentFrequency.display];
      }

      totalToApply = Number.parseFloat(totalToApply - amount).toFixed(2);

      // just in case the retainer amount given is > the invoice total
      if (amount < 0) {
        amount = 0;
      }

      const initialDisplayValue = () => {
        const getValues = () => {
          if (invoice.schedule_time_offset % 365 === 0) {
            return {
              interval: 365,
              offset: invoice.schedule_time_offset / 365,
            };
          } else if (invoice.schedule_time_offset % 30 === 0) {
            return { interval: 30, offset: invoice.schedule_time_offset / 30 };
          } else if (invoice.schedule_time_offset % 7 === 0) {
            return { interval: 7, offset: invoice.schedule_time_offset / 7 };
          } else {
            return { interval: 1, offset: invoice.schedule_time_offset };
          }
        };

        if (
          invoice.schedule_trigger == 'OnSessionDate' ||
          invoice.schedule_trigger == 'WhenReceived'
        ) {
          return `${scheduleTriggerMap[invoice.schedule_trigger]}`;
        } else {
          const intervalMap = {
            1: 'day(s)',
            7: 'week(s)',
            30: 'month(s)',
            365: 'year(s)',
          };

          return `${scheduleTriggerMap[invoice.schedule_trigger]} by ${
            getValues().offset
          } ${intervalMap[getValues().interval]}`;
        }
      };

      const inputContainer =
        basedOn == 'based_on_session'
          ? {
              name: 'schedule',
              value:
                i === 0
                  ? initialDisplayValue()
                  : scheduleTriggerMap.OnSessionDate,
              type: 'schedule_interval',
              editable: true,
            }
          : {
              name: 'due_date',
              value: due_date,
              type: 'interval',
              editable: true,
            };

      const extractTwoIfExists = obj => {
        if (obj.value.includes('2')) {
          return 2;
        } else {
          return 1;
        }
      };

      const row = {
        id: order,
        editable: true,
        data: [
          { name: 'order', value: order, type: 'number' },
          inputContainer,
          {
            name: 'amount',
            value: amount,
            type: 'toggle_amount',
            editable: true,
          },
        ],
        rowAsObject: {
          amount,
          percent_amount: null,
          interval_amount:
            i == 0
              ? invoice.schedule_time_offset
              : extractTwoIfExists(currentFrequency),
          interval:
            basedOn == 'based_on_invoice'
              ? currentFrequency.value.replace('2', '')
              : i == 0
              ? invoice.schedule_trigger
              : 'OnSessionDate',
        },
      };

      if (invoice.schedule_trigger) {
        row.data[1].interval =
          i === 0 ? invoice.schedule_trigger : 'OnSessionDate';

        if (
          invoice.schedule_trigger !== 'WhenReceived' ||
          invoice.schedule_trigger !== 'OnSessionDate'
        ) {
          row.data[1].interval_amount = +invoice.schedule_time_offset;
        }
      }

      return row;
    });

    setRows(generatedRows);
  };

  const handleCreate = params => {
    const order = rows.length + 1;

    let amount;
    if (params.percent_amount !== undefined) {
      amount = Number.parseFloat(
        (Number.parseFloat(invoice.total) *
          Number.parseFloat(params.percent_amount)) /
          100
      ).toFixed(2);
    } else {
      amount = Number.parseFloat(params.amount.replace(/,/g, '')).toFixed(2);
    }

    let due_date;
    if (params.interval_amount !== undefined) {
      due_date = `${params.interval_amount} ${params.interval}(s) later`;
    }

    let createDisplayValue;

    if (basedOn == 'based_on_session') {
      if (
        params.schedule_trigger == 'OnSessionDate' ||
        params.schedule_trigger == 'WhenReceived'
      ) {
        createDisplayValue = `${scheduleTriggerMap[params.schedule_trigger]}`;
      } else {
        const intervalMap = {
          1: 'day(s)',
          7: 'week(s)',
          30: 'month(s)',
          365: 'year(s)',
        };

        createDisplayValue = `${
          scheduleTriggerMap[params.schedule_trigger]
        } by ${params.schedule_offset} ${
          intervalMap[params.schedule_interval]
        }`;
      }
    }

    const getIntervalAndIntervalAmount = () => {
      if (basedOn == 'based_on_session') {
        return {
          intervalAmount:
            (params.schedule_offset || 0) * (params.schedule_interval || 0),
          interval: params.schedule_trigger,
        };
      } else {
        return {
          intervalAmount: params.interval_amount,
          interval: params.interval,
        };
      }
    };

    const inputContainer =
      basedOn == 'based_on_session'
        ? {
            name: 'schedule',
            value: createDisplayValue,
            type: 'schedule_interval',
            editable: true,
          }
        : {
            name: 'due_date',
            value: due_date,
            type: 'interval',
            editable: true,
          };

    const newRow = {
      id: order,
      editable: true,
      data: [
        { name: 'order', value: order, type: 'number' },
        inputContainer,
        {
          name: 'amount',
          value: amount,
          type: 'toggle_amount',
          editable: true,
        },
      ],
      rowAsObject: {
        amount,
        base_payments_on: basedOn,
        percent_amount: params.percent_amount
          ? params.percent_amount / 100
          : undefined,
        interval_amount: getIntervalAndIntervalAmount().intervalAmount,
        interval: getIntervalAndIntervalAmount().interval,
      },
    };

    newRow.data[1].interval = getIntervalAndIntervalAmount().interval;
    newRow.data[1].interval_amount = getIntervalAndIntervalAmount().intervalAmount;

    setRows(rows.concat([newRow]));
  };

  const handleUpdate = (order, params) => {
    let amount;
    if (params.percent_amount !== undefined) {
      amount = Number.parseFloat(
        (Number.parseFloat(invoice.total) *
          Number.parseFloat(params.percent_amount)) /
          100
      ).toFixed(2);
    } else {
      amount = Number.parseFloat(params.amount.replace(/,/g, '')).toFixed(2);
    }

    let displayValue;

    if (basedOn == 'based_on_invoice') {
      displayValue = `${params.interval_amount} ${params.interval}(s) later`;
    } else {
      if (
        params.schedule_trigger == 'OnSessionDate' ||
        params.schedule_trigger == 'WhenReceived'
      ) {
        displayValue = `${scheduleTriggerMap[params.schedule_trigger]}`;
      } else {
        const intervalMap = {
          1: 'day(s)',
          7: 'week(s)',
          30: 'month(s)',
          365: 'year(s)',
        };

        displayValue = `${scheduleTriggerMap[params.schedule_trigger]} by ${
          params.schedule_offset
        } ${intervalMap[params.schedule_interval]}`;
      }
    }

    const getIntervalAndIntervalAmount = () => {
      if (basedOn == 'based_on_session') {
        return {
          intervalAmount:
            (params.schedule_offset || 0) * (params.schedule_interval || 0),
          interval: params.schedule_trigger,
        };
      } else {
        return {
          intervalAmount: params.interval_amount,
          interval: params.interval,
        };
      }
    };

    const rowToUpdate = rows[order - 1];
    rowToUpdate.data[1].value = displayValue;
    rowToUpdate.data[2].value = amount;
    rowToUpdate.data[1].interval = getIntervalAndIntervalAmount().interval;
    rowToUpdate.data[1].interval_amount = getIntervalAndIntervalAmount().intervalAmount;

    rowToUpdate.rowAsObject = {
      amount,
      percent_amount: params.percent_amount
        ? params.percent_amount / 100
        : undefined,
      interval_amount: getIntervalAndIntervalAmount().intervalAmount,
      interval: getIntervalAndIntervalAmount().interval,
    };

    setRows([...rows]);
  };

  const handleDelete = order => {
    const newRows = rows.filter(row => row.id !== order);
    setRows(newRows);
  };

  const priceAdjuster = () => {
    let totalPercentage = 0;
    let totalInstallmentAmount = 0;
    let oneCentTooMany = false;
    let oneCentTooLittle = false;
    let updatedRow = rows[0];

    rows.map(row => {
      totalPercentage = totalPercentage + row.rowAsObject.percent_amount;
      totalInstallmentAmount =
        totalInstallmentAmount + Number(row.rowAsObject.amount);
    });

    if (totalPercentage == 1) {
      if ((totalInstallmentAmount - invoice.total).toFixed(2) == 0.01) {
        oneCentTooMany = true;
      }
      if ((invoice.total - totalInstallmentAmount).toFixed(2) == 0.01) {
        oneCentTooLittle = true;
      }
    }

    if (oneCentTooMany) {
      updatedRow.data[2].value = Number(
        updatedRow.data[2].value - 0.01
      ).toFixed(2);
      updatedRow.rowAsObject.amount = (updatedRow.rowAsObject.amount - 0.01)
        .toFixed(2)
        .toString();
      updatedRow.rowAsObject.percent_amount =
        updatedRow.rowAsObject.amount / invoice.total;

      let newRows = rows.map(item => (item.id === 1 ? updatedRow : item));
      setRows(newRows);
    } else if (oneCentTooLittle) {
      updatedRow.data[2].value = Number(updatedRow.data[2].value) + 0.01;

      updatedRow.rowAsObject.amount = (
        Number(updatedRow.rowAsObject.amount) + 0.01
      )
        .toFixed(2)
        .toString();
      updatedRow.rowAsObject.percent_amount =
        updatedRow.rowAsObject.amount / invoice.total;

      let newRows = rows.map(item => (item.id === 1 ? updatedRow : item));
      setRows(newRows);
    }
  };

  useEffect(() => {
    if (rows.length > 1) {
      let row1 = !rows[0].rowAsObject.interval;
      let row2 = !rows[1].rowAsObject.interval;
      if (row1 || row2) {
        setDisabled(true);
      } else setDisabled(false);
      priceAdjuster();
    }
  }, [rows]);

  useEffect(() => {
    if (
      currentPlan === 'retainer_balance' &&
      retainerAmount.value &&
      currentFrequency.value
    ) {
      generateTableRows();
    } else if (
      currentPlan === 'retainer_multiple' &&
      retainerAmount.value &&
      numberOfPayments &&
      currentFrequency.value
    ) {
      generateTableRows();
    } else if (
      currentPlan === 'equal' &&
      numberOfPayments &&
      currentFrequency.value
    ) {
      generateTableRows();
    } else {
      setRows([]);
    }
  }, [
    currentPlan,
    retainerAmount.value,
    retainerAmount.type,
    numberOfPayments,
    currentFrequency,
  ]);

  if (basedOn == 'based_on_session') {
    rows.forEach(row => {
      switch (row.rowAsObject.interval) {
        case 'OnSessionDate':
          row.rowAsObject.sortInterval = 0;
          return row;
        case 'BeforeSession':
          row.rowAsObject.sortInterval = row.rowAsObject.interval_amount * -1;
          return row;
        case 'AfterSession':
          row.rowAsObject.sortInterval = row.rowAsObject.interval_amount;
          return row;
        default:
          return row;
      }
    });
  }

  const sortedRows = rows.sort((rowA, rowB) => {
    if (basedOn == 'based_on_session') {
      if (rowA.rowAsObject.sortInterval < rowB.rowAsObject.sortInterval) {
        return -1;
      } else {
        return 1;
      }
    } else {
      return rowA.id > rowB.id ? 1 : -1;
    }
  });

  const orderCorrectedRows = sortedRows.map((row, index) => {
    const order = index + 1;
    row.id = order;
    row.data[0].value = order;

    return row;
  });

  const installmentsSum = orderCorrectedRows.reduce((a, b) => {
    const firstAddend = isNaN(a) ? Number.parseFloat(a.rowAsObject.amount) : a;
    const secondAddend = isNaN(b) ? Number.parseFloat(b.rowAsObject.amount) : b;
    return Number.parseFloat((firstAddend + secondAddend).toFixed(2));
  }, 0);
  const calculationError =
    Number.parseFloat(installmentsSum).toFixed(2) !==
    Number.parseFloat(invoice.total).toFixed(2);

  return (
    <Modal
      title="Add Payment Schedule"
      handleHide={() => handleHide(null)}
      maxWidth={940}
    >
      <div className={styles.AddPaymentScheduleTemplateModal}>
        <div>
          <Dropdown
            name="type_of_payment_plan"
            labelText="Type of Payment Plan"
            options={planOptions}
            initialValue={currentPlan}
            onChangeCallback={value => {
              setCurrentPlan(value);
              setRetainerAmount({ type: 'currency', value: 0 });
              setNumberOfPayments('');
              setRows([]);
            }}
          />

          {invoice.schedule_trigger === 'WhenReceived' && (
            <Dropdown
              name="based_on_session"
              labelText="Due Date Association"
              options={[
                { label: 'Please choose one', value: null },
                {
                  label: 'Relative to session date',
                  value: 'based_on_session',
                },
                {
                  label: 'Relative to invoice issue date',
                  value: 'based_on_invoice',
                },
              ]}
              initialValue={null}
              onChangeCallback={val => {
                if (val == 'Please choose one') {
                  setBasedOn(null);
                } else {
                  setBasedOn(val);
                }
                if (val === 'based_on_session') {
                  setCurrentFrequency({
                    value: invoice.schedule_trigger,
                  });
                }
              }}
            />
          )}
          {currentPlan !== 'custom' &&
            getPlanFields({
              currentPlan,
              retainerAmount,
              setRetainerAmount,
              numberOfPayments,
              setNumberOfPayments: value => {
                setNumberOfPayments(+value);
              },
            })}
        </div>
        <div>
          {currentPlan !== 'custom' && basedOn == 'based_on_invoice' && (
            <Dropdown
              name="payment_frequency"
              labelText="Payment Frequency"
              options={frequencyOptions}
              initialValue={currentFrequency.value}
              onChangeCallback={value => {
                setCurrentFrequency(
                  frequencyOptions.find(option => option.value === value)
                );
              }}
            />
          )}
        </div>
      </div>
      {(rows.length > 0 || (currentPlan === 'custom' && basedOn !== null)) && (
        <div className={styles.AddPaymentScheduleTemplateModalTableContainer}>
          <Table
            headers={headers}
            schema={schema}
            rows={orderCorrectedRows}
            title={title}
            subtitle={subtitle}
            handleCreate={handleCreate}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
            invoiceTemplateScheduleTrigger={invoice.schedule_trigger}
          />
          {calculationError && (
            <p
              className={
                styles['AddPaymentScheduleTemplateModal-calculationError']
              }
            >
              * The sum of these installments (
              {toCurrencyAmount(installmentsSum)}) does not add up to the total
              amount due ({toCurrencyAmount(invoice.total)}) for this invoice.
            </p>
          )}
          {disabled && (
            <p
              className={
                styles['AddPaymentScheduleTemplateModal-calculationError']
              }
            >
              * Please ensure you choose an interval (day, week, month, year).
            </p>
          )}
        </div>
      )}
      <div className={styles.AddPaymentScheduleTemplateModalButtons}>
        <Button text="Cancel" danger onClick={() => handleHide(null)} />
        <Button
          text="Finish"
          disabled={calculationError || rows.length === 0 || disabled}
          onClick={() => {
            const installments = rows.map(({ rowAsObject }) => ({
              amount: rowAsObject.amount,
              interval: rowAsObject.interval,
              interval_amount: rowAsObject.interval_amount,
              percent_amount: rowAsObject.percent_amount,
            }));

            axios
              .post(routes.PAYMENT_SCHEDULE_TEMPLATES.CREATE, {
                payment_schedule_template: {
                  studio_id: invoice.studio_id,
                  name: 'Default',
                  base_payments_on: basedOn,
                  payment_schedule_installment_templates_attributes: installments,
                },
              })
              .then(res => {
                handleHide(res.data.data.attributes);
              });
          }}
        />
      </div>
    </Modal>
  );
};

export default AddPaymentScheduleTemplateModal;
