import React, { useState } from 'react';
import moment from 'moment';
import { DATE_KEY_FORMAT } from '~constants/datetime';
import { uniqueId } from '~utils/misc';
import { useWindowSize } from '~hooks';
import { MOBILE_WIDTH } from '~constants/viewports';
import { isMobile } from '~constants/userAgent';
import CalendarCell from '../BookingCalendarCell';
import AvailabilitySummary from '../AvailabilitySummary';
import styles from '../BookingCalendar.module.scss';

const MonthView = ({
  dateContext,
  availabilities,
  onClickCallback,
  timeZone,
}) => {
  const [activeSlot, setActiveSlot] = useState(null);
  const currentDate = moment();
  const windowWidth = useWindowSize()[0];
  const mobileScreen = windowWidth <= MOBILE_WIDTH || isMobile();
  const dateContextClone = { ...dateContext };
  const startDate = moment(dateContextClone).startOf('week');
  const days = [...Array(7).keys()].map(i => {
    let weekday = moment(startDate).format('dd');
    startDate.add(1, 'day');
    return (
      <div key={`weekday-${i}`} className={styles['BookingCalendar-weekday']}>
        {weekday}
      </div>
    );
  });

  const renderCells = () => {
    const mutableDateContext = { ...dateContext };
    const monthStart = moment(mutableDateContext).startOf('month');
    const monthEnd = moment(mutableDateContext).endOf('month');
    let startDate = moment(monthStart).startOf('week');
    let endDate = moment(monthEnd).endOf('week');
    let baseMoment = moment();
    let days = [];
    let rows = [];

    // We need to loop until the end of the month.
    while (startDate <= endDate) {
      // Each week is 7 days, so we go up to 7 in this loop to construct a row.
      for (let i = 0; i < 7; i++) {
        let current = currentDate.isSame(startDate, 'day');
        let dayClone = moment(startDate).clone();
        const dayKey = moment(startDate).clone().format(DATE_KEY_FORMAT);
        const availabilitiesForDay = availabilities.filter(
          av => av.dateKey === dayKey
        );

        // When we use startOf and endOf, the hour gets set to midnight
        // so we need to set the hour and minute again.
        dayClone.set({
          hour: baseMoment.get('hour'),
          minute: baseMoment.get('minute'),
        });

        days.push(
          <CalendarCell
            timeZone={timeZone}
            day={dayClone}
            current={current}
            availabilities={availabilitiesForDay}
            key={uniqueId('Cell_')}
            setActiveSlot={setActiveSlot}
            activeSlot={activeSlot}
            onClickCallback={onClickCallback}
          />
        );
        startDate = moment(startDate).add(1, 'day');
      }

      // Push at least 4 rows into the calendar. This will be at least 4 rows worth of days
      // for the month.
      rows.push(
        <div className={styles['BookingCalendar-row']} key={`row-${startDate}`}>
          {days}
        </div>
      );

      days = []; // Clear out the days array so we can start the next row.
    }

    return <>{rows}</>;
  };

  const renderSlots = () => {
    const days = availabilities.map(av => av.dateKey);
    const dedupedDays = [...new Set(days)].sort(
      (a, b) => new Date(a) - new Date(b)
    );

    return (
      <>
        {availabilities.length ? (
          <>
            {dedupedDays.map(day => (
              <AvailabilitySummary
                day={day}
                availabilities={availabilities.filter(av => av.dateKey === day)}
                activeSlot={activeSlot}
                setActiveSlot={setActiveSlot}
                onClickCallback={onClickCallback}
                timeZone={timeZone}
                key={uniqueId('AvailabilityGroup_')}
              />
            ))}
          </>
        ) : (
          <p>No slots for this month.</p>
        )}
      </>
    );
  };

  return (
    <>
      {mobileScreen ? (
        <div className={styles['BookingCalendar-mobileSlots']}>
          {renderSlots()}
        </div>
      ) : (
        <>
          <div className={styles['BookingCalendar-weekdays']}>{days}</div>
          {renderCells()}
        </>
      )}
    </>
  );
};

export default MonthView;
