import React, { useState } from 'react';
import styles from './ClientNavbar.module.scss';

const ClientNavbar = props => {
  const { clientId } = props;

  const [currentUrlPath, setCurrentUrlPath] = useState(
    window.location.pathname
  );

  const CLIENT_PATHS = {
    details: `/clients/${clientId}`,
    activity: `/clients/${clientId}/activities`,
    workflows: `/clients/${clientId}/journeys`,
    toDos: `/clients/${clientId}/to-dos`,
    sessions: `/clients/${clientId}/sessions`,
    invoices: `/clients/${clientId}/invoices`,
    documents: `/clients/${clientId}/documents`,
    galleries: `/clients/${clientId}/galleries`,
  };

  return (
    <div className={styles.ClientNavbar}>
      <ul>
        <li
          className={
            currentUrlPath === CLIENT_PATHS.details
              ? styles['ClientNavbar-active']
              : ''
          }
        >
          <a
            href={CLIENT_PATHS.details}
            onClick={() => setCurrentUrlPath(CLIENT_PATHS.details)}
          >
            <span className="fa fa-user"></span>
            Details
          </a>
        </li>
        <li
          className={
            currentUrlPath === CLIENT_PATHS.activity
              ? styles['ClientNavbar-active']
              : ''
          }
        >
          <a
            href={CLIENT_PATHS.activity}
            onClick={() => setCurrentUrlPath(CLIENT_PATHS.activity)}
          >
            <span className="fa fa-bullseye"></span>
            Activity
          </a>
        </li>
        <li
          className={
            currentUrlPath === CLIENT_PATHS.workflows
              ? styles['ClientNavbar-active']
              : ''
          }
        >
          <a
            href={CLIENT_PATHS.workflows}
            onClick={() => setCurrentUrlPath(CLIENT_PATHS.workflows)}
          >
            <span className="fa fa-tasks"></span>
            Workflows
          </a>
        </li>
        <li
          className={
            currentUrlPath === CLIENT_PATHS.toDos
              ? styles['ClientNavbar-active']
              : ''
          }
        >
          <a
            href={CLIENT_PATHS.toDos}
            onClick={() => setCurrentUrlPath(CLIENT_PATHS.toDos)}
          >
            <span className="far fa-check-square"></span>
            To-Dos
          </a>
        </li>
        <li
          className={
            currentUrlPath === CLIENT_PATHS.sessions
              ? styles['ClientNavbar-active']
              : ''
          }
        >
          <a
            href={CLIENT_PATHS.sessions}
            onClick={() => setCurrentUrlPath(CLIENT_PATHS.sessions)}
          >
            <span className="fa fa-camera"></span>
            Sessions
          </a>
        </li>
        <li
          className={
            currentUrlPath === CLIENT_PATHS.invoices
              ? styles['ClientNavbar-active']
              : ''
          }
        >
          <a
            href={CLIENT_PATHS.invoices}
            onClick={() => setCurrentUrlPath(CLIENT_PATHS.invoices)}
          >
            <span className="fa fa-dollar-sign"></span>
            Invoices
          </a>
        </li>
        <li
          className={
            currentUrlPath === CLIENT_PATHS.documents
              ? styles['ClientNavbar-active']
              : ''
          }
        >
          <a
            href={CLIENT_PATHS.documents}
            onClick={() => setCurrentUrlPath(CLIENT_PATHS.documents)}
          >
            <span className="fa fa-file-alt"></span>
            Documents
          </a>
        </li>
        <li
          className={
            currentUrlPath === CLIENT_PATHS.galleries
              ? styles['ClientNavbar-active']
              : ''
          }
        >
          <a
            href={CLIENT_PATHS.galleries}
            onClick={() => setCurrentUrlPath(CLIENT_PATHS.galleries)}
          >
            <span className="fa fa-image"></span>
            Galleries
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ClientNavbar;
