import React, { useState } from 'react';
import AvailabilityCalendarRoot from '~components/AvailabilityCalendar/AvailabilityCalendarRoot';
import { Button, TextInput, TextArea, Dropdown, Toggle } from '~ui';
import styles from './BookingInvitationModal.module.scss';
import bookingSetupStyles from '../BookingSetup/BookingSetup.module.scss';
import TogglePopover from '~components/common/TogglePopover';

const SelectAvailabilityPage = ({
  initialBookingCalendar,
  calendar,
  sessionTypes,
  journeyTemplates,
  contracts,
  locations,
  invoiceTemplates,
  handleSave,
  isClient,
  hasShootProofIntegration,
  hasPicTimeIntegration,
  shootProofBrands,
}) => {
  const [bookingCalendar, setBookingCalendar] = useState(
    initialBookingCalendar
  );

  const getSchedule = leadDays => {
    if (leadDays === 0) return { interval: 1, number: 1 };

    if (leadDays % 30 === 0) {
      return { interval: 30, number: leadDays / 30 };
    } else if (leadDays % 7 === 0) {
      return { interval: 7, number: leadDays / 7 };
    } else {
      return { interval: 1, number: leadDays };
    }
  };

  const [leadInterval, setLeadInterval] = useState(
    getSchedule(initialBookingCalendar.lead_days).interval
  );
  const [leadDays, setLeadDays] = useState(
    getSchedule(initialBookingCalendar.lead_days).number
  );

  const [showLeadTime, setShowLeadTime] = useState(
    initialBookingCalendar.lead_days > 0
  );

  const saveDisabled = () => {
    if (
      bookingCalendar.contract_required &&
      !+bookingCalendar.contract_template_id &&
      bookingCalendar.invoice_required &&
      !+bookingCalendar.invoice_template_id
    ) {
      return true;
    } else if (
      bookingCalendar.contract_required &&
      !+bookingCalendar.contract_template_id
    ) {
      return true;
    } else if (
      bookingCalendar.invoice_required &&
      !+bookingCalendar.invoice_template_id
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <AvailabilityCalendarRoot
        bookingCalendar={bookingCalendar}
        calendar={calendar}
        isClient={isClient}
      />
      <div className={styles['BookingInvitationModal-form']}>
        <div className={bookingSetupStyles['BookingSetup-formColumns']}>
          <div className="w-50p">
            <TextInput
              labelText="Booking Calendar Title"
              name="title"
              className={bookingSetupStyles['BookingSetup-title']}
              initialValue={bookingCalendar.title}
              onChangeCallback={title =>
                setBookingCalendar({ ...bookingCalendar, title })
              }
              required
            />
          </div>
          <div className={bookingSetupStyles['BookingSetup-leadTime']}>
            <label>Require Lead Time to Book</label>
            <TogglePopover
              activator={<i className="fas fa-info-circle" />}
              popoverContent={
                <div style={{ height: '60px' }}>
                  Prevent last minute bookings by requiring sessions to be
                  completed at least X days in advance.
                </div>
              }
            />
            <div
              className={bookingSetupStyles['BookingSetup-leadTime-content']}
            >
              <Toggle
                initialValue={bookingCalendar.lead_days > 0}
                onChangeCallback={setShowLeadTime}
              />
              {showLeadTime && (
                <>
                  <TextInput
                    name="number"
                    type="number"
                    onFocusSelectText
                    characterLimit={1}
                    initialValue={leadDays}
                    onChangeCallback={setLeadDays}
                  />
                  <Dropdown
                    name="interval"
                    initialValue={leadInterval}
                    onChangeCallback={setLeadInterval}
                    options={[
                      { label: 'Day(s)', value: 1 },
                      { label: 'Week(s)', value: 7 },
                      { label: 'Month(s)', value: 30 },
                    ]}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <TextArea
          name="introduction"
          labelText="Introduction"
          initialValue={bookingCalendar.introduction || ''}
          onChangeCallback={introduction =>
            setBookingCalendar({ ...bookingCalendar, introduction })
          }
        />
        <div className={styles['BookingInvitationModal-formColumns']}>
          <Dropdown
            options={sessionTypes}
            name="session_type_id"
            labelText="Session Type"
            className="mr-24 mx-w-50p"
            initialValue={bookingCalendar.session_type_id || ''}
            onChangeCallback={session_type_id =>
              setBookingCalendar({ ...bookingCalendar, session_type_id })
            }
          />
          <Dropdown
            options={journeyTemplates}
            name="journey_template_id"
            labelText="Workflow"
            initialValue={bookingCalendar.journey_template_id || ''}
            onChangeCallback={journey_template_id =>
              setBookingCalendar({ ...bookingCalendar, journey_template_id })
            }
          />
        </div>
        {hasPicTimeIntegration && (
          <div className={styles['BookingInvitationModal-formColumns']}>
            <Toggle
              labelText="Create PicTime Project"
              initialValue={bookingCalendar.create_pic_time_project}
              onChangeCallback={create_pic_time_project =>
                setBookingCalendar({
                  ...bookingCalendar,
                  create_pic_time_project,
                })
              }
              className="mr-24 w-100p"
            />
          </div>
        )}
        {hasShootProofIntegration && (
          <div className={styles['BookingInvitationModal-formColumns']}>
            <Toggle
              labelText="Create Shootproof Gallery"
              initialValue={bookingCalendar.create_shoot_proof_gallery}
              onChangeCallback={create_shoot_proof_gallery =>
                setBookingCalendar({
                  ...bookingCalendar,
                  create_shoot_proof_gallery,
                })
              }
              className="mr-24 w-100p"
            />
            {bookingCalendar.create_shoot_proof_gallery && (
              <Dropdown
                name="shoot_proof_brand_id"
                labelText="Shootproof Brand"
                options={shootProofBrands}
                onChangeCallback={brandId =>
                  setBookingCalendar({
                    ...bookingCalendar,
                    shoot_proof_brand_id: brandId,
                  })
                }
                initialValue={String(bookingCalendar.shoot_proof_brand_id)}
              />
            )}
          </div>
        )}
        <div className={styles['BookingInvitationModal-formColumns']}>
          <Dropdown
            options={locations}
            labelText="Saved Locations"
            name="location_id"
            initialValue={bookingCalendar.location_id || ''}
            className="mr-24 mx-w-50p"
            onChangeCallback={location_id =>
              setBookingCalendar({ ...bookingCalendar, location_id })
            }
          />
          <TextInput
            labelText="New Location"
            name="full_address"
            className="w-100p"
            initialValue={bookingCalendar.full_address || ''}
            onChangeCallback={full_address =>
              setBookingCalendar({ ...bookingCalendar, full_address })
            }
          />
        </div>

        <TextArea
          name="notes"
          labelText="Notes"
          initialValue={bookingCalendar.notes || ''}
          onChangeCallback={notes =>
            setBookingCalendar({ ...bookingCalendar, notes })
          }
        />
        <div className={styles['BookingInvitationModal-formColumns']}>
          <Toggle
            labelText="Allow Coupon Codes"
            initialValue={bookingCalendar.coupons_allowed}
            onChangeCallback={coupons_allowed =>
              setBookingCalendar({ ...bookingCalendar, coupons_allowed })
            }
          />
        </div>
        <p>
          To require your client to pay an invoice or sign a contract for their
          session prior to completing their booking, make your selections here.
        </p>
        <div className={styles['BookingInvitationModal-formColumns']}>
          <div className={styles['BookingInvitationModal-formRows']}>
            <Toggle
              labelText="Require Contract"
              initialValue={bookingCalendar.contract_required}
              onChangeCallback={contract_required =>
                setBookingCalendar({ ...bookingCalendar, contract_required })
              }
            />
            <Dropdown
              disabled={!bookingCalendar.contract_required}
              options={contracts}
              initialValue={bookingCalendar.contract_template_id || ''}
              onChangeCallback={contract_template_id =>
                setBookingCalendar({
                  ...bookingCalendar,
                  contract_template_id,
                })
              }
              name="contract_template_id"
              className="mt-18"
              required
            />
          </div>
          <div className={styles['BookingInvitationModal-formRows']}>
            <div className={bookingSetupStyles['BookingSetup-invoice']}>
              <label>Require Invoice</label>
              <TogglePopover
                activator={<i className="fas fa-info-circle" />}
                popoverContent={
                  <div style={{ height: '60px' }}>
                    Don’t see your invoice template? Make sure the ‘First
                    Payment Due’ on your invoice template is set to ‘When
                    Invoice is Issued’.
                  </div>
                }
              />
              <Toggle
                // labelText="Require Invoice"
                initialValue={bookingCalendar.invoice_required}
                onChangeCallback={invoice_required =>
                  setBookingCalendar({ ...bookingCalendar, invoice_required })
                }
              />
              <Dropdown
                disabled={!bookingCalendar.invoice_required}
                options={invoiceTemplates}
                initialValue={bookingCalendar.invoice_template_id || ''}
                onChangeCallback={invoice_template_id =>
                  setBookingCalendar({
                    ...bookingCalendar,
                    invoice_template_id,
                  })
                }
                name="invoice_template_id"
                className="mt-18"
                required
              />
            </div>
          </div>
        </div>

        <div className={styles['BookingInvitationModal-formButton']}>
          <Button
            disabled={saveDisabled()}
            text="Save and Continue"
            onClick={() =>
              handleSave(bookingCalendar, leadDays, leadInterval, showLeadTime)
            }
          />
        </div>
      </div>
    </>
  );
};

export default SelectAvailabilityPage;
