import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal as BootstrapModal } from 'react-bootstrap';

import styles from './modal.module.scss';

const { Header, Title, Body, Footer } = BootstrapModal;

class Modal extends React.PureComponent {
  static propTypes = {
    children: PropTypes.func,
    show: PropTypes.bool,
    titleProps: PropTypes.object,
    title: PropTypes.string,
    buttonLabel: PropTypes.string,
    buttonProps: PropTypes.object,
    renderButton: PropTypes.func, // used to optionally render a custom component for the open button
    modalProps: PropTypes.object,
    onClose: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = { show: props.show };
  }

  openModal = () => {
    this.setState({ show: true });
  };

  closeModal = () => {
    const { onClose } = this.props;
    if (onClose) {
      const success = onClose();
      if (success === false) {
        return;
      }
    }
    this.setState({ show: false });
  };

  render() {
    const { modalProps, buttonProps, titleProps, renderButton } = this.props;
    const close = this.closeModal;
    const open = this.openModal;

    return (
      <React.Fragment>
        {renderButton ? (
          renderButton(open)
        ) : (
          <Button
            bsStyle="primary"
            bsSize="large"
            onClick={open}
            {...(buttonProps || {})}
          >
            {this.props.buttonLabel}
          </Button>
        )}

        <BootstrapModal
          show={this.state.show}
          onHide={close}
          backdrop="static"
          keyboard={false}
          {...(modalProps || {})}
        >
          <Header className={styles.modalHeader}>
            <Title {...(titleProps || {})}>{this.props.title}</Title>
            <a onClick={close} className={styles.closeModal}>
              X
            </a>
          </Header>

          <Body>{this.props.children({ closeModal: this.closeModal })}</Body>

          <Footer>
            {/* TODO This is where the "Save & Continue" Button needs to go. */}
          </Footer>
        </BootstrapModal>
      </React.Fragment>
    );
  }
}

export default Modal;
