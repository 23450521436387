export const toCurrencyAmount = amount => {
  const currency = window.app.invoices.currency;
  if (amount === '' || amount === undefined) {
    return '-';
  }

  return `${currency}${Number(amount).toFixed(2)}`;
};

export const currencyIcon = () => {
  const currency = window.app.invoices.currency;

  switch (currency) {
    case '£':
      return 'pound-sign';
    case '€':
      return 'euro-sign';
    default:
      return 'dollar-sign';
  }
};

export const toDollarAmount = amount => `$${Number(amount).toFixed(2)}`;

export const formatCurrencyInput = stringValue => {
  const valueArray = stringValue.replace(/[^0-9]+/g, '').split('');
  valueArray.splice(valueArray.length - 2, 0, '.');
  return Number(valueArray.join(''));
};
