import React, { useState } from 'react';
import { Modal, Button, Icon } from '~ui';
import { absolutePath, routes } from '~constants/routes';
import styles from '../../../Documents/DocumentsContent/DocumentsContent.module.scss';
import axios from 'axios';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { MOBILE_WIDTH } from '~constants/viewports';
import { isMobile } from '~constants/userAgent';
import { useWindowSize } from '~hooks';
import { useSelector } from 'react-redux';
import SendLimitReached from '../../../common/SendLimitReached';

const ClientContractsButtons = ({ contract }) => {
  const [showResendModal, setShowResendModal] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const windowWidth = useWindowSize()[0];
  const mobileScreen = windowWidth <= MOBILE_WIDTH || isMobile();
  const { activeTrial, studioId, trialVerified } = useSelector(
    state => state.documents
  );
  const [showBeforeSend, setShowBeforeSend] = useState(false);
  const [miniLoading, setMiniLoading] = useState(false);

  const checkValidation = async () => {
    if (activeTrial && !trialVerified) {
      setMiniLoading(true);

      const res = await axios.get(
        routes.STUDIO_PROFILE.CHECK_SEND_LIMIT(studioId)
      );
      if (res.data.reached_send_limit) {
        setMiniLoading(false);

        setShowBeforeSend(true);
      } else {
        setMiniLoading(false);

        setShowResendModal(true);
      }
    } else {
      setShowResendModal(true);
    }
  };

  const handleResend = async () => {
    try {
      setResendLoading(true);
      await axios.put(routes.CONTRACTS.RESEND(contract.id));
      setShowResendModal(false);
      toast.success('Contract Sent!', toastOptions);
    } catch (error) {
      setResendLoading(false);
      console.error(error);
      if (error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Error Sending the Contract', toastOptions);
      }
    }
  };

  return (
    <>
      <a
        onClick={e => {
          e.stopPropagation();
        }}
        target="_blank"
        href={absolutePath(`/customer/contracts/${contract.uuid}.pdf`)}
      >
        {mobileScreen ? 'Print' : 'Print PDF'}
      </a>
      <a
        onClick={e => {
          e.stopPropagation();
          checkValidation();
        }}
      >
        Resend
      </a>
      <a
        onClick={e => {
          e.stopPropagation();
          setShowViewModal(true);
        }}
      >
        View
      </a>
      {showResendModal && (
        <Modal
          title="Resend Contract"
          handleHide={() => setShowResendModal(false)}
        >
          <div>
            <div>
              Are you sure you'd like to resend this contract to{' '}
              {contract.client_name}?
            </div>
            <div className={styles.DocumentModalButtonContainer}>
              <Button
                text="Cancel"
                danger
                onClick={() => setShowResendModal(false)}
              />
              <Button
                text="Send"
                onClick={handleResend}
                disabled={resendLoading}
              />
            </div>
          </div>
        </Modal>
      )}
      {showViewModal && (
        <Modal title="View Contract" handleHide={() => setShowViewModal(false)}>
          <div dangerouslySetInnerHTML={{ __html: contract.contract_body }} />
        </Modal>
      )}
      {miniLoading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingText}>
            <p>Loading...</p>
            <Icon name="spinner" className="fa-pulse" large />
          </div>
        </div>
      )}
      {showBeforeSend && (
        <SendLimitReached handleHide={() => setShowBeforeSend(false)} />
      )}
    </>
  );
};

export default ClientContractsButtons;
