import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './PercentageInput.module.scss';
import { getLocale } from '~utils/locale';
import { keyCodes } from '~constants/keyCodes';

const VALID_NEXT = /^[0-9]{1}$/;

const PercentageInput = props => {
  const {
    characterLimit,
    disabled,
    hideIcon,
    initialValue,
    inline,
    labelText,
    name,
    onChangeCallback,
    required,
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
  } = props;
  const hasCharacterLimit = !!characterLimit;
  const cx = classNames.bind(styles);
  const classes = cx('PercentageInput', {
    'PercentageInput--hideIcon': hideIcon,
    'PercentageInput--disabled': disabled,
    'PercentageInput--inline': inline,
    [`PercentageInput--limit-${characterLimit}`]: hasCharacterLimit,
  });

  const [value, setValue] = useState(
    String(Number(String(initialValue || 0)).toFixed(maximumFractionDigits + 2))
  );

  const handleKeyDown = useCallback(
    ({ key, keyCode }) => {
      let newValue;
      if (keyCode === keyCodes.DELETE) {
        newValue = Number.parseFloat(value.substring(0, value.length - 1)) / 10;
      } else if (VALID_NEXT.test(key)) {
        newValue = Number.parseFloat(`${value}${key}`) * 10;
      } else {
        return;
      }

      const rounded = String(newValue.toFixed(maximumFractionDigits + 2));
      if (rounded <= 1) {
        newValue = String(newValue.toFixed(maximumFractionDigits + 2));
        setValue(newValue);
        onChangeCallback(newValue);
      }
    },
    [value]
  );

  const handleChange = useCallback(() => {}, []);

  const valueLocaleString = Number.parseFloat(value, 10).toLocaleString(
    getLocale(),
    {
      style: 'percent',
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits,
    }
  );
  const valueDisplay = valueLocaleString.substring(
    0,
    valueLocaleString.length - 1
  );

  return (
    <div className={classes}>
      {labelText && (
        <label htmlFor={name}>
          {labelText}
          {required && <span>*</span>}
        </label>
      )}
      {!hideIcon && (
        <span className={styles['PercentageInput-percentage']}>%</span>
      )}
      <input
        inputMode="numeric"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={valueDisplay}
        required={required}
        disabled={disabled}
        name={name}
      />
    </div>
  );
};

PercentageInput.propTypes = {
  disabled: PropTypes.bool,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  onChangeCallback: PropTypes.func,
  inline: PropTypes.bool,
};

PercentageInput.defaultProps = {
  disabled: false,
  initialValue: '0.0000',
  required: false,
  labelText: undefined,
  onChangeCallback: () => {},
  inline: false,
};

export default PercentageInput;
