import React, { useEffect, useState } from 'react';
import { Modal, Button } from '~ui';
import styles from './ApplyToExistingModal.module.scss';
import axios from 'axios';
import { routes } from '~constants/routes';
import { toast, toastOptions } from '~constants/toasts';

const ApplyToExistingModal = ({
  handleHide,
  journeys,
  journeyTemplateId,
  setJourneys,
}) => {
  const [loading, setLoading] = useState(false);

  const handleApplyToExisting = async () => {
    try {
      setLoading(true);
      await axios.put(routes.JOURNEYS.APPLY_TO_EXISTING, {
        journey: { journey_template_id: journeyTemplateId },
      });
      setLoading(false);
      setJourneys([]);
      handleHide();
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error('There was a problem applying your changes', toastOptions);
      handleHide();
    }
  };

  return (
    <Modal title="Apply Changes to Existing Workflows" handleHide={handleHide}>
      <div>
        Applying these changes will affect {journeys.length} workflows. Are you
        sure you want to apply these changes to these existing workflows?
      </div>
      <div className={styles['ApplyToExistingModal-buttonGroup']}>
        <Button
          disabled={loading}
          text={'Cancel'}
          onClick={handleHide}
          danger
        />
        <Button
          disabled={loading}
          text={'Apply Changes'}
          onClick={handleApplyToExisting}
        />
      </div>
    </Modal>
  );
};

export default ApplyToExistingModal;
