import React from 'react';
import { Modal, Button } from '~ui';

const DeleteConfirmation = props => {
  const { handleHide, handleDelete, name, resource, second_message } = props;
  return (
    <Modal title="Are you sure?" handleHide={handleHide}>
      <div className="mb-8">
        Would you like to delete the {!!name && `"${name}"`} {resource}?
      </div>
      {second_message && (
        <div style={{ marginTop: '32px', marginBottom: '8px', fontStyle: 'italic' }}>
          This {resource} is on a workflow and will be removed from the workflow steps.
        </div>
      )}
      <br />
      <div className="d-fr">
        <Button
          className="mr-8"
          text="Cancel"
          onClick={e => {
            e.stopPropagation();
            handleHide();
          }}
          danger
        />
        <Button
          text="Delete"
          onClick={e => {
            e.stopPropagation();
            handleDelete();
            handleHide();
          }}
        />
      </div>
    </Modal>
  );
};

export default DeleteConfirmation;
