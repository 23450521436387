import React, { useState } from 'react';
import styles from './LeadsPageShare.module.scss';
import { TextInput, Button, TextArea } from '~ui';
import { absolutePath } from '~constants/routes';

const LeadsPageShare = props => {
  const { meta } = props;
  const captureURL = absolutePath(`/customer/leads/new?ref=${meta.studio_id}`);
  const leadsHTML = `<iframe allowtransparency="true" src="${absolutePath(
    `/customer/leads/new?ref=${meta.studio_id}`
  )}" width="100%" height="850px" frameBorder="0"></iframe>`;

  const [captureCopyText, setCaptureCopyText] = useState('Copy');
  const [HTMLCopyText, setHTMLCopyText] = useState('Copy');

  return (
    <div className={styles.LeadsPageShare}>
      <h2>Using the Leads Page</h2>
      <label>
        To automatically capture Leads within Iris, add this URL to a page or a
        button on your website:
      </label>
      <div className={styles['LeadsPageShare-copyContainer']}>
        <TextInput disabled initialValue={captureURL} name="URL" />
        <Button
          text={captureCopyText}
          onClick={() => {
            navigator.clipboard.writeText(captureURL);
            setCaptureCopyText('Copied!');
            setTimeout(() => setCaptureCopyText('Copy'), 1500);
          }}
        />
      </div>
      <hr />
      <label>
        Or insert the following HTML to an existing web page to render your lead
        form:
      </label>
      <div className={styles['LeadsPageShare-copyContainer2']}>
        <TextArea disabled initialValue={leadsHTML} name="HTML" />
        <Button
          text={HTMLCopyText}
          onClick={() => {
            navigator.clipboard.writeText(leadsHTML);
            setHTMLCopyText('Copied!');
            setTimeout(() => setHTMLCopyText('Copy'), 1500);
          }}
        />
      </div>
    </div>
  );
};

export default LeadsPageShare;
