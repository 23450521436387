import {
  Modal,
  TextArea,
  TextInput,
  Button,
  Dropdown,
  Checkbox,
  DocumentUploader,
} from '~ui';
import styles from './LocationsModal.module.scss';
import axios from 'axios';
import React from 'react';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';

const LocationsModal = ({
  handleHide,
  countryOptions,
  locationInfo,
  handleSubmit,
  nameError,
  cityError,
  stateError,
  countryError,
  zipError,
  addressError,
  fullAddressError,
}) => {
  const [stateOptions, setStateOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    id: null,
    title: '',
    description: '',
    zip: '',
    address: '',
    city: '',
    state: '',
    country: '',
    latitude: '',
    longitude: '',
    is_shared: '',
    picture_url: '',
    urlComparison: '',
    picture_file_name: '',
  });

  useEffect(() => {
    if (locationInfo && locationInfo.country) {
      handleCountryDropdownChange(locationInfo.country);
      setFormData({
        id: locationInfo.id,
        title: locationInfo.title,
        description: locationInfo.description,
        zip: locationInfo.zip,
        address: locationInfo.address,
        city: locationInfo.city,
        state: locationInfo.state,
        country: locationInfo.country,
        latitude: locationInfo.latitude,
        longitude: locationInfo.longitude,
        is_shared: locationInfo.is_shared,
        picture_url: locationInfo.picture_url,
        urlComparison: locationInfo.picture_url,
        picture_file_name: locationInfo.picture_file_name,
      });
    }
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [
    nameError,
    cityError,
    stateError,
    countryError,
    zipError,
    addressError,
    fullAddressError,
  ]);

  const handleCountryDropdownChange = alpha2 => {
    setFormData({ ...formData, country: alpha2 });
    axios
      .get(`/api/states/${alpha2}`)
      .then(res => {
        let options = res.data.map(state => ({
          label: state[1],
          value: state[0],
        }));
        options = [{ label: 'Choose a State/Province', value: null }].concat(
          options
        );
        setStateOptions(options);
      })
      .catch(error => {
        const errorMessage = `Error fetching states for ${alpha2}: ${error.response.data.error.messages}`;
        toast.error(errorMessage, toastOptions);
        Rollbar.error(`${errorMessage}: ${error}`);
      });
  };

  const submit = () => {
    setLoading(true);
    handleSubmit(formData, handleHide);
  };

  return (
    <Modal
      handleHide={handleHide}
      title={locationInfo ? 'Edit Location' : 'New Location'}
    >
      <div name="row">
        <div name="col1" className={styles.LocationsModal}>
          <div>
            <TextInput
              onChangeCallback={value =>
                setFormData({ ...formData, title: value })
              }
              labelText="Location name"
              initialValue={locationInfo ? locationInfo.title : formData.title}
              required
              name="location name"
            />
          </div>
          {nameError && (
            <p className={styles['LocationsModal-error']}>
              Please enter a location name
            </p>
          )}
          <div>
            <TextInput
              onChangeCallback={value =>
                setFormData({ ...formData, address: value })
              }
              labelText="Address"
              initialValue={
                locationInfo ? locationInfo.address : formData.address
              }
              required
              name="address"
            />
          </div>
          {addressError && (
            <p className={styles['LocationsModal-error']}>
              Please enter a valid address
            </p>
          )}
          <div>
            <TextInput
              onChangeCallback={value =>
                setFormData({ ...formData, city: value })
              }
              labelText="City"
              initialValue={locationInfo ? locationInfo.city : formData.city}
              required
              name="city"
            />
          </div>
          {cityError && (
            <p className={styles['LocationsModal-error']}>
              Please enter a valid city
            </p>
          )}
          <div>
            <TextInput
              onChangeCallback={value =>
                setFormData({ ...formData, zip: value })
              }
              labelText="Zip"
              initialValue={locationInfo ? locationInfo.zip : formData.zip}
              required
              name="zip"
            />
          </div>
          {zipError && (
            <p className={styles['LocationsModal-error']}>
              Please enter a valid zip code
            </p>
          )}
          <div>
            <Dropdown
              name="country"
              options={countryOptions}
              initialValue={
                locationInfo ? locationInfo.country : formData.country
              }
              labelText="Country"
              required
              onChangeCallback={value => {
                handleCountryDropdownChange(value);
              }}
            />
          </div>
          {countryError && (
            <p className={styles['LocationsModal-error']}>
              Please select a country
            </p>
          )}
          <div>
            <Dropdown
              name="state_province"
              options={stateOptions}
              initialValue={locationInfo ? locationInfo.state : formData.state}
              onChangeCallback={value =>
                setFormData({ ...formData, state: value })
              }
              labelText="State"
              required
              disabled={stateOptions.length === 0}
            />
          </div>
          {stateError && (
            <p className={styles['LocationsModal-error']}>
              Please select a state
            </p>
          )}

          <div>
            <label>Description</label>
            <TextArea
              onChangeCallback={value =>
                setFormData({ ...formData, description: value })
              }
              initialValue={
                locationInfo ? locationInfo.description : formData.description
              }
              name="description"
            />
          </div>
          <div>
            {formData.picture_file_name && (
              <img
                src={formData.picture_url}
                alt="Banner image"
                width="auto"
                height={180}
              ></img>
            )}
            <DocumentUploader
              readAs="readAsDataURL"
              onChange={fileData => {
                setFormData({
                  ...formData,
                  picture_file_name: fileData.fileName,
                  picture_url: fileData.dataString,
                });
              }}
              onError={error => {
                toast.error(error.message, toastOptions);
              }}
            />
          </div>
          <div>
            <Checkbox
              labelText="Shared?"
              checked={locationInfo ? locationInfo.is_shared : false}
              onChangeCallback={value =>
                setFormData({ ...formData, is_shared: value })
              }
              name="share options"
            />
          </div>
          <div className={styles['LocationsModal-footer']}>
            <div className={styles['LocationsModal-footer-buttonGroup']}>
              <Button danger onClick={handleHide} text="Cancel" />
              {!loading && (
                <Button
                  onClick={submit}
                  text={locationInfo ? 'Save Location' : 'Create Location'}
                  loading={loading}
                />
              )}
              {loading && (
                <Button
                  onClick={submit}
                  text={'Please wait'}
                  loading={loading}
                  disabled
                />
              )}
            </div>
          </div>
          {fullAddressError && (
            <p className={styles['LocationsModal-error']}>
              The address given doesn't exist
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default LocationsModal;
