import React, { useState, useEffect, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import CalendarHeader from './AvailabilityCalendarHeader';
import WeekView from './WeekView';
import styles from './Calendar.module.scss';
import { listCalendarEvents } from '~actions/calendarEvents';
import { listBookingAvailabilities } from '~actions/bookingAvailabilities';
import { updateDSTDates } from '~actions/calendar';
import { DATE_KEY_FORMAT } from '~constants/datetime';

const AvailabilityCalendar = ({ isClient }) => {
  const timeZone = useSelector(state => state.bookingCalendar.meta.timeZone);
  const bookingCalendarId = useSelector(state => state.bookingCalendar.id);
  const [dateContext, setDateContext] = useState(moment());
  const showAllCalendars = useSelector(
    state => state.bookingCalendar.showAllCalendars
  );
  const debounceTimer = useRef(null);

  const dispatch = useDispatch();

  const getDSTDatesForCurrentAndFutureYears = () => {
    const dstDates = [];
    const currentYear = moment().year();

    for (let year = currentYear; year <= currentYear + 4; year++) {
      const startOfYear = moment.tz(`${year}-01-01T00:00:00`, timeZone);
      const endOfYear = moment.tz(`${year}-12-31T23:59:59`, timeZone);
      let current = startOfYear.clone();

      while (current.isBefore(endOfYear)) {
        const next = current.clone().add(1, 'hour');

        if (current.isDST() !== next.isDST()) {
          const transitionType = next.isDST() ? 'spring forward' : 'fall back';
          dstDates.push({
            date: next.format('YYYY-MM-DD'),
            type: transitionType,
            year: year,
          });

          current = next.clone().add(1, 'day').startOf('day');
        } else {
          current = next;
        }
      }
    }

    dispatch(updateDSTDates(dstDates));
  };

  useEffect(() => {
    getDSTDatesForCurrentAndFutureYears();
  }, []);

  const getAvailabilitiesForInterval = date => {
    let start = moment(date)
      .clone()
      .startOf('week')
      .subtract(1, 'week')
      .format(DATE_KEY_FORMAT);
    let end = moment(date)
      .clone()
      .endOf('week')
      .add(2, 'days')
      .format(DATE_KEY_FORMAT);

    dispatch(
      listBookingAvailabilities({
        booking_availability: {
          booking_calendar_id: bookingCalendarId,
          start,
          end,
          sort: 'hour',
          all: showAllCalendars,
        },
      })
    );

    dispatch(listCalendarEvents({ start, end }));
  };

  const debounceApiCall = newDateContext => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    debounceTimer.current = setTimeout(() => {
      getAvailabilitiesForInterval(newDateContext);
    }, 1000);
  };

  useEffect(() => {
    moment.tz.setDefault(timeZone);
    getAvailabilitiesForInterval(dateContext);
  }, [showAllCalendars]);

  const nextInterval = () => {
    let newDateContext = moment(dateContext).clone().add(1, 'week');

    setDateContext(newDateContext);
    debounceApiCall(newDateContext);
  };

  const prevInterval = () => {
    let newDateContext = moment(dateContext).clone().subtract(1, 'week');

    setDateContext(newDateContext);
    debounceApiCall(newDateContext);
  };

  return (
    <>
      <div className={styles.Calendar}>
        <DndProvider backend={HTML5Backend}>
          <div className={styles['CalendarContent']}>
            <CalendarHeader
              dateContext={dateContext}
              prevInterval={prevInterval}
              nextInterval={nextInterval}
              getAvailabilitiesForInterval={getAvailabilitiesForInterval}
              isClient={isClient}
            />
            <p style={{ textAlign: 'right' }}>
              <i>Double-click to add a time</i>
            </p>
            <WeekView dateContext={dateContext} />
          </div>
        </DndProvider>
      </div>
    </>
  );
};

export default AvailabilityCalendar;
