import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '../../store';
import JourneyTemplate from './JourneyTemplate';

const JourneyTemplateRoot = props => {
  const store = configureStore(props);

  return (
    <Provider store={store}>
      <JourneyTemplate />
    </Provider>
  );
};

export default JourneyTemplateRoot;
