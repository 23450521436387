import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import BookingCalendarLink from './BookingCalendarLink';
import SelectAvailability from './SelectAvailability';
import BookingSetupForm from './BookingSetupForm';
import styles from './BookingSetup.module.scss';
import { absolutePath } from '~constants/routes';
import { Icon } from '~ui';

const BookingSetupRoot = props => {
  const {
    initialBookingCalendar,
    bookingCalendar,
    locations,
    sessionTypes,
    journeyTemplates,
    invoiceTemplates,
    calendar,
    contracts,
    shootProofBrands,
    contractDrawnSignature,
    contractTypedSignature,
  } = props;

  const [currentUrl, setCurrentUrl] = useState(window.location.href);

  const BOOKING_PATHS = {
    root: `/studio/booking_calendars/${bookingCalendar.id}`,
    calendar: `/studio/booking_calendars/${bookingCalendar.id}/calendar`,
    summary: `/studio/booking_calendars/${bookingCalendar.id}/summary`,
  };

  return (
    <Router>
      <div className={styles.BookingSetup}>
        <a href={absolutePath('/studio/booking_setup')}>
          <Icon name="arrow-left" small /> Back to Booking Setup
        </a>
        <br />
        <div className={styles['BookingSetup-header']}>
          <Link
            to={BOOKING_PATHS.root}
            onClick={() => setCurrentUrl(absolutePath(BOOKING_PATHS.root))}
            className={
              currentUrl === absolutePath(BOOKING_PATHS.root)
                ? styles['BookingSetup-activeTab']
                : ''
            }
          >
            Calendar Info
          </Link>
          <Link
            to={BOOKING_PATHS.calendar}
            onClick={() => setCurrentUrl(absolutePath(BOOKING_PATHS.calendar))}
            className={
              currentUrl === absolutePath(BOOKING_PATHS.calendar)
                ? styles['BookingSetup-activeTab']
                : ''
            }
          >
            Select Availability
          </Link>
          <Link
            to={BOOKING_PATHS.summary}
            onClick={() => setCurrentUrl(absolutePath(BOOKING_PATHS.summary))}
            className={
              currentUrl === absolutePath(BOOKING_PATHS.summary)
                ? styles['BookingSetup-activeTab']
                : ''
            }
          >
            Summary
          </Link>
        </div>
        <hr />
        <Switch>
          <Route exact path={BOOKING_PATHS.root}>
            <BookingSetupForm
              bookingCalendar={bookingCalendar}
              initialBookingCalendar={initialBookingCalendar}
              locations={locations}
              sessionTypes={sessionTypes}
              journeyTemplates={journeyTemplates}
              invoiceTemplates={invoiceTemplates}
              contracts={contracts}
              shootProofBrands={shootProofBrands}
              contractDrawnSignature={contractDrawnSignature}
              contractTypedSignature={contractTypedSignature}
            />
          </Route>
          <Route path={BOOKING_PATHS.calendar}>
            <SelectAvailability
              bookingCalendar={bookingCalendar}
              calendar={calendar}
            />
          </Route>
          <Route path={BOOKING_PATHS.summary}>
            <BookingCalendarLink
              bookingCalendar={bookingCalendar}
              initialBookingCalendar={initialBookingCalendar}
              sessionTypes={sessionTypes}
              journeyTemplates={journeyTemplates}
              invoiceTemplates={invoiceTemplates}
              contracts={contracts}
            />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default BookingSetupRoot;
