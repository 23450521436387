import React, { useState } from 'react';
import styles from './Form.module.scss';
import { TextInput, Checkbox, Button, Dropdown } from '~ui';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import axios from 'axios';
import { routes } from '~constants/routes';
import { useHistory } from 'react-router';
import { emailValidation } from '~constants/validation';
import moment from 'moment-timezone';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const Form = ({ setCurrentUser, setLoading, feedbackOptions }) => {
  const history = useHistory();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [emailValid, setEmailValid] = useState(true);

  const [feedbackOption, setFeedbackOption] = useState(0);
  const [feedbackDetails, setFeedbackDetails] = useState('');

  const submitDisabled = ![
    firstName,
    lastName,
    email,
    password,
    companyName,
    agreeToTerms,
    feedbackOption,
    emailValid,
  ].every(Boolean);

  const getDisabled = () => {
    const foundOption = feedbackOptions.find(
      opt => opt.value == feedbackOption
    );

    return foundOption.prompt
      ? !feedbackDetails || submitDisabled
      : submitDisabled;
  };

  const submitForm = async () => {
    if (!executeRecaptcha) {
      return;
    }
    const recaptchaHumanKey = await executeRecaptcha('submit');

    try {
      setLoading(true);
      const formParams = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        company_name: companyName,
        agree_to_terms: agreeToTerms,
        time_zone: moment.tz.guess(),
        recaptcha_human_key: recaptchaHumanKey,
      };

      const feedbackParams = {
        feedback_form_option_id: feedbackOption,
        details: feedbackDetails,
        form_type: 'creation',
      };

      const { data } = await axios.post(routes.SIGNUP.CREATE, {
        signup: {
          form_attributes: formParams,
          feedback_attributes: feedbackParams,
        },
      });

      setCurrentUser(data);
      setLoading(false);
      history.push('/signup/studio_setup');

      //Adds NEW-TRIAL for facebook and google analytics
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'NEW-TRIAL' });
    } catch (error) {
      setLoading(false);
      error.response.data.errors.forEach(error => {
        if (error == 'Password is too short (minimum is 8 characters)') {
          toast.error(error, toastOptions);
        } else {
          toast.error(
            'Error creating account. Please contact support.',
            toastOptions
          );
        }
      });
    }
  };

  return (
    <div className={styles.Form}>
      <h1>Sign up for a free 14 day trial!</h1>
      <p>
        Get Started Absolutely FREE. <strong>No credit card needed</strong>.
      </p>
      <div className={styles['Form-container']}>
        <div className={styles['Form-container-inputs']}>
          <TextInput
            required
            labelText="First Name"
            name="firstName"
            onChangeCallback={setFirstName}
          />
          <TextInput
            required
            labelText="Last Name"
            name="lastName"
            onChangeCallback={setLastName}
          />
        </div>
        <TextInput
          required
          labelText="Email"
          name="email"
          validation={emailValidation}
          onChangeCallback={(value, valid) => {
            setEmail(value);
            setEmailValid(valid);
          }}
        />
        <TextInput
          required
          labelText="Password"
          name="password"
          type="password"
          onChangeCallback={setPassword}
        />
        <TextInput
          required
          labelText="Company Name"
          name="companyName"
          onChangeCallback={setCompanyName}
        />
        <Dropdown
          options={feedbackOptions}
          required
          labelText="How did you hear about us?"
          name="feedback"
          entireObjectPlease
          onChangeCallback={setFeedbackOption}
        />
        {feedbackOption !== 0 &&
          feedbackOptions.find(opt => opt.value == feedbackOption).prompt && (
            <TextInput
              required
              labelText={
                feedbackOptions.find(opt => opt.value == feedbackOption).prompt
              }
              name="feedbackDetails"
              onChangeCallback={setFeedbackDetails}
            />
          )}
        <div className={styles['Form-container-checkbox']}>
          <Checkbox
            required
            name="agreeToTerms"
            checked={agreeToTerms}
            onChangeCallback={() => {
              setAgreeToTerms(!agreeToTerms);
            }}
          />
          <p>
            By signing up, I agree to Iris Works'{' '}
            <a
              target="_blank"
              href="https://iris-works.com/terms-and-conditions/"
            >
              Terms and Conditions
            </a>{' '}
            and{' '}
            <a target="_blank" href="https://iris-works.com/privacy-policy/">
              Privacy Policy
            </a>
          </p>
        </div>
        <Button
          text={'Start 14-day free trial'}
          onClick={submitForm}
          disabled={getDisabled()}
        />
        <div className={styles['Form-container-cookies']}>
          <p>
            Iris Works utilizes cookies and the above information as outlined in
            our Privacy Policy, in order to:
          </p>
          <ul>
            <li>Optimize your experience as a user</li>
            <li>Store your preferences</li>
            <li>Track company analytics</li>
            <li>Contribute to our company marketing efforts</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Form;
