import React, { useEffect, useState } from 'react';
import styles from './Locations.module.scss';
import { Button, Dropdown, SearchInput, DeleteConfirmation, Icon } from '~ui';
import Table from '~ui/TableNew/Table';
import LocationsModal from './LocationsModal/LocationsModal';
import { routes } from '~constants/routes';
import ToggleContent from '../common/ToggleContent';
import axios from 'axios';
import { MOBILE_WIDTH } from '~constants/viewports';
import LocationsMap from './GoogleMap/LocationsMap';
import { useWindowSize } from '~hooks';
import SendLocationModal from './SendLocationModal/SendLocationModal';
import { useLoadScript } from '@react-google-maps/api';
import SendLimitReached from '../common/SendLimitReached';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';

const Locations = ({
  countryOptions,
  mapsApiKey,
  currentStudio,
  clients,
  markerIcon,
  activeTrial,
  trialVerified,
}) => {
  const [viewQuery, setViewQuery] = useState('');
  const [mapLatLon, setMapLatLon] = useState(null);
  const [studioLatLon, setStudioLatLon] = useState(null);
  const [locations, setLocations] = useState([]);
  const [rows, setRows] = useState([]);
  const [showLocationsEdit, setShowLocationsEdit] = useState(false);
  const [locationForEdit, setLocationForEdit] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [idForDelete, setIdForDelete] = useState();
  const [nameForDelete, setNameForDelete] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [totalRecords, setTotalRecords] = useState();
  const [showShared, setShowShared] = useState(0);
  const [nameError, setNameError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [zipError, setZipError] = useState(false);
  const [fullAddressError, setFullAddressError] = useState(false);
  const [openSendModal, setOpenSendModal] = useState(false);
  const [locationToSend, setLocationToSend] = useState();
  const [mapBounds, setMapBounds] = useState();
  const [showBeforeSend, setShowBeforeSend] = useState(false);
  const [loading, setLoading] = useState(false);

  const [headers, setHeaders] = useState([
    'Image',
    'Location Name',
    'Address',
    'Notes',
  ]);
  const windowWidth = useWindowSize()[0];
  const isMobileWidth = windowWidth < MOBILE_WIDTH;
  useEffect(() => {
    getLocations();
  }, [currentPage, showShared, mapBounds]);

  useEffect(() => {
    setRows(createRowItems());
  }, [locations, isMobileWidth]);

  useEffect(() => {
    if (isMobileWidth) {
      setHeaders(['Location Name', 'Address']);
    } else {
      setHeaders(['Image', 'Location Name', 'Address', 'Notes']);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (rows.length === 0 && currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [rows.length]);

  useEffect(() => {
    if (viewQuery.length > 0) {
      const location = `${viewQuery.trim()}`;
      setMapCenter(location);
    }
  }, [viewQuery]);

  useEffect(() => {
    if (nameError) {
      setTimeout(() => setNameError(false), 10000);
    }
  }, [nameError]);

  useEffect(() => {
    if (countryError) {
      setTimeout(() => setCountryError(false), 10000);
    }
  }, [countryError]);

  useEffect(() => {
    if (stateError) {
      setTimeout(() => setStateError(false), 10000);
    }
  }, [stateError]);

  useEffect(() => {
    if (zipError) {
      setTimeout(() => setZipError(false), 10000);
    }
  }, [zipError]);

  useEffect(() => {
    if (cityError) {
      setTimeout(() => setCityError(false), 10000);
    }
  }, [cityError]);

  useEffect(() => {
    if (addressError) {
      setTimeout(() => setAddressError(false), 10000);
    }
  }, [addressError]);

  useEffect(() => {
    if (fullAddressError) {
      setTimeout(() => setFullAddressError(false), 10000);
    }
  }, [fullAddressError]);

  const checkValidation = async locationInfo => {
    if (activeTrial && !trialVerified) {
      setLoading(true);
      const res = await axios.get(
        routes.STUDIO_PROFILE.CHECK_SEND_LIMIT(currentStudio.id)
      );
      if (res.data.reached_send_limit) {
        setLoading(false);
        setShowBeforeSend(true);
      } else {
        setLoading(false);
        setLocationToSend(locationInfo);
        setOpenSendModal(true);
      }
    } else {
      setLocationToSend(locationInfo);
      setOpenSendModal(true);
    }
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: mapsApiKey,
  });

  useEffect(() => {
    if (!currentStudio.address && isLoaded) {
      setStudioLatLon({ latitude: 'No latitude', longitude: 'No longitude' });
    } else {
      const address = currentStudio.address;
      const city = currentStudio.city;
      const zip = currentStudio.postal;
      const state = currentStudio.state;

      const location = `${address} ${city} ${state} ${zip}`;
      if (isLoaded) {
        setStudioCenter(location);
      }
    }
  }, [currentStudio, isLoaded]);

  const geoCodeLocation = async location => {
    let geocoder;
    let latitude;
    let longitude;

    const request = {
      address: location,
    };

    geocoder = geocoder || new google.maps.Geocoder();
    await geocoder.geocode(request, function (results) {
      if (results === null) return;
      longitude = results[0].geometry.location.lng();
      latitude = results[0].geometry.location.lat();
    });
    return { latitude, longitude };
  };

  const setStudioCenter = async location => {
    try {
      const res = await geoCodeLocation(location);
      const latitude = res.latitude;
      const longitude = res.longitude;

      if (latitude != null) {
        setStudioLatLon({
          latitude,
          longitude,
        });
      }
    } catch (error) {
      return;
    }
  };

  const setMapCenter = async location => {
    try {
      const res = await geoCodeLocation(location);
      const latitude = res.latitude;
      const longitude = res.longitude;

      if (latitude != null) {
        setMapLatLon({
          latitude,
          longitude,
        });
      }
    } catch (error) {
      return;
    }
  };

  const getLocations = async () => {
    try {
      const res = await axios.get(routes.LOCATIONS.INDEX, {
        params: {
          page: currentPage,
          include_shared: showShared,
          bounds: showShared ? mapBounds : undefined,
        },
      });

      const newLocations = res.data.data.map(location => location.attributes);

      setPageCount(res.data.meta.page_count);
      setTotalRecords(res.data.meta.total_records);
      setLocations(newLocations);
    } catch (error) {
      console.log(error);
    }
  };

  const createRowItems = () => {
    const rowItems = locations.map(locationInfo => {
      const {
        id,
        address,
        title,
        description,
        city,
        state,
        picture_url,
        studio_id,
      } = locationInfo;
      const sharedCheck = () => {
        if (currentStudio.id === studio_id) {
          return true;
        } else {
          return false;
        }
      };
      const res = sharedCheck();
      const rowItem = {
        id,
        editable: res,
        deletable: res,
        editButtonOverride: () => {
          setLocationForEdit(locationInfo);
          setShowLocationsEdit(true);
        },
        deleteButtonOverride: () => {
          setNameForDelete(title);
          setIdForDelete(id);
          setShowDeleteConfirmation(true);
        },
        buttonGroup: (
          <>
            <div className={styles.Icons}>
              <i
                className="fas fa-directions"
                onClick={() => {
                  window
                    .open(
                      `https://maps.google.com?q=${address},${city},${state}`,
                      '_blank'
                    )
                    .focus();
                }}
              />
              {res && (
                <i
                  className="fa fa-envelope Icons"
                  onClick={() => {
                    checkValidation(locationInfo);
                  }}
                />
              )}
            </div>
          </>
        ),
        data: {
          ...(!isMobileWidth && {
            image: {
              value:
                picture_url !== 'missing-image-locations.jpg' ? (
                  <img
                    src={picture_url}
                    alt="Location image"
                    width="auto"
                    height={180}
                  ></img>
                ) : (
                  <div style={{ height: '180px' }}></div>
                ),
              type: 'image',
              editable: false,
              deletable: false,
            },
          }),
          title: {
            value: () => (
              <div className={styles['Locations-tableColumn']}>{title}</div>
            ),
            type: 'function',
            editable: false,
            deletable: true,
          },
          address: {
            value: () => (
              <div className={styles['Locations-tableColumn']}>{address}</div>
            ),
            type: 'function',
            editable: false,
            deletable: false,
          },
          ...(!isMobileWidth && {
            description: {
              value: description || <div></div>,
              type: 'string',
              editable: false,
              deletable: false,
            },
          }),
        },
      };
      return rowItem;
    });
    return rowItems;
  };

  const handleDelete = async idForDelete => {
    try {
      await axios.delete(routes.LOCATIONS.INDEX + `/${idForDelete}`);
      getLocations();
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdate = async (
    {
      title,
      description,
      address,
      city,
      state,
      zip,
      is_shared,
      country,
      latitude,
      longitude,
      id,
      picture_url,
      urlComparison,
      picture_file_name,
    },
    handleHide
  ) => {
    let params;
    const location = `${address.trim()} ${city.trim()} ${state.trim()} ${zip.trim()} ${country.trim()}`;

    if (country === '' || country === 'Select a country') {
      setCountryError(true);
      return;
    } else {
      setCountryError(false);
    }
    if (state === '' || state === 'Choose a State/Province') {
      setStateError(true);
      return;
    } else {
      setStateError(false);
    }

    if (title === '') {
      setNameError(true);
      return;
    } else {
      setNameError(false);
    }

    const res = await geoChecker(address, city, zip, state);

    if (res) {
      try {
        const geo = await geoCodeLocation(location);
        if (geo.latitude != null) {
          latitude = geo.latitude;
          longitude = geo.longitude;
          setFullAddressError(false);
        }
      } catch (error) {
        toast.error("Error verifying location. Please contact support.", toastOptions);
        setFullAddressError(true);
        return;
      }
    } else {
      return;
    }

    setMapCenter(location);

    if (picture_url === urlComparison) {
      params = {
        location: {
          title,
          description,
          address,
          city,
          state,
          zip,
          is_shared,
          country,
          latitude,
          longitude,
        },
      };
    } else {
      params = {
        location: {
          title,
          description,
          address,
          city,
          state,
          zip,
          is_shared,
          country,
          latitude,
          longitude,
          picture: picture_url,
          picture_file_name,
        },
      };
    }

    try {
      await axios.put(routes.LOCATIONS.INDEX + `/${id}`, params);
      getLocations();
      handleHide();
    } catch (error) {
      Rollbar.error("Error updating location", error);
      toast.error("Error updating location. Please contact support.", toastOptions);      
      console.error(error);
    }
  };

  const handleNewLocation = async (
    {
      title,
      description,
      address,
      city,
      state,
      zip,
      is_shared,
      country,
      latitude,
      longitude,
      picture_url,
      picture_file_name,
    },
    handleHide
  ) => {
    let params;
    const location = `${address.trim()} ${city.trim()} ${state.trim()} ${zip.trim()} ${country.trim()}`;

    if (country === '' || country === 'Select a country') {
      setCountryError(true);
      return;
    } else {
      setCountryError(false);
    }
    if (state === '' || state === 'Choose a State/Province') {
      setStateError(true);
      return;
    } else {
      setStateError(false);
    }

    if (title === '') {
      setNameError(true);
      return;
    } else {
      setNameError(false);
    }

    const res = await geoChecker(address, city, zip, state);

    if (res) {
      try {
        const geo = await geoCodeLocation(location);
        if (geo.latitude != null) {
          latitude = geo.latitude;
          longitude = geo.longitude;
          setFullAddressError(false);
        }
      } catch (error) {
        toast.error("Error checking location. Please contact support.");
        setFullAddressError(true);
        return;
      }
    } else {
      return;
    }

    setMapCenter(location);

    if (picture_url === '') {
      params = {
        location: {
          title,
          description,
          address,
          city,
          state,
          zip,
          is_shared,
          country,
          latitude,
          longitude,
        },
      };
    } else {
      params = {
        location: {
          title,
          description,
          address,
          city,
          state,
          zip,
          is_shared,
          country,
          latitude,
          longitude,
          picture: picture_url,
          picture_file_name,
        },
      };
    }
    try {
      await axios.post(routes.LOCATIONS.INDEX, params);
      getLocations();
      handleHide();
    } catch (error) {
      Rollbar.error("Error creating location", error);
      toast.error("Error creating location. Please contact support.", toastOptions);
      console.error(error);
    }
  };

  const geoChecker = async (address, city, zip, state) => {
    const cityCheck = `${city.trim()} ${state.trim()}`;
    try {
      const res = await geoCodeLocation(cityCheck);
      if (res.latitude != null) {
        setCityError(false);
      }
    } catch (error) {
      toast.error("Error locating city. Please contact support.", toastOptions);
      setCityError(true);
      return false;
    }

    const addressCheck = `${address.trim()} ${city.trim()} ${state.trim()}`;
    try {
      const res = await geoCodeLocation(addressCheck);
      if (res.latitude != null) {
        setAddressError(false);
      }
    } catch (error) {
      toast.error("Error locating address. Please contact support.", toastOptions);
      setAddressError(true);
      return false;
    }

    const zipCheck = `${zip.trim()}`;
    if (zipCheck.length >= 1) {
      setZipError(false);
    } else {
      setZipError(true);
      return false;
    }

    return true;
  };
  return (
    <>
      <div className={styles.Locations}>
        <div className={styles['Locations-topWrapper']}>
          <div className={styles['Locations-topWrapper-title']}>
            <h1>Location Scout</h1>
          </div>
          <div className={styles['Locations-topWrapper-title']}>
            <ToggleContent
              toggle={handleShow => (
                <Button onClick={handleShow} text="New Location" />
              )}
              content={handleHide => (
                <LocationsModal
                  {...{
                    getLocations,
                    handleHide,
                    countryOptions,
                    nameError,
                    cityError,
                    stateError,
                    zipError,
                    countryError,
                    addressError,
                    fullAddressError,
                  }}
                  handleSubmit={handleNewLocation}
                />
              )}
            />
          </div>
        </div>
        <div className={styles['Locations-topWrapper']}>
          <div className={styles['Locations-topWrapper-dropDownWrapper']}>
            <Dropdown
              name="status"
              initialValue={showShared ? 1 : 0}
              onChangeCallback={val => {
                setShowShared(+val);
              }}
              options={[
                { label: 'My Locations', value: 0 },
                { label: 'Shared Locations', value: 1 },
              ]}
            />
          </div>
          <div className={styles['Locations-topWrapper-searchBarWrapper']}>
            <SearchInput
              name="City search"
              placeholder="Search by city, state, or zip"
              onChangeCallback={value => setViewQuery(value)}
            />
          </div>
        </div>
        <div style={{ width: '100%', height: '405px' }}>
          {locations && (
            <LocationsMap
              locations={locations}
              mapLatLon={mapLatLon}
              studioLatLon={studioLatLon}
              markerIcon={markerIcon}
              isLoaded={isLoaded}
              loadError={loadError}
              setMapBounds={setMapBounds}
              showShared={showShared}
            />
          )}
        </div>
        <div>
          <Table
            readOnly
            title="List View"
            headers={headers}
            rows={rows}
            setRows={setRows}
            page={currentPage}
            pageCount={pageCount}
            totalRecords={totalRecords}
            pageChangeCallback={setCurrentPage}
          />
        </div>
      </div>
      {showLocationsEdit && (
        <LocationsModal
          {...{
            getLocations,
            countryOptions,
            nameError,
            cityError,
            stateError,
            zipError,
            countryError,
            addressError,
            fullAddressError,
          }}
          locationInfo={locationForEdit}
          handleSubmit={handleUpdate}
          handleHide={() => setShowLocationsEdit(false)}
        />
      )}
      {showDeleteConfirmation && (
        <DeleteConfirmation
          handleHide={() => setShowDeleteConfirmation(false)}
          handleDelete={() => handleDelete(idForDelete)}
          name={nameForDelete}
          resource="Location"
        />
      )}
      {openSendModal && (
        <SendLocationModal
          handleHide={() => setOpenSendModal(false)}
          {...{
            locationToSend,
            openSendModal,
            clients,
            currentStudio,
          }}
        />
      )}
      {showBeforeSend && (
        <SendLimitReached handleHide={() => setShowBeforeSend(false)} />
      )}
      {loading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingText}>
            <p>Loading...</p>
            <Icon name="spinner" className="fa-pulse" large />
          </div>
        </div>
      )}
    </>
  );
};
export default Locations;
