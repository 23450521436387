import axios from 'axios';
import { routes } from '~constants/routes';
import {
  CLIENT_LIST,
  CLIENTS,
  GROUPS,
  DELETE_CONFIRMATION,
} from '~constants/actionTypes';

export const getAllClients = () => async (dispatch, getState) => {
  try {
    const { meta } = getState().clientList;
    const {
      status,
      page,
      search_term,
      dateAddedSortDirection,
      alphabeticalSort,
      companyAlpabeticalSort,
      itemsPerPage,
    } = meta;

    dispatch({ type: CLIENT_LIST.GET.REQUEST, payload: status });

    let params = {
      status,
      page,
      search_term,
      date_added_sort_direction: dateAddedSortDirection,
      alphabetical_sort: alphabeticalSort,
      company_alphabetical_sort: companyAlpabeticalSort,
      items_per_page: itemsPerPage,
    };

    const { data } = await axios.get(routes.CLIENTS.GET, { params });

    dispatch({
      type: CLIENT_LIST.GET.SUCCESS,
      payload: { data: data.data, meta: { ...data.meta } },
    });
  } catch (error) {
    dispatch({
      type: CLIENT_LIST.GET.FAILURE,
      payload: error.message,
      error: true,
    });
  }
};

export const bulkUpdate = ({ updateAction, clientIds }) => async dispatch => {
  try {
    dispatch({ type: CLIENT_LIST.BULK_UPDATE.REQUEST });

    await axios.put(routes.CLIENTS.BULK_UPDATE, {
      update_action: updateAction,
      client_ids: clientIds,
    });

    dispatch({
      type: CLIENT_LIST.BULK_UPDATE.SUCCESS,
    });
    return { res: 'success', errors: null };
  } catch (error) {
    dispatch({
      type: CLIENT_LIST.BULK_UPDATE.FAILURE,
      payload: error.message,
      error: true,
    });
    return { res: 'error', errors: error };
  }
};

export const addClient = params => async dispatch => {
  try {
    dispatch({ type: CLIENTS.CREATE.REQUEST });

    const { data } = await axios.post(routes.CLIENTS.CREATE, {
      client: params,
    });

    dispatch({ type: CLIENTS.CREATE.SUCCESS, payload: { attributes: data } });
    return { res: 'success', errors: null, id: data.id };
  } catch (error) {
    dispatch({
      type: CLIENTS.CREATE.FAILURE,
      payload: error.message,
      error: true,
    });
    return { res: 'error', errors: error };
  }
};

export const deleteClient = clientId => async dispatch => {
  try {
    dispatch({ type: CLIENTS.DELETE.REQUEST });

    await axios.delete(routes.CLIENTS.DELETE(clientId));

    dispatch({ type: CLIENTS.DELETE.SUCCESS, payload: { id: clientId } });

    return { res: 'success', errors: null };
  } catch (error) {
    dispatch({
      type: CLIENTS.DELETE.FAILURE,
      payload: error.message,
      error: true,
    });
    return { res: 'error', errors: error };
  }
};

export const updateClient = (clientId, params) => async dispatch => {
  try {
    dispatch({ type: CLIENTS.UPDATE.REQUEST });

    const client = await axios.put(routes.CLIENTS.UPDATE(clientId), {
      client: params,
    });

    dispatch({
      type: CLIENTS.UPDATE.SUCCESS,
      payload: client.data.data.attributes,
    });

    return { res: 'success', errors: null };
  } catch (error) {
    dispatch({
      type: CLIENTS.UPDATE.FAILURE,
      payload: error.message,
      error: true,
    });
    return { res: 'error', errors: error };
  }
};

export const getGroups = () => async (dispatch, getState) => {
  try {
    const { meta } = getState().clientList;
    const { page, search_term, itemsPerPage } = meta;

    dispatch({ type: GROUPS.GET.REQUEST });

    let params = {
      page,
      search_term,
      items_per_page: itemsPerPage,
    };

    const { data } = await axios.get(routes.GROUPS.GET, { params });

    dispatch({
      type: GROUPS.GET.SUCCESS,
      payload: { data: data.data, meta: { ...data.meta } },
    });
  } catch (error) {
    dispatch({
      type: GROUPS.GET.FAILURE,
      payload: error.message,
      error: true,
    });
  }
};

export const bulkUpdateGroups = ({
  updateAction,
  clientIds,
  groupIds,
}) => async dispatch => {
  try {
    dispatch({ type: GROUPS.BULK_UPDATE.REQUEST });

    await axios.put(routes.GROUPS.BULK_UPDATE, {
      update_action: updateAction,
      client_ids: clientIds,
      group_ids: groupIds,
    });

    dispatch({
      type: GROUPS.BULK_UPDATE.SUCCESS,
    });
    return { res: 'success', errors: null };
  } catch (error) {
    dispatch({
      type: GROUPS.BULK_UPDATE.FAILURE,
      payload: error.message,
      error: true,
    });
    return { res: 'error', errors: error };
  }
};

export const addGroup = params => async dispatch => {
  try {
    dispatch({ type: GROUPS.CREATE.REQUEST });
    const { data } = await axios.post(routes.GROUPS.CREATE, {
      group: params,
    });

    dispatch({ type: GROUPS.CREATE.SUCCESS, payload: data.data });
    return { res: 'success', errors: null };
  } catch (error) {
    dispatch({
      type: GROUPS.CREATE.FAILURE,
      payload: error.message,
      error: true,
    });
    return { res: 'error', errors: error };
  }
};

export const deleteGroup = groupId => async dispatch => {
  try {
    dispatch({ type: GROUPS.DELETE.REQUEST });

    await axios.delete(routes.GROUPS.DELETE(groupId));

    dispatch({ type: GROUPS.DELETE.SUCCESS, payload: { id: groupId } });

    return { res: 'success', errors: null };
  } catch (error) {
    dispatch({
      type: GROUPS.DELETE.FAILURE,
      payload: error.message,
      error: true,
    });
    return { res: 'error', errors: error };
  }
};

export const updateGroup = (groupId, params) => async dispatch => {
  try {
    dispatch({ type: GROUPS.UPDATE.REQUEST });

    const group = await axios.put(routes.GROUPS.PUT(groupId), {
      group: params,
    });

    dispatch({
      type: GROUPS.UPDATE.SUCCESS,
      payload: group.data.data.attributes,
    });

    return { res: 'success', errors: null };
  } catch (error) {
    dispatch({
      type: GROUPS.UPDATE.FAILURE,
      payload: error.message,
      error: true,
    });
    return { res: 'error', errors: error };
  }
};

export const updateClientListMeta = params => ({
  type: CLIENT_LIST.META.UPDATE,
  payload: params,
});

export const updateIdsForDelete = params => ({
  type: DELETE_CONFIRMATION.META.UPDATE,
  payload: params,
});
