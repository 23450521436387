import React from 'react';
import PendingSessions from './PendingSessions';
import UpcomingSessions from './UpcomingSessions/UpcomingSessions';
import { Accordion } from '~ui';
import styles from './SessionHolder.module.scss';

const SessionHolder = props => {
  return (
    <div className={styles.SessionHolder}>
      <div className={styles['SessionHolder-accordion']}>
        <Accordion title="Upcoming Sessions" expanded={true}>
          <UpcomingSessions client_id={props.client_id} limitToThree />
        </Accordion>
      </div>
      {props.bookingEnabled && (
        <div className={styles['SessionHolder-accordion']}>
          <Accordion title="Pending Sessions" expanded={false}>
            <PendingSessions
              calendar={props.calendar}
              client={props.client}
              locations={props.locations}
              sessionTypes={props.sessionTypes}
              journeyTemplates={props.journeyTemplates}
              invoiceTemplates={props.invoiceTemplates}
              contracts={props.contracts}
              emailTemplates={props.emailTemplates}
              locationVariables={props.locationVariables}
              timeZone={props.timeZone}
            />
          </Accordion>
        </div>
      )}
    </div>
  );
};

export default SessionHolder;
