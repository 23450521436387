import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Icon } from '~ui';
import TogglePopover from '~components/common/TogglePopover';
import {
  updateShowDeleteModal,
  updateShowEditModal,
  updateActiveEvent,
} from '~actions/calendar';
import { TIME_SLOT_HEIGHT } from '~constants/calendar';
import CalendarEventSummary from '../CalendarEventSummary';
import styles from './CalendarEvent.module.scss';

const CalendarEvent = props => {
  const { event, tall, setHeight, customStyles } = props;
  const { title, start_time, end_time, all_day, status } = event;
  const timeZone = useSelector(state => state.calendar.meta.timeZone);
  const dstDates = useSelector(state => state.calendar.dstDates);
  const monthName = moment(start_time).format('MMMM');

  const isDSTDate = () => {
    if (dstDates === undefined) return false;

    const momentDate = moment(start_time);
    const formattedDate = momentDate.format('YYYY-MM-DD');

    const transition = dstDates.find(t => t.date === formattedDate);
    if (transition) {
      return true;
    } else {
      return false;
    }
  };

  const november = monthName == 'November';
  const march = monthName == 'March';
  const subtractInteger =
    isDSTDate() && november ? 1 : isDSTDate() && march ? -1 : 0;

  const timezonesAreSame =
    moment().tz(moment.tz.guess()).format('z') ==
    moment().tz(timeZone).format('z');

  const formatString = timezonesAreSame ? 'h:mm a' : 'h:mm a (z)';

  const formattedStartTime = moment
    .utc(start_time)
    .tz(timeZone)
    .format(formatString);
  const cx = classNames.bind(styles);
  const classes = cx('CalendarEvent', {
    'CalendarEvent--tall': tall,
  });
  const dispatch = useDispatch();

  const getStyles = () => {
    let newStyles = { ...customStyles };
    newStyles.backgroundColor =
      status === 'needs_rescheduled' ? styles.cinnabarRed : event.session_color;
    newStyles.color = event.textColor;

    if (setHeight) {
      const timeDifference =
        moment(end_time).diff(moment(start_time), 'minutes') / 60;
      const duration = all_day ? 0.5 : timeDifference;
      const relativeDistanceFromMidnight =
        (60 +
          moment(start_time).diff(
            moment(start_time).startOf('day'),
            'minutes'
          )) /
        60;
      const offsetStart = all_day
        ? 0
        : relativeDistanceFromMidnight - subtractInteger;

      newStyles.height = `${duration * TIME_SLOT_HEIGHT}px`;

      if (!all_day) {
        newStyles.top = `${offsetStart * TIME_SLOT_HEIGHT}px`;
      }
    }

    return newStyles;
  };

  const openEditModal = event => {
    dispatch(updateActiveEvent(event));
    dispatch(updateShowEditModal(true));
  };

  const openDeleteModal = event => {
    dispatch(updateActiveEvent(event));
    dispatch(updateShowDeleteModal(true));
  };

  const controls = [
    <a
      onClick={() => openEditModal(event)}
      onKeyPress={() => openEditModal(event)}
    >
      <i tabIndex={0} className="fa fa-pencil-alt" />
    </a>,
    <a
      onClick={() => openDeleteModal(event)}
      onKeyPress={() => openDeleteModal(event)}
    >
      <i tabIndex={0} className="fa fa-trash-alt" />
    </a>,
  ];

  return (
    <TogglePopover
      activator={
        <div className={classes} style={getStyles()}>
          <div>
            {status === 'needs_rescheduled' && (
              <Icon name="exclamation-triangle" small />
            )}{' '}
            {!all_day && formattedStartTime} {title}
          </div>
        </div>
      }
      popoverContent={<CalendarEventSummary event={event} />}
      popoverControls={controls}
    />
  );
};

CalendarEvent.propTypes = {
  event: PropTypes.object.isRequired,
  tall: PropTypes.bool,
  setHeight: PropTypes.bool,
};

CalendarEvent.defaultProps = {
  tall: false,
  setHeight: false,
};

export default CalendarEvent;
