import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { TIME_SLOT_HEIGHT } from '~constants/calendar';
import styles from './CalendarEvent.module.scss';

const CalendarEvent = props => {
  const { event, customStyles } = props;
  const { start_time, end_time, all_day } = event;
  const formattedStartTime = moment(start_time).format('h:mm a');
  const slotInterval = useSelector(state => state.bookingCalendar.slotInterval);
  const dstDates = useSelector(state => state.calendar.dstDates);
  const timeZone = useSelector(state => state.bookingCalendar.meta.timeZone);

  const timeSlotHeightMultiplier = 60 / +slotInterval;

  const isDSTDate = () => {
    if (dstDates === undefined) return false;

    const momentDate = moment(start_time);
    const formattedDate = momentDate.format('YYYY-MM-DD');

    const transition = dstDates.find(t => t.date === formattedDate);
    if (transition) {
      return true;
    } else {
      return false;
    }
  };

  const isAfterDSTTransition = () => {
    const date = moment.tz(start_time, timeZone);
    const yearOfStartTime = date.year();

    const getRelevantTransition = (type, year) => {
      const transition = dstDates.find(t => t.type === type && t.year === year);
      return transition;
    };

    const springForward = getRelevantTransition(
      'spring forward',
      yearOfStartTime
    );
    const fallBack = getRelevantTransition('fall back', yearOfStartTime);

    const startTimeAfterSpringForward =
      springForward && date.isAfter(moment.tz(springForward.date, timeZone));
    const startTimeAfterFallBack =
      fallBack && date.isAfter(moment.tz(fallBack.date, timeZone));

    return {
      startTimeAfterSpringForward,
      startTimeAfterFallBack,
    };
  };

  const calculateOffset = () => {
    const {
      startTimeAfterSpringForward,
      startTimeAfterFallBack,
    } = isAfterDSTTransition();

    if (startTimeAfterSpringForward && startTimeAfterFallBack) {
      return -1;
    }
    if (startTimeAfterSpringForward && !startTimeAfterFallBack) {
      return +1;
    }
  };

  const getStyles = () => {
    let newStyles = {
      ...customStyles,
      opacity: 0.4,
      cursor: 'not-allowed',
    };
    newStyles.backgroundColor = event.session_color;
    newStyles.color = event.textColor;

    const timeDifference =
      moment(end_time).diff(moment(start_time), 'minutes') / 60;
    const duration = all_day ? 0.5 : timeDifference;
    let relativeDistanceFromMidnight =
      (+slotInterval +
        moment(start_time).diff(moment(start_time).startOf('day'), 'minutes')) /
      60;

    const startTimeIsDSTDate = isDSTDate();

    if (startTimeIsDSTDate) {
      const val = calculateOffset();
      relativeDistanceFromMidnight += val;
    }

    const offsetStart = all_day ? 0 : relativeDistanceFromMidnight;

    if (all_day) {
      newStyles.height = `${duration * TIME_SLOT_HEIGHT}px`;
    } else {
      newStyles.height = `${
        duration * (TIME_SLOT_HEIGHT * timeSlotHeightMultiplier)
      }px`;
    }

    if (!all_day) {
      newStyles.top = `${
        offsetStart * TIME_SLOT_HEIGHT * timeSlotHeightMultiplier
      }px`;
    }

    return newStyles;
  };

  return (
    <div className={styles.CalendarEvent} style={getStyles()}>
      <div>
        {!all_day && formattedStartTime} {event.title}
      </div>
    </div>
  );
};

CalendarEvent.propTypes = {
  event: PropTypes.object.isRequired,
};

export default CalendarEvent;
