import React from 'react';
import ClientSessions from './ClientSessions/ClientSessions';

const ClientSessionsRoot = props => {
  const { calendar, client_id, studioCreatedAt } = props;

  return (
    <>
      <ClientSessions
        calendar={calendar}
        client_id={client_id}
        studioCreatedAt={studioCreatedAt}
      />
    </>
  );
};

export default ClientSessionsRoot;
