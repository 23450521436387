import React from 'react';
import classNames from 'classnames/bind';
import { EVENT_HEIGHT } from '~constants/calendar';
import { uniqueId } from '~utils';
import styles from '../TimeSlot/TimeSlot.module.scss';
import CalendarEvent from '../CalendarEvent';
import CronofyEvent from '../CronofyEvent';
import PlaceholderEvent from '../PlaceholderEvent';
import { useSelector, useDispatch } from 'react-redux';
import Availability from '../Availability';
import { updateActiveSlot } from '~actions/bookingCalendar';
import { DATE_KEY_FORMAT } from '~constants/datetime';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import {
  listBookingAvailabilities,
  createBookingAvailability,
} from '~actions/bookingAvailabilities';
import { listCalendarEvents } from '~actions/calendarEvents';
import moment from 'moment';

const AllDayTimeSlot = props => {
  const { events, className, day, availabilities } = props;
  const dispatch = useDispatch();
  const timeZone = useSelector(state => state.bookingCalendar.meta.timeZone);
  const calendarId = useSelector(state => state.bookingCalendar.id);
  const showAllCalendars = useSelector(
    state => state.bookingCalendar.showAllCalendars
  );
  const hasClassName = !!className;
  const cx = classNames.bind(styles);
  const classes = cx('TimeSlot', 'AllDaySlot', {
    [className]: hasClassName,
  });
  const startOfWeek = moment(day).clone().startOf('week');
  const endOfWeek = moment(day).clone().endOf('week');
  const getStyles = event => {
    const start = moment(event.start_time) || moment(event.start.date);
    const end = moment(event.end_time) || moment(event.end.date);
    let width = end.diff(start, 'hours');

    if (end.isAfter(endOfWeek)) {
      width = endOfWeek.diff(start, 'hours') + 24;
    }

    if (start.isBefore(startOfWeek)) {
      width = end.diff(startOfWeek, 'hours');
    }

    if (event.event_uid && width === 0) {
      width = 24;
    }

    return {
      width: `${(width / 24) * 100}%`,
      left:
        !event.is_all_day_event && !event.isOvernight
          ? `${Math.abs(day.startOf('day').diff(start, 'hours') / 24) * 100}%`
          : 0,
    };
  };

  const getAvailabilityStyles = availability => {
    const start = moment(availability.start_time);
    const end = moment(availability.end_time);
    let width = end.diff(start, 'hours');

    if (end.isAfter(endOfWeek)) {
      width = endOfWeek.diff(start, 'hours') + 24;
    }

    if (start.isBefore(startOfWeek)) {
      width = end.diff(startOfWeek, 'hours');
    }

    return {
      width: `${(width / 24) * 100}%`,
      left:
        !availability.all_day && !availability.isOvernight
          ? `${Math.abs(day.startOf('day').diff(start, 'hours') / 24) * 100}%`
          : 0,
    };
  };

  const getAvailabilitiesForInterval = date => {
    let start = moment(date)
      .tz(timeZone)
      .subtract(1, 'week')
      .format(DATE_KEY_FORMAT);
    let end = moment(date)
      .tz(timeZone)
      .add(1, 'week')
      .add(2, 'days')
      .format(DATE_KEY_FORMAT);

    dispatch(
      listBookingAvailabilities({
        booking_availability: {
          booking_calendar_id: calendarId,
          start,
          end,
          sort: 'hour',
          all: showAllCalendars,
        },
      })
    );

    dispatch(listCalendarEvents({ start, end }));
  };

  const createNewSlot = async () => {
    const start = moment(day).startOf('day');

    const requestParams = {
      booking_availability: {
        booking_calendar_id: calendarId,
        start_time: start,
        end_time: moment(day).endOf('day'),
        all_day: true,
      },
    };

    const createRes = await dispatch(createBookingAvailability(requestParams));

    if (createRes.errors) {
      toast.error(createRes.errors);
    } else {
      getAvailabilitiesForInterval(start);
      dispatch(updateActiveSlot(createRes));
    }
  };

  return (
    <div className={classes} onDoubleClick={createNewSlot}>
      {events.map((event, index) => {
        let customStyles = {
          position: 'absolute',
        };

        customStyles.width = getStyles(event).width;
        customStyles.left = getStyles(event).left;
        customStyles.top = `${index * EVENT_HEIGHT}px`;

        if (event.event_uid) {
          const key = uniqueId(`event${event.event_uid}_`);

          return (
            <CronofyEvent event={event} key={key} customStyles={customStyles} />
          );
        }

        if (event.type === 'placeholder') {
          return <PlaceholderEvent key={uniqueId(`placeholder_`)} />;
        }

        return (
          <CalendarEvent
            event={event}
            key={uniqueId(`event${event.id}_`)}
            customStyles={customStyles}
          />
        );
      })}
      {availabilities.map((availability, index) => {
        let customStyles = {
          position: 'absolute',
        };
        // Leaving this in for future reference of formula used

        // const width = `${100 / availabilities.length}%`;
        // const left =
        //   index == 0 ? '0%' : `${(index * 100) / availabilities.length}%`;
        // customStyles.width = width;
        // customStyles.left = left;

        customStyles.width = getAvailabilityStyles(availability).width;
        customStyles.left = getAvailabilityStyles(availability).left;
        customStyles.top = `${index * EVENT_HEIGHT}px`;
        return (
          <Availability
            availability={availability}
            key={uniqueId(`av${availability.id}index${index}_`)}
            customStyles={customStyles}
          />
        );
      })}
    </div>
  );
};

export default AllDayTimeSlot;
