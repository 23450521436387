import React, { useState, useEffect } from 'react';
import styles from './RecentActivity.module.scss';
import moment from 'moment-timezone';
import { absolutePath, routes } from '~constants/routes';
import axios from 'axios';
import { Icon } from '~ui';
import DetailsModal from '../DetailsModal/DetailsModal.jsx';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { MOBILE_WIDTH } from '~constants/viewports';
import { useWindowSize } from '~hooks';
import { isMobile } from '~constants/userAgent';
import SendLimitReached from '../../common/SendLimitReached';

const RecentActivity = props => {
  const { clientId, activeTrial, studioId, trialVerified } = props;
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [emailDetails, setEmailDetails] = useState(false);
  const [emailAttachments, setEmailAttachments] = useState(false);
  const [referenceId, setReferenceId] = useState();
  const [paginationButtons, setPaginationButtons] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [listOfActivities, setListOfActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const windowWidth = useWindowSize()[0];
  const mobileScreen = windowWidth <= MOBILE_WIDTH || isMobile();
  const [showBeforeSend, setShowBeforeSend] = useState(false);

  const checkValidation = async activity => {
    if (activeTrial && !trialVerified) {
      setLoading(true);

      const res = await axios.get(
        routes.STUDIO_PROFILE.CHECK_SEND_LIMIT(studioId)
      );

      if (res.data.reached_send_limit) {
        setLoading(false);

        setShowBeforeSend(true);
      } else {
        setLoading(false);

        resendContent(activity.attributes);
      }
    } else {
      resendContent(activity.attributes);
    }
  };

  const grabNextPage = async () => {
    const res = await axios.get(
      `/api/clients/${clientId}/new_activities?page=${page}`
    );
    setListOfActivities(res.data.data);
    setPageCount(res.data.meta.page_count);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    grabNextPage(page);
  }, [page]);

  useEffect(() => {
    if (pageCount > 1) {
      setPaginationButtons(true);
    }
  }, [pageCount]);

  const generatePaginationNumbers = () => {
    let paginationArr = [];

    for (let i = 1; i <= pageCount; i++) {
      paginationArr.push(
        <a
          className={page === i ? styles.Disable : ''}
          key={i}
          onClick={() => setPage(i)}
        >
          {i}
        </a>
      );
    }
    if (paginationArr.length === 1) {
      paginationArr = [];
    }
    return paginationArr;
  };

  const resendContent = async activity => {
    const res = await getClientActivities(activity);
    setReferenceId(res.reference_id);
    setOpenConfirmationModal(true);
  };

  const getClientActivities = async activity => {
    try {
      const res = await axios.get(`/api/client_activities/${activity.id}`);
      return res.data.data.attributes;
    } catch (error) {
      console.error(error);
    }
  };

  const handleDetailsOnClick = async activity => {
    setLoading(true);
    const res = await getClientActivities(activity);
    if (res.reference_type === 'EmailMessage') {
      setEmailDetails(res.reference);
      setEmailAttachments(
        res.email_attachments.length <= 0 ? false : res.email_attachments
      );
      setOpenModal(true);
      setLoading(false);
    } else if (res.reference_type === 'WebFormResponse') {
      const data = await axios.get(
        `/api/questionnaire_responses/${res.reference_id}`
      );
      const uuid = data.data.data.attributes.uuid;
      window.location.href = absolutePath(`/customer/form/${uuid}/print`);
      setLoading(false);
    } else if (activity.reference_type === 'Invoice') {
      let path = absolutePath(`/clients/${clientId}/invoices`);
      if (activity.message == 'invoice_full_payment') {
        path += '?status=paid';
      } else if (activity.message == 'invoice_partial_paid') {
        path += '?status=partially_paid';
      } else if (activity.message == 'quote_sent') {
        path += '?status=quotes';
      } else if (activity.message == 'quote_accepted') {
        path += '?status=all';
      } else if (activity.message == 'quote_declined') {
        path += '?status=declined_quote';
      } else if (activity.message == 'quote_modification') {
        path += '?status=mod_requested_quote';
      }
      window.location.href = path;
      setLoading(false);
    } else {
      window.location.href = absolutePath(`${res.url}`);
      setLoading(false);
    }
  };

  const generateFontAwesomeClass = activity => {
    let fontAwesomeClass;
    switch (activity) {
      case 'inquiry_received':
        fontAwesomeClass = 'fa-user';
        break;
      case 'invoice_sent':
      case 'quote_sent':
        fontAwesomeClass = 'fa-dollar-sign';
        break;
      case 'document_sent':
        fontAwesomeClass = 'fa-file-archive';
        break;
      case 'web_form_sent':
        fontAwesomeClass = 'fa-file-alt';
        break;
      case 'email_sent':
      case 'receipt_sent':
      case 'payment_reminder_sent':
      case 'marketing_email_sent':
        fontAwesomeClass = 'fa-check';
        break;
      case 'email_received':
        fontAwesomeClass = 'fa-envelope';
        break;
      case 'email_rejected':
        fontAwesomeClass = 'fa-times';
        break;
      case 'invoice_full_payment':
        fontAwesomeClass = 'fa-dollar-sign';
        break;
      case 'invoice_partial_paid':
        fontAwesomeClass = 'fa-dollar-sign';
        break;
      case 'contract_sent':
      case 'contract_signed':
        fontAwesomeClass = 'fa-gavel';
        break;
      case 'quote_accepted':
        fontAwesomeClass = 'fa-check';
        break;
      case 'quote_declined':
        fontAwesomeClass = 'fa-times';
        break;
      case 'quote_modification':
        fontAwesomeClass = 'fa-file-alt';
        break;
      default:
        fontAwesomeClass = 'fa-flag';
    }
    return fontAwesomeClass;
  };

  const checkCommunication = activity => {
    if (activity.status.is_sent) {
      return 'Sent';
    } else if (activity.status.is_opened) {
      return 'Opened';
    } else if (activity.status.is_clicked) {
      return 'Clicked';
    } else if (activity.status.is_rejected) {
      return 'Rejected';
    }
  };

  const grabTimeAndDate = timeAndDate => {
    const formattedTime = moment(timeAndDate).format('h:mma, MM/DD/YYYY');
    return formattedTime;
  };

  const incrementOrDecrement = plusOrMinus => {
    let currentPage = page;
    if (plusOrMinus === '+') {
      currentPage += 1;
      setPage(currentPage);
    } else {
      currentPage -= 1;
      setPage(currentPage);
    }
  };

  return (
    <div>
      <h1>Recent Activity</h1>
      {isLoading ? (
        <h1>Loading...</h1>
      ) : (
        <>
          {listOfActivities.length === 0 ? (
            <div>This client has no recent activities.</div>
          ) : (
            listOfActivities.map((activity, index) => {
              const emailRejected = activity.message === 'email_rejected';

              return (
                <div key={index} className={styles.Description}>
                  <div className={styles['Description-information']}>
                    <div className={styles['Description-flex']}>
                      <div className={styles.Icon}>
                        <span className="fa-stack fa-lg">
                          <i
                            className="fa fa-circle fa-stack-2x"
                            style={
                              emailRejected
                                ? { color: '#dc3532' }
                                : { color: '#d9fdf6' }
                            }
                          ></i>
                          <i
                            className={`fa ${generateFontAwesomeClass(
                              activity.attributes.message
                            )} fa-stack-1x`}
                            style={
                              emailRejected
                                ? { color: 'white' }
                                : { color: 'black' }
                            }
                          ></i>
                        </span>
                      </div>
                      <div className={styles['Description-message']}>
                        {activity.attributes.status_message}
                      </div>
                    </div>
                    <div className={styles['Description-flex']}>
                      <div style={{ display: 'flex', width: '50%' }}>
                        <div>
                          {grabTimeAndDate(activity.attributes.updated_at)}{' '}
                          {checkCommunication(activity.attributes)}
                        </div>
                      </div>
                      <div
                        className={styles.ButtonWrapper}
                        style={
                          mobileScreen ? {} : { display: 'flex', width: '33%' }
                        }
                      >
                        <div
                          className={styles['Description-pointer']}
                          onClick={() =>
                            handleDetailsOnClick(activity.attributes)
                          }
                        >
                          Details
                        </div>
                        {activity.attributes.reference_type ==
                          'EmailMessage' ? (
                          <div
                            className={styles['Description-pointer']}
                            onClick={() => checkValidation(activity)}
                          >
                            Re-send
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
          {paginationButtons && (
            <div className={styles.Pagination}>
              <a
                className={page === 1 ? styles.Disable : ''}
                onClick={() => incrementOrDecrement('-')}
              >
                ← Previous
              </a>
              {generatePaginationNumbers()}
              <a
                className={page === pageCount ? styles.Disable : ''}
                onClick={() => incrementOrDecrement('+')}
              >
                Next →
              </a>
            </div>
          )}
        </>
      )}

      {loading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingText}>
            <p>Loading...</p>
            <Icon name="spinner" className="fa-pulse" large />
          </div>
        </div>
      )}
      {openModal && (
        <DetailsModal
          emailDetails={emailDetails}
          emailAttachments={emailAttachments}
          setOpenModal={setOpenModal}
        />
      )}
      {openConfirmationModal && (
        <ConfirmationModal
          grabNextPage={grabNextPage}
          handleHide={setOpenConfirmationModal}
          referenceId={referenceId}
          setLoading={setLoading}
        />
      )}
      {showBeforeSend && (
        <SendLimitReached handleHide={() => setShowBeforeSend(false)} />
      )}
    </div>
  );
};

export default RecentActivity;
