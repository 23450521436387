import React from 'react';
import PropTypes from 'prop-types';
import modes from '../email_modal_modes';
import EmailTemplateCreatorContainer from '../email_template_creator/email_template_creator_container';
import EmailSenderContainer from '../email_sender/email_sender_container';

import styles from './email_modal_content.module.scss';

class EmailModalContent extends React.PureComponent {
  static propTypes = {
    email_templates: PropTypes.array.isRequired,
    selectedTemplate: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    isFetchingTemplate: PropTypes.bool.isRequired,
    mode: PropTypes.string.isRequired,
    templateProps: PropTypes.object.isRequired,
    sendingProps: PropTypes.object.isRequired,
    handleTemplateChoice: PropTypes.func.isRequired,
    bookingUrls: PropTypes.array,
    locations: PropTypes.array,
  };

  render() {
    const {
      mode,
      email_templates,
      selectedTemplate,
      handleTemplateChoice,
      isFetchingTemplate,
      templateProps,
      sendingProps,
      bookingUrls,
      locations,
    } = this.props;
    const isSendingMode = mode === modes.SENDING;
    const isTemplateMode = mode === modes.TEMPLATE;

    const defaultSelect = (
      <select
        className="form-control"
        disabled={isFetchingTemplate}
        value={selectedTemplate}
        onChange={handleTemplateChoice}
      >
        <option value="" default>
          Choose Template...
        </option>
        <option value="new">+ Create New Email Template</option>
        {email_templates.map(t => (
          <option key={t.id} value={t.id}>
            {t.name}
          </option>
        ))}
      </select>
    );

    const getStyle = shouldDisplay =>
      shouldDisplay ? {} : { display: 'none' };

    return (
      <div className={styles.emailModalContainer}>
        <div className="form-group">
          <label className="control-label">Email Template</label>
          {defaultSelect}
          {selectedTemplate && selectedTemplate !== 'new' && (
            <a
              href={`/studio/email_templates/${selectedTemplate}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Preview Email
            </a>
          )}
        </div>

        <div style={getStyle(isSendingMode)}>
          <EmailSenderContainer
            {...sendingProps}
            bookingUrls={bookingUrls}
            locations={locations}
          />
        </div>

        <div style={getStyle(isTemplateMode)}>
          <EmailTemplateCreatorContainer
            {...templateProps}
            bookingUrls={bookingUrls}
            locations={locations}
          />
        </div>
      </div>
    );
  }
}

export default EmailModalContent;
