import React from 'react';
import styles from './Step.module.scss';
import classNames from 'classnames/bind';
import StepForm from './StepForm';
import ToggleContent from '~components/common/ToggleContent';
import { DeleteConfirmation } from '~ui';

const resourceMap = {
  'Engine::Actions::SendEmail': 'EmailTemplate',
  'Engine::Actions::MakeToDo': 'ReminderTemplate',
  'Engine::Actions::SendPaymentReminder': 'SendPaymentReminder',
};

const Step = props => {
  const {
    stepIcon,
    stepName,
    timeOffset,
    stepTriggerType,
    handleDeleteStep,
    isEditing,
    handleEditClose,
    step,
    actionType,
  } = props;

  const cx = classNames.bind(styles);
  const classes = cx('Step-icon', `Step-icon-${stepIcon}`);

  const getInterval = () => {
    switch (stepTriggerType) {
      case 'BeforeSession':
        return `${timeOffset} days prior to session`;
      case 'AfterSession':
        return `${timeOffset} days after session`;
      case 'AfterBooked':
        return `${timeOffset} days after booked`;
      case 'SessionBooked':
        return 'Once session is booked';
      default:
        return '';
    }
  };

  const getSchedule = () => {
    if (timeOffset % 365 === 0) {
      return { interval: 365, number: timeOffset / 365 };
    } else if (timeOffset % 30 === 0) {
      return { interval: 30, number: timeOffset / 30 };
    } else if (timeOffset % 7 === 0) {
      return { interval: 7, number: timeOffset / 7 };
    } else {
      return { interval: 1, number: timeOffset };
    }
  };

  const initialEditFormState = {
    item: step.action.id,
    description: step.action.id == 2 ? step.name : '',
    schedule: step.trigger.id,
    number: getSchedule().number,
    scheduleInterval: getSchedule().interval,
    resourceId: step.resource ? step.resource.id : undefined,
    resourceType: resourceMap[actionType],
    stepId: step.id,
  };

  return (
    <>
      <div className={styles.Step}>
        <div className={classes}>
          <i className={`fas ${stepIcon} c-w`} />
        </div>

        <div className={styles['Step-main']}>
          {isEditing ? (
            <div className={styles['Step-editing']}>
              <StepForm
                isEditing
                handleClose={handleEditClose}
                initialFormState={initialEditFormState}
              />
            </div>
          ) : (
            <>
              <div className={styles['Step-main-info']}>{stepName}</div>
              <div className={styles['Step-main-rightside']}>
                <div className={styles['Step-main-time']}>{getInterval()}</div>
                <div className={styles['Step-main-exbutton']}>
                  <ToggleContent
                    toggle={handleShow => (
                      <a
                        onClick={e => {
                          e.stopPropagation();
                          handleShow();
                        }}
                      >
                        <i className="fas fa-times" />
                      </a>
                    )}
                    content={handleHide => (
                      <DeleteConfirmation
                        handleHide={handleHide}
                        handleDelete={handleDeleteStep}
                        name={step.name}
                        resource="Workflow Step"
                      />
                    )}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Step;
