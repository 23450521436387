import React, { useEffect, useState } from 'react';
import {
  getDocuments,
  getClientDocuments,
  updateDocumentsMeta,
  updateClientDocumentsMeta,
} from '~actions/documents';
import { getQuestionnaires } from '~actions/questionnaires';
import { getContractTemplates } from '~actions/contractTemplates';
import { absolutePath, routes } from '~constants/routes';
import { useDispatch } from 'react-redux';
import styles from './DocumentsContent.module.scss';
import axios from 'axios';
import DocumentsTable from '../DocumentsTable';
import QuestionnairesTable from '../QuestionnairesTable';
import ContractsTable from '../ContractsTable';

const menuFilters = [
  'questionnaires',
  'contracts',
  'documents',
  'client documents',
];

const DocumentsContent = () => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);
  const [filterType, setFilterType] = useState('questionnaires');

  useEffect(() => {
    dispatch(getClientDocuments());
    dispatch(getDocuments());
    dispatch(getQuestionnaires());
    dispatch(getContractTemplates());
  }, []);

  const handleDocumentDelete = async (idForDelete, getData) => {
    try {
      await axios.delete(
        absolutePath(`/${routes.DOCUMENTS.DELETE(idForDelete)}`)
      );

      dispatch(getData());
    } catch (error) {
      console.error(error);
    }
  };

  const handleMenuFilter = (type, index) => {
    setActiveTab(index);
    setFilterType(type);
  };

  const renderTableFromFilter = () => {
    switch (filterType) {
      case 'documents':
        return (
          <DocumentsTable
            handleDocumentDelete={id => handleDocumentDelete(id, getDocuments)}
            selectorKey="documents"
            updateMetaData={updateDocumentsMeta}
            getData={getDocuments}
          />
        );
      case 'client documents':
        return (
          <DocumentsTable
            handleDocumentDelete={id =>
              handleDocumentDelete(id, getClientDocuments)
            }
            selectorKey="clientDocuments"
            updateMetaData={updateClientDocumentsMeta}
            getData={getClientDocuments}
          />
        );
      case 'questionnaires':
        return <QuestionnairesTable />;
      case 'contracts':
        return <ContractsTable />;
      default:
        break;
    }
  };

  return (
    <div className={styles.DocumentContainer}>
      <div className={styles.DocumentHeader}>
        <ul>
          {menuFilters.map((menuItem, index) => (
            <li key={menuItem}>
              <button
                onClick={() => handleMenuFilter(menuItem, index)}
                className={
                  index === activeTab ? styles['activeButton'] : 'inactive'
                }
              >
                {menuItem}
              </button>
            </li>
          ))}
        </ul>
      </div>
      {renderTableFromFilter()}
    </div>
  );
};

export default DocumentsContent;
