import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendInvoice } from '~actions/invoice';
import { actionsLoadingSelector } from '~selectors';
import { isMobile } from '~constants/userAgent';
import { toastOptions } from '~constants/toasts';
import { getInvoice, updateInvoice } from '~actions/invoice';
import { Button } from '~ui';
import InvoiceHeader from './InvoiceHeader';
import InvoiceDetails from './InvoiceDetails';
import InvoiceInstallmentsTable from './InvoiceInstallmentsTable';
import InvoiceItemsTable from './InvoiceItemsTable';
import InvoiceTotals from './InvoiceTotals';
import { toast } from 'react-toastify';
import styles from './Invoice.module.scss';
import InvoiceRefundsTable from './InvoiceRefundsTable';

const Invoice = () => {
  const invoice = useSelector(state => state.invoice);
  const studioTax = useSelector(state => state.invoice.studioTax);
  const manual_refunds = useSelector(state => state.invoice.manual_refunds);
  const square_refunds = useSelector(state => state.invoice.square_refunds);
  const stripe_refunds = useSelector(state => state.invoice.stripe_refunds);
  let refunds = square_refunds.concat(stripe_refunds);
  refunds = refunds.concat(manual_refunds);

  const {
    id,
    payments,
    paid_in_full,
    memo,
    schedule,
    is_quote,
    total,
    sent_at,
  } = invoice;
  const dispatch = useDispatch();

  let clientPayments = payments
    .filter(
      payment =>
        (payment.square_payment_id !== null ||
          payment.stripe_payment_id !== null ||
          payment.manual_refund) &&
        payment.available_refund_amount > 0
    )
    .map(payment => {
      return {
        label: `${moment(payment.payment_date).format('MMMM D, YYYY')} - ${
          window.app.invoices.currency
        }${payment.amount}`,
        value:
          payment.square_payment_id ||
          payment.stripe_payment_id ||
          `manual-${payment.id}`,
      };
    });
  clientPayments.unshift({
    label: 'Select payment',
    value: '',
  });

  const installmentsSum = schedule.installments.reduce((a, b) => {
    const firstAddend = isNaN(a) ? Number.parseFloat(a.amount) : a;
    const secondAddend = isNaN(b) ? Number.parseFloat(b.amount) : b;
    return Number.parseFloat((firstAddend + secondAddend).toFixed(2));
  }, 0);

  const calculationError =
    Number.parseFloat(installmentsSum) !== Number.parseFloat(total);

  const asyncSendInvoice = async () => {
    try {
      await dispatch(sendInvoice(id));

      toast.success(`${is_quote ? 'Quote' : 'Invoice'} sent.`, toastOptions);
    } catch (e) {
      toast.error(
        `Error sending ${is_quote ? 'Quote' : 'Invoice'}.`,
        toastOptions
      );
    }
  };

  const canEditInvoice = !paid_in_full;
  const isLoading = useSelector(state =>
    actionsLoadingSelector(state, ['INVOICES_SEND'])
  );

  const asyncUpdateTaxBeforeDiscount = async tax_before_discount => {
    const params = {
      invoice: {
        tax_before_discount: !tax_before_discount,
      },
    };

    return dispatch(updateInvoice(invoice.id, params));
  };

  const asyncUpdateTax = async taxAmount => {
    const params = {
      invoice: {
        tax_enabled: true,
        tax_amount: taxAmount,
      },
    };

    return dispatch(updateInvoice(invoice.id, params));
  };

  const asyncDeleteTax = async () => {
    const params = {
      invoice: {
        tax_enabled: false,
        tax_amount: 0.0,
      },
    };

    return dispatch(updateInvoice(invoice.id, params));
  };

  const asyncUpdateDiscount = async (discountAmount, discountIsPercent) => {
    let params = {
      invoice: {
        has_discount: true,
        percent_based_discount: discountIsPercent,
        discount_amount: discountAmount,
      },
    };

    return dispatch(updateInvoice(invoice.id, params));
  };

  const asyncDeleteDiscount = async () => {
    const params = {
      invoice: {
        has_discount: false,
        discount_amount: 0.0,
        percent_based_discount: 0.0,
      },
    };

    return dispatch(updateInvoice(invoice.id, params));
  };

  return (
    <div className={styles.Invoice}>
      <InvoiceHeader clientPayments={clientPayments} />
      <InvoiceDetails />
      <div className={styles['Invoice-section']}>
        <InvoiceItemsTable readOnly={!canEditInvoice} isMobile={isMobile()} />
        <InvoiceTotals
          invoice={invoice}
          handleTaxDiscountSwap={tax_before_discount => {
            asyncUpdateTaxBeforeDiscount(tax_before_discount).then(() =>
              dispatch(getInvoice(invoice.id))
            );
          }}
          handleUpdateTax={taxAmount => asyncUpdateTax(taxAmount)}
          handleDeleteTax={asyncDeleteTax}
          handleUpdateDiscount={(discountAmount, discountIsPercent) =>
            asyncUpdateDiscount(discountAmount, discountIsPercent)
          }
          handleDeleteDiscount={asyncDeleteDiscount}
          studioTax={studioTax}
          showAmountPaid
          showBalanceDue
        />
      </div>
      {(schedule.installments.length > 1 || calculationError) && (
        <div className={styles['Invoice-section']}>
          <InvoiceInstallmentsTable
            schedule={schedule}
            installmentsSum={installmentsSum}
            readOnly={!canEditInvoice}
            isMobile={isMobile()}
          />
        </div>
      )}
      {refunds.length > 0 && <InvoiceRefundsTable refunds={refunds} />}
      {memo && (
        <div className={styles['Invoice-section']}>
          <h2>Memo</h2>
          <p>{memo}</p>
        </div>
      )}
      <Button
        className={styles.SendButton}
        text={`${sent_at ? 'Resend' : 'Send'} ${
          is_quote ? 'Quote' : 'Invoice'
        }`}
        onClick={asyncSendInvoice}
        loading={isLoading}
        disabled={
          Number.parseFloat(installmentsSum) !== Number.parseFloat(total)
        }
      />
    </div>
  );
};

export default Invoice;
