import qs from 'qs';

const setQueryStringWithoutPageReload = qsValue => {
  const newurl =
    window.location.protocol +
    '//' +
    window.location.host +
    window.location.pathname +
    qsValue;

  window.history.pushState({ path: newurl }, '', newurl);
};

export const setQueryStringValue = (
  key,
  value,
  queryString = window.location.search.substring(1)
) => {
  const values = qs.parse(queryString);
  let updatedQs = { ...values, [key]: value };

  if (value === '' || value === null) {
    delete updatedQs.key;
  } else if (
    typeof value === 'object' &&
    Object.values(value).some(element => element === '' || element === null)
  ) {
    delete updatedQs.key;
  }
  let newQsValue = qs.stringify(updatedQs);

  setQueryStringWithoutPageReload(`?${newQsValue}`);
};

export const getQueryStringValue = (
  key,
  queryString = window.location.search.substring(1)
) => {
  const values = qs.parse(queryString);
  return values[key];
};

export const clearQueryString = () => {
  setQueryStringWithoutPageReload('');
};

export const uniqueId = (prefix = '') =>
  prefix +
  Math.random()
    .toString(36)
    .substr(2, 9);
