import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { absolutePath } from '~constants/routes';
import { toastOptions } from '~constants/toasts';
import { Button, Dropdown, Modal, SearchInput, Checkbox } from '~ui';
import styles from './EditPlanModal.module.scss';

const EditPlanModal = ({ handleHide, initialPromoCode }) => {
  const { availablePlans, currentPlan, currentSubscription } = useSelector(
    state => state.subscription
  );

  const upgradeMap = {
    'iris-mo-bronze': 'iris-ann-bronze',
    'iris-mo-mktg': 'iris-ann-mktg',
    'iris-mo-bronze-booking': 'iris-ann-bronze-booking',
    'iris-mo-booking-mktg': 'iris-ann-booking-mktg',
    'iris-ann-bronze': 'iris-ann-bronze-booking',
    'iris-ann-mktg': 'iris-ann-booking-mktg',
    'iris-ann-bronze-booking': 'iris-ann-booking-mktg',
    'iris-ann-booking-mktg': 'iris-ann-booking-mktg',
  };

  const [isUpgrading, setIsUpgrading] = useState(false);
  const [couponMessage, setCouponMessage] = useState('');
  const [couponCodeValid, setCouponCodeValid] = useState(true);
  const [readableCouponCode, setReadableCouponCode] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [newPlan, setNewPlan] = useState();
  const [couponCode, setCouponCode] = useState(initialPromoCode || '');
  const [disabled, setDisabled] = useState(false);
  const [checked, setChecked] = useState(true);
  const [samePlan, setSamePlan] = useState(true);
  const [initialValue, setInitialValue] = useState();
  const [submit, setSubmit] = useState(false);

  const editModalOptions = [
    { label: 'Monthly Basic ($25.00/month)', value: 'iris-mo-bronze' },
    { label: 'Annual Basic ($270.00/year)', value: 'iris-ann-bronze' },
    {
      label: 'Monthly Booking ($35.00/month)',
      value: 'iris-mo-bronze-booking',
    },
    {
      label: 'Annual Booking ($357.00/year)',
      value: 'iris-ann-bronze-booking',
    },
  ];

  const checkIfUpgrading = newStripePlanId => {
    const newHasMarketing = newStripePlanId.split('-').includes('mktg');
    const currentHasMarketing = currentPlan.stripe_plan_id
      .split('-')
      .includes('mktg');

    const newHasBooking = newStripePlanId.split('-').includes('booking');
    const currentHasBooking = currentPlan.stripe_plan_id
      .split('-')
      .includes('booking');

    const newIsMonthly = newStripePlanId.split('-').includes('mo');
    const currentIsMonthly = currentPlan.stripe_plan_id
      .split('-')
      .includes('mo');
    if (
      currentIsMonthly &&
      currentHasBooking &&
      !newIsMonthly &&
      !newHasBooking
    ) {
      setIsUpgrading(false);
      return;
    } else if (
      currentIsMonthly &&
      !currentHasBooking &&
      !currentHasMarketing &&
      newIsMonthly &&
      !newHasBooking &&
      newHasMarketing
    ) {
      setIsUpgrading(true);
    } else if (
      currentIsMonthly &&
      !currentHasBooking &&
      newIsMonthly &&
      newHasBooking
    ) {
      setIsUpgrading(true);
    } else if (
      currentIsMonthly &&
      currentHasBooking &&
      !currentHasMarketing &&
      newIsMonthly &&
      newHasBooking &&
      newHasMarketing
    ) {
      setIsUpgrading(true);
    } else if (
      !currentIsMonthly &&
      !currentHasBooking &&
      !currentHasMarketing &&
      !newIsMonthly &&
      !newHasBooking &&
      newHasMarketing
    ) {
      setIsUpgrading(true);
    } else if (
      !currentIsMonthly &&
      currentHasBooking &&
      !currentHasMarketing &&
      !newIsMonthly &&
      newHasBooking &&
      newHasMarketing
    ) {
      setIsUpgrading(true);
    } else if (
      !currentIsMonthly &&
      !currentHasBooking &&
      !newIsMonthly &&
      newHasBooking
    ) {
      setIsUpgrading(true);
    } else if (
      !currentIsMonthly &&
      !currentHasBooking &&
      newIsMonthly &&
      newHasBooking
    ) {
      setIsUpgrading(true);
    } else if (currentIsMonthly && !newIsMonthly) {
      setIsUpgrading(true);
    } else {
      setIsUpgrading(false);
    }
  };

  useEffect(() => {
    if (newPlan && couponCode) {
      handleSearchInputChange(couponCode);
    }
  }, [newPlan]);

  const handleSearchInputChange = searchValue => {
    setCouponCode(searchValue);
    if (!searchValue) {
      setCouponMessage('');
      setCouponCodeValid(true);
      setPromoCode('');
      return;
    }
    axios
      .get(`/api/coupons/${searchValue}`, {
        params: {
          plan: newPlan.stripe_plan_id,
        },
      })
      .then(res => {
        if (
          res.data.redeem_by &&
          moment(res.data.redeem_by).isBefore(moment())
        ) {
          setCouponMessage('Coupon code expired');
          setCouponCodeValid(false);
          setReadableCouponCode('');
        } else {
          setCouponMessage(res.data.description);
          setReadableCouponCode(searchValue);
          setCouponCodeValid(true);
          setPromoCode(res.data.promotional_code);
        }
      })
      .catch(error => {
        setReadableCouponCode('');
        if (error.response.status === 404) {
          setCouponMessage('Coupon code not found');
          setCouponCodeValid(false);
        } else if (error.response.status === 418) {
          setCouponMessage('Coupon not valid for this plan');
          setCouponCodeValid(false);
        } else {
          const errorMessage = `Error fetching coupon code: ${error.response.data.error.messages}`;
          toast.error(errorMessage, toastOptions);
          Rollbar.error(`${errorMessage}: ${error}`);
          setCouponCodeValid(false);
        }
      });
  };

  const handleSubmit = () => {
    const reqParams = {
      subscription: {
        plan: {
          stripe_plan_id: newPlan.stripe_plan_id || null,
          coupon_code: promoCode ? promoCode : null,
          readable_coupon_code: readableCouponCode ? readableCouponCode : null,
        },
      },
    };

    axios
      .put(`/api/subscriptions/${currentSubscription.id}`, reqParams)
      .then(() => {
        window.location.href = absolutePath('/studio/subscription');
      })
      .catch(err => {
        setSubmit(false);
        setDisabled(false);
        let errorMessage = 'Unable to process update, please contact support';

        if (err.response.data && err.response.data.error.messages) {
          errorMessage = err.response.data.error.messages[0];
        }

        toast.error(errorMessage, toastOptions);
        Rollbar.error(`${errorMessage}: ${err}`);
      });
  };

  const dropdownOnChange = newStripePlanId => {
    setSubmit(false);
    checkIfUpgrading(newStripePlanId);
    if (newStripePlanId !== currentPlan.stripe_plan_id) {
      for (let key in availablePlans) {
        if (availablePlans[key].stripe_plan_id == newStripePlanId) {
          setNewPlan(availablePlans[key]);
        }
      }
    } else {
      setNewPlan(currentPlan);
    }
  };

  useEffect(() => {
    if (initialPromoCode) {
      const upgradedStripePlanId = upgradeMap[currentPlan.stripe_plan_id];

      if (upgradedStripePlanId.split('-').includes('mktg')) {
        setChecked(true);
      }

      dropdownOnChange(upgradedStripePlanId);
    } else {
      if (
        currentPlan.stripe_plan_id === 'iris-mo-mktg' ||
        currentPlan.stripe_plan_id === 'iris-ann-booking-mktg' ||
        currentPlan.stripe_plan_id === 'iris-mo-booking-mktg' ||
        currentPlan.stripe_plan_id === 'iris-ann-mktg'
      ) {
        setChecked(true);
      } else {
        setChecked(false);
      }
      dropdownOnChange(currentPlan.stripe_plan_id);
    }
  }, []);

  useEffect(() => {
    if (newPlan) {
      if (newPlan.stripe_plan_id === 'iris-mo-mktg') {
        setInitialValue('iris-mo-bronze');
        return;
      }
      if (newPlan.stripe_plan_id === 'iris-ann-booking-mktg') {
        setInitialValue('iris-ann-bronze-booking');
        return;
      }
      if (newPlan.stripe_plan_id === 'iris-mo-booking-mktg') {
        setInitialValue('iris-mo-bronze-booking');
        return;
      }
      if (newPlan.stripe_plan_id === 'iris-ann-mktg') {
        setInitialValue('iris-ann-bronze');
        return;
      } else {
        setInitialValue(newPlan.stripe_plan_id);
      }
    }
  }, [newPlan]);

  useEffect(() => {
    if (newPlan) {
      upgradeOrDowngrade();
    }
  }, [checked, initialValue, newPlan]);

  const checkSame = () => {
    if (newPlan) {
      if (newPlan.stripe_plan_id === currentPlan.stripe_plan_id) {
        setSamePlan(true);
      } else {
        setSamePlan(false);
      }
    }
  };

  useEffect(() => {
    checkSame();
  }, [newPlan]);

  const upgradeOrDowngrade = () => {
    if (checked && newPlan.stripe_plan_id === 'iris-ann-bronze') {
      setNewPlan(availablePlans.annualPlanBasicMarketing);
      checkIfUpgrading(availablePlans.annualPlanBasicMarketing.stripe_plan_id);
    }
    if (checked && newPlan.stripe_plan_id === 'iris-mo-bronze') {
      setNewPlan(availablePlans.monthlyPlanBasicMarketing);
      checkIfUpgrading(availablePlans.monthlyPlanBasicMarketing.stripe_plan_id);
    }
    if (checked && newPlan.stripe_plan_id === 'iris-ann-bronze-booking') {
      setNewPlan(availablePlans.annualPlanPremiumMarketing);
      checkIfUpgrading(
        availablePlans.annualPlanPremiumMarketing.stripe_plan_id
      );
    }
    if (checked && newPlan.stripe_plan_id === 'iris-mo-bronze-booking') {
      setNewPlan(availablePlans.monthlyPlanPremiumMarketing);
      checkIfUpgrading(
        availablePlans.monthlyPlanPremiumMarketing.stripe_plan_id
      );
    }
    if (!checked && newPlan.stripe_plan_id === 'iris-ann-mktg') {
      setNewPlan(availablePlans.annualPlanBasic);
      checkIfUpgrading(availablePlans.annualPlanBasic.stripe_plan_id);
    }
    if (!checked && newPlan.stripe_plan_id === 'iris-mo-booking-mktg') {
      setNewPlan(availablePlans.monthlyPlanPremium);
      checkIfUpgrading(availablePlans.monthlyPlanPremium.stripe_plan_id);
    }
    if (!checked && newPlan.stripe_plan_id === 'iris-ann-booking-mktg') {
      setNewPlan(availablePlans.annualPlanPremium);
      checkIfUpgrading(availablePlans.annualPlanPremium.stripe_plan_id);
    }
    if (!checked && newPlan.stripe_plan_id === 'iris-mo-mktg') {
      setNewPlan(availablePlans.monthlyPlanBasic);
      checkIfUpgrading(availablePlans.monthlyPlanBasic.stripe_plan_id);
    }
  };

  const changeChecked = () => {
    setSubmit(false);
    setChecked(checked === true ? false : true);
  };

  useEffect(() => {
    if (submit) handleSubmit();
  }, [submit]);

  return (
    <Modal title="Edit Plan" handleHide={handleHide}>
      <div className={styles['EditPlanModal-body']}>
        <div>
          {initialValue && (
            <Dropdown
              name="plan"
              labelText="Plan"
              initialValue={initialValue}
              options={editModalOptions}
              required
              onChangeCallback={dropdownOnChange}
            />
          )}
          <div className={styles['EditPlanModal-couponInput']}>
            <SearchInput
              name="coupon_code"
              labelText="Coupon Code"
              initialValue={couponCode}
              onChangeCallback={handleSearchInputChange}
              disabled={!isUpgrading}
              hideIcon
            />
            <p className={styles['EditPlanModal-couponCode']}>
              {couponMessage}
            </p>
          </div>
        </div>
        <div className={styles['EditPlanModal-checkbox']}>
          <div className={styles['EditPlanModal-checkbox-div']}>
            <Checkbox
              name="marketing checkbox"
              labelText="Add email marketing?"
              checked={checked}
              onChangeCallback={changeChecked}
            />
            <div>
              <span>* Access to the Iris Email Marketing Template Library</span>
              <span>* Send bulk marketing emails to clients and leads</span>
            </div>
          </div>
          <div>
            <div>
              <span>
                You currently pay: ${Number(currentPlan.amount).toFixed(2)}{' '}
                {currentPlan.interval === 'month' ? 'monthly' : 'annually'}
              </span>
            </div>
            {newPlan && (
              <div>
                <span>
                  You will pay: ${Number(newPlan.amount).toFixed(2)}{' '}
                  {newPlan.interval === 'month' ? 'monthly' : 'annually'}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles['EditPlanModal-terms']}>
        <p>
          By signing up, I agree to Iris Works'{' '}
          <a
            target="_blank"
            href="https://iris-works.com/terms-and-conditions/"
          >
            Terms and Conditions
          </a>{' '}
          and{' '}
          <a target="_blank" href="https://iris-works.com/privacy-policy/">
            Privacy Policy
          </a>
        </p>
      </div>
      <div className={styles['EditPlanModal-footer']}>
        <Button danger text="Cancel" onClick={handleHide} />
        <Button
          text={disabled ? 'Updating' : 'Update Plan'}
          disabled={!newPlan || disabled || !couponCodeValid || samePlan}
          onClick={() => {
            setDisabled(true);
            setSubmit(true);
          }}
        />
      </div>
    </Modal>
  );
};

export default EditPlanModal;
