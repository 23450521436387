import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DeleteConfirmation, Modal } from '~ui';
import { routes } from '~constants/routes';
import ToggleContent from '~components/common/ToggleContent';
import SelectAvailabilityPage from '../../../BookingInvitationModal/SelectAvailabilityPage';
import SendEmailPage from '../../../BookingInvitationModal/SendEmailPage';
import styles from './PendingSessions.module.scss';

const PendingSessions = props => {
  const {
    client,
    calendar,
    sessionTypes,
    journeyTemplates,
    contracts,
    locations,
    invoiceTemplates,
    emailTemplates,
    locationVariables,
    timeZone,
    hasShootProofIntegration,
    hasPicTimeIntegration,
    shootProofBrands,
  } = props;
  const [pageIndex, setPageIndex] = useState(0);
  const [bookingCalendars, setBookingCalendars] = useState([]);
  const fetchBookingCalendars = async () => {
    const response = await axios.get(routes.BOOKING_CALENDARS.INDEX, {
      params: { client_id: client.id },
    });
    setBookingCalendars(response.data.data);
  };

  useEffect(() => {
    fetchBookingCalendars();
  }, []);

  const deleteCalendar = async calendarId => {
    await axios.delete(routes.BOOKING_CALENDARS.DELETE(calendarId));
    setBookingCalendars(
      bookingCalendars.filter(
        bookingCalendar => bookingCalendar.attributes.id !== calendarId
      )
    );
  };

  const handleSave = async (
    bookingCalendar,
    leadDays,
    leadInterval,
    showLeadTime
  ) => {
    const res = await axios.put(
      routes.BOOKING_CALENDARS.UPDATE(bookingCalendar.id),
      {
        booking_calendar: {
          ...bookingCalendar,
          lead_days: showLeadTime ? leadDays * leadInterval : 0,
        },
      }
    );

    const publishRes = await axios.put(
      routes.BOOKING_CALENDARS.PUBLISH(bookingCalendar.id)
    );

    if (res.status > 399 || publishRes.status > 399) {
      setErrors(res.data.error.messages);
      return;
    }

    fetchBookingCalendars();
    setPageIndex(1);
  };

  return (
    <div className={styles.PendingSessions}>
      <ul>
        {bookingCalendars.length === 0 && (
          <li>There are currently no pending booking invitations.</li>
        )}
        {bookingCalendars.map((bookingCalendar, index) => (
          <li key={index}>
            <span>{bookingCalendar.attributes.title}</span>
            <div className={styles['PendingSessions-actions']}>
              <ToggleContent
                toggle={handleShow => (
                  <a className="fa fa-pencil-alt" onClick={handleShow} />
                )}
                content={handleHide => (
                  <Modal
                    title={`Book ${client.first_name}`}
                    handleHide={handleHide}
                  >
                    {pageIndex === 0 ? (
                      <SelectAvailabilityPage
                        initialBookingCalendar={{
                          ...bookingCalendar.attributes,
                          slotInterval: '60',
                          meta: {
                            lastPublished: null,
                            timeZone: timeZone,
                          },
                        }}
                        calendar={calendar}
                        sessionTypes={sessionTypes}
                        journeyTemplates={journeyTemplates}
                        contracts={contracts}
                        locations={locations}
                        invoiceTemplates={invoiceTemplates}
                        handleSave={handleSave}
                        hasShootProofIntegration={hasShootProofIntegration}
                        hasPicTimeIntegration={hasPicTimeIntegration}
                        shootProofBrands={shootProofBrands}
                        isClient
                      />
                    ) : (
                      <SendEmailPage
                        emailTemplates={emailTemplates}
                        locations={locationVariables}
                        bookingCalendar={bookingCalendar.attributes}
                        handleHide={() => {
                          handleHide();
                          setPageIndex(0);
                        }}
                      />
                    )}
                  </Modal>
                )}
              />
              <ToggleContent
                toggle={handleShow => (
                  <a onClick={handleShow} className="fa fa-times" />
                )}
                content={handleHide => (
                  <DeleteConfirmation
                    handleHide={handleHide}
                    handleDelete={() =>
                      deleteCalendar(bookingCalendar.attributes.id)
                    }
                    name={bookingCalendar.attributes.title}
                    resource="Booking Invitation"
                  />
                )}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PendingSessions;
