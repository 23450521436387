import React, { useEffect, useState } from 'react';
import styles from './GroupsTable.module.scss';
import { TableNew as Table, Icon } from '~ui';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { headerActionItems, rowActionItems } from './tableActions';
import {
  getAllClients,
  getGroups,
  updateClientListMeta,
  updateIdsForDelete,
} from '~actions/clientList';
import { useDispatch, useSelector } from 'react-redux';
import { actionsLoadingSelector, actionsErroredSelector } from '~selectors';
import DeleteGroupModal from '../ClientListModals/DeleteGroupModal';
import { getDropdownOptions } from '../metaMap';
import ManageGroupModal from '../ClientListModals/ManageGroupModal/index';

const GroupsTable = ({ isMobile }) => {
  const dispatch = useDispatch();
  const [groupIdList, setGroupIdList] = useState([]);
  const [checked, setChecked] = useState(false);
  const [openGroupModal, setOpenGroupModal] = useState(null);

  const { meta, idsForDelete, groups } = useSelector(state => state.clientList);

  const { page, page_count, total_records } = useSelector(
    state => state.clientList.meta
  );

  const options = getDropdownOptions(
    meta,
    dispatch,
    getAllClients,
    getGroups,
    updateClientListMeta
  );

  const isLoading = useSelector(state =>
    actionsLoadingSelector(state, ['GROUPS_GET'])
  );

  const errors = useSelector(state =>
    actionsErroredSelector(state, ['GROUPS_GET'])
  );

  useEffect(() => {
    if (isLoading !== false) {
      return;
    }

    if (errors.GROUPS_GET) {
      toast.error(
        `Error grabbing groups! Please reach out to support.`,
        toastOptions
      );
    }
  }, [isLoading, errors]);

  const selectAllCallback = checked => {
    if (checked) {
      const groupIds = groups.map(group => {
        return group.attributes.id;
      });
      setGroupIdList(groupIds);
    } else {
      setGroupIdList([]);
    }
  };

  let ITEM_HEADERS = [
    {
      column: '',
      sortable: false,
      checkbox: true,
      checked: checked,
      setChecked: setChecked,
      selectAllCallback: selectAllCallback,
    },
    { column: 'Group Name', sortable: false },
    {
      column: 'Category',
      sortable: false,
    },
  ];

  useEffect(() => {
    dispatch(getGroups());
  }, []);

  const [rows, setRows] = useState([]);

  const handleGroupCheck = (val, id) => {
    if (val === true) {
      setGroupIdList(prevGroupIdList => [...prevGroupIdList, id]);
    } else {
      setGroupIdList(prevGroupIdList =>
        prevGroupIdList.filter(groupId => groupId !== id)
      );
    }
  };

  const createRowItem = props => {
    const { id, temp, isChecked, groupName, category, exportName } = props;

    const rowItem = {
      id,
      editable: false,
      deletable: false,
      onClickCallback: () => {
        setOpenGroupModal(props);
      },
      temp,
      rowActionOptions: rowActionItems(
        id,
        dispatch,
        updateIdsForDelete,
        exportName
      ),
      data: {
        checkbox: {
          value: isChecked,
          purpleBorder: true,
          type: 'checkbox',
          editable: true,
          preventRowOnClickCallback: true,
          onChangeCallback: e => {
            handleGroupCheck(e, id);
          },
        },
        status: {
          value: groupName,
          type: 'string',
          editable: false,
        },
        name: {
          value: category,
          type: 'string',
          editable: false,
        },
      },
    };

    return rowItem;
  };

  const createMobileRowItem = props => {
    const { id, temp, isChecked, groupName, category, exportName } = props;

    const rowItem = {
      id,
      deletable: false,
      onClickCallback: () => {},
      temp,
      rowActionOptions: rowActionItems(
        id,
        dispatch,
        updateIdsForDelete,
        exportName
      ),
      data: {
        checkbox: {
          value: isChecked,
          purpleBorder: true,
          type: 'checkbox',
          editable: true,
          preventRowOnClickCallback: true,
          onChangeCallback: e => {
            handleGroupCheck(e, id);
          },
        },
        status: {
          value: groupName,
          type: 'string',
          editable: false,
        },
        name: {
          value: category,
          type: 'string',
          editable: false,
        },
      },
    };

    return rowItem;
  };

  useEffect(() => {
    if (groups) {
      const newRows = groups.map(group => {
        const isChecked = groupIdList.includes(group.attributes.id);
        if (isMobile) {
          return createMobileRowItem({
            id: +group.attributes.id,
            temp: false,
            groupName: group.attributes.name,
            category: group.attributes.group_type,
            isChecked,
            exportName: group.attributes.export_name,
          });
        } else {
          return createRowItem({
            id: +group.attributes.id,
            temp: false,
            groupName: group.attributes.name,
            category: group.attributes.group_type,
            isChecked,
            exportName: group.attributes.export_name,
            sessionTypes: group.attributes.session_types,
          });
        }
      });

      setRows(newRows);
    }
  }, [groups, groupIdList, isMobile]);

  return (
    <>
      {isLoading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingText}>
            <p>Loading...</p>
            <Icon name="spinner" className="fa-pulse" large />
          </div>
        </div>
      )}
      <div className={styles.GroupsTable}>
        <Table
          actionBarCount={groupIdList.length}
          headerActionOptions={headerActionItems(
            groupIdList,
            dispatch,
            updateIdsForDelete
          )}
          readOnly
          headers={ITEM_HEADERS}
          optionsDropdownButtonProps={
            isMobile
              ? {
                  options: options,
                  label: options[meta.currentTitleIndex].label,
                  lightGrey: true,
                }
              : undefined
          }
          rows={rows}
          setRows={setRows}
          loading={isLoading}
          searchInputProps={{
            name: 'group-search',
            placeholder: 'Search by name',
            width: '295px',
            lightGrey: true,
            onChangeCallback: searchValue => {
              if (searchValue) {
                if (meta.titleDisplay !== 'Search Results') {
                  dispatch(
                    updateClientListMeta({
                      beforeSearchTitleDisplay: meta.titleDisplay,
                    })
                  );
                }
                dispatch(
                  updateClientListMeta({
                    titleDisplay: 'Search Results',
                    search_term: searchValue,
                    page: 1,
                  })
                );
              } else {
                dispatch(
                  updateClientListMeta({
                    titleDisplay: meta.beforeSearchTitleDisplay,
                    search_term: null,
                    page: 1,
                  })
                );
              }
              dispatch(getGroups());
            },
          }}
          page={page}
          pageCount={page_count}
          totalRecords={total_records}
          pageChangeCallback={newPageIndex => {
            dispatch(
              updateClientListMeta({
                page: newPageIndex,
              })
            );
            dispatch(getGroups());
          }}
          pageSizeCallback={pageCount => {
            dispatch(
              updateClientListMeta({
                itemsPerPage: pageCount,
              })
            );
            dispatch(getGroups());
          }}
          itemsPerPage={meta.itemsPerPage}
        />
      </div>

      {idsForDelete.length > 0 && (
        <DeleteGroupModal
          handleHide={() => dispatch(updateIdsForDelete([]))}
          idsForDelete={idsForDelete}
          setGroupIdList={setGroupIdList}
          setChecked={setChecked}
        />
      )}
      {openGroupModal && (
        <ManageGroupModal
          handleHide={() => setOpenGroupModal(null)}
          groupData={openGroupModal}
          updateModal={true}
        />
      )}
    </>
  );
};

export default GroupsTable;
