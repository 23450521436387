import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { uniqueId } from '~utils';
import classNames from 'classnames/bind';
import { errorsSelector } from '~selectors';
import styles from './JourneyTemplate.module.scss';
import Step from './Step';
import StepForm from './StepForm';
import { SearchInput, Accordion } from '~ui';
import { updateJourneyTemplate } from '~actions/journeyTemplate';
import ToggleContent from '~components/common/ToggleContent';
import AddStepModal from './AddStepModal';
import ApplyToExistingModal from './ApplyToExistingModal';
import { MOBILE_WIDTH } from '~constants/viewports';
import { useWindowSize } from '~hooks';
import axios from 'axios';
import { routes } from '~constants/routes';

const initialNewItemFormState = {
  item: 0,
  description: '',
  schedule: 1,
  number: 1,
  scheduleInterval: 1,
  resourceId: undefined,
  resourceType: undefined,
};

const JourneyTemplate = () => {
  const { steps, name, id: journeyTemplateId } = useSelector(
    state => state.journeyTemplate.currentJourneyTemplate
  );

  const [journeys, setJourneys] = useState([]);

  useEffect(() => {
    getActiveJourneys();
  }, [steps.length]);

  const getActiveJourneys = async () => {
    try {
      const res = await axios.get(routes.JOURNEYS.ACTIVE_JOURNEYS, {
        params: { journey: { journey_template_id: journeyTemplateId } },
      });
      setJourneys(res.data.can_be_updated);
    } catch (error) {
      console.error(error);
    }
  };

  const errors = useSelector(errorsSelector);

  const newWorkflows = useSelector(state => state.journeyTemplate.newWorkflows);

  const sessionBookedSteps = steps.filter(
    step => step.trigger.trigger_type == 'SessionBooked'
  );

  const afterBookedSteps = steps
    .filter(step => step.trigger.trigger_type == 'AfterBooked')
    .sort((a, b) => {
      if (a.time_offset == null) {
        return -1;
      } else if (b.time_offset == null) {
        return 1;
      } else {
        return a.time_offset - b.time_offset;
      }
    });

  const beforeSessionSteps = steps
    .filter(step => step.trigger.trigger_type == 'BeforeSession')
    .sort((a, b) => {
      if (a.time_offset == null) {
        return -1;
      } else if (b.time_offset == null) {
        return 1;
      } else {
        return b.time_offset - a.time_offset;
      }
    });

  const allBeforeSessionSteps = sessionBookedSteps
    .concat(afterBookedSteps)
    .concat(beforeSessionSteps);

  const afterSessionSteps = steps
    .filter(step => step.trigger.trigger_type == 'AfterSession')
    .sort((a, b) => a.time_offset - b.time_offset);

  const dispatch = useDispatch();

  const [editingId, setEditingId] = useState(0);
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);
  const windowWidth = useWindowSize()[0];

  const cx = classNames.bind(styles);
  const hoverClasses = cx('JourneyTemplate-steps-clickContainer', {
    'JourneyTemplate-steps-clickContainer-hover': editingId == 0,
  });

  const handleDeleteStep = id => {
    dispatch(updateJourneyTemplate({ type: 'DELETE_STEP', deletedId: id }));
  };

  return (
    <div className={styles.JourneyTemplate}>
      {newWorkflows ? (
        <div>
          <div className={styles['JourneyTemplate-topMenu']}>
            <a
              href="/studio/workflows"
              className={styles['JourneyTemplate-topMenu-back']}
            >
              <i className="fas fa-arrow-left" /> Back to all Workflows
            </a>
            <div className={styles['JourneyTemplate-topMenu-title']}>
              <SearchInput
                name="journeyTemplateTitle"
                labelText="Workflow Title"
                className={styles['JourneyTemplate-topMenu-title-input']}
                initialValue={name}
                hideIcon
                onChangeCallback={value => {
                  dispatch(
                    updateJourneyTemplate({ type: 'UPDATE_NAME', name: value })
                  );
                }}
              />
              {errors.JOURNEY_TEMPLATE_UPDATE &&
                errors.JOURNEY_TEMPLATE_UPDATE.map(err => (
                  <p key={uniqueId('err')}>{err}</p>
                ))}
              <ToggleContent
                toggle={handleShow => (
                  <button
                    className={styles['JourneyTemplate-addButton']}
                    onClick={handleShow}
                  >
                    Add Item
                    <i className="fas fa-plus ml-6" />
                  </button>
                )}
                content={handleHide => (
                  <AddStepModal
                    handleHide={handleHide}
                    allBeforeSessionSteps={allBeforeSessionSteps}
                    afterSessionSteps={afterSessionSteps}
                    initialNewItemFormState={initialNewItemFormState}
                  />
                )}
              />
              {journeys.length > 0 && (
                <ToggleContent
                  toggle={handleShow => (
                    <button
                      className={styles['JourneyTemplate-applyButton']}
                      onClick={handleShow}
                    >
                      Apply to Existing Workflows
                    </button>
                  )}
                  content={handleHide => (
                    <ApplyToExistingModal
                      {...{
                        handleHide,
                        journeys,
                        journeyTemplateId,
                        setJourneys,
                      }}
                    />
                  )}
                />
              )}
            </div>
          </div>
          <div
            className={
              styles[
                `JourneyTemplate-${
                  windowWidth <= MOBILE_WIDTH
                    ? 'stepContainerMobile'
                    : 'stepContainer'
                }`
              ]
            }
          ></div>
          <div className={styles['JourneyTemplate-steps']}>
            {!!allBeforeSessionSteps.length && (
              <>
                <div className={styles['JourneyTemplate-header']}>
                  Before Session
                </div>
                <div className={styles['JourneyTemplate-steps']}>
                  {allBeforeSessionSteps.map(step => (
                    <div
                      key={uniqueId('step_')}
                      className={hoverClasses}
                      onClick={() => {
                        if (editingId === 0) {
                          setEditingId(step.id);
                        }
                      }}
                    >
                      <Step
                        stepName={step.name}
                        actionType={step.action.action_type}
                        timeOffset={step.time_offset}
                        stepIcon={step.action.icon}
                        stepTriggerType={step.trigger.trigger_type}
                        handleDeleteStep={() => {
                          handleDeleteStep(step.id);
                        }}
                        isEditing={step.id == editingId}
                        handleEditClose={() => setEditingId(0)}
                        step={step}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
            {!!afterSessionSteps.length && (
              <>
                <div className={styles['JourneyTemplate-header']}>
                  After Session
                </div>
                <div className={styles['JourneyTemplate-steps']}>
                  {afterSessionSteps.map(step => (
                    <div
                      key={uniqueId('step_')}
                      className={hoverClasses}
                      onClick={() => {
                        if (editingId === 0) {
                          setEditingId(step.id);
                        }
                      }}
                    >
                      <Step
                        stepName={step.name}
                        actionType={step.action.action_type}
                        timeOffset={step.time_offset}
                        stepIcon={step.action.icon}
                        stepTriggerType={step.trigger.trigger_type}
                        handleDeleteStep={() => {
                          handleDeleteStep(step.id);
                        }}
                        isEditing={step.id == editingId}
                        handleEditClose={() => setEditingId(0)}
                        step={step}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <div>
          <a
            href="/studio/workflows"
            className={styles['JourneyTemplate-back']}
          >
            <i className="fas fa-arrow-left" /> Back to all Workflows
          </a>
          <div className={styles['JourneyTemplate-title']}>
            <div>
              <SearchInput
                name="journeyTemplateTitle"
                labelText="Workflow Title"
                initialValue={name}
                hideIcon
                onChangeCallback={value => {
                  dispatch(
                    updateJourneyTemplate({ type: 'UPDATE_NAME', name: value })
                  );
                }}
              />
              {errors.JOURNEY_TEMPLATE_UPDATE &&
                errors.JOURNEY_TEMPLATE_UPDATE.map(err => (
                  <p key={uniqueId('err')}>{err}</p>
                ))}
            </div>
            <div className={styles['JourneyTemplate-title-container']}>
              {journeys.length > 0 && (
                <ToggleContent
                  toggle={handleShow => (
                    <button
                      className={styles['JourneyTemplate-applyButton']}
                      onClick={handleShow}
                    >
                      Apply to Existing Workflows
                    </button>
                  )}
                  content={handleHide => (
                    <ApplyToExistingModal
                      {...{
                        handleHide,
                        journeys,
                        journeyTemplateId,
                        setJourneys,
                      }}
                    />
                  )}
                />
              )}
            </div>
          </div>
          <div className={styles['JourneyTemplate-addStepAccordion']}>
            <Accordion
              title="Add an Item"
              disabled={editingId !== 0}
              disabledMessage="Please finish editing before adding a new item."
              expanded={isAccordionExpanded}
            >
              <StepForm
                beforeSessionSteps={allBeforeSessionSteps}
                afterSessionSteps={afterSessionSteps}
                initialFormState={initialNewItemFormState}
                handleClose={() => {
                  setIsAccordionExpanded(false);
                }}
              />
            </Accordion>
          </div>
          {!!allBeforeSessionSteps.length && (
            <>
              <div className={styles['JourneyTemplate-header']}>
                Before Session
              </div>
              <div className={styles['JourneyTemplate-steps']}>
                {allBeforeSessionSteps.map(step => (
                  <div
                    key={uniqueId('step_')}
                    className={hoverClasses}
                    onClick={() => {
                      if (editingId === 0) {
                        setIsAccordionExpanded(false);
                        setEditingId(step.id);
                      }
                    }}
                  >
                    <Step
                      stepName={step.name}
                      actionType={step.action.action_type}
                      timeOffset={step.time_offset}
                      stepIcon={step.action.icon}
                      stepTriggerType={step.trigger.trigger_type}
                      handleDeleteStep={() => {
                        handleDeleteStep(step.id);
                      }}
                      isEditing={step.id == editingId}
                      handleEditClose={() => setEditingId(0)}
                      step={step}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
          {!!afterSessionSteps.length && (
            <>
              <div className={styles['JourneyTemplate-header']}>
                After Session
              </div>
              <div className={styles['JourneyTemplate-steps']}>
                {afterSessionSteps.map(step => (
                  <div
                    key={uniqueId('step_')}
                    className={hoverClasses}
                    onClick={() => {
                      if (editingId === 0) {
                        setIsAccordionExpanded(false);
                        setEditingId(step.id);
                      }
                    }}
                  >
                    <Step
                      stepName={step.name}
                      actionType={step.action.action_type}
                      timeOffset={step.time_offset}
                      stepIcon={step.action.icon}
                      stepTriggerType={step.trigger.trigger_type}
                      handleDeleteStep={() => {
                        handleDeleteStep(step.id);
                      }}
                      isEditing={step.id == editingId}
                      handleEditClose={() => setEditingId(0)}
                      step={step}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default JourneyTemplate;
