import React from 'react';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import {
  tinymceHeight,
  vendorButtons,
  customButtons,
  vendorPlugins,
  blockFormats,
  validElements,
  contentStyle,
  variables,
} from '../../email_modal/tinymce_editor/editor_config';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';

const TinyMceInput = props => {
  const {
    labelText,
    onChangeCallback,
    value,
    bookingUrls,
    locations,
    disableVariables = false,
    readOnly,
    disabled,
  } = props;

  const setup = editor => {
    editor.ui.registry.addMenuButton('variablesReact', {
      text: 'Variables',
      fetch: function (callback) {
        const items = variables.map(function (item) {
          return {
            type: 'menuitem',
            text: item[1],
            onAction: function (_) {
              editor.insertContent(item[0]);
            },
          };
        });
        callback(items);
      },
    });

    if (bookingUrls && bookingUrls.length) {
      editor.ui.registry.addMenuButton('bookingUrlsReact', {
        text: 'Booking URLs',
        fetch: function (callback) {
          const items = bookingUrls.map(function (item) {
            return {
              type: 'menuitem',
              text: item[1],
              onAction: function (_) {
                editor.insertContent(
                  '<a href="' + item[0] + '">' + item[1] + '</a>'
                );
              },
            };
          });
          callback(items);
        },
      });
    }

    if (locations && locations.length) {
      editor.ui.registry.addMenuButton('locationsReact', {
        text: 'Locations',
        fetch: function (callback) {
          const items = locations.map(function (location) {
            return {
              type: 'menuitem',
              text: location[1],
              onAction: function (_) {
                editor.insertContent(
                  '<a href="' + location[0] + '">' + location[1] + '</a>'
                );
              },
            };
          });
          callback(items);
        },
      });
    }
  };
  const plugins = vendorPlugins;
  const toolbar = vendorButtons.concat(customButtons).join(' ');

  const uploadImage = async (file, callback) => {
    const form = new FormData();
    form.append('email_image[file]', file);
    try {
      const { data } = await axios.post('/api/email_images', form);
      callback(data.url);
    } catch (e) {
      toast.error('Error attaching file', toastOptions);
      console.error(e);
    }
  };

  return (
    <>
      {labelText && <label>{labelText}</label>}
      <Editor
        disabled={disabled || readOnly}
        value={value}
        init={{
          setup: !disableVariables ? setup : null,
          plugins,
          toolbar,
          menubar: false,
          content_style: contentStyle,
          paste_data_images: false,
          image_advtab: true,
          height: tinymceHeight,
          branding: false,
          convert_urls: false,
          theme: 'silver',
          language: 'en',
          browser_spellcheck: true,
          block_formats: blockFormats.join(';'),
          valid_elements: validElements.join(','),
          file_picker_types: 'image',
          image_title: true,
          automatic_uploads: true,
          contextmenu: false,
          file_picker_callback: function (cb, value, meta) {
            var input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            input.onchange = clickEvent => {
              if (!cb) {
                return;
              }

              const file = clickEvent.target.files[0];
              const callback = cb;
              uploadImage(file, callback);
              cb = null;
            };

            input.click();
          },
        }}
        onEditorChange={onChangeCallback}
      />
    </>
  );
};

export default TinyMceInput;
