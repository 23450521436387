import React, { useState, useEffect } from 'react';
import MappingDropdown from '../MappingDropdown';
import OptionValue from '../OptionValue';
import FieldName from '../FieldName';
import DraggableInput from '../../draggable/DraggableInput';
import styles from './MultipleCheckInput.module.scss';

const MultipleCheckInput = ({
  updateInputData,
  index,
  data,
  handleRemove,
  reOrderHandler,
  title,
  errors,
}) => {
  const [focusNewInputIndex, setFocusNewInputIndex] = useState(null);

  const removeOption = optionIndex => {
    const optionsCopy = [...data.options];
    optionsCopy.splice(optionIndex, 1);

    updateInputData(index, { options: optionsCopy });
  };

  const handleDropdown = value => {
    if (value != data.client_mapping) {
      updateInputData(index, { client_mapping: value });
    }
  };

  const handleRadio = value => {
    if (value != data.is_required) {
      updateInputData(index, { is_required: value });
    }
  };

  const handleInputChange = (value, optionIndex) => {
    const updatedOptions = [...data.options];
    updatedOptions[optionIndex] = value;
    updateInputData(index, { options: updatedOptions });
  };

  const handleAddInput = nextIndex => {
    const optionsCopy = [...data.options];
    optionsCopy.splice(nextIndex, 0, '');

    updateInputData(index, { options: optionsCopy });
    setFocusNewInputIndex(nextIndex);
  };

  useEffect(() => {
    setFocusNewInputIndex(null);
  }, [data.options]);

  return (
    <DraggableInput
      index={index}
      handleRemove={handleRemove}
      reOrderHandler={reOrderHandler}
      title={title}
    >
      <div>
        <FieldName
          name="introduction"
          initialValue={data.label}
          onChangeCallback={val => updateInputData(index, { label: val })}
          errors={errors}
        />
        <div className={styles.MultipleCheckInputStyles} disabled>
          <i className="far fa-dot-circle"></i> Multiple Check
        </div>
        {data.options.map((option, index) => (
          <OptionValue
            key={index}
            index={index}
            initialOptionvalue={option}
            onChange={handleInputChange}
            removeOption={removeOption}
            handleAddInput={handleAddInput}
            errors={errors}
            focusNewInput={index === focusNewInputIndex}
          />
        ))}
        <MappingDropdown
          radioCallback={handleRadio}
          dropdownCallback={handleDropdown}
          index={index}
          initialRadioValue={data.is_required || false}
          initialMappingValue={data.client_mapping}
          options={[
            { label: 'None', value: null },
            { label: 'Client notes', value: 'notes' },
          ]}
        />
      </div>
    </DraggableInput>
  );
};

export default MultipleCheckInput;
