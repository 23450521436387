import React, { useEffect, useState } from 'react';
import { Dropdown } from '~ui';
import styles from './MappingDropdown.module.scss';
import { useIsFirstMount } from '~hooks';

const MappingDropdown = ({
  options,
  dropdownCallback,
  radioCallback,
  index,
  initialRadioValue,
  initialMappingValue,
}) => {
  const [selectedRadio, setSelectedRadio] = useState(false);
  const isFirstMount = useIsFirstMount();

  useEffect(() => {
    if (!isFirstMount) {
      handleRadioCallback();
    }
  }, [selectedRadio]);

  useEffect(() => {
    setSelectedRadio(initialRadioValue);
  }, [initialRadioValue]);

  useEffect(() => {
    dropdownCallback(initialMappingValue);
  }, [initialMappingValue]);

  const handleRadioCallback = () => {
    radioCallback(selectedRadio);
  };

  return (
    <div className={styles.MappingDropdown}>
      <h3>Mapping</h3>
      <Dropdown
        labelText=""
        name="mappingOptions"
        initialValue={initialMappingValue || 0}
        options={options}
        onChangeCallback={dropdownCallback}
      />
      <label htmlFor="required" style={{ marginRight: '10px' }}>
        Required?{' '}
      </label>
      <input
        type="radio"
        id={`required-yes-${index}`}
        name={`required-${index}`}
        value="yes"
        onChange={() => setSelectedRadio(true)}
        checked={selectedRadio}
      />
      <label htmlFor="required-yes">Yes</label>
      <input
        type="radio"
        id={`required-no-${index}`}
        name={`required-${index}`}
        value="no"
        onChange={() => setSelectedRadio(false)}
        checked={!selectedRadio}
      />
      <label htmlFor="required-no">No</label>
    </div>
  );
};

export default MappingDropdown;
