import React, { useState } from 'react';
import styles from './BookingCalendarLink.module.scss';
import { absolutePath } from '~constants/routes';

const BookingCalendarLink = props => {
  const {
    initialBookingCalendar,
    sessionTypes,
    journeyTemplates,
    invoiceTemplates,
    contracts,
  } = props;
  const [copyText, setCopyText] = useState('Copy');

  const findTemplate = (num, arr) => {
    if (num && arr) {
      return arr.find(element => element.value === num);
    }
  };

  const invoice = findTemplate(
    initialBookingCalendar.invoice_template_id,
    invoiceTemplates
  );
  const contract = findTemplate(
    initialBookingCalendar.contract_template_id,
    contracts
  );
  const workflow = findTemplate(
    initialBookingCalendar.journey_template_id,
    journeyTemplates
  );
  const session = findTemplate(
    initialBookingCalendar.session_type_id,
    sessionTypes
  );

  const bookingURL = absolutePath(
    `/customer/booking/${initialBookingCalendar.uuid}`
  );

  return (
    <div className={styles.BookingCalendarLink}>
      <div className={styles['BookingCalendarLink-link']}>
        <div>
          <h3>Booking Link</h3>
          <p>{bookingURL}</p>
        </div>
        <div>
          <a href={bookingURL} target="_blank" className="c-m">
            <span className="fas fa-eye"></span>
            Preview
          </a>

          <a
            onClick={() => {
              navigator.clipboard.writeText(bookingURL);
              setCopyText('Copied!');
              setTimeout(() => setCopyText('Copy'), 1500);
            }}
            className="c-m"
          >
            <span className="fas fa-copy"></span>
            {copyText}
          </a>
        </div>
      </div>
      <div className={styles['BookingCalendarLink-content']}>
        <h3>Booking Title</h3>
        <p>{initialBookingCalendar.title}</p>
        {initialBookingCalendar.full_address && (
          <div>
            <h3>Location</h3>
            <p>{initialBookingCalendar.full_address}</p>
          </div>
        )}
        <div className={styles['BookingCalendarLink-content-twoColumn']}>
          {session && (
            <div>
              <h3>Session Type</h3>
              <p>{session.label}</p>
            </div>
          )}
          {workflow && (
            <div>
              <h3>Workflow</h3>
              <p>{workflow.label}</p>
            </div>
          )}
        </div>

        <h3>Notes</h3>
        <p>
          {initialBookingCalendar.notes
            ? initialBookingCalendar.notes
            : 'No notes provided'}
        </p>
        <hr />
        <div className={styles['BookingCalendarLink-content-twoColumn']}>
          {contract && (
            <div>
              <h3>Contract Template</h3>
              <p>{contract.label}</p>
            </div>
          )}
          {invoice && (
            <div>
              <h3>Invoice Template</h3>
              <p>{invoice.label}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingCalendarLink;
