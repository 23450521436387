import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { Modal, Button } from '~ui';
import { deleteGroup, getGroups, bulkUpdateGroups } from '~actions/clientList';
import { useDispatch } from 'react-redux';

const DeleteGroupModal = props => {
  const { handleHide, idsForDelete, setGroupIdList, setChecked } = props;
  const bulkDelete = idsForDelete.length > 1;

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const deleteGroups = async () => {
    setIsLoading(true);

    if (bulkDelete) {
      const res = await dispatch(
        bulkUpdateGroups({ updateAction: 'destroy', groupIds: idsForDelete })
      );
      setIsLoading(false);
      dispatch(getGroups());
      if (res.errors) {
        toast.error(
          `Error deleting! Please reach out to support.`,
          toastOptions
        );
      } else {
        toast.success(`Delete successful!`, toastOptions);
        await dispatch(getGroups());
      }
    } else {
      const res = await dispatch(deleteGroup(idsForDelete[0]));
      setIsLoading(false);

      if (res.errors) {
        toast.error(
          `Error deleting group! Please reach out to support.`,
          toastOptions
        );
      } else {
        toast.success(`Delete successful!`, toastOptions);
      }
    }
    setChecked(false);
    setGroupIdList([]);
    handleHide();
  };

  return (
    <Modal title={`Delete Group(s)`} handleHide={handleHide}>
      <div className="mb-8">
        <p>
          Are you sure you want to delete{' '}
          {bulkDelete ? <span>these groups</span> : <span>this group</span>}?
          This action cannot be undone.
        </p>
      </div>
      <br />
      <div className="d-fr">
        <Button
          className="mr-8"
          text="Cancel"
          onClick={e => {
            e.stopPropagation();
            handleHide();
          }}
          danger
        />
        <Button
          text="Delete"
          onClick={e => {
            e.stopPropagation();
            deleteGroups();
          }}
          loading={isLoading}
        />
      </div>
    </Modal>
  );
};

export default DeleteGroupModal;
