import React, { useEffect, useState, useRef } from 'react';
import styles from './InputContainer.module.scss';
import { useDrop } from 'react-dnd';
import ItemTypes from '../constants/ItemTypes';
import { INPUTS } from '../constants/inputs';
import { TextInput, Button } from '~ui';
import axios from 'axios';
import { routes } from '~constants/routes';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { useDispatch } from 'react-redux';
import { getQuestionnaires } from '~actions/questionnaires';

const InputContainer = ({ editQuestionnaire, handleHide }) => {
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState([]);
  const [formName, setFormName] = useState('');
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  const inputContainerRef = useRef();

  useEffect(() => {
    if (editQuestionnaire) {
      makeRenderableInputs();
    }
  }, [editQuestionnaire]);

  const makeRenderableInputs = () => {
    // Ensure they're sorted by their sort order
    editQuestionnaire.inputs.sort((a, b) => a.sort_order - b.sort_order);

    // Map over the inputs and find the INPUT component from inputs.js and add the data
    const renderableInputs = editQuestionnaire.inputs.map(qInput => {
      let renderableInput = INPUTS.find(
        inp => inp.data.input_type === qInput.input_type
      );

      renderableInput = { ...renderableInput, data: qInput };

      return renderableInput;
    });

    setInputs(renderableInputs);
  };

  const [{ isOver }, drop] = useDrop({
    accept: [ItemTypes.FIELD, ItemTypes.INPUT],
    drop: (item, monitor) => {
      if (item.type === ItemTypes.FIELD) {
        const tempIndex = inputs.findIndex(inp => inp.id === 'temp');
        addInputToContainer(item.id, tempIndex);
      }
    },
    hover: (item, monitor) => {
      if (item.type === ItemTypes.FIELD) {
        const clientOffset = monitor.getClientOffset();
        const clientOffsetY = clientOffset.y;
        const inputElements = [...inputContainerRef.current.children];

        if (inputElements.length > 0) {
          inputElements.forEach((el, i) => {
            // Don't consider the temp element in the loop because we don't want to move the temp input if we're hovered over itself.
            if (el.getAttribute('data-temp') === 'temp') {
              return;
            }

            // Get the top and bottom of the element to check if we're hovering over it.
            const { top, bottom } = el.getBoundingClientRect();

            // Get the midline of the element so that we can use this as a trigger to move the temp input when the user crosses while dragging.
            const elementMidline = (bottom + top) / 2;

            // If we're not hovering over the element then we don't want to consider it.
            if (!(clientOffsetY > top && clientOffsetY < bottom)) {
              return;
            }

            // Check if the hovering position is above or below the midline of the input element, and add 20px of buffer.
            if (clientOffsetY > elementMidline - 20) {
              addTempInputToContainer(i + 1);
            } else if (clientOffsetY < elementMidline + 20) {
              addTempInputToContainer(i);
            }
          });
        }
      }
    },
    collect: monitor => {
      const isFieldOver =
        monitor.getItemType() === ItemTypes.FIELD ? !!monitor.isOver() : false;

      return {
        isOver: isFieldOver,
      };
    },
  });

  useEffect(() => {
    // Adds a temp input only if we're over the drop container, but not hovering over another already existing input
    // Check collect method above for isOver prop value
    if (isOver && !inputs.some(input => input.id === 'temp')) {
      addTempInputToContainer(inputs.length);
    }

    // If user does not drop, then we remove the temp input
    if (!isOver && inputs.some(input => input.id === 'temp')) {
      const filteredInputs = [...inputs].filter(input => input.id !== 'temp');
      setInputs(filteredInputs);
    }
  }, [isOver]);

  const addTempInputToContainer = index => {
    if (inputs.some(input => input.id === 'temp')) {
      const updatedInputs = [...inputs];
      const fromIndex = updatedInputs.findIndex(inp => inp.id === 'temp');

      const existingTempInputElement = updatedInputs[fromIndex];
      const toIndex = index;

      if (fromIndex !== toIndex) {
        updatedInputs.splice(fromIndex, 1);
        updatedInputs.splice(toIndex, 0, existingTempInputElement);
        setInputs(updatedInputs);
      }
    } else {
      setInputs(inputs => {
        const updatedInputs = [...inputs];
        updatedInputs.splice(index, 0, INPUTS[0]);
        return updatedInputs;
      });
    }
  };

  const addInputToContainer = (id, index) => {
    const newInput = INPUTS.find(input => id === input.id);
    setInputs(inputs => {
      const updatedInputs = [...inputs];
      updatedInputs.splice(index, 1, newInput);
      return updatedInputs;
    });
  };

  const reOrderHandler = (dragIndex, hoverIndex) => {
    const dragItem = inputs[dragIndex];

    if (dragItem) {
      setInputs(prevState => {
        const copiedStateArray = [...prevState];

        const prevItem = copiedStateArray.splice(hoverIndex, 1, dragItem);

        copiedStateArray.splice(dragIndex, 1, prevItem[0]);

        return copiedStateArray;
      });
    }
  };

  const updateInputData = (index, incomingDataObj) => {
    const copiedInputsArray = [...inputs];

    let updatedData = {
      ...copiedInputsArray[index].data,
      ...incomingDataObj,
    };

    if (incomingDataObj.client_mapping === 'None') {
      updatedData.client_mapping = null;
    }

    copiedInputsArray[index] = {
      ...copiedInputsArray[index],
      data: updatedData,
    };

    setInputs(copiedInputsArray);
  };

  function validateObjects(inputs) {
    const visibleInputs = inputs.filter(inp => !inp.data._destroy);
    for (const obj of visibleInputs) {
      if (obj.data.label === '') {
        setErrors(true);
        return true;
      }
      if (
        ['multi_check', 'select', 'radio'].includes(obj.data.input_type) &&
        obj.data.options.some(option => option === '')
      ) {
        setErrors(true);
        return true;
      }
    }
    setErrors(false);
    return false;
  }

  const removeFromInputs = index => {
    const copiedInputsArray = [...inputs];

    copiedInputsArray[index] = {
      ...copiedInputsArray[index],
      data: { ...copiedInputsArray[index].data, _destroy: true },
    };

    setInputs(copiedInputsArray);
  };

  const getParams = () => {
    let sortOrder = 0;

    const inputsParam = inputs.map(input => {
      if (input.data._destroy) {
        return {
          ...input.data,
          sort_order: null,
        };
      } else {
        sortOrder += 1;
        return {
          ...input.data,
          sort_order: sortOrder,
        };
      }
    });

    return {
      questionnaire: {
        name: formName,
        inputs_attributes: inputsParam,
      },
    };
  };

  const handleCreate = async () => {
    setLoading(true);
    const res = validateObjects(inputs);
    if (res) {
      setLoading(false);
      toast.error('Please complete the highlighted fields.', toastOptions);
      return;
    } else {
      try {
        await axios.post(routes.QUESTIONNAIRES.CREATE, getParams());
        dispatch(getQuestionnaires());
        handleHide();
      } catch (error) {
        toast.error('Error creating.', toastOptions);
        console.log({ error });
      }
    }
    setLoading(false);
  };

  const handleEditSave = async () => {
    setLoading(true);
    const res = validateObjects(inputs);
    if (res) {
      setLoading(false);
      toast.error('Please complete the highlighted fields.', toastOptions);
      return;
    } else {
      try {
        await axios.put(
          routes.QUESTIONNAIRES.UPDATE(editQuestionnaire.id),
          getParams()
        );
        handleHide();
      } catch (error) {
        toast.error('Error saving.', toastOptions);
        console.log({ error });
      }
    }
    setLoading(false);
  };

  const disabled = !formName || !inputs.length > 0; // This will have to be revised

  drop(inputContainerRef);

  return (
    <div className={styles.InputContainer}>
      <h2>Your Questionnaire</h2>

      <div className={styles['InputContainer-header']}>
        <div>
          <TextInput
            name="questionnaireName"
            initialValue={editQuestionnaire ? editQuestionnaire.name : ''}
            onChangeCallback={setFormName}
            placeholder="Name of Form"
            setWarningForEmpty={errors}
          />
        </div>
        <div className={styles['InputContainer-header-buttons']}>
          <Button text="Cancel" whiteDanger onClick={handleHide} />
          <Button
            text="Save"
            onClick={editQuestionnaire ? handleEditSave : handleCreate}
            disabled={disabled || loading}
          />
        </div>
      </div>
      <div className={styles['InputContainer-inputs']} ref={inputContainerRef}>
        {inputs.map(({ InputComponent, data, title }, i) => (
          <React.Fragment key={i}>
            {data._destroy ? null : (
              <InputComponent
                updateInputData={updateInputData}
                data={data}
                index={i}
                handleRemove={() => removeFromInputs(i)}
                reOrderHandler={reOrderHandler}
                title={title}
                errors={errors}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default InputContainer;
