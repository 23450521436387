import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Sidebar } from '~ui';
import {
  getAllClients,
  updateClientListMeta,
  getGroups,
} from '~actions/clientList';
import { getOptions } from '../metaMap';
import { useWindowSize } from '~hooks';

const ClientListSidebar = () => {
  const { meta, groups } = useSelector(state => state.clientList);
  const dispatch = useDispatch();
  const options = getOptions(
    meta,
    dispatch,
    getAllClients,
    getGroups,
    updateClientListMeta
  );

  const windowWidth = useWindowSize()[0];
  const isMobile = windowWidth < 680;

  if (isMobile) {
    return <></>;
  } else {
    return (
      <div>
        <Sidebar options={options} />
      </div>
    );
  }
};

export default ClientListSidebar;
