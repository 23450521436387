import React, { useState, useEffect } from 'react';
import styles from './StudioQuestionnaire.module.scss';
import { Modal, Icon } from '~ui';
import { FIELDS } from './constants/fields';
import DraggableField from './draggable/DraggableField';
import InputContainer from './InputContainer';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import axios from 'axios';
import { routes } from '~constants/routes';

const StudioQuestionnaire = ({ editQuestionnaireId, handleHide }) => {
  const [questionnaire, setQuestionnaire] = useState(null);
  const [loading, setLoading] = useState(false);

  const getEditingQuestionnaire = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        routes.QUESTIONNAIRES.SHOW(editQuestionnaireId)
      );

      setLoading(false);
      setQuestionnaire(res.data.data.attributes);
    } catch (error) {
      console.log({ error });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (editQuestionnaireId) {
      getEditingQuestionnaire();
    }
  }, []);

  return (
    <Modal
      handleHide={handleHide}
      title={editQuestionnaireId ? 'Edit Questionnaire' : 'New Questionnaire'}
      maxWidth={1200}
      backgroundColor={'#f4f4f4'}
    >
      <DndProvider backend={HTML5Backend}>
        <div className={styles.StudioQuestionnaire}>
          <div className={styles['StudioQuestionnaire-fields']}>
            <div className={styles.DraggableFieldsContainer}>
              <h2>Fields</h2>
              {FIELDS.map((field, i) => (
                <React.Fragment key={i}>
                  <DraggableField field={field} />
                </React.Fragment>
              ))}
            </div>
          </div>
          <InputContainer
            editQuestionnaire={questionnaire}
            handleHide={handleHide}
          />
        </div>
      </DndProvider>
      {loading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingText}>
            <p>Loading...</p>
            <Icon name="spinner" className="fa-pulse" large />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default StudioQuestionnaire;
