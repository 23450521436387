import React from 'react';
import classNames from 'classnames/bind';
import { uniqueId } from '~utils';
import styles from './TimeSlot.module.scss';
import CalendarEvent from '../CalendarEvent';
import CronofyEvent from '../CronofyEvent';

const TimeSlot = props => {
  const { events, onDoubleClick, className, day } = props;
  const hasClassName = !!className;
  const cx = classNames.bind(styles);
  const classes = cx('TimeSlot', {
    [className]: hasClassName,
  });
  const endOfWeek = day.clone().endOf('week');
  const getWidth = event => {
    const start = event.event_uid
      ? moment(event.start.time)
      : moment(event.start_time);
    const end = event.event_uid
      ? moment(event.end.time)
      : moment(event.end_time);
    let width = event.all_day ? end.diff(start, 'days') : 1;

    if (end.isAfter(endOfWeek)) {
      width = endOfWeek.diff(start, 'days') + 1;
    }

    return `${width * 100}%`;
  };

  let customStyles = {
    position: 'absolute',
  };

  return (
    <div className={classes} onDoubleClick={onDoubleClick}>
      {events.map((event, index) => {
        const width = getWidth(event);
        const left = `${100 - (events.length - index) * width}%`;
        customStyles.width = width;
        customStyles.left = left;

        if (event.event_uid) {
          const key = uniqueId(`event${event.event_uid}_`);

          return (
            <CronofyEvent
              event={event}
              key={key}
              customStyles={customStyles}
              setHeight
            />
          );
        }

        return (
          <CalendarEvent
            event={event}
            key={uniqueId(`event${event.id}_`)}
            customStyles={customStyles}
            setHeight
          />
        );
      })}
    </div>
  );
};

export default TimeSlot;
