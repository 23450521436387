import React from 'react';
import { useSelector } from 'react-redux';
import { toCurrencyAmount } from '~utils';
import { absolutePath } from '~constants/routes';
import { FULL_DATE_FORMAT } from '~constants/datetime';
import { Pill } from '~ui';
import styles from './InvoiceDetails.module.scss';

const renderInvoiceStatus = (paid_in_full, due_date, status) => {
  if (status == 'ach_pending') {
    return <Pill text="ACH Pending" className="va-t" color="orange" small />;
  } else if (paid_in_full) {
    return <Pill text="Paid" className="va-t" color="green" small />;
  } else if (moment(due_date).isBefore(moment().startOf('day'))) {
    return <Pill text="Late" className="va-t" color="red" small />;
  }

  return <Pill text="Unpaid" className="va-t" color="orange" small />;
};

const InvoiceDetails = () => {
  const invoice = useSelector(state => state.invoice);
  const {
    totals,
    issued_at,
    client,
    sent_at,
    next_installment_date_due,
    paid_in_full,
    due_date,
    schedule,
    status,
    client_name,
  } = invoice;

  return (
    <div className={styles.InvoiceDetails}>
      <div>
        <div>
          <span>Client</span>
          <span>
            <a href={absolutePath(`/clients/${client.id}`)} target="_blank">
              {client_name}
            </a>
          </span>
        </div>
        <div>
          <span>Status</span>
          <span>{renderInvoiceStatus(paid_in_full, due_date, status)}</span>
        </div>
        <div>
          <span>Amount Remaining</span>
          <span>{toCurrencyAmount(totals.due)}</span>
        </div>
      </div>
      <div>
        <div>
          <span>Issue Date</span>
          <span>{moment(issued_at).format(FULL_DATE_FORMAT)}</span>
        </div>
        {schedule.installments.length === 1 && (
          <div>
            <span>Due Date</span>
            <span>{moment(due_date).format(FULL_DATE_FORMAT)}</span>
          </div>
        )}
        {!paid_in_full && schedule.installments.length > 1 && (
          <div>
            <span>Next Payment Due</span>
            <span>
              {moment(next_installment_date_due).format(FULL_DATE_FORMAT)}
            </span>
          </div>
        )}
        {sent_at && (
          <div>
            <span>Last Sent</span>
            <span>{moment(sent_at).format(FULL_DATE_FORMAT)}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default InvoiceDetails;
