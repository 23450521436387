import React, { useState } from 'react';
import ToggleContent from '~components/common/ToggleContent';
import { Modal, Button, DocumentUploader, Dropdown, DateTimePicker } from '~ui';
import { useSelector } from 'react-redux';
import styles from './FundyImportModal.module.scss';
import axios from 'axios';
import { routes } from '~constants/routes';
import moment from 'moment';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';

const FundyImportModal = props => {
  const { clients } = useSelector(state => state.invoice);

  const [clientId, setClientId] = useState();
  const [dueDate, setDueDate] = useState(moment());
  const [fileName, setFileName] = useState();
  const [dataString, setDataString] = useState();

  const handleSubmit = async () => {
    try {
      const res = await axios.post(routes.INVOICES.FUNDY_IMPORT, {
        invoice: {
          xml_file: dataString,
          client_id: clientId,
          due_date: dueDate,
        },
      });

      window.location.href = `/invoices/${res.data.invoice_id}`;
    } catch (error) {
      toast.error('There was an error uploading your XML file', toastOptions);
    }
  };

  const handleCancel = () => {
    setDataString();
    setFileName();
    setDueDate(moment());
    setClientId();
    handleHide();
  };

  return (
    <ToggleContent
      toggle={handleShow => (
        <Button
          text="Fundy Import"
          onClick={handleShow}
          whiteRectangle={true}
        />
      )}
      content={handleHide => (
        <Modal title={`Import Fundy Order`} handleHide={handleHide}>
          <div className={styles.FundyImportModal}>
            <div className={styles['FundyImportModal-input']}>
              <Dropdown
                labelText="Client"
                name="client"
                options={clients}
                onChangeCallback={setClientId}
              />
            </div>
            <div className={styles['FundyImportModal-input']}>
              <label>Due Date</label>
              <DateTimePicker
                name="due_date"
                onChangeCallback={setDueDate}
                disableTime
              />
            </div>
            <div className={styles['FundyImportModal-input']}>
              {!!fileName && <p>{fileName}</p>}
              <DocumentUploader
                acceptedDocumentTypes={['text/xml']}
                readAs="readAsText"
                onChange={fileData => {
                  setFileName(fileData.fileName);
                  setDataString(fileData.dataString);
                }}
                onError={error => {
                  toast.error(error.message, toastOptions);
                }}
              />
            </div>
          </div>
          <div className={styles['FundyImportModal-buttons']}>
            <Button text="Cancel" danger onClick={handleCancel} />
            <Button
              text="Submit"
              onClick={handleSubmit}
              disabled={!clientId || !fileName || !dataString || !dueDate}
            />
          </div>
        </Modal>
      )}
    />
  );
};

export default FundyImportModal;
