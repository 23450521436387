import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from '~utils';
import TableCell from './TableCell';
import { TABLE_TYPES } from '~constants/tableTypes';
import { CustomDropdown } from '~ui';

const TableRow = props => {
  const {
    data,
    hooks,
    temp,
    editable,
    editRowTempState,
    deletable,
    id,
    index,
    handleDelete,
    readOnly,
    type,
    setDisableAdd,
    editButtonOverride,
    deleteButtonOverride,
    buttonGroup,
    setRowId,
    disableButtonGroupOnEdit,
    closeEditingExternally,
    setCloseEditingExternally,
    onClickCallback = () => {},
    stylesForButtonsAndIcons,
    rowActionOptions,
  } = props;

  const [isEditing, setIsEditing] = useState(temp);

  useEffect(() => {
    if (closeEditingExternally) {
      setIsEditing(false);
      setCloseEditingExternally(false);
      setDisableAdd(false);
    }
  }, [closeEditingExternally]);
  /*
   * params are only used with a static table
   */
  let params = {};

  const names = Object.keys(data);
  const values = Object.values(data);

  names.map((name, index) => {
    params[name] = values[index].value;
  });

  useEffect(() => {
    setIsEditing(temp);
  }, [temp]);

  const handleParamChange = (value, name) => {
    const paramsBuffer = params;

    paramsBuffer[name] = value;

    params = paramsBuffer;
  };

  const handleEditButton = async e => {
    if (setRowId) {
      setRowId(id);
    }
    e.stopPropagation();
    if (editButtonOverride) {
      editButtonOverride();
      return;
    }

    if (isEditing) {
      const body = type === TABLE_TYPES.STATIC ? params : data;
      let res = true;

      if (temp) {
        if (hooks.create) {
          res = await hooks.create(body);
        }

        if (res) {
          editRowTempState(index, false);
        }
      } else {
        editRowTempState(index, false);

        if (hooks.update) {
          res = await hooks.update({ id, body });
        }
      }

      if (res) {
        setIsEditing(false);
        setDisableAdd(false);
      }
    } else {
      setIsEditing(true);
    }
  };

  const handleDeleteButton = e => {
    e.stopPropagation();
    if (deleteButtonOverride) {
      deleteButtonOverride();
      return;
    } else {
      setDisableAdd(false);
      isEditing && !temp ? setIsEditing(false) : handleDelete(index);
    }
  };

  const renderEditButton = () => {
    if (!editable) {
      return;
    }

    return (
      <a onKeyPress={handleEditButton} onClick={handleEditButton}>
        <i className={`fa ${isEditing ? 'fa-check' : 'fa-pencil-alt'}`} />
      </a>
    );
  };

  const renderDeleteButton = () => {
    if (!deletable && !isEditing) {
      return;
    }

    return (
      <a onKeyPress={handleDeleteButton} onClick={handleDeleteButton}>
        <i className={`fa ${isEditing ? 'fa-times' : 'fa-trash-alt'}`} />
      </a>
    );
  };

  const renderActionItemsButton = () => {
    return <CustomDropdown options={rowActionOptions} rowItem={true} />;
  };

  const lastIndex = values.length - 1;
  const lastCell = values[lastIndex];

  if (type === TABLE_TYPES.STATIC) {
    lastCell.onChangeCallback = value =>
      handleParamChange(value, names[lastIndex]);
  }

  return (
    <tr
      id={id || index}
      onClick={onClickCallback}
      onDoubleClick={() => {
        if (editButtonOverride || onClickCallback) {
          return;
        }
        if (!editable) {
          return;
        }
        setIsEditing(true);
      }}
    >
      {values.slice(0, -1).map((cell, idx) => {
        const name = names[idx];
        const cellId = uniqueId('cell_');
        const cellObj = { ...cell, ...{ name } };

        /*
         * if the table is static, each row will contain a callback to change params, skipping the ones with edit functions
         */
        if (type === TABLE_TYPES.STATIC) {
          if (!cellObj.onChangeCallback) {
            cellObj.onChangeCallback = value => handleParamChange(value, name);
          }
        }

        return <TableCell key={cellId} cell={cellObj} isEditing={isEditing} />;
      })}
      <TableCell
        cell={{ ...lastCell, ...{ name: names[lastIndex] } }}
        isEditing={isEditing}
      >
        {rowActionOptions ? (
          <div>{renderActionItemsButton()}</div>
        ) : !readOnly && isEditing && disableButtonGroupOnEdit ? (
          <div>
            {renderEditButton()}
            {renderDeleteButton()}
          </div>
        ) : !readOnly ? (
          <div className={stylesForButtonsAndIcons}>
            {buttonGroup && buttonGroup}
            {renderEditButton()}
            {renderDeleteButton()}
          </div>
        ) : (
          <></>
        )}
      </TableCell>
    </tr>
  );
};

TableRow.propTypes = {
  temp: PropTypes.bool,
  readOnly: PropTypes.bool,
  hooks: PropTypes.shape({
    update: PropTypes.func,
    delete: PropTypes.func,
    create: PropTypes.func,
  }),
  type: PropTypes.oneOf([TABLE_TYPES.STATIC, TABLE_TYPES.DYNAMIC]),
  setDisableAdd: PropTypes.func,
};

TableRow.defaultProps = {
  temp: false,
  readOnly: false,
  hooks: {},
  type: TABLE_TYPES.STATIC,
  setDisableAdd: () => {},
};

export default TableRow;
