import axios from 'axios';
import { routes } from '~constants/routes';

const getLastCompletedStep = (invoice, contract, client) => {
  if (invoice) {
    return 'Payment';
  } else if (contract.contract_id) {
    return 'Contract Signature';
  } else if (client.id) {
    return 'Client Info';
  }
  return 'Selected Availability';
};

export const emailStudio = async (
  client,
  selectedAvailability,
  invoice,
  contract,
  uuid,
  action
) => {
  if (client.id && selectedAvailability.id) {
    let resources = {};
    if (invoice) {
      resources['invoice'] = invoice.id;
    }

    if (contract.contract_id) {
      resources['contract'] = contract.contract_id;
    }

    await axios.post(routes.CUSTOMER_BOOKING.NOTIFY_STUDIO(uuid), {
      client_id: client.id,
      availability_id: selectedAvailability.id,
      last_completed_step: {
        name: getLastCompletedStep(invoice, contract, client),
        resources,
        action: action,
      },
    });
  }
};

export const restoreAvailability = async (selectedAvailability, token) => {
  if (selectedAvailability.id) {
    await axios.put(
      routes.BOOKING_AVAILABILITIES.UPDATE(selectedAvailability.id),
      {
        booking_availability: {
          booking_calendar_id: selectedAvailability.booking_calendar_id,
          status: 'available',
          status_updated_at: moment(),
          client_id: null,
        },
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  }
};
