import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './Pill.module.scss';

const Pill = props => {
  const { className, small, text, color } = props;
  const hasClassName = !!className;
  const cx = classNames.bind(styles);
  const classes = cx('Pill', {
    'Pill--small': small,
    [`Pill--${color}`]: !!color,
    [className]: hasClassName,
  });

  return <div className={classes}>{text}</div>;
};

Pill.propTypes = {
  className: PropTypes.string,
  small: PropTypes.bool,
  text: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['green', 'orange', 'red', 'blue', 'purple', 'gray']),
};

Pill.defaultProps = {
  className: null,
  small: false,
  color: null,
};

export default Pill;
