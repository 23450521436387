import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { API_DATE_FORMAT } from '~constants/datetime';
import { toCurrencyAmount, formatCurrencyInput } from '~utils';
import { Button, Dropdown, DateTimePicker, Modal, Table } from '~ui';
import { toastOptions } from '~constants/toasts';
import RetainerAndBalanceFields from './RetainerAndBalanceFields';
import RetainerAndMultipleFields from './RetainerAndMultipleFields';
import EqualPaymentsFields from './EqualPaymentsFields';
import { routes } from '~constants/routes';
import { FULL_DATE_TIME_FORMAT, FULL_DATE_FORMAT } from '~constants/datetime';
import styles from './AddPaymentScheduleModal.module.scss';

const planOptions = [
  { label: 'Choose a plan', value: '' },
  { label: 'Retainer + Balance', value: 'retainer_balance' },
  {
    label: 'Retainer + Multiple Payments',
    value: 'retainer_multiple',
  },
  { label: 'Multiple Equal Payments', value: 'equal' },
  { label: 'Custom', value: 'custom' },
];

const frequencyOptions = [
  { label: 'Choose a frequency', value: '' },
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Bi-Weekly', value: 'bi_weekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Bi-Monthly', value: 'bi_monthly' },
];

const FREQUENCY_OPTIONS_TO_MOMENT_PARAMS = {
  daily: { multiplier: 1, key: 'd' },
  weekly: { multiplier: 1, key: 'w' },
  bi_weekly: { multiplier: 2, key: 'w' },
  monthly: { multiplier: 1, key: 'M' },
  bi_monthly: { multiplier: 2, key: 'M' },
};

const INTERVAL_OPTIONS_TO_MOMENT_KEYS = {
  day: 'd',
  week: 'w',
  month: 'M',
  year: 'y',
};

const scheduleTriggerMap = {
  WhenReceived: 'When invoice is received',
  BeforeSession: 'Before the session',
  AfterSession: 'After the session',
  OnSessionDate: 'On the session date',
};

const getPlanFields = options => {
  if (options.basedOn == 'based_on_invoice' && !options.currentFrequency) {
    return null;
  }

  if (options.currentPlan === 'retainer_balance') {
    return (
      <RetainerAndBalanceFields
        retainerAmount={options.retainerAmount.value}
        setRetainerAmount={options.setRetainerAmount}
      />
    );
  } else if (options.currentPlan === 'retainer_multiple') {
    return (
      <RetainerAndMultipleFields
        retainerAmount={options.retainerAmount.value}
        setRetainerAmount={options.setRetainerAmount}
        numberOfPayments={options.numberOfPayments}
        setNumberOfPayments={options.setNumberOfPayments}
      />
    );
  } else if (options.currentPlan === 'equal') {
    return (
      <EqualPaymentsFields
        numberOfPayments={options.numberOfPayments}
        setNumberOfPayments={options.setNumberOfPayments}
      />
    );
  }
};

const title = 'Payment Schedule';
const subtitle = 'When you will be receiving your payments for this invoice';
const headers = ['Installment', 'Due Date', 'Amount'];

const AddPaymentScheduleModal = ({ invoice, handleHide }) => {
  const [currentPlan, setCurrentPlan] = useState(planOptions[0].value);
  const [retainerAmount, setRetainerAmount] = useState({
    type: 'currency',
    value: 0,
  });
  const [numberOfPayments, setNumberOfPayments] = useState(0);
  const [firstPaymentDueDate, setFirstPaymentDueDate] = useState(
    moment(invoice.due_date).format(API_DATE_FORMAT)
  );
  const [currentFrequency, setCurrentFrequency] = useState(
    frequencyOptions[0].value
  );
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [basedOn, setBasedOn] = useState(
    invoice.calendar_event_id ? 'based_on_session' : null
  );

  const [invoiceCalEventDetails, setInvoiceCalEventDetails] = useState();

  const getCalendarEvent = async () => {
    try {
      const res = await axios.get(
        routes.CALENDAR_EVENTS.SHOW(invoice.calendar_event_id)
      );

      setInvoiceCalEventDetails(res.data.data.attributes);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (invoice.calendar_event_id) {
      getCalendarEvent();
    }
  }, []);

  const schemaInputContainer =
    basedOn == 'based_on_session'
      ? {
          name: 'schedule',
          value: 'OnSessionDate',
          type: 'schedule_interval',
          editable: true,
        }
      : {
          name: 'due_date',
          value: '',
          type: rows.length === 0 ? 'datetime' : 'toggle_datetime',
          editable: true,
        };

  const schema = [
    { name: 'order', value: '', type: 'number' },
    schemaInputContainer,
    { name: 'amount', value: '', type: 'toggle_amount', editable: true },
  ];

  const generateTableRows = () => {
    if (currentPlan === 'custom') {
      return [{}];
    }
    let hasRetainer;
    let numberOfRows;
    if (currentPlan === 'retainer_balance') {
      hasRetainer = true;
      numberOfRows = 2;
    } else if (currentPlan === 'retainer_multiple') {
      hasRetainer = true;
      numberOfRows = numberOfPayments + 1;
    } else {
      hasRetainer = false;
      numberOfRows = numberOfPayments;
    }

    let totalToApply = Number.parseFloat(invoice.total).toFixed(2);
    const generatedRows = [...Array(numberOfRows)].map((_, i) => {
      const order = i + 1;
      let amount;
      let due_date;

      const dateMultiplier =
        basedOn === 'based_on_invoice'
          ? FREQUENCY_OPTIONS_TO_MOMENT_PARAMS[currentFrequency].multiplier
          : null;
      const dateKey =
        basedOn === 'based_on_invoice'
          ? FREQUENCY_OPTIONS_TO_MOMENT_PARAMS[currentFrequency].key
          : null;

      if (hasRetainer && i === 0) {
        if (retainerAmount.type === 'currency') {
          amount = retainerAmount.value;
        } else if (retainerAmount.type === 'percent') {
          amount = (invoice.total * retainerAmount.value).toFixed(2);
        }

        due_date = moment(firstPaymentDueDate).format(FULL_DATE_FORMAT);
      } else if (i === numberOfRows - 1) {
        amount = totalToApply;
        due_date =
          basedOn === 'based_on_invoice'
            ? moment(firstPaymentDueDate)
                .add(i * dateMultiplier, dateKey)
                .format(FULL_DATE_FORMAT)
            : null;
      } else {
        amount = Number.parseFloat(totalToApply / (numberOfRows - i)).toFixed(
          2
        );
        due_date =
          basedOn === 'based_on_invoice'
            ? moment(firstPaymentDueDate)
                .add(i * dateMultiplier, dateKey)
                .format(FULL_DATE_FORMAT)
            : null;
      }

      totalToApply = Number.parseFloat(totalToApply - amount).toFixed(2);

      // just in case the retainer amount given is > the invoice total
      if (amount < 0) {
        amount = 0;
      }

      const inputContainer =
        basedOn == 'based_on_session' && i !== 0
          ? {
              name: 'schedule',
              value:
                i === 0
                  ? moment(firstPaymentDueDate).format(FULL_DATE_FORMAT)
                  : 'On Session Date',
              editable: i !== 0,
              type: 'schedule_interval',
            }
          : basedOn === 'based_on_session' && i === 0
          ? {
              name: 'due_date',
              value: moment(firstPaymentDueDate).format(FULL_DATE_FORMAT),
              type: 'datetime',
              editable: true,
            }
          : {
              name: 'due_date',
              value: due_date,
              type: 'datetime',
              editable: true,
            };

      const isBasedOnSession = basedOn === 'based_on_session';
      const isFirstItem = i === 0;

      const getValueForBasedOnSession = (firstValue, secondValue) => {
        if (isBasedOnSession) {
          return isFirstItem ? firstValue : secondValue;
        }
        return null;
      };

      return {
        id: order,
        editable: true,
        data: [
          { name: 'order', value: order, type: 'number' },
          inputContainer,
          {
            name: 'amount',
            value: amount,
            type: 'toggle_amount',
            editable: true,
          },
        ],
        rowAsObject: {
          due_date:
            basedOn == 'based_on_session' && i == 0
              ? moment(firstPaymentDueDate).format(FULL_DATE_FORMAT)
              : basedOn == 'based_on_session'
              ? moment(invoiceCalEventDetails.start_time).format(
                  FULL_DATE_FORMAT
                )
              : due_date,
          order,
          amount,
          percent_amount: null,
          interval_amount: getValueForBasedOnSession(
            invoice.first_installment.interval_amount,
            0
          ),
          interval: getValueForBasedOnSession(
            invoice.first_installment.interval,
            'day'
          ),
          schedule_trigger: getValueForBasedOnSession(
            invoice.first_installment.schedule_trigger,
            'OnSessionDate'
          ),
        },
      };
    });

    setRows(generatedRows);
  };

  const handleCreate = params => {
    const order = rows.length + 1;

    let amount;
    if (params.percent_amount !== undefined) {
      amount = formatCurrencyInput(
        String(
          (
            Number(invoice.total) *
            (Number(params.percent_amount) / 100)
          ).toFixed(2)
        )
      );
    } else {
      amount = formatCurrencyInput(params.amount);
    }

    let due_date;
    if (params.interval_amount !== undefined) {
      due_date = moment(rows[order - 2].rowAsObject.due_date)
        .add(
          params.interval_amount,
          INTERVAL_OPTIONS_TO_MOMENT_KEYS[params.interval]
        )
        .format(FULL_DATE_FORMAT);
    } else {
      due_date = moment(params.due_date).format(FULL_DATE_FORMAT);
    }

    const getBasedOnDisplayValue = () => {
      if (params.schedule_trigger == 'OnSessionDate') {
        return 'On Session Date';
      } else if (params.schedule_trigger == 'WhenReceived') {
        return 'When Invoice is Received';
      } else {
        const intervalMap = {
          1: 'day(s)',
          7: 'week(s)',
          30: 'month(s)',
          365: 'year(s)',
        };

        return `${scheduleTriggerMap[params.schedule_trigger]} by ${
          params.schedule_offset
        } ${intervalMap[params.schedule_interval]}`;
      }
    };

    const calculateUpdatedDueDate = () => {
      const initialDateForCalc = moment(invoiceCalEventDetails.start_time);

      if (params.schedule_trigger == 'WhenReceived') {
        return moment().format(FULL_DATE_FORMAT);
      }

      if (params.schedule_trigger == 'OnSessionDate') {
        return initialDateForCalc.format(FULL_DATE_FORMAT);
      } else if (params.schedule_trigger == 'BeforeSession') {
        const days = params.schedule_offset * params.schedule_interval;

        return initialDateForCalc
          .subtract(days, 'days')
          .format(FULL_DATE_FORMAT);
      } else if (params.schedule_trigger == 'AfterSession') {
        const days = params.schedule_offset * params.schedule_interval;

        return initialDateForCalc.add(days, 'days').format(FULL_DATE_FORMAT);
      }
    };

    const inputContainer =
      basedOn === 'based_on_session'
        ? {
            name: 'schedule',
            value: getBasedOnDisplayValue(),
            editable: true,
            type: 'schedule_interval',
          }
        : {
            name: 'due_date',
            value: due_date,
            type: order === 1 ? 'datetime' : 'toggle_datetime',
            editable: true,
          };

    const newRow = {
      id: order,
      editable: true,
      data: [
        { name: 'order', value: order, type: 'number' },
        inputContainer,
        {
          name: 'amount',
          value: amount,
          type: 'toggle_amount',
          editable: true,
        },
      ],
      rowAsObject: {
        due_date:
          basedOn === 'based_on_session' ? calculateUpdatedDueDate() : due_date,
        order,
        amount,
        percent_amount: params.percent_amount
          ? params.percent_amount / 100
          : undefined,
        interval_amount:
          basedOn === 'based_on_session'
            ? params.schedule_trigger === 'OnSessionDate' ||
              params.schedule_trigger === 'WhenReceived'
              ? 0
              : +params.schedule_offset * +params.schedule_interval
            : params.interval_amount,
        interval: basedOn == 'based_on_session' ? 'day' : params.interval,
        schedule_trigger: params.schedule_trigger,
      },
    };

    if (params.schedule_trigger) {
      newRow.data[1].interval = params.schedule_trigger;

      if (
        params.schedule_trigger !== 'OnSessionDate' &&
        params.schedule_trigger !== 'WhenReceived'
      ) {
        newRow.data[1].interval_amount =
          +params.schedule_offset * +params.schedule_interval;
      }
    }

    setRows(rows.concat([newRow]));
  };

  const handleUpdate = (order, params) => {
    let amount;
    if (params.percent_amount !== undefined) {
      amount = formatCurrencyInput(
        String(
          (
            Number(invoice.total) *
            (Number(params.percent_amount) / 100)
          ).toFixed(2)
        )
      );
    } else {
      amount = formatCurrencyInput(params.amount);
    }

    let due_date;
    if (params.interval_amount !== undefined) {
      due_date = moment(rows[order - 2].rowAsObject.due_date)
        .add(
          params.interval_amount,
          INTERVAL_OPTIONS_TO_MOMENT_KEYS[params.interval]
        )
        .format(FULL_DATE_FORMAT);
    } else {
      due_date = moment(params.due_date).format(FULL_DATE_FORMAT);
    }

    const getBasedOnDisplayValue = () => {
      if (params.schedule_trigger == 'OnSessionDate') {
        return 'On Session Date';
      } else if (params.schedule_trigger == 'WhenReceived') {
        return 'When Invoice is Received';
      } else {
        const intervalMap = {
          1: 'day(s)',
          7: 'week(s)',
          30: 'month(s)',
          365: 'year(s)',
        };

        return `${scheduleTriggerMap[params.schedule_trigger]} by ${
          params.schedule_offset
        } ${intervalMap[params.schedule_interval]}`;
      }
    };

    const calculateUpdatedDueDate = () => {
      const initialDateForCalc = moment(invoiceCalEventDetails.start_time);

      if (params.schedule_trigger == 'OnSessionDate') {
        return initialDateForCalc.format(FULL_DATE_FORMAT);
      } else if (params.schedule_trigger == 'BeforeSession') {
        const days = params.schedule_offset * params.schedule_interval;

        return initialDateForCalc
          .subtract(days, 'days')
          .format(FULL_DATE_FORMAT);
      } else if (params.schedule_trigger == 'AfterSession') {
        const days = params.schedule_offset * params.schedule_interval;

        return initialDateForCalc.add(days, 'days').format(FULL_DATE_FORMAT);
      } else if (params.schedule_trigger == 'WhenReceived') {
        return moment(invoice.issue_date).format(FULL_DATE_FORMAT);
      }
    };

    const rowToUpdate = rows[order - 1];

    if (params.schedule_trigger) {
      rowToUpdate.data[1].interval = params.schedule_trigger;

      if (
        params.schedule_trigger !== 'OnSessionDate' &&
        params.schedule_trigger !== 'WhenReceived'
      ) {
        rowToUpdate.data[1].interval_amount =
          +params.schedule_offset * +params.schedule_interval;
      }
    }

    let intervalAmount;
    let intervalValue;
    let scheduleTriggerValue;

    if (order === 1) {
      intervalAmount = undefined;
      intervalValue = undefined;
      scheduleTriggerValue = undefined;
    } else {
      intervalAmount =
        basedOn === 'based_on_session'
          ? params.schedule_trigger === 'OnSessionDate' ||
            params.schedule_trigger === 'WhenReceived'
            ? 0
            : +params.schedule_offset * +params.schedule_interval
          : params.interval_amount;

      intervalValue = basedOn === 'based_on_session' ? 'day' : params.interval;
      scheduleTriggerValue = params.schedule_trigger;
    }

    rowToUpdate.data[1].value =
      basedOn == 'based_on_session' && order != 1
        ? getBasedOnDisplayValue()
        : due_date;
    rowToUpdate.data[2].value = amount;
    rowToUpdate.rowAsObject = {
      due_date:
        basedOn == 'based_on_session' && order != 1
          ? calculateUpdatedDueDate()
          : due_date,
      order,
      amount,
      percent_amount: params.percent_amount,
      interval_amount: intervalAmount,
      interval: intervalValue,
      schedule_trigger: scheduleTriggerValue,
    };

    setRows([...rows]);
  };

  const handleDelete = order => {
    const newRows = rows.filter(row => row.id !== order);
    setRows(newRows);
  };

  useEffect(() => {
    if (
      currentPlan === 'retainer_balance' &&
      retainerAmount.value &&
      firstPaymentDueDate &&
      currentFrequency
    ) {
      generateTableRows();
    } else if (
      currentPlan === 'retainer_multiple' &&
      retainerAmount.value &&
      numberOfPayments &&
      firstPaymentDueDate &&
      currentFrequency
    ) {
      generateTableRows();
    } else if (
      currentPlan === 'equal' &&
      numberOfPayments &&
      firstPaymentDueDate &&
      currentFrequency
    ) {
      generateTableRows();
    } else {
      setRows([]);
    }
  }, [
    currentPlan,
    retainerAmount.value,
    retainerAmount.type,
    numberOfPayments,
    firstPaymentDueDate,
    currentFrequency,
  ]);

  useEffect(() => {
    if (invoiceCalEventDetails && basedOn == 'based_on_session') {
      setCurrentFrequency('based_on_session');
    } else {
      setCurrentFrequency(frequencyOptions[0].value);
    }
  }, [invoiceCalEventDetails, basedOn]);

  const sortedRows =
    basedOn === 'based_on_session'
      ? rows
      : rows.sort((rowA, rowB) =>
          moment(rowA.rowAsObject.due_date).isAfter(
            moment(rowB.rowAsObject.due_date)
          )
            ? 1
            : -1
        );

  const orderCorrectedRows = sortedRows.map((row, index) => {
    const order = index + 1;
    row.id = order;
    row.data[0].value = order;
    row.rowAsObject.order = order;

    return row;
  });

  const installmentsSum = orderCorrectedRows.reduce((a, b) => {
    const firstAddend = isNaN(a) ? Number.parseFloat(a.rowAsObject.amount) : a;
    const secondAddend = isNaN(b) ? Number.parseFloat(b.rowAsObject.amount) : b;
    return Number.parseFloat((firstAddend + secondAddend).toFixed(2));
  }, 0);
  const calculationError =
    Number.parseFloat(installmentsSum).toFixed(2) !==
    Number.parseFloat(invoice.total).toFixed(2);

  return (
    <Modal title="Add Payment Schedule" handleHide={handleHide} maxWidth={940}>
      <div className={styles.AddPaymentScheduleModal}>
        <div>
          <Dropdown
            name="type_of_payment_plan"
            labelText="Type of Payment Plan"
            options={planOptions}
            initialValue={currentPlan}
            onChangeCallback={value => {
              setCurrentPlan(value);
              setRetainerAmount({ type: 'currency', value: 0 });
              setNumberOfPayments('');
              setRows([]);
            }}
          />
          <Dropdown
            name="based_on_session"
            labelText="Due Date Association"
            options={[
              { label: 'Please choose one', value: null },
              ...(invoice.calendar_event_id
                ? [
                    {
                      label: 'Relative to session date',
                      value: 'based_on_session',
                    },
                  ]
                : []),
              {
                label: 'Relative to invoice issue date',
                value: 'based_on_invoice',
              },
            ]}
            initialValue={basedOn}
            onChangeCallback={val => {
              if (val == 'Please choose one') {
                setBasedOn(null);
              } else {
                setBasedOn(val);
              }
              if (val === 'based_on_session') {
                setCurrentFrequency({
                  value: invoice.schedule_trigger,
                });
              }
            }}
          />
          {currentPlan !== 'custom' &&
            getPlanFields({
              currentPlan,
              retainerAmount,
              setRetainerAmount,
              numberOfPayments,
              setNumberOfPayments: value => {
                setNumberOfPayments(+value);
              },
              currentFrequency,
              basedOn,
            })}
        </div>
        <div>
          {currentPlan !== 'custom' && (
            <>
              {basedOn === 'based_on_invoice' ? (
                <>
                  <DateTimePicker
                    name="first_payment_due_date"
                    labelText="First Payment Due Date"
                    disableTime
                    initialDateTime={firstPaymentDueDate}
                    onChangeCallback={value => {
                      setFirstPaymentDueDate(value);
                    }}
                  />
                  <Dropdown
                    name="payment_frequency"
                    labelText="Payment Frequency"
                    options={frequencyOptions}
                    initialValue={currentFrequency}
                    onChangeCallback={value => {
                      setCurrentFrequency(value);
                    }}
                  />
                </>
              ) : null}
            </>
          )}
          {basedOn === 'based_on_session' && invoiceCalEventDetails ? (
            <div>
              <h2>Session</h2>
              <p>{`${invoiceCalEventDetails.title} - ${moment(
                invoiceCalEventDetails.start_time
              ).format(FULL_DATE_TIME_FORMAT)}`}</p>
            </div>
          ) : null}
        </div>
      </div>
      {(rows.length > 0 || currentPlan === 'custom') && (
        <div className={styles.AddPaymentScheduleModalTableContainer}>
          <Table
            headers={headers}
            schema={schema}
            rows={orderCorrectedRows}
            title={title}
            subtitle={subtitle}
            handleCreate={handleCreate}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
          />
          {calculationError && (
            <p className={styles['AddPaymentScheduleModal-calculationError']}>
              * The sum of these installments (
              {toCurrencyAmount(installmentsSum)}) does not add up to the total
              amount due ({toCurrencyAmount(invoice.total)}) for this invoice.
            </p>
          )}
        </div>
      )}
      <div className={styles.AddPaymentScheduleModalButtons}>
        <Button text="Cancel" danger onClick={handleHide} />
        <Button
          text="Finish"
          disabled={calculationError || loading || rows.length === 0}
          loading={loading}
          onClick={() => {
            setLoading(true);
            const paymentScheduleId = invoice.schedule.id;
            const installments = rows.map(row => ({
              ...row.rowAsObject,
              payment_schedule_id: paymentScheduleId,
            }));
            axios
              .post('/api/payment_schedule_installments/batch_create', {
                payment_schedule_installment: {
                  payment_schedule_id: paymentScheduleId,
                  collection: installments,
                },
              })
              .then(() => {
                handleHide();
                setLoading(false);
                toast.info(
                  'Looks like you’ve changed the due dates on this invoice! Please re-send the invoice if you want Iris to automatically remind your client when a payment is due.',
                  { ...toastOptions, autoClose: false }
                );
              });
          }}
        />
      </div>
    </Modal>
  );
};

export default AddPaymentScheduleModal;
