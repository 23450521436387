import React from 'react';
import moment from 'moment';
import momenttz from 'moment-timezone';
import classNames from 'classnames/bind';
import { uniqueId } from '~utils/misc';
import {
  FULL_DATE_FORMAT,
  TIME_FORMAT,
  DATE_TIME_FORMAT,
  DATE_FORMAT,
} from '~constants/datetime';
import styles from './AvailabilitySummary.module.scss';

const AvailabilitySummary = ({
  day,
  availabilities,
  activeSlot,
  setActiveSlot,
  onClickCallback,
}) => {
  const cx = classNames.bind(styles);

  return (
    <div className={styles['AvailabilitySummary']}>
      <h1>{moment(day).format(FULL_DATE_FORMAT)}</h1>
      <h3>{`${availabilities.length} ${
        availabilities.length === 1 ? 'option' : 'options'
      }`}</h3>
      {availabilities
        .sort((a, b) => {
          if (moment(a.start_time).isBefore(moment(b.start_time))) {
            return -1;
          } else if (moment(b.start_time).isBefore(moment(a.start_time))) {
            return 1;
          }

          return 0;
        })
        .map(availability => {
          const classes = cx('AvailabilitySummary-slot', {
            'AvailabilitySummary-slot--active':
              activeSlot && activeSlot.id === availability.id,
          });
          const timeZoneGuess = momenttz.tz.guess();
          const startTime = momenttz(availability.start_time).tz(timeZoneGuess);
          const endTime = momenttz(availability.end_time).tz(timeZoneGuess);

          return (
            <div
              className={classes}
              onClick={() => {
                onClickCallback(availability);
                setActiveSlot(availability);
              }}
              key={uniqueId('Slot_')}
            >
              {availability.all_day ? (
                <span>
                  All Day{''}
                  {endTime
                    .clone()
                    .startOf('day')
                    .isBetween(startTime, endTime) &&
                    `, ${startTime.format(DATE_FORMAT)} - ${endTime.format(
                      DATE_FORMAT
                    )}`}
                </span>
              ) : (
                <>
                  {endTime.diff(startTime, 'hour') > 24 ||
                  endTime
                    .clone()
                    .startOf('day')
                    .isBetween(startTime, endTime) ? (
                    <span>{`${startTime.format(
                      DATE_TIME_FORMAT
                    )} - ${endTime.format(DATE_TIME_FORMAT)}`}</span>
                  ) : (
                    <span>{`${startTime.format(TIME_FORMAT)} - ${endTime.format(
                      TIME_FORMAT
                    )}`}</span>
                  )}
                </>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default AvailabilitySummary;
