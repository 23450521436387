import React, { useState } from 'react';
import {
  Modal,
  Button,
  TextInput,
  Dropdown,
  DateTimePicker,
  Checkbox,
} from '~ui';
import styles from './UpdateCouponModal.module.scss';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { updateCoupon, getCouponCodeList } from '~actions/couponCodes';
import { useDispatch } from 'react-redux';
import moment from 'moment';

const UpdateCouponModal = props => {
  const { handleHide, studio_id, couponForUpdate } = props;

  const {
    id,
    code,
    discountAmount,
    maxRedemptions,
    discountType,
    expirationDate,
    firstInstallment,
  } = couponForUpdate;

  const timeZoneGuess = moment.tz.guess();

  const [updateCouponParams, setUpdateCouponParams] = useState({
    studio_id: studio_id,
    expiration_date: expirationDate,
    first_installment: firstInstallment,
  });
  const [isLoading, setIsLoading] = useState(false);

  const originalDate = moment(expirationDate);
  const newDate = moment(updateCouponParams.expiration_date);

  let disabled =
    (originalDate.isSame(newDate, 'day') ||
      (expirationDate == 'None' &&
        updateCouponParams.expiration_date == 'None')) &&
    ((maxRedemptions == null && updateCouponParams.redemptions === '') ||
      (maxRedemptions != null &&
        maxRedemptions == updateCouponParams.redemptions));

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    setIsLoading(true);

    if (validateDate()) {
      toast.error(
        `Please ensure the expiration date is in the future.`,
        toastOptions
      );
      setIsLoading(false);
      return;
    }

    const payload = { ...updateCouponParams };

    if (originalDate.isSame(newDate, 'day')) {
      delete payload.expiration_date;
    }

    const updateRes = await dispatch(
      updateCoupon(id, { coupon_code: payload })
    );

    if (updateRes.errors) {
      toast.error(
        `Error updating coupon. Please reach out to support.`,
        toastOptions
      );
      setIsLoading(false);
    } else {
      dispatch(getCouponCodeList());
      toast.success(`Coupon was updated!`, toastOptions);
      handleHide();
    }
  };

  const validateDate = () => {
    if (!originalDate.isSame(newDate, 'day')) {
      const isDateInThePast = newDate.isBefore(moment(), 'day');

      if (isDateInThePast) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <Modal title={`Update Coupon Code`} handleHide={handleHide} maxWidth={500}>
      <div className={styles['UpdateCouponModal-row']}>
        <div>
          <TextInput
            name="discountCode"
            labelText={'Discount Code'}
            onChangeCallback={e => { }}
            initialValue={code}
            required
            disabled
          />
          <p className={styles['UpdateCouponModal-note']}>
            Customers will enter this discount code at checkout.
          </p>
        </div>
      </div>
      <div className={styles['UpdateCouponModal-row']}>
        <div>
          <Dropdown
            labelText={'Discount Type'}
            options={[
              { label: 'Choose discount type', value: '' },
              { label: 'Percentage', value: 'percentage' },
              { label: 'Dollar amount', value: 'money_amount' },
            ]}
            name="discountType"
            onChangeCallback={e => { }}
            initialValue={discountType}
            required
            disabled
          />
        </div>
        <div>
          <TextInput
            name="discountValue"
            labelText={'Discount Value'}
            onChangeCallback={e => { }}
            initialValue={discountAmount}
            required
            disabled
          />
        </div>
      </div>

      <div className={styles['UpdateCouponModal-row']}>
        <div>
          <DateTimePicker
            name="expirationDate"
            labelText="Expiration Date"
            disableTime={true}
            onChangeCallback={date => {
              const convertedDate = moment(date).tz(timeZoneGuess).format('YYYY-MM-DD');
              setUpdateCouponParams({
                ...updateCouponParams,
                expiration_date: convertedDate,
              });
            }}
            initialDateTime={expirationDate == 'None' ? null : expirationDate}
            onBlurInput={date => {
              const convertedDate = moment(date).tz(timeZoneGuess).format('YYYY-MM-DD');
              setUpdateCouponParams({
                ...updateCouponParams,
                expiration_date: convertedDate,
              });
            }}
            makeMaxWidth
            icon={'fa fa-calendar-alt'}
          />
        </div>
        <div>
          <TextInput
            name="maximumRedemptions"
            labelText={'Maximum Redemptions'}
            onChangeCallback={e => {
              setUpdateCouponParams({
                ...updateCouponParams,
                redemptions: e,
              });
            }}
            initialValue={
              maxRedemptions == null ? '' : maxRedemptions.toString()
            }
          />
        </div>
      </div>
      <div className={styles['UpdateCouponModal-row']}>
        <div>
          <Checkbox
            name="preventOnFirstInstallment"
            labelText={'Prevent on first installment'}
            checked={
              updateCouponParams.first_installment == false ? false : true
            }
            onChangeCallback={e => { }}
            disabled
          />
          <p className={styles['UpdateCouponModal-note']}>
            By checking this box, your coupon code will not be applied to the
            first invoice installment. However, it will be applied to all
            subsequent installments.
          </p>
        </div>
      </div>
      <hr></hr>

      <div className={styles['UpdateCouponModal-buttons']}>
        <Button
          text="Cancel"
          onClick={() => {
            handleHide();
          }}
          danger
        />
        <Button
          onClick={handleSubmit}
          text="Save"
          disabled={disabled}
          loading={isLoading}
        />
      </div>
    </Modal>
  );
};

export default UpdateCouponModal;
