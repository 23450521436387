import React from 'react';
import PT from 'prop-types';

import Modal from '../modal/modal';
import EmailModalContentContainer from './email_modal_content/email_modal_content_container';

import styles from './email_modal.module.scss';

class EmailModal extends React.PureComponent {
  static propTypes = {
    button_label: PT.string,
    button_class_name: PT.string,
    client_id: PT.number,
    client_full_name: PT.string,
    bookingUrls: PT.array,
    locations: PT.array,
  };

  render() {
    const {
      button_label,
      button_class_name,
      client_id,
      client_full_name,
      bookingUrls,
      locations,
      checkSendLimit,
      activeTrial,
      trialAccountVerified,
    } = this.props;

    const modalTitle = `Email ${client_full_name}`;

    return (
      <Modal
        title={modalTitle}
        buttonLabel={button_label || 'Send Email'}
        buttonProps={{ bsClass: button_class_name }}
        modalProps={{ bsSize: 'large' }}
        titleProps={{ bsClass: styles.emailModalTitle }}
      >
        {({ closeModal }) => (
          <EmailModalContentContainer
            closeModal={closeModal}
            client_id={client_id}
            generateParams={{ client_id }}
            showSessionChooser
            bookingUrls={bookingUrls}
            locations={locations}
            checkSendLimit={checkSendLimit}
            activeTrial={activeTrial}
            trialAccountVerified={trialAccountVerified}
          />
        )}
      </Modal>
    );
  }
}

export default EmailModal;
