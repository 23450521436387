import React from 'react';
import styles from './PhotoLayout.module.scss';
import SessionTypeIcon from '../SessionTypeIcon';
import Form from '../Form';
import { Dropdown } from '~ui';

const PhotoLayout = props => {
  const {
    isSubmitted,
    headline,
    displaySessionIcons,
    sessionTypes,
    dropdownOptions,
    chosenSessionTypeIds,
    setChosenSessionTypeIds,
    setChosenSessionTypeNames,
    handleSessionType,
    chosenSessionTypeNames,
    submitColor,
    sitekey,
    studioId,
    setIsSubmitted,
    customFields,
    leadsPhoto,
    displayLogo,
    logo,
    transparentBackground,
    showEmailMarketing,
    redirectURL,
  } = props;

  const handleSessionTypeChange = (val) => {
    const selectedValue = parseInt(val, 10);

    if (selectedValue === 0) {
      setChosenSessionTypeIds([]);
      setChosenSessionTypeNames([]);
    } else {
      setChosenSessionTypeIds([val]);
      setChosenSessionTypeNames([
        sessionTypes.find(st => val == st.id).name,
      ]);
    }
  }

  return (
    <div
      className={
        styles[transparentBackground ? 'Transparent' : 'NonTransparent']
      }
    >
      <div className={styles.PhotoLayout}>
        {!isSubmitted ? (
          <>
            <div
              className={styles['PhotoLayout-PhotoContainer']}
              style={{ backgroundImage: `url(${leadsPhoto})` }}
            ></div>
            <div>
              {displayLogo && (
                <div className={styles['PhotoLayout-logo']}>
                  <img src={logo} alt="logo" />
                </div>
              )}
              <div className={styles['PhotoLayout-sessionTypes']}>
                <h1>{headline}</h1>
                {displaySessionIcons ? (
                  <div className={styles['PhotoLayout-sessionTypes-icons']}>
                    {sessionTypes.map((st, i) => (
                      <SessionTypeIcon
                        key={i}
                        {...{
                          chosenSessionTypeIds,
                          handleSessionType,
                          st,
                        }}
                      />
                    ))}
                  </div>
                ) : (
                  <div className={styles['PhotoLayout-sessionTypes-dropdown']}>
                    <Dropdown
                      options={dropdownOptions}
                      onChangeCallback={handleSessionTypeChange}
                      name="sessionTypeDropdown"
                    />
                  </div>
                )}
              </div>
              <Form
                {...{
                  chosenSessionTypeIds,
                  chosenSessionTypeNames,
                  submitColor,
                  sitekey,
                  studioId,
                  setIsSubmitted,
                  customFields,
                  showEmailMarketing,
                  redirectURL,
                }}
              />
            </div>
          </>
        ) : (
          <div className={styles['PhotoLayout-submitted']}>
            <h1>Thank you!</h1>
            <p>I'll be in touch soon with next steps on your session!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PhotoLayout;
