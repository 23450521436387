import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { TableNew as Table } from '~ui';
import { PRODUCT_HEADERS } from '~constants/invoices';
import {
  createProduct,
  updateProduct,
  deleteProduct,
  checkLineItemTemplates,
} from '~actions/invoiceProducts';
import { clearErrors } from '~actions/errors';
import { actionsLoadingSelector, actionsErroredSelector } from '~selectors';
import { toastOptions } from '~constants/toasts';
import { isEmpty, alphabetize } from '~utils';
import styles from './InvoiceSetup.module.scss';
import InvoiceProductAlertModal from './InvoiceProductAlertModal';
import { useIsFirstMount } from '~hooks';

const InvoiceProductsSection = ({ invoiceId, getProductList }) => {
  const dispatch = useDispatch();
  const products = useSelector(state => state.invoiceProducts.products);
  const studio = useSelector(state => state.invoiceProducts.studio);
  const hasQuickbooks = useSelector(
    state => state.invoiceProducts.meta.features.hasQuickbooks
  );
  const quickbooksItemOptions = useSelector(
    state => state.invoiceProducts.meta.quickbooksItemOptions
  );
  const productTemplateList = useSelector(
    state => state.invoiceProducts.productTemplateList
  );
  const isFirstMount = useIsFirstMount();

  const isLoading = useSelector(state =>
    actionsLoadingSelector(state, [
      'INVOICE_PRODUCTS_CREATE',
      'INVOICE_PRODUCTS_UPDATE',
      'INVOICE_PRODUCTS_DELETE',
    ])
  );
  const errors = useSelector(state =>
    actionsErroredSelector(state, [
      'INVOICE_PRODUCTS_CREATE',
      'INVOICE_PRODUCTS_UPDATE',
      'INVOICE_PRODUCTS_DELETE',
    ])
  );

  const [rows, setRows] = useState([]);
  const [productMap, setProductMap] = useState({});

  const createRow = (
    id = null,
    temp = false,
    name,
    description,
    price,
    taxable,
    quick_books_value = null
  ) => {
    const row = {
      id,
      editable: true,
      deletable: true,
      temp,
      data: {
        name: {
          value: name,
          type: 'string',
          editable: true,
        },
        description: {
          cellStyles: { maxWidth: '400px', overflowWrap: 'anywhere' },
          editValue: description,
          value: <p>{description}</p>,
          type: 'editableTextNode',
          editable: true,
        },
        price: {
          value: price,
          type: 'currency',
          editable: true,
        },
        taxable: {
          value: taxable,
          type: 'boolean',
          editable: true,
        },
      },
    };

    if (hasQuickbooks) {
      row.data['quickbooks_item_id'] = {
        value: quick_books_value,
        options: quickbooksItemOptions,
        type: 'enum',
        editable: true,
      };
    }

    return row;
  };

  const getProductIDAndPrice = newRows => {
    const productMap = {};

    newRows.forEach(row => {
      const productID = row.id;
      const price = row.data.price.value;

      productMap[productID] = price;
    });

    return productMap;
  };

  const productPriceChanged = (productId, price) => {
    return productMap.hasOwnProperty(productId)
      ? productMap[productId] != price
      : false;
  };

  useEffect(() => {
    if (products.length > 0 && !invoiceId) {
      const newRows = products.sort(alphabetize).map(product => {
        const { id, name, description, price, taxable } = product;

        let quick_books_value = null;

        if (hasQuickbooks && product['quickbooks_item_id']) {
          quick_books_value = product['quickbooks_item_id'].toString();
        }

        return createRow(
          id,
          false,
          name,
          description,
          price,
          taxable,
          quick_books_value
        );
      });

      setRows([...newRows]);

      if (isFirstMount) {
        const productMap = getProductIDAndPrice(newRows);
        setProductMap(productMap);
      }
    }
  }, [products]);

  const getErrorMessage = () =>
    Object.keys(errors).reduce((acc, key) => {
      acc += unescape(errors[key]);
      return acc;
    }, '');

  useEffect(() => {
    if (!isEmpty(errors)) {
      toast.error(
        `There was a problem saving your changes: ${getErrorMessage()}.`,
        toastOptions
      );
      dispatch(clearErrors());
    }
  }, [isLoading]);

  const schema = createRow(null, false, '', '', '0.00', false);
  const [product, setProduct] = useState();

  const handleCreate = async (productObj, setProduct) => {
    const wrappedParams = {
      invoice_product: { ...productObj, studio_id: studio.id },
    };

    dispatch(createProduct(wrappedParams, setProduct));
  };

  const updateHook = async params => {
    const { id, body } = params;
    const { description, name, price } = body;

    if (description === '' || name === '') {
      toast.error(
        `The following fields are required: ${
          description === '' ? 'description\n' : ''
        } ${name === '' ? 'name\n' : ''}`,
        toastOptions
      );

      return false;
    }

    const wrappedParams = {
      invoice_product: {
        ...body,
        price: Number(price.replace(/[^0-9.-]+/g, '')),
      },
    };

    if (hasQuickbooks) {
      wrappedParams.invoice_product['quickbooks_item_id'] = Number(
        body['quickbooks_item_id']
      );
    }

    dispatch(updateProduct(id, wrappedParams));

    let priceHasChanged = productPriceChanged(
      id,
      wrappedParams.invoice_product.price
    );

    if (priceHasChanged) {
      dispatch(checkLineItemTemplates(id));
    }

    return true;
  };

  const deleteHook = async params => {
    const { id } = params;
    if (!id) return;
    const wrappedParams = { invoice_product: null };
    dispatch(deleteProduct(id, wrappedParams));
  };

  const createHook = async product => {
    const { description, name, price, taxable } = product;

    if (description === '' || name === '') {
      toast.error(
        `The following fields are required: ${
          description === '' ? 'description\n' : ''
        } ${name === '' ? 'name\n' : ''}`,
        toastOptions
      );

      return false;
    }

    if (description.length > 255) {
      toast.error(
        `The description cannot exceed 255 characters.`,
        toastOptions
      );

      return false;
    }

    const productObj = {
      description,
      name,
      price: Number(price.replace(/[^0-9.-]+/g, '')),
      taxable,
    };

    if (hasQuickbooks) {
      productObj['quickbooks_item_id'] = product.quickbooks_item_id;
    }

    try {
      await handleCreate(productObj, setProduct);

      return true;
    } catch (error) {
      console.error({ error });
      return false;
    }
  };

  useEffect(() => {
    if (product) {
      getThenAdd(product);
    }
  }, [product]);

  const getThenAdd = product => {
    if (invoiceId) {
      getProductList(product);
    }
  };

  const hooks = {
    create: createHook,
    update: updateHook,
    delete: deleteHook,
  };

  return (
    <>
      <Table
        stylesForButtonsAndIcons={styles.ButtonStyles}
        title={invoiceId ? '' : 'Product'}
        headers={PRODUCT_HEADERS(hasQuickbooks)}
        rows={rows}
        setRows={setRows}
        schema={schema}
        hooks={hooks}
        createRowOnLoad={invoiceId ? true : false}
        floatNewRowToTop
      />
      {productTemplateList && productTemplateList.length > 0 && (
        <InvoiceProductAlertModal
          handleHide={() => dispatch(checkLineItemTemplates(null, true))}
          templateNames={productTemplateList}
        />
      )}
    </>
  );
};

export default InvoiceProductsSection;
