import React from 'react';
import InvoicesContent from '../../Invoices/InvoicesContent/index';
import InvoicesSidebar from '../../Invoices/InvoicesSidebar';
import { Provider } from 'react-redux';
import { configureStore } from '../../../store';
import ClientInvoicesHeader from './ClientInvoicesHeader/ClientInvoicesHeader';
import { statusMetaMap } from '../../Invoices/InvoicesSidebar/options';

const ClientInvoicesRoot = props => {
  const searchParams = new URLSearchParams(document.location.search);
  const statusParam = searchParams.get('status');

  const initialStoreMeta = statusMetaMap[statusParam] || statusMetaMap.open;

  const store = configureStore({
    invoice: {
      ...props,
      meta: {
        ...initialStoreMeta,
        total_records: 0,
        page_count: 0,
        due_date: { start: null, end: null },
        client_id: props.client_id,
      },
    },
  });

  return (
    <Provider store={store}>
      <>
        <ClientInvoicesHeader />
        <div style={{ display: 'flex' }}>
          <div>
            <InvoicesSidebar fromClients />
          </div>
          <div style={{ flex: '1' }}>
            <InvoicesContent />
          </div>
        </div>
      </>
    </Provider>
  );
};

export default ClientInvoicesRoot;
