import React, { useState } from 'react';
import { Modal, Button, TextInput, Toggle } from '~ui';
import axios from 'axios';
import { toast } from 'react-toastify';

const AdditionalDetailsModal = ({
  handleHide,
  additionalDetails,
  setAdditionalDetails,
  id,
  value,
}) => {
  const [tempAdditionalDetails, setTempAdditionalDetails] = useState(
    additionalDetails
  );

  let title;
  if (value === 'E-Mktg') {
    title = 'Edit Email Marketing';
  } else if (value === 'weddingDate') {
    title = 'Edit Wedding Date';
  } else if (value === 'referredBy') {
    title = 'Edit Referred By';
  }

  const handleSubmit = async () => {
    const detailsParams = {};

    if (additionalDetails.weddingDate !== tempAdditionalDetails.weddingDate) {
      detailsParams.wedding_date = moment(
        tempAdditionalDetails.weddingDate
      ).format('DD-MM-YYYY');
    }
    if (additionalDetails.referredBy !== tempAdditionalDetails.referredBy) {
      detailsParams.referred_by = tempAdditionalDetails.referredBy;
    }
    if (
      additionalDetails.subscribedToEmailMarketing !==
      tempAdditionalDetails.subscribedToEmailMarketing
    ) {
      detailsParams.email_marketing =
        tempAdditionalDetails.subscribedToEmailMarketing;
    }

    const params = {
      client: detailsParams,
    };

    try {
      await axios.put(`/api/clients/${id}`, params);
      setAdditionalDetails(tempAdditionalDetails);
      toast.success('Updated!');
    } catch (error) {
      toast.error('Error updating!');
    }
  };

  return (
    <Modal title={title} handleHide={handleHide}>
      {value === 'weddingDate' && (
        <div className="fg-1">
          <div className="mb-8">Wedding Date</div>
          <TextInput
            name="companyProfileInput"
            onChangeCallback={val =>
              setTempAdditionalDetails({
                ...tempAdditionalDetails,
                weddingDate: moment(val).format('MM/DD/YYYY'),
              })
            }
            initialValue={
              tempAdditionalDetails.weddingDate
                ? moment(tempAdditionalDetails.weddingDate).format('YYYY-MM-DD')
                : ''
            }
            type="date"
          />
        </div>
      )}
      {value === 'referredBy' && (
        <div className="fg-1">
          <div className="mb-8">Referred By</div>
          <TextInput
            name="referredBy"
            onChangeCallback={val =>
              setTempAdditionalDetails({
                ...tempAdditionalDetails,
                referredBy: val,
              })
            }
            initialValue={tempAdditionalDetails.referredBy}
          />
        </div>
      )}
      {value === 'E-Mktg' && (
        <div className="fg-1">
          <strong>
            <div className="mb-8">
              Ensure you have the client's permission before enrolling them into
              email marketing.{' '}
            </div>
          </strong>
          <div className="mb-8">Enroll this client in email marketing? </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '150px',
              justifyContent: 'space-evenly',
            }}
          >
            <div>No </div>
            <Toggle
              initialValue={tempAdditionalDetails.subscribedToEmailMarketing}
              onChangeCallback={val => {
                setTempAdditionalDetails({
                  ...tempAdditionalDetails,
                  subscribedToEmailMarketing: val,
                });
              }}
            />
            <div> Yes</div>
          </div>
        </div>
      )}
      <div className="d-fr mt-8">
        <Button className="mr-8" text="Cancel" onClick={handleHide} danger />
        <Button
          text="Update"
          onClick={() => {
            handleSubmit();
            handleHide();
          }}
        />
      </div>
    </Modal>
  );
};

export default AdditionalDetailsModal;
