import React from 'react';
import { TextInput } from '~ui';

const EqualPaymentsFields = ({ numberOfPayments, setNumberOfPayments }) => (
  <TextInput
    name="number_of_payments"
    labelText="Number of Payments"
    initialValue={numberOfPayments}
    onChangeCallback={setNumberOfPayments}
  />
);

export default EqualPaymentsFields;
