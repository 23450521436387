import axios from 'axios';
import { routes, absolutePath } from '~constants/routes';
import { CALENDAR_EVENTS } from '~constants/actionTypes';

export const listCalendarEvents = params => async dispatch => {
  try {
    dispatch({ type: CALENDAR_EVENTS.LIST.REQUEST });

    const res = await axios.get(routes.GROUPED_CALENDAR_EVENTS.INDEX, {
      params,
    });

    dispatch({ type: CALENDAR_EVENTS.LIST.SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: CALENDAR_EVENTS.LIST.FAILURE,
      payload: error,
      error: true,
    });
  }
};

export const getCalendarEvents = params => async dispatch => {
  try {
    dispatch({ type: CALENDAR_EVENTS.GET.REQUEST });

    const res = await axios.get(routes.CALENDAR_EVENTS.INDEX, {
      params,
    });

    dispatch({ type: CALENDAR_EVENTS.GET.SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: CALENDAR_EVENTS.GET.FAILURE,
      payload: error,
      error: true,
    });
  }
};

export const createCalendarEvent = params => async dispatch => {
  try {
    dispatch({ type: CALENDAR_EVENTS.CREATE.REQUEST });

    const event = await axios
      .post(absolutePath(routes.CALENDAR_EVENTS.CREATE), params)
      .then(res => res.data.data.attributes);

    dispatch({ type: CALENDAR_EVENTS.CREATE.SUCCESS, payload: event });
    return { res: 'success', errors: null };
  } catch (error) {
    dispatch({
      type: CALENDAR_EVENTS.CREATE.FAILURE,
      payload: error,
      error: true,
    });
    return { res: 'error', errors: error.response.data.error.messages };
  }
};

export const updateCalendarEvent = (eventId, params) => async dispatch => {
  try {
    dispatch({ type: CALENDAR_EVENTS.UPDATE.REQUEST });

    const event = await axios
      .put(routes.CALENDAR_EVENTS.UPDATE(eventId), params)
      .then(res => res.data.data.attributes);

    dispatch({ type: CALENDAR_EVENTS.UPDATE.SUCCESS, payload: event });
    return { res: 'success', errors: null };
  } catch (error) {
    dispatch({
      type: CALENDAR_EVENTS.UPDATE.FAILURE,
      payload: error,
      error: true,
    });
    return { res: 'error', errors: error.response.data.error.messages };
  }
};

export const deleteCalendarEvent = eventId => async dispatch => {
  try {
    dispatch({ type: CALENDAR_EVENTS.DELETE.REQUEST });

    const res = await axios.delete(routes.CALENDAR_EVENTS.DELETE(eventId));

    dispatch({ type: CALENDAR_EVENTS.DELETE.SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: CALENDAR_EVENTS.DELETE.FAILURE,
      payload: error,
      error: true,
    });
  }
};

export const getSessionTypeCount = params => async dispatch => {
  try {
    dispatch({ type: CALENDAR_EVENTS.GET_SESSION_TYPE_COUNT.REQUEST });

    const res = await axios.get(routes.CALENDAR_EVENTS.GET_SESSION_TYPE_COUNT, {
      params,
    });

    dispatch({
      type: CALENDAR_EVENTS.GET_SESSION_TYPE_COUNT.SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: CALENDAR_EVENTS.GET_SESSION_TYPE_COUNT.FAILURE,
      payload: error,
      error: true,
    });
  }
};
