import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Col, Row, Button } from 'react-bootstrap';
import classnames from 'classnames';
import moment from 'moment';

import TinymceEditor from '../tinymce_editor/tinymce_editor_container';
import BackendSelect from '../../common/backend_select/backend_select';
import styles from './email_sender.module.scss';

class EmailSender extends React.PureComponent {
  static propTypes = {
    currentEmailSending: PropTypes.object.isRequired,
    beginEdit: PropTypes.func.isRequired,
    updateEmailSending: PropTypes.func.isRequired,
    sendEmail: PropTypes.func.isRequired,
    handleSessionChoice: PropTypes.func.isRequired,
    selectedSession: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showSessionChooser: PropTypes.bool,
    client_id: PropTypes.number,
    generateEmail: PropTypes.func.isRequired,
    bookingUrls: PropTypes.array,
    locations: PropTypes.array,
  };

  state = {
    isSending: false,
  };

  handleSendEmail = () => {
    this.setState({ isSending: true });
    this.props.sendEmail();
  };

  render() {
    const {
      currentEmailSending,
      beginEdit,
      updateEmailSending,
      handleSessionChoice,
      selectedSession,
      showSessionChooser,
      client_id,
      generateEmail,
      bookingUrls,
      locations,
    } = this.props;

    let sessionChooser;

    if (showSessionChooser && client_id) {
      sessionChooser = (
        <div className="form-group">
          <label className="control-label">Calendar Event</label>
          <BackendSelect
            apiName="calendar_events"
            className="form-control"
            placeholder="Choose Calendar Event"
            params={{ client_id }}
            name="calendar_event_id"
            getItemName={({ attributes }) => {
              return `${attributes.title} - ${moment(
                attributes.start_time
              ).format('M.DD.YYYY')}`;
            }}
            onChange={handleSessionChoice}
            value={selectedSession}
          />
        </div>
      );
    }

    return (
      <React.Fragment>
        {sessionChooser}
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <Button bsSize="small" onClick={beginEdit}>
                Edit Template
              </Button>
              <Button
                bsSize="small"
                bsClass={classnames(
                  styles.generateButton,
                  'btn btn-primary btn-sm'
                )}
                onClick={generateEmail}
              >
                Generate
              </Button>
              <form>
                <TinymceEditor
                  value={currentEmailSending.email_body || ''}
                  onChange={value => updateEmailSending({ email_body: value })}
                  bookingUrls={bookingUrls}
                  locations={locations}
                />
              </form>
            </Col>
          </Row>
          <Row>
            <Col xs={6} />
            <Col xs={6}>
              <Button
                className={styles.emailSendButton}
                onClick={this.handleSendEmail}
                disabled={this.state.isSending}
              >
                Send ⇨
              </Button>
            </Col>
          </Row>
        </Grid>
      </React.Fragment>
    );
  }
}

export default EmailSender;
