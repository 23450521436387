import { INVOICE_PRODUCTS } from '~constants/actionTypes';

export default function (state = {}, action) {
  let products = [];

  switch (action.type) {
    case INVOICE_PRODUCTS.CREATE.SUCCESS:
      return { ...state, products: [...state.products, action.payload] };
    case INVOICE_PRODUCTS.UPDATE.SUCCESS:
      products = state.products.map(product => {
        if (product.id === action.payload.id) {
          return {
            ...product,
            ...action.payload,
          };
        }

        return product;
      });
      return { ...state, products };
    case INVOICE_PRODUCTS.DELETE.SUCCESS:
      products = state.products.filter(
        product => product.id !== action.payload.id
      );

      return { ...state, products };
    case INVOICE_PRODUCTS.GET.SUCCESS:
      return { ...state, products: action.payload };
    case INVOICE_PRODUCTS.CHECK_LINE_ITEM_TEMPLATES.SUCCESS:
      return { ...state, productTemplateList: action.payload };
    default:
      return state;
  }
}
