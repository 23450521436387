import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Button } from '~ui';
import { deleteInvoice } from '~actions/invoice';
import { absolutePath } from '~constants/routes';

const DeleteInvoiceModal = ({ handleHide }) => {
  const dispatch = useDispatch();
  const invoice = useSelector(state => state.invoice);
  const { invoice_num, id } = invoice;
  const client = useSelector(
    state => state.invoice.client.attributes.full_name
  );
  const deleteInvoiceAsync = async invoiceId =>
    dispatch(deleteInvoice(invoiceId));

  return (
    <Modal title="Are you sure?" handleHide={handleHide}>
      <p>{`You will be deleting Invoice ${invoice_num} for ${client}.`}</p>
      <br />
      <br />
      <Button
        text="Delete Invoice"
        onClick={() => {
          deleteInvoiceAsync(id).then(
            () => (window.location.href = absolutePath('/invoices'))
          );
        }}
        className="mr-24"
      />
      <Button text="Cancel" onClick={handleHide} danger />
    </Modal>
  );
};

DeleteInvoiceModal.propTypes = {
  handleHide: PropTypes.func.isRequired,
};

export default DeleteInvoiceModal;
