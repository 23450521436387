import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Dropdown } from '~ui';
import styles from './TablePagination.module.scss';
import { isMobile } from '~constants/userAgent';
import { MOBILE_WIDTH } from '~constants/viewports';
import { useWindowSize } from '~hooks';

const TablePagination = ({
  currentPage,
  totalPages,
  onPreviousCallback,
  onNextCallback,
  handlePageNumberClick,
  pageSizeCallback,
  itemsPerPage,
  newPagination,
  label,
  hasPrevious,
  hasNext,
}) => {
  if (newPagination) {
    const windowWidth = useWindowSize()[0];
    const mobileScreen = windowWidth <= MOBILE_WIDTH || isMobile();

    const getDisplayedPages = () => {
      if (totalPages <= 3) {
        return Array.from({ length: totalPages }, (_, i) => i + 1);
      } else if (currentPage <= 2) {
        return [1, 2, 3];
      } else if (currentPage >= totalPages - 1) {
        return [totalPages - 2, totalPages - 1, totalPages];
      } else {
        return [currentPage - 1, currentPage, currentPage + 1];
      }
    };

    const displayedPages = getDisplayedPages();

    return (
      <>
        {/* <div className={styles.TablePagination style={{ justifyContent: 'center' }}> */}
        <div className={styles.TablePagination} style={{ justifyContent: 'center' }}>
          {totalPages > 1 && (
            <>
              {currentPage > 1 && (
                <i
                  className="fa fa-chevron-left"
                  onClick={onPreviousCallback}
                  style={{
                    cursor: 'pointer',
                    fontSize: 'small',
                    color: '#949596',
                  }}
                ></i>
              )}
              <p>
                {displayedPages.map(page => (
                  <span
                    key={page}
                    style={{
                      backgroundColor:
                        page === currentPage ? '#A67EFB' : 'transparent',
                      borderRadius: '5px',
                      paddingTop: '2px',
                      paddingBottom: '2px',
                      paddingRight: '4px',
                      paddingLeft: '7px',
                      cursor: 'pointer',
                      color: page === currentPage ? 'white' : '#949596',
                    }}
                    onClick={() => handlePageNumberClick(page)}
                  >
                    {page}{' '}
                  </span>
                ))}
              </p>
              {currentPage < totalPages && (
                <i
                  className="fa fa-chevron-right"
                  onClick={onNextCallback}
                  style={{
                    cursor: 'pointer',
                    fontSize: 'small',
                    color: '#949596',
                  }}
                ></i>
              )}
            </>
          )}
          {!mobileScreen && pageSizeCallback && (
            <div
              className={styles.Dropdown}
              style={totalPages == 1 ? { marginTop: '40px' } : {}}
            >
              <p>Items per page: </p>
              <div style={{ marginTop: '10px' }}>
                <Dropdown
                  name="Items per page"
                  initialValue={itemsPerPage}
                  onChangeCallback={e => {
                    pageSizeCallback(Number(e));
                  }}
                  options={[
                    { label: '10', value: 10 },
                    { label: '20', value: 20 },
                    { label: '30', value: 30 },
                  ]}
                />
              </div>
            </div>
          )}
        </div>
      </>
    );
  } else {
    return (
      <div className={styles.TablePagination} style={{ justifyContent: 'flex-end' }}>
        <p>{label}</p>
        <Icon
          name="angle-left"
          className={styles.PreviousButton}
          onClickCallback={() => {
            if (hasPrevious) {
              onPreviousCallback();
            }
          }}
        />
        <Icon
          name="angle-right"
          className={styles.NextButton}
          onClickCallback={() => {
            if (hasNext) {
              onNextCallback();
            }
          }}
        />
      </div>
    );
  }
};
TablePagination.propTypes = {
  onPreviousCallback: PropTypes.func.isRequired,
  onNextCallback: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  hasPrevious: PropTypes.bool.isRequired,
  hasNext: PropTypes.bool.isRequired,
};

export default TablePagination;
