import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  DropdownButton,
  SearchInput,
  TablePagination,
  DateRangeInput,
} from '~ui';
import { TABLE_TYPES } from '~constants/tableTypes';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import styles from './Table.module.scss';
import EmptyBody from './EmptyBody';
import TableActionBar from './TableActionBar';

const Table = ({
  editFunctions,
  hooks,
  headers,
  rows,
  title,
  subtitle,
  readOnly,
  page,
  pageCount,
  totalRecords,
  pageChangeCallback,
  newPagination,
  searchInputProps,
  dropdownButtonProps,
  optionsDropdownButtonProps,
  dateRangePickerProps,
  schema,
  setRows,
  type,
  loading,
  floatNewRowToTop,
  setRowId,
  disableButtonGroupOnEdit,
  closeEditingExternally,
  setCloseEditingExternally,
  createRowOnLoad,
  floatToBottomAfterCreation,
  stylesForButtonsAndIcons,
  createButtonOverride,
  headerActionOptions,
  actionBarCount,
  downloadOnClick,
  pageSizeCallback,
  itemsPerPage,
  pageSize,
}) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [statefulRows, setStatefulRows] = useState([]);
  const [disableAdd, setDisableAdd] = useState(false);
  const PAGE_SIZE = pageSize || 10;
  const showPagination =
    page && !!totalRecords && (itemsPerPage > 10 || pageCount > 1);


  useEffect(() => {
    const tempRows = rows;

    if (editFunctions) {
      tempRows.map((row, idx) => {
        editFunctions.map(ef => {
          const name = ef.name;

          if (row.data[name]) {
            row.data[name]['onChangeCallback'] = val => ef.fn(idx, val);
          }
        });
      });
    }

    setStatefulRows(tempRows);
  }, [rows]);

  const checkHooks = rowItem => {
    if (hooks.delete) {
      hooks.delete(rowItem);
    }
  };

  const getPaginationLabel = () => {
    const startNumber = page * PAGE_SIZE - (PAGE_SIZE - 1);
    const endNumber = startNumber + rows.length - 1;

    return `${startNumber}-${endNumber} of ${totalRecords}`;
  };

  const handlePageClick = page => {
    setPageIndex(page);
    pageChangeCallback(page);
  };

  const editRowTempState = (rowIndex, state) => {
    if (floatToBottomAfterCreation && floatNewRowToTop) {
      let tempRows = rows;
      const tempItem = tempRows.shift();
      tempRows.push(tempItem);
      tempItem.temp = state;
      setRows([...tempRows]);
    } else {
      const tempRows = rows;
      const tempItem = tempRows[rowIndex];
      tempItem.temp = state;
      setRows([...tempRows]);
    }
  };

  const createRow = () => {
    if (setRowId) {
      setRowId('create');
    }
    // add to rows based on schema
    const newRow = { ...schema, temp: true };

    if (floatNewRowToTop) {
      const rowsBuffer = rows;
      rowsBuffer.unshift(newRow);
      setRows([...rowsBuffer]);
    } else {
      setRows([...rows, newRow]);
    }

    setDisableAdd(true);
  };

  useEffect(() => {
    if (createRowOnLoad) {
      createRow();
    }
  }, []);

  const deleteRow = rowIndex => {
    // delete the item in the index
    const tempRows = rows;
    const rowItem = tempRows[rowIndex];
    tempRows.splice(rowIndex, 1);
    setRows([...tempRows]);
    checkHooks(rowItem);
  };

  return (
    <>
      <div className={styles.TableHeader}>
        <div>
          {dropdownButtonProps && dropdownButtonProps.leftOrientation && (
            <div className={styles.LeftOrientedDropdown}>
              <p>View: </p>
              <DropdownButton
                options={dropdownButtonProps.options}
                label={dropdownButtonProps.label}
                lightGrey={dropdownButtonProps.lightGrey}
              />
            </div>
          )}
          {title && <h2>{title}</h2>}
          {subtitle && <p>{subtitle}</p>}
        </div>
        <div className={styles.TableHeaderButtons}>
          {dateRangePickerProps && (
            <DateRangeInput
              name="date-range"
              initialStartDate={dateRangePickerProps.startDate || null}
              initialEndDate={dateRangePickerProps.endDate || null}
              onChangeCallback={dateRangePickerProps.dateRangeCallback}
              hideErrors
            />
          )}
          {searchInputProps && (
            <SearchInput
              name={searchInputProps.name}
              onChangeCallback={searchInputProps.onChangeCallback}
              placeholder={searchInputProps.placeholder}
              width={searchInputProps.width}
              lightGrey={searchInputProps.lightGrey}
            />
          )}
          {optionsDropdownButtonProps && (
              <DropdownButton
                options={optionsDropdownButtonProps.options}
                label={optionsDropdownButtonProps.label}
                lightGrey={optionsDropdownButtonProps.lightGrey}
              />
            )
          }
          {dropdownButtonProps && !dropdownButtonProps.leftOrientation && (
            <DropdownButton
              options={dropdownButtonProps.options}
              label={dropdownButtonProps.label}
              lightGrey={dropdownButtonProps.lightGrey}
            />
          )}
          {!readOnly && (
            <div className={styles['Table-addButton']}>
              <Button
                text={
                  createButtonOverride && createButtonOverride.text
                    ? createButtonOverride.text
                    : 'Add'
                }
                icon={
                  createButtonOverride && createButtonOverride.icon
                    ? createButtonOverride.icon
                    : 'plus'
                }
                onClick={
                  createButtonOverride && createButtonOverride.create
                    ? createButtonOverride.create
                    : createRow
                }
                purpleRectangle={
                  createButtonOverride && createButtonOverride.purpleRectangle
                    ? true
                    : false
                }
                whiteRectangle={
                  createButtonOverride && createButtonOverride.whiteRectangle
                    ? true
                    : false
                }
                disabled={loading || disableAdd}
              />
            </div>
          )}
        </div>
      </div>
      {headerActionOptions && (
        <TableActionBar
          options={headerActionOptions}
          actionBarCount={actionBarCount}
          downloadOnClick={downloadOnClick}
        />
      )}
      <table
        className={styles.Table}
        {...(headerActionOptions && {
          style: { borderRadius: '0', MozBorderRadius: '0' },
        })}
      >
        <TableHeader headers={headers} />
        <tbody>
          {statefulRows.length === 0 ? (
            <EmptyBody columnCount={headers.length} />
          ) : (
            statefulRows.map((row, index) => {
              return (
                <TableRow
                  hooks={hooks}
                  key={index}
                  editRowTempState={editRowTempState}
                  index={index}
                  handleDelete={deleteRow}
                  type={type}
                  setDisableAdd={setDisableAdd}
                  setRowId={setRowId}
                  disableButtonGroupOnEdit={disableButtonGroupOnEdit}
                  closeEditingExternally={closeEditingExternally}
                  setCloseEditingExternally={setCloseEditingExternally}
                  stylesForButtonsAndIcons={stylesForButtonsAndIcons}
                  {...row}
                />
              );
            })
          )}
        </tbody>
      </table>
      {showPagination && (
        <TablePagination
          label={getPaginationLabel()}
          hasPrevious={page > 1}
          newPagination={newPagination}
          hasNext={page < pageCount}
          currentPage={page === undefined ? pageIndex : page}
          totalPages={pageCount}
          onPreviousCallback={() => {
            const newPageIndex = page === undefined ? pageIndex - 1 : page - 1;
            setPageIndex(newPageIndex);
            pageChangeCallback(newPageIndex);
          }}
          onNextCallback={() => {
            const newPageIndex = page === undefined ? pageIndex + 1 : page + 1;
            setPageIndex(newPageIndex);
            pageChangeCallback(newPageIndex);
          }}
          handlePageNumberClick={handlePageClick}
          pageSizeCallback={pageSizeCallback}
          itemsPerPage={itemsPerPage}
        />
      )}
    </>
  );
};

Table.propTypes = {
  oneAddMax: PropTypes.bool,
  headers: PropTypes.array.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      data: PropTypes.object,
      editable: PropTypes.bool,
      deletable: PropTypes.bool,
    })
  ).isRequired,
  searchInputProps: PropTypes.shape({
    name: PropTypes.string,
    onChangeCallback: PropTypes.func,
  }),
  dropdownButtonProps: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        onClickCallback: PropTypes.func,
      })
    ),
  }),
  optionsDropdownButtonProps: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        onClickCallback: PropTypes.func,
      })
    ),
  }),
  hooks: PropTypes.shape({
    update: PropTypes.func,
    delete: PropTypes.func,
    create: PropTypes.func,
  }),
  page: PropTypes.number,
  pageCount: PropTypes.number,
  totalRecords: PropTypes.number,
  pageChangeCallback: PropTypes.func,
  floatNewRowToTop: PropTypes.bool,
  type: PropTypes.oneOf([TABLE_TYPES.STATIC, TABLE_TYPES.DYNAMIC]),
  loading: PropTypes.bool,
};

Table.defaultProps = {
  oneAddMax: true,
  title: null,
  subtitle: null,
  rows: [],
  hooks: {},
  page: undefined,
  pageCount: undefined,
  totalRecords: undefined,
  pageChangeCallback: () => {},
  floatNewRowToTop: false,
  type: TABLE_TYPES.STATIC,
  loading: false,
};

export default Table;
