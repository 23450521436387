import React, { useEffect, useState } from 'react';
import styles from './UpcomingEvents.module.scss';
import axios from 'axios';
import { routes, absolutePath } from '~constants/routes';
import moment from 'moment';
import { Icon } from '~ui';

const UpcomingEvents = ({ studioTimezone }) => {
  const [irisEvents, setIrisEvents] = useState();
  const [cronEvents, setCronEvents] = useState();
  const [mergedEvents, setMergedEvents] = useState([]);
  const [irisLoading, setIrisLoading] = useState();
  const [cronLoading, setCronLoading] = useState();

  const timezonesAreSame =
    moment().tz(moment.tz.guess()).format('z') ==
    moment().tz(studioTimezone).format('z');

  const formatString = !timezonesAreSame ? 'h:mma (z)' : 'h:mma';

  const getIrisEvents = async () => {
    try {
      setIrisLoading(true);
      const res = await axios.get(routes.CALENDAR_EVENTS.TODAY);
      setIrisEvents(res.data);
      setIrisLoading(false);
    } catch (error) {
      setIrisLoading(false);
      console.error(error);
    }
  };

  const getCronofyEvents = async () => {
    try {
      setCronLoading(true);
      const res = await axios.get(routes.CALENDAR_EVENTS.CRONOFY, {
        params: {
          start: moment().toString(),
          end: moment().startOf('day').add(30, 'days').toString(),
        },
      });

      setCronEvents(res.data);
      setCronLoading(false);
    } catch (error) {
      setCronLoading(false);
      console.error(error);
    }
  };

  const mergeAndMakeRenderableEvents = (calendarJson, cronJson) => {
    const mappedCron = cronJson.map(ev => ({
      start_time: moment(ev.start_time).format('YYYY-MM-DDTHH:mm:ssZZ'),
      end_time: moment(ev.end_time).format('YYYY-MM-DDTHH:mm:ssZZ'),
      all_day: ev.allDay,
      ...ev,
    }));

    calendarJson.push.apply(calendarJson, mappedCron);

    const sortedEvents = calendarJson.sort((a, b) => {
      if (moment(a.start_time).isBefore(b.start_time)) {
        return -1;
      } else if (moment(a.start_time).isSame(b.start_time)) {
        return 0;
      } else {
        return 1;
      }
    });

    const allEvents = sortedEvents.map(ev => ({
      start_time: ev.start_time,
      title: ev.title,
      client_id: ev.client_id || null,
      status: ev.status,
      day: moment(ev.start_time).calendar(null, {
        sameDay: '[Today], MMMM D, YYYY',
        nextDay: '[Tomorrow], MMMM D, YYYY',
        nextWeek: 'dddd, MMMM D, YYYY',
      }),
    }));

    const dateKeyEvents = [];

    allEvents
      .slice(0, 8)
      .forEach(e =>
        dateKeyEvents.find(dateKeyEvent => dateKeyEvent[e.day])
          ? dateKeyEvents.find(d => d[e.day])[e.day].push(e)
          : dateKeyEvents.push({ [e.day]: [e] })
      );

    setMergedEvents(dateKeyEvents);
  };

  useEffect(() => {
    getIrisEvents();
    getCronofyEvents();
  }, []);

  useEffect(() => {
    if (irisEvents && cronEvents) {
      mergeAndMakeRenderableEvents(irisEvents, cronEvents);
    }
  }, [irisEvents, cronEvents]);

  return (
    <div className={styles.UpcomingEvents}>
      <h1>Upcoming Events</h1>
      <>
        {(irisLoading || cronLoading) && (
          <div className={styles['UpcomingEvents-loading']}>
            <Icon name="spinner" className="fa-pulse" large />
          </div>
        )}
      </>
      {mergedEvents &&
        mergedEvents.map((calEvent, ind) => (
          <React.Fragment key={ind}>
            <h3 className={styles['UpcomingEvents-day']}>
              {Object.keys(calEvent)[0]}
            </h3>
            {calEvent[Object.keys(calEvent)[0]].map((e, index) => (
              <div
                key={index}
                onClick={() => {
                  if (e.client_id) {
                    window.location.href = absolutePath(
                      `/clients/${e.client_id}/sessions`
                    );
                  } else {
                    window.location.href = absolutePath('/calendar');
                  }
                }}
                className={styles['UpcomingEvents-eventInfo']}
              >
                <div>
                  {moment(e.start_time).tz(studioTimezone).format(formatString)}
                </div>
                {e.status == 'needs_rescheduled' && (
                  <div style={{ display: 'flex' }}>
                    <strong style={{ marginRight: '5px' }}>
                      Needs Rescheduled:
                    </strong>
                    <p>{e.title}</p>
                  </div>
                )}
                {e.status != 'needs_rescheduled' && <div>{e.title}</div>}
              </div>
            ))}
          </React.Fragment>
        ))}
      {!irisLoading && !cronLoading && !mergedEvents.length > 0 && (
        <div className={styles['UpcomingEvents-noEvents']}>
          There are currently no upcoming sessions.
        </div>
      )}
      <div className={styles['UpcomingEvents-link']}>
        <a href={absolutePath('/calendar')}>
          View All <i className={'fas fa-arrow-right'}></i>
        </a>
      </div>
    </div>
  );
};

export default UpcomingEvents;
