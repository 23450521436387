import { CALENDAR } from '~constants/actionTypes';

export const updateSelectedDay = newDay => ({
  type: CALENDAR.UPDATE_SELECTED_DAY,
  payload: newDay,
});

export const updateInterval = newInterval => ({
  type: CALENDAR.UPDATE_INTERVAL,
  payload: newInterval,
});

export const updateShowDeleteModal = newValue => ({
  type: CALENDAR.UPDATE_SHOW_DELETE_MODAL,
  payload: newValue,
});

export const updateShowEditModal = newValue => ({
  type: CALENDAR.UPDATE_SHOW_EDIT_MODAL,
  payload: newValue,
});

export const updateActiveEvent = newEvent => ({
  type: CALENDAR.UPDATE_ACTIVE_EVENT,
  payload: newEvent,
});

export const updateSun = newValue => ({
  type: CALENDAR.UPDATE_SUN,
  payload: newValue,
});

export const updateDSTDates = newValue => ({
  type: CALENDAR.UPDATE_DST,
  payload: newValue,
});
