import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import MenuItem from './MenuItem';
import styles from './SidebarMenu.module.scss';
import { uniqueId } from '~utils';

const SidebarMenu = ({ items, initialActiveMenuItem, onChangeCallback }) => {
  const cx = classNames.bind(styles);
  const classes = cx('SidebarMenu', {});

  const [activeMenuItem, setActiveMenuItem] = useState(initialActiveMenuItem);

  return (
    <ul className={classes} role="menubar" aria-orientation="vertical">
      {items.map(({ name, labelText, onClickCallback, icon, disabled }) => {
        return (
          <MenuItem
            name={name}
            key={uniqueId('MenuItem_')}
            active={name === activeMenuItem}
            labelText={labelText}
            onClickCallback={() => {
              setActiveMenuItem(name);
              onChangeCallback(name);
              onClickCallback();
            }}
            icon={icon}
            disabled={disabled}
          />
        );
      })}
    </ul>
  );
};

SidebarMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      active: PropTypes.bool,
      labelText: PropTypes.string,
      onClickCallback: PropTypes.func,
      icon: PropTypes.string,
    })
  ).isRequired,
};

export default SidebarMenu;
