import {
  BOOKING_AVAILABILITY,
  BOOKING_CALENDAR,
  BOOKING_CALENDAR_REQUESTS,
} from '~constants/actionTypes';

export default function (state = {}, action) {
  switch (action.type) {
    case BOOKING_AVAILABILITY.LIST.SUCCESS:
      return { ...state, availabilities: action.payload };
    case BOOKING_CALENDAR.UPDATE_SELECTED_DAY:
      return { ...state, selectedDay: action.payload };
    case BOOKING_CALENDAR.UPDATE_INTERVAL:
      return { ...state, interval: action.payload };
    case BOOKING_CALENDAR.UPDATE_ACTIVE_SLOT:
      return { ...state, activeSlot: action.payload };
    case BOOKING_CALENDAR.UPDATE_SLOT_INTERVAL:
      return { ...state, slotInterval: action.payload };
    case BOOKING_CALENDAR.UPDATE_SHOW_ALL_CALENDARS:
      return { ...state, showAllCalendars: action.payload };
    case BOOKING_CALENDAR_REQUESTS.PUBLISH.SUCCESS:
      return {
        ...state,
        meta: { ...state.meta, lastPublished: action.payload },
      };
    default:
      return state;
  }
}
