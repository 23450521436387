import InvoiceProductsSection from '../../InvoiceSetup/InvoiceProductsSection';
import { Modal } from '~ui';
import React from 'react';

const NewProductModal = ({ getProductList, invoiceId }) => {
  const closeModal = () => {
    getProductList();
  };
  return (
    <Modal
      title={'Create an invoice product'}
      handleHide={closeModal}
      maxWidth={1000}
    >
      <InvoiceProductsSection
        invoiceId={invoiceId}
        getProductList={getProductList}
      />
    </Modal>
  );
};
export default NewProductModal;
