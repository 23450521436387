import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './SearchInput.module.scss';
import { Icon } from '~ui';

const SearchInput = ({
  disabled,
  initialValue,
  name,
  onChangeCallback,
  placeholder,
  className,
  labelText,
  hideIcon,
  width,
  lightGrey,
}) => {
  const hasClassName = !!className;
  const cx = classNames.bind(styles);
  const classes = cx('SearchInput', {
    'SearchInput--disabled': disabled,
    'SearchInput--hideIcon': hideIcon,
    'SearchInput--lightGrey': lightGrey,
    [className]: hasClassName,
  });

  const [value, setValue] = useState(initialValue || '');
  const [typingTimeout, setTypingTimeout] = useState();

  useEffect(() => {
    if (initialValue !== value) {
      setValue(initialValue || '');
    }
  }, [initialValue]);

  return (
    <div className={classes}>
      {labelText && <label htmlFor={name}>{labelText}</label>}
      {!hideIcon && (
        <Icon name="search" className={styles['SearchInput-search']} />
      )}
      {value !== '' && (
        <Icon
          name="times-circle"
          className={styles['SearchInput-clear']}
          onClickCallback={() => {
            setValue('');
            onChangeCallback('');
          }}
        />
      )}
      <input
        style={width ? { width: `${width}` } : {}}
        name={name}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={e => {
          const { value } = e.target;
          setValue(value);

          if (typingTimeout) {
            clearTimeout(typingTimeout);
          }

          setTypingTimeout(
            setTimeout(() => {
              onChangeCallback(value);
            }, 500)
          );
        }}
      />
    </div>
  );
};

SearchInput.propTypes = {
  disabled: PropTypes.bool,
  initialValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChangeCallback: PropTypes.func,
  placeholder: PropTypes.string,
  labelText: PropTypes.string,
  hideIcon: PropTypes.bool,
};

SearchInput.defaultProps = {
  disabled: false,
  initialValue: '',
  onChangeCallback: () => {},
  placeholder: '',
  labelText: undefined,
  hideIcon: false,
};

export default SearchInput;
