import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { routes } from '~constants/routes';
import EmailModal from './EmailModal';
import ConfirmationModal from './ConfirmationModal';

const EditAndSend = ({ booking_urls, locations }) => {
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [step, setStep] = useState(null);

  useEffect(() => {
    const fetchEditAndSendSteps = async () => {
      const response = await axios.get(routes.JOURNEY_STEPS.EDIT_AND_SEND);

      if (response.data.scheduled_at && !response.data.to_do.completed_at) {
        setShowModal(true);
        setStep(response.data);
      }
    };

    fetchEditAndSendSteps();
  }, []);

  return (
    <>
      {showModal && step && (
        <EmailModal
          handleHide={() => setShowModal(false)}
          client={step.client}
          calendarEvent={step.calendar_event}
          emailTemplate={step.email_template}
          journeyTemplate={step.journey_template}
          toDo={step.to_do}
          initialEmailBody={step.email_body}
          handleCancel={() => setShowConfirmationModal(true)}
          booking_urls={booking_urls}
          locations={locations}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          handleHide={() => setShowConfirmationModal(false)}
          toDo={step.to_do}
          journeyStep={step.journey_step}
          calendarEvent={step.calendar_event}
        />
      )}
    </>
  );
};

export default EditAndSend;
