import React, { useState } from 'react';
import qs from 'qs';
import { API_DATE_FORMAT } from '~constants/datetime';
import { absolutePath } from '~constants/routes';
import { readableStatusColors } from '~constants/readableStatusColors';
import {
  DataTable,
  SearchInput,
  SidebarMenu,
  DateRangeInput,
  DropdownButton,
  Icon,
  Pill,
} from '~ui';
import { useQueryString } from '~hooks';
import { clearQueryString, getWithParams } from '~utils';
import styles from './Spotlight';

const readableStatusesMap = readableStatusColors.reduce((acc, status) => {
  acc[status.status] = (
    <Pill small text={status.labelText} color={status.color} />
  );
  return acc;
}, {});

const Spotlight = () => {
  const sidebarMenuItems = [
    {
      name: 'contracts',
      labelText: 'Contracts',
      labelTextSingular: 'Contract',
      onClickCallback: () => {
        resetFilters();
        setResource('contracts');
        setSort({ column: 'delivered_at', direction: 'desc' });
        loadContracts();
      },
      icon: 'signature',
    },
    {
      name: 'invoices',
      labelText: 'Invoices',
      labelTextSingular: 'Invoice',
      onClickCallback: () => {
        resetFilters();
        setResource('invoices');
        setSort({ column: 'due_date', direction: 'desc' });
        loadInvoices();
      },
      icon: 'file-invoice-dollar',
    },
    {
      name: 'questionnaires',
      labelText: 'Questionnaires',
      labelTextSingular: 'Questionnaire',
      onClickCallback: () => {
        resetFilters();
        setResource('questionnaires');
        setSort({ column: 'created_at', direction: 'desc' });
        loadQuestionnaires();
      },
      icon: 'question',
    },
    {
      name: 'sessions',
      labelText: 'Sessions',
      labelTextSingular: 'Session',
      onClickCallback: () => {
        resetFilters();
        setResource('sessions');
        setStatus('upcoming');
        setSort({ column: 'start_time', direction: 'asc' });
        loadSessions();
      },
      icon: 'calendar',
    },
    {
      name: 'emails',
      labelText: 'Scheduled Emails',
      labelTextSingular: 'Email',
      onClickCallback: () => {
        resetFilters();
        setResource('emails');
        setSort({ column: 'scheduled_at', direction: 'desc' });
        loadEmails();
      },
      icon: 'envelope',
    },
    {
      name: 'client_activities',
      labelText: 'Client Activities',
      labelTextSingular: 'Client Activity',
      onClickCallback: () => {
        resetFilters();
        setSort({ column: 'created_at', direction: 'desc' });
        setResource('client_activities');
        loadClientActivities();
      },
      icon: 'users',
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(null);
  const [pageMeta, setPageMeta] = useState({});
  const [sorting, setSorting] = useState(false);
  const [sort, setSort] = useQueryString('sort', null);
  const [resource, setResource] = useQueryString(
    'resource',
    sidebarMenuItems[0].name
  );
  const [searchValue, setSearchValue] = useQueryString('search_term');
  const [page, setPage] = useQueryString('page', 1);
  const [date, setDate] = useQueryString('date', null);
  const [status, setStatus] = useQueryString('status', null);

  const resetFilters = () => {
    setStatus(null);
    setSearchValue(null);
    setPage(1);
    setSorting(false);
    setSort(null);
    setDate(null);
    clearQueryString();
  };

  const loadContracts = async () => {
    setLoading(true);

    await getWithParams('/api/contracts', getParams()).then(res => {
      const contracts = res.data.data.map(({ attributes }) => ({
        client_name: () => (
          <a
            href={absolutePath(`/clients/${attributes.client_id}`)}
            target="_blank"
          >
            {attributes.client_name}
            <Icon name="external-link-alt" className="ml-12" small />
          </a>
        ),
        name: attributes.contract_template_name,
        delivered_at:
          attributes.delivered_at || attributes.read_at || attributes.signed_at,
        status: readableStatusesMap[attributes.status],
      }));

      setData(contracts);
      setPageMeta(res.data.meta);
      setLoading(false);
    });
  };

  const loadInvoices = async () => {
    setLoading(true);
    const params = getParams();
    if (params.status == '' || params.status == undefined) {
      params.status = 'all';
    }
    await getWithParams('/api/invoices', { ...params, draft: false }).then(
      res => {
        const invoices = res.data.data.map(({ attributes }) => ({
          client_name: () => (
            <a
              href={absolutePath(`/clients/${attributes.client_id}`)}
              target="_blank"
            >
              {attributes.client_name}
              <Icon name="external-link-alt" className="ml-12" small />
            </a>
          ),
          total: attributes.total,
          paid_amount: attributes.paid_amount,
          due_date: attributes.due_date,
          status: readableStatusesMap[attributes.status],
        }));

        setData(invoices);
        setPageMeta(res.data.meta);
        setLoading(false);
      }
    );
  };

  const loadQuestionnaires = async () => {
    setLoading(true);

    await getWithParams('/api/web_form_responses', getParams()).then(res => {
      const questionnaires = res.data.data.map(({ attributes }) => ({
        client_name: () => (
          <a
            href={absolutePath(`/clients/${attributes.client_id}`)}
            target="_blank"
          >
            {attributes.client_name}
            <Icon name="external-link-alt" className="ml-12" small />
          </a>
        ),
        name: attributes.name,
        created_at: attributes.created_at,
        status: readableStatusesMap[attributes.status],
      }));

      setData(questionnaires);
      setPageMeta(res.data.meta);
      setLoading(false);
    });
  };

  const loadSessions = async optionalParams => {
    setLoading(true);

    await getWithParams('/api/calendar_events', {
      ...getParams(),
      page: page,
      ...optionalParams,
      sessions_only: true,
    }).then(res => {
      const sessions = res.data.data.map(({ attributes }) => {
        const now = moment();
        const eventIsPast = moment(attributes.start_time).isBefore(now);
        const isActive = attributes.status === 'active';
        let status;
        if (isActive && eventIsPast) {
          status = 'past';
        } else if (isActive && !eventIsPast) {
          status = 'upcoming';
        } else {
          status = attributes.status;
        }

        return {
          client_name: () => (
            <a
              href={absolutePath(`/clients/${attributes.client_id}`)}
              target="_blank"
            >
              {attributes.client_name}
              <Icon name="external-link-alt" className="ml-12" small />
            </a>
          ),
          title: attributes.title,
          start_time: attributes.start_time,
          session_type: attributes.session_type,
          status: readableStatusesMap[status],
        };
      });

      setData(sessions);
      setPageMeta(res.data.meta);
      setLoading(false);
    });
  };

  const loadEmails = async () => {
    setLoading(true);

    await getWithParams('/api/email_messages', getParams()).then(res => {
      const emails = res.data.data.map(({ attributes }) => ({
        client_name: () => (
          <a
            href={absolutePath(`/clients/${attributes.client_id}`)}
            target="_blank"
          >
            {attributes.client_name}
            <Icon name="external-link-alt" className="ml-12" small />
          </a>
        ),
        name: attributes.name,
        scheduled_at: attributes.scheduled_at,
        status: readableStatusesMap[attributes.status],
      }));

      setData(emails);
      setPageMeta(res.data.meta);
      setLoading(false);
    });
  };

  const loadClientActivities = async () => {
    setLoading(true);

    await getWithParams('/api/client_activities', getParams()).then(res => {
      const clientActivities = res.data.data.map(({ attributes }) => ({
        client_name: () => (
          <a
            href={absolutePath(`/clients/${attributes.client_id}`)}
            target="_blank"
          >
            {attributes.client_name}
            <Icon name="external-link-alt" className="ml-12" small />
          </a>
        ),
        status_message: attributes.status_message,
        created_at: attributes.created_at,
      }));

      setData(clientActivities);
      setPageMeta(res.data.meta);
      setLoading(false);
    });
  };

  const onSortCallback = (column, direction) => {
    setSorting(true);
    setSort({ column, direction });

    const loadFunctionMap = {
      contracts: loadContracts,
      emails: loadEmails,
      invoices: loadInvoices,
      questionnaires: loadQuestionnaires,
      sessions: loadSessions,
      client_activities: loadClientActivities,
    };
    const loadFunction = loadFunctionMap[resource];

    if (column === undefined || direction === undefined) {
      loadFunction();
    }

    const sortParams = {
      sort: {
        column,
        direction,
      },
    };
    loadFunction(sortParams);
  };

  const allData = {
    contracts: {
      columnTypes: ['function', 'string', 'date', 'string'],
      headers: [
        { name: 'client_name', labelText: 'Client Name' },
        { name: 'name', labelText: 'Title' },
        { name: 'delivered_at', labelText: 'Date Sent' },
        { name: 'status', labelText: 'Status', sortable: false },
      ],
      filterOptions: [
        {
          label: 'All',
          onClickCallback: () => {
            setStatus(null);
            setPage(1);
            loadContracts();
          },
        },
        {
          label: 'Unread',
          onClickCallback: () => {
            setStatus('unread');
            setPage(1);
            loadContracts();
          },
        },
        {
          label: 'Read',
          onClickCallback: () => {
            setStatus('read');
            setPage(1);
            loadContracts();
          },
        },
        {
          label: 'Unsigned',
          onClickCallback: () => {
            setStatus('unsigned');
            setPage(1);
            loadContracts();
          },
        },
        {
          label: 'Signed',
          onClickCallback: () => {
            setStatus('signed');
            setPage(1);
            loadContracts();
          },
        },
      ],
      dateRangeCallback: (startDate, endDate) => {
        const start = startDate ? startDate.format(API_DATE_FORMAT) : null;
        const end = endDate ? endDate.format(API_DATE_FORMAT) : null;
        setDate({ start, end });
        setPage(1);
        loadContracts();
      },
      searchCallback: term => {
        setSearchValue(term);
        setPage(1);
        loadContracts();
      },
      dateFilterName: 'delivered_at',
      pageChangeCallback: pageNumber => {
        setPage(pageNumber);
        loadContracts();
      },
    },
    invoices: {
      columnTypes: ['function', 'currency', 'currency', 'date', 'string'],
      headers: [
        { name: 'client_name', labelText: 'Client Name' },
        { name: 'total', labelText: 'Total' },
        { name: 'paid_amount', labelText: 'Paid Amount', sortable: false },
        { name: 'due_date', labelText: 'Due Date' },
        { name: 'status', labelText: 'Status', sortable: false },
      ],
      filterOptions: [
        {
          label: 'All',
          onClickCallback: () => {
            setStatus(null);
            setPage(1);
            loadInvoices();
          },
        },
        {
          label: 'Unpaid',
          onClickCallback: () => {
            setStatus('unpaid');
            setPage(1);
            loadInvoices();
          },
        },
        {
          label: 'Partially Paid',
          onClickCallback: () => {
            setStatus('partially_paid');
            setPage(1);
            loadInvoices();
          },
        },
        {
          label: 'Paid',
          onClickCallback: () => {
            setStatus('paid');
            setPage(1);
            loadInvoices();
          },
        },
        {
          label: 'Late',
          onClickCallback: () => {
            setStatus('late');
            setPage(1);
            loadInvoices();
          },
        },
        {
          label: 'Refunded',
          onClickCallback: () => {
            setStatus('refunded');
            setPage(1);
            loadInvoices();
          },
        },
        {
          label: 'Partially Refunded',
          onClickCallback: () => {
            setStatus('partially_refunded');
            setPage(1);
            loadInvoices();
          },
        },
        {
          label: 'ACH Pending',
          onClickCallback: () => {
            setStatus('ach_pending');
            setPage(1);
            loadInvoices();
          },
        },
      ],
      dateRangeCallback: (startDate, endDate) => {
        const start = startDate ? startDate.format(API_DATE_FORMAT) : null;
        const end = endDate ? endDate.format(API_DATE_FORMAT) : null;
        setDate({ start, end });
        setPage(1);
        loadInvoices();
      },
      searchCallback: term => {
        setSearchValue(term);
        setPage(1);
        loadInvoices();
      },
      dateFilterName: 'due_date',
      pageChangeCallback: pageNumber => {
        setPage(pageNumber);
        loadInvoices();
      },
    },
    questionnaires: {
      columnTypes: ['function', 'string', 'date', 'string'],
      headers: [
        { name: 'client_name', labelText: 'Client Name' },
        { name: 'name', labelText: 'Title' },
        { name: 'created_at', labelText: 'Date Sent' },
        { name: 'status', labelText: 'Status' },
      ],
      filterOptions: [
        {
          label: 'All',
          onClickCallback: () => {
            setStatus(null);
            setPage(1);
            loadQuestionnaires();
          },
        },
        {
          label: 'Incomplete',
          onClickCallback: () => {
            setStatus('incomplete');
            setPage(1);
            loadQuestionnaires();
          },
        },
        {
          label: 'Complete',
          onClickCallback: () => {
            setStatus('complete');
            setPage(1);
            loadQuestionnaires();
          },
        },
      ],
      dateRangeCallback: (startDate, endDate) => {
        const start = startDate ? startDate.format(API_DATE_FORMAT) : null;
        const end = endDate ? endDate.format(API_DATE_FORMAT) : null;
        setDate({ start, end });
        setPage(1);
        loadQuestionnaires();
      },
      searchCallback: term => {
        setSearchValue(term);
        setPage(1);
        loadQuestionnaires();
      },
      dateFilterName: 'created_at',
      pageChangeCallback: pageNumber => {
        setPage(pageNumber);
        loadQuestionnaires();
      },
    },
    sessions: {
      columnTypes: ['function', 'string', 'datetime', 'string', 'string'],
      headers: [
        { name: 'client_name', labelText: 'Client Name' },
        { name: 'title', labelText: 'Title' },
        { name: 'start_time', labelText: 'Start Time' },
        { name: 'session_type', labelText: 'Session Type' },
        { name: 'status', labelText: 'Status', sortable: false },
      ],
      filterOptions: [
        {
          label: 'All',
          onClickCallback: () => {
            setStatus(null);
            setPage(1);
            loadSessions();
          },
        },
        {
          label: 'Upcoming',
          onClickCallback: () => {
            setStatus('upcoming');
            setPage(1);
            loadSessions();
          },
        },
        {
          label: 'Needs Rescheduled',
          onClickCallback: () => {
            setStatus('needs_rescheduled');
            setPage(1);
            loadSessions();
          },
        },
      ],
      dateRangeCallback: (startDate, endDate) => {
        const start = startDate ? startDate.format(API_DATE_FORMAT) : null;
        const end = endDate ? endDate.format(API_DATE_FORMAT) : null;
        setDate({ start, end });
        setPage(1);
        loadSessions();
      },
      searchCallback: term => {
        setSearchValue(term);
        setPage(1);
        loadSessions();
      },
      dateFilterName: 'start_time',
      pageChangeCallback: pageNumber => {
        setPage(pageNumber);
        loadSessions({ page: pageNumber });
      },
    },
    emails: {
      columnTypes: ['function', 'string', 'date', 'string'],
      headers: [
        { name: 'client_name', labelText: 'Client Name' },
        { name: 'name', labelText: 'Title' },
        { name: 'scheduled_at', labelText: 'Date Scheduled' },
        { name: 'status', labelText: 'Status' },
      ],
      filterOptions: [
        {
          label: 'All',
          onClickCallback: () => {
            setStatus(null);
            setPage(1);
            loadEmails();
          },
        },
        {
          label: 'Scheduled',
          onClickCallback: () => {
            setStatus('scheduled');
            setPage(1);
            loadEmails();
          },
        },
        {
          label: 'Delivered',
          onClickCallback: () => {
            setStatus('delivered');
            setPage(1);
            loadEmails();
          },
        },
      ],
      dateRangeCallback: (startDate, endDate) => {
        const start = startDate ? startDate.format(API_DATE_FORMAT) : null;
        const end = endDate ? endDate.format(API_DATE_FORMAT) : null;
        setDate({ start, end });
        setPage(1);
        loadEmails({ scheduled_at: { start, end } });
      },
      searchCallback: term => {
        setSearchValue(term);
        setPage(1);
        loadEmails({ search_term: term });
      },
      dateFilterName: 'scheduled_at',
      pageChangeCallback: pageNumber => {
        setPage(pageNumber);
        loadEmails();
      },
    },
    client_activities: {
      columnTypes: ['function', 'string', 'date'],
      headers: [
        { name: 'client_name', labelText: 'Client Name' },
        { name: 'status_message', labelText: 'Description', sortable: false },
        { name: 'created_at', labelText: 'Date' },
      ],
      filterOptions: [
        {
          label: 'All',
          onClickCallback: () => {
            setStatus(null);
            setPage(1);
            loadClientActivities();
          },
        },
        {
          label: 'Contract',
          onClickCallback: () => {
            setStatus('contract');
            setPage(1);
            loadClientActivities();
          },
        },
        {
          label: 'Document',
          onClickCallback: () => {
            setStatus('document');
            setPage(1);
            loadClientActivities();
          },
        },
        {
          label: 'Email',
          onClickCallback: () => {
            setStatus('email');
            setPage(1);
            loadClientActivities();
          },
        },
        {
          label: 'Lead',
          onClickCallback: () => {
            setStatus('lead');
            setPage(1);
            loadClientActivities();
          },
        },
        {
          label: 'Invoice',
          onClickCallback: () => {
            setStatus('invoice');
            setPage(1);
            loadClientActivities();
          },
        },
        {
          label: 'Quote',
          onClickCallback: () => {
            setStatus('quote');
            setPage(1);
            loadClientActivities();
          },
        },
        {
          label: 'Questionnaire',
          onClickCallback: () => {
            setStatus('questionnaire');
            setPage(1);
            loadClientActivities();
          },
        },
      ],
      dateRangeCallback: (startDate, endDate) => {
        const start = startDate ? startDate.format(API_DATE_FORMAT) : null;
        const end = endDate ? endDate.format(API_DATE_FORMAT) : null;
        setDate({ start, end });
        setPage(1);
        loadClientActivities();
      },
      searchCallback: term => {
        setSearchValue(term);
        setPage(1);
        loadClientActivities();
      },
      dateFilterName: 'created_at',
      pageChangeCallback: pageNumber => {
        setPage(pageNumber);
        loadClientActivities();
      },
    },
  };

  const generatePageTitle = activeMenuItem => {
    const activeItem = sidebarMenuItems.find(
      item => item.name === activeMenuItem
    );

    if (loading) {
      return `Loading ${activeItem.labelText}`;
    } else if (!loading && pageMeta.total_records === 1) {
      return `${pageMeta.total_records} ${activeItem.labelTextSingular}`;
    }
    return `${pageMeta.total_records} ${activeItem.labelText}`;
  };

  const getDropdownFilterLabel = () => {
    if (status !== null) {
      const readableColorObject = readableStatusColors.find(
        item => item.status === status
      );
      return readableColorObject.labelText;
    } else {
      return 'All';
    }
  };

  const getParams = () => {
    const params = qs.parse(window.location.search.substring(1));
    if (!params) {
      return;
    }

    if (
      params.date &&
      !(
        (params.date.start == '' && params.date.end != '') ||
        (params.date.start != '' && params.date.end == '')
      ) &&
      Object.values(params.date).some(
        element => element === '' || element === null
      )
    ) {
      let newParams = { ...params };
      delete newParams.date;
      return newParams;
    }

    return { ...params, [allData[resource].dateFilterName]: params.date };
  };

  if (loading === null) {
    switch (resource) {
      case 'questionnaires':
        return loadQuestionnaires();
      case 'emails':
        return loadEmails();
      case 'invoices':
        return loadInvoices();
      case 'sessions':
        return loadSessions();
      case 'client_activities':
        return loadClientActivities();
      default:
        return loadContracts();
    }
  }

  return (
    <div className={styles['Spotlight']}>
      <SidebarMenu
        items={sidebarMenuItems}
        initialActiveMenuItem={resource}
        onChangeCallback={setResource}
      />
      <div className={styles['SpotlightContent']}>
        <div className={styles['SpotlightHeader']}>
          <DropdownButton
            label={getDropdownFilterLabel()}
            className="mr-12 fg-1"
            options={allData[resource].filterOptions}
          />
          <DateRangeInput
            name="spotlight-range"
            initialStartDate={date ? date.start : null}
            initialEndDate={date ? date.end : null}
            onChangeCallback={allData[resource].dateRangeCallback}
            className="mr-12"
            hideErrors
          />
          <SearchInput
            name="search"
            initialValue={searchValue}
            onChangeCallback={allData[resource].searchCallback}
            className="fg-1"
          />
        </div>
        <DataTable
          title={generatePageTitle(resource)}
          headers={allData[resource].headers}
          rows={data}
          columnTypes={allData[resource].columnTypes}
          loading={loading}
          page={pageMeta.page}
          pageCount={pageMeta.page_count}
          totalRecords={pageMeta.total_records}
          sorting={sorting}
          onSortCallback={onSortCallback}
          pageChangeCallback={allData[resource].pageChangeCallback}
        />
      </div>
    </div>
  );
};

export default Spotlight;
