import React from 'react';
import classNames from 'classnames/bind';
import { EVENT_HEIGHT } from '~constants/calendar';
import { uniqueId } from '~utils';
import styles from '../TimeSlot/TimeSlot.module.scss';
import CalendarEvent from '../CalendarEvent';
import CronofyEvent from '../CronofyEvent';
import PlaceholderEvent from '../PlaceholderEvent';

const AllDayTimeSlot = props => {
  const { events, onDoubleClick, className, day } = props;
  const hasClassName = !!className;
  const cx = classNames.bind(styles);
  const classes = cx('TimeSlot', {
    [className]: hasClassName,
  });
  const startOfWeek = day.clone().startOf('week');
  const endOfWeek = day.clone().endOf('week');
  const getStyles = event => {
    const start = moment(event.start_time) || moment(event.start.date);
    const end = moment(event.end_time) || moment(event.end.date);
    let width = end.diff(start, 'hours');

    if (end.isAfter(endOfWeek)) {
      width = endOfWeek.diff(start, 'hours') + 24;
    }

    if (start.isBefore(startOfWeek)) {
      width = end.diff(startOfWeek, 'hours');
    }

    return {
      width: `${(width / 24) * 100}%`,
      left:
        !event.is_all_day_event && !event.isOvernight
          ? `${Math.abs(day.startOf('day').diff(start, 'hours') / 24) * 100}%`
          : 0,
    };
  };

  return (
    <div className={classes} onDoubleClick={onDoubleClick}>
      {events.map((event, index) => {
        let customStyles = {
          position: 'absolute',
        };

        customStyles.width = getStyles(event).width;
        customStyles.left = getStyles(event).left;
        customStyles.top = `${index * EVENT_HEIGHT}px`;

        if (event.event_uid) {
          const key = uniqueId(`event${event.event_uid}_`);

          return (
            <CronofyEvent
              event={event}
              key={key}
              customStyles={customStyles}
              setHeight
            />
          );
        }

        if (event.type === 'placeholder') {
          return <PlaceholderEvent key={uniqueId(`placeholder_`)} />;
        }

        return (
          <CalendarEvent
            event={event}
            key={uniqueId(`event${event.id}_`)}
            customStyles={customStyles}
            setHeight
          />
        );
      })}
    </div>
  );
};

export default AllDayTimeSlot;
