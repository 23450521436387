import React from 'react';
import PropTypes from 'prop-types';

class Pagination extends React.PureComponent {
  static propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    paginationClick: PropTypes.func.isRequired,
  };

  RenderPaginationRow = page_number => {
    if (page_number === this.props.currentPage) {
      return (
        <span key={page_number} className="page as-link current m-r-sm">
          {page_number}
        </span>
      );
    }

    return (
      <span
        key={page_number}
        onClick={() => this.props.paginationClick(page_number)}
        className="page as-link teal m-r-sm"
      >
        {page_number}
      </span>
    );
  };

  RenderPreviousPage = page_number => {
    if (page_number > 1) {
      return (
        <span
          key={0}
          onClick={() => this.props.paginationClick(page_number - 1)}
          className="page as-link teal m-r-sm"
        >
          ← Previos
        </span>
      );
    }
    return false;
  };

  RenderNextPage = page_number => {
    if (page_number < this.props.totalPages) {
      return (
        <span
          key={this.props.totalPages + 1}
          onClick={() => this.props.paginationClick(page_number + 1)}
          className="page as-link teal m-r-sm"
        >
          Next →
        </span>
      );
    }
    return false;
  };

  render() {
    const paginationItems = [];

    if (this.props.totalPages > 1) {
      paginationItems.push(this.RenderPreviousPage(this.props.currentPage));

      for (let i = 1; i <= this.props.totalPages; i++) {
        paginationItems.push(this.RenderPaginationRow(i));
      }

      paginationItems.push(this.RenderNextPage(this.props.currentPage));

      return (
        <div id="add_attachment_pages">
          <div className="pagination">{paginationItems}</div>
        </div>
      );
    }

    return (
      <div id="add_attachment_pages">
        <div className="pagination">{paginationItems}</div>
      </div>
    );
  }
}

export default Pagination;
