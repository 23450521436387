import { JOURNEY_TEMPLATES } from '~constants/actionTypes';

export default function (state = {}, action) {
  switch (action.type) {
    case JOURNEY_TEMPLATES.INDEX.SUCCESS:
      return {
        ...state,
        templates: action.payload
      };
    case JOURNEY_TEMPLATES.CREATE.SUCCESS:
      return {
        ...state,
        templates: [...state.templates, action.payload]
      };
    case JOURNEY_TEMPLATES.DELETE.SUCCESS:
      return state;
    default:
      return state;
  }
}
