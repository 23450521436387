import React, { useState } from 'react';
import { Dropdown, DocumentUploader, Modal, Icon, Checkbox, Button } from '~ui';
import axios from 'axios';
import { routes } from '~constants/routes';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import styles from './DocumentUploadModal.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getDocuments, getClientDocuments } from '~actions/documents';

const DocumentUploadModal = ({ handleHide, documentId = null }) => {
  const dispatch = useDispatch();

  const { clientsOptions } = useSelector(state => state.documents);
  const { clientId: initialClientId } = useSelector(state => state.documents);

  const [loading, setLoading] = useState(false);
  const [clientId, setClientId] = useState(initialClientId);
  const [fileString, setFileString] = useState();
  const [fileName, setFileName] = useState();
  const [file, setFile] = useState();
  const [assignToClient, setAssignToClient] = useState(!!initialClientId);

  const handleUpload = (uploadedFileString, uploadedFileName, uploadedFile) => {
    if (uploadedFile.type === 'text/html') {
      toast.error('Problem with upload. Please contact support.', toastOptions);
      return;
    }

    setFileString(uploadedFileString);
    setFileName(uploadedFileName);
    setFile(uploadedFile);
  };

  const saveDocument = async () => {
    const formData = new FormData();
    formData.append('document[file]', file);

    if (clientId) {
      formData.append('document[client_id]', clientId);
    }

    try {
      setLoading(true);

      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      if (documentId) {
        await axios.put(routes.DOCUMENTS.UPDATE(documentId), formData, headers);
      } else {
        await axios.post(routes.DOCUMENTS.CREATE, formData, headers);
      }

      toast.success('Document Uploaded!', toastOptions);
      setLoading(false);
      handleHide();

      dispatch(getDocuments());
      dispatch(getClientDocuments(initialClientId));
    } catch (error) {
      setLoading(false);
      toast.error('Error uploading document', toastOptions);
      console.error(error);
      handleHide();
    }
  };

  return (
    <Modal
      title={`${documentId ? 'Update' : 'Upload a'} Document`}
      handleHide={handleHide}
    >
      <>
        {loading ? (
          <div>
            <div className={styles.loadingText}>
              <p>Loading...</p>
              <Icon name="spinner" className="fa-pulse" large />
            </div>
          </div>
        ) : (
          <>
            {fileName ? (
              <h1 className={styles['DocumentUploadModal-filename']}>
                {fileName}
              </h1>
            ) : (
              <div className={styles['DocumentUploadModal-input']}>
                <DocumentUploader
                  readAs="readAsDataURL"
                  onChange={fileData => {
                    handleUpload(
                      fileData.dataString,
                      fileData.fileName,
                      fileData.file
                    );
                  }}
                  onError={error => {
                    toast.error(error.message, toastOptions);
                  }}
                />
              </div>
            )}
            {initialClientId || documentId ? null : (
              <div className={styles['DocumentUploadModal-input']}>
                <Checkbox
                  labelText="Assign to client?"
                  onChangeCallback={setAssignToClient}
                  name="assign_to_client"
                />
              </div>
            )}
            {assignToClient && !initialClientId && (
              <div className={styles['DocumentUploadModal-input']}>
                <Dropdown
                  labelText="Client"
                  name="client"
                  options={clientsOptions}
                  onChangeCallback={setClientId}
                />
              </div>
            )}
            <div className={styles['DocumentUploadModal-buttonGroup']}>
              <Button danger text="Cancel" onClick={handleHide} />
              <Button
                text={documentId ? 'Update' : 'Save'}
                onClick={saveDocument}
                disabled={!fileName}
              />
            </div>
          </>
        )}
      </>
    </Modal>
  );
};

export default DocumentUploadModal;
