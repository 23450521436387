import React, { useRef, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './DropdownButton.module.scss';
import { uniqueId } from '~utils';
import { keyCodes } from '~constants/keyCodes';

const DropdownButton = ({ disabled, label, options, lightGrey, className }) => {
  const hasClassName = !!className;
  const cx = classNames.bind(styles);
  const classes = cx('DropdownButton', {
    'DropdownButton--disabled': disabled,
    'DropdownButton--lightGrey': lightGrey,
    [className]: hasClassName,
  });
  const id = uniqueId('DropdownButton_');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef();
  const dropdownListRef = useRef();

  const handleClickOutside = e => {
    if (
      (dropdownRef.current && dropdownRef.current.contains(e.target)) ||
      (dropdownListRef.current && dropdownListRef.current.contains(e.target))
    ) {
      return;
    }

    setIsOpen(false);
  };

  const keydownListener = ({ keyCode }) => {
    if (isOpen && keyCode === keyCodes.ESCAPE) {
      setIsOpen(false);
    }
  };

  useLayoutEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', keydownListener);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('keydown', keydownListener);
      };
    }
  }, [isOpen]);

  return (
    <div className={classes} ref={dropdownRef}>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        id={id}
        aria-haspopup="true"
        aria-expanded={isOpen}
        disabled={disabled}
      >
        {label}
      </button>
      {isOpen && (
        <div
          className={styles['DropdownButton-options']}
          aria-labelledby={id}
          ref={dropdownListRef}
        >
          {options.map(({ label, onClickCallback }) => (
            <a
              key={uniqueId('option_')}
              children={label}
              onClick={() => {
                setIsOpen(false);
                onClickCallback();
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

DropdownButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClickCallback: PropTypes.func.isRequired,
    })
  ).isRequired,
};

DropdownButton.defaultProps = {
  disabled: false,
};

export default DropdownButton;
