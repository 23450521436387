import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { toast } from 'react-toastify';
import { withContext } from 'with-context';

import ApiCacheContext from '../../../contexts/api_cache_context';
import EmailTemplateCreator from './email_template_creator';

@withContext(ApiCacheContext, 'apiCacheContext')
class EmailTemplateCreatorContainer extends React.PureComponent {
  static propTypes = {
    currentEmailTemplate: PropTypes.object.isRequired,
    updateEmailTemplate: PropTypes.func.isRequired,
    replaceEmailTemplate: PropTypes.func.isRequired,
    isFetchingTemplate: PropTypes.bool.isRequired,
    setIsFetchingTemplate: PropTypes.func.isRequired,
    onTemplateSave: PropTypes.func.isRequired,
    apiCacheContext: PropTypes.object.isRequired,
    bookingUrls: PropTypes.array,
    locations: PropTypes.array,
  };

  state = {
    selectedDocument: '',
    selectedContract: '',
    selectedQuestionnaire: '',
    selectedInvoice: '',
  };

  constructor(props) {
    super(props);

    const { currentEmailTemplate } = props;
    if (currentEmailTemplate.id) {
      this.checkAndFetchEmailTemplate(currentEmailTemplate);
    }
  }

  fetchingTemplate = () => {
    this.props.setIsFetchingTemplate(true);
  };

  notFetchingTemplate = () => {
    this.props.setIsFetchingTemplate(false);
  };

  checkAndFetchEmailTemplate = async template => {
    const { isFetchingTemplate, replaceEmailTemplate } = this.props;

    if (template._fetched || isFetchingTemplate) {
      return;
    }

    this.fetchingTemplate();
    try {
      const res = await axios.get(`/api/legacy_email_templates/${template.id}`);
      replaceEmailTemplate(res.data);
    } catch (e) {
      toast.info(`Failed to get email template ${e}`);
      console.error('toast this', e);
    } finally {
      this.notFetchingTemplate();
    }
  };

  componentDidUpdate = prevProps => {
    const previousTemplate = prevProps.currentEmailTemplate;
    const currentTemplate = this.props.currentEmailTemplate;
    const differentTemplate =
      currentTemplate.id && previousTemplate.id !== currentTemplate.id;
    if (!differentTemplate) {
      return;
    }

    this.checkAndFetchEmailTemplate(currentTemplate);
  };

  reloadAttachments = () => {
    this.props.apiCacheContext.reloadIndex('documents');
  };

  addAttachment = attachmentItem => {
    const { currentEmailTemplate, updateEmailTemplate } = this.props;
    const newAttachment = {
      id: '',
      item_id: attachmentItem.id,
      item_name: attachmentItem.attributes.file_file_name,
      item_type: 'Document',
      deleted: false,
    };
    const currentAttachments = currentEmailTemplate.email_attachments;
    updateEmailTemplate({
      email_attachments: currentAttachments.concat(newAttachment),
    });
  };

  addDocument = () => {
    this.addAttachment(this.state.selectedDocument);
  };

  addContract = () => {
    const { currentEmailTemplate, updateEmailTemplate } = this.props;
    const { selectedContract } = this.state;
    if (!selectedContract) {
      return;
    }
    const newAttachment = {
      id: '',
      item_id: selectedContract.id,
      item_name: selectedContract.attributes.name,
      item_type: 'ContractTemplate',
      deleted: false,
    };
    const currentAttachments = currentEmailTemplate.email_attachments;
    updateEmailTemplate({
      email_attachments: currentAttachments.concat(newAttachment),
    });
  };

  addInvoice = () => {
    const { currentEmailTemplate, updateEmailTemplate } = this.props;
    const { selectedInvoice } = this.state;
    if (!selectedInvoice) {
      return;
    }
    const newAttachment = {
      id: '',
      item_id: selectedInvoice.id,
      item_name: selectedInvoice.name,
      item_type: 'InvoiceTemplate',
      deleted: false,
    };
    const currentAttachments = currentEmailTemplate.email_attachments;
    updateEmailTemplate({
      email_attachments: currentAttachments.concat(newAttachment),
    });
  };

  setSelectedDocument = selectedDocument => this.setState({ selectedDocument });
  setSelectedContract = selectedContract => this.setState({ selectedContract });
  setSelectedQuestionnaire = selectedQuestionnaire =>
    this.setState({ selectedQuestionnaire });
  setSelectedInvoice = selectedInvoice => this.setState({ selectedInvoice });

  addQuestionnaire = () => {
    const { currentEmailTemplate, updateEmailTemplate } = this.props;
    const { selectedQuestionnaire } = this.state;
    if (!selectedQuestionnaire) {
      return;
    }
    const newAttachment = {
      id: '',
      item_id: selectedQuestionnaire.id,
      item_name: selectedQuestionnaire.name,
      item_type: 'WebForm',
      deleted: false,
    };
    const currentAttachments = currentEmailTemplate.email_attachments;
    updateEmailTemplate({
      email_attachments: currentAttachments.concat(newAttachment),
    });
  };

  removeAttachment = att => {
    const { currentEmailTemplate, updateEmailTemplate } = this.props;
    const currentAttachments = currentEmailTemplate.email_attachments;
    const newAttachments = [...currentAttachments];
    const index = currentAttachments.indexOf(att);
    const newAttachment = { ...att, _destroy: true };
    newAttachments.splice(index, 1, newAttachment);
    updateEmailTemplate({ email_attachments: newAttachments });
  };

  saveEmail = async () => {
    const { currentEmailTemplate } = this.props;

    let url = `/api/legacy_email_templates`;
    const body = {
      email_template: {
        ...currentEmailTemplate,
        email_attachments_attributes: currentEmailTemplate.email_attachments,
      },
    };

    let req;
    if (currentEmailTemplate.id) {
      url = `${url}/${currentEmailTemplate.id}`;
      req = axios.put(url, body);
    } else {
      req = axios.post(url, body);
    }

    try {
      const { data } = await req;
      this.props.onTemplateSave(data);
      toast.success('Email template saved');
    } catch (e) {
      console.trace(e);
      const errors = e.response.data.errors.join('. ');
      toast.info(`Error saving email: ${errors}`);
    }
  };

  render() {
    return (
      <EmailTemplateCreator
        {...this.props}
        {...this.state}
        addAttachment={this.addAttachment}
        addDocument={this.addDocument}
        addContract={this.addContract}
        addQuestionnaire={this.addQuestionnaire}
        addInvoice={this.addInvoice}
        saveEmail={this.saveEmail}
        setSelectedDocument={this.setSelectedDocument}
        setSelectedContract={this.setSelectedContract}
        setSelectedQuestionnaire={this.setSelectedQuestionnaire}
        setSelectedInvoice={this.setSelectedInvoice}
        removeAttachment={this.removeAttachment}
        reloadAttachments={this.reloadAttachments}
      />
    );
  }
}

export default EmailTemplateCreatorContainer;
