import React from 'react';
import moment from 'moment';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import TogglePopover from '~components/common/TogglePopover';
import { useWindowSize } from '~hooks';
import { MOBILE_WIDTH } from '~constants/viewports';
import AvailabilitySummary from '../AvailabilitySummary';
import styles from './BookingCalendarCell.module.scss';

const BookingCalendarCell = props => {
  const {
    current,
    day,
    availabilities,
    activeSlot,
    setActiveSlot,
    onClickCallback,
    timeZone,
  } = props;
  const cx = classNames.bind(styles);
  const windowWidth = useWindowSize()[0];
  const classes = cx('BookingCalendarCell', {
    'BookingCalendarCell--current': current,
    'BookingCalendarCell--selected':
      activeSlot && moment(activeSlot.start_time).isSame(day, 'day'),
  });
  const slotCount = availabilities.length;

  return (
    <div className={classes}>
      <div>{moment(day).format('D')}</div>
      {windowWidth <= MOBILE_WIDTH && slotCount > 0 && (
        <div className={styles['BookingCalendarCell-eventMarker']} />
      )}
      {windowWidth > MOBILE_WIDTH && slotCount > 0 && (
        <TogglePopover
          activator={
            <div className={styles['BookingCalendarCell-slotCount']}>
              <span>{slotCount}</span>
              <span>{slotCount === 1 ? 'option' : 'options'}</span>
            </div>
          }
          popoverContent={
            <AvailabilitySummary
              day={day}
              availabilities={availabilities}
              activeSlot={activeSlot}
              setActiveSlot={setActiveSlot}
              onClickCallback={onClickCallback}
              timeZone={timeZone}
            />
          }
        />
      )}
    </div>
  );
};

BookingCalendarCell.propTypes = {
  current: PropTypes.bool,
  day: PropTypes.object.isRequired,
  availabilities: PropTypes.array,
};

BookingCalendarCell.defaultProps = {
  current: false,
  availabilities: [],
};

export default BookingCalendarCell;
