import React, { useState, useRef, useCallback } from 'react';
import mapStyles from '../GoogleMap/mapStyles';
import styles from './SentLocation.module.scss';
import { Button } from '~ui';
import {
  useLoadScript,
  GoogleMap,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';

const SentLocation = ({ locations }) => {
  const location = locations.location;
  const googleMapsApiKey = locations.googleMapsApiKey;
  const studioLogoUrl = locations.studioLogoUrl;
  const locationPictureUrl = locations.locationPictureUrl;

  const [selectedLocation, setSelectedLocation] = useState(true);

  const mapRef = useRef();

  const onMapLoad = useCallback(map => {
    mapRef.current = map;
  }, []);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: googleMapsApiKey,
  });

  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  };

  const options = {
    styles: mapStyles,
  };

  const center = {
    lat: Number(location.latitude),
    lng: Number(location.longitude),
  };

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading maps';

  return (
    <div>
      <div className={styles.SentLocationWrapper}>
        <div className={styles['SentLocationWrapper-gridChildElement']}>
          {studioLogoUrl === 'studios/logos/fitted.jpg' ? (
            <div></div>
          ) : (
            <img src={studioLogoUrl} alt="studio_logo" />
          )}
        </div>
        <div className={styles['SentLocationWrapper-gridChildElement']}>
          <div className={styles['SentLocationWrapper-textChild']}>
            <p>
              {location.address}, {location.city}, {location.state},{' '}
              {location.zip}
            </p>
          </div>
          <div className={styles['SentLocationWrapper-textChild']}>
            <p>{location.description}</p>
          </div>
          <div className={styles['SentLocationWrapper-directions']}>
            <p
              onClick={() => {
                window
                  .open(
                    `https://maps.google.com?q=${location.address},${location.city},${location.state}`,
                    '_blank'
                  )
                  .focus();
              }}
            >
              Get directions
            </p>
          </div>
        </div>
      </div>
      <div>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          options={options}
          onLoad={onMapLoad}
          zoom={14}
          center={center}
        >
          <Marker
            position={{
              lat: Number(location.latitude),
              lng: Number(location.longitude),
            }}
            icon={locations.mapMarker}
            onClick={() => {
              setSelectedLocation(true);
            }}
          />
          {selectedLocation && (
            <InfoWindow
              position={{
                lat: Number(location.latitude),
                lng: Number(location.longitude),
              }}
              onCloseClick={() => {
                setSelectedLocation(false);
              }}
            >
              <div className={styles.InfoBox}>
                {locationPictureUrl !== 'missing-image-locations.jpg' ? (
                  <img
                    src={locationPictureUrl}
                    alt="Location image"
                    width="auto"
                    height={100}
                  />
                ) : (
                  <div></div>
                )}
                <h3>{location.title}</h3>
                <h4>{location.address}</h4>
                <p>{location.description}</p>
                <Button
                  onClick={() => {
                    window
                      .open(
                        `https://maps.google.com?q=${location.address},${location.city},${location.state}`,
                        '_blank'
                      )
                      .focus();
                  }}
                  text="Get directions"
                />
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </div>
    </div>
  );
};
export default SentLocation;
