import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers';

const devTools = (name = 'Iris') => {
  if (
    process.env.NODE_ENV === 'development' &&
    window.__REDUX_DEVTOOLS_EXTENSION__
  ) {
    return window.__REDUX_DEVTOOLS_EXTENSION__({ name, id: name });
  }
  return f => f;
};

export const configureStore = (initialState, options = { combine: true }) =>
  createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(thunkMiddleware),
      devTools(options.name)
    )
  );
