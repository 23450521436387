import axios from 'axios';
import { routes } from '~constants/routes';
import { INVOICE_PAYMENTS } from '~constants/actionTypes';

export const createInvoicePayment = params => async dispatch => {
  try {
    dispatch({ type: INVOICE_PAYMENTS.CREATE.REQUEST });

    const payment = await axios
      .post(routes.INVOICE_PAYMENTS.CREATE, params)
      .then(res => res.data.data.attributes);

    dispatch({ type: INVOICE_PAYMENTS.CREATE.SUCCESS, payload: payment });
  } catch (error) {
    dispatch({
      type: INVOICE_PAYMENTS.CREATE.FAILURE,
      payload: error,
      error: true,
    });
  }
};

export const updateInvoicePayment = (paymentId, params) => async dispatch => {
  try {
    dispatch({ type: INVOICE_PAYMENTS.UPDATE.REQUEST });

    const payment = await axios
      .put(routes.INVOICE_PAYMENTS.UPDATE(paymentId), params)
      .then(res => res.data.data.attributes);

    dispatch({ type: INVOICE_PAYMENTS.UPDATE.SUCCESS, payload: payment });
  } catch (error) {
    dispatch({
      type: INVOICE_PAYMENTS.UPDATE.FAILURE,
      payload: error,
      error: true,
    });
  }
};

export const deleteInvoicePayment = (paymentId, params) => async dispatch => {
  try {
    dispatch({ type: INVOICE_PAYMENTS.DELETE.REQUEST });

    const res = await axios.delete(routes.INVOICE_PAYMENTS.DELETE(paymentId), {
      data: params,
    });

    dispatch({ type: INVOICE_PAYMENTS.DELETE.SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: INVOICE_PAYMENTS.DELETE.FAILURE,
      payload: error,
      error: true,
    });
  }
};
