import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import ToggleContent from '~components/common/ToggleContent';
import CalendarEventForm from '../CalendarEventForm';
import { uniqueId } from '~utils';
import { DEFAULT_EVENTS } from '~constants/calendar';
import styles from './TimeSlotGroup.module.scss';
import AllDayTimeSlot from '../AllDayTimeSlot';
import TimeSlot from '../TimeSlot';

const TimeSlotGroup = ({ day, events, dateContext }) => {
  const renderTimeSlots = () => {
    return Object.keys(events)
      .filter(key => key !== 'all_day')
      .sort()
      .map(hour => {
        let dayClone = moment(day).clone();
        let key = uniqueId('timeSlot_');

        dayClone.set({ hour: hour, minute: '0' });

        return (
          <ToggleContent
            key={key}
            toggle={handleShow => (
              <TimeSlot
                onDoubleClick={handleShow}
                events={events[hour]}
                day={day}
              />
            )}
            content={handleHide => (
              <CalendarEventForm
                day={Number(dayClone.format('x'))}
                handleHide={handleHide}
                dateContext={dateContext}
              />
            )}
          />
        );
      });
  };

  return (
    <div className={styles.TimeSlotGroup}>
      <ToggleContent
        toggle={handleShow => (
          <AllDayTimeSlot
            onDoubleClick={handleShow}
            events={events.all_day}
            day={day}
          />
        )}
        content={handleHide => (
          <CalendarEventForm
            day={Number(moment(day).clone().format('x'))}
            handleHide={handleHide}
            allDayOverride
            dateContext={dateContext}
          />
        )}
      />
      {renderTimeSlots()}
    </div>
  );
};

TimeSlotGroup.propTypes = {
  events: PropTypes.object,
};

TimeSlotGroup.defaultProps = {
  events: DEFAULT_EVENTS,
};

export default TimeSlotGroup;
