import React, { useState, useRef } from 'react';
import styles from './ApplicationNavbar.module.scss';
import logo from '~images/logo.png';
import { MOBILE_WIDTH } from '~constants/viewports';
import { useWindowSize } from '~hooks';

const ApplicationNavbar = ({ small_logo }) => {
  const settingsRef = useRef();
  const windowWidth = useWindowSize()[0];
  const [showDropdownButton, setShowDropdownButton] = useState(false);
  const [openMobileDropdown, setOpenMobileDropdown] = useState(false);
  const [openMobileSettingsOptions, setOpenMobileSettingsOptions] = useState(
    false
  );

  const isMobileWidth = windowWidth < MOBILE_WIDTH;
  const itemHeight = 44;
  const numItems = openMobileSettingsOptions ? 11 : 9;
  const actualHeight = numItems * itemHeight;

  const currentUrl = window.location.pathname;

  const navigationPaths = {
    dashboard: '/dashboard' === currentUrl,
    emailMarketing: '/email_marketing' === currentUrl,
    clients: '/clients' === currentUrl,
    locations: '/locations' === currentUrl,
    toDos: '/todos' === currentUrl,
    calendar: '/calendar' === currentUrl,
    invoices: '/invoices' === currentUrl,
    spotlight: '/spotlight' === currentUrl,
  };

  const highlight = { color: '#8dd6ff', fontWeight: 'bold' };

  return (
    <>
      <div className={styles.ApplicationNavbar}>
        <div>
          <a
            style={{
              height: '41px',
              display: 'flex',
              marginBottom: '34px',
              paddingLeft: '0px',
            }}
            href="/"
          >
            <img src={logo} />
          </a>
        </div>
        {!isMobileWidth && (
          <div>
            <a
              href="/dashboard"
              style={navigationPaths.dashboard ? highlight : {}}
            >
              Dashboard
            </a>
            <a
              href="/email_marketing"
              style={navigationPaths.emailMarketing ? highlight : {}}
            >
              Email Marketing
            </a>
            <a href="/clients" style={navigationPaths.clients ? highlight : {}}>
              Clients
            </a>
            <a
              href="/locations"
              style={navigationPaths.locations ? highlight : {}}
            >
              Locations
            </a>
            <a href="/todos" style={navigationPaths.toDos ? highlight : {}}>
              To-dos
            </a>
            <a
              href="/calendar"
              style={navigationPaths.calendar ? highlight : {}}
            >
              Calendar
            </a>
            <a
              href="/invoices"
              style={navigationPaths.invoices ? highlight : {}}
            >
              Invoices
            </a>
            <a
              href="/spotlight"
              style={navigationPaths.spotlight ? highlight : {}}
            >
              Spotlight
            </a>
            <div
              ref={settingsRef}
              onBlur={() => {
                setShowDropdownButton(false);
              }}
              tabIndex="-1"
              onClick={() => {
                setShowDropdownButton(!showDropdownButton);
              }}
              className={styles['ApplicationNavbar-settings']}
            >
              <img src={small_logo} className={styles.SmallImage} />{' '}
              <a style={{ paddingLeft: '0px', color: 'black' }}>My Settings</a>
              <span
                style={{ marginTop: '1px', marginRight: '5px', color: 'black' }}
                className="caret"
              ></span>
              {showDropdownButton && (
                <div className={styles.SettingsOrLogout}>
                  <a
                    href="/studio/profile"
                    style={{ height: '33px' }}
                    onMouseDown={e => e.preventDefault()}
                  >
                    Settings
                  </a>
                  <a
                    href="/logout"
                    style={{ height: '33px' }}
                    onMouseDown={e => e.preventDefault()}
                  >
                    Logout
                  </a>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {isMobileWidth && (
        <div className={styles.ClientMobileNavbar}>
          <div
            onClick={() => {
              setOpenMobileDropdown(!openMobileDropdown);
              setOpenMobileSettingsOptions(false);
            }}
            className={styles.Hamburger}
            style={{ backgroundColor: openMobileDropdown ? '#ddd' : '' }}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
          {openMobileDropdown && (
            <ul
              className={
                openMobileDropdown ? styles['ClientMobileNavbar-open'] : ''
              }
              style={{
                maxHeight: `${actualHeight}px`,
              }}
            >
              <li>
                <a
                  href="/dashboard"
                  style={navigationPaths.dashboard ? highlight : {}}
                >
                  Dashboard
                </a>
              </li>
              <li>
                <a
                  href="/email_marketing"
                  style={navigationPaths.emailMarketing ? highlight : {}}
                >
                  Email Marketing
                </a>
              </li>
              <li>
                <a
                  href="/clients"
                  style={navigationPaths.clients ? highlight : {}}
                >
                  Clients
                </a>
              </li>
              <li>
                <a
                  href="/locations"
                  style={navigationPaths.locations ? highlight : {}}
                >
                  Locations
                </a>
              </li>
              <li>
                <a href="/todos" style={navigationPaths.toDos ? highlight : {}}>
                  To-dos
                </a>
              </li>
              <li>
                <a
                  href="/calendar"
                  style={navigationPaths.calendar ? highlight : {}}
                >
                  Calendar
                </a>
              </li>
              <li>
                <a
                  href="/invoices"
                  style={navigationPaths.invoices ? highlight : {}}
                >
                  Invoices
                </a>
              </li>
              <li>
                <a
                  href="/spotlight"
                  style={navigationPaths.spotlight ? highlight : {}}
                >
                  Spotlight
                </a>
              </li>
              <li>
                <div
                  onClick={() => {
                    setOpenMobileSettingsOptions(!openMobileSettingsOptions);
                  }}
                  className={styles['ApplicationNavbar-settings']}
                >
                  <img
                    src={small_logo}
                    className={styles.SmallImage}
                    style={{ marginTop: '10px' }}
                  />{' '}
                  <a
                    style={{
                      paddingLeft: '0px',
                      color: 'black',
                      display: 'block',
                    }}
                  >
                    My Settings
                    <span
                      style={{
                        marginTop: '1px',
                        marginRight: '5px',
                        color: 'black',
                        width: '5px',
                      }}
                      className="caret"
                    ></span>
                  </a>
                </div>
              </li>
              {openMobileSettingsOptions && (
                <>
                  <li>
                    {' '}
                    <a href="/studio/profile">Settings</a>
                  </li>
                  <li>
                    {' '}
                    <a href="/logout">Logout</a>
                  </li>
                </>
              )}
            </ul>
          )}
        </div>
      )}
    </>
  );
};

export default ApplicationNavbar;
