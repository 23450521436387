import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toCurrencyAmount, uniqueId } from '~utils';
import { CurrencyInput, TextInput, Button } from '~ui';
import styles from './CustomerInvoice.module.scss';
import moment from 'moment';
import { FULL_DATE_FORMAT } from '~constants/datetime';

const InvoiceTotals = props => {
  const {
    totals,
    invoice,
    currentTip,
    paidTip,
    meta,
    studio,
    handleCouponCode,
    handleTip,
    hasActiveCode,
    allowCoupons,
  } = props;
  const { hasSquare, tipsEnabled, showCouponCodes } = meta.features;
  const { currency } = meta;
  const {
    tax_before_discount,
    next_installment_date_due,
    next_installment_amount_due,
    coupon_code,
    paid_amount,
  } = invoice;
  const { subtotal, discount, tax, total, due } = totals;
  const [code, setCode] = useState('');
  const [tip, setTip] = useState(0);

  const applyCoupon = () => {
    handleCouponCode(code, studio)
  };

  const applyTip = () => {
    handleTip(tip, studio)
  }

  const taxAndDiscount = () => {
    let items = [
      { label: 'Discount', value: discount },
      { label: 'Tax', value: tax },
    ];

    if (tax_before_discount) {
      items.reverse();
    }

    return items
      .filter(item => item.value !== null)
      .map(item => {
        const { label, value } = item;
        const adjustedValue =
          label === 'Discount'
            ? `- ${toCurrencyAmount(value)}`
            : toCurrencyAmount(value);

        if (Number(item.value) > 0) {
          return (
            <div
              key={uniqueId('InvoiceTotals-line_')}
              className={styles['InvoiceTotals-line']}
            >
              <span className={styles['InvoiceTotals-lineHeader']}>
                {label}
              </span>
              <span>{adjustedValue}</span>
            </div>
          );
        }
      });
  };

  return (
    <div className={styles.InvoiceTotals}>
      <div className={styles['InvoiceTotals-line']}>
        <span className={styles['InvoiceTotals-lineHeader']}>Subtotal</span>
        <span>{toCurrencyAmount(subtotal)}</span>
      </div>
      {!discount && coupon_code && (
        <div className={styles['InvoiceTotals-line']}>
          <span className={styles['InvoiceTotals-lineHeader']}>
            Coupon Code Applied:{' '}
            <span style={{ textTransform: 'uppercase' }}>
              {coupon_code.code}
            </span>
          </span>
          <span>
            {coupon_code.type === 'money_amount'
              ? `-$${Number(coupon_code.amount).toFixed(2)}`
              : `-${Number(coupon_code.amount).toFixed(2)}%`}
          </span>
        </div>
      )}
      {taxAndDiscount()}
      <div className={styles['InvoiceTotals-line']}>
        <span className={styles['InvoiceTotals-lineHeader']}>Total</span>
        <span>{toCurrencyAmount(total)}</span>
      </div>
      {Number(paid_amount) > 0 && (
        <div className={styles['InvoiceTotals-line']}>
          <span className={styles['InvoiceTotals-lineHeader']}>
            Amount Paid
          </span>
          <span>{toCurrencyAmount(paid_amount)}</span>
        </div>
      )}
      <div className={styles['InvoiceTotals-line']}>
        <span className={styles['InvoiceTotals-lineHeader']}>Balance Due</span>
        <span>{toCurrencyAmount(due)}</span>
      </div>
      {!discount &&
        !coupon_code &&
        hasSquare &&
        showCouponCodes &&
        hasActiveCode &&
        Number(totals.due) > 0 &&
        !(Number(paid_amount) > 0) &&
        allowCoupons && (
          <div className={styles['InvoiceTotals-couponCodeContainer']}>
            <TextInput placeholder="Coupon Code" onChangeCallback={setCode} />
            <div className={styles['InvoiceTotals-applyButton']}>
              <Button text="Apply" onClick={applyCoupon} />
            </div>
          </div>
        )}
      {tipsEnabled &&
        hasSquare &&
        Number(invoice.totals.due) > 0 &&
        !invoice.is_quote &&
        !+paidTip &&
        !+currentTip && (
          <div className={styles['InvoiceTotals-tipContainer']}>
            <span className={styles['InvoiceTotals-tipLineHeader']}>Tip</span>
            <span className={styles['InvoiceTotals-inputTipContainer']}>
              <CurrencyInput
                max={5000}
                name="tip_amount"
                currencyType={currency}
                onChangeCallback={val => {
                  setTip(Number(val));
                }}
              />
              <Button text="Apply" onClick={applyTip} />
            </span>
          </div>
        )}
      {!!+paidTip > 0 && (
        <div className={styles['InvoiceTotals-line']}>
          <span className={styles['InvoiceTotals-lineHeader']}>Tip</span>  
          <span>{toCurrencyAmount(paidTip)}</span>
          </div>
      )}
      {!!+currentTip && (
        <div className={styles['InvoiceTotals-tipContainer']}>
          <span className={styles['InvoiceTotals-tipLineHeader']}>Tip</span>
          <span className={styles['InvoiceTotals-editTipContainer']}>
            <Button text="Edit" onClick={applyTip} />
            <CurrencyInput
              max={5000}
              name="tip_amount"
              currencyType={currency}
              initialValue={currentTip}
              onChangeCallback={val => {
                setTip(Number(val));
              }}
            />
          </span>            
        </div>
      )}
      {next_installment_date_due && Number(totals.due) > 0 && (
        <div className={styles['InvoiceTotals-todayLine']}>
          <span className={styles['InvoiceTotals-lineHeader']}>
            Total Due Today:{' '}
            {moment(next_installment_date_due).format(FULL_DATE_FORMAT)}
          </span>
          <span>
            {toCurrencyAmount(
              `${Number(next_installment_amount_due) + Number(currentTip)}`
            )}
          </span>
        </div>
      )}
    </div>
  );
};

InvoiceTotals.propTypes = {
  totals: PropTypes.object.isRequired,
};

export default InvoiceTotals;
