import React, { useState, useEffect } from 'react';
import { TableNew as Table, Icon, DeleteConfirmation } from '~ui';
import styles from './InvoicesTable.module.scss';
import { API_DATE_FORMAT } from '~constants/datetime';
import { useDispatch, useSelector } from 'react-redux';
import { actionsLoadingSelector } from '~selectors';
import { dropdownOptions } from './dropdownOptions';
import CSVExport from '../../Invoice/CSVExport';
import moment from 'moment';
import { FULL_DATE_FORMAT } from '~constants/datetime';
import {
  getInvoicesList,
  deleteInvoice,
  updateInvoiceListMeta,
} from '~actions/invoice';
import { getCouponCodeList } from '~actions/couponCodes';

const InvoicesTable = ({ isMobile }) => {
  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [idForDelete, setIdForDelete] = useState();
  const [currentFilterIndex, setCurrentFilterIndex] = useState(0);
  const [date, setDate] = useState(null);

  const options = dropdownOptions(
    dispatch,
    getInvoicesList,
    updateInvoiceListMeta,
    setCurrentFilterIndex,
    getCouponCodeList
  );
  const invoices = useSelector(state => state?.invoice.invoicesList);

  const studioHasQuickbooks = useSelector(
    state => state.invoice.studioHasQuickbooks
  );
  const years = useSelector(state => state.invoice.years);
  const {
    titleDisplay,
    page,
    page_count,
    total_records,
    is_quote,
    client_id,
  } = useSelector(state => state.invoice.meta);

  const isLoading = useSelector(state =>
    actionsLoadingSelector(state, ['INVOICES_LIST_GET'])
  );

  const amountHeader = is_quote ? 'Quote Amount' : 'Invoice Amount';
  const numberHeader = is_quote ? 'Quote Number' : 'Invoice Number';

  let ITEM_HEADERS = [
    numberHeader,
    'Client',
    'Due Date',
    amountHeader,
    'Paid Amount',
    'Status',
  ];

  let MOBILE_ITEM_HEADERS = [numberHeader, 'Client', 'Due Date', 'Status'];

  if (client_id !== undefined) {
    ITEM_HEADERS = [
      numberHeader,
      'Due Date',
      amountHeader,
      'Paid Amount',
      'Status',
    ];

    MOBILE_ITEM_HEADERS = [numberHeader, 'Due Date', 'Status'];
  }

  const renderDueDate = (due_date, has_quickbooks) => () => (
    <div className={styles['InvoicesTable-dueDate']}>
      {studioHasQuickbooks && (
        <div className={styles['InvoicesTable-quickbooksDot']}>
          {has_quickbooks ? (
            <div
              className={styles['InvoicesTable-quickbooksDot-submitted']}
            ></div>
          ) : (
            <div
              className={styles['InvoicesTable-quickbooksDot-notSubmitted']}
            ></div>
          )}
        </div>
      )}
      {moment(due_date).format(FULL_DATE_FORMAT)}
    </div>
  );

  const createRowItem = ({
    id,
    temp,
    invoice_num,
    client_name,
    due_date,
    invoice_amount,
    paid_amount,
    payment_status,
    has_quickbooks,
  }) => {
    const rowItem = {
      id,
      editable: true,
      deletable: true,
      temp,
      onClickCallback: () => {
        window.location.href = `/invoices/${id}`;
      },
      editButtonOverride: () => {
        window.location.href = `/invoices/${id}`;
      },
      deleteButtonOverride: () => {
        setIdForDelete(id);
        setShowDeleteModal(true);
      },
      data: {
        invoice_num: {
          value: invoice_num,
          type: 'enum',
          editable: false,
        },
        ...(client_id === undefined && {
          client_name: {
            value: client_name,
            type: 'enum',
            editable: false,
          },
        }),
        due_date: {
          value: renderDueDate(due_date, has_quickbooks),
          type: 'function',
          editable: false,
        },
        invoice_amount: {
          value: invoice_amount,
          type: 'currency',
          editable: false,
        },
        paid_amount: {
          value: paid_amount,
          type: 'currency',
          editable: false,
        },
        payment_status: {
          value: () => (
            <div
              className={styles[`InvoicesTable-${payment_status.classname}`]}
            >
              {payment_status.text}
            </div>
          ),
          type: 'function',
          editable: false,
        },
      },
    };

    return rowItem;
  };

  const createMobileRowItem = ({
    id,
    temp,
    invoice_num,
    client_name,
    due_date,
    payment_status,
    has_quickbooks,
  }) => {
    const rowItem = {
      id,
      editable: false,
      deletable: false,
      temp,
      readOnly: true,
      onClickCallback: () => {
        window.location.href = `/invoices/${id}`;
      },
      data: {
        invoice_num: {
          value: invoice_num,
          type: 'enum',
          editable: false,
        },
        ...(client_id === undefined && {
          client_name: {
            value: client_name,
            type: 'enum',
            editable: false,
          },
        }),
        due_date: {
          value: renderDueDate(due_date, has_quickbooks),
          type: 'function',
          editable: false,
        },
        payment_status: {
          value: () => (
            <div
              className={styles[`InvoicesTable-${payment_status.classname}`]}
            >
              {payment_status.text}
            </div>
          ),
          type: 'function',
          editable: false,
        },
      },
    };

    return rowItem;
  };

  useEffect(() => {
    if (invoices) {
      const newRows = invoices.map(inv => {
        if (isMobile) {
          return createMobileRowItem({
            id: +inv.id,
            temp: false,
            invoice_num: inv.attributes.invoice_num,
            client_name: inv.attributes.client_name,
            due_date: inv.attributes.due_date,
            payment_status: inv.attributes.payment_status,
            has_quickbooks: inv.attributes.has_quickbooks,
          });
        } else {
          return createRowItem({
            id: +inv.id,
            temp: false,
            invoice_num: inv.attributes.invoice_num,
            client_name: inv.attributes.client_name,
            due_date: inv.attributes.due_date,
            invoice_amount: +inv.attributes.total,
            paid_amount: +inv.attributes.paid_amount,
            payment_status: inv.attributes.payment_status,
            has_quickbooks: inv.attributes.has_quickbooks,
          });
        }
      });

      setRows(newRows);
    }
  }, [invoices, isMobile]);

  return (
    <>
      {isLoading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingText}>
            <p>Loading...</p>
            <Icon name="spinner" className="fa-pulse" large />
          </div>
        </div>
      )}
      <div className={styles.InvoicesTable}>
        <Table
          readOnly
          title={titleDisplay}
          headers={isMobile ? MOBILE_ITEM_HEADERS : ITEM_HEADERS}
          dropdownButtonProps={
            isMobile
              ? {
                  options: options,
                  label: options[currentFilterIndex].label,
                }
              : undefined
          }
          dateRangePickerProps={{
            dateRangeCallback: (startDate, endDate) => {
              const start = startDate
                ? startDate.format(API_DATE_FORMAT)
                : null;
              const end = endDate ? endDate.format(API_DATE_FORMAT) : null;
              setDate({ start, end });
              dispatch(
                updateInvoiceListMeta({
                  due_date: { start: start, end: end },
                })
              );
              dispatch(getInvoicesList());
            },
            startDate: date ? date.start : null,
            endDate: date ? date.end : null,
          }}
          rows={rows}
          setRows={setRows}
          loading={isLoading}
          searchInputProps={{
            name: 'invoice-search',
            onChangeCallback: searchValue => {
              if (searchValue) {
                dispatch(
                  updateInvoiceListMeta({
                    search_term: searchValue,
                    status: null,
                    is_quote: false,
                    titleDisplay: 'Search Results',
                    page: 1,
                    ...(date && {
                      due_date: { start: date.start, end: date.end },
                    }),
                  })
                );
              } else {
                dispatch(
                  updateInvoiceListMeta({
                    search_term: null,
                    status: 'all',
                    is_quote: false,
                    titleDisplay: 'All Invoices',
                    page: 1,
                    ...(date && {
                      due_date: { start: date.start, end: date.end },
                    }),
                  })
                );
              }
              dispatch(getInvoicesList());
            },
          }}
          page={page}
          pageCount={page_count}
          totalRecords={total_records}
          pageChangeCallback={newPageIndex => {
            dispatch(
              updateInvoiceListMeta({
                page: newPageIndex,
              })
            );
            dispatch(getInvoicesList());
          }}
        />
        {client_id === undefined && (
          <div className={styles['InvoicesTable-CSVExport']}>
            <CSVExport years={years} />
          </div>
        )}
      </div>
      {showDeleteModal && (
        <DeleteConfirmation
          handleHide={() => setShowDeleteModal(false)}
          handleDelete={async () => {
            await dispatch(deleteInvoice(idForDelete));
            await dispatch(getInvoicesList());
            setIdForDelete();
            setShowDeleteModal(false);
          }}
          resource="Invoice"
        />
      )}
    </>
  );
};

export default InvoicesTable;
