import React, { useEffect, useState } from 'react';
import styles from './ClientListTable.module.scss';
import { TableNew as Table, Icon, Pill } from '~ui';
import moment from 'moment';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { headerActionItems, rowActionItems } from './tableActions';
import { absolutePath } from '~constants/routes';
import DeleteClientModal from '../ClientListModals/DeleteClientModal';
import {
  getAllClients,
  getGroups,
  bulkUpdate,
  updateClient,
  updateClientListMeta,
  updateIdsForDelete,
} from '~actions/clientList';
import { useDispatch, useSelector } from 'react-redux';
import { actionsLoadingSelector, actionsErroredSelector } from '~selectors';
import {
  activeSubOptions,
  inactiveSubOptions,
  marketingContactSubOptions,
  getDropdownOptions,
} from '../metaMap';
import { useWindowSize } from '~hooks';
import AddToGroupModal from '../ClientListModals/AddToGroupModal';
import ActionConfirmationModal from '../ClientListModals/ActionConfirmationModal/ActionConfirmationModal';
import BulkAddToGroupModal from '../ClientListModals/BulkAddToGroupModal';

const ClientListTable = () => {
  const dispatch = useDispatch();
  const [clientIdList, setClientIdList] = useState([]);
  const [checked, setChecked] = useState(false);
  const [
    openActionConfirmationModal,
    setOpenActionConfirmationModal,
  ] = useState(false);
  const [openBulkAddToGroup, setOpenBulkAddToGroup] = useState(false);
  const windowWidth = useWindowSize()[0];
  const isMobile = windowWidth < 680;

  const [openAddToGroup, setOpenAddToGroup] = useState(null);

  const { meta, clients, idsForDelete } = useSelector(
    state => state.clientList
  );

  const { page, page_count, total_records } = useSelector(
    state => state.clientList.meta
  );

  useEffect(() => {
    setClientIdList([]);
    setChecked(false);
  }, [meta]);

  const isLoading = useSelector(state =>
    actionsLoadingSelector(state, [
      'CLIENT_LIST_GET',
      'CLIENT_LIST_BULK_UPDATE',
      'CLIENTS_UPDATE',
    ])
  );

  const options = getDropdownOptions(
    meta,
    dispatch,
    getAllClients,
    getGroups,
    updateClientListMeta
  );

  const errors = useSelector(state =>
    actionsErroredSelector(state, ['CLIENT_LIST_GET'])
  );

  useEffect(() => {
    if (isLoading !== false) {
      return;
    }

    if (errors.CLIENT_LIST_GET) {
      toast.error(
        `Error grabbing clients! Please reach out to support.`,
        toastOptions
      );
    }
  }, [isLoading, errors]);

  const onSortCallback = async (column, direction) => {
    if (column === 'Date Added') {
      let newDirection = direction === null ? 'asc' : 'desc';

      await dispatch(
        updateClientListMeta({
          ...meta,
          dateAddedSortDirection: newDirection,
          alphabeticalSort: null,
          companyAlpabeticalSort: null,
        })
      );
      dispatch(getAllClients());
    }
    if (column === 'Full Name') {
      await dispatch(
        updateClientListMeta({
          ...meta,
          alphabeticalSort: direction,
          dateAddedSortDirection: null,
          companyAlpabeticalSort: null,
        })
      );
      dispatch(getAllClients());
    }
    if (column === 'Company Name') {
      await dispatch(
        updateClientListMeta({
          ...meta,
          companyAlpabeticalSort: direction,
          alphabeticalSort: null,
          dateAddedSortDirection: null,
        })
      );
      dispatch(getAllClients());
    }
  };

  const selectAllCallback = checked => {
    if (checked) {
      const clientIds = clients.map(client => {
        return client.attributes.id;
      });
      setClientIdList(clientIds);
    } else {
      setClientIdList([]);
    }
  };

  let ITEM_HEADERS = [
    {
      column: '',
      sortable: false,
      checkbox: true,
      checked: checked,
      setChecked: setChecked,
      selectAllCallback: selectAllCallback,
    },
    { column: 'Status', sortable: false },
    {
      column: 'Full Name',
      sortable: true,
      onSortCallback: onSortCallback,
      sortDirection: meta.alphabeticalSort,
    },
    { column: 'Phone', sortable: false },
    { column: 'Email', sortable: false },
    {
      column: 'Company Name',
      sortable: true,
      onSortCallback: onSortCallback,
      sortDirection: meta.companyAlpabeticalSort,
    },
    {
      column: 'Date Added',
      sortable: true,
      onSortCallback: onSortCallback,
      sortDirection: meta.dateAddedSortDirection,
    },
  ];

  const currentSubOption = () => {
    if (meta.activeSubOptions) {
      return activeSubOptions(dispatch, getAllClients, updateClientListMeta);
    }
    if (meta.inactiveSubOptions) {
      return inactiveSubOptions(dispatch, getAllClients, updateClientListMeta);
    }
    if (meta.marketingSubOptions) {
      return marketingContactSubOptions(
        dispatch,
        getAllClients,
        updateClientListMeta
      );
    }
  };

  const downloadCSV = () => {
    const exportName = 'all';
    window.location.href = `/api/clients/export/${exportName}`;
  };

  let MOBILE_ITEM_HEADERS = [
    {
      column: '',
      sortable: false,
      checkbox: true,
      checked: checked,
      setChecked: setChecked,
      selectAllCallback: selectAllCallback,
    },
    { column: 'Status', sortable: false },
    {
      column: 'Full Name',
      sortable: true,
      onSortCallback: onSortCallback,
      sortDirection: meta.alphabeticalSort,
    },
    {
      column: 'Date Added',
      sortable: true,
      onSortCallback: onSortCallback,
      sortDirection: meta.dateAddedSortDirection,
    },
  ];

  useEffect(() => {
    dispatch(getAllClients());
  }, []);

  const [rows, setRows] = useState([]);

  const handleClientCheck = (val, id) => {
    if (val === true) {
      setClientIdList(prevClientIdList => [...prevClientIdList, id]);
    } else {
      setClientIdList(prevClientIdList =>
        prevClientIdList.filter(clientId => clientId !== id)
      );
    }
  };

  const readableStatusColors = [
    {
      status: 'Client',
      labelText: 'Client',
      color: 'gray',
    },
    {
      status: 'Lead',
      labelText: 'Lead',
      color: 'purple',
    },
    {
      status: 'Repeat Client',
      labelText: 'Repeat Client',
      color: 'green',
    },
    {
      status: 'Marketing Contact',
      labelText: 'Marketing Contact',
      color: 'purple',
    },
  ];

  const readableStatusesMap = readableStatusColors.reduce((acc, status) => {
    acc[status.status] = (
      <Pill small text={status.labelText} color={status.color} />
    );
    return acc;
  }, {});

  const createRowItem = props => {
    const {
      id,
      temp,
      status,
      name,
      phone,
      email,
      dateAdded,
      isChecked,
      company,
    } = props;

    const rowItem = {
      id,
      editable: false,
      deletable: false,
      onClickCallback: () => {
        window.location.href = absolutePath(`/clients/${id}`);
      },
      temp,
      rowActionOptions: rowActionItems(
        id,
        dispatch,
        getAllClients,
        updateClient,
        updateIdsForDelete,
        setOpenAddToGroup,
        setOpenActionConfirmationModal
      ),
      data: {
        checkbox: {
          value: isChecked,
          purpleBorder: true,
          type: 'checkbox',
          editable: true,
          preventRowOnClickCallback: true,
          onChangeCallback: e => {
            handleClientCheck(e, id);
          },
        },
        status: {
          value: readableStatusesMap[status],
          type: 'node',
          editable: false,
        },
        name: {
          value: (
            <a
              onClick={e => {
                e.stopPropagation();
              }}
              style={{ color: 'black', margin: '0px', padding: '0px' }}
              target="_blank"
              rel="noopener noreferrer"
              href={absolutePath(`/clients/${id}`)}
            >
              {name}
            </a>
          ),
          type: 'enum',
          editable: false,
        },
        phone: {
          value: phone,
          type: 'string',
          editable: false,
        },
        email: {
          value: <p>{email}</p>,
          type: 'enum',
          editable: false,
          preventRowOnClickCallback: true,
        },
        company: {
          value: company,
          type: 'string',
          editable: false,
        },
        dateAdded: {
          value: moment(dateAdded).format('MM/DD/YYYY'),
          type: 'string',
          editable: false,
        },
      },
    };

    return rowItem;
  };

  const createMobileRowItem = props => {
    const { id, status, name, dateAdded, isChecked, temp } = props;

    const rowItem = {
      id,
      deletable: false,
      onClickCallback: () => {
        window.location.href = absolutePath(`/clients/${id}`);
      },
      temp,
      data: {
        checkbox: {
          value: isChecked,
          purpleBorder: true,
          type: 'checkbox',
          editable: true,
          preventRowOnClickCallback: true,
          onChangeCallback: e => {
            handleClientCheck(e, id);
          },
        },
        status: {
          value: readableStatusesMap[status],
          type: 'node',
          editable: false,
        },
        name: {
          value: name,
          type: 'string',
          editable: false,
        },
        dateAdded: {
          value: moment(dateAdded).format('MM/DD/YYYY'),
          type: 'string',
          editable: false,
        },
      },
    };

    return rowItem;
  };

  useEffect(() => {
    if (clients) {
      const newRows = clients.map(client => {
        const isChecked = clientIdList.includes(client.attributes.id);
        if (isMobile) {
          return createMobileRowItem({
            id: +client.attributes.id,
            temp: false,
            status: returnStatus(client.attributes.status),
            name: client.attributes.full_name,
            dateAdded: client.attributes.added_at,
            isChecked,
          });
        } else {
          return createRowItem({
            id: +client.attributes.id,
            temp: false,
            status: returnStatus(client.attributes.status),
            name: client.attributes.full_name,
            phone: client.attributes.phone,
            email: client.attributes.email,
            dateAdded: client.attributes.added_at,
            isChecked,
            unsubscribed: client.attributes.unsubscribe_date,
            company: client.attributes.company_name,
          });
        }
      });

      setRows(newRows);
    }
  }, [clients, clientIdList, isMobile]);

  const returnStatus = status => {
    switch (status) {
      case 'client':
        return 'Client';
      case 'lead':
        return 'Lead';
      case 'marketing_contact':
        return 'Marketing Contact';
      case 'repeat client':
        return 'Repeat Client';
      default:
        return 'Client';
    }
  };

  return (
    <>
      {isLoading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingText}>
            <p>Loading...</p>
            <Icon name="spinner" className="fa-pulse" large />
          </div>
        </div>
      )}
      <div className={styles.ClientListTable}>
        <Table
          newPagination={true}
          actionBarCount={clientIdList.length}
          downloadOnClick={downloadCSV}
          headerActionOptions={headerActionItems(
            clientIdList,
            dispatch,
            getAllClients,
            bulkUpdate,
            setClientIdList,
            updateIdsForDelete,
            setChecked,
            setOpenActionConfirmationModal,
            setOpenBulkAddToGroup
          )}
          readOnly
          headers={isMobile ? MOBILE_ITEM_HEADERS : ITEM_HEADERS}
          dropdownButtonProps={
            isMobile
              ? {
                  options: currentSubOption(),
                  label: currentSubOption()[meta.currentFilterIndex].label,
                  lightGrey: true,
                }
              : {
                  options: currentSubOption(),
                  label: currentSubOption()[meta.currentFilterIndex].label,
                  leftOrientation: true,
                  lightGrey: true,
                }
          }
          optionsDropdownButtonProps={
            isMobile
              ? {
                  options: options,
                  label: options[meta.currentTitleIndex].label,
                  lightGrey: true,
                }
              : undefined
          }
          rows={rows}
          setRows={setRows}
          loading={isLoading}
          searchInputProps={{
            name: 'client-search',
            placeholder: 'Search by name, phone, or email',
            width: '295px',
            lightGrey: true,
            onChangeCallback: searchValue => {
              if (searchValue) {
                if (meta.titleDisplay !== 'Search Results') {
                  dispatch(
                    updateClientListMeta({
                      beforeSearchTitleDisplay: meta.titleDisplay,
                    })
                  );
                }
                dispatch(
                  updateClientListMeta({
                    titleDisplay: 'Search Results',
                    search_term: searchValue,
                    page: 1,
                  })
                );
              } else {
                dispatch(
                  updateClientListMeta({
                    titleDisplay: meta.beforeSearchTitleDisplay,
                    search_term: null,
                    page: 1,
                  })
                );
              }
              dispatch(getAllClients());
            },
          }}
          page={page}
          pageCount={page_count}
          totalRecords={total_records}
          pageChangeCallback={newPageIndex => {
            dispatch(
              updateClientListMeta({
                page: newPageIndex,
              })
            );
            dispatch(getAllClients());
          }}
          pageSizeCallback={pageCount => {
            dispatch(
              updateClientListMeta({
                itemsPerPage: pageCount,
              })
            );
            dispatch(getAllClients());
          }}
          itemsPerPage={meta.itemsPerPage}
        />
      </div>

      {idsForDelete.length > 0 && (
        <DeleteClientModal
          handleHide={() => dispatch(updateIdsForDelete([]))}
          idsForDelete={idsForDelete}
          setClientIdList={setClientIdList}
          setChecked={setChecked}
        />
      )}
      {openAddToGroup && (
        <AddToGroupModal
          clientId={openAddToGroup}
          handleHide={() => setOpenAddToGroup(null)}
        />
      )}
      {openActionConfirmationModal && (
        <ActionConfirmationModal
          handleHide={() => setOpenActionConfirmationModal(false)}
          option={openActionConfirmationModal}
        />
      )}
      {openBulkAddToGroup && (
        <BulkAddToGroupModal
          handleHide={() => setOpenBulkAddToGroup(false)}
          clientIdList={openBulkAddToGroup}
          setClientIdList={setClientIdList}
          setChecked={setChecked}
        />
      )}
    </>
  );
};

export default ClientListTable;
