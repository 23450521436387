/**
 * Sorts an array of objects alphabetically using a specific key.
 * @param {Object} firstObject The first object to compare.
 * @param {Object} secondObject The second object to compare.
 * @param {String} sortKey The key to sort on.
 * @return {Array<Object>} The alphabetized collection.
 */
export const alphabetize = (firstObject, secondObject) => {
  const firstValue = firstObject.name.toUpperCase();
  const secondValue = secondObject.name.toUpperCase();

  let comparison = 0;
  if (firstValue > secondValue) {
    comparison = 1;
  } else if (firstValue < secondValue) {
    comparison = -1;
  }
  return comparison;
};
