import React from 'react';
import styles from './SendModal.module.scss';
import { Button } from '~ui';
import { absolutePath } from '~constants/routes';

const PurchaseMarketing = ({ handleHide, marketing }) => {
  if (!marketing) {
    return (
      <div>
        <p>
          In order to send out your campaign, please upgrade your subscription
          to include email marketing.
        </p>
        <div className={styles['SendModal-footerButtons']}>
          <Button text="Cancel" danger onClick={handleHide} />
          <Button
            text="Upgrade Now"
            onClick={() => {
              window.location.href = absolutePath('/studio/subscription');
            }}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <p>
          Congrats on taking the next step for your business! We know you're
          eager to get started with Email Marketing. In order to protect our
          email reputation, our team reviews all accounts for authenticity
          before you can send your first campaign. We will notify you as soon as
          your account has been validated. If you have any questions, please
          reach out to our support team.
        </p>
        <div className={styles['SendModal-footerButtons']}>
          <Button text="Cancel" danger onClick={handleHide} />
        </div>
      </div>
    );
  }
};

export default PurchaseMarketing;
