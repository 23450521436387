import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import { toastOptions } from '~constants/toasts';
import { Button, MenuButton } from '~ui';
import ToggleContent from '~components/common/ToggleContent';
import AddPaymentScheduleModal from '../AddPaymentScheduleModal';
import RecordPaymentModal from '../RecordPaymentModal';
import RefundModal from '../RefundModal';
import InvoiceModal from '../InvoiceModal';
import DeleteInvoiceModal from '../DeleteInvoiceModal';
import QUICKBOOKS_LOGO from '~images/integrations/qb_logo.png';
import {
  getInvoice,
  updateShowAddPaymentScheduleModal,
  updateShowRecordPaymentModal,
  updateHasQuickbooks,
  showRefundModal,
} from '~actions/invoice';
import { absolutePath, routes } from '~constants/routes';
import styles from './InvoiceHeader.module.scss';

const addPaymentSchedule = dispatch => {
  dispatch(updateShowAddPaymentScheduleModal(true));
};

const asyncGetInvoice = async (dispatch, invoiceId) =>
  dispatch(getInvoice(invoiceId));

const takePayment = uuid => {
  window.open(absolutePath(`/customer/invoices/${uuid}`), '_blank');
};

const recordPayment = dispatch => {
  dispatch(updateShowRecordPaymentModal(true));
};

const initiateRefund = dispatch => {
  dispatch(showRefundModal(true));
};

const preview = id => {
  window.open(absolutePath(`/invoices/${id}.pdf`), '_blank');
};

const openInvoiceSettings = () => {
  window.open(absolutePath('/studio/invoice_setup'), '_blank');
};

const InvoiceHeader = ({ clientPayments }) => {
  const dispatch = useDispatch();
  const invoice = useSelector(state => state.invoice);

  const hasQuickbooksIntegration = useSelector(
    state => state.invoice.meta.features.hasQuickbooksIntegration
  );
  const showAddPaymentScheduleModal = useSelector(
    state => state.invoice.showAddPaymentScheduleModal
  );
  const showRecordPaymentModal = useSelector(
    state => state.invoice.showRecordPaymentModal
  );
  const displayRefundModal = useSelector(
    state => state.invoice.showRefundModal
  );

  const {
    id,
    invoice_num,
    payments,
    is_quote,
    hasQuickbooks,
    quickbooksViewUrl,
    uuid,
    schedule,
    paid_in_full,
    items,
  } = invoice;

  let canRemovePaymentSchedule = true;
  if (
    schedule.installments.length === 1 &&
    Number(schedule.installments[0].amount) === Number(invoice.total)
  ) {
    canRemovePaymentSchedule = false;
  }

  const getInvoiceActionOptions = () => {
    const actionOptions = [
      {
        label: 'Take Payment Now',
        onClickCallback: () => {
          takePayment(uuid);
        },
        ref: React.createRef(),
      },
      {
        label: 'Record Payment',
        onClickCallback: () => {
          recordPayment(dispatch);
        },
        ref: React.createRef(),
      },
      {
        label: 'Preview as PDF',
        onClickCallback: () => {
          preview(id);
        },
        ref: React.createRef(),
      },
      {
        label: 'Invoice Settings',
        onClickCallback: openInvoiceSettings,
        ref: React.createRef(),
      },
    ];

    if (clientPayments.length > 1) {
      actionOptions.push({
        label: 'Process Refund',
        onClickCallback: () => {
          initiateRefund(dispatch);
        },
        ref: React.createRef(),
      });
    }

    if (!canRemovePaymentSchedule) {
      actionOptions.unshift({
        label: 'Add Payment Schedule',
        onClickCallback: () => {
          if (items.length == 0) {
            toast.info(
              'Please add line item(s) before adding a payment schedule',
              toastOptions
            );
          } else {
            addPaymentSchedule(dispatch);
          }
        },
        ref: React.createRef(),
      });
    }

    if (paid_in_full) {
      actionOptions.splice(0, 1);
    } else if (canRemovePaymentSchedule) {
      actionOptions.splice(1, 0, {
        label: 'Remove Payment Schedule',
        onClickCallback: async () => {
          const paymentScheduleId = invoice.schedule.id;
          await axios
            .put(`/api/payment_schedules/${paymentScheduleId}/restore`)
            .then(res => {
              toast.info(
                'Looks like you’ve changed the due dates on this invoice! Please check the invoice due date and re-send the invoice if you want Iris to automatically remind your client when a payment is due.',
                { ...toastOptions, autoClose: false }
              );
            });
          asyncGetInvoice(dispatch, invoice.id);
        },
        ref: React.createRef(),
      });
    }

    return actionOptions;
  };

  const getQuickbooksOptions = () => {
    if (hasQuickbooks) {
      return [
        {
          label: 'View in QuickBooks',
          onClickCallback: () => {
            window.open(quickbooksViewUrl);
          },
          ref: React.createRef(),
        },
        {
          label: 'Delete in QuickBooks',
          onClickCallback: async () => {
            const confirmed = confirm(
              'Really delete the invoice from QuickBooks Online?'
            );
            if (confirmed) {
              try {
                await axios.delete(routes.QUICKBOOKS_INVOICE.DELETE(id));
                dispatch(updateHasQuickbooks(false));
                toast.success('Invoice deleted from Quickbooks.', toastOptions);
              } catch (error) {
                const errorMessage = 'Error deleting Invoice from Quickbooks';
                toast.error(errorMessage, toastOptions);
                Rollbar.error(`${errorMessage}: ${error}`);
              }
            }
          },
          ref: React.createRef(),
        },
      ];
    }

    return [
      {
        label: 'Push to QuickBooks',
        onClickCallback: () => {
          window.open(absolutePath(`/invoices/${id}/quickbooks`), '_self');
        },
        ref: React.createRef(),
      },
    ];
  };

  return (
    <div className={styles['InvoiceHeader']}>
      <h1>{`${is_quote ? 'Quote' : 'Invoice'} ${invoice_num}`}</h1>
      <div className={styles['InvoiceHeader-buttons']}>
        <ToggleContent
          toggle={handleShow => (
            <Button
              ariaLabel="Edit Invoice"
              category="icon"
              icon="pencil-alt"
              onClick={handleShow}
            />
          )}
          content={handleHide => <InvoiceModal handleHide={handleHide} />}
        />
        <ToggleContent
          toggle={handleShow => (
            <Button
              ariaLabel="Delete Invoice"
              category="icon"
              icon="trash-alt"
              onClick={handleShow}
            />
          )}
          content={handleHide => <DeleteInvoiceModal handleHide={handleHide} />}
        />
        {hasQuickbooksIntegration && (
          <MenuButton
            name="Quickbooks Actions"
            options={getQuickbooksOptions()}
            logo={<img src={QUICKBOOKS_LOGO} height="20" width="auto" />}
          />
        )}
        <MenuButton
          name="Invoice Actions"
          options={getInvoiceActionOptions()}
        />
        {showAddPaymentScheduleModal && (
          <AddPaymentScheduleModal
            invoice={invoice}
            handleHide={() => {
              asyncGetInvoice(dispatch, invoice.id).then(() => {
                dispatch(updateShowAddPaymentScheduleModal(false));
              });
            }}
          />
        )}
        {showRecordPaymentModal && (
          <RecordPaymentModal
            payments={payments}
            handleHide={() => dispatch(updateShowRecordPaymentModal(false))}
          />
        )}
        {displayRefundModal && (
          <RefundModal
            payments={payments}
            invoice={invoice}
            clientPayments={clientPayments}
            handleHide={() => dispatch(showRefundModal(false))}
          />
        )}
      </div>
    </div>
  );
};

export default InvoiceHeader;
