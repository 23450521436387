import React from 'react';
import styles from './ProgressBarStep.module.scss';
import classNames from 'classnames/bind';

const ProgressBarStep = props => {
  const { active, complete, onClickCallback, label, isLastStep } = props;

  const cx = classNames.bind(styles);
  const classes = cx('ProgressBarStep', {
    'ProgressBarStep-lastStep': isLastStep,
    'ProgressBarStep-active': active,
    'ProgressBarStep-complete': complete,
  });

  return (
    <div className={classes}>
      <div>
        <div onClick={onClickCallback}></div>
        <div>{label}</div>
      </div>
      {!isLastStep && <hr />}
    </div>
  );
};

export default ProgressBarStep;
