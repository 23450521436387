import React from 'react';
import PT from 'prop-types';
import axios from 'axios';
import { withContext } from 'with-context';

import ApiCacheContext from '../../../contexts/api_cache_context';
import TinymceEditor from './tinymce_editor';

@withContext(ApiCacheContext, 'apiCacheContext')
class TinymceEditorContainer extends React.PureComponent {
  static propTypes = {
    apiCacheContext: PT.object.isRequired,
    bookingUrls: PT.array,
    locations: PT.array,
  };

  uploadImage = async (file, callback) => {
    const form = new FormData();
    form.append('email_image[file]', file);
    try {
      const { data } = await axios.post('/api/email_images', form);
      callback(data.url);

      const { addItemToCache } = this.props.apiCacheContext;
      addItemToCache('email_images', data);
    } catch (e) {
      alert('error attaching file'); // eslint-disable-line
      console.error(e);
    }
  };

  fetchImageList = async callback => {
    const images = await this.props.apiCacheContext.fetchItems('email_images');
    const tinymceImages = images.map(image => ({
      title: image.name,
      value: image.url,
    }));
    callback(tinymceImages);
  };

  render() {
    return (
      <TinymceEditor
        uploadImage={this.uploadImage}
        fetchImageList={this.fetchImageList}
        {...this.props}
      />
    );
  }
}

export default TinymceEditorContainer;
