import React from 'react';
import ClientListTable from '../ClientListTable';
import GroupsTable from '../GroupsTable';
import { useWindowSize } from '~hooks';
import { useSelector } from 'react-redux';
import styles from './ClientListContent.module.scss';

const ClientListContent = props => {
  const { groups } = useSelector(state => state.clientList.meta);

  const windowWidth = useWindowSize()[0];
  const isMobile = windowWidth < 680;

  return (
    <div className={styles.ClientListContent}>
      {!groups && <ClientListTable isMobile={isMobile} />}
      {groups && <GroupsTable isMobile={isMobile} />}
    </div>
  );
};

export default ClientListContent;
