import React from 'react';
import styles from './Landing.module.scss';
import { absolutePath } from '~constants/routes';
import { useHistory } from 'react-router';
import { kaylaLockeBase64, quoteBase64 } from './images.js';

const Landing = () => {
  const history = useHistory();

  return (
    <div className={styles.Landing}>
      <div className={styles['Landing-container']}>
        <h1>Sign Up for a free 14 day trial!</h1>
        <p>
          Iris Works provides you with the tools you need to effectively run and
          grow your business in an all-in-one program that's intuitive and
          easy-to-use.
        </p>
        <img
          src={kaylaLockeBase64}
          className={styles['Landing-testimonialImage']}
        />
        <img src={quoteBase64} className={styles['Landing-quote1']} />
        <img src={quoteBase64} className={styles['Landing-quote2']} />
        <div className={styles['Landing-testimonial']}>
          <p>
            "Iris Works has saved me hours of time, streamlined my work, and
            given me a professional platform for my clients to experience.
            Couldn’t recommend them enough!"
          </p>
          <p>Kayla Locke, Locke Photography</p>
        </div>
      </div>
      <div className={styles['Landing-container']}>
        <div className={styles['Landing-card']}>
          <p>
            <span>Get Started Absolutely FREE. </span>
            No credit card needed.
          </p>
          <div className={styles['Landing-card-buttons']}>
            <button
              onClick={() => {
                history.push('/signup/form');
              }}
            >
              <i className="far fa-envelope"></i>
              Sign up with email
            </button>
          </div>
          <div className={styles['Landing-card-text']}>
            <p>
              By signing up, I agree to Iris Works'{' '}
              <a
                target="_blank"
                href="https://iris-works.com/terms-and-conditions/"
              >
                Terms and Conditions{' '}
              </a>{' '}
              and{' '}
              <a target="_blank" href="https://iris-works.com/privacy-policy/">
                Privacy Policy
              </a>
              .
            </p>
            <hr />
            <div>
              Already have an account?{' '}
              <button
                onClick={() => {
                  window.location.href = absolutePath('/login');
                }}
              >
                Log In
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
