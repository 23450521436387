import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import AvailabilityCalendarRoot from '../AvailabilityCalendar/AvailabilityCalendarRoot';
import styles from './BookingSetup.module.scss';

const SelectAvailability = props => {
  const { bookingCalendar, calendar } = props;

  useEffect(() => {
    moment.tz.setDefault(bookingCalendar.meta.timeZone);
  }, []);

  return (
    <div className={styles.BookingSetup}>
      <AvailabilityCalendarRoot
        bookingCalendar={bookingCalendar}
        calendar={calendar}
      />
    </div>
  );
};

export default SelectAvailability;
