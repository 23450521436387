const getMinute = minutes => {
  if (minutes <= 30) {
    return 30;
  }

  return 0;
};

const getHour = (hour, minutes) => {
  if (minutes > 30) {
    return ++hour;
  }

  return hour;
};

/**
 * This function takes a time and returns the time to the closest half hour.
 * - If the time is 30 minutes or less past the hour, it will return a time 30 minutes past the hour.
 * - If the time is over 30 minutes past the hour, it will return a time rounded to the next hour.
 * @param {Date} date - The date we want to convert.
 * @return {Date} - The date rounded to the closest half hour.
 */
export const getNearestHalfHourDateTime = (date = new Date()) => {
  let hour = date.getHours();
  let minutes = date.getMinutes();

  date.setMinutes(getMinute(minutes));
  date.setHours(getHour(hour, minutes));
  date.setSeconds(0);

  return date;
};

export const getDaysBetweenDates = (startDate, endDate, format) => {
  const now = startDate.clone();
  let dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format(format));
    now.add(1, 'days');
  }

  return dates;
};
