import React from 'react';
import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <div className={styles['Footer']}>
      <p>
        *Per our Terms & Conditions, you may cancel your Iris account at any
        time but will not be issued a refund.
      </p>
      <div className={styles['Footer--links']}>
        <a href="https://iris-works.com/terms-and-conditions">Terms of Use</a>
        <span>|</span>
        <a href="https://iris-works.com/privacy-policy">Privacy Policy</a>
      </div>
    </div>
  );
};

export default Footer;
