import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Landing from './Landing';
import Form from './Form';
import StudioSelections from './StudioSelections';
import Header from './Header';
import styles from './SignUpRoot.module.scss';
import { Icon } from '~ui';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const SignUpRoot = props => {
  const [currentUser, setCurrentUser] = useState('');
  const [loading, setLoading] = useState(false);

  return (
    <div className={styles.SignUpRoot}>
      {loading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingText}>
            <p>Loading...</p>
            <Icon name="spinner" className="fa-pulse" large />
          </div>
        </div>
      )}
      <Router>
        <Header />
        <Switch>
          <Route exact path="/signup">
            <Landing />
          </Route>
          <Route path="/signup/form">
            <GoogleReCaptchaProvider reCaptchaKey={props.sitekey}>
              <Form
                {...{
                  setCurrentUser,
                  loading,
                  setLoading,
                  feedbackOptions: props.feedback_form_options,
                }}
              />
            </GoogleReCaptchaProvider>
          </Route>
          <Route path="/signup/studio_setup">
            <StudioSelections
              defaultSessionTypes={props.default_session_types}
              {...{ loading, setLoading, currentUser }}
            />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default SignUpRoot;
