import React, { useState } from 'react';
import axios from 'axios';
import { routes } from '~constants/routes';
import { useSelector } from 'react-redux';
import { Button, Icon, Dropdown } from '~ui';
import SQUARE_LOGO from '~images/integrations/Square_logo_white.png';
import PAYPAL_LOGO from '~images/integrations/PayPal_logo.png';
import STRIPE_LOGO from '~images/integrations/Stripe_logo.png';
import PayPalModal from './PayPalModal';
import styles from './InvoiceSetup.module.scss';

const ProcessorContainer = ({
  buttonText,
  buttonColor,
  imgSrc,
  imgWidth,
  imgHeight,
  backgroundColor,
  onClick,
}) => (
  <div className={styles.ProcessorContainer}>
    <div
      style={{ backgroundColor: backgroundColor, borderColor: backgroundColor }}
    >
      <img src={imgSrc} width={imgWidth} height={imgHeight} />
    </div>
    <div>
      <Button
        small
        onClick={onClick}
        text={buttonText}
        className={buttonColor === 'red' ? undefined : styles.ConnectButton}
        danger={buttonColor === 'red'}
      />
    </div>
  </div>
);

const PaymentProcessorsSection = ({ asyncUpdateInvoiceConfig }) => {
  const invoiceConfig = useSelector(state => state.invoiceConfig);
  const [showOtherProcessors, setShowOtherProcessors] = useState(false);
  const [showPayPalModal, setShowPayPalModal] = useState(false);
  const [savingLocation, setSavingLocation] = useState(false);

  return (
    <div className={styles.PaymentProcessorsContainer}>
      {invoiceConfig.showOtherProcessorsOption && (
        <h2>Our Preferred Processor</h2>
      )}
      <div>
        <ProcessorContainer
          buttonText={
            invoiceConfig.meta.features.hasSquare ? 'Disconnect' : 'Connect'
          }
          buttonColor={invoiceConfig.meta.features.hasSquare && 'red'}
          imgSrc={SQUARE_LOGO}
          imgWidth="100px"
          imgHeight="25px"
          backgroundColor="black"
          onClick={() => {
            if (invoiceConfig.meta.features.hasSquare) {
              axios.delete(routes.SQUARE_CONNECTION.DELETE).then(res => {
                if (res.status === 200) {
                  window.location.reload();
                }
              });

              axios.delete(routes.FEATURE_FLAGS.DELETE, {
                data: {
                  feature_flag: {
                    studio_id: invoiceConfig.meta.studio_id,
                    feature: 'square_user_tips_enabled',
                  },
                },
              });
            } else {
              window.location.href = invoiceConfig.meta.square_connect_url;
            }
          }}
        />
        {invoiceConfig.meta.features.hasSquare ? (
          <div className={styles['PaymentProcessorsContainer-squareLocations']}>
            <div>
              <Dropdown
                labelText={
                  <div>
                    Square Location{' '}
                    <span
                      className={
                        styles['PaymentProcessorsContainer-squareAdditional']
                      }
                    >
                      Additional Square options available in Settings below.
                    </span>
                  </div>
                }
                name="square_location"
                disabled={savingLocation}
                options={invoiceConfig.meta.squareLocationOptions}
                initialValue={invoiceConfig.square_location_id}
                onChangeCallback={value => {
                  setSavingLocation(true);
                  asyncUpdateInvoiceConfig({
                    square_location_id: value,
                  }).then(() => {
                    window.location.reload();
                  });
                }}
              />
              {savingLocation && <p>Saving location...</p>}
            </div>
            {invoiceConfig.meta.squareLocationOptions.length === 0 && (
              <p className="c-cr">
                There was a problem retrieving your Square locations.
                <br />
                You may need to reconnect with Square.
              </p>
            )}
          </div>
        ) : (
          <div>
            <ul>
              <li>
                <strong>Special Offer:</strong> Sign up and get free processing
                on up to $1,000 in card processing.
              </li>
              <li>
                Pay 2.9% + $0.30 per online transaction. Custom rates available
                for qualified businesses.
              </li>
              <li>See your money in 1-2 business days.</li>
            </ul>
            <a
              href="https://iris-works.com/square-preferred-partner/"
              target="_blank"
            >
              Learn More
            </a>
          </div>
        )}
      </div>

      {invoiceConfig.showOtherProcessorsOption && (
        <>
          <button
            onClick={() => {
              setShowOtherProcessors(!showOtherProcessors);
            }}
          >
            <span>
              <Icon small name={showOtherProcessors ? 'minus' : 'plus'} />
            </span>
            <h2>Other Processors</h2>
          </button>
          {showOtherProcessors && (
            <div>
              {invoiceConfig.hasPayPal && (
                <ProcessorContainer
                  buttonText={
                    invoiceConfig.meta.features.hasPayPal ? 'Edit' : 'Connect'
                  }
                  imgSrc={PAYPAL_LOGO}
                  imgWidth="100px"
                  imgHeight="26px"
                  backgroundColor="#ffc300"
                  onClick={() => {
                    setShowPayPalModal(true);
                  }}
                />
              )}
              {invoiceConfig.hasStripe && (
                <ProcessorContainer
                  buttonText={
                    invoiceConfig.meta.features.hasStripe
                      ? 'Disconnect'
                      : 'Connect'
                  }
                  buttonColor={invoiceConfig.meta.features.hasStripe && 'red'}
                  imgSrc={STRIPE_LOGO}
                  imgWidth="100px"
                  imgHeight="42px"
                  backgroundColor="#32325d"
                  onClick={() => {
                    if (invoiceConfig.meta.features.hasStripe) {
                      axios
                        .delete(routes.STRIPE_CONNECTION.DELETE)
                        .then(res => {
                          if (res.status === 204) {
                            window.location.reload();
                          }
                        });
                    } else {
                      window.location.href =
                        invoiceConfig.meta.stripe_connect_url;
                    }
                  }}
                />
              )}
            </div>
          )}
        </>
      )}

      {showPayPalModal && (
        <PayPalModal
          hasPayPal={invoiceConfig.meta.features.hasPayPal}
          paypalAddress={invoiceConfig.paypal_address}
          handleHide={shouldReload => {
            setShowPayPalModal(false);

            if (shouldReload) {
              window.location.reload();
            }
          }}
          handleUpdate={asyncUpdateInvoiceConfig}
        />
      )}
    </div>
  );
};

export default PaymentProcessorsSection;
