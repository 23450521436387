import React, { useEffect, useState } from 'react';
import { Button, Dropdown } from '~ui';
import { absolutePath } from '~constants/routes';
import styles from './BookingLandingPage.module.scss';
import { useWindowSize } from '~hooks';
import { MOBILE_WIDTH } from '~constants/viewports';
import { isMobile } from '~constants/userAgent';

const BookingLandingPage = props => {
  const {
    bookingPhoto,
    studio,
    initialBookingCalendars,
    customMessage,
  } = props.props;
  const [bookingCalendarId, setBookingCalendarId] = useState('Select calendar');
  const [calendarArray, setCalendarArray] = useState([]);
  const picturePresent = true;
  const windowWidth = useWindowSize()[0];
  const mobileScreen = windowWidth <= MOBILE_WIDTH || isMobile();
  const usePicture = !mobileScreen && picturePresent ? true : false;

  useEffect(() => {
    let refinedCalendarList = [];
    initialBookingCalendars.forEach(calendar => {
      if (calendar.show_on_landing) {
        refinedCalendarList.push(calendar);
      }
    });
    const calendarArray = refinedCalendarList.map(calendar => {
      const value = calendar.uuid;
      const label = calendar.title;
      return { value, label };
    });
    calendarArray.splice(0, 0, {
      value: 'Select calendar',
      label: 'Select calendar',
    });
    setCalendarArray(calendarArray);
  }, [initialBookingCalendars]);

  const sendToCalendar = () => {
    window.open(absolutePath(`/customer/booking/${bookingCalendarId}`));
  };

  return (
    <div
      className={
        studio.booking_transparent_background
          ? styles['MasterBookingCalendar-transparent']
          : styles['MasterBookingCalendar-nonTransparent']
      }
    >
      <div className={styles['MasterBookingCalendar-flex']}>
        {usePicture && studio.booking_photo_file_name && (
          <div
            className={styles['MasterBookingCalendar-background']}
            style={{ backgroundImage: `url(${bookingPhoto})` }}
          />
        )}
        <div
          style={
            usePicture && studio.booking_photo_file_name
              ? { width: '60%', position: 'relative' }
              : { width: '100%', position: 'relative' }
          }
        >
          {studio.booking_display_logo && (
            <div style={{ textAlign: 'center' }}>
              <img
                src={studio.logo}
                className={
                  studio.booking_photo_file_name
                    ? styles['MasterBookingCalendar-logo']
                    : styles['MasterBookingCalendar-small-logo']
                }
              />
            </div>
          )}
          <h1>{customMessage}</h1>
          <div className={styles['MasterBookingCalendar-dropdown']}>
            <Dropdown
              initialValue={'Select calendar'}
              name="Select calendar"
              options={calendarArray}
              onChangeCallback={setBookingCalendarId}
            />
          </div>
          <div className={styles['MasterBookingCalendar-button']}>
            <Button
              text="Book now"
              onClick={sendToCalendar}
              disabled={bookingCalendarId == 'Select calendar' ? true : false}
              style={{
                background: studio.booking_submit_color,
                border: studio.booking_submit_color,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingLandingPage;
