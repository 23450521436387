import React from 'react';
import CalendarRoute from './CalendarRoute';
import InfoRoute from './InfoRoute';
import ContractRoute from './ContractRoute';
import InvoiceRoute from './InvoiceRoute';
import ConfirmRoute from './ConfirmRoute';
import BookingHeader from './BookingHeader';
import { configureStore } from '../../store';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import BookingFooter from './BookingFooter';

const BookingRoot = props => {
  const {
    bookingCalendar,
    studio,
    availabilities,
    meta,
    user,
    token,
    firstAvailabilityDate,
    showEmailMarketing,
    countryOptions,
    hasActiveCode,
  } = props;
  const {
    invoice_required,
    contract_required,
    address_required,
  } = props.bookingCalendar;
  const calendarUUID = window.location.pathname.split('/')[3];

  const BOOKING_PATHS = {
    root: (uuid = ':uuid') => `/customer/booking/${uuid}`,
    ...(!bookingCalendar.client_id && {
      info: (uuid = ':uuid') => `/customer/booking/${uuid}/info`,
    }),
    ...(contract_required && {
      contract: (uuid = ':uuid') => `/customer/booking/${uuid}/contract`,
    }),
    ...(invoice_required && {
      invoice: (uuid = ':uuid') => `/customer/booking/${uuid}/invoice`,
    }),
    confirm: (uuid = ':uuid') => `/customer/booking/${uuid}/confirm`,
  };

  const initialState = {
    customerBooking: {
      bookingLogicCompleted: false,
      selectedAvailability: {},
      clientInfo: {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        id: bookingCalendar.client_id,
      },
      showTimer: false,
      bookingCalendar: props.bookingCalendar,
      contract: {},
      calendarEvent: null,
      invoice: null,
      firstAvailabilityDate,
      meta,
      user,
      studio,
      token,
      bookingFooterState: {
        onContinue: null,
        currentSelection: null,
        continueDisabled: false,
      },
      BOOKING_PATHS: BOOKING_PATHS,
      uuid: calendarUUID,
    },
  };

  const store = configureStore(initialState);

  return (
    <Provider store={store}>
      <Router>
        <BookingHeader
          BOOKING_PATHS={BOOKING_PATHS}
          studio={studio}
          uuid={calendarUUID}
          bookingCalendar={bookingCalendar}
        />
        <Switch>
          <Route exact path={BOOKING_PATHS.root()}>
            <CalendarRoute
              bookingCalendar={bookingCalendar}
              availabilities={availabilities}
              studio={studio}
              firstAvailabilityDate={firstAvailabilityDate}
            />
          </Route>
          {!bookingCalendar.client_id && (
            <Route path={BOOKING_PATHS.info()}>
              <InfoRoute
                showEmailMarketing={showEmailMarketing}
                addressRequired={address_required}
                contractRequired={contract_required}
                countryOptions={countryOptions}
                uuid={calendarUUID}
                BOOKING_PATHS={BOOKING_PATHS}
              />
            </Route>
          )}
          {contract_required && (
            <Route path={BOOKING_PATHS.contract()}>
              <ContractRoute
                uuid={calendarUUID}
                BOOKING_PATHS={BOOKING_PATHS}
              />
            </Route>
          )}
          {invoice_required && (
            <Route path={BOOKING_PATHS.invoice()}>
              <InvoiceRoute
                uuid={calendarUUID}
                BOOKING_PATHS={BOOKING_PATHS}
                hasActiveCode={hasActiveCode}
              />
            </Route>
          )}
          <Route path={BOOKING_PATHS.confirm()}>
            <ConfirmRoute studio={studio} />
          </Route>
        </Switch>
        <BookingFooter BOOKING_PATHS={BOOKING_PATHS} uuid={calendarUUID} />
      </Router>
      {/* Only here for styling purposes */}
      <div style={{ height: '120px' }}></div>
    </Provider>
  );
};

export default BookingRoot;
