import React, { useState, useEffect, useRef } from 'react';
import styles from './ClientDetails.module.scss';
import FamilyMemberModal from './ClientModals/FamilyMemberModal';
import NotesAndCommentsModal from './ClientModals/NotesAndCommentsModal';
import AdditionalDetailsModal from './ClientModals/AdditionalDetailsModal';
import { Button } from '~ui';
import moment from 'moment';
import ToggleContent from '~components/common/ToggleContent';
import ContactInfoEditModal from './ClientModals/ContactInfoEditModal';
const ClientDetails = props => {
  const {
    dateAdded,
    emailMarketingEnabled,
    emailMarketingUnsubscribeDate,
    emailValidated,
    referredBy,
    subscribedToEmailMarketing,
    children,
    spouse,
    id,
  } = props;

  const [hide, setHide] = useState(true);
  const [partner, setPartner] = useState(spouse || '');
  const [partnerToUpdate, setPartnerToUpdate] = useState(false);
  const [kids, setKids] = useState(children || '');
  const [childToUpdate, setChildToUpdate] = useState(false);
  const [modalTitle, setModalTitle] = useState('Add a child');
  const [partnerOrChild, setPartnerOrChild] = useState('spouse');
  const [comments, setComments] = useState(props.comments);
  const [notesTooLarge, setNotesTooLarge] = useState(false);
  const [commentsTooLarge, setCommentsTooLarge] = useState(false);
  const [notesOrComments, setNotesOrComments] = useState(false);
  const [value, setValue] = useState(false);

  const [contactInformation, setContactInformation] = useState({
    birthday: props.birthday ? moment(props.birthday).format('MM/DD/YYYY') : '',
    email: props.email,
    alternateEmail: props.alternateEmail,
    phone: props.phoneNumber,
    secondaryPhone: props.secondaryPhone,
    companyName: props.companyName,
    facebook: props.facebookProfile,
    instagram: props.instagramHandle,
    notes: props.notes,
    city: props.city,
    state: props.state,
    zip: props.zip,
    country: props.country,
    address: props.address,
    firstName: props.firstName,
    lastName: props.lastName,
    fullAddress: props.fullAddress,
  });

  const [additionalDetails, setAdditionalDetails] = useState({
    weddingDate: moment(props.weddingDate).format('MM/DD/YYYY'),
    subscribedToEmailMarketing: subscribedToEmailMarketing,
    referredBy: referredBy,
  });

  const noteSection = useRef(null);
  const commentSection = useRef(null);

  useEffect(() => {
    if (noteSection.current) {
      let height = noteSection.current.offsetHeight;

      if (height > 70) {
        setNotesTooLarge(true);
      } else {
        setNotesTooLarge(false);
      }
    }
    if (commentSection.current) {
      let height = commentSection.current.offsetHeight;
      if (height > 70) {
        setCommentsTooLarge(true);
      } else {
        setCommentsTooLarge(false);
      }
    }
  }, [noteSection, commentSection, contactInformation.notes]);

  useEffect(() => {
    partnerDetails();
  }, [partner]);

  useEffect(() => {
    childrenDetails();
  }, [kids]);

  useEffect(() => {
    if (contactInformation.email !== props.email) {
      isEmailvalidated();
    }
  }, [contactInformation.email]);

  useEffect(() => {
    hasEmailMarketing();
  }, [additionalDetails]);

  const getBirthday = birthday => {
    if (birthday) {
      return moment(birthday).format('MMMM D, YYYY');
    }
  };

  const getAge = birthday => {
    if (birthday) {
      let age;
      const ageInYears = moment().diff(moment(birthday, 'YYYYMMDD'), 'years');
      const ageInMonths = moment().diff(moment(birthday, 'YYYYMMDD'), 'months');
      const ageInDays = moment().diff(moment(birthday, 'YYYYMMDD'), 'days');

      if (ageInYears > 0) {
        age = `(${ageInYears})`;
      } else if (ageInMonths > 0) {
        age =
          ageInMonths > 1
            ? `(${ageInMonths} months)`
            : `(${ageInMonths} month)`;
      } else {
        age = ageInDays > 1 ? `(${ageInDays} days)` : `(${ageInDays} day)`;
      }

      return age;
    }
  };

  const isEmailvalidated = () => {
    let emailHasChanged = false;
    if (contactInformation.email !== props.email) {
      emailHasChanged = true;
    }
    if (emailValidated == null || emailHasChanged) {
      return <p>{contactInformation.email}</p>;
    } else if (emailValidated) {
      return (
        <p>
          <span className="fa fa-check-circle teal"></span>{' '}
          {contactInformation.email}{' '}
        </p>
      );
    } else {
      return (
        <>
          <a title="This is an invalid email address">
            <span className="fa fa-times red"></span>
          </a>{' '}
          {contactInformation.email}
        </>
      );
    }
  };

  const hasEmailMarketing = () => {
    if (emailMarketingUnsubscribeDate) {
      return <p>Unsubscribed</p>;
    } else if (additionalDetails.subscribedToEmailMarketing) {
      return <p>Active</p>;
    } else {
      return <p>Inactive</p>;
    }
  };

  const partnerDetails = () => {
    if (partner != '') {
      return (
        <>
          <div
            className={styles['backgroundForChild']}
            onClick={() => updatePartner(partner)}
          >
            <div>
              <p>{partner.name}</p>
              <div className={styles['child-birthday-details']}>
                <p>Birthdate: {getBirthday(partner.birthday)}</p>
                <p>Age: {getAge(partner.birthday)}</p>
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  const childrenDetails = () => {
    const lastKid = kids[kids.length - 1];
    if (kids != '') {
      return (
        <>
          {kids.map(kid => {
            return [
              <div
                className={styles['backgroundForChild']}
                key={kid.id}
                onClick={() => updateChild(kid)}
              >
                <div>
                  <p>{kid.name}</p>
                  <div className={styles['child-birthday-details']}>
                    <p>Birthdate: {getBirthday(kid.birthday)}</p>
                    <p>Age: {getAge(kid.birthday)}</p>
                  </div>
                </div>
                {lastKid.id !== kid.id && <hr />}
              </div>,
            ];
          })}
        </>
      );
    }
  };

  const createPartner = title => {
    setModalTitle(title);
    setPartnerOrChild('spouse');
    setHide(false);
  };

  const createChild = title => {
    setModalTitle(title);
    setPartnerOrChild('child');
    setHide(false);
  };

  const updatePartner = partner => {
    setModalTitle('Update partner information');
    setPartnerOrChild('spouse');
    setPartnerToUpdate(partner);
    setHide(false);
  };

  const updateChild = kid => {
    setModalTitle('Update child information');
    setPartnerOrChild('child');
    setChildToUpdate(kid);
    setHide(false);
  };

  return (
    <div className={styles.detailsWrapper}>
      <div className={styles.ClientProfile}>
        <div className={styles['ClientProfile-infoContainer']}>
          <div
            className={styles['ClientProfile-infoContainer-companyProfileBox']}
            style={{ height: 'fit-content' }}
          >
            <div
              className={
                styles['ClientProfile-infoContainer-companyProfileBox-titleBar']
              }
            >
              <div
                className={
                  styles[
                    'ClientProfile-infoContainer-companyProfileBox-titleBar-title'
                  ]
                }
              >
                Contact Information
              </div>
              <div
                className={
                  styles[
                    'ClientProfile-infoContainer-companyProfileBox-titleBar-icon'
                  ]
                }
              >
                <ToggleContent
                  toggle={handleShow => (
                    <i
                      className="fas fa-pencil-alt c-dg"
                      onClick={handleShow}
                    />
                  )}
                  content={handleHide => (
                    <ContactInfoEditModal
                      handleHide={handleHide}
                      contactInformation={contactInformation}
                      setContactInformation={setContactInformation}
                      id={id}
                      countryOptions={props.countryOptions}
                    />
                  )}
                />
              </div>
            </div>

            <div className={styles.contentBlockWrapper}>
              <div className={styles['contentBlockWrapper-block']}>
                <div
                  className={styles['ClientProfile-infoContainer-infoHeader']}
                >
                  Email
                </div>
                <div className={styles['ClientProfile-infoContainer-info']}>
                  <a href={`mailto:${contactInformation.email}`}>
                    {isEmailvalidated()}
                  </a>
                </div>
                <div
                  className={styles['ClientProfile-infoContainer-infoHeader']}
                >
                  Alternate Email
                </div>
                <div className={styles['ClientProfile-infoContainer-info']}>
                  <a href={`mailto:${contactInformation.alternateEmail}`}>
                    {contactInformation.alternateEmail}
                  </a>
                </div>
                <div
                  className={styles['ClientProfile-infoContainer-infoHeader']}
                >
                  Address
                </div>
                <div className={styles['ClientProfile-infoContainer-info']}>
                  {contactInformation.fullAddress}
                </div>
                <div
                  className={styles['ClientProfile-infoContainer-infoHeader']}
                >
                  Facebook
                </div>
                <div
                  className={styles['ClientProfile-infoContainer-info']}
                  style={{ overflowWrap: 'anywhere' }}
                >
                  {contactInformation.facebook}
                </div>
                <div
                  className={styles['ClientProfile-infoContainer-infoHeader']}
                >
                  Birthday
                </div>
                <div className={styles['ClientProfile-infoContainer-info']}>
                  {contactInformation.birthday}
                </div>
              </div>
              <div style={{ width: '50%' }}>
                <div
                  className={styles['ClientProfile-infoContainer-infoHeader']}
                >
                  Phone
                </div>
                <div className={styles['ClientProfile-infoContainer-info']}>
                  {contactInformation.phone}
                </div>
                <div
                  className={styles['ClientProfile-infoContainer-infoHeader']}
                >
                  Secondary Phone
                </div>
                <div className={styles['ClientProfile-infoContainer-info']}>
                  {contactInformation.secondaryPhone}
                </div>
                <div
                  className={styles['ClientProfile-infoContainer-infoHeader']}
                >
                  Company Name
                </div>
                <div className={styles['ClientProfile-infoContainer-info']}>
                  {contactInformation.companyName}
                </div>
                <div
                  className={styles['ClientProfile-infoContainer-infoHeader']}
                >
                  Instagram
                </div>
                <div className={styles['ClientProfile-infoContainer-info']}>
                  {contactInformation.instagram}
                </div>
              </div>
            </div>

            <div className={styles['ClientProfile-infoContainer-infoHeader']}>
              Notes
            </div>
            <div
              className={styles['ClientProfile-infoContainer-info']}
              style={
                notesTooLarge
                  ? {
                      whiteSpace: 'pre-line',
                      height: '70px',
                      overflow: 'hidden',
                    }
                  : { whiteSpace: 'pre-line' }
              }
              ref={noteSection}
            >
              {contactInformation.notes}
            </div>
            {notesTooLarge && (
              <div className={styles['ClientProfile-infoContainer-info']}>
                <br />
                <a onClick={() => setNotesOrComments('notes')}>...more</a>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.ClientProfile}>
        <div className={styles['ClientProfile-infoContainer']}>
          <div
            className={styles['ClientProfile-infoContainer-companyProfileBox']}
            style={{ height: 'fit-content' }}
          >
            <div
              className={
                styles['ClientProfile-infoContainer-companyProfileBox-titleBar']
              }
            >
              <div
                className={
                  styles[
                    'ClientProfile-infoContainer-companyProfileBox-titleBar-title'
                  ]
                }
              >
                Additional Details
              </div>
            </div>
            <div className={styles.contentBlockWrapper}>
              <div className={styles['contentBlockWrapper-block']}>
                <div
                  className={styles['ClientProfile-infoContainer-infoHeader']}
                >
                  <div style={{ display: 'flex', position: 'relative' }}>
                    Partner
                    {partner == '' && (
                      <div className={styles['addMarginToButtons']}>
                        <Button
                          small
                          text="+ Add"
                          onClick={() => createPartner('Add a partner')}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles['ClientProfile-infoContainer-info']}>
                  {partnerDetails()}
                </div>
                <div
                  className={styles['ClientProfile-infoContainer-infoHeader']}
                >
                  <div style={{ display: 'flex', position: 'relative' }}>
                    Children
                    <div className={styles['addMarginToButtons']}>
                      <Button
                        small
                        text="+ Add"
                        onClick={() => createChild('Add a child')}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles['ClientProfile-infoContainer-info']}>
                  {childrenDetails()}
                </div>
                <div
                  className={styles['backgroundForChild']}
                  onClick={() => setValue('weddingDate')}
                >
                  <div>
                    <div
                      className={
                        styles['ClientProfile-infoContainer-infoHeader']
                      }
                    >
                      Wedding Date
                    </div>
                    <div className={styles['ClientProfile-infoContainer-info']}>
                      {props.weddingDate && additionalDetails.weddingDate}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: '50%' }}>
                <div
                  className={styles['ClientProfile-infoContainer-infoHeader']}
                >
                  Date Added
                </div>
                <div className={styles['ClientProfile-infoContainer-info']}>
                  {dateAdded}
                </div>
                {emailMarketingEnabled && (
                  <div
                    className={
                      !emailMarketingUnsubscribeDate &&
                      styles['backgroundForChild']
                    }
                    onClick={() =>
                      !emailMarketingUnsubscribeDate && setValue('E-Mktg')
                    }
                  >
                    <div>
                      <div
                        className={
                          styles['ClientProfile-infoContainer-infoHeader']
                        }
                      >
                        Enrolled in E-Mktg
                      </div>
                      <div
                        className={styles['ClientProfile-infoContainer-info']}
                      >
                        {hasEmailMarketing()}
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={styles['backgroundForChild']}
                  onClick={() => setValue('referredBy')}
                >
                  <div>
                    <div
                      className={
                        styles['ClientProfile-infoContainer-infoHeader']
                      }
                    >
                      Referred By
                    </div>
                    <div className={styles['ClientProfile-infoContainer-info']}>
                      {additionalDetails.referredBy}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles['ClientProfile-infoContainer-infoHeader']}>
              Lead Comments
            </div>
            <div className={styles['ClientProfile-infoContainer-info']}>
              <div
                dangerouslySetInnerHTML={{ __html: comments }}
                ref={commentSection}
                style={
                  commentsTooLarge
                    ? {
                        whiteSpace: 'pre-line',
                        height: '70px',
                        overflow: 'hidden',
                      }
                    : { whiteSpace: 'pre-line' }
                }
              />
            </div>
            {commentsTooLarge && (
              <div className={styles['ClientProfile-infoContainer-info']}>
                <br />
                <a onClick={() => setNotesOrComments('comments')}>...more</a>
              </div>
            )}
          </div>
        </div>
      </div>
      {!hide && (
        <FamilyMemberModal
          title={modalTitle}
          partnerOrChild={partnerOrChild}
          handleHide={setHide}
          kids={kids}
          partner={partner}
          setPartner={setPartner}
          setKids={setKids}
          childToUpdate={childToUpdate}
          partnerToUpdate={partnerToUpdate}
          setChildToUpdate={setChildToUpdate}
          setPartnerToUpdate={setPartnerToUpdate}
          clientId={id}
        />
      )}
      {notesOrComments && (
        <NotesAndCommentsModal
          notes={contactInformation.notes}
          comments={props.comments}
          notesOrComments={notesOrComments}
          setNotesOrComments={setNotesOrComments}
        />
      )}
      {value && (
        <AdditionalDetailsModal
          handleHide={() => setValue(false)}
          additionalDetails={additionalDetails}
          setAdditionalDetails={setAdditionalDetails}
          id={id}
          value={value}
        />
      )}
    </div>
  );
};

export default ClientDetails;
