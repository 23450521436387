import React from 'react';
import classNames from 'classnames/bind';
import styles from './Item.module.scss';
import moment from 'moment';

const Item = props => {
  const { icon, description, datetime, children, index } = props;

  const cx = classNames.bind(styles);
  const classes = cx('Item', {
    'Item-gray': index % 2 === 0,
    'Item-white': index % 2 === 1,
  });

  return (
    <div className={classes}>
      <span className={styles['Item-icon']}>
        <span className="fa-stack fa-lg">
          <i className="fa fa-circle fa-stack-2x" />
          <i className={`fa ${icon} fa-stack-1x`} />
        </span>
      </span>
      <div className={styles['Item-description']}>{description}</div>
      <div>{datetime && moment(datetime).format('MM/DD/YYYY')}</div>
      <div className={styles['Item-buttonGroup']}>{children}</div>
    </div>
  );
};

export default Item;
