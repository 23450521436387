import React from 'react';

import DocumentsContent from './DocumentsContent';
import DocumentsHeader from './DocumentsHeader';

import { configureStore } from '../../store';
import { Provider } from 'react-redux';

const DocumentsRoot = ({
  clients,
  locations,
  bookingUrls,
  hasStudioSignature,
  hasDrawnSignature,
  contractsOptions,
  activeTrial,
  studioId,
  trialVerified,
}) => {
  const store = configureStore({
    documents: {
      clientDocuments: { data: [], meta: { page: 1, limit: 10 } },
      documents: { data: [], meta: { page: 1, limit: 10 } },
      questionnaires: { data: [], meta: { page: 1, limit: 10 } },
      contracts: { data: [], meta: { page: 1, limit: 10 } },
      clientsOptions: clients,
      locations: locations,
      bookingUrls: bookingUrls,
      clientId: null,
      hasStudioSignature: hasStudioSignature,
      hasDrawnSignature: hasDrawnSignature,
      contractsOptions: contractsOptions,
      activeTrial: activeTrial,
      studioId: studioId,
      trialVerified,
    },
  });

  return (
    <Provider store={store}>
      <DocumentsHeader />
      <DocumentsContent clients={clients} />
    </Provider>
  );
};

export default DocumentsRoot;
