import React, { useState, useEffect } from 'react';
import { absolutePath } from '~constants/routes';
import { Button } from '~ui';
import axios from 'axios';
import styles from './ClientGalleries.module.scss';
import moment from 'moment';
import { useSelector } from 'react-redux';

const ClientGalleries = props => {
  const { hasShootProofIntegration, hasPicTimeIntegration, clientId } = props;
  const calendarEvents = useSelector(state => state.calendar.clientEvents.data);
  const [shootProofgalleryArray, setShootProofGalleryArray] = useState([]);
  const [picTimeGalleryArray, setPicTimeGalleryArray] = useState([]);

  const grabGalleries = async () => {
    const res = await axios.get(
      `/api/galleries/shoot_proof?client_id=${clientId}`
    );
    setShootProofGalleryArray(res.data.data);
  };

  const grabPicTimeGalleries = async () => {
    const res = await axios.get(
      `/api/galleries/pic_time?client_id=${clientId}`
    );
    setPicTimeGalleryArray(res.data.data);
  };

  useEffect(() => {
    if (hasShootProofIntegration) {
      grabGalleries();
    }
    if (hasPicTimeIntegration) {
      grabPicTimeGalleries();
    }
  }, [calendarEvents]);

  const generateShootProof = () => {
    return (
      <>
        <h1>ShootProof Galleries</h1>
        {!hasShootProofIntegration ? (
          <div className={styles.GalleryButton}>
            <a href={absolutePath(`/studio/integrations`)}>
              <Button text={'Connect with ShootProof'} />
            </a>
          </div>
        ) : (
          <div className={styles.ClientGallery}>
            {shootProofgalleryArray.length == 0 ? (
              <div>This client has no ShootProof galleries.</div>
            ) : (
              shootProofgalleryArray.map(gallery => {
                return (
                  <a
                    key={gallery.id}
                    href={gallery.attributes.studio_gallery_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>
                      <h2>{gallery.attributes.title}</h2>
                      <h2>
                        {moment(gallery.attributes.start_time).format(
                          'MMM D, YYYY'
                        )}
                      </h2>
                      <img src={gallery.attributes.cover_image_url} />
                    </div>
                  </a>
                );
              })
            )}
          </div>
        )}
      </>
    );
  };

  const generatePicTime = () => {
    return (
      <>
        <h1>Pic-Time Galleries</h1>
        {!hasPicTimeIntegration ? (
          <div className={styles.GalleryButton}>
            <a href={absolutePath(`/studio/integrations`)}>
              <Button text={'Connect with Pic-Time'} />
            </a>
          </div>
        ) : (
          <div className={styles.ClientGallery}>
            {picTimeGalleryArray.length == 0 ? (
              <div>This client has no Pic-Time galleries.</div>
            ) : (
              picTimeGalleryArray.map(gallery => {
                return (
                  <a
                    key={gallery.id}
                    href={gallery.attributes.studio_gallery_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div>
                      <h2>{gallery.attributes.title}</h2>
                      <h2>
                        {moment(gallery.attributes.start_time).format(
                          'MMM D, YYYY'
                        )}
                      </h2>
                      <img src={gallery.attributes.cover_image_url} />
                    </div>
                  </a>
                );
              })
            )}
          </div>
        )}
      </>
    );
  };

  const generateBoth = () => {
    return (
      <>
        {generateShootProof()}
        {generatePicTime()}
      </>
    );
  };

  return (
    <div>
      {hasShootProofIntegration && hasPicTimeIntegration && generateBoth()}
      {!hasShootProofIntegration && !hasPicTimeIntegration && generateBoth()}
      {!hasShootProofIntegration && hasPicTimeIntegration && generatePicTime()}
      {hasShootProofIntegration &&
        !hasPicTimeIntegration &&
        generateShootProof()}
    </div>
  );
};

export default ClientGalleries;
