import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useDrag } from 'react-dnd';
import ItemTypes from '../ItemTypes';
import { Popover, Button, Checkbox, DateTimePicker } from '~ui';
import { updateActiveSlot } from '~actions/bookingCalendar';
import {
  listBookingAvailabilities,
  updateBookingAvailability,
  deleteBookingAvailability,
} from '~actions/bookingAvailabilities';
import { listCalendarEvents } from '~actions/calendarEvents';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { get } from '~utils';
import { TIME_FORMAT } from '~constants/datetime';
import { DATE_KEY_FORMAT } from '~constants/datetime';
import { TIME_SLOT_HEIGHT } from '~constants/calendar';
import styles from './Availability.module.scss';

const Availability = props => {
  const { availability, customStyles } = props;
  const all_day = availability.all_day;
  const { start_time, end_time } = availability;
  const calendarId = useSelector(state => state.bookingCalendar.id);
  const [newStartTime, setNewStartTime] = useState(start_time);
  const [newEndTime, setNewEndTime] = useState(end_time);
  const [allDay, setAllDay] = useState(all_day);
  const [multiDay, setMultiDay] = useState(availability.multi_day);
  const activeSlot = useSelector(state => state.bookingCalendar.activeSlot);
  const timeZone = useSelector(state => state.bookingCalendar.meta.timeZone);
  const dstDates = useSelector(state => state.calendar.dstDates);
  const [showPopover, setShowPopover] = useState(false);
  const monthName = moment(start_time).format('MMMM');
  const november = monthName == 'November';
  const march = monthName == 'March';

  useEffect(() => {
    if (activeSlot && activeSlot.id === availability.id) {
      setShowPopover(true);
    }
  }, [activeSlot]);

  const cx = classNames.bind(styles);
  const classes = cx('Availability', {
    'Availability--selected': availability.id === get(activeSlot, ['id']),
    'Availability--inactive':
      !availability.current && availability.calendar_title,
  });
  const formattedStartTime = moment(start_time).format(TIME_FORMAT);
  const formattedEndTime = moment(end_time).format(TIME_FORMAT);
  const dispatch = useDispatch();
  const activatorRef = useRef();
  const getBounds = () => activatorRef.current.getBoundingClientRect();
  const slotInterval = useSelector(state => state.bookingCalendar.slotInterval);
  const showAllCalendars = useSelector(
    state => state.bookingCalendar.showAllCalendars
  );
  const timeSlotHeightMultiplier = 60 / +slotInterval;

  const isDSTDate = () => {
    if (dstDates === undefined) return false;

    const momentDate = moment(start_time);
    const formattedDate = momentDate.format('YYYY-MM-DD');

    const transition = dstDates.find(t => t.date === formattedDate);
    if (transition) {
      return true;
    } else {
      return false;
    }
  };

  const subtractInteger =
    isDSTDate() && november ? 1 : isDSTDate() && march ? -1 : 0;

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.AVAILABILITY },
    canDrag: activeSlot && !all_day ? activeSlot.id === availability.id : false,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  const getStyles = () => {
    let newStyles = {
      ...customStyles,
      opacity: isDragging ? 0.5 : 1,
      cursor:
        activeSlot && !all_day && activeSlot.id === availability.id
          ? 'move'
          : 'pointer',
    };

    const timeDifference =
      moment(end_time).diff(moment(start_time), 'minutes') / 60;

    const duration = all_day || availability.multi_day ? 0.5 : timeDifference;

    let relativeDistanceFromMidnight =
      (60 / timeSlotHeightMultiplier +
        moment(start_time).diff(moment(start_time).startOf('day'), 'minutes')) /
      60;

    const startTimeIsDSTDate = isDSTDate();

    const isBetweenMidnightAndTwoAM = () => {
      const time = moment(start_time);
      const hour = time.hour();
      return hour >= 0 && hour < 2;
    };

    if (
      startTimeIsDSTDate &&
      (november || march) &&
      isBetweenMidnightAndTwoAM()
    ) {
      relativeDistanceFromMidnight += november ? 1 : -1;
    }

    const offsetStart =
      all_day || availability.multi_day
        ? 0
        : relativeDistanceFromMidnight - subtractInteger;

    if (all_day || availability.multi_day) {
      newStyles.height = `${duration * TIME_SLOT_HEIGHT}px`;
    } else {
      newStyles.height = `${
        duration * TIME_SLOT_HEIGHT * timeSlotHeightMultiplier
      }px`;
    }

    if (!all_day || !availability.multi_day) {
      newStyles.top = `${
        offsetStart * TIME_SLOT_HEIGHT * timeSlotHeightMultiplier
      }px`;
    }

    return newStyles;
  };

  const getAvailabilitiesForInterval = date => {
    let start = moment(date)
      .clone()
      .subtract(1, 'week')
      .format(DATE_KEY_FORMAT);
    let end = moment(date)
      .clone()
      .add(1, 'week')
      .add(2, 'days')
      .format(DATE_KEY_FORMAT);

    dispatch(
      listBookingAvailabilities({
        booking_availability: {
          booking_calendar_id: calendarId,
          start,
          end,
          sort: 'hour',
          all: showAllCalendars,
        },
      })
    );
    dispatch(listCalendarEvents({ start, end }));
  };

  const handleUpdateTimes = async () => {
    const requestParams = {
      booking_availability: {
        booking_calendar_id: calendarId,
        start_time: allDay ? moment(newStartTime).startOf('day') : newStartTime,
        end_time: allDay ? moment(newEndTime).endOf('day') : newEndTime,
        all_day: allDay,
      },
    };
    const updateRes = await dispatch(
      updateBookingAvailability(activeSlot.id, requestParams)
    );

    if (updateRes.res === 'error') {
      toast.error(
        updateRes.errors.response.data.error.messages[0],
        toastOptions
      );
    } else {
      getAvailabilitiesForInterval(moment(activeSlot.start_time));
      dispatch(updateActiveSlot(null));
    }
  };

  const handleDelete = async () => {
    const requestParams = {
      booking_availability: {
        booking_calendar_id: calendarId,
      },
    };
    await dispatch(deleteBookingAvailability(availability.id, requestParams));
    getAvailabilitiesForInterval(moment(activeSlot.start_time));
    dispatch(updateActiveSlot(null));
  };

  return (
    <>
      <div
        className={classes}
        style={getStyles()}
        ref={drag}
        onClick={e => {
          if (availability.current || !availability.calendar_title) {
            e.stopPropagation();
            dispatch(updateActiveSlot(availability));
          }
        }}
      >
        <div className={styles['Availability-slot']} ref={activatorRef}>
          {all_day ? (
            <span>All Day</span>
          ) : (
            <span>
              {!availability.current &&
                availability.calendar_title &&
                `${availability.calendar_title} `}
              {formattedStartTime} - {formattedEndTime}
            </span>
          )}
        </div>
      </div>
      {showPopover && (
        <Popover
          handleHide={() => {
            setShowPopover(false);
            dispatch(updateActiveSlot(null));
          }}
          activatorBounds={getBounds}
          controls={[
            <a onClick={() => handleDelete()} onKeyPress={() => handleDelete()}>
              <i tabIndex={0} className="fa fa-trash-alt" />
            </a>,
          ]}
        >
          <div className={styles['Availability-popoverInputs']}>
            <div className={styles['Availability-popoverInput']}>
              <label>Start Time</label>
              <DateTimePicker
                name="start_time"
                onChangeCallback={setNewStartTime}
                initialDateTime={start_time}
                disableDate={!multiDay}
              />
            </div>
            <div className={styles['Availability-popoverInput']}>
              <label>End Time</label>
              <DateTimePicker
                name="end_time"
                onChangeCallback={setNewEndTime}
                initialDateTime={end_time}
                disableDate={!multiDay}
              />
            </div>
          </div>

          <br />
          <div className={styles['Availability-checkboxes']}>
            <Checkbox
              labelText="All Day?"
              checked={allDay}
              onChangeCallback={setAllDay}
              name="allDay"
            />

            <Checkbox
              labelText="Multi Day?"
              checked={multiDay}
              onChangeCallback={setMultiDay}
              name="multiDay"
            />
          </div>
          <div className={styles['Availability-save']}>
            <Button text="Save" onClick={handleUpdateTimes} />
          </div>
        </Popover>
      )}
    </>
  );
};

Availability.propTypes = {
  availability: PropTypes.object.isRequired,
};

export default Availability;
