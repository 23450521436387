import React from 'react';
import PropTypes from 'prop-types';

export default class AttachmentRow extends React.PureComponent {
  static propTypes = {
    attachment: PropTypes.object.isRequired,
    deleteAttachment: PropTypes.func.isRequired,
  };

  render() {
    const { attachment, deleteAttachment } = this.props;
    const { id, item_id, item_name, item_type, _destroy } = attachment;

    const style = _destroy ? { display: 'none' } : { display: 'table-block' };

    return (
      <tr className="email-attachment" style={style}>
        <td>
          <span className="document-file-name" title={item_name}>
            {item_name}
          </span>

          <input
            value={item_id}
            name="email_template[email_attachments_attributes][][item_id]"
            type="hidden"
          />
          <input
            value={item_type}
            name="email_template[email_attachments_attributes][][item_type]"
            type="hidden"
          />
          <input
            value={id}
            name="email_template[email_attachments_attributes][][id]"
            type="hidden"
          />
          <input
            type="hidden"
            value={_destroy || 0}
            name="email_template[email_attachments_attributes][][_destroy]"
            className="attachment-destroy-field"
          />
        </td>
        <td className="email-attachment-controls">
          <span
            className="delete-attachment as-link teal"
            onClick={() => deleteAttachment(item_id)}
          >
            X
          </span>
        </td>
      </tr>
    );
  }
}
