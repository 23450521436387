import React, { useState, useEffect } from 'react';
import { Modal, Button, TextInput, Dropdown, TextArea } from '~ui';
import axios from 'axios';
import { toast } from 'react-toastify';
import { emailValidation } from '~constants/validation';

const ContactInfoEditModal = ({
  handleHide,
  contactInformation,
  setContactInformation,
  id,
  countryOptions,
}) => {
  const [tempContactInformation, setTempContactInformation] = useState(
    contactInformation
  );
  const [stateOptions, setStateOptions] = useState([]);

  useEffect(() => {
    if (tempContactInformation.country) {
      handleCountryDropdownChange(tempContactInformation.country);
    }
  }, []);

  const handleCountryDropdownChange = (alpha2, disableState) => {
    if (disableState == 'Choose a State/Province') {
      setTempContactInformation({
        ...tempContactInformation,
        country: alpha2,
        state: 'Choose a State/Province',
      });
    } else {
      setTempContactInformation({ ...tempContactInformation, country: alpha2 });
    }
    axios
      .get(`/api/states/${alpha2}`)
      .then(res => {
        let options = res.data.map(state => ({
          label: state[1],
          value: state[0],
        }));
        options = [{ label: 'Choose a State/Province', value: null }].concat(
          options
        );
        setStateOptions(options);
      })
      .catch(error => {
        const errorMessage = `Error fetching states for ${alpha2}: ${error.response.data.error.messages}`;
        toast.error(errorMessage);
        Rollbar.error(`${errorMessage}: ${error}`);
      });
  };

  const handleSubmit = async () => {
    const clientParams = {};

    const address = tempContactInformation.address
      ? tempContactInformation.address
      : '';
    const city =
      tempContactInformation.city && tempContactInformation.state
        ? `${tempContactInformation.city},`
        : tempContactInformation.city
        ? tempContactInformation.city
        : '';
    const state = tempContactInformation.state
      ? tempContactInformation.state
      : '';
    const zip = tempContactInformation.zip ? tempContactInformation.zip : '';

    const fullAddress = `${address} ${city} ${state} ${zip}`;

    if (contactInformation.email !== tempContactInformation.email) {
      clientParams.email = tempContactInformation.email.toLowerCase();
    }
    if (
      contactInformation.alternateEmail !==
      tempContactInformation.alternateEmail
    ) {
      clientParams.alternate_email = tempContactInformation.alternateEmail.toLowerCase();
    }
    if (contactInformation.phone !== tempContactInformation.phone) {
      clientParams.phone = tempContactInformation.phone;
    }
    if (
      contactInformation.secondaryPhone !==
      tempContactInformation.secondaryPhone
    ) {
      clientParams.secondary_phone = tempContactInformation.secondaryPhone;
    }
    if (contactInformation.companyName !== tempContactInformation.companyName) {
      clientParams.company_name = tempContactInformation.companyName;
    }
    if (contactInformation.facebook !== tempContactInformation.facebook) {
      clientParams.facebook = tempContactInformation.facebook;
    }
    if (contactInformation.instagram !== tempContactInformation.instagram) {
      clientParams.instagram = tempContactInformation.instagram;
    }
    if (contactInformation.notes !== tempContactInformation.notes) {
      clientParams.notes = tempContactInformation.notes;
    }
    if (contactInformation.city !== tempContactInformation.city) {
      clientParams.city = tempContactInformation.city;
      clientParams.fullAddress = fullAddress;
    }
    if (contactInformation.state !== tempContactInformation.state) {
      clientParams.state = tempContactInformation.state;
      clientParams.fullAddress = fullAddress;
    }
    if (contactInformation.zip !== tempContactInformation.zip) {
      clientParams.zip = tempContactInformation.zip;
      clientParams.fullAddress = fullAddress;
    }
    if (contactInformation.country !== tempContactInformation.country) {
      clientParams.country = tempContactInformation.country;
      clientParams.fullAddress = fullAddress;
    }
    if (contactInformation.phone !== tempContactInformation.phone) {
      clientParams.phone = tempContactInformation.phone;
    }
    if (contactInformation.address !== tempContactInformation.address) {
      clientParams.address = tempContactInformation.address;
      clientParams.fullAddress = fullAddress;
    }
    if (contactInformation.firstName !== tempContactInformation.firstName) {
      clientParams.first_name = tempContactInformation.firstName;
    }
    if (contactInformation.lastName !== tempContactInformation.lastName) {
      clientParams.last_name = tempContactInformation.lastName;
    }
    if (contactInformation.birthday !== tempContactInformation.birthday) {
      clientParams.birthday = moment(tempContactInformation.birthday).format(
        'DD-MM-YYYY'
      );
    }

    const params = {
      client: clientParams,
    };

    if (Object.keys(params.client).length !== 0) {
      try {
        await axios.put(`/api/clients/${id}`, params);
        setContactInformation({
          ...tempContactInformation,
          fullAddress: fullAddress,
          ...(params.client.alternate_email && {
            alternateEmail: tempContactInformation.alternateEmail.toLowerCase(),
          }),
          ...(params.client.email && {
            email: tempContactInformation.email.toLowerCase(),
          }),
        });
        toast.success('Updated!');
      } catch (error) {
        if (error.response.data.errors[0]) {
          toast.error(error.response.data.errors[0]);
        } else {
          toast.error('Error updating!');
        }
      }
    }
  };

  return (
    <Modal
      title="Edit Contact Information"
      handleHide={handleHide}
      countryOptions={countryOptions}
    >
      <div className="d-f">
        <div className="fg-1 mr-12">
          <div className="mb-8 mt-8">First Name</div>
          <TextInput
            name="firstNameInput"
            onChangeCallback={val =>
              setTempContactInformation({
                ...tempContactInformation,
                firstName: val,
              })
            }
            initialValue={tempContactInformation.firstName || ''}
          />
        </div>
        <div className="fg-1">
          <div className="mb-8 mt-8">Last Name</div>
          <TextInput
            name="lastNameInput"
            onChangeCallback={val =>
              setTempContactInformation({
                ...tempContactInformation,
                lastName: val,
              })
            }
            initialValue={tempContactInformation.lastName || ''}
          />
        </div>
      </div>
      <div className="d-f  mt-8">
        <div className="fg-1 mr-12">
          <div className="mb-8">Phone Number</div>
          <TextInput
            name="companyProfileInput"
            onChangeCallback={val =>
              setTempContactInformation({
                ...tempContactInformation,
                phone: val,
              })
            }
            initialValue={tempContactInformation.phone || ''}
          />
        </div>
        <div className="fg-1">
          <div className="mb-8">Secondary Phone</div>
          <TextInput
            name="companyProfileInput"
            onChangeCallback={val =>
              setTempContactInformation({
                ...tempContactInformation,
                secondaryPhone: val,
              })
            }
            initialValue={tempContactInformation.secondaryPhone || ''}
          />
        </div>
      </div>
      <div className="d-f">
        <div className="fg-1 mr-12">
          <div className="mb-8 mt-8">Email</div>
          <TextInput
            name="emailInput"
            onChangeCallback={val =>
              setTempContactInformation({
                ...tempContactInformation,
                email: val,
              })
            }
            validation={emailValidation}
            initialValue={tempContactInformation.email || ''}
          />
        </div>
        <div className="fg-1">
          <div className="mb-8 mt-8">Alternate Email</div>
          <TextInput
            name="secondaryEmailInput"
            onChangeCallback={val =>
              setTempContactInformation({
                ...tempContactInformation,
                alternateEmail: val,
              })
            }
            validation={emailValidation}
            initialValue={tempContactInformation.alternateEmail || ''}
          />
        </div>
      </div>
      <div className="d-f  mt-8">
        <div className="fg-1 mr-12">
          <div className="mb-8">Company Name</div>
          <TextInput
            name="companyProfileInput"
            onChangeCallback={val =>
              setTempContactInformation({
                ...tempContactInformation,
                companyName: val,
              })
            }
            initialValue={tempContactInformation.companyName || ''}
          />
        </div>
        <div className="fg-1">
          <div className="mb-8">Birthday</div>
          <TextInput
            name="birthday"
            onChangeCallback={val =>
              setTempContactInformation({
                ...tempContactInformation,
                birthday: moment(val).format('MM/DD/YYYY'),
              })
            }
            initialValue={
              tempContactInformation.birthday
                ? moment(tempContactInformation.birthday).format('YYYY-MM-DD')
                : ''
            }
            type="date"
          />
        </div>
      </div>
      <div className="d-f">
        <div className="fg-1 mr-12">
          <div className="mb-8 mt-8">Facebook</div>
          <TextInput
            name="emailInput"
            onChangeCallback={val =>
              setTempContactInformation({
                ...tempContactInformation,
                facebook: val,
              })
            }
            initialValue={tempContactInformation.facebook || ''}
          />
        </div>
        <div className="fg-1">
          <div className="mb-8 mt-8">Instagram</div>
          <TextInput
            name="secondaryEmailInput"
            onChangeCallback={val =>
              setTempContactInformation({
                ...tempContactInformation,
                instagram: val,
              })
            }
            initialValue={tempContactInformation.instagram || ''}
          />
        </div>
      </div>
      <div className="mb-8 mt-8">Country</div>
      <Dropdown
        name="country"
        options={countryOptions}
        initialValue={tempContactInformation.country || ''}
        onChangeCallback={handleCountryDropdownChange}
      />
      <div className="d-f">
        <div className="fg-1 mr-12">
          <div className="mb-8 mt-8">Address</div>
          <TextInput
            name="addressInput"
            onChangeCallback={val =>
              setTempContactInformation({
                ...tempContactInformation,
                address: val,
              })
            }
            initialValue={tempContactInformation.address || ''}
          />
        </div>
        <div className="fg-1">
          <div className="mb-8 mt-8">City</div>
          <TextInput
            name="cityInput"
            onChangeCallback={val =>
              setTempContactInformation({
                ...tempContactInformation,
                city: val,
              })
            }
            initialValue={tempContactInformation.city || ''}
          />
        </div>
      </div>
      <div className="d-f">
        <div className="fg-1 mr-12">
          <div className="mb-8 mt-8">State</div>
          <Dropdown
            name="state_province"
            options={stateOptions}
            initialValue={tempContactInformation.state || ''}
            onChangeCallback={val =>
              setTempContactInformation({
                ...tempContactInformation,
                state: val,
              })
            }
            disabled={stateOptions.length === 0}
          />
        </div>
        <div className="fg-1">
          <div className="mb-8 mt-8">Zip Code</div>
          <TextInput
            name="zipCodeInput"
            onChangeCallback={val =>
              setTempContactInformation({
                ...tempContactInformation,
                zip: val,
              })
            }
            initialValue={tempContactInformation.zip || ''}
          />
        </div>
      </div>

      <div className="mb-8 mt-8">Notes</div>
      <TextArea
        labelText=""
        name="textArea"
        initialValue={tempContactInformation.notes}
        onChangeCallback={val =>
          setTempContactInformation({
            ...tempContactInformation,
            notes: val,
          })
        }
      />
      <div className="d-fr mt-8">
        <Button className="mr-8" text="Cancel" onClick={handleHide} danger />
        <Button
          text="Update"
          onClick={() => {
            handleSubmit();
            handleHide();
          }}
        />
      </div>
    </Modal>
  );
};

export default ContactInfoEditModal;
