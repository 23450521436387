import React, { useState } from 'react';
import { Button } from '~ui';
import { toCurrencyAmount } from '~utils';

import Tax from './Tax';
import Discount from './Discount';
import styles from './InvoiceTotals.module.scss';

const InvoiceTotals = props => {
  const {
    invoice,
    handleTaxDiscountSwap,
    handleUpdateTax,
    handleDeleteTax,
    handleUpdateDiscount,
    handleDeleteDiscount,
    showAmountPaid,
    showBalanceDue,
    studioTax,
  } = props;
  const { subtotal, due } = invoice.totals;
  const {
    paid_amount,
    tax_enabled,
    has_discount,
    tax_before_discount,
    paid_in_full,
    total,
    tip_amount,
    coupon_code,
  } = invoice;
  const canEditInvoice = !paid_in_full;
  const [editingTax, setEditingTax] = useState(false);
  const [editingDiscount, setEditingDiscount] = useState(false);
  const [taxEnabled, setTaxEnabled] = useState(tax_enabled);
  const [discountEnabled, setDiscountEnabled] = useState(has_discount);

  const renderTaxAndDiscount = () => {
    let lines = [
      () => (
        <>
          {canEditInvoice && !discountEnabled && !(coupon_code) && (
            <div className={styles['InvoiceTotals-line']}>
              <Button
                text="Add Discount"
                icon="plus"
                category="outlined"
                small
                onClick={() => {
                  setEditingDiscount(true);
                  setDiscountEnabled(true);
                }}
              />
            </div>
          )}
          {discountEnabled && (
            <Discount
              isEditing={editingDiscount}
              setDiscountEnabled={setDiscountEnabled}
              setEditingDiscount={setEditingDiscount}
              handleUpdateDiscount={handleUpdateDiscount}
              handleDeleteDiscount={handleDeleteDiscount}
              invoice={invoice}
            />
          )}
        </>
      ),
      () => (
        <>
          {canEditInvoice && !taxEnabled && (
            <div className={styles['InvoiceTotals-line']}>
              <Button
                text="Add Tax"
                category="outlined"
                icon="plus"
                small
                onClick={() => {
                  setEditingTax(true);
                  setTaxEnabled(true);
                }}
              />
            </div>
          )}
          {taxEnabled && (
            <Tax
              isEditing={editingTax}
              setTaxEnabled={setTaxEnabled}
              setEditingTax={setEditingTax}
              handleUpdateTax={handleUpdateTax}
              handleDeleteTax={handleDeleteTax}
              invoice={invoice}
              studioTax={studioTax}
            />
          )}
        </>
      ),
    ];

    if (tax_before_discount) {
      return lines.reverse();
    }

    return lines;
  };
  return (
    <div className={styles.InvoiceTotalsContainer}>
      <div className={styles.InvoiceTotals}>
        <div className={styles['InvoiceTotals-line']}>
          <span className={styles['InvoiceTotals-lineLabel']}>Subtotal</span>
          <span className={styles['InvoiceTotals-lineValue']}>
            {toCurrencyAmount(subtotal)}
          </span>
          <span className={styles['InvoiceTotals-lineBuffer']} />
        </div>
        {coupon_code && (
          <div className={styles['InvoiceTotals-line']}>
            <span className={styles['InvoiceTotals-lineLabel']}>Applied Coupon Code</span>
            <span className={styles['InvoiceTotals-lineValue']} style={{ textTransform: 'uppercase' }}>
              {coupon_code.code}
            </span>
            <span className={styles['InvoiceTotals-lineBuffer']} />
          </div>
        )}
        <div className={styles['InvoiceTotals-taxAndDiscount']}>
          {canEditInvoice && (
            <div className={styles['InvoiceTotals-toggle']}>
              <Button
                ariaLabel="Swap Tax and Discount"
                icon="random"
                category="icon"
                onClick={() => handleTaxDiscountSwap(tax_before_discount)}
              />
            </div>
          )}
          {renderTaxAndDiscount().map((Line, index) => (
            <Line key={index} />
          ))}
        </div>
        <div className={styles['InvoiceTotals-line']}>
          <span className={styles['InvoiceTotals-lineLabel']}>Total</span>
          <span className={styles['InvoiceTotals-lineValue']}>
            {toCurrencyAmount(total)}
          </span>
          <span className={styles['InvoiceTotals-lineBuffer']} />
        </div>
        {tip_amount && (
          <div className={styles['InvoiceTotals-line']}>
            <span className={styles['InvoiceTotals-lineLabel']}>Tip</span>
            <span className={styles['InvoiceTotals-lineValue']}>
              {toCurrencyAmount(tip_amount)}
            </span>
            <span className={styles['InvoiceTotals-lineBuffer']} />
          </div>
        )}

        {showAmountPaid && (
          <div className={styles['InvoiceTotals-line']}>
            <span className={styles['InvoiceTotals-lineLabel']}>
              Amount Paid
            </span>
            <span className={styles['InvoiceTotals-lineValue']}>
              {toCurrencyAmount(paid_amount)}
            </span>
            <span className={styles['InvoiceTotals-lineBuffer']} />
          </div>
        )}

        {showBalanceDue && (
          <div className={styles['InvoiceTotals-line']}>
            <span className={styles['InvoiceTotals-lineLabel']}>
              Balance Due
            </span>
            <span className={styles['InvoiceTotals-lineValue']}>
              {toCurrencyAmount(due)}
            </span>
            <span className={styles['InvoiceTotals-lineBuffer']} />
          </div>
        )}
      </div>
    </div>
  );
};

export default InvoiceTotals;
