import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { FULL_DATE_FORMAT } from '~constants/datetime';
import { useClickedOutside } from '~hooks';
import { Icon, DatePicker } from '~ui';
import { uniqueId } from '~utils';
import styles from './DateRangeInput.module.scss';

const DateRangeInput = props => {
  const {
    disabled,
    labelText,
    name,
    onChangeCallback,
    required,
    initialStartDate,
    initialEndDate,
    className,
    hideErrors,
  } = props;

  const [startDate, setStartDate] = useState(
    initialStartDate ? moment(initialStartDate) : null
  );
  const [endDate, setEndDate] = useState(
    initialEndDate ? moment(initialEndDate) : null
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [newDate, setNewDate] = useState(false);
  const popoverRef = useRef(null);
  const id = uniqueId('DateRangeInput_');
  const hasClassName = !!className;
  const cx = classNames.bind(styles);
  const classes = cx('DateRangeInput', {
    'DateRangeInput--disabled': disabled,
    'DateRangeInput--invalid': !isValid,
    [className]: hasClassName,
  });

  useClickedOutside(popoverRef, () => setIsOpen(false));

  useEffect(() => {
    updateValidation();

    if (moment(initialStartDate).diff(startDate) !== 0) {
      setStartDate(initialStartDate ? moment(initialStartDate) : null);
    }

    if (moment(initialEndDate).diff(endDate) !== 0) {
      setEndDate(initialEndDate ? moment(initialEndDate) : null);
    }
  }, [startDate, endDate, initialStartDate, initialEndDate]);

  const clearDates = () => {
    setStartDate();
    setEndDate();
    onChangeCallback(null, null);
  };

  const updateValidation = () => {
    if (startDate && endDate) {
      setIsValid(startDate.diff(endDate) <= 0);
    }

    return;
  };

  const displayValue = () => {
    if (startDate && endDate) {
      return `${startDate.format(FULL_DATE_FORMAT)} - ${endDate.format(
        FULL_DATE_FORMAT
      )}`;
    }

    if (startDate && !endDate) {
      return `After ${startDate.format(FULL_DATE_FORMAT)}`;
    }

    if (!startDate && endDate && newDate) {
      return `Before ${endDate.format(FULL_DATE_FORMAT)}`;
    }

    return '';
  };

  return (
    <div className={styles['DateRangeInputContainer']}>
      <div className={classes}>
        {labelText && (
          <label htmlFor={name}>
            {labelText}
            {required && <span>*</span>}
          </label>
        )}
        <input
          type="text"
          name={name}
          value={displayValue()}
          required={required}
          autoComplete="off"
          onFocus={() => setIsOpen(true)}
          onChange={() => {}}
          disabled={disabled}
          aria-haspopup="true"
          aria-expanded={isOpen}
          placeholder="Date Range"
        />
        <Icon
          name="times-circle"
          className={styles['DateRangeInput-clear']}
          onClickCallback={() => clearDates()}
        />
        {!isValid && !hideErrors && <span>Invalid Date Range</span>}
      </div>
      {isOpen && (
        <div
          className={styles['DateRangeInput-popover']}
          ref={popoverRef}
          aria-labelledby={id}
        >
          <DatePicker
            initialDate={startDate}
            onChangeCallback={newDate => {
              setStartDate(newDate);
              if (isValid) {
                onChangeCallback(newDate, endDate);
              }
            }}
          />
          <div className={styles['DateRangeInput-buffer']} />
          <DatePicker
            initialDate={endDate}
            nextMonth={true}
            onChangeCallback={newDate => {
              setEndDate(newDate);
              setNewDate(true);
              if (isValid) {
                onChangeCallback(startDate, newDate);
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

DateRangeInput.propTypes = {
  disabled: PropTypes.bool,
  labelText: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChangeCallback: function(props, propName) {
    const fn = props[propName];
    if (typeof fn !== 'function' && fn.length !== 2) {
      return new Error(`${propName} must be a function with 2 arguments!`);
    }
  },
  required: PropTypes.bool,
  initialStartDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  initialEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

DateRangeInput.defaultProps = {
  disabled: false,
  onChangeCallback: (_0, _1) => {},
  required: false,
};

export default DateRangeInput;
