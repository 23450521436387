import React from 'react';
import { useState } from 'react';
import axios from 'axios';
import styles from './ClientAvatarModal.module.scss';
import { Modal, Button, DocumentUploader } from '~ui';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';

const ClientAvatarModal = props => {
  const { handleHide, client_id, setClientAvatar } = props;
  const [avatarData, setAvatarData] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    setLoading(true);
    const res = await axios.put(`/api/clients/${client_id}`, {
      client: { ...avatarData },
    });

    try {
      toast.success('Saved!', toastOptions);
      setClientAvatar(res.data.data.attributes.avatar);
      setLoading(false);
      handleHide(false);
    } catch (error) {
      toast.error('There was an error saving.', toastOptions);
      setLoading(false);
      handleHide(false);
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        handleHide={() => {
          handleHide(false);
        }}
        title={'Add Client Avatar'}
      >
        {avatarData && (
          <div className={styles.PreviewImage}>
            <img
              src={avatarData.avatar}
              alt="Banner image"
              width="auto"
              height={180}
            />
          </div>
        )}
        <DocumentUploader
          readAs="readAsDataURL"
          onChange={fileData => {
            setAvatarData({
              avatar_file_name: fileData.fileName,
              avatar: fileData.dataString,
            });
          }}
          onError={error => {
            toast.error(error.message, toastOptions);
          }}
        />
        <div className={styles['ClientAvatarModal-footer']}>
          <div className={styles['ClientAvatarModal-footer-buttonGroup']}>
            <Button
              danger
              onClick={() => {
                handleHide(false);
              }}
              text="Cancel"
            />
            {!loading && (
              <Button onClick={submit} text={'Save'} loading={loading} />
            )}
            {loading && (
              <Button
                onClick={submit}
                text={'Saving'}
                loading={loading}
                disabled
              />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ClientAvatarModal;
