import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import axios from 'axios';
import styles from './PaymentOptions.module.scss';
import { Button } from '~ui';
import { toCurrencyAmount } from '~utils';
import SquareACH from '../PaymentOptions/SquareACH';

const SquarePaymentMethods = props => {
  const {
    tipAmount,
    loadingCallback,
    isBooking,
    amountDue,
    invoiceUuid,
    client,
    currency,
    payments,
    achEnabled,
    giftCardEnabled,
    afterpayEnabled,
    cashAppEnabled,
    applePayEnabled,
    googlePayEnabled,
    hasAfterpayAttributes,
    hasGoogleAndApplePayAttributes,
    studio,
    user,
    squareRedirectUrl,
    availabilityId,
    bookingCalendarId,
  } = props;
  const { attributes: clientAttributes } = client;

  const [loading, setLoading] = useState(false);
  const [card, setCard] = useState(null);
  const [giftCard, setGiftCard] = useState(null);
  const [cardInputs, _setCardInputs] = useState(null);
  const [afterpay, setAfterpay] = useState(null);
  const [applePay, setApplePay] = useState(null);
  const [activeTab, setActiveTab] = useState('card');

  const cardInputsRef = useRef(cardInputs);
  const setCardInputs = data => {
    cardInputsRef.current = data;
    _setCardInputs(data);
  };

  const initCard = async () => {
    const cardResponse = await payments.card();
    await cardResponse.attach('#card-container');

    cardResponse.addEventListener('focusClassAdded', async cardInputEvent => {
      setCardInputs({
        ...cardInputsRef.current,
        [cardInputEvent.detail.field]:
          cardInputEvent.detail.currentState.isCompletelyValid,
      });
    });

    cardResponse.addEventListener('focusClassRemoved', async cardInputEvent => {
      setCardInputs({
        ...cardInputsRef.current,
        [cardInputEvent.detail.field]:
          cardInputEvent.detail.currentState.isCompletelyValid,
      });
    });

    setCard(cardResponse);
  };

  const initGiftCard = async () => {
    const gcResponse = await payments.giftCard();
    await gcResponse.attach('#gift-card-container');

    setGiftCard(gcResponse);
  };

  const createRequest = () => {
    let paymentsRequest;
    try {
      paymentsRequest = payments.paymentRequest({
        countryCode: studio.country,
        currencyCode: currency,
        total: {
          amount: amountDue,
          label: 'Total',
        },
        requestShippingContact: false,
        pickupContact: {
          addressLines: [studio.address],
          city: studio.city,
          countryCode: studio.country,
          email: user.email,
          familyName: user.first_name,
          givenName: user.last_name,
          phone: studio.phone,
          postalCode: studio.postal,
          state: studio.state,
        },
      });
    } catch (error) {
      console.error(error);
      toast.error("We've encountered an issue with Square");
      Rollbar.error('Error creating paymentRequest', error);
    }
    return paymentsRequest;
  };

  const initAfterpay = async () => {
    const paymentRequest = createRequest();
    const afterpayResponse = await payments.afterpayClearpay(paymentRequest);
    await afterpayResponse.attach('#afterpay-button');

    setAfterpay(afterpayResponse);
  };

  useEffect(() => {
    initCard();

    if (googlePayEnabled && hasGoogleAndApplePayAttributes) {
      initGooglePay();
    }

    if (cashAppEnabled && studio.country == 'US') {
      initCashApp();
    }

    if (
      afterpayEnabled &&
      hasAfterpayAttributes &&
      (studio.country == 'US' || studio.country == 'AU')
    ) {
      initAfterpay();
    }

    if (applePayEnabled && hasGoogleAndApplePayAttributes) {
      initApplePay();
    }
  }, []);

  useEffect(() => {
    addApplePayOnClick();
  }, [applePay]);

  const addApplePayOnClick = async () => {
    if (applePay !== null) {
      const applePayButton = document.getElementById('apple-pay-button');
      applePayButton.addEventListener('click', async function (event) {
        await createApplePayPayment(event, applePay);
      });
    }
  };

  const initApplePay = async () => {
    try {
      const paymentRequest = createRequest();
      const applePay = await payments.applePay(paymentRequest);
      setApplePay(applePay);
    } catch (error) {
      if (error.name === 'PaymentMethodUnsupportedError') {
        // Quietly fail if applePay is unsupported by the device.
      } else {
        Rollbar.error('Error initializing Apple Pay', error);
        console.error(error);
      }
    }
  };

  const initCashApp = async () => {
    const paymentsRequest = createRequest();
    const cashAppResponse = await payments.cashAppPay(paymentsRequest, {
      redirectURL: squareRedirectUrl,
    });
    cashAppResponse.addEventListener('ontokenization', event => {
      const { tokenResult } = event.detail;
      const tokenStatus = tokenResult.status;
      if (tokenStatus === 'OK') {
        const token = tokenResult.token;
        loadingCallback({ loading: true, reload: false });
        createPaymentRequest(token, null);
      }
    });

    await cashAppResponse.attach('#cash-app-pay', {
      size: 'medium',
      width: 'full',
      shape: 'semiround',
    });
  };

  const initGooglePay = async () => {
    const paymentRequest = createRequest();

    const googlePay = await payments.googlePay(paymentRequest);

    await googlePay.attach('#google-pay-button', {
      buttonSizeMode: 'fill',
      buttonType: 'long',
    });

    const googlePayButtonTarget = document.getElementById('google-pay-button');

    googlePayButtonTarget.onclick = async () => {
      const tokenResult = await googlePay.tokenize();
      const tokenStatus = tokenResult.status;

      if (tokenStatus === 'OK') {
        const token = tokenResult.token;
        loadingCallback({ loading: true, reload: false });
        createPaymentRequest(token, null);
      }
    };
  };

  const tokenize = async paymentMethod => {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Square Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      }

      Rollbar.error(errorMessage, tokenResult.status);
      toast.error(
        'Square Tokenization Failed, please contact support',
        toastOptions
      );
    }
  };

  const createPaymentRequest = async (
    token,
    buyerVerificationToken,
    isGiftCard = false
  ) => {
    await axios
      .post(`/customer/invoices/${invoiceUuid}/charge_square`, {
        nonce: token,
        token: buyerVerificationToken,
        tip_amount: tipAmount,
        is_gift_card: isGiftCard,
        isBooking: isBooking,
        availability_id: availabilityId,
        booking_calendar_id: bookingCalendarId,
        client_id: client.id,
      })
      .then(res => {
        loadingCallback({
          loading: false,
          success: true,
          reload: !isBooking,
          bookingLogicCompleted: isBooking,
          calendarEvent: isBooking ? res.data : false,
        });
        toast.success('Successfully created your payment!', toastOptions);
      })
      .catch(error => {
        loadingCallback({ loading: false, reload: false });
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.messages
        ) {
          error.response.data.error.messages.forEach(message =>
            toast.error(message, toastOptions)
          );
        } else {
          toast.error('An unexpected error occurred:', error);
        }
        setLoading(false);
      });
  };

  const createVerificationDetails = () => ({
    amount: amountDue,
    currencyCode: currency,
    intent: 'CHARGE',
    billingContact: {
      familyName: clientAttributes.last_name || undefined,
      givenName: clientAttributes.first_name || undefined,
      email: clientAttributes.email || undefined,
      country: clientAttributes.country || undefined,
      city: clientAttributes.city || undefined,
      addressLines: [clientAttributes.address || ''],
      postalCode: clientAttributes.zip || undefined,
      phone: clientAttributes.phone || undefined,
    },
  });

  const verifyBuyer = async token => {
    let verificationResults;
    try {
      verificationResults = await payments.verifyBuyer(
        token,
        createVerificationDetails()
      );
    } catch (error) {
      Rollbar.error(
        `Square Verification failed for invoice ${invoiceUuid}`,
        error
      );
      toast.error(
        "We've encountered and error with Square Verification, please contact support",
        toastOptions
      );
    }

    return verificationResults.token;
  };

  const createCardPayment = async card => {
    if (!cardInputs || !Object.values(cardInputs).every(val => !!val)) {
      toast.error(
        'One or more fields are invalid or incomplete, please ensure your card information is correct and try again',
        toastOptions
      );
      return;
    }

    setLoading(true);
    loadingCallback({ loading: true, reload: false });
    const token = await tokenize(card);
    const verificationToken = await verifyBuyer(token);
    await createPaymentRequest(token, verificationToken);
  };

  const createGiftCardPayment = async giftCard => {
    setLoading(true);
    loadingCallback({ loading: true, reload: false });
    const token = await tokenize(giftCard);
    await createPaymentRequest(token, null, true);
  };

  const createAfterpayPayment = async event => {
    event.preventDefault();
    try {
      const result = await afterpay.tokenize();
      if (result.status === 'OK') {
        await createPaymentRequest(result.token, null);
      }
    } catch (e) {
      console.error(e);
      toast.error("We've encountered an error with Afterpay");
      Rollbar.error('Error tokenizing afterpay response', e);
    }
  };

  const createApplePayPayment = async (event, applePay) => {
    event.preventDefault();
    try {
      const result = await applePay.tokenize();
      if (result.status === 'OK') {
        await createPaymentRequest(result.token, null);
      }
    } catch (e) {
      console.error(e);
      toast.error("We've encountered an error with Apple Pay");
      Rollbar.error('Error tokenizing Apple Pay response', e);
    }
  };

  return (
    <div>
      <form id="payment-form">
        <>
          {afterpayEnabled &&
            hasAfterpayAttributes &&
            (studio.country == 'US' || studio.country == 'AU') && (
              <div
                id="afterpay-button"
                style={{ marginBottom: '15px' }}
                onClick={createAfterpayPayment}
              ></div>
            )}
        </>
        {applePayEnabled && applePay && hasGoogleAndApplePayAttributes && (
          <div id="apple-pay-button"></div>
        )}
        {cashAppEnabled && hasAfterpayAttributes && studio.country === 'US' && (
          <>
            <div
              id="cash-app-pay"
              style={{
                marginBottom: '15px',
              }}
            ></div>
            <div id="cash-app-card-container"></div>
          </>
        )}
        {googlePayEnabled && hasGoogleAndApplePayAttributes && (
          <>
            <div
              id="google-pay-button"
              style={{
                marginBottom: '15px',
              }}
            ></div>
          </>
        )}
        <div className={styles['Square-tabs']}>
          <div
            className={
              activeTab === 'card' ? styles['Square-tabs-activeTab'] : ''
            }
            onClick={() => {
              if (activeTab !== 'card') {
                initCard();
              }
              setActiveTab('card');
            }}
          >
            <p>Credit/Debit</p>
          </div>
          {giftCardEnabled && (
            <div
              className={
                activeTab === 'gift_card' ? styles['Square-tabs-activeTab'] : ''
              }
              onClick={() => {
                if (activeTab !== 'gift_card') {
                  initGiftCard();
                }
                setActiveTab('gift_card');
              }}
            >
              <p>Gift Card</p>
            </div>
          )}
          {achEnabled && (
            <div
              className={
                activeTab === 'ach' ? styles['Square-tabs-activeTab'] : ''
              }
              onClick={() => {
                setActiveTab('ach');
              }}
            >
              <p>ACH</p>
            </div>
          )}
        </div>
        {activeTab === 'gift_card' && (
          <>
            <div id="gift-card-container"></div>
            <div className={styles['Square-button']}>
              {giftCard && (
                <Button
                  disabled={loading}
                  text={`Pay ${toCurrencyAmount(amountDue)} with gift card`}
                  onClick={() => createGiftCardPayment(giftCard)}
                />
              )}
            </div>
          </>
        )}
        {activeTab === 'ach' && (
          <SquareACH
            invoiceUUID={invoiceUuid}
            {...{
              tipAmount,
              loadingCallback,
              isBooking,
              amountDue,
              payments,
              currency,
            }}
          />
        )}
        {activeTab === 'card' && (
          <>
            <div id="card-container"></div>
            <div className={styles['Square-button']}>
              {card && (
                <Button
                  disabled={loading}
                  text={`Pay ${toCurrencyAmount(amountDue)}`}
                  onClick={() => createCardPayment(card)}
                />
              )}
            </div>
          </>
        )}
      </form>
      <div id="payment-status-container"></div>
    </div>
  );
};

export default SquarePaymentMethods;
