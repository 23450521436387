import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CustomerInvoice from '~components/CustomerInvoice';
import { actionsLoadingSelector, actionsErroredSelector } from '~selectors';
import {
  generateInvoice,
  checkClientPermission,
  updateBookingLogicCompleted,
  updateCalendarEvent,
} from '~actions/customerBooking';
import styles from './InvoiceRoute.module.scss';
import withBackHandler from '../withBackHandler';
import { CUSTOMER_BOOKING } from '~constants/actionTypes';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';

const InvoiceRoute = ({ uuid, BOOKING_PATHS, hasActiveCode }) => {
  const dispatch = useDispatch();
  const studio = useSelector(state => state.customerBooking.studio);
  const couponAllowed = useSelector(
    state => state.customerBooking.bookingCalendar.coupons_allowed
  );
  const user = useSelector(state => state.customerBooking.user);
  const meta = useSelector(state => state.customerBooking.meta);
  const loading = useSelector(state =>
    actionsLoadingSelector(state, ['CUSTOMER_BOOKING_INVOICE_GENERATE'])
  );
  const invoice = useSelector(state => state.customerBooking.invoice);
  const token = useSelector(state => state.customerBooking.token);
  const history = useHistory();
  const { id: client_id } = useSelector(
    state => state.customerBooking.clientInfo
  );

  const availability = useSelector(
    state => state.customerBooking.selectedAvailability
  );
  const bookingCalendar = useSelector(
    state => state.customerBooking.bookingCalendar
  );

  const cameFromInvoiceRoute = window.sessionStorage.getItem(
    'cameFromInvoiceRoute'
  );

  const cameFromConfirmRoute = window.sessionStorage.getItem(
    'cameFromConfirmRoute'
  );

  useEffect(() => {
    if (client_id && !cameFromInvoiceRoute) {
      dispatch(checkClientPermission(client_id));
    }
  }, [client_id]);

  const errors = useSelector(state =>
    actionsErroredSelector(state, [
      'CUSTOMER_BOOKING_AVAILABILITY_CHECK_CLIENT_PERMISSION',
    ])
  );

  const getErrorMessage = () =>
    Object.keys(errors).reduce((acc, key) => {
      acc += unescape(errors[key]);
      return acc;
    }, '');

  useEffect(() => {
    if (errors.CUSTOMER_BOOKING_AVAILABILITY_CHECK_CLIENT_PERMISSION) {
      Rollbar.error(
        `Invoice Route Error ${
          errors.CUSTOMER_BOOKING_AVAILABILITY_CHECK_CLIENT_PERMISSION
        }, ${getErrorMessage()}`
      );
      toast.error(getErrorMessage(), toastOptions);
      dispatch({ type: CUSTOMER_BOOKING.RESET });
      history.push(BOOKING_PATHS.root(uuid));
    }
  }, [errors]);

  useEffect(() => {
    if (cameFromInvoiceRoute) {
      dispatch({ type: CUSTOMER_BOOKING.RESET });
      history.push(BOOKING_PATHS.root(uuid));
    }
    if (client_id && !cameFromInvoiceRoute) {
      dispatch(generateInvoice(token));
      window.sessionStorage.setItem('cameFromInvoiceRoute', true);
    }
  }, []);

  return (
    <>
      {!cameFromConfirmRoute && (
        <div className={styles.InvoiceRoute}>
          {loading || !invoice ? (
            <h1>Loading...</h1>
          ) : (
            <CustomerInvoice
              invoice={invoice}
              studio={studio}
              user={user}
              meta={meta}
              handlePayment={(bookingLogicCompleted, calendarEvent) => {
                if (bookingLogicCompleted && calendarEvent) {
                  dispatch(updateBookingLogicCompleted(true));
                  dispatch(updateCalendarEvent(calendarEvent));
                }
                history.push(BOOKING_PATHS.confirm(uuid));
              }}
              hasActiveCode={hasActiveCode}
              couponAllowed={couponAllowed}
              availabilityId={availability.id}
              bookingCalendarId={bookingCalendar.id}
            />
          )}
        </div>
      )}
    </>
  );
};
export default withBackHandler(InvoiceRoute);
