export const dropdownOptions = (
  dispatch,
  getInvoicesList,
  updateInvoiceListMeta,
  setCurrentFilterIndex,
  getCouponCodeList
) => {
  return [
    {
      label: 'Open Invoices',
      onClickCallback: () => {
        dispatch(
          updateInvoiceListMeta({
            status: 'unpaid',
            is_quote: false,
            page: 1,
            titleDisplay: 'Open Invoices',
            search_term: null,
            codesExpanded: false,
          })
        );
        dispatch(getInvoicesList());
        setCurrentFilterIndex(0);
      },
    },
    {
      label: 'Late Invoices',
      onClickCallback: () => {
        dispatch(
          updateInvoiceListMeta({
            status: 'late',
            is_quote: false,
            page: 1,
            titleDisplay: 'Late Invoices',
            search_term: null,
            codesExpanded: false,
          })
        );
        dispatch(getInvoicesList());
        setCurrentFilterIndex(1);
      },
    },
    {
      label: 'Paid Invoices',
      onClickCallback: () => {
        dispatch(
          updateInvoiceListMeta({
            status: 'paid',
            is_quote: false,
            page: 1,
            titleDisplay: 'Paid Invoices',
            search_term: null,
            codesExpanded: false,
          })
        );
        dispatch(getInvoicesList());
        setCurrentFilterIndex(2);
      },
    },
    {
      label: 'Draft Invoices',
      onClickCallback: () => {
        dispatch(
          updateInvoiceListMeta({
            status: 'draft',
            is_quote: false,
            page: 1,
            titleDisplay: 'Draft Invoices',
            search_term: null,
            codesExpanded: false,
          })
        );
        dispatch(getInvoicesList());
        setCurrentFilterIndex(3);
      },
    },
    {
      label: 'All Invoices',
      onClickCallback: () => {
        dispatch(
          updateInvoiceListMeta({
            status: 'all',
            is_quote: false,
            page: 1,
            titleDisplay: 'All Invoices',
            search_term: null,
            codesExpanded: false,
          })
        );
        dispatch(getInvoicesList());
        setCurrentFilterIndex(4);
      },
    },
    {
      label: 'Pending Quotes',
      onClickCallback: () => {
        dispatch(
          updateInvoiceListMeta({
            status: 'pending_quote',
            is_quote: true,
            page: 1,
            titleDisplay: 'Pending Quotes',
            search_term: null,
            codesExpanded: false,
          })
        );
        dispatch(getInvoicesList());
        setCurrentFilterIndex(5);
      },
    },
    {
      label: 'Needs Review',
      onClickCallback: () => {
        dispatch(
          updateInvoiceListMeta({
            status: 'mod_requested_quote',
            is_quote: true,
            titleDisplay: 'Needs Review Quotes',
            search_term: null,
            codesExpanded: false,
          })
        );
        dispatch(getInvoicesList());
        setCurrentFilterIndex(6);
      },
    },
    {
      label: 'Declined Quotes',
      onClickCallback: () => {
        dispatch(
          updateInvoiceListMeta({
            status: 'declined_quote',
            is_quote: true,
            page: 1,
            titleDisplay: 'Declined Quotes',
            search_term: null,
            codesExpanded: false,
          })
        );
        dispatch(getInvoicesList());
        setCurrentFilterIndex(7);
      },
    },
    {
      label: 'Expired Quotes',
      onClickCallback: () => {
        dispatch(
          updateInvoiceListMeta({
            status: 'expired_quote',
            is_quote: true,
            page: 1,
            titleDisplay: 'Expired Quotes',
            search_term: null,
            codesExpanded: false,
          })
        );
        dispatch(getInvoicesList());
        setCurrentFilterIndex(8);
      },
    },
    {
      label: 'Draft Quotes',
      onClickCallback: () => {
        dispatch(
          updateInvoiceListMeta({
            status: 'draft_quote',
            is_quote: true,
            page: 1,
            titleDisplay: 'Draft Quotes',
            search_term: null,
            codesExpanded: false,
          })
        );
        dispatch(getInvoicesList());
        setCurrentFilterIndex(9);
      },
    },
    {
      label: 'All Quotes',
      onClickCallback: () => {
        dispatch(
          updateInvoiceListMeta({
            status: 'quotes',
            is_quote: true,
            page: 1,
            titleDisplay: 'All Quotes',
            search_term: null,
            codesExpanded: false,
          })
        );
        dispatch(getInvoicesList());
        setCurrentFilterIndex(10);
      },
    },
    {
      label: 'Coupon Codes',
      onClickCallback: () => {
        dispatch(
          updateInvoiceListMeta({
            codesExpanded: true,
            status: 'codes',
            is_quote: false,
            page: 1,
            titleDisplay: 'Coupon Codes',
            search_term: null,
          })
        );
        dispatch(getCouponCodeList());
        setCurrentFilterIndex(11);
      },
    },
  ];
};
