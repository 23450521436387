import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js/pure';
import { uniqueId } from '~utils';
import axios from 'axios';
import { absolutePath } from '~constants/routes';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { Button, Checkbox, Dropdown, Modal, TextInput, SearchInput } from '~ui';
import styles from './NewSubscriptionModal.module.scss';
import moment from 'moment';
import { phoneValidation, emailValidation } from '~constants/validation';

const stripeCardElementOptions = {
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const NewSubscriptionModal = ({ handleHide, plan }) => {
  const {
    stripePublicKey,
    studioId,
    isOnePageSignup,
    defaultSessionTypes,
  } = useSelector(state => state.subscription);
  const [stripePromise, setStripePromise] = useState();
  const [stripe, setStripe] = useState();

  const [disabled, setDisabled] = useState(false);

  const [stripePlanId, setStripePlanId] = useState(plan.stripe_plan_id);
  const [couponCode, setCouponCode] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [couponCodeValid, setCouponCodeValid] = useState(true);
  const [readableCouponCode, setReadableCouponCode] = useState('');
  const [couponMessage, setCouponMessage] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [billingFirstName, setBillingFirstName] = useState('');
  const [billingLastName, setBillingLastName] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [country, setCountry] = useState();
  const [stateOptions, setStateOptions] = useState([]);
  const [stateProvince, setStateProvince] = useState();
  const [city, setCity] = useState('');
  const [zipPostalCode, setZipPostalCode] = useState('');
  const [card, setCard] = useState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [sessionTypes, setSessionTypes] = useState([]);
  const [emailValid, setEmailValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);

  useEffect(() => {
    setStripePromise(loadStripe(stripePublicKey));
  }, []);

  const stripeCreateTokenParams = {
    name: `${billingFirstName} ${billingLastName}`,
    address_line1: addressLine1,
    address_line2: addressLine2,
    address_city: city,
    address_state: stateProvince,
    address_zip: zipPostalCode,
    address_country: country,
  };

  const subscriptionParams = {
    stripe_plan_id: stripePlanId,
    billing_first_name: billingFirstName,
    billing_last_name: billingLastName,
    billing_address_1: addressLine1,
    billing_address_2: addressLine2,
    billing_city: city,
    billing_state: stateProvince,
    billing_country: country,
    billing_postal: zipPostalCode,
    coupon_code: promoCode ? promoCode : null,
    readable_coupon_code: readableCouponCode ? readableCouponCode : null,
  };

  const checkFormValid = () => {
    if (
      !billingFirstName ||
      !billingLastName ||
      !addressLine1 ||
      !country ||
      (stateOptions.length > 0 && !stateProvince) ||
      !zipPostalCode ||
      !city ||
      !emailValid ||
      !phoneValid ||
      !card
    ) {
      toast.error('Please provide all billing information', toastOptions);
      return false;
    }
    return true;
  };

  const newSubscriptionSubmit = stripeCallResult => {
    axios
      .post('/api/subscriptions', {
        subscription: {
          studio_id: studioId,
          stripe_token: stripeCallResult.token.id,
          ...subscriptionParams,
        },
      })
      .then(() => {
        //Adds SUBSCRIPTION for facebook and google analytics
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'SUBSCRIPTION', action: stripePlanId });

        window.location.reload();
      })
      .catch(err => {
        setDisabled(false);
        let errorMessage =
          'Unable to create subscription, please contact support';

        if (err.response.data && err.response.data.error.messages) {
          errorMessage = err.response.data.error.messages[0];
        }

        toast.error(errorMessage, toastOptions);
        Rollbar.error(`${errorMessage}: ${err}`);
      });
  };

  const onePageSubmit = async stripeCallResult => {
    try {
      await axios.post('/api/one_page_signup', {
        one_page_signup: {
          subscription: {
            stripe_token: stripeCallResult.token.id,
            ...subscriptionParams,
          },
          user: {
            first_name: firstName,
            last_name: lastName,
            phone: phone,
            email: email,
            password: password,
            password_confirmation: password,
            time_zone: moment.tz.guess(),
          },
          studio: {
            business_name: businessName,
            studio_session_types: defaultSessionTypes.map(sessionType => {
              if (sessionTypes.includes(sessionType.id)) {
                return {
                  name: sessionType.name,
                  color: sessionType.color,
                };
              }
            }),
          },
        },
      });

      //Adds SUBSCRIPTION for facebook and google analytics
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'SUBSCRIPTION', action: stripePlanId });

      window.location.href = absolutePath('/login?subscribed=true');
    } catch (err) {
      let errorMessage =
        'Unable to create subscription, please contact support';
      if (err.response.status == 422) {
        errorMessage = err.response.data.error.messages.join('\n');
      }
      setDisabled(false);
      toast.error(errorMessage, toastOptions);
      Rollbar.error(`${errorMessage}: ${err}`);
    }
  };

  // Test card number 4060 3203 9137 2332
  const handleSubmit = () => {
    if (checkFormValid()) {
      stripe.createToken(card, stripeCreateTokenParams).then(result => {
        if (result.error) {
          const errorMessage = `Error creating Stripe token: ${result.error.message}`;
          toast.error(errorMessage, toastOptions);
          Rollbar.error(`${errorMessage}: ${result.error}`);
        } else {
          setDisabled(true);
          isOnePageSignup
            ? onePageSubmit(result)
            : newSubscriptionSubmit(result);
        }
      });
    }
  };

  return (
    <Modal title="New Subscription" handleHide={handleHide}>
      <div className={styles['NewSubscriptionModal-body']}>
        {stripePromise ? (
          <Elements stripe={stripePromise}>
            <NewSubscriptionForm
              setStripe={setStripe}
              formData={{
                stripePlanId,
                setStripePlanId,
                couponCode,
                setCouponCode,
                couponCodeValid,
                setCouponCodeValid,
                couponMessage,
                setCouponMessage,
                billingFirstName,
                setBillingFirstName,
                billingLastName,
                setBillingLastName,
                addressLine1,
                setAddressLine1,
                addressLine2,
                setAddressLine2,
                country,
                setCountry,
                stateOptions,
                setStateOptions,
                stateProvince,
                setStateProvince,
                city,
                setCity,
                zipPostalCode,
                setZipPostalCode,
                card,
                setCard,
                firstName,
                setFirstName,
                lastName,
                setLastName,
                email,
                setEmail,
                password,
                setPassword,
                phone,
                setPhone,
                businessName,
                setBusinessName,
                sessionTypes,
                setSessionTypes,
                phoneValid,
                setPhoneValid,
                emailValid,
                setEmailValid,
                promoCode,
                setPromoCode,
                readableCouponCode,
                setReadableCouponCode,
              }}
            />
          </Elements>
        ) : (
          <span className="Spinner" />
        )}
      </div>
      <div className={styles['NewSubscriptionModal-terms']}>
        <p>
          By signing up, I agree to Iris Works'{' '}
          <a
            target="_blank"
            href="https://iris-works.com/terms-and-conditions/"
          >
            Terms and Conditions
          </a>{' '}
          and{' '}
          <a target="_blank" href="https://iris-works.com/privacy-policy/">
            Privacy Policy
          </a>
        </p>
      </div>
      <div className={styles['NewSubscriptionModal-footer']}>
        <Button danger text="Cancel" onClick={handleHide} />
        <Button
          text={disabled ? 'Subscribing' : 'Subscribe'}
          disabled={disabled || !couponCodeValid}
          onClick={handleSubmit}
        />
      </div>
    </Modal>
  );
};

// This needs to be separate because the usage of Stripe hooks must be
// within the <Elements> component.
const NewSubscriptionForm = ({ formData, setStripe }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(true);

  const {
    planOptions,
    countryOptions,
    isOnePageSignup,
    defaultSessionTypes,
  } = useSelector(state => state.subscription);

  useEffect(() => {
    if (stripe && elements) {
      setLoading(false);
      setStripe(stripe);
    }
  }, [stripe, elements]);

  useEffect(() => {
    if (formData.couponCode) {
      handleSearchInputChange(formData.couponCode);
    }
  }, [formData.stripePlanId]);

  if (loading) {
    return <span className="Spinner" />;
  }

  const handleSearchInputChange = searchValue => {
    formData.setCouponCode(searchValue);
    if (!searchValue) {
      formData.setCouponMessage('');
      formData.setCouponCodeValid(true);
      formData.setPromoCode('');
      return;
    }

    axios
      .get(`/api/coupons/${searchValue}`, {
        params: {
          plan: formData.stripePlanId,
        },
      })
      .then(res => {
        if (
          res.data.redeem_by &&
          moment(res.data.redeem_by).isBefore(moment())
        ) {
          formData.setCouponMessage('Coupon code expired');
          formData.setCouponCodeValid(false);
          formData.setReadableCouponCode('');
        } else {
          formData.setCouponMessage(res.data.description);
          formData.setCouponCodeValid(true);
          formData.setPromoCode(res.data.promotional_code);
          formData.setReadableCouponCode(searchValue);
        }
      })
      .catch(error => {
        formData.setReadableCouponCode('');
        if (error.response.status === 404) {
          formData.setCouponMessage('Coupon code not found');
          formData.setCouponCodeValid(false);
        } else if (error.response.status === 418) {
          formData.setCouponMessage('Coupon not valid for this plan');
          formData.setCouponCodeValid(false);
        } else {
          const errorMessage = `Error fetching coupon code: ${error.response.data.error.messages}`;
          toast.error(errorMessage, toastOptions);
          Rollbar.error(`${errorMessage}: ${error}`);
          formData.setCouponCodeValid(false);
        }
      });
  };

  const handleCountryDropdownChange = alpha2 => {
    formData.setCountry(alpha2);
    axios
      .get(`/api/states/${alpha2}`)
      .then(res => {
        let options = res.data.map(state => ({
          label: state[1],
          value: state[0],
        }));
        options = [{ label: 'Choose a State/Province', value: null }].concat(
          options
        );
        formData.setStateOptions(options);
      })
      .catch(error => {
        const errorMessage = `Error fetching states for ${alpha2}: ${error.response.data.error.messages}`;
        toast.error(errorMessage, toastOptions);
        Rollbar.error(`${errorMessage}: ${error}`);
      });
  };

  const handleSessionTypesChange = sessionTypeId => {
    const sessionTypesCopy = formData.sessionTypes;
    if (!sessionTypesCopy.includes(sessionTypeId)) {
      sessionTypesCopy.push(sessionTypeId);
    } else {
      sessionTypesCopy.splice(sessionTypesCopy.indexOf(sessionTypeId), 1);
    }
    formData.setSessionTypes(sessionTypesCopy);
  };

  return (
    <form>
      <div>
        <Dropdown
          name="plan"
          labelText="Plan"
          initialValue={formData.stripePlanId}
          options={planOptions}
          onChangeCallback={formData.setStripePlanId}
          required
        />
        <div>
          <SearchInput
            name="coupon_code"
            labelText="Coupon Code"
            initialValue={formData.couponCode}
            onChangeCallback={handleSearchInputChange}
            hideIcon
          />
          <p className={styles['NewSubscriptionModal-couponCode']}>
            {formData.couponMessage}
          </p>
        </div>
      </div>
      {isOnePageSignup && (
        <>
          <div>
            <TextInput
              name="first_name"
              labelText="First Name"
              initialValue={formData.firstName}
              onChangeCallback={formData.setFirstName}
              required
            />
            <TextInput
              name="last_name"
              labelText="Last Name"
              initialValue={formData.lastName}
              onChangeCallback={formData.setLastName}
              required
            />
          </div>
          <div>
            <TextInput
              name="email"
              labelText="Email"
              initialValue={formData.email}
              onChangeCallback={(value, valid) => {
                formData.setEmail(value);
                formData.setEmailValid(valid);
              }}
              validation={emailValidation}
              required
            />
            <TextInput
              name="password"
              type="password"
              labelText="Password"
              initialValue={formData.password}
              onChangeCallback={formData.setPassword}
              required
            />
          </div>
          <div>
            <TextInput
              name="phone"
              labelText="Phone"
              initialValue={formData.phone}
              onChangeCallback={(value, valid) => {
                formData.setPhone(value);
                formData.setPhoneValid(valid);
              }}
              validation={phoneValidation}
              type="tel"
              required
            />
            <TextInput
              name="business_name"
              labelText="Business Name"
              initialValue={formData.businessName}
              onChangeCallback={formData.setBusinessName}
              required
            />
          </div>
          <label>Which type(s) of photography do you specialize in?</label>
          <div className={styles['NewSubscriptionModal-sessionTypes']}>
            <div>
              {defaultSessionTypes.slice(0, 3).map(sessionType => (
                <Checkbox
                  name={sessionType.name}
                  key={uniqueId('sessionType_')}
                  labelText={sessionType.name}
                  onChangeCallback={checked => {
                    handleSessionTypesChange(sessionType.id);
                  }}
                />
              ))}
            </div>
            <div>
              {defaultSessionTypes.slice(3, 6).map(sessionType => (
                <Checkbox
                  name={sessionType.name}
                  key={uniqueId('sessionType_')}
                  labelText={sessionType.name}
                  onChangeCallback={checked => {
                    handleSessionTypesChange(sessionType.id);
                  }}
                />
              ))}
            </div>
            <div>
              {defaultSessionTypes.slice(6, 9).map(sessionType => (
                <Checkbox
                  name={sessionType.name}
                  key={uniqueId('sessionType_')}
                  labelText={sessionType.name}
                  onChangeCallback={checked => {
                    handleSessionTypesChange(sessionType.id);
                  }}
                />
              ))}
            </div>
          </div>
        </>
      )}
      <hr />
      <h2>
        Billing Details<span>*</span>
      </h2>
      <div>
        <fieldset className={styles.StripeFieldset}>
          <CardElement
            options={stripeCardElementOptions}
            onBlur={() => {
              formData.setCard(elements.getElement('card'));
            }}
          />
        </fieldset>
      </div>
      <div>
        <TextInput
          name="first_name"
          labelText={`${isOnePageSignup ? 'Billing' : ''} First Name`}
          initialValue={formData.billingFirstName}
          onChangeCallback={formData.setBillingFirstName}
          required
        />
        <TextInput
          name="last_name"
          labelText={`${isOnePageSignup ? 'Billing' : ''} Last Name`}
          initialValue={formData.billingLastName}
          onChangeCallback={formData.setBillingLastName}
          required
        />
      </div>
      <div>
        <TextInput
          name="address_line_1"
          labelText="Address Line 1"
          initialValue={formData.addressLine1}
          onChangeCallback={formData.setAddressLine1}
          required
        />
        <TextInput
          name="address_line_2"
          labelText="Address Line 2"
          initialValue={formData.addressLine2}
          onChangeCallback={formData.setAddressLine2}
        />
      </div>
      <div>
        <Dropdown
          name="country"
          labelText="Country"
          options={countryOptions}
          initialValue={formData.country}
          onChangeCallback={handleCountryDropdownChange}
          required
        />
        <Dropdown
          name="state_province"
          labelText="State/Province"
          options={formData.stateOptions}
          initialValue={formData.stateProvince}
          onChangeCallback={formData.setStateProvince}
          disabled={formData.stateOptions.length === 0}
          required={formData.stateOptions.length > 0}
        />
      </div>
      <div>
        <TextInput
          name="city"
          labelText="City"
          initialValue={formData.city}
          onChangeCallback={formData.setCity}
          required
        />
        <TextInput
          name="zip_postal_code"
          labelText="Zip/Postal Code"
          initialValue={formData.zipPostalCode}
          onChangeCallback={formData.setZipPostalCode}
          required
        />
      </div>
    </form>
  );
};

export default NewSubscriptionModal;
