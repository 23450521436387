import React from 'react';
import styles from './ClientProfileRoot.module.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { configureStore } from '../../store';
import { Provider } from 'react-redux';

import {
  ClientDetailsRoot,
  ClientActivity,
  ClientJourneys,
  ToDosRoot,
  ClientSessionsRoot,
  ClientInvoicesRoot,
  ClientDocumentsRoot,
  ClientBanner,
  ClientNavbar,
  ClientMobileNavbar,
  ClientGalleries,
} from '../ClientProfile';

const ClientProfileRoot = props => {
  const {
    clientId,
    clientDetails,
    clientActivity,
    clientJourneys,
    clientTodos,
    clientSessions,
    clientInvoices,
    clientDocuments,
    clientBanner,
    clientNavbar,
    clientMobileNavbar,
    clientGalleries,
  } = props;
  const CLIENT_PROFILE_PATHS = {
    details: (clientId = ':client_id') => `/clients/${clientId}`,
    activities: (clientId = ':client_id') => `/clients/${clientId}/activities`,
    journeys: (clientId = ':client_id') => `/clients/${clientId}/journeys`,
    toDos: (clientId = ':client_id') => `/clients/${clientId}/to-dos`,
    sessions: (clientId = ':client_id') => `/clients/${clientId}/sessions`,
    invoices: (clientId = ':client_id') => `/clients/${clientId}/invoices`,
    documents: (clientId = ':client_id') => `/clients/${clientId}/documents`,
    galleries: (clientId = ':client_id') => `/clients/${clientId}/galleries`,
  };

  const store = configureStore({
    calendar: clientBanner.calendar,
  });

  return (
    <Provider store={store}>
      <Router>
        <div>
          <ClientMobileNavbar {...clientMobileNavbar} />
          <ClientNavbar {...clientNavbar} />
          <ClientBanner {...clientBanner} />
        </div>
        <div className={styles.ClientProfileRoot}>
          <Switch>
            <Route exact path={CLIENT_PROFILE_PATHS.details(clientId)}>
              <ClientDetailsRoot {...clientDetails} />
            </Route>
            <Route path={CLIENT_PROFILE_PATHS.activities(clientId)}>
              <ClientActivity {...clientActivity} />
            </Route>
            <Route path={CLIENT_PROFILE_PATHS.journeys(clientId)}>
              <div className={styles['ClientProfileRoot-leftAlign']}>
                <ClientJourneys {...clientJourneys} />
              </div>
            </Route>
            <Route path={CLIENT_PROFILE_PATHS.toDos(clientId)}>
              <div className={styles['ClientProfileRoot-leftAlign']}>
                <ToDosRoot {...clientTodos} />
              </div>
            </Route>
            <Route path={CLIENT_PROFILE_PATHS.sessions(clientId)}>
              <ClientSessionsRoot {...clientSessions} />
            </Route>
            <Route path={CLIENT_PROFILE_PATHS.invoices(clientId)}>
              <ClientInvoicesRoot {...clientInvoices} />
            </Route>
            <Route path={CLIENT_PROFILE_PATHS.documents(clientId)}>
              <ClientDocumentsRoot {...clientDocuments} />
            </Route>
            <Route path={CLIENT_PROFILE_PATHS.galleries(clientId)}>
              <ClientGalleries {...clientGalleries} />
            </Route>
          </Switch>
        </div>
      </Router>
    </Provider>
  );
};

export default ClientProfileRoot;
