import React, { useState, useEffect } from 'react';
import styles from './WhatsNew.module.scss';
import axios from 'axios';
import { routes } from '~constants/routes';
import { Icon } from '~ui';

const WhatsNew = () => {
  const [blogPosts, setBlogPosts] = useState();
  const [loading, setLoading] = useState();

  const getBlogPosts = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(routes.BLOG_POSTS.INDEX);
      setBlogPosts(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getBlogPosts();
  }, []);

  return (
    <div className={styles.WhatsNew}>
      <h1>What's New</h1>
      {loading && (
        <div className={styles['WhatsNew-loading']}>
          <Icon name="spinner" className="fa-pulse" large />
        </div>
      )}
      {blogPosts && (
        <div className={styles['WhatsNew-content']}>
          {blogPosts.map((post, index) => (
            <div key={index}>
              <i className={'fas fa-angle-right'}></i>
              <a href={post.url}>{post.title}</a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default WhatsNew;
