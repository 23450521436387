import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Sidebar } from '~ui';
import { getInvoicesList, updateInvoiceListMeta } from '~actions/invoice';
import { getCouponCodeList } from '~actions/couponCodes';
import { getOptions } from './options';
import { useWindowSize } from '~hooks';

const InvoicesSidebar = ({ fromClients }) => {
  const meta = useSelector(state => state.invoice.meta);
  const dispatch = useDispatch();
  const options = getOptions(
    meta,
    dispatch,
    getInvoicesList,
    updateInvoiceListMeta,
    getCouponCodeList
  );

  const windowWidth = useWindowSize()[0];
  const isMobile = windowWidth < 680;

  if (fromClients) {
    options.splice(2, 1);
  }

  if (isMobile) {
    return <></>;
  } else {
    return (
      <div>
        <Sidebar options={options} />
      </div>
    );
  }
};

export default InvoicesSidebar;
