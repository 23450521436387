import axios from 'axios';
import { routes } from '~constants/routes';
import { INVOICE_CONFIGS } from '~constants/actionTypes';

export const updateInvoiceConfig = params => async dispatch => {
  try {
    dispatch({ type: INVOICE_CONFIGS.UPDATE.REQUEST });

    const invoiceConfig = await axios
      .put(routes.INVOICE_CONFIGS.UPDATE, { invoice_config: params })
      .then(res => res.data);

    dispatch({ type: INVOICE_CONFIGS.UPDATE.SUCCESS, payload: invoiceConfig });
  } catch (error) {
    dispatch({
      type: INVOICE_CONFIGS.UPDATE.FAILURE,
      payload: error.response.data.error,
      error: true,
    });
  }
};
