import React, { cloneElement, createRef } from 'react';
import PropTypes from 'prop-types';
import { Popover } from '~ui';
import ToggleContent from '~components/common/ToggleContent';

const TogglePopover = props => {
  const {
    activator,
    popoverContent,
    popoverControls,
    popoverWidth = '400px',
  } = props;
  const activatorRef = createRef(null);
  const getBounds = () => activatorRef.current.getBoundingClientRect();

  return (
    <ToggleContent
      toggle={handleShow =>
        cloneElement(activator, {
          onClick: e => {
            e.stopPropagation();
            handleShow();
          },
          ref: activatorRef,
        })
      }
      content={handleHide => (
        <Popover
          allowHandleClickOutside
          handleHide={handleHide}
          activatorBounds={getBounds}
          controls={popoverControls}
          popoverWidth={popoverWidth}
        >
          {popoverContent}
        </Popover>
      )}
    />
  );
};

TogglePopover.propTypes = {
  activator: PropTypes.object.isRequired,
  popoverContent: PropTypes.object.isRequired,
};

export default TogglePopover;
