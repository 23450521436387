import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Timeline, Icon } from '~ui';
import { FULL_DATE_TIME_FORMAT } from '~constants/datetime';
import styles from './ClientJourneys.module.scss';
import axios from 'axios';

const ClientJourneys = props => {
  const { client } = props;
  const [currentJourneyIndex, setCurrentJourneyIndex] = useState(0);
  const noJourneyResponse = `${client.first_name} is not a part of any Workflows.`;
  const [isLoading, setIsLoading] = useState(false);
  const [journeys, setJourneys] = useState([]);
  const numJourneys = Object.keys(journeys).length;
  const journeysArray = Object.entries(journeys).sort(
    (a, b) => new Date(b[0]) - new Date(a[0])
  );

  useEffect(() => {
    setIsLoading(true);
    getClientJourneys();
  }, []);

  const getClientJourneys = async () => {
    await axios.get(`/api/clients/${client.id}/journeys`).then(res => {
      setJourneys(res.data);
      setIsLoading(false);
    });
  };

  const renderCurrentJourney = () => {
    return (
      <div className={styles['ClientJourneys-current']}>
        <h2>
          {journeysArray[currentJourneyIndex][1].session.name},{' '}
          {moment(journeysArray[currentJourneyIndex][1].session.date).format(
            FULL_DATE_TIME_FORMAT
          )}
        </h2>
        <h3>{journeysArray[currentJourneyIndex][1].templateName}</h3>
        <Timeline items={journeysArray[currentJourneyIndex][1].steps} />
      </div>
    );
  };

  const getPreviousJourney = () => {
    if (currentJourneyIndex - 1 < 0) {
      setCurrentJourneyIndex(numJourneys - 1);
      return;
    }

    setCurrentJourneyIndex(currentJourneyIndex - 1);
  };

  const getNextJourney = () => {
    if (currentJourneyIndex + 1 > numJourneys - 1) {
      setCurrentJourneyIndex(0);
      return;
    }

    setCurrentJourneyIndex(currentJourneyIndex + 1);
  };

return (
  <>
    {isLoading ? (
      <h1>Loading...</h1>
    ) : (
      <>
        {numJourneys >= 1 && (
          <>
            <h1>Workflows</h1>
            <div className={styles.ClientJourneys}>
              {numJourneys > 1 && (
                <Icon
                  name="arrow-left"
                  onClickCallback={getPreviousJourney}
                  className="mr-12 mt-18"
                  large
                />
              )}
              {renderCurrentJourney()}
              {numJourneys > 1 && (
                <Icon
                  name="arrow-right"
                  onClickCallback={getNextJourney}
                  className="mt-18"
                  large
                />
              )}
            </div>
          </>
        )}
        {numJourneys === 0 && (
          <>
            <h1>Workflows</h1>
            <h2 style={{ textAlign: 'center' }}>{noJourneyResponse}</h2>
          </>
        )}
      </>
    )}
  </>
)};

export default ClientJourneys;
