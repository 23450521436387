/**
 * This object contains all our Rails API endpoints scoped by
 * API. The nested endpoints within the APIs should match the
 * Rails action names.
 *
 * @example Using a route to make a request to the API.
 *
 * async function example() {
 *  const response = await axios
 *    .get(routes.CALENDAR_EVENTS.INDEX)
 *    .catch(err => {
 *      // Do something with error.
 *    });
 *  // Do something with response.
 * }
 *
 */

export const routes = {
  BOOKING_CALENDARS: {
    INDEX: '/api/booking_calendars',
    CREATE: '/api/booking_calendars',
    UPDATE: calendarId => `/api/booking_calendars/${calendarId}`,
    DELETE: calendarId => `/api/booking_calendars/${calendarId}`,
    PUBLISH: calendarId => `/api/booking_calendars/${calendarId}/publish`,
    DUPLICATE: toDuplicateCalendarId =>
      `/api/booking_calendars/${toDuplicateCalendarId}/duplicate`,
  },
  BOOKING_AVAILABILITIES: {
    INDEX: '/api/booking_availabilities',
    CREATE: '/api/booking_availabilities',
    UPDATE: availabilityId => `/api/booking_availabilities/${availabilityId}`,
    SELECT_AVAILABILITY: availabilityId =>
      `/api/booking_availabilities/${availabilityId}/select_availability`,
    DELETE: availabilityId => `/api/booking_availabilities/${availabilityId}`,
    BOOK: availabilityId =>
      `/api/booking_availabilities/${availabilityId}/book`,
    CHECK_CLIENT_PERMISSION: availabilityId =>
      `/api/booking_availabilities/${availabilityId}/check_client_permission`,
  },
  BOOKING_CONTRACT: {
    GENERATE: contractTemplateId =>
      `/api/contract_templates/${contractTemplateId}/booking_generate`,
    SIGN: contractId => `/api/contracts/${contractId}/booking_sign`,
  },
  BOOKING_INVOICE: {
    GENERATE: invoiceTemplateId =>
      `/api/invoice_templates/${invoiceTemplateId}/booking_generate`,
  },
  CALENDAR_EVENTS: {
    INDEX: '/api/calendar_events',
    CREATE: '/api/calendar_events',
    SHOW: eventId => `/api/calendar_events/${eventId}`,
    UPDATE: eventId => `/api/calendar_events/${eventId}`,
    DELETE: eventId => `/api/calendar_events/${eventId}`,
    GET_SESSION_TYPE_COUNT: '/api/calendar_events/session_type_count',
    TODAY: '/api/calendar_events/today',
    CRONOFY: '/api/cronofy_calendar/events',
  },
  CHARGES: {
    GET: '/api/charges',
  },
  CLIENTS: {
    GET: '/api/clients',
    SEND_EMAIL: '/api/clients/send_email',
    CREATE: '/api/clients',
    BULK_UPDATE: '/api/clients/bulk_update',
    IMPORT: '/api/clients/import',
    DELETE: clientId => `/api/clients/${clientId}`,
    UPDATE: clientId => `/api/clients/${clientId}`,
  },
  CUSTOMER_BOOKING: {
    NOTIFY_STUDIO: calendarUuid =>
      `/customer/booking/${calendarUuid}/notify_studio`,
  },
  COUPON_CODES: {
    VALIDATE: `/api/coupon_codes/validate`,
    GET: '/api/coupon_codes',
    CREATE: `/api/coupon_codes`,
    UPDATE: couponId => `/api/coupon_codes/${couponId}`,
    DELETE: couponId => `/api/coupon_codes/${couponId}`,
  },
  LEGACY_EMAIL_TEMPLATES: {
    SHOW: templateId => `/api/legacy_email_templates/${templateId}`,
    BOOKING_GENERATE: templateId =>
      `/api/legacy_email_templates/${templateId}/booking_generate`,
    DELETE: templateId => `/api/legacy_email_templates/${templateId}`,
  },
  EMAIL_TEMPLATES: {
    GET: '/api/email_templates',
    SHOW: id => `/api/email_templates/${id}`,
    POST: '/api/email_templates',
    PUT: id => `/api/email_templates/${id}`,
    DELETE: id => `/api/email_templates/${id}`,
  },
  FEATURE_FLAGS: {
    POST: '/api/feature_flags',
    DELETE: '/api/feature_flags',
  },
  GROUPS: {
    GET: '/api/groups',
    CREATE: '/api/groups',
    BULK_UPDATE: '/api/groups/bulk_update',
    PUT: id => `/api/groups/${id}`,
    DELETE: id => `/api/groups/${id}`,
  },
  GROUPED_CALENDAR_EVENTS: {
    INDEX: '/api/grouped_calendar_events',
  },
  INVOICES: {
    SHOW: invoiceId => `/api/invoices/${invoiceId}`,
    CREATE: '/api/invoices',
    UPDATE: invoiceId => `/api/invoices/${invoiceId}`,
    DELETE: invoiceId => `/api/invoices/${invoiceId}`,
    SEND: invoiceId => `/api/invoices/${invoiceId}/send_to_client`,
    GET: '/api/invoices',
    FUNDY_IMPORT: '/api/invoices/fundy_import',
    GET_INSTALLMENTS: invoiceId =>
      `/api/invoices/${invoiceId}/get_invoice_installments`,
  },
  INVOICE_CONFIGS: {
    UPDATE: '/api/invoice_config',
  },
  INVOICE_LINE_ITEMS: {
    CREATE: '/api/invoice_line_items',
    UPDATE: itemId => `/api/invoice_line_items/${itemId}`,
    DELETE: itemId => `/api/invoice_line_items/${itemId}`,
  },
  INVOICE_PAYMENTS: {
    CREATE: '/api/invoice_payments',
    UPDATE: paymentId => `/api/invoice_payments/${paymentId}`,
    DELETE: paymentId => `/api/invoice_payments/${paymentId}`,
  },
  INVOICE_PRODUCTS: {
    GET: '/api/invoice_products',
    CREATE: '/api/invoice_products',
    UPDATE: productId => `/api/invoice_products/${productId}`,
    DELETE: productId => `/api/invoice_products/${productId}`,
    CHECK_LINE_ITEM_TEMPLATES: productId =>
      `/api/invoice_products/${productId}/check_line_item_templates`,
  },
  INVOICE_TEMPLATES: {
    SHOW: templateId => `/api/invoice_templates/${templateId}`,
    CREATE: '/api/invoice_templates',
    UPDATE: templateId => `/api/invoice_templates/${templateId}`,
    DELETE: templateId => `/api/invoice_templates/${templateId}`,
  },
  JOURNEY_TEMPLATES: {
    INDEX: '/engine/journey_templates',
    CREATE: '/engine/journey_templates/',
    DELETE: journeyTemplateId =>
      `/engine/journey_templates/${journeyTemplateId}`,
  },
  JOURNEYS: {
    ACTIVE_JOURNEYS: '/engine/journeys/active_journeys',
    APPLY_TO_EXISTING: '/engine/journeys/apply_to_existing',
  },
  JOURNEY_TEMPLATE: {
    GET: journeyTemplateId => `/engine/journey_templates/${journeyTemplateId}`,
    UPDATE: journeyTemplateId =>
      `/engine/journey_templates/${journeyTemplateId}`,
    RESTORE: journeyTemplateId =>
      `/engine/journey_templates/${journeyTemplateId}/restore`,
  },
  JOURNEY_STEPS: {
    UPDATE: journeyStepId => `/engine/journey_steps/${journeyStepId}`,
    EDIT_AND_SEND: '/engine/journey_steps/edit_and_send',
  },
  LOCATIONS: {
    INDEX: '/api/locations',
  },
  PAYMENT_SCHEDULES: {
    UPDATE: scheduleId => `/api/payment_schedules/${scheduleId}`,
    RESTORE: scheduleId => `/api/payment_schedules/${scheduleId}/restore`,
  },
  PAYMENT_SCHEDULE_INSTALLMENTS: {
    CREATE: '/api/payment_schedule_installments',
    UPDATE: installmentId =>
      `/api/payment_schedule_installments/${installmentId}`,
    DELETE: installmentId =>
      `/api/payment_schedule_installments/${installmentId}`,
  },
  PAYMENT_SCHEDULE_TEMPLATES: {
    CREATE: '/api/payment_schedule_templates',
    UPDATE: scheduleId => `/api/payment_schedule_templates/${scheduleId}`,
  },
  QUICKBOOKS_INVOICE: {
    DELETE: invoiceId => `/api/quickbooks/invoices/${invoiceId}`,
  },
  REMINDER_TEMPLATE: {
    CREATE: '/api/reminder_templates',
    UPDATE: id => `/api/reminder_templates/${id}`,
  },
  SCHEDULED_EMAILS: {
    GET: clientId => `/api/clients/${clientId}/scheduled_emails`,
    DELETE: stepId => `/engine/journey_steps/${stepId}`,
  },
  SPUD_USER: {
    UPDATE: id => `/api/spud_users/${id}`,
  },
  SQUARE_CONNECTION: {
    DELETE: '/api/square_user/deauthorize',
  },
  STRIPE_CONNECTION: {
    DELETE: '/api/stripe_user/deauthorize',
  },
  STUDIO_PROFILE: {
    UPDATE: id => `/api/studios/${id}`,
    EXTEND_TRIAL: id => `/api/studios/${id}/extend_trial`,
    CHECK_SEND_LIMIT: id => `/api/studios/${id}/check_send_limit`,
  },
  STUDIOS: {
    UPDATE: studioId => `/api/studios/${studioId}`,
  },

  SUN: {
    INDEX: '/api/sun',
  },
  TODOS: {
    GET: '/api/todos',
    CREATE: '/api/todos',
    UPDATE: todoId => `/api/todos/${todoId}`,
    COMPLETE: todoId => `/api/todos/${todoId}/complete`,
    UNCOMPLETE: todoId => `/api/todos/${todoId}/un_complete`,
    DELETE: todoId => `/api/todos/${todoId}`,
  },
  SIGNUP: {
    CREATE: '/api/sign_up',
    STUDIO_SETUP: '/api/sign_up/studio_setup',
    STUDIO_CREATE: '/api/sign_up/studio_create',
  },
  FORECAST: {
    INDEX: '/api/forecasts',
  },
  CLIENT_ACTIVITIES: {
    INDEX: '/api/client_activities',
  },
  GOALS_METRICS: {
    INDEX: '/api/goal_metric_calculations',
    GET_DATA: chartId => `/api/goal_metric_calculations/${chartId}`,
  },
  BLOG_POSTS: {
    INDEX: '/api/blog_posts',
  },
  SESSION_TYPES: {
    INDEX: '/api/session_types',
    CREATE: '/api/session_types',
    UPDATE: id => `/api/session_types/${id}`,
    UPDATE_MULTIPLE: `/api/session_types/update_multiple`,
    DELETE: id => `/api/session_types/${id}`,
  },
  LEADS: {
    CREATE: '/api/leads',
  },
  DOCUMENTS: {
    INDEX: '/api/documents',
    CLIENT_DOCUMENTS: '/api/documents/client_documents',
    GET: id => `/api/documents/${id}`,
    UPDATE: id => `/api/documents/${id}`,
    CREATE: '/api/documents',
    DELETE: id => `api/documents/${id}`,
    SEND_IT: id => `/api/documents/${id}/send_it`,
  },
  QUESTIONNAIRES: {
    INDEX: '/api/web_forms',
    CREATE: '/api/web_forms',
  },
  CONTRACT_TEMPLATES: {
    INDEX: '/api/contract_templates',
    CREATE: '/api/contract_templates',
    DUPLICATE: id => `/api/contract_templates/${id}/duplicate`,
    UPDATE: id => `/api/contract_templates/${id}`,
    DELETE: id => `/api/contract_templates/${id}`,
    GENERATE: id => `/api/contract_templates/${id}/generate`,
  },
  CONTRACTS: {
    INDEX: '/api/contracts',
    CREATE: '/api/contracts',
    DELETE: id => `/api/contracts/${id}`,
    RESEND: id => `/api/contracts/${id}/resend`,
  },
  QUESTIONNAIRES: {
    INDEX: '/api/questionnaires',
    CREATE: '/api/questionnaires',
    SHOW: id => `/api/questionnaires/${id}`,
    UPDATE: id => `/api/questionnaires/${id}`,
    SEND_IT: id => `/api/questionnaires/${id}/send_it`,
    DELETE: id => `/api/questionnaires/${id}`,
    DUPLICATE: id => `/api/questionnaires/${id}/duplicate`,
  },
  QUESTIONNAIRE_RESPONSES: {
    INDEX: '/api/questionnaire_responses',
    SHOW: id => `/api/questionnaire_responses/${id}`,
    DELETE: id => `/api/questionnaire_responses/${id}`,
    RESEND: id => `/api/questionnaire_responses/${id}/resend`,
  },
  EMAIL_CAMPAIGN_TEMPLATES: {
    INDEX: '/api/email_campaign_templates',
    CREATE: '/api/email_campaign_templates',
    UPDATE: id => `/api/email_campaign_templates/${id}`,
    DELETE: id => `/api/email_campaign_templates/${id}`,
    GET_CLIENTS: '/api/email_campaign_templates/get_clients',
    SEND_TO_CLIENTS: id =>
      `/api/email_campaign_templates/${id}/send_to_client_list`,
    TEST_SEND: id => `/api/email_campaign_templates/${id}/test_send`,
  },
  EMAIL_CAMPAIGNS: {
    INDEX: '/api/email_campaigns',
    STATISTICS: '/api/email_campaigns/statistics',
    CANCEL: id => `/api/email_campaigns/${id}/delete_campaign`,
    SHOW: id => `/api/email_campaigns/${id}`,
  },
  INTEGRATION_CONFIGS: {
    UPDATE: '/api/integration_config',
  },
  SHOOT_PROOF_USER: {
    DELETE: '/api/shoot_proof_user/deauthorize',
  },
  PIC_TIME_USER: {
    DELETE: '/api/pic_time_user/deauthorize',
  },
};

export const absolutePath = route => {
  const pathPieces = window.location.href.split('/');
  const rootPath = pathPieces[0] + '//' + pathPieces[2];

  return `${rootPath}${route}`;
};
