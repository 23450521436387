import React, { useRef, useEffect } from 'react';
import { TextInput } from '~ui';
import styles from './OptionValue.module.scss';

const OptionValue = ({
  index,
  initialOptionvalue,
  onChange,
  removeOption,
  handleAddInput,
  errors,
  focusNewInput,
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (focusNewInput && inputRef.current) {
      inputRef.current.focus();
    }
  }, [focusNewInput]);

  const handleInputChange = event => {
    onChange(event, index);
  };

  return (
    <div
      className={styles.ButtonStyle}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          handleAddInput(index + 1);
        }
      }}
    >
      <TextInput
        ref={inputRef}
        name="optionName"
        initialValue={initialOptionvalue || ''}
        onChangeCallback={handleInputChange}
        placeholder="Enter Option Value"
        setWarningForEmpty={errors}
      />
      <button onClick={() => handleAddInput(index + 1)}>+</button>
      {index !== 0 && (
        <button
          className={styles['ButtonStyle-secondButton']}
          onClick={() => removeOption(index)}
          style={{ paddingLeft: '4px' }}
        >
          -
        </button>
      )}
    </div>
  );
};

export default OptionValue;
