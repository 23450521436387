import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';

const PaypalButton = props => {
  const { settings, loadingCallback, className, isBooking } = props;
  const { currency, amountDue, name, paypalAddress, invoiceUuid } = settings;

  const Button = paypal.Buttons.driver('react', {
    React,
    ReactDOM,
  });

  const createOrder = (data, actions) => {
    return actions.order.create({
      intent: 'CAPTURE',
      purchase_units: [
        {
          amount: {
            currency_code: currency,
            value: amountDue,
          },
          item_name: name,
          payee: {
            email_address: paypalAddress,
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    loadingCallback({ loading: true, reload: false });
    return actions.order.capture().then(function (details) {
      axios
        .get(`/webhooks/paypal/${invoiceUuid}/confirm_payment`)
        .then(() => {
          loadingCallback({
            loading: false,
            reload: !isBooking,
            success: true,
          });
          toast.success('Successfully created your payment!', toastOptions);
        })
        .catch(error => {
          loadingCallback({ loading: false, reload: false });
          toast.error(
            `We've encountered an error with your payment.`,
            toastOptions
          );
        });
    });
  };

  return (
    <div className={className}>
      <Button
        createOrder={(data, actions) => createOrder(data, actions)}
        onApprove={(data, actions) => onApprove(data, actions)}
        onError={err => {
          Rollbar.error('Error with paypal button', err);
          toast.error(
            `Paypal encountered an error with your payment.`,
            toastOptions
          );
        }}
        style={{
          shape: 'pill',
          color: 'gold',
          label: 'paypal',
          size: 'small',
        }}
      />
    </div>
  );
};

PaypalButton.propTypes = {
  settings: PropTypes.object.isRequired,
  loadingCallback: PropTypes.func.isRequired,
  className: PropTypes.string,
  isBooking: PropTypes.bool,
};

PaypalButton.defaultProps = {
  className: null,
  isBooking: false,
};

export default PaypalButton;
