import React from 'react';
import PastSessions from '../../SessionsHolder/PastSessions/PastSessions';
import UpcomingSessions from '../../SessionsHolder/UpcomingSessions/UpcomingSessions';
import { Accordion } from '~ui';
import styles from './ClientSessions.module.scss';

const ClientSessions = props => {
  const { calendar, client_id, studioCreatedAt } = props;

  return (
    <div>
      <div className={styles.Header}>
        <h1>Sessions</h1>
      </div>
      <h2>Upcoming Sessions</h2>
      <UpcomingSessions calendar={calendar} client_id={client_id} />
      <br />
      <Accordion title="Past Sessions" expanded={false}>
        <PastSessions
          calendar={calendar}
          client_id={client_id}
          studioCreatedAt={studioCreatedAt}
        />
      </Accordion>
    </div>
  );
};

export default ClientSessions;
