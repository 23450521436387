import React, { useState } from 'react';
import styles from './EmailTemplatesTable.module.scss';
import {
  SearchInput,
  Button,
  DataTable,
  DeleteConfirmation,
  Modal,
  TextInput,
} from '~ui';
import ToggleContent from '~components/common/ToggleContent';
import axios from 'axios';
import { absolutePath, routes } from '~constants/routes';

const EmailTemplatesTable = props => {
  const { emailTemplates } = props;

  const [templates, setTemplates] = useState(emailTemplates.emailTemplates);
  const [name, setName] = useState();

  const headers = [
    { name: 'name', labelText: 'Name', type: 'function', sortable: false },
    {
      name: 'emailSubject',
      labelText: 'Email Subject',
      type: 'string',
      sortable: false,
    },
    {
      name: 'attachments',
      labelText: 'Attachments',
      type: 'function',
      sortable: false,
    },
    { name: 'buttonGroup', labelText: '', type: 'function', sortable: false },
  ];

  const deleteTemplate = id => {
    axios
      .delete(routes.LEGACY_EMAIL_TEMPLATES.DELETE(id))
      .then(() =>
        setTemplates(
          templates.filter(template => template.attributes.id !== id)
        )
      );
  };

  const renderButtons = template => {
    return (
      <div className={styles['EmailTemplatesTable-buttons']}>
        <a
          className="fas fa-eye c-mg mr-12"
          title="Preview"
          href={absolutePath(
            `/studio/email_templates/${template.attributes.id}`
          )}
          target="_blank"
        />
        <a
          className="fa fa-pencil-alt c-mg mr-12"
          title="Edit"
          href={absolutePath(
            `/studio/email_templates/${template.attributes.id}/edit`
          )}
        />
        <ToggleContent
          toggle={handleShow => (
            <a
              onClick={handleShow}
              className="fas fa-trash-alt c-mg mr-12"
              title="Delete"
            />
          )}
          content={handleHide => (
            <DeleteConfirmation
              handleHide={handleHide}
              handleDelete={() => deleteTemplate(template.attributes.id)}
              name={template.attributes.name}
              resource="Email Template"
              second_message={template.attributes.has_journey_step_templates}
            />
          )}
        />
      </div>
    );
  };

  const renderTitle = template => {
    return (
      <a
        href={absolutePath(
          `/studio/email_templates/${template.attributes.id}/edit`
        )}
      >
        {template.attributes.name}
      </a>
    );
  };

  const handleCreate = async () => {
    const res = await axios.post(routes.EMAIL_TEMPLATES.POST, {
      email_template: { name, email_body: '<p></p>' },
    });

    window.location.href = absolutePath(
      `/studio/email_templates/${res.data.data.id}/edit`
    );
  };

  const filterEmailTemplates = value => {
    let filteredResults = [];
    if (value === '') {
      setTemplates(emailTemplates.emailTemplates);
    } else {
      templates.map(template => {
        if (
          template.attributes.name.toLowerCase().includes(value.toLowerCase())
        ) {
          filteredResults.push(template);
        }
      });
      setTemplates(filteredResults);
    }
  };

  const renderAttachments = template => {
    if (template.attributes.email_attachments.length > 0) {
      return (
        <div>
          {template.attributes.email_attachments.map(attachment => {
            if (attachment.item_type === 'ContractTemplate') {
              return (
                <i
                  className="fas fa-signature mr-12 c-bl"
                  title={attachment.name}
                />
              );
            } else if (attachment.item_type === 'InvoiceTemplate') {
              return (
                <i
                  className="fas fa-file-invoice-dollar mr-12 c-bl"
                  title={attachment.name}
                />
              );
            } else if (attachment.item_type === 'WebForm') {
              return (
                <i
                  className="fas fa-question mr-12 c-bl"
                  title={attachment.name}
                />
              );
            } else {
              return (
                <i
                  className="fas fa-file-alt mr-12 c-bl"
                  title={attachment.name}
                />
              );
            }
          })}
        </div>
      );
    } else {
      return <div>-</div>;
    }
  };

  return (
    <div className={styles.EmailTemplatesTable}>
      <div className={styles['EmailTemplatesTable-header']}>
        <h1>Email Templates</h1>
        <div className={styles['EmailTemplatesTable-header-rightSide']}>
          <SearchInput
            name="Search Templates"
            placeholder="Search Templates"
            onChangeCallback={filterEmailTemplates}
          />
          <ToggleContent
            toggle={handleShow => (
              <Button
                className="ml-12"
                text="Create Template"
                icon="plus"
                onClick={handleShow}
              />
            )}
            content={handleHide => (
              <Modal title="Create an Email Template" handleHide={handleHide}>
                <div className="mb-8">
                  What will your new Email Template be called?
                </div>
                <TextInput
                  name="emailTemplateTextInput"
                  placeholder="Email Template Name"
                  onChangeCallback={setName}
                  autoFocus
                />
                <br />
                <div className="d-fr">
                  <Button
                    className="mr-8"
                    text="Cancel"
                    onClick={handleHide}
                    danger
                  />
                  <Button text="Save" onClick={handleCreate} />
                </div>
              </Modal>
            )}
          />
        </div>
      </div>
      <a
        className={styles['EmailTemplatesTable-marketplaceLink']}
        href="https://iris-works.com/marketplace/"
        target="_blank"
      >
        Need an email template? Click here to view curated workflows from
        industry leaders
      </a>
      <div className={styles['EmailTemplatesTable-table']}>
        <DataTable
          columnTypes={['string']}
          headers={headers}
          rows={templates.map(template => ({
            name: renderTitle(template),
            emailSubject: template.attributes.email_subject,
            attachments: renderAttachments(template),
            buttonGroup: renderButtons(template),
          }))}
        />
      </div>
    </div>
  );
};

export default EmailTemplatesTable;
