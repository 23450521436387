import React, { useState, useRef } from 'react';
import styles from './ImportClientsModal.module.scss';
import { Modal, Button } from '~ui';
import axios from 'axios';
import { routes } from '~constants/routes';
import { toastOptions } from '~constants/toasts';
import { toast } from 'react-toastify';

const ImportClientsModal = props => {
  const { handleHide } = props;
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  let disabled = selectedFile === null;

  const handleChooseFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = event => {
    const file = event.target.files[0];

    setSelectedFile(file);
  };

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);

      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const response = await axios.post(
        routes.CLIENTS.IMPORT,
        formData,
        headers
      );

      toast.success(response.data.message, toastOptions);
      handleHide();
      window.location.reload();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.info(
          "There's a problem importing this CSV. Please reach out to support.",
          { ...toastOptions, autoClose: false }
        );
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.info(error.response.data.message, {
          ...toastOptions,
          autoClose: false,
        });
      } else {
        toast.error('Error creating clients. Please reach out to support.');
      }
    }
  };

  return (
    <Modal title={'Import Clients'} handleHide={handleHide}>
      <div className={styles['ImportClientsModal']}>
        <div className={styles['ImportClientsModal-row']}>
          <strong>Import your client information:</strong>
        </div>
        <div className={styles['ImportClientsModal-row']}>
          Upload a CSV file with the below column headings and your client's
          information, and we'll instantly add them to your Iris Works client
          list. Watch this{' '}
          <a
            href="https://www.youtube.com/@Iris-works/search?query=import"
            target="_blank"
            rel="noopener noreferrer"
          >
            video
          </a>{' '}
          for more information!
        </div>
        <div className={styles['ImportClientsModal-row']}>
          <strong>
            {' '}
            Click
            <a
              href="https://docs.google.com/spreadsheets/d/118jAe66ab5BNL4VrLCAJfArimRteg0qIkJJdv_4lC8k/edit?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              HERE{' '}
            </a>
            to download our template.
          </strong>
        </div>
        <hr />
        <h3>CSV</h3>
        <div className={styles['ImportClientsModal-uploader']}>
          <label htmlFor="file-input" onClick={handleChooseFileClick}>
            <Button text="Choose File" purpleRectangle={true} />
          </label>
          {selectedFile ? (
            <span style={{ color: '#7F7F7F' }}> {selectedFile.name}</span>
          ) : (
            <span style={{ color: '#7F7F7F' }}> No file chosen</span>
          )}
          <input
            type="file"
            id="file-input"
            style={{ display: 'none' }}
            onChange={handleFileChange}
            ref={fileInputRef}
            accept=".csv"
          />
        </div>
        <div className={styles['ImportClientsModal-columnsRow']}>
          <div className={styles['ImportClientsModal-column']}>
            <div>
              <strong>Required Columns:</strong>
            </div>
            <div>first_name</div>
            <div>last_name</div>
            <div>email</div>
            <div>client_status</div>
          </div>
          <div className={styles['ImportClientsModal-column']}>
            <div>
              <strong>Optional Columns:</strong>
            </div>
            <div>birthday</div>
            <div>alternate_email</div>
            <div>phone</div>
            <div>secondary_phone</div>
            <div>company_name</div>
            <div>address</div>
            <div>city</div>
            <div>state</div>
            <div>zip</div>
            <div>country</div>
            <div>wedding_date</div>
            <div>notes</div>
            <div>group_name</div>
            <div>email_marketing</div>
          </div>
        </div>
        <div className={styles['ImportClientsModal-notes']}>
          NOTES:
          <ul>
            <li>
              When adding "yes" to the email_marketing column, you are
              confirming you have the client's permission to send them
              promotional emails
            </li>
            <li>
              The client_status column needs one of these values: client, lead,
              marketing contact
            </li>
            <li>
              The country column needs a 2 character country code, for example:
              US, CA, UK
            </li>
          </ul>
        </div>
        <hr />
        <div className={styles['ImportClientsModal-buttons']}>
          <Button
            text="Cancel"
            onClick={() => {
              handleHide();
            }}
            whiteRectangle={true}
          />
          <Button
            onClick={handleUpload}
            text="Upload"
            purpleRectangle={true}
            disabled={disabled}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ImportClientsModal;
