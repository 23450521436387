import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import axios from 'axios';
import { SCHEDULE_TEMPLATE_HEADERS } from '~constants/invoices';
import { toastOptions } from '~constants/toasts';
import { routes } from '~constants/routes';
import { toCurrencyAmount } from '~utils';
import { Table } from '~ui';
import styles from './InvoiceInstallmentsTable.module.scss';
import { useEffect } from 'react';

const title = 'Payment Schedule';
const subtitle = 'When you will be receiving your payments for this invoice';
const scheduleTriggerMap = {
  WhenReceived: 'When invoice is received',
  BeforeSession: 'Before the session',
  AfterSession: 'After the session',
  OnSessionDate: 'On the session date',
};

const InvoiceInstallmentsTable = ({
  invoice,
  schedule,
  handleUpdateSchedule,
  setDisabled,
  setIntervalError,
  intervalError,
}) => {
  const fullParams = params => ({
    payment_schedule_template: {
      payment_schedule_installment_templates_attributes: [params],
    },
  });

  const handleCreate = params => {
    let createParams;
    let percentCheck;

    if (params.schedule_trigger) {
      params.interval = params.schedule_trigger;
      params.interval_amount =
        +params.schedule_interval * +params.schedule_offset;
    }

    if (!params.interval) {
      setIntervalError(true);
      return;
    } else setIntervalError(false);

    if (params.percent_amount) {
      percentCheck = params.amount / invoice.total;
    } else {
      percentCheck = null;
    }

    const newParams = {
      ...params,
      percent_amount: params.percent_amount
        ? params.percent_amount / 100
        : percentCheck && percentCheck > 1
        ? '1'
        : percentCheck,
      amount: params.percent_amount
        ? (invoice.total * params.percent_amount) / 100
        : percentCheck > 1
        ? invoice.total
        : params.amount,
    };

    if (params.percent_amount) {
      createParams = priceAdjuster(newParams);
    }

    if (!createParams) {
      createParams = newParams;
    }

    const wrappedParams = fullParams(createParams);

    axios
      .put(routes.PAYMENT_SCHEDULE_TEMPLATES.UPDATE(schedule.id), wrappedParams)
      .then(res => handleUpdateSchedule(res.data.data.attributes))
      .catch(err => {
        const position = { position: 'bottom-center' };

        if (err) {
          toast.error(err, { ...toastOptions, ...position });
        }
      });
  };

  const handleUpdate = async (id, params) => {
    let updateParams;
    let percentCheck;

    if (params.schedule_trigger) {
      params.interval = params.schedule_trigger;
      params.interval_amount =
        +params.schedule_interval * +params.schedule_offset;
    }

    if (
      !params.interval &&
      params.invoice_template_schedule_trigger !== 'WhenReceived'
    ) {
      setIntervalError(true);
      return;
    } else setIntervalError(false);

    if (params.percent_amount) {
      percentCheck = params.amount / invoice.total;
    } else {
      percentCheck = null;
    }

    const newParams = {
      ...params,
      interval_amount:
        params.interval == 'OnSessionDate' || params.interval == 'WhenReceived'
          ? 0
          : params.interval_amount,
      percent_amount: params.percent_amount
        ? params.percent_amount / 100
        : percentCheck && percentCheck > 1
        ? '1'
        : percentCheck,
      amount: params.percent_amount
        ? (invoice.total * params.percent_amount) / 100
        : percentCheck > 1
        ? invoice.total
        : params.amount,
    };

    if (params.percent_amount) {
      updateParams = priceAdjuster({ id, ...newParams });
    }

    if (!updateParams) {
      updateParams = newParams;
    }

    const wrappedParams = fullParams({ id, ...updateParams });

    axios
      .put(routes.PAYMENT_SCHEDULE_TEMPLATES.UPDATE(schedule.id), wrappedParams)
      .then(res => handleUpdateSchedule(res.data.data.attributes))
      .catch(err => {
        const position = { position: 'bottom-center' };

        if (err) {
          toast.error(err, { ...toastOptions, ...position });
        }
      });
  };

  const handleDelete = async id => {
    const wrappedParams = fullParams({ id, _destroy: true });

    await axios
      .put(routes.PAYMENT_SCHEDULE_TEMPLATES.UPDATE(schedule.id), wrappedParams)
      .then(res => handleUpdateSchedule(res.data.data.attributes))
      .catch(err => {
        const position = { position: 'bottom-center' };

        if (err) {
          toast.error(err, { ...toastOptions, ...position });
        }
      });

    if (rows.length === 1) {
      handleUpdateSchedule(null);
      setDisabled(false);
    }
  };

  const rows = schedule.installments.map((installment, index) => {
    const {
      id,
      amount,
      interval,
      interval_amount,
      percent_amount,
    } = installment;

    const basedOnSessionDisplayValue = () => {
      if (interval_amount % 365 === 0) {
        return `${scheduleTriggerMap[interval]} by ${
          interval_amount / 365
        } year(s)`;
      } else if (interval_amount % 30 === 0) {
        return `${scheduleTriggerMap[interval]} by ${
          interval_amount / 30
        } month(s)`;
      } else if (interval_amount % 7 === 0) {
        return `${scheduleTriggerMap[interval]} by ${
          interval_amount / 7
        } week(s)`;
      } else {
        return `${scheduleTriggerMap[interval]} by ${interval_amount} day(s)`;
      }
    };

    const inputContainer =
      schedule.base_payments_on == 'based_on_session'
        ? {
            name: 'schedule',
            value:
              interval === 'WhenReceived' || interval === 'OnSessionDate'
                ? scheduleTriggerMap[interval]
                : basedOnSessionDisplayValue(),
            type: 'schedule_interval',
            editable: true,
          }
        : {
            name: 'due_date',
            value:
              index === 0
                ? scheduleTriggerMap[invoice.schedule_trigger]
                : `${interval_amount} ${interval}(s) later`,
            type: 'interval',
            editable: index !== 0,
          };

    const row = {
      id,
      editable: true,
      deletable: true,
      data: [
        { name: 'order', value: index + 1, type: 'number' },
        inputContainer,
        {
          name: 'amount',
          value: amount,
          type: 'toggle_amount',
          editable: true,
        },
      ],
    };

    if (interval) {
      row.data[1].interval = interval;
    }

    if (interval && interval_amount) {
      row.data[1].interval_amount = interval_amount;
    }

    if (percent_amount) {
      row.data[2].percent_amount = percent_amount;
    }

    return row;
  });

  const priceAdjuster = params => {
    let totalPercentage = 0;
    let totalInstallmentAmount = 0;
    let oneCentTooMany = false;
    let oneCentTooLittle = false;
    let priceAdjustedRow = params;

    rows.map(row => {
      if (params.id) {
        if (row.id !== params.id) {
          if (!row.data[2].percent_amount) {
            totalPercentage += Number(
              (row.data[2].value / invoice.total).toFixed(2)
            );
            totalInstallmentAmount += Number(row.data[2].value);
          } else {
            totalPercentage += Number(row.data[2].percent_amount);
            totalInstallmentAmount += Number(row.data[2].value);
          }
        }
      } else {
        if (!row.data[2].percent_amount) {
          totalPercentage += Number(
            (row.data[2].value / invoice.total).toFixed(2)
          );
          totalInstallmentAmount += Number(row.data[2].value);
        } else {
          totalPercentage += Number(row.data[2].percent_amount);
          totalInstallmentAmount += Number(row.data[2].value);
        }
      }
    });

    totalPercentage += Number(params.percent_amount);
    totalInstallmentAmount += Number(params.amount.toFixed(2));

    if (totalPercentage == 1) {
      if ((totalInstallmentAmount - invoice.total).toFixed(2) == 0.01) {
        oneCentTooMany = true;
      } else if ((invoice.total - totalInstallmentAmount).toFixed(2) == 0.01) {
        oneCentTooLittle = true;
      }
    }

    if (oneCentTooMany) {
      priceAdjustedRow.amount = Number(
        priceAdjustedRow.amount.toFixed(2) - 0.01
      ).toFixed(2);

      priceAdjustedRow.percent_amount = priceAdjustedRow.amount / invoice.total;

      return { ...priceAdjustedRow };
    } else if (oneCentTooLittle) {
      priceAdjustedRow.amount = Number(
        priceAdjustedRow.amount.toFixed(2) + 0.01
      ).toFixed(2);

      priceAdjustedRow.percent_amount = priceAdjustedRow.amount / invoice.total;

      return { ...priceAdjustedRow };
    } else {
      return { ...params };
    }
  };

  const schemaInputContainer =
    schedule.base_payments_on == 'based_on_session'
      ? {
          name: 'schedule',
          value: 'OnSessionDate',
          type: 'schedule_interval',
          editable: true,
        }
      : {
          name: 'due_date',
          value: '',
          type: 'interval',
          editable: true,
        };

  const schema = [
    { name: 'order', value: '', type: 'number' },
    schemaInputContainer,
    { name: 'amount', value: '', type: 'toggle_amount', editable: true },
  ];

  const installmentsSum = schedule.installments.reduce((a, b) => {
    const firstAddend = isNaN(a) ? Number.parseFloat(a.amount) : a;
    const secondAddend = isNaN(b) ? Number.parseFloat(b.amount) : b;
    return Number.parseFloat((firstAddend + secondAddend).toFixed(2));
  }, 0);

  const calculationError =
    Number.parseFloat(installmentsSum).toFixed(2) !==
    Number.parseFloat(invoice.total).toFixed(2);

  useEffect(() => {
    if (calculationError || intervalError) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [calculationError, intervalError]);

  return (
    <div className={styles.InvoiceInstallmentsTable}>
      <Table
        headers={SCHEDULE_TEMPLATE_HEADERS}
        rows={rows}
        schema={schema}
        title={title}
        subtitle={subtitle}
        handleCreate={handleCreate}
        handleUpdate={handleUpdate}
        handleDelete={handleDelete}
        invoiceTemplateScheduleTrigger={invoice.schedule_trigger}
      />
      {calculationError && (
        <p className={styles['InstallmentTemplatesTable-error']}>
          * The sum of these installments ({toCurrencyAmount(installmentsSum)})
          does not add up to the total amount due for this invoice.
        </p>
      )}
      {intervalError && (
        <p className={styles['InstallmentTemplatesTable-error']}>
          * Please ensure you choose an interval (day, week, month, year).
        </p>
      )}
    </div>
  );
};

InvoiceInstallmentsTable.propTypes = {
  schedule: PropTypes.shape({
    name: PropTypes.string,
    installments: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  installmentsSum: PropTypes.number,
  handleUpdateSchedule: PropTypes.func,
};

InvoiceInstallmentsTable.defaultProps = {
  installmentsSum: 0,
  handleUpdateSchedule: _ => {},
};

export default InvoiceInstallmentsTable;
