import { TODOS } from '~constants/actionTypes';

export default function(state = {}, action) {
  switch (action.type) {
    case TODOS.GET.SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        meta: action.payload.meta,
      };
    case TODOS.CREATE.SUCCESS:
      return { ...state, items: [...state.items, action.payload] };
    case TODOS.UPDATE.SUCCESS:
      return {
        ...state,
        items: state.items.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              ...action.payload,
            };
          }
          return item;
        }),
      };
    case TODOS.COMPLETE.SUCCESS:
      return {
        ...state,
        items: state.items.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              ...action.payload,
            };
          }
          return item;
        }),
      };
    case TODOS.UNCOMPLETE.SUCCESS:
      return {
        ...state,
        items: state.items.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              ...action.payload,
            };
          }
          return item;
        }),
      };
    case TODOS.DELETE.SUCCESS:
      const items = state.items.filter(item => item.id !== action.payload.id);

      return { ...state, items };
    default:
      return state;
  }
}
