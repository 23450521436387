/**
 * This object contains keyCodes that correspond to keyboard keys.
 *
 * @example Using a keyCode constant in a key event handler to check which key is detected.
 *
 * const example = event => {
 *   if (event.keyCode === keyCodes.ESCAPE) {
 *     // perform action associated with escape key
 *   }
 * }
 *
 */

export const keyCodes = {
  DELETE: 8,
  ENTER: 13,
  ESCAPE: 27,
  SPACE: 32,
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,
};
