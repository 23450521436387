import React, { useEffect, useState } from 'react';
import styles from './LeadsPageSettings.module.scss';
import { Accordion, Toggle, TextInput, Dropdown, Button, Icon } from '~ui';
import { ChromePicker } from 'react-color';
import TogglePopover from '~components/common/TogglePopover';
import { routes } from '~constants/routes';
import axios from 'axios';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import ToggleContent from '~components/common/ToggleContent';
import CustomFieldsModal from './CustomFieldsModal';
import ImageUploading from 'react-images-uploading';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SessionList from './SessionList';

const LeadsPageSettings = props => {
  const { leadsData, meta } = props;
  const { studio_id, email_templates_options } = meta;
  const MAX_UPLOAD_MB = 20;
  const MAX_UPLOAD_BYTES = MAX_UPLOAD_MB * 1000000;

  const [showStudioLogo, setShowStudioLogo] = useState(
    leadsData.leads_display_logo
  );
  const [displaySessionIcons, setDisplaySessionIcons] = useState(
    leadsData.leads_display_session_icons
  );
  const [transparentBackground, setTransparentBackground] = useState(
    !!leadsData.leads_transparent_background
  );
  const [submitColor, setSubmitColor] = useState(
    leadsData.leads_submit_color || '#111111'
  );
  const [leadsHeadline, setLeadsHeadline] = useState(leadsData.leads_headline);
  const [responseEmailId, setResponseEmailId] = useState(
    leadsData.default_lead_response_email_template_id
  );

  const [redirectURL, setRedirectURL] = useState(leadsData.leads_redirect_url);

  const [settingsLoading, setSettingsLoading] = useState(false);
  const [useCustomSessionOrder, setUseCustomSessionOrder] = useState(
    leadsData.leads_custom_session_order
  );

  const [sessionTypes, setSessionTypes] = useState([]);
  const [customFields, setCustomFields] = useState(
    leadsData.custom_fields.map(field => ({
      fieldName: field.field_name,
      destroy: false,
      required: field.required,
      id: field.id,
    }))
  );

  const [leadsPhoto, setLeadsPhoto] = useState([]);

  const [layout, setLayout] = useState(leadsData.leads_layout);

  const sortAndMergeArray = arr => {
    const withOrder = [];
    const withoutOrder = [];

    arr.forEach(item => {
      if (item.order !== null && item.order !== undefined) {
        withOrder.push(item);
      } else {
        withoutOrder.push(item);
      }
    });

    withOrder.sort((a, b) => a.order - b.order);

    withoutOrder.sort((a, b) => a.name.localeCompare(b.name));

    setSessionTypes(withOrder.concat(withoutOrder));
  };

  useEffect(() => {
    sortAndMergeArray(leadsData.lead_form_session_types);
  }, []);

  const layoutOptions = [
    { label: 'Photo', value: 'photo' },
    { label: 'Logo', value: 'logo' },
  ];

  const handleSave = async () => {
    const params = {
      studio: {
        leads_display_logo: showStudioLogo,
        leads_display_session_icons: displaySessionIcons,
        leads_submit_color: submitColor,
        leads_headline: leadsHeadline,
        leads_redirect_url: redirectURL,
        default_lead_response_email_template_id: responseEmailId
          ? +responseEmailId
          : null,
        leads_layout: layout,
        leads_custom_fields_attributes: customFields.map(cf => ({
          id: typeof cf.id === 'number' ? cf.id : null,
          field_name: cf.fieldName,
          required: cf.required,
          _destroy: cf.destroy || false,
        })),
        leads_transparent_background: transparentBackground,
        leads_custom_session_order:
          useCustomSessionOrder && sessionTypes.length > 0 ? true : false,
      },
    };

    if (leadsPhoto[0] && leadsPhoto[0].file.name) {
      if (leadsPhoto[0].file.size <= MAX_UPLOAD_BYTES) {
        params.studio.leads_photo = leadsPhoto[0].data_url;
        params.studio.leads_photo_file_name = leadsPhoto[0].file.name;
      } else {
        toast.error('Photo file size must be less than 20 MB');
        return;
      }
    } else if (leadsPhoto[0] && leadsPhoto[0].file.name === null) {
      params.studio.leads_photo = null;
      params.studio.leads_photo_file_name = null;
    }

    const sessionTypeParams = {
      session_types: sessionTypes.map((type, index) => {
        return { id: type.id, order: index + 1 };
      }),
    };

    try {
      setSettingsLoading(true);

      if (useCustomSessionOrder && sessionTypes.length > 0) {
        await axios.put(
          routes.SESSION_TYPES.UPDATE_MULTIPLE,
          sessionTypeParams
        );
      }

      const res = await axios.put(
        routes.STUDIO_PROFILE.UPDATE(studio_id),
        params
      );
      if (res.status === 200) {
        toast.success('Settings Saved', toastOptions);
        window.location.reload();
      }
    } catch (error) {
      toast.error('There was an error saving your settings', toastOptions);
      setSettingsLoading(false);
    }
  };

  const handleImageDelete = () => {
    setLeadsPhoto([{ file: { name: null } }]);
  };

  const showDelete = () => {
    if (leadsPhoto[0]) {
      return !!leadsPhoto[0].file.name;
    } else {
      return !!leadsData.leads_photo_file_name;
    }
  };

  return (
    <>
      {settingsLoading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingText}>
            <p>Loading...</p>
            <Icon name="spinner" className="fa-pulse" large />
          </div>
        </div>
      )}
      <div className={styles.LeadsPageSettings}>
        <Accordion title="Page Form Settings" expanded>
          <div className={styles['LeadsPageSettings-toggleContainer']}>
            <p>Show my Studio Logo</p>
            <Toggle
              initialValue={showStudioLogo}
              onChangeCallback={setShowStudioLogo}
            />
          </div>
          <div className={styles['LeadsPageSettings-toggleContainer']}>
            <p>Use Icons for Session Selection</p>
            <Toggle
              initialValue={displaySessionIcons}
              onChangeCallback={setDisplaySessionIcons}
            />
          </div>
          <div className={styles['LeadsPageSettings-toggleContainer']}>
            <p>Transparent Background</p>
            <Toggle
              initialValue={transparentBackground}
              onChangeCallback={setTransparentBackground}
            />
          </div>
          <div className={styles['LeadsPageSettings-toggleContainer']}>
            <p>Use Custom Session Order</p>
            <Toggle
              initialValue={useCustomSessionOrder}
              onChangeCallback={setUseCustomSessionOrder}
            />
          </div>
          <DndProvider backend={HTML5Backend}>
            {useCustomSessionOrder && (
              <SessionList
                sessionTypes={sessionTypes}
                setSessionTypes={setSessionTypes}
              />
            )}
          </DndProvider>

          <div className={styles['LeadsPageSettings-toggleContainer']}>
            <p>Submit Button Color</p>
            <div>
              <TogglePopover
                popoverWidth="267px"
                activator={
                  <div
                    className={styles['LeadsPageSettings-label-color']}
                    style={{ backgroundColor: submitColor }}
                  />
                }
                popoverContent={
                  <ChromePicker
                    disableAlpha
                    color={submitColor}
                    onChange={({ hex }) => {
                      setSubmitColor(hex);
                    }}
                  />
                }
              />
            </div>
          </div>
          <hr />
          <div className={styles['LeadsPageSettings-inputs']}>
            <TextInput
              labelText="Leads Page Headline"
              name="headline"
              initialValue={leadsHeadline}
              onChangeCallback={setLeadsHeadline}
            />
            <Dropdown
              labelText={
                <div className={styles['LeadsPageSettings-emailLabel']}>
                  <label>Default Auto Response Email</label>
                  <p>Automatic email response sent to submitted lead</p>
                </div>
              }
              name="email"
              options={email_templates_options}
              onChangeCallback={setResponseEmailId}
              initialValue={+responseEmailId}
            />
            <Dropdown
              labelText={'Choose Form Layout'}
              name="formLayout"
              options={layoutOptions}
              onChangeCallback={setLayout}
              initialValue={layout}
            />
            <TextInput
              labelText={
                <div className={styles['LeadsPageSettings-redirectLabel']}>
                  <label>Custom Confirmation Page</label>
                  <p>
                    Redirect lead submissions to a custom confirmation or thank
                    you page. If left blank, leads will be redirected to a
                    default Thank You page.{' '}
                    <b>
                      Copy and paste your confirmation page url. For example:
                      https://iris-works.com/
                    </b>
                  </p>
                </div>
              }
              name="redirectURL"
              initialValue={redirectURL}
              onChangeCallback={setRedirectURL}
              placeholder="Copy and paste url here"
            />
            {layout === 'photo' && (
              <>
                <div className={styles['LeadsPageSettings-uploadLabel']}>
                  <label>Upload a photo for your layout</label>
                  <p>Maximum file size: 20MB</p>
                </div>
                <ImageUploading
                  value={leadsPhoto}
                  onChange={setLeadsPhoto}
                  dataURLKey="data_url"
                >
                  {({ onImageUpload }) => (
                    <div
                      className={styles['LeadsPageSettings-uploadContainer']}
                    >
                      <Button text="Upload" onClick={onImageUpload} />
                      <div className={styles['LeadsPageSettings-filename']}>
                        <p>
                          {leadsPhoto[0]
                            ? leadsPhoto[0].file.name
                            : leadsData.leads_photo_file_name}
                        </p>
                        {showDelete() && (
                          <a
                            className="fas fa-trash-alt"
                            onClick={handleImageDelete}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </ImageUploading>
              </>
            )}
          </div>
          <div className={styles['LeadsPageSettings-customFieldsContainer']}>
            <div
              className={
                styles['LeadsPageSettings-customFieldsContainer-heading']
              }
            >
              <h2>Custom Fields</h2>
              <ToggleContent
                toggle={handleShow => <a onClick={handleShow}>Add+</a>}
                content={handleHide => (
                  <CustomFieldsModal
                    {...{
                      setCustomFields,
                      customFields,
                      handleHide,
                    }}
                  />
                )}
              />
            </div>
            {customFields
              .filter(f => !f.destroy)
              .map((field, index) => (
                <div
                  className={
                    styles[
                      'LeadsPageSettings-customFieldsContainer-customFields'
                    ]
                  }
                  key={index}
                >
                  <div>
                    <label>{field.fieldName}</label>
                    <div
                      className={styles['LeadsPageSettings-customFieldInput']}
                    >
                      <TextInput
                        name={`customField_${index}`}
                        disabled
                        required={field.required}
                        className={
                          styles['LeadsPageSettings-customFieldTextInput']
                        }
                      />
                      <a
                        onClick={() => {
                          const copy = customFields;
                          copy.find(f => f.id === field.id).destroy = true;
                          setCustomFields([...copy]);
                        }}
                      >
                        <i className="fa fa-trash-alt" />
                      </a>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className={styles['LeadsPageSettings-buttons']}>
            <div>
              <Button text="Save" onClick={handleSave} />
            </div>
          </div>
        </Accordion>
      </div>
    </>
  );
};

export default LeadsPageSettings;
