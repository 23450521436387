import axios from 'axios';
import { routes } from '~constants/routes';
import { JOURNEY_TEMPLATE, REMINDER_TEMPLATE } from '~constants/actionTypes';

export const getJourneyTemplate = () => async (dispatch, getState) => {
  const { currentJourneyTemplate } = getState().journeyTemplate;

  try {
    dispatch({ type: JOURNEY_TEMPLATE.GET.REQUEST });

    const res = await axios
      .get(routes.JOURNEY_TEMPLATE.GET(currentJourneyTemplate.id))
      .then(res => res);

    dispatch({
      type: JOURNEY_TEMPLATE.GET.SUCCESS,
      payload: res.data.data.attributes,
    });
  } catch (error) {
    dispatch({
      type: JOURNEY_TEMPLATE.GET.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};

export const restoreJourneyTemplate = () => async (dispatch, getState) => {
  const { currentJourneyTemplate } = getState().journeyTemplate;

  try {
    dispatch({ type: JOURNEY_TEMPLATE.RESTORE.REQUEST })
    const res = await axios.put(routes.JOURNEY_TEMPLATE.RESTORE(currentJourneyTemplate.id))
      .then(res => res)

    dispatch({
      type: JOURNEY_TEMPLATE.RESTORE.SUCCESS,
      payload: res.data.data.attributes
    })
  } catch (error) {
    dispatch({
      type: JOURNEY_TEMPLATE.RESTORE.FAILURE,
      payload: error,
      error: true
    })
  }
}

export const updateJourneyTemplate = params => async (dispatch, getState) => {
  const { currentJourneyTemplate } = getState().journeyTemplate;

  const getStepAttributes = () => {
    switch (params.type) {
      case 'ADD_STEP':
        return [...currentJourneyTemplate.steps, params.step];
      case 'DELETE_STEP':
        return currentJourneyTemplate.steps.map(step =>
          step.id === params.deletedId ? { ...step, _destroy: true } : step
        );
      case 'UPDATE_STEP':
        return currentJourneyTemplate.steps.map(step =>
          step.id === params.step.id ? { ...params.step } : step
        );
      default:
        return currentJourneyTemplate.steps;
    }
  };

  try {
    dispatch({ type: JOURNEY_TEMPLATE.UPDATE.REQUEST });
    const res = await axios
      .put(routes.JOURNEY_TEMPLATE.UPDATE(currentJourneyTemplate.id), {
        journey_template: {
          ...currentJourneyTemplate,
          name:
            params.type == 'UPDATE_NAME'
              ? params.name
              : currentJourneyTemplate.name,
          steps_attributes: getStepAttributes(),
        },
      })
      .then(res => res);

    dispatch({
      type: JOURNEY_TEMPLATE.UPDATE.SUCCESS,
      payload: res.data.data.attributes,
    });
  } catch (error) {
    dispatch({
      type: JOURNEY_TEMPLATE.UPDATE.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};

export const createReminderTemplate = (
  description,
  studioId
) => async dispatch => {
  try {
    dispatch({ type: REMINDER_TEMPLATE.CREATE.REQUEST });
    const res = await axios.post(routes.REMINDER_TEMPLATE.CREATE, {
      reminder_template: {
        description: description,
        studio_id: studioId,
      },
    });
    dispatch({ type: REMINDER_TEMPLATE.CREATE.SUCCESS });
    return res.data.data.id;
  } catch (error) {
    dispatch({
      type: REMINDER_TEMPLATE.CREATE.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};

export const updateReminderTemplate = (description, id) => async dispatch => {
  try {
    dispatch({ type: REMINDER_TEMPLATE.UPDATE.REQUEST });
    const res = await axios.put(routes.REMINDER_TEMPLATE.UPDATE(id), {
      reminder_template: {
        description: description,
      },
    });
    dispatch({ type: REMINDER_TEMPLATE.UPDATE.SUCCESS });
    return res.data.data.id;
  } catch (error) {
    dispatch({
      type: REMINDER_TEMPLATE.UPDATE.FAILURE,
      payload: error.response.data.error.messages,
      error: true,
    });
  }
};

export const setResourceOptions = itemOptionId => ({
  type: JOURNEY_TEMPLATE.SET.RESOURCE_OPTIONS,
  payload: itemOptionId,
});
