import React from 'react';
import { Modal, Button } from '~ui';

const SendLimitReached = ({ handleHide }) => {
  return (
    <Modal
      title={`You’ve hit your free trial sending limit!
    `}
      handleHide={handleHide}
    >
      <div>
        <p>
          In order to protect our reputation and prevent spammers from abusing
          our email functionality, we limit the number of messages our free
          trial users can send. Ready to continue sending emails through Iris
          Works? Just use the chat icon in the bottom corner of this page to
          verify your account, and our team will get back to you as soon as
          possible!
        </p>
      </div>
      <div className="d-fr">
        <Button
          className="mr-8"
          text="Close"
          onClick={() => {
            handleHide();
          }}
          danger
        />
      </div>
    </Modal>
  );
};

export default SendLimitReached;
