export const MAX_UPLOAD_MB = 20;
export const MAX_UPLOAD_BYTES = MAX_UPLOAD_MB * 1000000;
export const DOCUMENT_TYPE = [
  //Microsoft
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',

  //Documents
  'application/pdf',
  'text/html',
  'text/plain',
  'text/rtf',
  'application/xml',

  //Archives
  'application/x-compressed',
  'application/x-tar',
  'application/x-gtar',
  'application/x-gzip',
  'application/zip',
  'application/gzip',
  'application/zip',

  //Images
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',

  //Adobe
  'image/vnd.adobe.photoshop',
  'application/postscript',
  'application/x-shockwave-flash',
];
