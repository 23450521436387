import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames/bind';
import { uniqueId } from '~utils';
import { Icon } from '~ui';
import { FULL_DATE_FORMAT } from '~constants/datetime';
import styles from './Timeline.module.scss';

const Timeline = props => {
  const { items } = props;
  const cx = classnames.bind(styles);

  const getIcon = item => {
    const classes = cx('Timeline-itemIcon', {
      'Timeline-itemIcon--complete': item.isComplete,
    });

    if (item.isComplete) {
      return <Icon name="check-circle" className={classes} large />;
    }

    return <Icon name="circle" className={classes} large outlined />;
  };

  const getFiller = (item, isLastItem) => {
    const classes = cx('Timeline-itemFiller', {
      'Timeline-itemFiller--complete': item.isComplete,
      'Timeline-itemFiller--last': isLastItem,
    });

    return <div className={classes} />;
  };

  return (
    <div className={styles['Timeline']}>
      <ul role="list">
        {items.map((item, index) => {
          let isLastItem = false;

          if (index + 1 === items.length) {
            isLastItem = true;
          }

          return (
            <li key={uniqueId('Timeline-item_')}>
              {getIcon(item)}
              {getFiller(item, isLastItem)}
              <div className={styles['TimelineBody']}>
                <div>{moment(item.date).format(FULL_DATE_FORMAT)}</div>
                <span>{item.content}</span>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

Timeline.propTypes = {
  items: PropTypes.array,
};

export default Timeline;
