import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableNew as Table, DeleteConfirmation } from '~ui';
import { absolutePath } from '~constants/routes';
import DocumentsButtons from '../ButtonGroups/DocumentsButtons';
import DocumentPreviewModal from '../DocumentPreviewModal';
import styles from './DocumentsTable.module.scss';
const DocumentsTable = ({
  handleDocumentDelete,
  selectorKey,
  updateMetaData,
  getData,
}) => {
  const { data, meta } = useSelector(state => state.documents[selectorKey]);
  const clientId = useSelector(state => state.documents.clientId);

  const [rows, setRows] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [idForDelete, setIdForDelete] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewId, setPreviewId] = useState(null);

  const dispatch = useDispatch();

  const ITEM_HEADERS = [
    'Name',
    ...(selectorKey == 'clientDocuments' ? ['Client Name'] : []),
  ];

  const createRowItem = ({
    id,
    fileName,
    clientName,
    documentUrl,
    clientId,
  }) => {
    const rowItem = {
      id,
      editable: false,
      deletable: true,
      temp: false,
      onClickCallback: () => {
        setPreviewId(id);
        setShowPreviewModal(true);
      },
      deleteButtonOverride: () => {
        setIdForDelete(id);
        setShowDeleteModal(true);
      },
      buttonGroup: (
        <DocumentsButtons
          documentId={id}
          documentName={fileName}
          documentUrl={documentUrl}
        />
      ),
      data: {
        fileName: {
          value: fileName,
          type: 'enum',
          editable: false,
        },
        ...(selectorKey == 'clientDocuments'
          ? {
              clientName: {
                value: (
                  <a
                    onClick={e => {
                      e.stopPropagation();
                    }}
                    style={{ marginLeft: '0px' }}
                    target="blank"
                    href={absolutePath(`/clients/${clientId}`)}
                  >
                    {clientName}
                  </a>
                ),
                type: 'enum',
                editable: false,
              },
            }
          : null),
      },
    };

    return rowItem;
  };

  useEffect(() => {
    if (data) {
      const newRows = data.map(document => {
        return createRowItem({
          id: +document.id,
          fileName: document.attributes.file_file_name,
          ...(selectorKey == 'clientDocuments'
            ? {
                clientName: document.attributes.client_name,
                clientId: document.attributes.client_id,
              }
            : null),
          documentUrl: document.attributes.download_url,
        });
      });

      setRows(newRows);
    }
  }, [data]);

  return (
    <>
      <div>
        <Table
          stylesForButtonsAndIcons={styles.DocumentsTable}
          readOnly
          headers={ITEM_HEADERS}
          rows={rows}
          setRows={setRows}
          page={meta.page}
          pageCount={meta.page_count}
          totalRecords={meta.total_records}
          pageChangeCallback={newPageIndex => {
            dispatch(
              updateMetaData({
                page: newPageIndex,
              })
            );
            dispatch(getData(clientId));
          }}
        />
      </div>
      {showDeleteModal && (
        <DeleteConfirmation
          handleHide={() => setShowDeleteModal(false)}
          handleDelete={() => handleDocumentDelete(idForDelete)}
          resource="Document"
        />
      )}
      {showPreviewModal && (
        <DocumentPreviewModal
          previewId={previewId}
          handleHide={() => {
            setShowPreviewModal(false);
            setPreviewId(null);
          }}
        />
      )}
    </>
  );
};

export default DocumentsTable;
