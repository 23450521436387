import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styles from './BookingHeader.module.scss';
import { ProgressBar } from '~ui';

const BookingHeader = ({ BOOKING_PATHS, studio, uuid, bookingCalendar }) => {
  const { pathname } = useLocation();
  const { contract_required, invoice_required, client_id } = useSelector(
    state => state.customerBooking.bookingCalendar
  );

  const steps = [
    {
      label: 'Select a Time',
      active: pathname === BOOKING_PATHS.root(uuid),
    },

    ...(!client_id
      ? [
          {
            label: 'Your Info',
            active: pathname === BOOKING_PATHS.info(uuid),
          },
        ]
      : []),

    ...(contract_required
      ? [
          {
            label: 'Sign Contract',
            active: pathname === BOOKING_PATHS.contract(uuid),
          },
        ]
      : []),
    ...(invoice_required
      ? [
          {
            label: 'Pay Invoice',
            active: pathname === BOOKING_PATHS.invoice(uuid),
          },
        ]
      : []),
    {
      label: 'Confirmation',
      active: pathname === BOOKING_PATHS.confirm(uuid),
    },
  ];

  return (
    <div className={styles.BookingHeader}>
      <img src={studio.logo} className={styles['BookingHeader-studioLogo']} />
      <h1>{bookingCalendar.title}</h1>
      <div className={styles['BookingHeader-progressBar']}>
        <ProgressBar steps={steps} />
      </div>
    </div>
  );
};

export default BookingHeader;
