/**
 * The time slots rendered on the calendar are always 40 pixels tall.
 * In other words, each hour is 40 pixels.
 */
export const TIME_SLOT_HEIGHT = 40;

/**
 * The events on the calendar all have a height of 22 px, except for the
 * events rendered on the CalendarEventPane.
 */
export const EVENT_HEIGHT = 22;

/**
 * We use this on the calendar to render a time slot for each hour. There's
 * two empty strings for All Day and 12 am, which we do not render. It helps
 * with spacing on the week view.
 */
export const HOURS = [
  '',
  '1 AM',
  '2 AM',
  '3 AM',
  '4 AM',
  '5 AM',
  '6 AM',
  '7 AM',
  '8 AM',
  '9 AM',
  '10 AM',
  '11 AM',
  '12 PM',
  '1 PM',
  '2 PM',
  '3 PM',
  '4 PM',
  '5 PM',
  '6 PM',
  '7 PM',
  '8 PM',
  '9 PM',
  '10 PM',
  '11 PM',
];

export const DEFAULT_EVENTS = [...Array(24).keys()].reduce(
  (acc, i) => {
    let hour = ('0' + i).slice(-2); // Prepend '0' to digits less than 10.
    acc[hour] = [];
    return acc;
  },
  { all_day: [] }
);
