import React, { useState, useEffect } from 'react';
import styles from './CalendarConnect.module.scss';
import { TextInput, Button, TableNew as Table } from '~ui';
import axios from 'axios';
import moment from 'moment-timezone';
import ConnectConfirmationModal from './ConnectConfirmationModal';

const CalendarConnect = props => {
  const { calendarFeedURL, calendarFeedURL2 } = props;

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [captureCopyText, setCaptureCopyText] = useState('Copy');
  const [connectedCalendar, setConnectedCalendar] = useState(null);
  const [rows, setRows] = useState([]);

  const cronofyProviderName = provider => {
    switch (provider) {
      case 'google':
        return 'Google';
      case 'apple':
        return 'Apple';
      case 'office365':
        return 'Office365';
      case 'exchange':
        return 'Microsoft Exchange';
      case 'live_connect':
        return 'Outlook.com';
      default:
        return 'Other';
    }
  };

  const cronofyCalendarStatus = (calendarId, syncCalenadarId) => {
    if (calendarId == syncCalenadarId) {
      return 'Sync';
    } else {
      return 'View';
    }
  };

  const grabCalendarData = async () => {
    const res = await axios.get('/api/cronofy_calendar');
    if (res) {
      const rows = res.data.calendars.map(calendar => {
        setConnectedCalendar({
          calName: cronofyProviderName(res.data.provider_name),
          syncDate: res.data.synced_at,
        });
        return createRowItem({
          calendarId: res.data.calendar_id,
          id: calendar.calendar_id,
          calendarName: calendar.calendar_name,
        });
      });
      setRows(rows);
    }
  };

  useEffect(() => {
    grabCalendarData();
  }, []);

  const authorize = async () => {
    try {
      const response = await axios.post('/api/cronofy_calendar');
      window.location.href = response.data.auth_link;
    } catch (error) {
      console.log(error);
    }
  };

  const deauthorize = async () => {
    await axios.delete('/api/cronofy_calendar');
    window.location.reload(true);
  };

  const HEADERS = ['Calendar', 'Status'];

  const createRowItem = ({ calendarId, id, calendarName }) => ({
    id,
    editable: false,
    deletable: false,
    data: {
      name: {
        value: calendarName,
        type: 'string',
        editable: false,
      },
      status: {
        value: cronofyCalendarStatus(calendarId, id),
        type: 'string',
        editable: false,
      },
    },
  });

  const syncCheck = () => {
    const syncDate = connectedCalendar.syncDate;
    let noSync = 'Calendar has not yet performed a full sync.';

    if (moment(syncDate).isValid()) {
      return `Calendar last sync'd at: ${moment(syncDate).format(
        'h:mm A M/D/YYYY'
      )}.`;
    } else {
      return noSync;
    }
  };

  return (
    <div className={styles.CalendarConnect}>
      <h1>
        <strong>Sync with External Calendar</strong>
      </h1>
      {rows.length === 0 && <Button text={'Connect'} onClick={authorize} />}
      {rows.length > 0 && (
        <>
          <Table
            readOnly
            title={
              <p>
                Iris Works is connected to your{' '}
                <strong>{connectedCalendar.calName}</strong> calendar.
              </p>
            }
            headers={HEADERS}
            rows={rows}
            setRows={setRows}
            loading={false}
          />
          <h5>{syncCheck()}</h5>
          <Button
            text={'Disconnect'}
            onClick={() => setShowConfirmation(true)}
          />
        </>
      )}
      <hr />
      <h1>
        <strong>Subscribe to My Calendar</strong>
      </h1>
      <p>
        <a style={{ color: '#7ec0e5' }} href={calendarFeedURL2}>
          Click here
        </a>{' '}
        to subscribe to your calendar in programs like Outlook or iCloud.
      </p>
      <p>
        Or, copy the link below and paste it into your calendar application:
      </p>
      <div className={styles['CalendarConnect-copyContainer']}>
        <TextInput disabled initialValue={calendarFeedURL} name="URL" />
        <Button
          text={captureCopyText}
          onClick={() => {
            navigator.clipboard.writeText(calendarFeedURL);
            setCaptureCopyText('Copied!');
            setTimeout(() => setCaptureCopyText('Copy'), 1500);
          }}
        />
      </div>
      <p>
        <strong>
          Notice: This is the private address for your calendar. Don't share
          this address with others unless you want them to see all the events on
          the calendar.
        </strong>
      </p>
      <br></br>
      <p>
        <strong>More Information:</strong>
      </p>
      <p>
        {' '}
        <a
          href="https://support.apple.com/kb/PH11523?locale=en_US"
          target="_blank"
          style={{ color: '#7ec0e5' }}
        >
          Subscribe to calendars in iCloud
        </a>
      </p>
      <p>
        {' '}
        <a
          href="https://support.office.com/en-in/article/View-and-subscribe-to-Internet-Calendars-f6248506-e144-4508-b658-c838b6067597#bm2"
          target="_blank"
          style={{ color: '#7ec0e5' }}
        >
          Add an Internet Calendar Subscription to Outlook
        </a>
      </p>
      {showConfirmation && (
        <ConnectConfirmationModal
          handleHide={setShowConfirmation}
          deauthorize={deauthorize}
        />
      )}
    </div>
  );
};

export default CalendarConnect;
