import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './CurrencyInput.module.scss';
import { getLocale } from '~utils/locale';
import { keyCodes } from '~constants/keyCodes';

const VALID_NEXT = /^[0-9]{1}$/;

const CurrencyInput = props => {
  const {
    disabled,
    hideIcon,
    initialValue,
    inline,
    labelText,
    characterLimit,
    max,
    name,
    onChangeCallback,
    required,
    currencyType,
  } = props;
  // auto-calculate number of characters based on `max` prop
  const hasCharacterLimit = !!characterLimit;
  const cx = classNames.bind(styles);
  const classes = cx('CurrencyInput', {
    'CurrencyInput--hideIcon': hideIcon,
    'CurrencyInput--disabled': disabled,
    'CurrencyInput--inline': inline,
    [`CurrencyInput--limit-${characterLimit}`]: hasCharacterLimit,
  });
  const [value, setValue] = useState(
    String(Number(String(initialValue || 0)).toFixed(2))
  );
  const handleKeyDown = useCallback(
    ({ key, keyCode }) => {
      let newValue;

      if (keyCode === keyCodes.DELETE) {
        newValue = Number.parseFloat(value.substring(0, value.length - 1)) / 10;
      } else if (VALID_NEXT.test(key)) {
        newValue = Number.parseFloat(`${value}${key}`) * 10;
      } else {
        return;
      }

      const rounded = newValue.toFixed(2);
      if (rounded >= 0 && rounded <= max) {
        setValue(String(rounded));
        // onChangeCallback(String(rounded));
      }
    },
    [value]
  );
  const handleChange = useCallback(() => {}, []);
  const valueDisplay = Number.parseFloat(value, 10)
    .toLocaleString(getLocale(), {
      style: 'currency',
      currency: window.app.studio.currency || currencyType,
    })
    .replace(/[a-zA-Z$£€]/g, '');

  return (
    <div className={classes}>
      {labelText && (
        <label htmlFor={name}>
          {labelText}
          {required && <span>*</span>}
        </label>
      )}
      {!hideIcon && (
        <span className={styles['CurrencyInput-currency']}>
          {window.app.invoices.currency}
        </span>
      )}
      <input
        inputMode="numeric"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={() => onChangeCallback(value)}
        value={valueDisplay}
        required={required}
        disabled={disabled}
        name={name}
      />
    </div>
  );
};

CurrencyInput.propTypes = {
  disabled: PropTypes.bool,
  hideIcon: PropTypes.bool,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inline: PropTypes.bool,
  labelText: PropTypes.string,
  max: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onChangeCallback: PropTypes.func,
  required: PropTypes.bool,
};

CurrencyInput.defaultProps = {
  disabled: false,
  hideIcon: false,
  initialValue: '0.00',
  inline: false,
  labelText: undefined,
  onChangeCallback: () => {},
  required: false,
};

export default CurrencyInput;
