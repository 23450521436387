import React, { useState } from 'react';
import { Modal, Button, Dropdown } from '~ui';
import TinyMceInput from '~components/common/TinyMceInput';
import styles from './SendLocationModal.module.scss';
import axios from 'axios';
import { absolutePath } from '~constants/routes';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';

const SendLocationModal = ({
  handleHide,
  locationToSend,
  clients,
  currentStudio,
}) => {
  const [selectedClientId, setSelectedClientId] = useState();
  const locationURL = absolutePath(
    `/customer/locations/${locationToSend.uuid}`
  );
  const locationHTML = `
  <h1>Hello,</h1>
  <p>${currentStudio.name} has sent you a location:</p>
  <p><strong>${locationToSend.title}:</strong> <a href="${locationURL}">${locationURL}</a></p>
  <p>Thanks!</p>
  `;

  const [emailBody, setEmailBody] = useState(locationHTML);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (selectedClientId === undefined) return;

    const params = {
      id: locationToSend.id,
      client_id: selectedClientId,
      message: emailBody,
    };

    try {
      setLoading(true);
      await axios.put(`/api/locations/${locationToSend.id}/send_it`, params);
      setLoading(false);
      handleHide();
      toast.success('Location Sent!', toastOptions);
    } catch (error) {
      if (error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Location send failed.', toastOptions);
      }
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <Modal handleHide={handleHide} title="Send Location">
      <div className={styles.Wrapper}>
        <Dropdown
          name="recipient list"
          labelText="Send to"
          options={clients}
          onChangeCallback={value => {
            setSelectedClientId(value);
          }}
        />
      </div>
      <div className={styles.Wrapper}>
        <TinyMceInput
          labelText="Message"
          value={emailBody}
          onChangeCallback={setEmailBody}
        />
      </div>
      <div className={styles['SendLocationModal-footer']}>
        <div className={styles['SendLocationModal-ButtonGroup']}>
          <Button danger onClick={handleHide} text="Close" />
          <Button
            onClick={handleSubmit}
            text={loading ? 'Loading' : 'Send'}
            disabled={!selectedClientId || loading}
          />
        </div>
      </div>
    </Modal>
  );
};
export default SendLocationModal;
