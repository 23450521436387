import React from 'react';
import styles from './LogoLayout/LogoLayout.module.scss';
import classnames from 'classnames/bind';

const SessionTypeIcon = props => {
  const { st, chosenSessionTypeIds, handleSessionType } = props;

  const cx = classnames.bind(styles);
  const iconClasses = cx('LogoLayout-sessionTypes-icons-icon', {
    'LogoLayout-sessionTypes-icons-icon-selected': chosenSessionTypeIds.includes(
      st.id
    ),
  });
  return (
    <div
      onClick={() => handleSessionType(st)}
      key={st.id}
      className={iconClasses}
    >
      <div>
        <img className={styles['SessionTypes-icon']} src={st.icon} />
      </div>
      <p>{st.name}</p>
    </div>
  );
};

export default SessionTypeIcon;
