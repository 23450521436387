import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from '~utils';
import { Icon } from '~ui';
import styles from './Accordion.module.scss';

const Accordion = ({
  children,
  expanded,
  title,
  disabled,
  disabledMessage,
  preventClose,
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const [showDisabledMessage, setShowDisabledMessage] = useState(false);
  const uniqueButtonId = uniqueId('accordionButton_');
  const uniquePanelId = uniqueId('accordionPanel_');

  useEffect(() => {
    if (disabled === false) {
      setShowDisabledMessage(false);
    }
  }, [disabled]);

  useEffect(() => {
    if (!preventClose) {
      setIsExpanded(expanded);
    }
  }, [children]);

  return (
    <div className={styles.Accordion}>
      <h2 className={styles.AccordionHeader}>
        <button
          id={uniqueButtonId}
          aria-expanded={isExpanded}
          aria-controls={uniquePanelId}
          onClick={() => {
            if (disabled) {
              setShowDisabledMessage(true);
            } else {
              setIsExpanded(!isExpanded);
            }
          }}
        >
          <span>
            <Icon name={isExpanded ? 'angle-down' : 'angle-right'} small />
          </span>
          <span>{title}</span>
          {showDisabledMessage && <span>{disabledMessage}</span>}
        </button>
      </h2>
      {isExpanded && (
        <div
          className={styles.AccordionPanel}
          id={uniquePanelId}
          role="region"
          aria-labelledby={uniqueButtonId}
        >
          {children}
        </div>
      )}
    </div>
  );
};

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

Accordion.defaultProps = {
  expanded: false,
};

export default Accordion;
