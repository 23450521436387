import { CHARGES } from '~constants/actionTypes';

export default function(state = {}, action) {
  switch (action.type) {
    case CHARGES.GET.SUCCESS:
      return {
        ...state,
        charges: {
          page: action.payload.page,
          pageCount: action.payload.pageCount,
          totalRecords: action.payload.totalRecords,
          items: action.payload.charges,
        },
      };
    default:
      return state;
  }
}
