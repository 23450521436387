import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TableNew as Table, TextInput, TextArea, Button, Dropdown } from '~ui';
import { routes, absolutePath } from '~constants/routes';
import AddPaymentScheduleModal from './AddPaymentScheduleTemplateModal';
import InstallmentTemplatesTable from './InstallmentTemplatesTable';
import InvoiceTotals from '../Invoice/InvoiceTotals';
import styles from './StudioInvoiceTemplate.module.scss';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { uniqueId } from '~utils';

const HEADERS = ['Product', 'Price', 'Quantity', 'Total', 'Taxable'];

const StudioInvoiceTemplate = ({
  productDropdownOptions,
  invoiceProducts,
  itemTemplates,
  template,
  studioId,
  studioTax,
}) => {
  const [intervalError, setIntervalError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [name, setName] = useState(template ? template.name : '');
  const [memo, setMemo] = useState(template ? template.memo : '');

  const firstProduct = invoiceProducts[0];
  const items = itemTemplates || [];

  const [invoiceTemplate, setInvoiceTemplate] = useState(
    template || {
      studio_id: studioId,
      totals: { subtotal: 0, tax: 0, discount: 0, taxable_subtotal: 0 },
      tax_enabled: !!studioTax,
      paid_amount: 0,
      has_discount: false,
      percent_based_discount: false,
      discount_amount: 0,
      tax_before_discount: false,
      tax_amount: studioTax ? studioTax : 0,
      paid_in_full: false,
      total: 0,
      schedule_trigger: 'WhenReceived',
      schedule_time_offset: 1,
    }
  );

  const [paymentScheduleTemplate, setPaymentScheduleTemplate] = useState(
    template ? template.payment_schedule_template : null
  );
  const [errors, setErrors] = useState([]);
  const [showPaymentScheduleModal, setShowPaymentScheduleModal] = useState(
    false
  );

  const handlePriceChange = (rowIndex, value) => {
    const tempRows = rows;

    if (tempRows.length > 0) {
      const rowItem = tempRows[rowIndex];
      const { data } = rowItem;

      const price = value;
      const quantity = data.quantity.value;

      const total = price * quantity;

      data.price.value = price;
      data.total.value = total;

      tempRows[rowIndex] = rowItem;

      setRows([...tempRows]);
    }
  };

  const handleQuantityChange = (rowIndex, value) => {
    const tempRows = rows;

    if (value < 1) {
      toast.error('Please enter a number greater than 0', toastOptions);
      return;
    }

    if (tempRows.length > 0) {
      const rowItem = tempRows[rowIndex];
      const { data } = rowItem;

      const price = +data.price.value;
      const total = price * value;

      data.total.value = total;
      data.quantity.value = value;

      tempRows[rowIndex] = rowItem;

      setRows([...tempRows]);
    }
  };

  const handleProductSelectChange = (rowIndex, value) => {
    const selectedInvoiceProduct = invoiceProducts.find(invoiceProduct => {
      return +invoiceProduct.id === +value;
    });

    if (!selectedInvoiceProduct) {
      return;
    }

    const tempRows = rows;
    const rowItem = rows[rowIndex];

    rowItem.data.invoice_product_id.value = +selectedInvoiceProduct.id;
    rowItem.data.price.value = +selectedInvoiceProduct.price;
    rowItem.data.total.value =
      +rowItem.data.quantity.value * selectedInvoiceProduct.price;
    rowItem.data.taxable.value = !!selectedInvoiceProduct.taxable;

    tempRows[rowIndex] = rowItem;

    setRows([...tempRows]);
  };

  const editFunctions = [
    {
      name: 'invoice_product_id',
      fn: handleProductSelectChange,
    },
    {
      name: 'quantity',
      fn: handleQuantityChange,
    },
  ];

  const createRowItem = (
    id = null,
    productId,
    productPrice,
    productQuantity,
    productTaxable,
    temp = false
  ) => {
    return {
      id,
      editable: true,
      deletable: true,
      temp: temp,
      data: {
        invoice_product_id: {
          value: +productId,
          type: 'enum',
          options: productDropdownOptions,
          editable: true,
        },
        price: {
          value: +productPrice,
          type: 'currency',
          editable: false,
          disabled: true,
        },
        quantity: {
          value: productQuantity,
          type: 'number',
          editable: true,
          onChangeCallback: val => {
            handleQuantityChange(index, val);
          },
          min: 1,
        },
        total: {
          value: +productPrice * +productQuantity,
          type: 'currency',
          editable: false,
          disabled: true,
        },
        taxable: {
          value: productTaxable,
          type: 'boolean',
          editable: false,
        },
      },
    };
  };

  const mapItemsToRows = () => {
    return items.map(item => {
      const { id, invoice_product_id, price, taxable, quantity } = item;

      return createRowItem(id, invoice_product_id, price, quantity, taxable);
    });
  };

  const [rows, setRows] = useState([]);
  const [schema, setSchema] = useState(
    createRowItem(
      rows.length,
      firstProduct.invoice_product_id,
      firstProduct.price,
      1,
      firstProduct.taxable
    )
  );

  useEffect(() => {
    setRows(mapItemsToRows);
  }, []);

  useEffect(() => {
    const newSchema = createRowItem(
      null,
      firstProduct.id,
      firstProduct.price,
      1,
      firstProduct.taxable
    );
    setSchema(newSchema);
  }, [rows]);

  useEffect(() => {
    if (rows.length > 0) {
      const subtotal = rows.reduce((acc, row) => acc + row.data.total.value, 0);

      const taxableSubtotal = rows.reduce((acc, row) => {
        if (row.data.taxable.value) {
          return acc + row.data.total.value;
        }
        return acc;
      }, 0);

      setInvoiceTemplate({
        ...invoiceTemplate,
        totals: {
          ...invoiceTemplate.totals,
          subtotal: subtotal,
          taxable_subtotal: taxableSubtotal,
        },
      });
    }
  }, [rows, paymentScheduleTemplate]);

  useEffect(() => {
    calculateInvoiceTotals();
  }, [
    invoiceTemplate.totals.subtotal,
    invoiceTemplate.tax_amount,
    invoiceTemplate.discount_amount,
    invoiceTemplate.tax_before_discount,
    invoiceTemplate.totals.taxable_subtotal,
  ]);

  const calculateInvoiceTotals = () => {
    const {
      tax_amount,
      discount_amount,
      percent_based_discount,
      tax_before_discount,
    } = invoiceTemplate;
    const { subtotal, taxable_subtotal } = invoiceTemplate.totals;

    const discountFigure = percent_based_discount
      ? +discount_amount * +subtotal
      : +discount_amount;

    const roundToHundredth = value => {
      //Using exponential notation to avoid floating point inconsistency
      return Number(Math.round(value + 'e2') + 'e-2');
    };

    if (tax_before_discount) {
      const taxTotal = +taxable_subtotal * +tax_amount;
      const totalAfterTaxBeforeDiscount = +subtotal + +taxTotal;
      const newTotal = +totalAfterTaxBeforeDiscount - +discountFigure;
      const roundedTotal = roundToHundredth(+newTotal);

      setInvoiceTemplate({
        ...invoiceTemplate,
        total: roundedTotal,
        totals: {
          ...invoiceTemplate.totals,
          tax: taxTotal,
          discount: discountFigure,
        },
      });
    } else {
      const totalAfterDiscount = +subtotal - +discountFigure;
      const discountPriceOfTaxableItems =
        (+totalAfterDiscount * +taxable_subtotal) / +subtotal;
      const taxTotal = +tax_amount * discountPriceOfTaxableItems;
      const newTotal = +totalAfterDiscount + +taxTotal;
      const roundedTotal = roundToHundredth(+newTotal);

      setInvoiceTemplate({
        ...invoiceTemplate,
        total: roundedTotal,
        totals: {
          ...invoiceTemplate.totals,
          tax: taxTotal,
          discount: discountFigure,
        },
      });
    }
  };

  const rowsToSavableItems = () => {
    // there is definitely a more optimal way to do this
    const newRows = rows.filter(row => !row.id && !row.temp);
    const existingItems = rows.filter(row => row.id);

    const parsedExistingItems = existingItems.map(row => {
      const {
        id,
        data: { invoice_product_id, price, quantity, taxable },
      } = row;

      const updatedItem = items.find(item => +item.id === +id);

      updatedItem.invoice_product_id = invoice_product_id.value;
      updatedItem.price = price.value;
      updatedItem.quantity = quantity.value;
      updatedItem.taxable = taxable.value;

      return updatedItem;
    });

    const deletedItems = items.filter(
      item => !parsedExistingItems.find(row => +row.id === +item.id)
    );

    deletedItems.map(item => (item['_destroy'] = true));

    const parsedNewRows = newRows.map(row => {
      const {
        data: { invoice_product_id, price, quantity, taxable, total },
      } = row;

      return {
        invoiceTemplateName: name,
        invoice_product_id: invoice_product_id.value,
        invoice_template_memo: memo,
        price: price.value,
        quantity: quantity.value,
        taxable: taxable.value,
        total: total.value,
      };
    });

    return [...parsedExistingItems, ...parsedNewRows, ...deletedItems];
  };

  function lineItemsPresent(array) {
    const filteredArray = array.filter(obj => !obj._destroy);
    if (filteredArray.length === 0) {
      return false;
    }
    return true;
  }

  const handleSave = async () => {
    if (!name) {
      setErrors(['Name is required']);
      return;
    }

    const savableItems = rowsToSavableItems();

    if (!lineItemsPresent(savableItems)) {
      toast.error('Please add a line item', toastOptions);
      return;
    }

    const params = {
      invoice_template: {
        schedule_time_offset: showOffset
          ? invoiceTemplate.schedule_time_offset
          : 0,
        schedule_trigger: invoiceTemplate.schedule_trigger,
        name: name,
        memo: memo,
        has_discount: invoiceTemplate.has_discount,
        discount_amount: invoiceTemplate.discount_amount,
        percent_based_discount: invoiceTemplate.percent_based_discount,
        tax_enabled: invoiceTemplate.tax_enabled,
        tax_amount: invoiceTemplate.tax_amount,
        tax_before_discount: invoiceTemplate.tax_before_discount,
        invoice_line_item_templates_attributes: savableItems,
        payment_schedule_template_id: paymentScheduleTemplate
          ? paymentScheduleTemplate.id
          : null,
      },
    };

    const urlMethod = template
      ? {
          url: routes.INVOICE_TEMPLATES.UPDATE(invoiceTemplate.id),
          method: 'put',
        }
      : { url: routes.INVOICE_TEMPLATES.CREATE, method: 'post' };

    try {
      const { url, method } = urlMethod;
      const res = await axios[method](url, params);

      if (res.status < 300) {
        window.location.href = absolutePath('/studio/invoice_templates');
      }
    } catch (err) {
      setErrors(err.response.data.error.messages);
    }
  };

  const calculateScheduleIntervalInitialValue = () => {
    if (template) {
      if (template.schedule_time_offset % 365 === 0) {
        return { interval: 365, offset: template.schedule_time_offset / 365 };
      } else if (template.schedule_time_offset % 30 === 0) {
        return { interval: 30, offset: template.schedule_time_offset / 30 };
      } else if (template.schedule_time_offset % 7 === 0) {
        return { interval: 7, offset: template.schedule_time_offset / 7 };
      } else {
        return { interval: 1, offset: template.schedule_time_offset };
      }
    } else {
      return { interval: 1, offset: 1 };
    }
  };

  const [scheduleInterval, setScheduleInterval] = useState(
    calculateScheduleIntervalInitialValue().interval
  );
  const [intervalMultiplier, setIntervalMultiplier] = useState(
    calculateScheduleIntervalInitialValue().offset
  );

  useEffect(() => {
    setInvoiceTemplate({
      ...invoiceTemplate,
      schedule_time_offset: showOffset
        ? +intervalMultiplier * +scheduleInterval
        : 0,
    });
  }, [scheduleInterval, intervalMultiplier, invoiceTemplate.schedule_trigger]);

  const showOffset =
    invoiceTemplate.schedule_trigger === 'BeforeSession' ||
    invoiceTemplate.schedule_trigger === 'AfterSession';

  const scheduleTriggerOptions = [
    { label: 'When Invoice is Issued', value: 'WhenReceived' },
    { label: 'On the Session Date', value: 'OnSessionDate' },
    { label: 'Before the Session', value: 'BeforeSession' },
    { label: 'After the Session', value: 'AfterSession' },
  ];

  return (
    <div className={styles['StudioInvoiceTemplate']}>
      <div className={styles['StudioInvoiceTemplate-header']}>
        <h1>{template ? 'Edit Invoice Template' : 'New Invoice Template'}</h1>
        {errors.length > 0 &&
          errors.map((err, index) => (
            <h2 key={uniqueId(`error_message${index}_`)}>{err}</h2>
          ))}
      </div>
      <div className={styles['StudioInvoiceTemplate-inputs']}>
        <TextInput
          name="invoiceTemplateName"
          labelText="Template name"
          initialValue={name}
          onChangeCallback={setName}
          required
        />
        <div className={styles['StudioInvoiceTemplate-scheduleContainer']}>
          <Dropdown
            labelText="First Payment Due"
            name="invoice_template_schedule_trigger"
            initialValue={invoiceTemplate.schedule_trigger}
            options={scheduleTriggerOptions}
            onChangeCallback={value => {
              setInvoiceTemplate({
                ...invoiceTemplate,
                schedule_trigger: value,
              });
            }}
          />

          {showOffset && (
            <>
              <div
                className={styles['StudioInvoiceTemplate-scheduleContainer-by']}
              >
                by
              </div>
              <TextInput
                name="intervalMultiplier"
                type="intervalMultiplier"
                onFocusSelectText
                characterLimit={2}
                initialValue={intervalMultiplier}
                onChangeCallback={value => {
                  setIntervalMultiplier(+value);
                }}
              />
              <Dropdown
                name="interval"
                initialValue={String(scheduleInterval)}
                onChangeCallback={value => {
                  setScheduleInterval(+value);
                }}
                options={[
                  { label: 'Day(s)', value: 1 },
                  { label: 'Week(s)', value: 7 },
                  { label: 'Month(s)', value: 30 },
                  { label: 'Year(s)', value: 365 },
                ]}
              />
            </>
          )}
        </div>
        <TextArea
          labelText="Memo"
          name="invoice_template_memo"
          initialValue={memo}
          onChangeCallback={setMemo}
        />
      </div>
      <div className={styles['StudioInvoiceTemplate-table']}>
        <Table
          editFunctions={editFunctions}
          headers={HEADERS}
          rows={rows}
          schema={schema}
          setRows={setRows}
          title={'Line Items'}
          floatNewRowToTop
          floatToBottomAfterCreation
        />
      </div>
      <div className={styles['StudioInvoiceTemplate-totals']}>
        <InvoiceTotals
          invoice={invoiceTemplate}
          handleTaxDiscountSwap={() =>
            setInvoiceTemplate({
              ...invoiceTemplate,
              tax_before_discount: !invoiceTemplate.tax_before_discount,
            })
          }
          handleUpdateTax={async taxAmount => {
            setInvoiceTemplate({
              ...invoiceTemplate,
              tax_amount: taxAmount,
              tax_enabled: true,
            });
          }}
          handleDeleteTax={async () => {
            setInvoiceTemplate({
              ...invoiceTemplate,
              tax_enabled: false,
              tax_amount: 0.0,
              totals: { ...invoiceTemplate.totals, tax: 0 },
            });
          }}
          handleUpdateDiscount={async (discountAmount, discountIsPercent) => {
            setInvoiceTemplate({
              ...invoiceTemplate,
              has_discount: true,
              percent_based_discount: discountIsPercent,
              discount_amount: discountAmount,
            });
          }}
          handleDeleteDiscount={async () => {
            setInvoiceTemplate({
              ...invoiceTemplate,
              has_discount: false,
              percent_based_discount: 0.0,
              discount_amount: 0.0,
              totals: { ...invoiceTemplate.totals, discount: 0 },
            });
          }}
        />
      </div>
      <div>
        {paymentScheduleTemplate && (
          <InstallmentTemplatesTable
            invoice={invoiceTemplate}
            schedule={paymentScheduleTemplate}
            handleUpdateSchedule={setPaymentScheduleTemplate}
            setDisabled={setDisabled}
            setIntervalError={setIntervalError}
            intervalError={intervalError}
          />
        )}
      </div>

      <div className={styles['StudioInvoiceTemplate-buttons']}>
        {paymentScheduleTemplate ? (
          <Button
            text="Remove Payment Schedule"
            onClick={() => setPaymentScheduleTemplate(null)}
            white
          />
        ) : (
          <Button
            disabled={rows.length === 0}
            text="Add Payment Schedule"
            onClick={() => setShowPaymentScheduleModal(true)}
            white
          />
        )}
        <Button
          text="Cancel"
          onClick={() =>
            (window.location.href = absolutePath('/studio/invoice_templates'))
          }
          danger
        />

        <Button
          text="Save"
          onClick={handleSave}
          disabled={disabled || intervalError}
        />
      </div>
      {showPaymentScheduleModal && (
        <AddPaymentScheduleModal
          invoice={invoiceTemplate}
          handleHide={template => {
            setPaymentScheduleTemplate(template);
            setShowPaymentScheduleModal(false);
          }}
        />
      )}
    </div>
  );
};

export default StudioInvoiceTemplate;
