import React, { useContext } from 'react';
import styles from './EmailMarketingHeader.module.scss';
import { Button } from '~ui';
import { absolutePath } from '~constants/routes';
import EmailMarketingContext from '../EmailMarketingContext';

const EmailMarketingHeader = ({ setActiveTabKey }) => {
  const { statistics } = useContext(EmailMarketingContext);

  return (
    <>
      <div className={styles.EmailMarketingHeader}>
        <div className={styles['EmailMarketingHeader-title']}>
          <h1>Email Marketing Campaigns</h1>
          <div className={styles['EmailMarketingHeader-title-buttons']}>
            <Button
              text="New Campaign Template"
              icon="plus"
              onClick={() => {
                window.location.href = absolutePath('/email_marketing/new');
              }}
            />
          </div>
        </div>
        <div className={styles['EmailMarketingHeader-metrics']}>
          <div
            className={styles['EmailMarketingHeader-metrics-metricContainer']}
          >
            <p>Total Sends this Month</p>
            <h1>{statistics.totalSends}</h1>
          </div>
          <div
            className={styles['EmailMarketingHeader-metrics-metricContainer']}
          >
            <p>Open Rate this Month</p>
            <h1>{statistics.totalOpenRate}%</h1>
          </div>
          <div
            className={styles['EmailMarketingHeader-metrics-metricContainer']}
          >
            <p>Click Rate this Month</p>
            <h1>{statistics.totalClickRate}%</h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailMarketingHeader;
