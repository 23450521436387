import React, { useState, useEffect } from 'react';
import BookingCalendar from '~components/BookingCalendar';
import { Modal, Button } from '~ui';
import { useDispatch } from 'react-redux';
import { clearErrors } from '~actions/errors';
import styles from './CalendarRoute.module.scss';
import {
  selectAvailability,
  updateFooterOnContinue,
  updateFooterCurrentSelection,
  updateFooterContinueDisabled,
} from '~actions/customerBooking';
import moment from 'moment-timezone';

const CalendarRoute = props => {
  const { bookingCalendar, studio, firstAvailabilityDate } = props;
  const [showModal, setShowModal] = useState(true);
  const [selectedAvailability, setSelectedAvailability] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    window.sessionStorage.removeItem('TIMER_SECONDS');
    window.sessionStorage.removeItem('START_TIME');
    window.sessionStorage.removeItem('cameFromContractRoute');
    window.sessionStorage.removeItem('cameFromInvoiceRoute');
    window.sessionStorage.removeItem('cameFromConfirmRoute');
  }, []);

  const onContinue = async nextStep => {
    dispatch(clearErrors());
    await dispatch(selectAvailability(selectedAvailability));
    nextStep();
  };

  useEffect(() => {
    dispatch(updateFooterOnContinue(onContinue));
    dispatch(updateFooterCurrentSelection(selectedAvailability));
    dispatch(updateFooterContinueDisabled(!selectedAvailability));
  }, [selectedAvailability]);

  return (
    <>
      <div className={styles.CalendarRoute}>
        {showModal && (
          <Modal
            title={bookingCalendar.title}
            handleHide={() => setShowModal(false)}
          >
            <div className={styles['CalendarRoute-modal']}>
              <p style={{ whiteSpace: 'pre-line' }}>
                {bookingCalendar.introduction &&
                bookingCalendar.introduction.length > 0
                  ? bookingCalendar.introduction
                  : 'Click "Book Appointment!" to get started.'}
              </p>
              <div className={styles['CalendarRoute-modalButtons']}>
                <Button
                  text="Book Appointment!"
                  onClick={() => setShowModal(false)}
                />
              </div>
            </div>
          </Modal>
        )}
        <p>
          <em>All times are shown in {moment.tz.guess()} timezone</em>
        </p>
        <BookingCalendar
          calendarId={bookingCalendar.id}
          timeZone={studio.time_zone}
          onClickCallback={setSelectedAvailability}
          firstAvailabilityDate={firstAvailabilityDate}
        />
      </div>
    </>
  );
};
export default CalendarRoute;
