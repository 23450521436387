import React, { useState, useEffect, useRef, useCallback } from 'react';
import mapStyles from './mapStyles';
import styles from './InfoWindow.module.scss';
import { Button } from '~ui';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';

const LocationsMap = ({
  locations = false,
  mapLatLon,
  studioLatLon,
  markerIcon,
  loadError,
  isLoaded,
  setMapBounds,
  showShared,
}) => {
  const mapRef = useRef();

  const onMapLoad = useCallback(map => {
    mapRef.current = map;
    google.maps.event.addListener(mapRef.current, 'idle', mapBoundsChanged);
  }, []);

  const mapBoundsChanged = () => {
    if (mapRef.current) {
      setTimeout(() => setAreaOfBounds(mapRef.current.getBounds()), 1000);
    }
  };

  const [areaOfBounds, setAreaOfBounds] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  };

  const options = {
    styles: mapStyles,
  };

  let lat;
  let lng;

  const panTo = useCallback(
    ({ lat, lng }) => [
      mapRef.current.panTo({ lat, lng }),
      mapRef.current.setZoom(14),
    ],
    []
  );

  const studioAddressPanTo = useCallback(
    ({ lat, lng }) => [
      mapRef.current.panTo({ lat, lng }),
      mapRef.current.setZoom(4),
    ],
    []
  );

  const studioLatLonCheck = () => {
    if (!showShared) {
      if (studioLatLon === null) return;
      if (studioLatLon.latitude === 'No latitude' && locations.length < 1) {
        lat = Number(39.8283);
        lng = Number(-98.5795);
        studioAddressPanTo({ lat, lng });
        return;
      }
      if (locations.length === 1 && studioLatLon) {
        lat = Number(locations[0].latitude);
        lng = Number(locations[0].longitude);
        panTo({ lat, lng });
        return;
      }
      if (locations.length > 1 && studioLatLon) {
        const latLngArr = locations.map(location => {
          return {
            lat: Number(location.latitude),
            lng: Number(location.longitude),
          };
        });
        const bounds = new google.maps.LatLngBounds();
        latLngArr.map(location => {
          bounds.extend(location);
        });
        mapRef.current.fitBounds(bounds);
        return;
      }
      if (studioLatLon.latitude !== 'No latitude') {
        lat = Number(studioLatLon.latitude);
        lng = Number(studioLatLon.longitude);
        panTo({ lat, lng });
        return;
      }
    }
  };

  const mapLatLonCheck = () => {
    if (mapLatLon != null) {
      lat = Number(mapLatLon.latitude);
      lng = Number(mapLatLon.longitude);
      panTo({ lat, lng });
    }
  };

  useEffect(() => {
    if (isLoaded && showShared) {
      setMapBounds(areaOfBounds.toUrlValue());
    }
  }, [areaOfBounds]);

  useEffect(() => {
    if (showShared) {
      mapBoundsChanged();
    }
  }, [showShared]);

  useEffect(() => {
    if (isLoaded) {
      studioLatLonCheck();
    }
  }, [studioLatLon, locations]);

  useEffect(() => {
    if (isLoaded) {
      mapLatLonCheck();
    }
  }, [mapLatLon]);

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading maps';

  return (
    <>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        options={options}
        onLoad={onMapLoad}
      >
        {locations.map(location => (
          <Marker
            key={location.id}
            position={{
              lat: Number(location.latitude),
              lng: Number(location.longitude),
            }}
            icon={markerIcon}
            onClick={() => {
              setSelectedLocation(location);
              lat = Number(location.latitude);
              lng = Number(location.longitude);
              panTo({ lat, lng });
            }}
          />
        ))}
        {selectedLocation && (
          <InfoWindow
            position={{
              lat: Number(selectedLocation.latitude),
              lng: Number(selectedLocation.longitude),
            }}
            onCloseClick={() => {
              setSelectedLocation(null);
            }}
          >
            <div className={styles.InfoBox}>
              {selectedLocation.picture_url !==
                'missing-image-locations.jpg' && (
                <img
                  src={selectedLocation.picture_url}
                  alt="Location image"
                  width="auto"
                  height={100}
                ></img>
              )}
              <h3>{selectedLocation.title}</h3>
              <h4>{selectedLocation.address}</h4>
              <p>{selectedLocation.description}</p>
              <Button
                onClick={() => {
                  window
                    .open(
                      `https://maps.google.com?q=${selectedLocation.address},${selectedLocation.city},${selectedLocation.state}`,
                      '_blank'
                    )
                    .focus();
                }}
                text="Get directions"
              />
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </>
  );
};

export default LocationsMap;
