import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import WebpackerReact from 'webpacker-react';
import { combineProviders } from './combine_contexts';
import { withCatchComponent } from './catch_component';

class WebpackerReactPortalProvider extends React.Component {
  static propTypes = {
    consumers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        props: PropTypes.object.isRequired,
      })
    ).isRequired,
    providers: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        props: PropTypes.object.isRequired,
      })
    ).isRequired,
    registeredContexts: PropTypes.object,
  };

  constructor(props) {
    super(props);

    const providerProps = props.providers;
    if (!providerProps.length) {
      providerProps.push({ name: 'GlobalContext', props: {} });
    }

    const providers = this.props.providers.map(({ name, props }) => {
      const Context = this.props.registeredContexts[name];
      if (!Context) {
        console.error(`Context not registered: ${name}`);
        return;
      }
      return { Component: Context.Provider, props };
    });
    this.Provider = combineProviders(providers);
  }

  render() {
    const { registeredComponents } = WebpackerReact;
    const { consumers, providers } = this.props;
    const components = consumers.map(({ id, name, props }) => {
      const Component = registeredComponents[name];
      const element = React.createElement(Component, props);
      const domNode = document.getElementById(id);
      return ReactDOM.createPortal(withCatchComponent(element), domNode);
    });
    return <this.Provider>{components}</this.Provider>;
  }
}

export default WebpackerReactPortalProvider;

export const withRegisteredContexts = contexts => props => (
  <WebpackerReactPortalProvider {...props} registeredContexts={contexts} />
);
