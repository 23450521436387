import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from '~utils';
import TableCell from './TableCell';

const TableRow = props => {
  const {
    row,
    editing,
    handleUpdate,
    handleDelete,
    rowId,
    readOnly,
    invoiceTemplateScheduleTrigger,
    stringId,
  } = props;
  const { id, data } = row;

  // Since we can't set default props of nested props due to shallow comparison,
  // we have to set true as the default ourselves.
  const editable = row.editable === undefined ? true : row.editable;
  const deletable = row.deletable === undefined ? true : row.deletable;

  const [isEditing, setIsEditing] = useState(editing || row.isEditing);

  const renderCells = () =>
    data.slice(0, -1).map(cell => {
      const cellId = uniqueId('cell_');

      return (
        <TableCell
          key={cellId}
          cell={cell}
          isEditing={isEditing}
          rowId={id}
          invoiceTemplateScheduleTrigger={invoiceTemplateScheduleTrigger}
          stringId={stringId}
        />
      );
    });

  const getParams = () => {
    const inputs = document.querySelectorAll('input,textarea,select');

    return Array.from(inputs).reduce((acc, input) => {
      if (input.type === 'checkbox') {
        acc[input.name] = input.checked;
      } else {
        acc[input.name] = input.value;
      }

      return acc;
    }, {});
  };

  const renderEditButton = () => {
    if (!editable) {
      return;
    }

    const onClickCallback = isEditing
      ? () => {
          setIsEditing(false);
          handleUpdate(
            row.hasOwnProperty('rowIndex') ? row.rowIndex : id,
            getParams()
          );
        }
      : () => setIsEditing(true);

    return (
      <a onKeyPress={onClickCallback} onClick={onClickCallback}>
        <i className={`fa ${isEditing ? 'fa-check' : 'fa-pencil-alt'}`} />
      </a>
    );
  };

  const renderDeleteButton = () => {
    if (!deletable && !isEditing) {
      return;
    }

    const onClickCallback = isEditing
      ? () => setIsEditing(false)
      : () => handleDelete(row.hasOwnProperty('rowIndex') ? row.rowIndex : id);

    return (
      <a onKeyPress={onClickCallback} onClick={onClickCallback}>
        <i className={`fa ${isEditing ? 'fa-times' : 'fa-trash-alt'}`} />
      </a>
    );
  };

  return (
    <tr
      id={rowId}
      onDoubleClick={() => {
        if (!editable) {
          return;
        }
        setIsEditing(true);
      }}
    >
      {renderCells()}
      <>
        <TableCell
          cell={data[data.length - 1]}
          isEditing={isEditing}
          rowId={id}
          invoiceTemplateScheduleTrigger={invoiceTemplateScheduleTrigger}
          stringId={stringId}
        >
          {!readOnly && (
            <div className="ml-6 mn-w-94">
              {renderEditButton()}
              {renderDeleteButton()}
            </div>
          )}
        </TableCell>
      </>
    </tr>
  );
};

TableRow.propTypes = {
  editing: PropTypes.bool,
  row: PropTypes.shape({
    id: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.object),
    editable: PropTypes.bool,
    deletable: PropTypes.bool,
  }).isRequired,
  handleUpdate: PropTypes.func,
  handleDelete: PropTypes.func,
  readOnly: PropTypes.bool,
  rowId: PropTypes.string,
};

TableRow.defaultProps = {
  editing: false,
  handleUpdate: () => {},
  handleDelete: () => {},
};

export default TableRow;
