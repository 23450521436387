import React, { useState } from 'react';
import { Modal, TextInput, Dropdown, Button, Checkbox } from '~ui';
import TogglePopover from '~components/common/TogglePopover';
import { emailValidation, phoneValidation } from '~constants/validation';
import { addClient, getAllClients } from '~actions/clientList';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { absolutePath } from '~constants/routes';
import { toastOptions } from '~constants/toasts';
import styles from './NewLeadModal.module.scss';

const NewLeadModal = props => {
  const { studio_id, handleHide, questionnaires, currentPlan } = props;

  const [emailMarketing, setEmailMarketing] = useState(false);

  const handleChange = emailMarketing => {
    setEmailMarketing(emailMarketing);

    setNewLeadParams({
      ...newLeadParams,
      email_marketing: emailMarketing,
    });
  };

  const [newLeadParams, setNewLeadParams] = useState({
    studio_id: studio_id,
    is_lead: true,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    web_form_id: '',
    send_web_form: '',
    company_name: '',
    email_marketing: '',
  });

  let disabled =
    newLeadParams.first_name == '' ||
    newLeadParams.last_name == '' ||
    newLeadParams.email == '';

  let hasEmailMarketing = currentPlan.includes('mktg');

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const createRes = await dispatch(addClient(newLeadParams));

    if (createRes.errors) {
      toast.error(
        `Error creating lead. Please reach out to support.`,
        toastOptions
      );
    } else {
      dispatch(getAllClients());
      window.location.href = absolutePath(`/clients/${createRes.id}`);
    }
  };

  return (
    <Modal title={'New Lead'} handleHide={handleHide}>
      <div className={styles['NewLeadModal-row']}>
        <div>
          <TextInput
            name="name"
            labelText="First Name"
            initialValue={''}
            lightGrey={true}
            required
            onChangeCallback={val =>
              setNewLeadParams({
                ...newLeadParams,
                first_name: val,
              })
            }
          />
        </div>
        <div>
          <TextInput
            name="name"
            labelText="Last Name"
            initialValue={''}
            lightGrey={true}
            required
            onChangeCallback={val =>
              setNewLeadParams({
                ...newLeadParams,
                last_name: val,
              })
            }
          />
        </div>
      </div>
      <div className={styles['NewLeadModal-row']}>
        <div>
          <TextInput
            name="email"
            labelText="Email"
            initialValue={''}
            validation={emailValidation}
            lightGrey={true}
            required
            onChangeCallback={val =>
              setNewLeadParams({
                ...newLeadParams,
                email: val,
              })
            }
          />
        </div>
      </div>
      <div className={styles['NewLeadModal-row']}>
        <TextInput
          name="phone"
          labelText={'Phone'}
          initialValue={''}
          validation={phoneValidation}
          lightGrey={true}
          onChangeCallback={val =>
            setNewLeadParams({
              ...newLeadParams,
              phone: val,
            })
          }
        />
      </div>
      <div className={styles['NewLeadModal-row']}>
        <TextInput
          name="companyName"
          labelText={'Company Name'}
          initialValue={''}
          lightGrey={true}
          onChangeCallback={val =>
            setNewLeadParams({
              ...newLeadParams,
              company_name: val,
            })
          }
        />
      </div>
      <div className={styles['NewLeadModal-row']}>
        <Dropdown
          name="questionnaire"
          labelText="Send Questionnaire?"
          lightGrey={true}
          options={questionnaires}
          onChangeCallback={val =>
            setNewLeadParams({
              ...newLeadParams,
              web_form_id: val,
              send_web_form: '1',
            })
          }
        />
      </div>
      {hasEmailMarketing && (
        <div className={styles['NewLeadModal-rowWithToggle']}>
          <Checkbox
            name="emailMarketing"
            labelText="Enroll lead in email marketing?"
            lightGrey={true}
            onChangeCallback={handleChange}
          />
          <TogglePopover
            activator={<i className="fas fa-info-circle" />}
            popoverContent={
              <div style={{ height: '60px' }}>
                By checking this box, you are confirming you have the lead's
                permission to send them promotional emails.
              </div>
            }
          />
        </div>
      )}
      <div className={styles['NewLeadModal-buttons']}>
        <Button
          text="Cancel"
          onClick={() => {
            handleHide();
          }}
          whiteRectangle={true}
        />
        <Button
          onClick={handleSubmit}
          text="Save"
          purpleRectangle={true}
          disabled={disabled}
        />
      </div>
    </Modal>
  );
};

export default NewLeadModal;
