import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js/pure';
import axios from 'axios';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { Button, Dropdown, Modal, TextInput, SearchInput } from '~ui';
import styles from './EditBillingDetailsModal.module.scss';
import { absolutePath } from '~constants/routes';

const stripeCardElementOptions = {
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const EditBillingDetailsModal = ({ handleHide }) => {
  const { stripePublicKey, currentSubscription } = useSelector(
    state => state.subscription
  );
  const [stripePromise, setStripePromise] = useState();
  const [stripe, setStripe] = useState();
  const [disabled, setDisabled] = useState(false);

  const [firstName, setFirstName] = useState(
    currentSubscription.billing_first_name
  );
  const [lastName, setLastName] = useState(
    currentSubscription.billing_last_name
  );
  const [addressLine1, setAddressLine1] = useState(
    currentSubscription.billing_address_1
  );
  const [addressLine2, setAddressLine2] = useState(
    currentSubscription.billing_address_2 || ''
  );
  const [country, setCountry] = useState(currentSubscription.billing_country);
  const [stateOptions, setStateOptions] = useState([]);
  const [stateProvince, setStateProvince] = useState(
    currentSubscription.billing_state
  );
  const [city, setCity] = useState(currentSubscription.billing_city);
  const [zipPostalCode, setZipPostalCode] = useState(
    currentSubscription.billing_postal
  );
  const [card, setCard] = useState();

  useEffect(() => {
    setStripePromise(loadStripe(stripePublicKey));
  }, []);

  // Test card number 4060 3203 9137 2332
  const handleSubmit = () => {
    if (
      !firstName ||
      !lastName ||
      !addressLine1 ||
      !country ||
      (stateOptions.length > 0 && !stateProvince) ||
      !zipPostalCode ||
      !city
    ) {
      toast.error('Please provide all billing information', toastOptions);
      return;
    }

    const stripeCreateTokenParams = {
      name: `${firstName} ${lastName}`,
      address_line1: addressLine1,
      address_line2: addressLine2,
      address_city: city,
      address_state: stateProvince,
      address_zip: zipPostalCode,
      address_country: country,
    };

    const editSubscriptionParams = {
      billing_first_name: firstName,
      billing_last_name: lastName,
      billing_address_1: addressLine1,
      billing_address_2: addressLine2,
      billing_city: city,
      billing_state: stateProvince,
      billing_country: country,
      billing_postal: zipPostalCode,
    };

    const person = {
      address: {
        line1: addressLine1,
        line2: addressLine2,
        city: city,
        state: stateProvince,
        country: country,
        postal_code: zipPostalCode,
      },
      first_name: firstName,
      last_name: lastName,
    };

    if (card === undefined) {
      stripe.createToken('person', person).then(result => {
        if (result.error) {
          const errorMessage = `Error creating Stripe token: ${result.error.message}`;
          toast.error(errorMessage, toastOptions);
          Rollbar.error(`${errorMessage}: ${result.error}`);
        } else {
          setDisabled(true);
          axios
            .put(`/api/subscriptions/${currentSubscription.id}`, {
              subscription: {
                billing_update: {
                  stripe_token: result.token.id,
                  ...editSubscriptionParams,
                },
              },
            })
            .then(() => {
              window.location.href = absolutePath('/studio/subscription');
            })
            .catch(err => {
              setDisabled(false);
              const errorMessage =
                'Unable to create subscription, please contact support';
              toast.error(errorMessage, toastOptions);
              Rollbar.error(`${errorMessage}: ${err}`);
            });
        }
      });
      return;
    } else {
      stripe.createToken(card, stripeCreateTokenParams).then(result => {
        if (result.error) {
          const errorMessage = `Error creating Stripe token: ${result.error.message}`;
          toast.error(errorMessage, toastOptions);
          Rollbar.error(`${errorMessage}: ${result.error}`);
        } else {
          setDisabled(true);
          axios
            .put(`/api/subscriptions/${currentSubscription.id}`, {
              subscription: {
                billing: {
                  stripe_token: result.token.id,
                  ...editSubscriptionParams,
                },
              },
            })
            .then(() => {
              window.location.href = absolutePath('/studio/subscription');
            })
            .catch(err => {
              setDisabled(false);
              const errorMessage =
                'Unable to create subscription, please contact support';
              toast.error(errorMessage, toastOptions);
              Rollbar.error(`${errorMessage}: ${err}`);
            });
        }
      });
      return;
    }
  };

  const handleCountryDropdownChange = alpha2 => {
    setCountry(alpha2);
    axios
      .get(`/api/states/${alpha2}`)
      .then(res => {
        let options = res.data.map(state => ({
          label: state[1],
          value: state[0],
        }));
        options = [{ label: 'Choose a State/Province', value: null }].concat(
          options
        );
        setStateOptions(options);
      })
      .catch(error => {
        const errorMessage = `Error fetching states for ${alpha2}: ${error.response.data.error.messages}`;
        toast.error(errorMessage, toastOptions);
        Rollbar.error(`${errorMessage}: ${error}`);
      });
  };

  useEffect(() => {
    if (country) {
      handleCountryDropdownChange(country);
    }
  }, []);

  return (
    <Modal title="Edit Billing Details" handleHide={handleHide}>
      <div className={styles['EditBillingDetailsModal-body']}>
        {stripePromise ? (
          <Elements stripe={stripePromise}>
            <EditBillingDetailsForm
              setStripe={setStripe}
              handleCountryDropdownChange={handleCountryDropdownChange}
              formData={{
                firstName,
                setFirstName,
                lastName,
                setLastName,
                addressLine1,
                setAddressLine1,
                addressLine2,
                setAddressLine2,
                country,
                setCountry,
                stateOptions,
                setStateOptions,
                stateProvince,
                setStateProvince,
                city,
                setCity,
                zipPostalCode,
                setZipPostalCode,
                card,
                setCard,
              }}
            />
          </Elements>
        ) : (
          <span className="Spinner" />
        )}
      </div>
      <div className={styles['EditBillingDetailsModal-footer']}>
        <Button danger text="Cancel" onClick={handleHide} />
        <Button
          text={disabled ? 'Updating' : 'Update Details'}
          disabled={disabled}
          onClick={handleSubmit}
        />
      </div>
    </Modal>
  );
};

// This needs to be separate because the usage of Stripe hooks must be
// within the <Elements> component.
const EditBillingDetailsForm = ({
  formData,
  setStripe,
  handleCountryDropdownChange,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(true);

  const { countryOptions } = useSelector(state => state.subscription);

  useEffect(() => {
    if (stripe && elements) {
      setLoading(false);
      setStripe(stripe);
    }
  }, [stripe, elements]);

  if (loading) {
    return <span className="Spinner" />;
  }

  return (
    <form>
      <div>
        <label>Card Details</label>
        <fieldset className={styles.StripeFieldset}>
          <CardElement
            options={stripeCardElementOptions}
            onBlur={() => {
              formData.setCard(elements.getElement('card'));
            }}
          />
        </fieldset>
      </div>
      <div>
        <TextInput
          name="first_name"
          labelText="First Name"
          initialValue={formData.firstName}
          onChangeCallback={formData.setFirstName}
          required
        />
        <TextInput
          name="last_name"
          labelText="Last Name"
          initialValue={formData.lastName}
          onChangeCallback={formData.setLastName}
          required
        />
      </div>
      <div>
        <TextInput
          name="address_line_1"
          labelText="Address Line 1"
          initialValue={formData.addressLine1}
          onChangeCallback={formData.setAddressLine1}
          required
        />
        <TextInput
          name="address_line_2"
          labelText="Address Line 2"
          initialValue={formData.addressLine2}
          onChangeCallback={formData.setAddressLine2}
        />
      </div>
      <div>
        <Dropdown
          name="country"
          labelText="Country"
          options={countryOptions}
          initialValue={formData.country}
          onChangeCallback={handleCountryDropdownChange}
          required
        />
        <Dropdown
          name="state_province"
          labelText="State/Province"
          options={formData.stateOptions}
          initialValue={formData.stateProvince}
          onChangeCallback={formData.setStateProvince}
          disabled={formData.stateOptions.length === 0}
          required={formData.stateOptions.length > 0}
        />
      </div>
      <div>
        <TextInput
          name="city"
          labelText="City"
          initialValue={formData.city}
          onChangeCallback={formData.setCity}
          required
        />
        <TextInput
          name="zip_postal_code"
          labelText="Zip/Postal Code"
          initialValue={formData.zipPostalCode}
          onChangeCallback={formData.setZipPostalCode}
          required
        />
      </div>
    </form>
  );
};

export default EditBillingDetailsModal;
