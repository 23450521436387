import React from 'react';
import { Modal, Button } from '~ui';

const CannotDeleteJourneyTemplateModal = ({
  handleHide,
  incompleteJourneysArray,
}) => {
  return (
    <Modal title="Cannot Delete Active Workflow" handleHide={handleHide}>
      <div className="mb-8">
        This workflow cannot be deleted because it is currently being used on
        the following events:
      </div>
      {incompleteJourneysArray.map((journey, index) => {
        return <div key={index}>{journey.title}</div>;
      })}
      <br />
      <div className="d-fr">
        <Button className="mr-8" text="Close" onClick={handleHide} danger />
      </div>
    </Modal>
  );
};

export default CannotDeleteJourneyTemplateModal;
