import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { toCurrencyAmount, uniqueId } from '~utils';
import styles from './CustomerInvoice.module.scss';

const MobileInvoiceItems = ({ items, title, subtitle }) => {
  const [isScrolling, setIsScrolling] = useState(false);

  const getLineItemCardId = indexOfLineItem => {
    return `LineItemCard-${indexOfLineItem}`;
  };

  useLayoutEffect(() => {
    if (isScrolling) {
      const lastCard = document.getElementById(
        getLineItemCardId(items.length - 1)
      );
      window.scrollTo(0, lastCard.offsetTop - 20);
      setIsScrolling(false);
    }
  }, [isScrolling]);

  const getItemCard = (item, indexOfLineItem) => {
    const cardBody = (
      <div className={styles.CardBody}>
        <p>{item.description}</p>
        <p>{`${item.quantity} x ${toCurrencyAmount(item.price)}`}</p>
        <p>{item.taxable ? 'Taxable' : 'Not taxable'}</p>
      </div>
    );

    return (
      <div
        key={uniqueId('lineItem_')}
        id={getLineItemCardId(indexOfLineItem)}
        className={styles['MobileCard']}
      >
        <div className={styles['MobileCard-header']}>
          <h3>{item.invoice_type}</h3>
          <p>{toCurrencyAmount(+item.price * +item.quantity)}</p>
        </div>
        {cardBody}
      </div>
    );
  };

  return (
    <div>
      <div className={styles['MobileSectionHeader']}>
        <div>
          <h2>{title}</h2>
          <p>{subtitle}</p>
        </div>
      </div>
      {items.length === 0 ? (
        <p>No line items yet.</p>
      ) : (
        items.map((item, index) => getItemCard(item, index))
      )}
    </div>
  );
};

MobileInvoiceItems.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default MobileInvoiceItems;
