import React, { useState, useRef, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import styles from './Tooltip.module.scss';

const Tooltip = ({ description }) => {
  const [isActive, setIsActive] = useState(false);
  const activatorRef = useRef();
  const getBounds = () => activatorRef.current.getBoundingClientRect();
  const tooltipRef = useRef();
  const OFFSET = 16;

  useLayoutEffect(() => {
    if (isActive) {
      positionElement();
    }
  }, [isActive]);

  const positionElement = () => {
    const tooltipNode = tooltipRef.current;
    const bounds = getBounds();
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;
    const heightOffset =
      viewportHeight -
      (tooltipNode.clientHeight + bounds.top + bounds.height + OFFSET);
    const widthOffset =
      viewportWidth -
      (tooltipNode.clientWidth + bounds.left + bounds.width + OFFSET);

    if (heightOffset < 0) {
      tooltipNode.style.bottom = '0px';
      tooltipNode.style.top = 'auto';
    } else {
      tooltipNode.style.top = `${bounds.top + window.pageYOffset}px`;
      tooltipNode.style.bottom = 'auto';
    }

    if (widthOffset < 0) {
      let right = viewportWidth - (bounds.left + OFFSET);
      tooltipNode.style.right =
        right > 1500 ? `${right - 1000}px` : `${right}px`;
      tooltipNode.style.left = 'auto';
    } else {
      let left = bounds.left + bounds.width + OFFSET;
      tooltipNode.style.left = `${left}px`;
      tooltipNode.style.right = 'auto';
    }

    tooltipNode.style.display = 'block';
  };

  return (
    <div className={styles['Tooltip-container']}>
      <span
        tabIndex={0}
        ref={activatorRef}
        aria-label="Tooltip, tab to open"
        aria-haspopup={true}
        aria-expanded={isActive}
        className="fa fa-info-circle"
        onFocus={() => {
          positionElement();
          setIsActive(true);
        }}
        onBlur={() => {
          setIsActive(false);
        }}
        onMouseEnter={() => {
          setIsActive(true);
        }}
        onMouseLeave={() => {
          setIsActive(false);
        }}
      />
      {ReactDOM.createPortal(
        <div
          className={styles['Tooltip-description']}
          style={isActive ? { display: 'block' } : { display: 'none' }}
          ref={tooltipRef}
        >
          {description}
        </div>,
        document.getElementById('tooltip-container')
      )}
    </div>
  );
};

export default Tooltip;
