import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Checkbox } from '~ui';
import { uniqueId } from '~utils/misc';

function isArrayOfTypeStrings(arr) {
  return arr.length > 0 && typeof arr[0] === 'string';
}

const TableHeader = ({ headers }) => {
  const ASCENDING = 'asc';
  const DESCENDING = 'desc';

  if (isArrayOfTypeStrings(headers)) {
    return (
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={`TableHeader-${index}`}>{header}</th>
          ))}
        </tr>
      </thead>
    );
  } else {
    return (
      <thead>
        <tr>
          {headers.map((header, headerIndex) => {
            const {
              column,
              sortable,
              onSortCallback,
              sortDirection,
              checkbox,
              selectAllCallback,
              checked,
              setChecked,
            } = header;

            const handleHeaderClick = () => {
              if (!sortable) {
                return;
              }

              if (sortDirection === ASCENDING) {
                onSortCallback(column, DESCENDING);
              } else if (sortDirection === DESCENDING) {
                onSortCallback(column, null);
              } else {
                onSortCallback(column, ASCENDING);
              }
            };

            const getIcon = () => {
              if (sortDirection === null) {
                return 'arrows-alt-v';
              }

              if (sortDirection === ASCENDING) {
                return 'long-arrow-alt-up';
              }

              if (sortDirection === DESCENDING) {
                return 'long-arrow-alt-down';
              }
            };

            return (
              <th
                key={uniqueId(`header_${headerIndex}_`)}
                onClick={handleHeaderClick}
              >
                <div style={sortable ? { cursor: 'pointer' } : {}}>
                  {checkbox && (
                    <Checkbox
                      checked={checked}
                      name={'select all'}
                      onChangeCallback={e => {
                        setChecked(e);
                        selectAllCallback(e);
                      }}
                      purpleBorder={true}
                      whiteBackground={true}
                    />
                  )}
                  {column}
                  {sortable && <Icon className="ml-12" name={getIcon()} />}
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }
};

TableHeader.propTypes = {
  headers: PropTypes.array.isRequired,
};

export default TableHeader;
