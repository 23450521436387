import React, { useState } from 'react';
import { Modal, Button } from '~ui';
import axios from 'axios';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import styles from '../CancelSubscriptionModal/CancelSubscriptionModal.module.scss';

const PauseConfirmModal = ({ handleHide, currentSubscription }) => {
  const [disabled, setDisabled] = useState(false);

  const handleKeepMyData = async () => {
    setDisabled(true);
    try {
      const reqParams = {
        subscription: {
          plan: {
            stripe_plan_id: 'iris-keep-my-data',
          },
        },
      };

      await axios.put(
        `/api/subscriptions/${currentSubscription.id}`,
        reqParams
      );

      window.location.reload();
      setDisabled(false);
    } catch (error) {
      setDisabled(false);
      const message =
        'Unable to process subscription update, please contact support';
      toast.error(message, toastOptions);
      Rollbar.error('Unable to process pause subscription', {
        studio: currentSubscription.studio_id,
      });
    }
  };

  return (
    <Modal title="Confirm Account Pause" handleHide={handleHide}>
      <div>
        <p>
          Great! We’ll pause your account - think of this as putting Iris on
          hold for a bit. We will maintain your account exactly as it is today
          until you’re back in full swing! And when you’re ready, just log back
          in to reactivate your account to one of our active plans.
        </p>
      </div>
      <div className={styles['CancelSubscriptionModal--footer']}>
        <Button danger text="Exit" onClick={handleHide} />
        <Button
          text={disabled ? 'Updating' : 'Confirm'}
          onClick={handleKeepMyData}
          disabled={disabled}
        />
      </div>
    </Modal>
  );
};

export default PauseConfirmModal;
