import React from 'react';
import { Modal, Button } from '~ui';

const InvoiceProductAlertModal = ({ handleHide, templateNames }) => {
  return (
    <Modal title={'Check Invoice Templates!'} handleHide={handleHide}>
      <p>
        With this new invoice product price change, you should review the
        following invoice templates for correctness:
      </p>
      {templateNames.map(template => (
        <p key={template}>
          <strong>{template}</strong>
        </p>
      ))}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button text="Close" danger onClick={handleHide} />
      </div>
    </Modal>
  );
};

export default InvoiceProductAlertModal;
