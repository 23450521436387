import React from 'react';
import CalendarEventForm from '~components/Calendar/CalendarEventForm';
import ToggleContent from '~components/common/ToggleContent';
import { getNearestHalfHourDateTime } from '~utils';
import styles from './NewCalendarEventModal.module.scss';

const NewCalendarEventModal = props => {
  const { client_id, getCalendarEvents, noSpecificDay } = props;
  return (
    <ToggleContent
      toggle={handleShow => (
        <div className={styles.button}>
          <button
            type="button"
            className="btn btn-primary btn-wide"
            onClick={handleShow}
          >
            New Event
          </button>
        </div>
      )}
      content={handleHide => (
        <CalendarEventForm
          day={Date.parse(getNearestHalfHourDateTime())}
          handleHide={handleHide}
          clientOverride={client_id}
          getCalendarEvents={getCalendarEvents}
          noSpecificDay={noSpecificDay}
        />
      )}
    />
  );
};

export default NewCalendarEventModal;
