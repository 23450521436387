import { ERRORS } from '~constants/actionTypes';

export default function(state = {}, action) {
  switch (action.type) {
    case ERRORS.CLEAR:
      return action.payload;
    default:
      const { type } = action;
      const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

      // Not a *_REQUEST, *_SUCCESS, or *_FAILURE action, so we ignore them.
      if (!matches) return state;

      const [, requestName, requestState] = matches;
      return {
        ...state,
        // Store whether a request is happening at the moment or not
        // e.g. will be true when receiving GET_TODOS_REQUEST
        //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
        [requestName]: requestState === 'FAILURE' ? action.payload : '',
      };
  }
}
