import React from 'react';
import PropTypes from 'prop-types';

const LoadingBody = ({ columnCount }) => (
  <tbody>
    <tr>
      <td colSpan={columnCount} style={{ textAlign: 'center' }}>
        Loading...
      </td>
    </tr>
  </tbody>
);

LoadingBody.propTypes = {
  columnCount: PropTypes.number.isRequired,
};

export default LoadingBody;
