import React from 'react';
import { Provider } from 'react-redux';
import Invoice from '../Invoice';
import { configureStore } from '../../store';

const InvoiceRoot = props => {
  const store = configureStore(props);

  return (
    <Provider store={store}>
      <Invoice />
    </Provider>
  );
};

export default InvoiceRoot;
