import React from 'react';
import styles from './Unsubscribe.module.scss';

const Unsubscribe = () => {
  return (
    <div className={styles.Unsubscribe}>
      <div>
        <h1>Thank You</h1>
        <h3>You have been successfully unsubscribed from our email list.</h3>
      </div>
    </div>
  );
};

export default Unsubscribe;
