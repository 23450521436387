import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actionsErroredSelector } from '~selectors';
import { clearErrors } from '~actions/errors';
import { toastOptions } from '~constants/toasts';
import { routes } from '~constants/routes';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  Dropdown,
  Toggle,
  PercentageInput,
  Button,
  Checkbox,
  TextArea,
} from '~ui';
import styles from './InvoiceSetup.module.scss';

const PaymentSettingsSection = ({
  asyncUpdateInvoiceConfig,
  setCurrency,
  setTaxEnabled,
  setTaxAmount,
  setSendPaymentReminders,
  setInvoiceEmailMessage,
  setTipsEnabled,
  setCashAppEnabled,
  setShowCouponCodes,
  currency,
  taxEnabled,
  taxAmount,
  sendPaymentReminders,
  invoiceEmailMessage,
  tipsEnabled,
  cashAppEnabled,
  showCouponCodes,
}) => {
  const dispatch = useDispatch();
  const invoiceConfig = useSelector(state => state.invoiceConfig);
  const errors = useSelector(state =>
    actionsErroredSelector(state, ['INVOICE_CONFIGS_UPDATE'])
  );
  const [loading, setLoading] = useState();
  const { studio_id } = invoiceConfig.meta;
  const { hasSquare, hasAfterpayAttributes, hasGoogleAndApplePayAttributes } = invoiceConfig.meta.features;
  useEffect(() => {
    if (loading !== false) {
      return;
    }

    if (errors.INVOICE_CONFIGS_UPDATE) {
      toast.error('Error saving Payment Settings.', toastOptions);
      dispatch(clearErrors());
    } else {
      toast.success('Payment Settings successfully saved.', toastOptions);
    }
  }, [loading]);

  const tipsHandleChange = enabled => {
    setTipsEnabled(enabled);
    const params = {
      feature_flag: {
        studio_id: studio_id,
        feature: 'square_user_tips_enabled',
      },
    };
    if (enabled) {
      axios.post(routes.FEATURE_FLAGS.POST, params);
    } else {
      axios.delete(routes.FEATURE_FLAGS.DELETE, { data: params });
    }

    if (cashAppEnabled) {
      setCashAppEnabled(false);
      axios.delete(routes.FEATURE_FLAGS.DELETE, {
        data: {
          feature_flag: {
            studio_id: studio_id,
            feature: 'cash_app_enabled',
          },
        },
      });
    }
  };

  const cashAppHandleChange = enabled => {
    setCashAppEnabled(enabled);
    const params = {
      feature_flag: {
        studio_id: studio_id,
        feature: 'cash_app_enabled',
      },
    };
    if (enabled) {
      axios.post(routes.FEATURE_FLAGS.POST, params);
    } else {
      axios.delete(routes.FEATURE_FLAGS.DELETE, { data: params });
    }

    if (tipsEnabled) {
      setTipsEnabled(false);
      axios.delete(routes.FEATURE_FLAGS.DELETE, {
        data: {
          feature_flag: {
            studio_id: studio_id,
            feature: 'square_user_tips_enabled',
          },
        },
      });
    }
  };

  const handleChange = async (feature, enabled) => {
    const params = {
      feature_flag: {
        studio_id: studio_id,
        feature: feature,
      },
    };

    try {
      let res;
      if (enabled) {
        res = await axios.post(routes.FEATURE_FLAGS.POST, params);
      } else {
        res = await axios.delete(routes.FEATURE_FLAGS.DELETE, { data: params });
      }
      return true;
    } catch (error) {
      // Feature flag has already been revoked or granted. 
      // Proceed without error.
      if (error.response.status == 417) {
        return true;
      }
      toast.error("Error saving settings.", toastOptions);
      console.error(error);
      return false;
    }
  };

  const handleGoogleAndApplePay = async enabled => {
    const applePayFeature = 'square_apple_pay_enabled'
    const googlePayFeature = 'google_pay_enabled'

    const appleResponse = await handleChange(applePayFeature, enabled);
    if (appleResponse) {
      await handleChange(googlePayFeature, enabled)
    }
  };

  const achHandleChange = enabled => {
    const params = {
      feature_flag: {
        studio_id: studio_id,
        feature: 'square_user_ach_enabled',
      },
    };
    if (enabled) {
      axios.post(routes.FEATURE_FLAGS.POST, params);
    } else {
      axios.delete(routes.FEATURE_FLAGS.DELETE, { data: params });
    }
  };

  const giftCardHandleChange = enabled => {
    const params = {
      feature_flag: {
        studio_id: studio_id,
        feature: 'square_user_gift_card_enabled',
      },
    };
    if (enabled) {
      axios.post(routes.FEATURE_FLAGS.POST, params);
    } else {
      axios.delete(routes.FEATURE_FLAGS.DELETE, { data: params });
    }
  };

  const afterpayHandleChange = enabled => {
    const params = {
      feature_flag: {
        studio_id: studio_id,
        feature: 'afterpay_enabled',
      },
    };
    if (enabled) {
      axios.post(routes.FEATURE_FLAGS.POST, params);
    } else {
      axios.delete(routes.FEATURE_FLAGS.DELETE, { data: params });
    }
  };

  const couponCodesHandleChange = enabled => {
    setShowCouponCodes(enabled);
    const params = {
      feature_flag: {
        studio_id: studio_id,
        feature: 'show_coupon_codes',
      },
    };
    if (enabled) {
      axios.post(routes.FEATURE_FLAGS.POST, params);
    } else {
      axios.delete(routes.FEATURE_FLAGS.DELETE, { data: params });
    }
  };

  return (
    <div className={styles.PaymentSettingsContainer}>
      <Dropdown
        labelText="Currency"
        name="currency"
        options={invoiceConfig.meta.currencyOptions}
        initialValue={currency}
        onChangeCallback={setCurrency}
      />
      <label>Sales Tax</label>
      <div className={styles.SalesTaxContainer}>
        <Toggle initialValue={taxEnabled} onChangeCallback={setTaxEnabled} />
        <PercentageInput
          name="sales_tax"
          initialValue={taxAmount}
          onChangeCallback={setTaxAmount}
          disabled={!taxEnabled}
          maximumFractionDigits={3}
          minimumFractionDigits={3}
        />
      </div>
      <label>
        Square Settings{' '}
        {!hasSquare && (
          <span className={styles.ConnectToSquare}>
            {' '}
            Connect to Square to access these features!  When tips or coupon codes are enabled, all other payment processors will become
            unavailable.
          </span>
        )}
      </label>
      <div className={styles.ToggleContainer}>
        <div className={styles.SalesTaxContainer}>
          <Toggle
            disabled={!hasSquare}
            initialValue={invoiceConfig.ach_enabled}
            onChangeCallback={achHandleChange}
          />
        </div>
        <div>
          ACH <span className={styles.ConnectToSquare}>(US accounts only)</span>
        </div>
      </div>
      <div className={styles.ToggleContainer}>
        <div className={styles.SalesTaxContainer}>
          <Toggle
            disabled={!hasSquare || !hasAfterpayAttributes}
            initialValue={invoiceConfig.afterpay_enabled}
            onChangeCallback={afterpayHandleChange}
          />
        </div>
        <div>
          Afterpay
          <span className={styles.ConnectToSquare}>
            (Must have all studio contact information)
          </span>
        </div>
      </div>
      <div className={styles.ToggleContainer}>
        <div className={styles.SalesTaxContainer}>
          <Toggle
            disabled={!hasSquare || !hasGoogleAndApplePayAttributes}
            initialValue={invoiceConfig.googlePayEnabled}
            onChangeCallback={handleGoogleAndApplePay}
          />
        </div>
        <div>
          Apple Pay/Google Pay
          <span className={styles.ConnectToSquare}>
            (Accept Apple Pay and Google Pay for payment. Must have country selected in studio profile. Apple Pay is only
            available in Safari Browsers)
          </span>
        </div>
      </div>
      <div className={styles.ToggleContainer}>
        <div className={styles.SalesTaxContainer}>
          <Toggle
            disabled={!hasSquare}
            initialValue={cashAppEnabled}
            onChangeCallback={cashAppHandleChange}
          />
        </div>
        <div>
          Cash App
          <span className={styles.ConnectToSquare}>
            (Must have all studio contact information + US accounts only, Tips
            are not currently available with CashApp)
          </span>
        </div>
      </div>
      <div className={styles.ToggleContainer}>
        <div className={styles.SalesTaxContainer}>
          <Toggle
            disabled={!hasSquare}
            initialValue={invoiceConfig.gift_card_enabled}
            onChangeCallback={giftCardHandleChange}
          />
        </div>
        <div>
          Accept Gift Cards{' '}
          <span className={styles.ConnectToSquare}>
            (Accept Square Gift Cards for payment)
          </span>
        </div>
      </div>
      <div className={styles.ToggleContainer}>
        <div className={styles.SalesTaxContainer}>
          <Toggle
            disabled={!hasSquare}
            initialValue={tipsEnabled}
            onChangeCallback={tipsHandleChange}
          />
        </div>
        <div>
          Tips{' '}
        </div>
      </div>
      <div className={styles.ToggleContainer}>
        <div className={styles.SalesTaxContainer}>
          <Toggle
            disabled={!hasSquare}
            initialValue={showCouponCodes}
            onChangeCallback={couponCodesHandleChange}
          />
        </div>
        <div>
          Coupon Codes{' '}
          <span className={styles.ConnectToSquare}>
            (Accept coupon codes from clients.)
          </span>
        </div>
      </div>
      <label>
        Send automatic payment reminders?
        <br />
        <span className="c-mg fw-n">
          Iris will automatically send reminders for invoices with payment
          schedules. Reminders will be sent 5 days before installment due dates
          and on installment due dates.
        </span>
      </label>
      <Checkbox
        name="send_payment_reminders"
        checked={sendPaymentReminders}
        onChangeCallback={setSendPaymentReminders}
      />
      <div className="mt-18">
        <TextArea
          name="invoice_email_message"
          initialValue={
            invoiceConfig.invoice_email_message
              ? invoiceConfig.invoice_email_message
              : ''
          }
          labelText="Include a customized email message when sending your invoices"
          onChangeCallback={setInvoiceEmailMessage}
        />
      </div>
      <Button
        text="Save"
        loading={loading}
        disabled={
          loading ||
          currency === undefined ||
          taxEnabled === undefined ||
          taxAmount === undefined
        }
        onClick={async () => {
          setLoading(true);
          asyncUpdateInvoiceConfig({
            currency: currency,
            tax_enabled_default: taxEnabled,
            tax_amount_default: (Number.parseFloat(taxAmount) * 100).toFixed(3),
            send_payment_reminders: sendPaymentReminders,
            invoice_email_message: invoiceEmailMessage,
          }).then(() => {
            setLoading(false);
          });
        }}
      />
    </div>
  );
};

export default PaymentSettingsSection;
