import React from 'react'

class CatchComponent extends React.PureComponent {
  state = {
    errorThrown: '',
  }

  componentDidCatch(e) {
    this.setState({errorThrown: e})
  }

  render() {
    const {errorThrown} = this.state
    if (!errorThrown) {
      return this.props.children
    }

    return <h1>{errorThrown.toString()}</h1>
  }
}

export default CatchComponent

export function withCatchComponent(child) {
  return <CatchComponent>{child}</CatchComponent>
}
