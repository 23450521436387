import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames/bind';
import { DatePicker } from '~ui';
import { FULL_DATE_FORMAT } from '~constants/datetime';
import { useClickedOutside } from '~hooks';
import { uniqueId } from '~utils';
import styles from './DateTimePicker.module.scss';

const DateTimePicker = props => {
  const {
    disabled,
    disableTime,
    disableDate,
    labelText,
    name,
    onChangeCallback,
    required,
    initialDateTime,
    valid,
    errorMessage,
    onBlurInput,
    makeMaxWidth,
    icon,
  } = props;

  const [dateTextValue, setDateTextValue] = useState('');
  const [timeHourValue, setTimeHourValue] = useState('');
  const [timeMinValue, setTimeMinValue] = useState('');
  const [meridiem, setMeridiem] = useState();
  const [dateTime, setDateTime] = useState(
    initialDateTime ? moment(initialDateTime) : moment()
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isValid, setIsValid] = useState(valid);
  const [timeValid, setTimeValid] = useState(true);
  const id = uniqueId('DateTimePicker-input_');
  const cx = classNames.bind(styles);
  const dateClasses = cx('DateInput', {
    'DateInput--disabled': disabled,
    'DateInput--invalid': !isValid,
    'DateInput--dateOnly': disableTime,
  });

  const timeClasses = cx('TimeInput', {
    'TimeInput--invalid': !timeValid || !isValid,
  });
  const popoverClasses = cx('DateTimePicker-popover', {
    'DateTimePicker-popover--hidden': !isOpen,
  });
  const popoverRef = useRef(null);
  const POPOVER_MIN_WIDTH = 274;

  useEffect(() => {
    const updatedDateTime = moment(initialDateTime).clone();
    if (updatedDateTime.isValid()) {
      if (updatedDateTime.hour() == 12) {
        setTimeHourValue(updatedDateTime.hour());
        setMeridiem('PM');
      } else if (updatedDateTime.hour() == 0) {
        setTimeHourValue(12);
        setMeridiem('AM');
      } else if (updatedDateTime.hour() > 12) {
        setTimeHourValue(updatedDateTime.hour() - 12);
        setMeridiem('PM');
      } else {
        setTimeHourValue(updatedDateTime.hour());
        setMeridiem('AM');
      }
      setTimeMinValue(
        updatedDateTime.minute() < 10
          ? `0${updatedDateTime.minute()}`
          : updatedDateTime.minute()
      );

      setTimeValid(true);
      setIsValid(valid);
      setDateTime(updatedDateTime);
      setDateTextValue(updatedDateTime.format(FULL_DATE_FORMAT));
    }
    positionPopover();
  }, [initialDateTime, valid]);

  useClickedOutside(popoverRef, () => setIsOpen(false));

  const positionPopover = () => {
    const popover = popoverRef.current;
    const input = document.getElementById(id);
    const inputBounds = input.getBoundingClientRect();

    popover.style.top = `${inputBounds.height}px`;

    if (POPOVER_MIN_WIDTH + inputBounds.left > window.innerWidth) {
      popover.style.right = '0px';
      popover.style.left = 'auto';
    } else {
      popover.style.right = 'auto';
      popover.style.left = '0px';
    }
  };

  const checkTimeValid = (hour, minute) => {
    if (hour > 12 || minute > 59) {
      setTimeValid(false);
      return false;
    } else {
      setTimeValid(true);
      return true;
    }
  };

  const handleTimeUpdate = (hourValue, minuteValue, meridiem) => {
    let hour = +hourValue;
    if (meridiem === 'PM' && hour !== 12) {
      hour = hour + 12;
    } else if (meridiem === 'AM' && hour === 12) {
      hour = 0;
    }

    const dateTimeClone = dateTime.clone();
    dateTimeClone.set({ hour, minute: minuteValue });
    onChangeCallback(dateTimeClone);
    onBlurInput(dateTimeClone);
    setDateTime(dateTimeClone);
  };

  const setDateAndDateTextValue = value => {
    const dateTimeClone = dateTime.clone();
    dateTimeClone.set({
      month: value.month(),
      date: value.date(),
      year: value.year(),
    });
    onChangeCallback(dateTimeClone);
    onBlurInput(dateTimeClone);
    setDateTextValue(dateTimeClone.format(FULL_DATE_FORMAT));
    setDateTime(dateTimeClone);
  };

  return (
    <div className={styles['DateTimePickerContainer']}>
      <div className={styles['DateTimePicker']} id={id}>
        {labelText && (
          <label htmlFor={name}>
            {labelText}
            {required && <span>*</span>}
          </label>
        )}
        <div className={styles['InputContainer']}>
          {!disableDate && (
            <div
              className={dateClasses}
              style={
                makeMaxWidth && disableTime && { width: `100%`, margin: '0px' }
              }
            >
              <input
                className="date"
                type="text"
                name={name}
                value={dateTextValue}
                required={required}
                autoComplete="off"
                readOnly
                onFocus={() => setIsOpen(true)}
                disabled={disabled}
                aria-haspopup="true"
                aria-expanded={isOpen}
              />
              {icon && (
                <i
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    color: '#A67EFB',
                    pointerEvents: 'none',
                  }}
                  className={icon}
                ></i>
              )}
            </div>
          )}
          {!disableTime && (
            <div className={timeClasses}>
              <input
                className="hr"
                type="text"
                name={name}
                value={timeHourValue}
                required={required}
                autoComplete="off"
                maxLength="2"
                onFocus={e => e.target.select()}
                onBlur={e => {
                  if (checkTimeValid(e.target.value, timeMinValue)) {
                    handleTimeUpdate(e.target.value, timeMinValue, meridiem);
                  }
                }}
                onChange={e => {
                  if (!isNaN(e.target.value)) {
                    setTimeHourValue(e.target.value);
                  }
                }}
              />
              <span>:</span>
              <input
                className="min"
                type="text"
                name={name}
                value={timeMinValue}
                required={required}
                autoComplete="off"
                maxLength="2"
                onFocus={e => e.target.select()}
                onBlur={e => {
                  if (checkTimeValid(timeHourValue, e.target.value)) {
                    handleTimeUpdate(timeHourValue, e.target.value, meridiem);
                  }
                }}
                onChange={e => {
                  if (!isNaN(e.target.value)) {
                    setTimeMinValue(e.target.value);
                  }
                }}
              />
              <select
                name="meridiem"
                value={meridiem}
                onBlur={() => onBlurInput(dateTime)}
                onChange={e => {
                  setMeridiem(e.target.value);
                  handleTimeUpdate(timeHourValue, timeMinValue, e.target.value);
                }}
              >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </div>
          )}
        </div>
        {!isValid && <span>{errorMessage}</span>}
        {!timeValid && isValid && <span>Invalid Time</span>}
      </div>
      <div className={popoverClasses} ref={popoverRef}>
        <DatePicker
          initialDate={dateTime}
          onChangeCallback={setDateAndDateTextValue}
        />
      </div>
    </div>
  );
};

DateTimePicker.propTypes = {
  disabled: PropTypes.bool,
  disableTime: PropTypes.bool,
  labelText: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChangeCallback: PropTypes.func,
  onBlurInput: PropTypes.func,
  required: PropTypes.bool,
  initialDateTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  valid: PropTypes.bool,
};

DateTimePicker.defaultProps = {
  disabled: false,
  disableTime: false,
  onChangeCallback: () => {},
  required: false,
  valid: true,
  onBlurInput: () => {},
};

export default DateTimePicker;
