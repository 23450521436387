import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { deleteCalendarEvent } from '~actions/calendarEvents';
import { updateShowDeleteModal } from '~actions/calendar';
import { actionsLoadingSelector } from '~selectors';
import { Button, Modal } from '~ui';

const DeleteEventModal = ({ getCalendarEventsForInterval, dateContext }) => {
  const dispatch = useDispatch();
  const activeEvent = useSelector(state => state.calendar.activeEvent);
  const isLoading = useSelector(state =>
    actionsLoadingSelector(state, ['CALENDAR_EVENTS_DELETE'])
  );
  const deleteEvent = async eventId => dispatch(deleteCalendarEvent(eventId));
  const hideDeleteModal = () => dispatch(updateShowDeleteModal(false));

  return (
    <Modal title="Are you sure?" handleHide={hideDeleteModal}>
      <p>{`You will be deleting the event ${activeEvent.title}.`}</p>
      <br />
      <br />
      <Button
        text="Delete Event"
        loading={isLoading}
        onClick={() => {
          deleteEvent(activeEvent.id).then(() => {
            getCalendarEventsForInterval(dateContext);
            hideDeleteModal();
          });
        }}
        className="mr-24"
      />
      <Button text="Cancel" onClick={hideDeleteModal} danger />
    </Modal>
  );
};

export default DeleteEventModal;
