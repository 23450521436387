import React, { useState } from 'react';
import { Dropdown, TextInput } from '~ui';
import styles from './ScheduleIntervalInputs.module.scss';

const ScheduleIntervalInputs = ({
  scheduleTrigger,
  scheduleOffset,
  rowId,
  invoiceTemplateScheduleTrigger,
  stringId,
}) => {
  const scheduleTriggerOptions = [
    { label: 'On the Session Date', value: 'OnSessionDate' },
    { label: 'Before the Session', value: 'BeforeSession' },
    { label: 'After the Session', value: 'AfterSession' },
    { label: 'When Invoice is Received', value: 'WhenReceived' },
  ];
  const initialPaymentIsWhenReceived =
    (rowId == 1 ||
      rowId == 'payment-schedule_row_0' ||
      stringId == 'payment-schedule_row_0') &&
    invoiceTemplateScheduleTrigger == 'WhenReceived';

  const [scheduleTriggerValue, setScheduleTriggerValue] = useState(
    scheduleTrigger
  );

  const getInitialSchedule = () => {
    if (!scheduleOffset) {
      return { schedule_offset: scheduleOffset, schedule_interval: 1 };
    }

    if (scheduleOffset % 365 === 0) {
      return { schedule_offset: scheduleOffset / 365, schedule_interval: 365 };
    } else if (scheduleOffset % 30 === 0) {
      return { schedule_offset: scheduleOffset / 30, schedule_interval: 30 };
    } else if (scheduleOffset % 7 === 0) {
      return { schedule_offset: scheduleOffset / 7, schedule_interval: 7 };
    } else {
      return { schedule_offset: scheduleOffset, schedule_interval: 1 };
    }
  };

  const showInterval =
    scheduleTriggerValue == 'BeforeSession' ||
    scheduleTriggerValue == 'AfterSession';

  return (
    <div className={styles.ScheduleIntervalInputs}>
      <Dropdown
        name="schedule_trigger"
        initialValue={scheduleTriggerValue}
        options={
          initialPaymentIsWhenReceived
            ? [{ label: 'When Invoice is Received', value: 'WhenReceived' }]
            : scheduleTriggerOptions
        }
        onChangeCallback={val => {
          setScheduleTriggerValue(val);
        }}
      />
      {showInterval && (
        <>
          <div className={styles['ScheduleIntervalInputs-by']}>by</div>
          <TextInput
            name="schedule_offset"
            type="number"
            onFocusSelectText
            characterLimit={2}
            initialValue={getInitialSchedule().schedule_offset || 1}
          />
          <Dropdown
            name="schedule_interval"
            initialValue={getInitialSchedule().schedule_interval}
            options={[
              { label: 'Day(s)', value: 1 },
              { label: 'Week(s)', value: 7 },
              { label: 'Month(s)', value: 30 },
              { label: 'Year(s)', value: 365 },
            ]}
          />
        </>
      )}
    </div>
  );
};

export default ScheduleIntervalInputs;
