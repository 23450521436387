import React from 'react';
import styles from './DraggableField.module.scss';
import { useDrag } from 'react-dnd';
import ItemTypes from '../../constants/ItemTypes';

const DraggableField = ({ field }) => {
  const { id, icon, title } = field;

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.FIELD, id },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div
      className={styles.DraggableField}
      style={{ border: isDragging ? '1px solid #75eeff' : '0px' }}
      ref={drag}
    >
      <div className={styles['DraggableField-title']}>
        {icon}
        {title}
      </div>
      <i className="fas fa-grip-vertical" />
    </div>
  );
};

export default DraggableField;
