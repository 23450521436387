import React from 'react';
import { Provider } from 'react-redux';
import InvoiceModal from '~components/Invoice/InvoiceModal';
import ToggleContent from '~components/common/ToggleContent';
import { configureStore } from '../../../store';

const NewInvoiceModal = props => {
  const client = { client_id: props.client_id };
  const store = configureStore({ invoice: { ...props.invoice, ...client } });

  // TODO We don't use the new Button component because it makes the other buttons
  // next to it look like shit. We'll replace it one day.
  return (
    <Provider store={store}>
      <ToggleContent
        toggle={handleShow => (
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleShow}
          >
            New Invoice
          </button>
        )}
        content={handleHide => <InvoiceModal handleHide={handleHide} />}
      />
    </Provider>
  );
};

export default NewInvoiceModal;
