import React, { useState, useRef, useEffect } from 'react';
import { Button, TextInput, TextArea, Dropdown } from '~ui';
import styles from './CampaignTemplate.module.scss';
import { routes, absolutePath } from '~constants/routes';
import axios from 'axios';
import { uniqueId } from '~utils';
import { toast } from 'react-toastify';
import { toastOptions } from '~constants/toasts';
import { SendModal } from '../Modals';
import EmailEditor from 'react-email-editor';
import { useDebounce, useIsFirstMount } from '~hooks';

const CampaignTemplate = ({
  initialFormData,
  campaignTemplateId,
  stepId,
  emailTemplateId,
  studioId,
  sessionTypesOptions,
  unlayerApiKey,
  railsEnv,
  studioGroups,
  timeZone,
  currentPlan,
  marketingValidated,
}) => {
  const marketing = currentPlan.includes('mktg');

  const UNLAYER_API_KEY = unlayerApiKey;

  const emptyFormData = {
    name: '',
    description: '',
    emailSubject: '',
    emailUnlayerJson: '',
  };

  const isFirstMount = useIsFirstMount();

  const emailEditorRef = useRef(null);

  const [isEditing, setIsEditing] = useState(!!initialFormData);
  const blockForSafari = !!initialFormData;
  const [loading, setLoading] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);
  const [returnToMarketing, setReturnToMarketing] = useState(false);
  const [formData, setFormData] = useState(
    isEditing ? initialFormData : emptyFormData
  );

  const [templateId, setTemplateId] = useState(campaignTemplateId || null);
  const [emailTempId, setEmailTempId] = useState(emailTemplateId || null);
  const [campaignStepId, setCampaignStepId] = useState(stepId || null);
  const [unlayerTemplatesOptions, setUnlayerTemplatesOptions] = useState([
    { label: 'Start from scratch', value: null },
  ]);
  const [unlayerLoading, setUnlayerLoading] = useState(false);
  const [unlayerChosenId, setUnlayerChosenId] = useState(null);

  const [changeDetected, setChangeDetected] = useState(false);
  const debouncedFormData = useDebounce(formData, 3000);

  useEffect(() => {
    if (changeDetected) {
      emailEditorRef.current.editor.exportHtml(data => {
        setFormData({ ...formData, emailUnlayerJson: data });
      });

      setChangeDetected(false);
    }
  }, [changeDetected]);

  useEffect(() => {
    if (!isFirstMount) {
      if (isEditing) {
        if (
          initialFormData === undefined ||
          initialFormData.name !== formData.name ||
          initialFormData.description !== formData.description ||
          initialFormData.emailSubject !== formData.emailSubject ||
          initialFormData.emailUnlayerJson !== formData.emailUnlayerJson
        ) {
          updateEmailCampaignTemplate(debouncedFormData);
        }
      } else if (
        debouncedFormData.name &&
        debouncedFormData.emailSubject &&
        debouncedFormData.emailUnlayerJson
      ) {
        createEmailCampaignTemplate(debouncedFormData);
      }
    }
  }, [debouncedFormData]);

  const emailEditorOnLoad = () => {
    emailEditorRef.current.addEventListener('design:updated', () => {
      setChangeDetected(true);
    });

    if (isEditing && emailEditorRef.current.editor) {
      emailEditorRef.current.editor.loadDesign(
        initialFormData.emailUnlayerJson
      );
    }
  };

  useEffect(() => {
    getUnlayerTemplates();
  }, []);

  useEffect(() => {
    if (!blockForSafari && emailEditorRef.current.editor) {
      if (unlayerChosenId) {
        getUnlayerTemplateById();
      } else {
        emailEditorRef.current.editor.loadBlank();
      }
    }
  }, [unlayerChosenId, emailEditorRef]);

  const getUnlayerTemplates = async () => {
    try {
      setUnlayerLoading(true);
      const res = await axios.get('https://api.unlayer.com/v2/templates', {
        auth: {
          username: UNLAYER_API_KEY,
          password: '',
        },
      });

      const options = res.data.data.map(template => ({
        label: template.name,
        value: template.id,
      }));

      options.sort((a, b) => a.label.localeCompare(b.label));

      setUnlayerTemplatesOptions([...unlayerTemplatesOptions, ...options]);
      setUnlayerLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getUnlayerTemplateById = async () => {
    try {
      setUnlayerLoading(true);
      const res = await axios.get(
        `https://api.unlayer.com/v2/templates/${unlayerChosenId}`,
        {
          auth: {
            username: UNLAYER_API_KEY,
            password: '',
          },
        }
      );

      emailEditorRef.current.editor.loadDesign(res.data.data.design);
      emailEditorRef.current.editor.exportHtml(data => {
        setFormData({ ...formData, emailUnlayerJson: data });
      });
      setUnlayerLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const sendTemplate = () => {
    setShowSendModal(true);
  };

  const createEmailCampaignTemplate = async debouncedFormData => {
    try {
      const { html, design } = debouncedFormData.emailUnlayerJson;
      setLoading(true);
      const res = await axios.post(routes.EMAIL_CAMPAIGN_TEMPLATES.CREATE, {
        email_campaign_template: {
          name: debouncedFormData.name,
          description: debouncedFormData.description,
          steps_attributes: [
            {
              order: 1,
              time_offset: 0,
              email_template_attributes: {
                name: uniqueId('email_campaign_template_'),
                email_subject: debouncedFormData.emailSubject,
                email_body: html,
                unlayer_json: design,
                studio_id: studioId,
              },
            },
          ],
        },
      });
      const stepId = res.data.data.attributes.steps.data[0].id;
      const emailTemplateId =
        res.data.data.attributes.steps.data[0].attributes.email_template.id;

      setTemplateId(res.data.data.attributes.id);
      setEmailTempId(emailTemplateId);
      setCampaignStepId(stepId);
      setIsEditing(true);
      setLoading(false);

      toast.success('Campaign Template Created!', {
        ...toastOptions,
        autoClose: 1000,
      });
    } catch (error) {
      console.error(error);
      toast.error(
        'There was an error creating your Campaign Template',
        toastOptions
      );
    }
  };

  const updateEmailCampaignTemplate = async debouncedFormData => {
    try {
      const { html, design } = debouncedFormData.emailUnlayerJson;
      setLoading(true);
      await axios.put(routes.EMAIL_CAMPAIGN_TEMPLATES.UPDATE(templateId), {
        email_campaign_template: {
          name: debouncedFormData.name,
          description: debouncedFormData.description,
          steps_attributes: [
            {
              id: campaignStepId,
              email_template_attributes: {
                id: emailTempId,
                email_subject: debouncedFormData.emailSubject,
                email_body: html,
                unlayer_json: design,
              },
            },
          ],
        },
      });
      toast.info('Campaign Template Updated!', {
        ...toastOptions,
        autoClose: 1000,
      });
      setLoading(false);
      if (returnToMarketing) {
        window.location.href = absolutePath('/email_marketing');
      }
    } catch (error) {
      console.error(error);
      toast.error(
        'There was an error updating your Campaign Template',
        toastOptions
      );
    }
  };

  const getTitle = () => {
    if (isEditing) {
      return 'Edit Campaign Template';
    } else {
      return 'New Campaign Template';
    }
  };

  return (
    <>
      <div className={styles['CampaignTemplate']}>
        <div className={styles['CampaignTemplate-header']}>
          <a href={absolutePath('/email_marketing')}>
            Back to Email Marketing Dashboard
          </a>
          <h1>{getTitle()}</h1>
        </div>
        <div className={styles['CampaignTemplate-form']}>
          <div className={styles['CampaignTemplate-form-column']}>
            <div className={styles['CampaignTemplate-form-input']}>
              <TextInput
                name="templateName"
                labelText="Template Name"
                initialValue={formData.name}
                onChangeCallback={val =>
                  setFormData({ ...formData, name: val })
                }
                required
              />
            </div>
            <div className={styles['CampaignTemplate-form-input']}>
              <TextArea
                name="templateDescription"
                labelText="Description"
                initialValue={formData.description}
                onChangeCallback={val =>
                  setFormData({ ...formData, description: val })
                }
              />
            </div>
          </div>
          <div className={styles['CampaignTemplate-form-column']}>
            <div className={styles['CampaignTemplate-form-input']}>
              <TextInput
                name="emailSubject"
                labelText="Email Subject"
                initialValue={formData.emailSubject}
                onChangeCallback={val =>
                  setFormData({ ...formData, emailSubject: val })
                }
                required
              />
            </div>
            <div className={styles['CampaignTemplate-form-input']}>
              <Dropdown
                name="templates"
                labelText="Iris Templates"
                disabled={
                  (initialFormData && initialFormData.name.length > 0) ||
                  unlayerLoading
                }
                options={unlayerTemplatesOptions}
                onChangeCallback={val => setUnlayerChosenId(+val)}
              />
            </div>
          </div>
        </div>
        <div className={styles['CampaignTemplate-buttonGroup']}>
          <Button
            disabled={loading}
            text="Cancel"
            danger
            onClick={() => {
              window.location.href = absolutePath('/email_marketing');
            }}
          />

          <>
            <Button
              disabled={loading || !formData.name || !formData.emailSubject}
              text="Save for later"
              white
              onClick={() => {
                emailEditorRef.current.editor.exportHtml(
                  data => {
                    setFormData({ ...formData, emailUnlayerJson: data });
                  },
                  {
                    minify: true,
                  }
                );
                setReturnToMarketing(true);
              }}
            />
            <Button
              disabled={loading || !formData.name || !formData.emailSubject}
              text="Save and Send"
              onClick={() => {
                emailEditorRef.current.editor.exportHtml(
                  data => {
                    setFormData({ ...formData, emailUnlayerJson: data });
                    sendTemplate();
                  },
                  {
                    minify: true,
                  }
                );
              }}
            />
          </>
        </div>
        <div>
          <EmailEditor
            ref={emailEditorRef}
            onLoad={emailEditorOnLoad}
            projectId={83126}
            minHeight={'700px'}
            options={{
              user: {
                id: `${railsEnv} ${studioId}`,
              },
            }}
          />
        </div>
      </div>

      {showSendModal && (
        <SendModal
          handleHide={() => setShowSendModal(false)}
          emailCampaignTemplateId={templateId}
          sessionTypesOptions={sessionTypesOptions}
          studioGroups={studioGroups}
          timeZone={timeZone}
          marketingValidated={marketingValidated}
          marketing={marketing}
        />
      )}
    </>
  );
};

export default CampaignTemplate;
