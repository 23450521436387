export const readableStatusColors = [
  {
    status: 'complete',
    labelText: 'Complete',
    color: 'green',
  },
  {
    status: 'incomplete',
    labelText: 'Incomplete',
    color: 'red',
  },
  {
    status: 'late',
    labelText: 'Late',
    color: 'red',
  },
  {
    status: 'paid',
    labelText: 'Paid',
    color: 'green',
  },
  {
    status: 'refunded',
    labelText: 'Fully refunded',
    color: 'green',
  },
  {
    status: 'partially_refunded',
    labelText: 'Partially refunded',
    color: 'green',
  },
  {
    status: 'partially_paid',
    labelText: 'Partially Paid',
    color: 'orange',
  },
  {
    status: 'read',
    labelText: 'Read',
    color: 'green',
  },
  {
    status: 'signed',
    labelText: 'Signed',
    color: 'green',
  },
  {
    status: 'unpaid',
    labelText: 'Unpaid',
    color: 'orange',
  },
  {
    status: 'ach_pending',
    labelText: 'ACH Pending',
    color: 'orange',
  },
  {
    status: 'unread',
    labelText: 'Unread',
    color: 'orange',
  },
  {
    status: 'unsigned',
    labelText: 'Unsigned',
    color: 'red',
  },
  {
    status: 'scheduled',
    labelText: 'Scheduled',
    color: 'blue',
  },
  {
    status: 'delivered',
    labelText: 'Delivered',
    color: 'green',
  },
  {
    status: 'past',
    labelText: 'Past',
    color: 'blue',
  },
  {
    status: 'upcoming',
    labelText: 'Upcoming',
    color: 'green',
  },
  {
    status: 'needs_rescheduled',
    labelText: 'Needs Rescheduled',
    color: 'red',
  },
  {
    status: 'cancelled',
    labelText: 'Cancelled',
    color: 'red',
  },
  {
    status: 'contract',
    labelText: 'Contract',
  },
  {
    status: 'document',
    labelText: 'Document',
  },
  {
    status: 'email',
    labelText: 'Email',
  },
  {
    status: 'lead',
    labelText: 'Lead',
  },
  {
    status: 'invoice',
    labelText: 'Invoice',
  },
  {
    status: 'quote',
    labelText: 'Quote',
  },
  {
    status: 'questionnaire',
    labelText: 'Questionnaire',
  },
];
