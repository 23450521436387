import React from 'react';
import { Modal, Button } from '~ui';
import axios from 'axios';
import { toast } from 'react-toastify';

const ConfirmationModal = props => {
  const { handleHide, referenceId, setLoading, grabNextPage } = props;

  const resendContent = async () => {
    setLoading(true);
    try {
      await axios.put(`/api/email_messages/${referenceId}/resend`);
      toast.success('Sent!');
      grabNextPage();
      setLoading(false);
    } catch (error) {
      if (error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Error!');
      }
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <Modal title="Are you sure?" handleHide={() => handleHide(false)}>
      <div className="mb-8">Would you like to re-send this email?</div>
      <br />
      <div className="d-fr">
        <Button
          className="mr-8"
          text="Cancel"
          onClick={e => {
            e.stopPropagation();
            handleHide();
          }}
          danger
        />
        <Button
          text="Re-send"
          onClick={e => {
            e.stopPropagation();
            resendContent();
            handleHide();
          }}
        />
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
