import React from 'react';
import Dropzone from 'react-dropzone';
import styles from './DocumentUploader.module.scss';
import { MAX_UPLOAD_MB, MAX_UPLOAD_BYTES, DOCUMENT_TYPE } from './uploader';

const DocumentUploader = ({
  onChange,
  onError,
  acceptedDocumentTypes,
  readAs,
}) => {
  const onDrop = acceptedFiles => {
    const fileName = acceptedFiles[0].name;

    if (acceptedFiles.length > 1) {
      onError({ message: 'Please attach one file at a time' });
      return;
    }

    if (acceptedFiles[0].size >= MAX_UPLOAD_BYTES) {
      onError({
        message: `File too large, must be less than ${MAX_UPLOAD_MB} MB`,
      });
      return;
    }

    const reader = new FileReader();
    reader[readAs](acceptedFiles[0]);
    reader.onload = () => {
      onChange({
        dataString: reader.result,
        fileName: fileName,
        file: acceptedFiles[0],
      });
    };

    reader.onerror = error => {
      onError({
        message:
          'We encountered an error while processing your file, please try again',
      });
      Rollbar.error(`Error processing email template attachment`, error);
    };
  };

  return (
    <Dropzone
      onDrop={onDrop}
      accept={
        acceptedDocumentTypes
          ? acceptedDocumentTypes.join()
          : DOCUMENT_TYPE.join()
      }
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()} className={styles.DocumentUploader}>
          <input {...getInputProps()} />
          <p>
            Drag 'n' drop your file here, or click to select file. Maximum file
            size is {MAX_UPLOAD_MB}mb.
          </p>
        </div>
      )}
    </Dropzone>
  );
};

export default DocumentUploader;
