import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextInput } from '~ui';
import { routes } from '~constants/routes';
import styles from './Form.module.scss';

const ReminderTemplateForm = ({ cancelCallback }) => {
  const [description, setDescription] = useState('');

  const handleSubmit = async () => {
    await axios.post(routes.REMINDER_TEMPLATE.CREATE, {
      reminder_template: {
        description,
      },
    });

    cancelCallback();
  };

  return (
    <div className={styles.Form}>
      {/* This will change to Reminder Template, but not until we enhance to-dos. */}
      <span className={styles['Form-header']}>Create To-Do</span>
      <TextInput
        name="description"
        labelText="Description"
        initialValue={description}
        onChangeCallback={setDescription}
        required
      />
      <div className={styles['Form-buttons']}>
        <Button
          onClick={cancelCallback}
          ariaLabel="Back to options"
          text="Cancel"
          danger
        />
        <Button onClick={handleSubmit} ariaLabel="Create To-Do" text="Save" />
      </div>
    </div>
  );
};

export default ReminderTemplateForm;
