import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const Context = React.createContext({});

class ApiCacheProvider extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any.isRequired, // React portals not supported by prop-types
  };

  constructor(props) {
    super(props);

    this.state = {
      apiCache: {},
      fetchItems: this.fetchItems,
      fetchItem: this.fetchItem,
      addItemToCache: this.addItemToCache,
      reloadIndex: this.reloadIndex,
      getItems: this.getItems,
    };
  }

  addItemsToCache = (name, items) => {
    const cache = this.state.apiCache[name] || { values: {}, sorted: [] };
    const values = { ...cache.values };
    const sorted = items.map(item => item.id);

    items.forEach(item => {
      if (values[item.id] && values[item.id]._fetched) {
        return;
      }
      const newItem = { ...item };
      values[item.id] = newItem;
    });
    const newCache = { values, sorted };
    this.setState({
      apiCache: {
        ...this.state.apiCache,
        [name]: newCache,
      },
    });
  };

  getItems = apiName => {
    const { apiCache } = this.state;
    const store = apiCache[apiName];
    if (store) {
      const { values, sorted } = store;
      if (sorted && values) {
        return sorted.map(id => values[id]);
      }
      if (values) {
        return Object.values(values);
      }
    }
    return [];
  };

  addItemToCache = (name, item) => {
    const cache = this.state.apiCache[name] || { values: {}, sorted: [] };
    const sorted = [...cache.sorted];
    const values = { ...cache.values };
    const newItem = { ...item, _fetched: true };
    if (!values[item.id]) {
      sorted.unshift(item.id);
    }
    values[item.id] = newItem;
    const newCache = { values, sorted };
    this.setState({
      apiCache: {
        ...this.state.apiCache,
        [name]: newCache,
      },
    });
  };

  reloadIndex = apiName => {
    const { apiCache } = this.state;

    this.setState(
      {
        apiCache: {
          ...apiCache,
          [apiName]: undefined,
        },
      },
      () => {
        this.fetchItems(apiName);
      }
    );
  };

  parseResponse = res => {
    return res.data || res;
  };

  fetchItems = async (apiName, params = {}) => {
    if (this.state.apiCache[apiName]) {
      return this.getItems(apiName);
    }

    if (apiName === 'contract_templates') {
      params.all_records = true;
      params.sort = {
        column: 'name',
        direction: 'asc',
      };
    }

    const body = { params };
    const url = apiName[0] === '/' ? apiName : `/api/${apiName}`;

    try {
      const { data } = await axios.get(url, body);
      const parsed = this.parseResponse(data);
      this.addItemsToCache(apiName, parsed);
      return parsed;
    } catch (e) {
      console.error(e);
    }
    return [];
  };

  fetchItem = async (apiName, id) => {
    const cache = this.state.apiCache[apiName] || {};
    const { values } = cache;
    if (values && values[id] && values[id]._fetched) {
      return values[id];
    }
    const url =
      apiName[0] === '/' ? `${apiName}/${id}` : `/api/${apiName}/${id}`;
    try {
      const { data } = await axios.get(url);
      const parsed = this.parseResponse(data);
      this.addItemToCache(apiName, parsed);
      return parsed;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export const Provider = ApiCacheProvider;
export const Consumer = Context.Consumer;

export default {
  Provider,
  Consumer,
};
